<template>
  <div class="pa-0 ma-0" style="padding: 0px !important; margin: 0px !important; width: 100% !important">
    <v-card style="" class="elevation-0 pa-0 ma-0">
      <v-form ref="form" lazy-validation>
        <v-row dense class="ma-0 pa-0">
          <!-- Customer Information -->
          <v-col cols="3" class="grey lighten-4">
            <v-row dense class="mx-1 mt-1">
              <v-col cols="12" class="d-flex align-center">
                <div class="text-left primary--text text-h6 font-weight-light">Customer / Prospect</div>
              </v-col>
              <v-col cols="12" sm="12">
                <v-divider class="grey lighten-1 mb-1"></v-divider>
              </v-col>
              <!-- <pre>{{ selectedOrgTags }}</pre> -->
              <v-col cols="12" xs="12" class="d-flex align-center">
                <v-autocomplete label="Select Organisation" v-model="selectedOrgTags" auto-select-first
                                :rules="rules.contactOrganisation" :items="organisations" item-text="orgName"
                                return-object outlined dense hide-details="auto" background-color="white"
                                color="primary" clearable @click:clear="clearSelectedOrganisation()"></v-autocomplete>
                <v-btn class="grey lighten-2 caption ml-2 font-weight-bold primary--text elevation-0"
                       @click="showAddOrgContactDialog('org')">Add +
                </v-btn>
              </v-col>
              <v-col cols="12" xs="12" class="d-flex align-center">
                <v-autocomplete
                    :disabled="JSON.stringify(selectedOrgTags) === '{}' || selectedOrgTags == null || selectedOrgTags.id === ''"
                    label="Select Contact" v-model="selectedContactTags" :rules="rules.leadContactTags"
                    :items="contacts" item-text="contactName" return-object outlined dense hide-details="auto"
                    background-color="white" color="primary" clearable @click:clear="clearSelectedContact()"
                    no-data-text="No Contacts for this Organisation"></v-autocomplete>
                <v-btn
                    :disabled="JSON.stringify(selectedOrgTags) === '{}' || selectedOrgTags == null || selectedOrgTags.id === ''"
                    class="grey lighten-2 caption ml-2 font-weight-bold primary--text elevation-0"
                    @click="showAddOrgContactDialog('contact')">Add +
                </v-btn>
              </v-col>
              <v-col cols="12" class="mt-2">
                <div class="text-left grey--text text--secondary text-h7 font-weight-light">MOF Owner</div>
              </v-col>
              <v-col cols="12" sm="12">
                <v-divider class="grey lighten-1 mb-1 mt-1"></v-divider>
              </v-col>
              <v-col cols="12" xs="12" md="12">
                <v-select label="Owner" v-model="selectedLeadOwner" :rules="rules.leadOwner" :items="users" outlined
                          dense hide-details="auto" background-color="white" color="primary" clearable
                          @click:clear="clearItem('leadStatus')" :menu-props="{ top: true, offsetY: true }"
                          :attach="true">
                  <template v-slot:selection="data">
                    {{ data.item.ownerUserName }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.ownerUserName }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" class="mt-2">
                <div class="text-left grey--text text--secondary text-h7 font-weight-light">Source of Lead</div>
              </v-col>
              <v-col cols="12" sm="12">
                <v-divider class="grey lighten-1"></v-divider>
              </v-col>
              <v-col cols="12" xs="12" md="12">
                <v-autocomplete label="Source" v-model="item.salesSource" :rules="rules.salesSource" :items="sources"
                                outlined dense hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearItem('orgOtherAddressType')" auto-select-first></v-autocomplete>
              </v-col>
              <v-col cols="12" xs="12" md="12">
                <v-text-field label="Source Name" v-model="item.salesSourceName" :rules="rules.salesSource" required
                              outlined dense hide-details="auto" background-color="white" color="primary" clearable
                              @click:clear="clearItem('leadSourceName')"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- Popout New Customer / Contact -->
          <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn"
                      mode="out-in">
            <v-col v-if="addOrg === true" class="grey lighten-2 pa-0 pt-2 ma-0" cols="3">
              <v-row dense class="pa-0 ma-0 px-2" fill-height>
                <v-col cols="12" class="d-flex pl-0 justify-center align-center">
                  <!-- @click="closeDialog" -->
                  <div class="pl-2 text-left grey--text text--darken-2 text-h6 font-weight-light">Add New Organisation
                  </div>
                  <v-spacer/>
                  <v-icon class="primary--text font-weight-bold dense" @click="closeAddOrgContactDialog" icon depressed>
                    icons8-back
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-divider class="grey lighten-1 mb-1"></v-divider>
                </v-col>
                <v-col cols="12" sm="12" class="">
                  <vuetify-google-autocomplete prepend-icon="icons8-google" autocomplete="donotcomplete" dense outlined
                                               background-color="white" label="Find a Business" name="myapp-name"
                                               ref="addressInput" types="" id="map" classname="form-control"
                                               v-on:placechanged="getAddressData" country="gb"
                                               clearable></vuetify-google-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" md="12">
                  <v-text-field label="Name" v-model="org.orgName" :rules="rules.orgName" required outlined dense
                                hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearItem('orgName')" autocomplete="donoautocomplete"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12">
                  <v-text-field label="Address" v-model="org.orgAddressLine1" :rules="rules.orgAddressLine1" outlined
                                dense hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearItem('orgAddressLine1')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="6">
                  <v-text-field label="County" v-model="org.orgCounty" :rules="rules.orgCounty" outlined dense
                                hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearItem('orgCounty')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="6">
                  <v-text-field label="Postcode" v-model="org.orgPostcode" :rules="rules.orgPostcode" outlined dense
                                hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearItem('orgPostcode')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="6">
                  <v-text-field label="Telephone" v-model="org.orgTelephone" :rules="rules.orgTelephone" outlined dense
                                hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearItem('orgTelephone')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="6">
                  <v-select label="Account Type" multiple v-model="selectedAccountType" :rules="rules.orgAccountType"
                            :items="accountTypes" outlined dense hide-details="auto" background-color="white"
                            color="primary" clearable @click:clear="clearSelectedAccountTypes()"
                            :menu-props="{ top: true, offsetY: true }" :attach="true"
                            autocomplete="donotautocomplete"></v-select>
                </v-col>
                <v-col cols="12" xs="6" class="my-2">
                  <v-btn block class="tertiary white--text font-weight-bold" depressed @click="saveOrg">
                    <v-icon class="mr-2">icons8-save</v-icon>
                    <span>Save Organisation</span></v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else-if="addContact === true" class="grey lighten-2 pa-0 pt-2 ma-0" cols="3">
              <v-row dense class="pa-0 ma-0 pt-3 pl-2 pr-3" fill-height>
                <v-col cols="12" class="d-flex pl-0 justify-center align-center">
                  <!-- @click="closeDialog" -->
                  <div class="pl-2 text-left grey--text text--darken-2 text-h6 font-weight-light">Add New Contact</div>
                  <v-spacer/>
                  <v-icon class="primary--text font-weight-bold dense" @click="closeAddOrgContactDialog" icon depressed>
                    icons8-back
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-divider class="grey lighten-1 mb-1"></v-divider>
                </v-col>
                <v-col cols="12" xs="12" class="my-1">
                  <v-text-field label="First Name" v-model="contactName.firstName" :rules="rules.contactFirstName"
                                outlined dense hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" class="my-1">
                  <v-text-field label="Last Name" v-model="contactName.lastName" :rules="rules.contactLastName" outlined
                                dense hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" class="my-1">
                  <v-text-field label="Email" v-model="contact.contactEmail" :rules="rules.contactEmail" outlined dense
                                hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearItem('contactEmail', '')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" class="my-1">
                  <v-text-field label="Telephone" v-model="contact.contactTelephone" :rules="rules.contactTelephone"
                                outlined dense hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearItem('contactTelephone')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" class="my-1">
                  <v-btn block class="tertiary white--text font-weight-bold" depressed @click="saveContact">
                    <v-icon class="mr-2">icons8-save</v-icon>
                    <span>Save Contact</span></v-btn>
                </v-col>
              </v-row>
            </v-col>
          </transition>
          <!-- Opportunity Details -->
          <v-col class="grey lighten-2 px-3 pb-12 ma-0" style="border-left: darkgrey solid 2px !important;">
            <v-row dense>
              <v-col cols="12" class="d-flex align-center">
                <div class="text-left primary--text text-h6 font-weight-light mt-2">Details</div>
              </v-col>
              <v-col cols="12" sm="12">
                <v-divider class="grey lighten-1 mb-1"></v-divider>
              </v-col>
              <v-col cols="12" xs="12" md="8" class="">
                <v-text-field label="Lead Title" v-model="item.salesTitle" :rules="rules.salesTitle" outlined dense
                              hide-details="auto" background-color="white" color="primary" clearable
                              @click:clear="clearItem('salesTitle')"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" md="4" class="">
                <v-select label="Lead Stage" v-model="selectedLeadStage" :rules="rules.leadStage" :items="leadStages"
                          outlined dense hide-details="auto" background-color="white" color="primary" clearable
                          @click:clear="clearItem('leadStages')" :menu-props="{ top: false, offsetY: true }"
                          :attach="true">
                  <template v-slot:selection="data">
                    {{ data.item.stageName }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.stageName }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <!-- :rules="descriptionRules" -->
                <v-textarea label="Short Description" :rules="descriptionRules" counter=280 rows="4"
                            background-color="white" v-model="item.salesDescription" outlined dense color="primary"
                            hide-details="auto" clearable @click:clear="clearItem('leadDescription', '')"></v-textarea>
              </v-col>
              <!--                            <v-col cols="12" xs="12" md="12">-->
              <!--                                <v-select label="Packages" deletable-chips tiles item-value="id" item-text="packageName" chips multiple v-model="selectedPackages" :rules="rules.salesOwner" :items="packages" outlined hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('salesStatus')" :menu-props="{ top: true, offsetY: true }" :attach="true" />-->
              <!--                            </v-col>-->

              <v-col cols="12" xs="12" md="6" class="d-flex mb-6 align-center">
                <div class="white px-3  align-center"
                     style="height: 75px !important; border: 1px solid darkgrey !important; border-radius: 5px !important; width: 100% !important">
                  <div class="mr-2 body-2 text--darken-2 grey--text mt-1">Type</div>
                  <v-btn-toggle v-model="item.salesType" class="" style="width: 100% !important" :color="chosenOneType"
                                dense>
                    <v-btn value="cold" active-class="info white--text" style="width: 33.33% !important">Cold</v-btn>
                    <v-btn value="warm" active-class="warning white--text" style="width: 33.33% !important">Warm</v-btn>
                    <v-btn value="hot" active-class="error white--text" style="width: 33.33% !important">Hot</v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>
              <v-col cols="12" xs="12" md="6" class="d-flex mb-6 align-center">
                <div class="white px-3  align-center"
                     style="height: 75px !important; border: 1px solid darkgrey !important; border-radius: 5px !important; width: 100% !important">
                  <div class="mr-2 body-2 text--darken-2 grey--text mt-1">Time Priority</div>
                  <v-btn-toggle v-model="item.salesTimePriority" class="" style="width: 100% !important"
                                :color="chosenOneTime" dense>
                    <v-btn value="low" active-class="success white--text" style="width: 33.33% !important" depressed>
                      Low
                    </v-btn>
                    <v-btn value="medium" active-class="warning white--text" style="width: 33.33% !important" depressed>
                      Medium
                    </v-btn>
                    <v-btn value="high" active-class="error white--text" style="width: 33.33% !important" depressed>
                      High
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <!-- Cancel / Save -->
          <v-col cols="12" class="grey darken-2 d-flex justify-center pa-5">
            <v-btn @click="closeItem" class="grey white--text font-weight-bold" :disabled="showAddOrgContact == true"
                   depressed>
              <v-icon class="mr-2">icons8-multiply</v-icon>
              <span>Cancel</span></v-btn>
            <v-spacer/>
            <v-btn class="primary white--text font-weight-bold ml-2" :disabled="showAddOrgContact == true" depressed
                   @click="saveItem">
              <v-icon class="mr-2">icons8-save</v-icon>
              <span>Save</span></v-btn>
          </v-col>
        </v-row>
      </v-form>


    </v-card>
  </div>
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
// import moment from 'moment';

export default {
  props: {
    datadown: {
      Type: String,
      Default: '',
    },
    activeitem: {
      Type: Object,
      Default: null,
    },
  },
  // * DATA
  data: () => ({
    key: 'lead',
    itemNew: true,
    schema: {},
    org: {},
    contact: {},
    editmode: true,

    leadTitle: '',
    countries: [],
    countryFilter: '',
    sources: [],

    selectedLeadStatus: [],
    selectedOrgTags: null,
    selectedContactTags: null,
    // dropdowns
    packageTypes: [],
    billingCycles: [],
    packageStatus: [],
    descriptionRules: [v => v?.length <= 280 || 'Max 280 characters'],
    selectedLeadOwner: [],
    selectedLeadStage: [],
    tagAutocomplete: '',


    selectedPackages: [],

    // contactName
    contactName: {
      title: '',
      firstName: '',
      lastName: '',
    },

    // dropdowns
    packages: [],

    leadStages: [],
    accountTypes: [],
    users: [],
    leadStatuses: [],
    organisations: [],
    contacts: [],

    addOrg: false,
    addContact: false,
    showAddOrgContact: false,
    selectedAccountType: [],


    chosenOneTime: '',
    chosenOneType: '',

    months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

    // rules
    packageCustomerDescriptionRules: [v => v.length <= 280 || 'Max 280 characters'],
    packageInternalDescriptionRules: [v => v.length <= 280 || 'Max 280 characters'],


    // new address object
    item: {},
    loading: true,
    rules: {},

  }),
  computed: {
    computedLocalAuthority() {
      if (this.countryFilter === '') {
        return [];
      } else {
        return this.localAuthority.filter((item) => {
          return item.country === this.countryFilter;
        });
      }
      // 	return this.localAuthority.filter(function (item) {
      // 		return item.country === this.countryFilter;
      // 	});
      // } else {
      //}
    },
  },
  methods: {
    showAddOrgContactDialog(state) {
      var t = this;

      if (state == 'org') {
        t.addOrg = true
        t.addContact = false
      } else if (state == 'contact') {
        t.addOrg = false
        t.addContact = true
      }

      t.showAddOrgContact = true
    },
    closeAddOrgContactDialog() {
      var t = this;
      t.showAddOrgContact = false
      t.addOrg = false
      t.addContact = false

      // // ////console.log("schema = " + JSON.stringify(t.orgSchema, null, 2))
      t.org = t.orgSchema
      t.contact = t.contactSchema

    },
    getAddressData: function (addressData, placeResultData) {
      let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
      // // ////console.log(JSON.stringify(placeResult, null, 2));
      this.org.orgName = placeResult.name;
      this.org.orgAddressLine1 = placeResult.addressLine1;
      this.org.orgAddressLine2 = placeResult.addressLine2;
      this.org.orgAddressLine3 = placeResult.addressLine3;
      this.org.orgTown = placeResult.town;
      this.org.orgCounty = placeResult.county;
      this.org.orgPostcode = placeResult.postcode;
      this.org.orgCountry = placeResult.country;
      this.org.orgLatitude = placeResult.lat;
      this.org.orgLongitude = placeResult.lng;
      this.org.orgTelephone = placeResult.elephone;
      this.org.orgWebsite = placeResult.website;
      // this.showMeetingAddress = true

    },
    clearSelectedOrganisation() {
      setTimeout(() => {
        this.selectedOrgTags = {id: '', orgName: ''};
      }, 1);
    },
    clearSelectedContact() {
      setTimeout(() => {
        this.selectedContactTags = {id: '', contactName: ''};
      }, 1);
    },
    // Clear Selected
    clearSelectedAccountTypes() {
      setTimeout(() => {
        this.selectedAccountType = [];
      }, 1);
    },
    // Clear Item Property
    clearContactName(key, value) {
      setTimeout(() => {
        this.item[key] = value;
      }, 1);
    },
    async saveOrg() {
      let t = this;
      var item = t.org
      if (JSON.stringify(this.selectedAccountType) !== '[]') {
        this.selectedAccountType = this.selectedAccountType.filter((item) => {
          return item !== '';
        });
        item.orgAccountTypes = this.selectedAccountType.join(',');
      }
      item.id = t.MIX_generateId();
      let result = await t.MIX_createItem(item, 'org', true);
      if (result.code === 1) {
        t.MIX_alertBox({color: "success", timeout: 2000, message: "Saved Organisation", show: true});
        t.closeAddOrgContactDialog()
        t.selectedOrgTags = {id: '', orgName: ''}
        t.selectedOrgTags.id = item.id
        t.selectedOrgTags.orgName = item.orgName
        let organisations = await t.MIX_organisations();
        t.organisations = organisations;
      }
    },
    async saveContact() {
      let t = this;
      var item = t.contact
      // Adding Contact Org as selectedOrgTags
      item.orgId = this.selectedOrgTags.id
      item.orgName = this.selectedOrgTags.orgName
      item.id = t.MIX_generateId();
      let result = await t.MIX_createItem(item, 'contact', true);
      if (result.code === 1) {
        t.MIX_alertBox({color: "success", timeout: 2000, message: "Saved Contact", show: true});
        t.closeAddOrgContactDialog()
        t.selectedContactTags = {id: '', contactName: ''}
        t.selectedContactTags.id = item.id
        t.selectedContactTags.contactName = item.contactFullName
        let contacts = await t.MIX_contacts();
        t.contacts = contacts;
      }
    },
    updateItem() {
      this.$emit('update', this.item);
    },
    // Clear Item Property
    clearItem(property) {
      setTimeout(() => {
        this.item[property] = '';
      }, 1);
    },
    // * CREATE ITEM
    async saveItem() {
      this.item.salesPackages = this.selectedPackages.join(',');
      this.$emit('save', this.item);
    },
    // Cancel Item
    cancelItem() {
      this.item = this.schema;
      this.$emit('close');
    },
    // Close Item
    closeItem() {
      this.item = this.schema;
      this.$emit('close');
    },
    formatTag: function (item) {
      if (item !== null) {
        return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
      } else {
        return '';
      }
    },
  },
  watch: {
    selectedOrgTags: {
      handler: async function () {
        // ////console.log("selectedOrgTags = " + JSON.stringify(this.selectedOrgTags, null, 2))
        this.item.orgId = this.selectedOrgTags?.id;
        this.item.orgName = this.selectedOrgTags?.orgName;
        // // ////console.log("item = " + JSON.stringify(this.item, null, 2))

        let contacts = await this.MIX_contacts(this?.selectedOrgTags?.id);
        this.contacts = contacts;

      },
      deep: true,
    },
    contactName: {
      handler: function () {
        this.contact.contactFirstName = this.contactName.firstName;
        this.contact.contactLastName = this.contactName.lastName;
        this.contact.contactFullName = this.contactName.firstName + ' ' + this.contactName.lastName;
        this.contact.contactFullName.trim();
      },
      deep: true,
    },
    selectedContactTags: {
      handler: function () {
        // ////console.log("selectedContactTags = " + JSON.stringify(this.selectedContactTags, null, 2))
        this.item.contactId = this.selectedContactTags?.id;
        this.item.contactName = this.selectedContactTags?.contactName;
        // // ////console.log("item = " + JSON.stringify(this.item, null, 2))
      },
      deep: true,
    },
    // addOrg: {
    //     handler: function () {
    //         if (this.addContact === true) {
    //             this.addContact = false;
    //         }
    //         // ////console.log("addOrg = " + this.addOrg)
    //     },
    //     deep: true
    // },
    // addContact: {
    //     handler: function () {
    //         if (this.addOrg === true) {
    //             this.addOrg = false;
    //         }
    //         // ////console.log("addContact = " + this.addContact)
    //     },
    //     deep: true
    // },
    datadown: {
      handler: function () {
        this.item = {...this.datadown};
        if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
          this.selectedLeadOwner = {
            ownerUserId: this.datadown.ownerUserId,
            ownerUserName: this.datadown.ownerUserName,
            ownerUserEmail: this.datadown.ownerUserEmail
          };
        } else {
          this.selectedLeadOwner = this.MIX_getCurrentOwner();
        }
        if (this.item.salesStageId !== '') {
          this.selectedLeadStage = {
            stageId: this.datadown.salesStageId,
            stageName: this.datadown.salesStageName
          }
        }
        if (this.item.orgId !== '') {
          this.selectedOrgTags = {
            id: this.datadown.orgId,
            orgName: this.datadown.orgName,
          };
        }
        if (this.item.contactId !== '') {
          this.selectedContactTags = {
            id: this.datadown.contactId,
            contactName: this.datadown.contactName,
          };
        }
        // // ////console.log("slectedLeadOwner = " + JSON.stringify(this.selectedLeadOwner, null, 2));
      },
      deep: true,
    },
    activeitem: {
      handler: function () {
        let t = this;
        t.item = {...t.activeitem};
      }, deep: true
    },
    leadTitle: {
      handler: function () {
        this.item.salesTitle = this.leadTitle;
        // // ////console.log('lead title = ' + this.item.salesTitle);
      },
    },
    // Selected Tags
    selectedTags: {
      handler: async function () {
        for (let i = 0; i < this.selectedTags.length; i++) {
          this.selectedTags[i] = this.selectedTags[i].toLowerCase();
          this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, ' ');
          if (this.selectedTags[i] === ' ') {
            this.selectedTags.splice(i, 1);
          } else {
            this.selectedTags[i] = this.selectedTags[i].trim();
          }
        }
        // Update Content Tags and split out array with commas
        this.contentItem.contentTags = this.selectedTags.join(',');

      },
      deep: true,
    },
    // selectedOrgTags: {
    //     handler: function () {
    //         this.item.leadOrgTags = '';
    //         this.item.leadOrgTagsId = '';
    //         for (let i = 0; i < this.selectedOrgTags.length; i++) {
    //             this.item.leadOrgTags = this.item.leadOrgTags + this.selectedOrgTags[i].orgName + ',';
    //             this.item.leadOrgTagsId = this.item.leadOrgTagsId + this.selectedOrgTags[i].id + ',';
    //         }
    //         // // ////console.log('selectedOrgTags = ' + JSON.stringify(this.selectedOrgTags, null, 2));
    //     },
    //     deep: true,
    // },
    selectedLeadStatus: {
      handler: function () {
        this.item.salesStatus = this.selectedLeadStatus.join(',');
      },
    },
    selectedIndustry: {
      handler: function () {
        this.item.orgIndustry = this.selectedIndustry.join(',');
      },
    },
    selectedChannel: {
      handler: function () {
        this.item.orgChannel = this.selectedChannel.join(',');
      },
    },
    selectedLeadOwner: {
      handler: function () {
        this.item.ownerUserId = this.selectedLeadOwner.ownerUserId;
        this.item.ownerUserName = this.selectedLeadOwner.ownerUserName;
        this.item.ownerUserEmail = this.selectedLeadOwner.ownerUserEmail;
        // // ////console.log('selectedLeadOwner = ' + JSON.stringify(this.selectedLeadOwner.ownerUserName, null, 2));
      },
    },
    selectedLeadStage: {
      handler: function () {
        this.item.salesStageName = this.selectedLeadStage.stageName;
        this.item.salesStageId = this.selectedLeadStage.stageId;
      },
    }

  },
  // * CREATED
  async created() {
    let t = this;
    t.schema = schema.lead;
    t.orgSchema = schema.organisation
    t.org = {...t.orgSchema}
    t.contactSchema = schema.contact
    t.contact = {...t.contactSchema}
    t.loading = true


    t.item = {...t.datadown};
    if (this.item.salesPackages !== '' && this.item.salesPackages !== undefined && this.item.salesPackages !== null) {
      this.selectedPackages = this.item.salesPackages.split(',');
    }
    if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
      this.selectedLeadOwner = {
        ownerUserId: this.datadown.ownerUserId,
        ownerUserName: this.datadown.ownerUserName,
        ownerUserEmail: this.datadown.ownerUserEmail
      };
    } else {
      this.selectedLeadOwner = this.MIX_getCurrentOwner();
    }
    // // ////console.log("selectedLeadOwner = " + JSON.stringify(this.selectedLeadOwner, null, 2));

    // Get Local Authority
    let localAuthoritiesResult = await this.MIX_redisSearch('idx:localAuthority', `*`, 0, 9999);
    t.localAuthority = localAuthoritiesResult.data.documents.map((x) => x.value);
    // Get Countries
    t.countries = Array.from(new Set(t.localAuthority.map((x) => x.country)));


    let sources = await t.MIX_redisReadSet('set:sources', true);
    if (sources.code === 1) {
      t.sources = sources.data;
    }
    let organisations = await t.MIX_organisations();
    t.organisations = organisations;

    let contacts = await t.MIX_contacts();
    // console.table(contacts)
    t.contacts = contacts;

    let users = await t.MIX_users();
    t.users = users.map((x) => {
      return {
        ownerUserId: x.id,
        ownerUserName: x.userName,
        ownerUserEmail: x.userEmail,
      };
    });
    // // ////console.log("users = " + JSON.stringify(users, null, 2));

    let leadStatuses = await t.MIX_redisReadSortedSet('sset:leadStatus', 0, 9999);
    if (leadStatuses.code === 1) {
      t.leadStatuses = leadStatuses.data;
    }


    let packageTypes = await t.MIX_redisReadSet('set:componentTypes', false);
    if (packageTypes.code === 1) {
      t.packageTypes = packageTypes.data;
    }
    let packageStatus = await t.MIX_redisReadSet('set:componentStatus', false);
    if (packageStatus.code === 1) {
      t.packageStatus = packageStatus.data;
    }
    let packagesResult = await this.MIX_redisSearch('idx:packages', `*`, 0, 9999);
    this.packages = packagesResult.data.documents.map((x) => x.value);

    let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
    if (billingCycles.code === 1) {
      t.billingCycles = billingCycles.data;
    }


    let leadStages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
    leadStages = leadStages.data.documents.map((x) => x.value);
    // // ////console.log("leadStages = " + JSON.stringify(leadStages, null, 2));
    t.leadStages = leadStages.filter((item) => {
      return item.stagePipeline === 'Leads';
    })
    // Get Account Types
    let accountTypes = await t.MIX_redisReadSet('set:orgAccountTypes', false);
    if (accountTypes.code === 1) {
      t.accountTypes = accountTypes.data;
    }
    t.leadStages = t.leadStages.map((x) => {
      return {
        stageId: x.id,
        stageName: x.stageName,
      };
    })
    // this.selectedLeadStatus = this.item.salesStatus.split(',');
    t.loading = false
  },
  mounted() {
  },
};
</script>