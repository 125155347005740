<template>
    <div class="noScroll">
        <div v-if="$route.query.printdialog" class="primary d-flex align-center justify-center noprint" style="height: 100px !important; width: 100% !important">
            <v-btn depressed class="white pa-3 ma-5 rounded" @click="printQuote()">Print Quote</v-btn>
        </div>
        <!-- <pre>{{ quoteOptions }}</pre> -->
        <div id="printview" class="white pa-5">
            <v-row dense class="pb-1" style="margin-left: 1px !important; margin-right: 1px !important; border-bottom: 3px solid grey !important">
                <v-col cols="8" class="d-flex align-start justify-start pa-0">
                    <div><v-img max-width="150" max-height="100" contain src="@/assets/logo.png" align="left" /></div>
                    <div class="px-2 body-2">
                        <strong>{{ companyDetails.companyName }}</strong><br />
                        {{ companyDetails.companyAddress }}<br />
                        <span class="caption"><a :href="'tel:'+ companyDetails.companyTelephone">{{ companyDetails.companyTelephone }}</a> <a :href="'mailto:' + companyDetails.companyEmail">{{ companyDetails.companyEmail }}</a></span>
                    </div>
                </v-col>
                <v-col cols="4" class="align-right d-flex align-center justify-end">
                    <!-- {{ 'https://storage.googleapis.com/mof-swapp-public/organisations/' + item.orgId + '.jpeg'}} -->
                    <div class="" v-if="item.orgId !== '' && item.orgId !== undefined && item.orgId !== null"><v-img class="" width="200" contain :src="'https://storage.googleapis.com/mof-swapp-public/organisations/' + item.orgId + '.jpeg'" /></div>
                    <div class="ml-5" v-if="item.quoteNPS === '1'"><v-img width="50" contain src="@/assets/npslogo.png" /></div>
                </v-col>
            </v-row>
            <v-row dense class="" style="margin-left: 1px !important; margin-right: 1px !important;">
                <v-col cols="2" class="">
                    <h1>
                        <span>Order</span>
                    </h1>
                </v-col>
            </v-row>

            <v-row dense class="">
                <v-col cols="12" class="body-2">
                    <table width="100%" border="1" style="border: 2px solid grey !important">
                        <thead>
                            <tr>
                                <td colspan="6" class="body-2">
                                     <span class="text-h7 font-weight-bold">Title:</span> <span class="text-h7">{{ item.quoteTitle }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" width="50%">
                                    <div class="text-h7 font-weight-bold">Client Details:</div>
                                </td>
                                <td colspan="3" width="50%">
                                    <div class="text-h7 font-weight-bold">Delivery Address:</div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="3" valign="top">
                                    <div v-if="item.id !== ''">
                                        <div class="">{{ item.orgName }}</div>
                                        <div class="" v-if="item.orgInvoiceAddressLine1 !== ''">{{ item.orgInvoiceAddressLine1 }}</div>
                                        <div class="" v-if="item.orgInvoiceAddressLine2 !== ''">{{ item.orgInvoiceAddressLine2 }}</div>
                                        <div class="" v-if="item.orgInvoiceAddressLine3 !== ''">{{ item.orgInvoiceAddressLine3 }}</div>
                                        <div class="" v-if="item.orgInvoiceTown !== ''">{{ item.orgInvoiceTown }}</div>
                                        <div class="" v-if="item.orgInvoiceCounty !== ''">{{ item.orgInvoiceCounty }}</div>
                                        <div class="" v-if="item.orgInvoicePostcode !== ''">{{ item.orgInvoicePostcode }}</div>
                                    </div>
                                    <div class="text-h7 mt-3">Client Ref: {{ item.quoteClientRef }}</div>
                                </td>
                                <td colspan="3" valign="top">
                                    <div v-if="item.id !== ''">
                                        <div class="">{{ item.orgName }}</div>
                                        <div class="" v-if="item.orgDeliveryAddressLine1 !== ''">{{ item.orgDeliveryAddressLine1 }}</div>
                                        <div class="" v-if="item.orgDeliveryAddressLine2 !== ''">{{ item.orgDeliveryAddressLine2 }}</div>
                                        <div class="" v-if="item.orgDeliveryAddressLine3 !== ''">{{ item.orgDeliveryAddressLine3 }}</div>
                                        <div class="" v-if="item.orgDeliveryTown !== ''">{{ item.orgDeliveryTown }}</div>
                                        <div class="" v-if="item.orgDeliveryCounty !== ''">{{ item.orgDeliveryCounty }}</div>
                                        <div class="" v-if="item.orgDeliveryPostcode !== ''">{{ item.orgDeliveryPostcode }}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr>
                                <td class="font-weight-bold text-center">Number</td>
                                <td class="font-weight-bold text-center">Date</td>
                                <td class="font-weight-bold text-center">Account Number</td>
                                <td class="font-weight-bold text-center">For Attention</td>
                                <td class="font-weight-bold text-center">Expiry Date</td>
                                <td class="font-weight-bold text-center">Requested Delivery</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">{{ item.quoteNumber }}<span v-if="quoteVersion"> - Rev. {{ quoteVersion }} </span></td>
                                <td class="text-center">{{ MIX_formatDateTime(item.quoteDate, 'X', 'DD-MMM-YYYY') }}</td>
                                <td class="text-center">{{ item.orgId }}</td>
                                <td class="text-center">{{ item.contactName }}</td>
                                <td class="text-center">{{ MIX_formatDateTime(item.quoteExpiryDate, 'X', 'DD-MMM-YYYY') }}</td>
                                <td class="text-center">
                                    <!-- {{ MIX_formatDateTime(item.quoteDeliveryDate, 'X', 'DD-MMM-YYYY') }} -->
                                    <span v-if="item.quoteDeliveryDate !== '0'">{{ MIX_formatDateTime(item.quoteDeliveryDate, 'X', 'DD-MMM-YYYY') }}</span><span v-else-if="item.quoteDeliveryDate === '0'">TBC</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </v-col>
            </v-row>
            <v-row class="px-3 body-2">
                <table width="100%" border="1" style="border: 2px solid grey !important">
                    <thead>
                        <tr>
                            <th align="center">ID</th>
                            <th align="left" v-if="toggleQuoteFeatures.includes('images')"></th>
                            <th align="left">Description</th>
                            <th align="center" v-if="toggleQuoteFeatures.includes('features')">Features</th>
                            <th align="center" v-if="toggleQuoteFeatures.includes('location')">Location</th>
                            <th align="center" class="grey lighten-4" v-if="toggleQuoteFeatures.includes('rrp')">RRP</th>
                            <th align="center" class="purple lighten-4">Qty</th>
                            <th align="center" class="cyan lighten-4">Net Price</th>
                            <th align="center" class="grey lighten-4" v-if="toggleQuoteFeatures.includes('discount')">Discount</th>
                            <th align="center" class="purple lighten-4">Extended</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in nonOptionalItems" :key="index" style="border-top: 1px solid grey !important">
                            <td align="center" width="20">{{ index + 1 }}</td>
                            <td align="left" width="110" v-if="toggleQuoteFeatures.includes('images')">
                                <div class="d-flex align-center justify-center white rounded" style="border: 1px solid lightgrey !important; width: 110px; height: 110px">
                                    <div v-if="item.lineImageId !== ''"><v-img class="my-2" width="100" height="100" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/thumbnails/' + item.lineImageId + '.jpeg'" /></div>
                                    <div v-if="item.lineImageId === ''"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
                                </div>
                            </td>
                            <td align="left" valign="top">
                                Code: <strong>{{ item.lineCode }}</strong
                                ><br />
                                {{ item.lineDescription }}
                                <br />
                                {{ item.lineDimensions }}
                                <div v-if="item.lineReference !== ''"><br />REF: <strong>{{ item.lineReference }}</strong></div>
                            </td>
                            <td align="left" valign="top" class="" v-if="toggleQuoteFeatures.includes('features')">
                                <div class="d-flex align-center justify-center"  v-if="item.lineFeatures !== '' && item.lineFeatures !== undefined && item.lineFeatures !== null">
                                    <div class="ml-2" style="border: 0px solid red;" v-for="feature in item.lineFeatures.split(',')" :key="feature"><v-img class="" width="50" contain :src="'https://storage.googleapis.com/mof-swapp-public/productfeatures/' + feature + '.jpeg'" /></div>
                                </div>
                            </td>
                            <td align="left" valign="top" v-if="toggleQuoteFeatures.includes('location')">
                                <div v-if="item.lineFloorTag !== ''">Floor: <strong>{{ item.lineFloorTag }}</strong
                                ><br /></div>
                                <div v-if="item.lineRoomTag !== ''">Room: <strong>{{ item.lineRoomTag }}</strong></div>
                            </td>
                            <td align="center" class="grey lighten-4" v-if="toggleQuoteFeatures.includes('rrp')">
                                {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineRRP) }}
                            </td>                            
                            <td align="center" class="purple lighten-4">
                                {{ item.lineQty }}
                            </td>
                            <td align="center" class="cyan lighten-4">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineSell) }}</td>
                            <td align="center" class="grey lighten-4" v-if="toggleQuoteFeatures.includes('discount')">
                                <div v-if="item.lineDiscount !== '' && item.lineDiscount != 0">{{ item.lineDiscount }} %</div>
                            </td>
                            <td align="center" class="purple lighten-4">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineTotalSell) }}</td>
                        </tr>
                    </tbody>

                    <tbody v-for="(option, index) in quoteOptions" width="100%" :key="index">
                        <!-- <pre>{{ option }}</pre> -->
                        <tr v-if="computedOptionalItems(option).length > 0" style="border-top: 1px solid grey !important">
                            <td colspan="6">
                                <div class="font-weight-bold body-1">
                                    {{ option }}
                                </div>
                            </td>
                        </tr>
                        <tr v-for="(item, index) in computedOptionalItems(option)" :key="index" style="border-top: 1px solid grey !important">
                            <td align="center" width="20">{{ index + 1 }}</td>
                            <td align="left" width="110" v-if="toggleQuoteFeatures.includes('images')">
                                <div class="d-flex align-center justify-center white rounded" style="border: 1px solid lightgrey !important; width: 110px; height: 110px">
                                    <div v-if="item.lineImageId !== ''"><v-img class="my-2" width="100" height="100" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/thumbnails/' + item.lineImageId + '.jpeg'" /></div>
                                    <div v-if="item.lineImageId === ''"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
                                </div>
                            </td>
                            <td align="left" valign="top">
                                Code: <strong>{{ item.lineCode }}</strong
                                ><br />
                                {{ item.lineDescription }}
                                <br />
                                {{ item.lineDimensions }}
                                <div v-if="item.lineReference !== ''"><br />REF: <strong>{{ item.lineReference }}</strong></div>
                            </td>
                            <td align="left" valign="top" class="" v-if="toggleQuoteFeatures.includes('features')">
                                <div class="d-flex align-center justify-center"  v-if="item.lineFeatures !== '' && item.lineFeatures !== undefined && item.lineFeatures !== null">
                                    <div class="ml-2" style="border: 0px solid red;" v-for="feature in item.lineFeatures.split(',')" :key="feature"><v-img class="" width="50" contain :src="'https://storage.googleapis.com/mof-swapp-public/productfeatures/' + feature + '.jpeg'" /></div>
                                </div>
                            </td>
                            <td align="left" valign="top" v-if="toggleQuoteFeatures.includes('location')">
                                <div v-if="item.lineFloorTag !== ''">Floor: <strong>{{ item.lineFloorTag }}</strong
                                ><br /></div>
                                <div v-if="item.lineRoomTag !== ''">Room: <strong>{{ item.lineRoomTag }}</strong></div>
                            </td>
                            <td align="center" class="grey lighten-4" v-if="toggleQuoteFeatures.includes('rrp')">
                                {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineRRP) }}
                            </td>                            
                            <td align="center" class="purple lighten-4">
                                {{ item.lineQty }}
                            </td>
                            <td align="center" class="cyan lighten-4">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineSell) }}</td>
                            <td align="center" class="grey lighten-4" v-if="toggleQuoteFeatures.includes('discount')">
                                <div v-if="item.lineDiscount !== '' && item.lineDiscount != 0">{{ item.lineDiscount }} %</div>
                            </td>
                            <td align="center" class="purple lighten-4">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineTotalSell) }}</td>
                        </tr>
                    </tbody>



                    <tbody>
                        <tr style="border-top: 3px solid black !important" v-if="item.quoteInstall === '1'">
                            <td :colspan="toggleQuoteFeatures.includes('showTotal')? 3 + toggleQuoteFeatures.length : 4 + toggleQuoteFeatures.length" class="font-weight-normal">Installation</td>
                            <td align="right" class="font-weight-normal">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.totalInstallSell) }}</td>
                        </tr>
                        <tr v-if="toggleQuoteFeatures.includes('showTotal')" style="border-top: 3px solid black !important">
                            <td :colspan="3 + toggleQuoteFeatures.length" class="font-weight-bold">Quote Total</td>
                            <td align="right" class="font-weight-bold">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.totalSell) }}</td>
                        </tr>
                        <tr v-for="(option, index) in quoteOptions" :key="index" style="border-top: 3px solid black !important">
                            <td v-if="computedOptionalItems(option).length > 0" :colspan="toggleQuoteFeatures.includes('showTotal')? 3 + toggleQuoteFeatures.length : 4 + toggleQuoteFeatures.length" class="font-weight-bold">Total Quote Amount {{ option }}</td>
                            <td v-if="computedOptionalItems(option).length > 0"  align="right" class="font-weight-bold">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(computedQuoteTotal(option)) }}</td>
                        </tr>
                        <tr style="border-top: 3px solid black !important">
                            <td :colspan="toggleQuoteFeatures.includes('showTotal')? 4 + toggleQuoteFeatures.length : 5 + toggleQuoteFeatures.length" class="text--secondary">Quoted By: {{ item.ownerUserName }} - <a :href="`mailto:${updatedEmailAddress}`">{{ updatedEmailAddress }}</a> - <a href="tel:01639812382">01639 812382</a></td>
                        </tr>
                    </tbody>
                </table>
            </v-row>
            <!-- Customer Note -->
            <v-row class="pt-3 px-3 body-2" v-if="item.quoteCustomerNote !== ''">
                <table width="100%" border="1" style="border: 2px solid grey !important">
                    <thead>
                        <tr>
                            <th align="left">Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="left">
                                <div class="caption mt-1">{{ item.quoteCustomerNote }}</div>
                            
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-row>            
            <!-- Terms & Conditions -->
            <v-row class="pt-3 px-3 body-2">
                <table width="100%" border="1" style="border: 2px solid grey !important">
                    <thead>
                        <tr>
                            <th align="left">Terms</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="left">
                                <div class="caption" v-html="item.quoteTerms"></div>
                                <div class="caption mt-3" v-if="item.approved === '0'">{{ quoteTermOptions.Estimate }}</div>
                            
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-row>

            <v-row dense class="mt-4">
                <v-col class="d-flex align-center justify-start">Date Printed: {{ MIX_formatDateTime($moment().format('x'), 'x', 'DD-MMM-YYYY') }}</v-col>
                <v-col class="d-flex align-center justify-center text-h5"><a href="https://www.ministryoffurniture.com" class="text-decoration-none">www.ministryoffurniture.com</a></v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from 'vuex';
import schema from '@/schema.js';
export default {
    name: 'PrintPreview',
    // * PROPS
    props: {
        quoteid: {
            type: String,
            default: '',
        },
        refreshlineitems: {
            type: Number,
            default: 0,
        },
        quotefeatures: {
            type: Array,
            default: () => []
        }
    },
    // * DATA
    data: () => ({
        
        key: 'orderLineItem',
        index: 'idx:orderLineItems',
        // TODO - RG - Create a Server Schema for lineItems
        printDialog: false,
        queryString: '',
        quoteId: '',
        loading: true,
        // Toggles
        toggleDeletedItems: false,
        toggleProductImages: false,
        toggleMoveItems: false,
        toggleMargin: false,
        toggleDiscount: false,
        mcdToggle: false,
        toggleQuoteFeatures: [],
        // Filters
        filter: {},
        // Item
        item: {},
        // Items
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 999,
        // Headers
        //No Headers as not using table
        sortBy: '@lineOrder',

        dropKey: 0,
        // items: [1, 2, 3, 4, 5],
        marginReasons: ['Market Price', 'Price Match', 'Competition', 'Other'],
        product: {
            lineType: 'product',
            productName: 'Item 1',
            productCode: 'EDW-31',
            supplierName: 'Metalliform',
            supplierAccNo: 'MET001',
            productImage: 'https://picsum.photos/id/341/250',
            qty: 1,
            cost: 100.0,
            sell: 142.86,
            RRP: 110.0,
            totalCost: 100.0,
            totalSell: 142.86,
            margin: 30,
            marginSell: 42.86,
            discount: 0,
            mcdRate: 0,
            lineTotal: 142.86,
            lineMargin: 30,
            lineMaringSell: 42.86,
            optional: false,
            includeTotal: true,
            marginReason: '',
            marginComments: '',
        },
        lineItems: [],
        companyDetails: {},
        quoteTermOptions: {},
        quoteOptions: {}, 

        updatedEmailAddress: '',
    }),
    // * COMPUTED
    computed: {
        searchQuery() {
            return '@quoteId:' + this.quoteId + '@deleted:0';
        },
        quoteInstall() {
            let totalInstallSell = this.items
                    .filter((item) => item.lineType === 'Product' && item.lineIncludeTotal === '1')
                    .map((item) => {
                        var IMP
                        if (item.lineIMP > 0) {
                            IMP = Number(item.lineIMP)
                        } else {
                            IMP = 8;                            
                        }
                        var lineInstall = ((Number(IMP) / 100) * Number(item.lineTotalSell)) //.toFixed(2)
                        console.log('lineInstall = ' + lineInstall)
                        return lineInstall
                    })
                    .reduce((prev, curr) => prev + curr, 0)
            
            totalInstallSell = (Number(totalInstallSell))//.toFixed(2)

            let totalInstallCost = totalInstallSell - (10 / 100) * totalInstallSell;
            return {
                totalInstallCost: totalInstallCost.toFixed(2),
                totalInstallSell: totalInstallSell.toFixed(2),
                totalInstallMarginValue: (totalInstallSell - totalInstallCost).toFixed(2),
                totalInstallMarginPercent: (((totalInstallSell - totalInstallCost) / totalInstallSell) * 100).toFixed(2),
            };
        },        
        quoteTotal() {
            let totalCost = Number(
                this.items
                    .filter((item) => item.lineType === 'Product' && item.lineIncludeTotal === '1')
                    .map((item) => Number(item.lineTotalCost))
                    .reduce((prev, curr) => prev + curr, 0)
            );
            let totalSell = Number(
                this.items
                    .filter((item) => item.lineType === 'Product' && item.lineIncludeTotal === '1')
                    .map((item) => Number(item.lineTotalSell))
                    .reduce((prev, curr) => prev + curr, 0)
            );
            if (this.item.quoteInstall === '1') {
                totalSell += Number(this.quoteInstall.totalInstallSell);
            }
            // console.log('totalCost = ', totalCost);
            let totalSellMargin = Number(totalSell) - Number(totalCost);
            let totalMargin = (totalSellMargin / totalSell) * 100;
            return {
                totalCost: totalCost.toFixed(2),
                totalSell: totalSell.toFixed(2),
                totalSellMargin: totalSellMargin.toFixed(2),
                totalMargin: totalMargin.toFixed(2),
            };
        },
        // Computed Items
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        nonOptionalItems() {
            var items = this.items 
            
            items = items.filter((item) => {
                return item.lineOptionalTags === ''
            })

            return items
        },
        quoteVersion() {
            const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

            var version = this.item.quoteVersion
            var revision = ''

            version = version - 1

            revision = alphabet[version]

            return revision
        }
    },
    // * WATCH
    watch: {
        refreshlineitems() {
            this.getLineItems();
        },
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
            },
            deep: true,
        },
        quoteid: {
            handler: async function () {
                this.quoteId = this.quoteid;
                await this.readItem();
                this.getLineItems();
            },
        },
        loading: {
            handler: function () {
                let t = this;
            },
            deep: true,
        },
        quotefeatures: {
            handler: function () {
                this.updateQuoteFeatures();
            },
            deep: true,
        },
    },
    // * METHODS
    methods: {
        computedOptionalItems(option) {
            var items = this.items 

            items = items.filter((item) => {
                return item.lineOptionalTags == option
            })

            return items
        },
        computedQuoteTotal(option) {
            var items = this.items 
            
            items = items.filter((item) => {
                return item.lineOptionalTags == option
            })
            var total = 0 
            
            items.forEach((item) => {
                total = total + parseFloat(item.lineTotalSell)
                console.log(total)
            })

            return total
            
        },
        getQuoteOptions() {
            var t = this

            var quote = this.item

            var options = quote.quoteOptionalTags.split(',');

            options.forEach((item, index) => {
                if (item === '') {
                    options.splice(index, 1)
                }
            })

            t.quoteOptions = options
        },
        // Print Quote
        printQuote() {
            window.print();
        },
        // Get Line Items
        async getLineItems() {
            let itemsResult = await this.MIX_redisOrderedSearch(this.index, this.searchQuery, this.itemsFrom, this.itemsTo, 'lineOrder');
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents.map((x) => x.value).sort((a,b) => a.lineOrder - b.lineOrder)
            }
        },
        // * READ ITEM
        async readItem() {
            let t = this;
            let result = await t.MIX_readItem(t.quoteId, 'order');
            if (result.code === 1) {
                this.item = result.data;
            }
        },
        updateQuoteFeatures() {
            let quoteFeatures = this.$route.query.quotefeatures;
            console.log('quoteFeatures = ' + quoteFeatures);
            if (quoteFeatures !== '' && quoteFeatures !== undefined && quoteFeatures !== null) {
                this.toggleQuoteFeatures = quoteFeatures.split(',');
            } else {
                this.toggleQuoteFeatures = this.quotefeatures;
            }
            console.log('this.toggleQuoteFeatures = ' + this.toggleQuoteFeatures);
        }, 
        async updateEmail() {
            var t = this; 

            var userId = t.item.ownerUserId

            var user = await t.MIX_readItem(userId, 'user')

            t.updatedEmailAddress = user.data.userEmail
        }
    },
    async created() {
        let t = this;
        let companyDetailsResult = await this.MIX_readItem("details", "company")
		this.companyDetails = companyDetailsResult.data;

        let quoteTermOptionsResult = await this.MIX_readItem("options", "quoteTerms")
		this.quoteTermOptions = quoteTermOptionsResult.data;
        
        if (this.quoteid === '') {
            this.quoteId = this.$route.params.id;
        } else {
            this.quoteId = this.quoteid;
        }
        this.item = schema.order;
        this.item = { ...this.datadown };
        await this.readItem();
        await this.getLineItems();
        this.updateQuoteFeatures();
        this.getQuoteOptions()
        await this.updateEmail()
        t.loading = false;
    },
};
</script>

<style scoped>
table {
    border-collapse: collapse;
}
th {
    padding: 5px !important;
}
td {
    padding: 5px !important;
}
noScrollBar {
    overflow-y: none !important;
}
</style>



<style>
@media print {
    @page {size: landscape}
    .noprint {
        display: none !important;
        visibility: hidden !important;
        max-height: 0;
    }
    html {
        overflow-y: hidden !important;
    }
    table { page-break-after:auto }
    table tr    { page-break-inside:avoid; page-break-after:auto }
    table td    { page-break-inside:avoid; page-break-after:auto }
}


</style>
