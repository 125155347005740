<template>
  <div class="" style="padding: 0px !important; margin: 0px !important; width: 99% !important">
    <v-row class="apptext--text" dense style="z-index: 0 !important">
      <!-- Main Content -->
      <v-col cols="12" class=" align-start rounded">
        <!-- //* VIEW MODE -->
        <!-- <pre>{{ datadown.id }}</pre> -->
        <!-- <pre>{{ items }}</pre> -->
        <v-row class="pt-2 " style="width: 100% !important" no-gutters>
          <v-col cols="12">
            <v-card class="elevation-0 grey lighten-5 pr-3"
                    style="height: 55vh !important; overflow-y: scroll !important ">
              <div v-for="(item, i) in computedItems" :key="i">
                <contactCard class="mb-2 ml-3" style="" :orgIsGroup="orgIsGroup" :actionLead="true"
                             :datadown="item"></contactCard>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import contactCard from '@/views/sales/ContactCard.vue'
// import companiesHouse from '@/views/organisations/companiesHouse.vue';
export default {
  name: 'OrganisationDetails',
  components: {
    // 'companies-house': companiesHouse,
    'contactCard': contactCard,
  },
  props: {
    datadown: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dense: {
      type: Boolean,
      default: false,
    },
    itemnew: {
      type: Boolean,
      default: false,
    },
    listshrunk: {
      type: Boolean,
      default: false,
    },
    orgtype: {
      type: String,
      default: '',
    },
    orgIsGroup: {
      type: Boolean,
      default: false,
    },
    organisations: {
      type: Array,
      default: () => [],
    }
  },
  // * DATA
  data: () => ({
    // Item
    key: 'contact',
    index: 'idx:contacts',
    items: [],
    itemsTotal: null,
    searchQuery: '',
    itemsFrom: 0,
    itemsTo: 9,
    datatableOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['contactFullName'],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    },
    sortBy: '@contactFullName',

    // Headers to display in the Data Table
    headers: [
      {text: 'Full Name', value: 'contactFullName', sortable: false, hidden: false, expanded: false},
      {text: 'First Name', value: 'contactFirstName', sortable: false, hidden: false, expanded: false},
      {text: 'Last Name', value: 'contactLastName', sortable: false, hidden: false, expanded: false},
      {text: 'Email', value: 'contactEmail', sortable: false, hidden: false, expanded: false},
      {text: 'Phone', value: 'contactMobilePhone', sortable: false, hidden: false, expanded: false},
      {text: 'Job Title', value: 'contactJobTitle', sortable: false, hidden: false, expanded: false},
      {text: 'Job Level', value: 'contactJobLevel', sortable: false, hidden: false, expanded: true},
      {text: 'Department', value: 'contactJobDepartment', sortable: false, hidden: false, expanded: true},
      {text: 'Organisation', value: 'orgName', sortable: false, hidden: false, expanded: false},
      {text: 'Organisation', value: 'orgId', sortable: false, hidden: false, expanded: false},
    ],

  }),
  computed: {
    ...mapGetters({
      GET_dense: 'GET_dense',
    }),
    computedFields() {
      let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
      if (!computedFields.includes('@id')) {
        computedFields.push('@id');
      }
      if (!computedFields.includes('@deleted')) {
        computedFields.push('@deleted');
      }
      if (!computedFields.includes('@actionId')) {
        computedFields.push('@actionId');
      }
      computedFields = computedFields.join(',');
      return computedFields;
    },
    computedItems() {
      // var t = this;

      var items = this.items

      // items = items.filter((item) => {
      //     return item.orgId == t.datadown.id
      // })


      return items
    },
    computedSearchQuery() {
      var t = this;
      let searchQuery = this.searchQuery;
      var orgs = ''

      // if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
      //     if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
      //         searchQuery = '*';
      //     } else {
      searchQuery = '@deleted:0';
      //     }
      // } else {
      //     if (searchQuery.startsWith('@')) {
      //         if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
      //             searchQuery = `${searchQuery}*`;
      //         } else {
      //             searchQuery = `${searchQuery}*  @deleted:0`;
      //         }
      //     } else {
      //         if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
      //             searchQuery = `${searchQuery}*`;
      //         } else {
      //             searchQuery = `${searchQuery}*  @deleted:0`;
      //         }
      //     }
      // }
      // Group Level Filter
      if (this.orgIsGroup == true && this.organisations.length > 0) {
        orgs = this.organisations
        orgs = orgs.map((y) => y.id).join("|")
        searchQuery = `${searchQuery} @orgId:{${orgs}}`;
      } else {
        searchQuery = `${searchQuery} @orgId:{${t.datadown.id}}`
      }
      //console.log('searchQuery = ' + searchQuery)

      // Page Filters
      return searchQuery;
    },
  },
  // * METHODS
  methods: {
    // * GET ITEMS
    async getItems() {
      //console.log('getting contacts ')
      let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
      if (itemsResult.code === 1) {
        this.itemsTotal = itemsResult.data.total;
        this.items = itemsResult.data.results;
        let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
        if (itemsTo < this.itemsTotal) {
          this.itemsTo = itemsTo;
        } else {
          this.itemsTo = this.itemsTotal;
        }
      } else {
        //// ////console.log("items = " + JSON.stringify(itemsResult, null, 2));
      }
    },
  },
  // * WATCH
  watch: {
    datatableOptions: {
      handler() {
        this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
        let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
        if (itemsTo < this.itemsTotal) {
          this.itemsTo = itemsTo;
        } else {
          this.itemsTo = this.itemsTotal;
        }
        this.getItems();
      },
      deep: true,
    },
    datadown() {
      const t = this;
      t.getItems()
      // // ////console.log("item customer = " + JSON.stringify(t.item, null, 2))
    },
    organisations() {
      var t = this;
      t.getItems()
    }
  },
  // * CREATED
  async created() {
    // let t = this;
    this.getItems();

  },
};
</script>

<style></style>
