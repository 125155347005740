<template>
	<div class="" style="padding: 0px !important; margin: 0px !important;  height: calc(100vh - 150px) !important; overflow: scroll !important">
		<!-- Menu Buttons -->
		<v-row class="apptext--text pt-3 px-3" dense>
			<v-col cols="12" class="d-flex align-center justify-start py-4">
                <VueEditor class="white" v-model="item.leadNotes" :editorToolbar="customToolbar" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <pre>{{ item }}</pre>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor";
    export default {
        // * COMPONENTS
        components: {
            VueEditor
        },
        // * PROPS
        props: {
			datadown: {
				type: Object,
				default: () => {
					return {};
				},
			},

        },
        // * DATA
        data: () => ({
            item: {},
            customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ]
        }),
        // * WATCH
		watch: {
			datadown: {
				handler: function () {
					this.item = { ...this.datadown };
					if (this.item.leadStatus !== "") {
						this.selectedLeadStatus = this.item.leadStatus.split(",");
					}
                }
            }
        },
        // * CREATED
        created() {
            let t = this;
            t.item = { ...t.datadown };
        }
    }
</script>