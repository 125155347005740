<template>
    <div class="grey lighten-3">
        <!--style="height: calc(100vh - 150px) !important; overflow: scroll !important"-->
        <v-row class="" dense>
            <v-col cols="12" sm="12" class="">
                <div class="d-flex align-center primary pa-4">
                    <div class="mx-2">
                        <v-icon class="white--text">icons8-hotel-building</v-icon>
                    </div>
                    <div class="text-left white--text text-h5 font-weight-light">Companies House Lookup</div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="mx-2" dense>
            <v-col cols="12" xs="12" sm="12">
                <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                <div class="body-2">Use the search below to find the company on companies house, click the tick to add the Registered Name and Number to this Organisation.</div>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
                <v-text-field background-color="white" outlined dense v-model="search" hide-details="auto" />
            </v-col>
            <v-col cols="12" xs="12" sm="8">
                <v-btn depressed class="accent secondary--text" @click="lookupCompany()">Search</v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table :headers="headers" :items="companies" :items-per-page="5" class="elevation-0">
                    <template v-slot:top>
                        <div class="d-flex align-center" style="width: 100% !important">
                            <!-- <div class="py-5 pl-3 body-2">
                                Showing <strong>{{ itemsFrom + 1 }}</strong>
                                <span v-if="itemsTo !== itemsTotal">
                                    to <strong>{{ itemsTo }}</strong></span
                                >
                                of <strong>{{ itemsTotal }}</strong>
                            </div> -->
                        </div>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-icon class="primary--text ml-5" @click="updateCH(item)">icons8-checked-checkbox</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    // TODO - RG - Add Paging to the results
    export default {
        props: {
            datadown: {
                Type: String,
                Default: '',
            },
        },
        data: () => ({
            search: '',
            companies: [],
            headers: [
                { text: 'Registered Name', value: 'title' },
                { text: 'Address', value: 'address_snippet' },
                { text: 'Company Number', value: 'company_number' },
                { text: 'Action', value: 'action' },
            ],
        }),
        watch: {
            datadown: {
                handler: function () {
                    this.search = this.datadown;
                },
                deep: true,
            },
        },
        methods: {
            async lookupCompany() {
                let companySearchResult = await this.$axios.get(`${process.env.VUE_APP_MOFSERVER}/companieshouse/search/companies/?q=${this.search}&items_per_page=10&start_index=0&restrictions=active-companies`);
                if (companySearchResult.data.code === 1) {
                    this.companies = [];
                    this.companies = companySearchResult.data.data.items;
                }
            },
            updateCH(item) {
                console.log('itemm = ', JSON.stringify(item, null, 2))
                this.$emit("updatech", { "registeredName": item.title, "registrationNumber": item.company_number });
            },
            closeItem() {
                this.$emit("closech");
            },
        },
        mounted() {
            this.search = this.datadown;
            this.lookupCompany();
        },
    };
</script>