<template>
    <div class="grey lighten-3">
        <!--style="height: calc(100vh - 150px) !important; overflow: scroll !important"-->
        <v-row class="" dense>
            <v-col cols="12" sm="12" class="">
                <div class="d-flex align-center primary pa-4">
                    <div class="mx-2">
                        <v-icon class="white--text">icons8-map</v-icon>
                    </div>
                    <div class="text-left white--text text-h5 font-weight-light">Add New Address</div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="mx-2 pb-5" dense>
            <v-col cols="12">
                <form>
                    <v-row dense>
                        <v-col cols="12" sm="6" class="d-flex align-center">
                            <div class="text-left primary--text text-h6 font-weight-light">Add Address</div>
                        </v-col>
                        <v-col cols="12">
                            <div class="body-2">Use the search below to find an address.</div>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-divider class="grey lighten-1 mb-1"></v-divider>
                        </v-col>
                        <v-col cols="12" xs="12" md="12">
                            <vuetify-google-autocomplete autocomplete="donotcomplete" dense outlined background-color="white" label="Find an address" name="myapp-name" ref="addressInput" types="" id="map" classname="form-control" v-on:placechanged="getAddressData" country="gb" clearable> </vuetify-google-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="Address Name" v-model="item.addressName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('addressLine1')"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-autocomplete label="Address Type" v-model="item.addressType" :items="addressTypes" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('addressType')"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="Address Line 1" v-model="item.addressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('addressLine1')"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="Address Line 2" v-model="item.addressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('addressLine2')"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="Address Line 3" v-model="item.addressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('addressLine3')"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="Town" v-model="item.addressTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('town')"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="County" v-model="item.addressCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('county')"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="Postcode" v-model="item.addressPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('postcode')"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="Country" v-model="item.addressCountry" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('country')"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="Lattitude" v-model="item.addressLat" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('lat')"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="Longitude" v-model="item.addressLng" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('lng')"></v-text-field>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-row>
        <!-- Save / Cancel Buttons -->
        <v-row dense>
            <v-col cols="12" class="mt-2 grey darken-1 pa-3 pr-4 d-flex justify-right align-center">
                <v-spacer />
                <div>
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <div class="ml-2">
                    <v-btn class="accent primary--text font-weight-bold" depressed @click="saveItem(item)"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        activeitem: {
            Type: Object,
            Default: null,
        },
    },
    // * DATA
    data: () => ({
        key: 'address',
        itemNew: true,
        schema: {},
        org: {},
        // dropdowns
        addressTypes: [],

        // new address object
        item: {},
    }),
    methods: {
        updateItem() {
            this.$emit('update', this.item);
        },
        getAddressData: function (addressData, placeResultData) {
            let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
            this.item.addressName = placeResult.name;
            this.item.addressLine1 = placeResult.addressLine1;
            this.item.addressLine2 = placeResult.addressLine2;
            this.item.addressLine3 = placeResult.addressLine3;
            this.item.addressTown = placeResult.town;
            this.item.addressCounty = placeResult.county;
            this.item.addressPostcode = placeResult.postcode;
            this.item.addressCountry = placeResult.country;
            this.item.addressLat = placeResult.lat;
            this.item.addressLng = placeResult.lng;
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem(item) {
            let t = this;
            // if no id then generate one
            if (item.id !== undefined && item.id !== null && item.id !== '') {
                // do nothing
            } else {
                item.id = t.MIX_generateId();
            }
			// console.log('this.key = ' + this.key)
			// console.log('this.itemNew = ' + this.itemNew)
			// console.log('item = ' + JSON.stringify(item, null, 2))
            let result = await this.MIX_createItem(item, this.key, this.itemNew);
			// console.log('result = ' + JSON.stringify(result, null, 2))
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
            }
            this.item = this.schema;
            this.$emit('close');
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                let t = this;
                this.org = { ...this.datadown };
                if (JSON.stringify(t.activeitem) === '{}') {
                    t.item = { ...t.schema };
                    this.item.orgId = this.org.id;
                    this.item.orgName = this.org.orgName;
                } else {
                    t.item = { ...t.activeItem };
                }
            },
            deep: true,
        },
        activeitem: {
            handler: function () {
                let t = this;
                t.item = { ...t.activeitem };
            }, deep: true
        }
    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.address;
        t.org = { ...t.datadown };
        if (JSON.stringify(t.activeitem) === '{}') {
            t.item = { ...t.schema };
            t.item.orgId = t.org.id;
            t.item.orgName = t.org.orgName;
        } else {
            t.item = { ...t.activeitem };
        }
        let addressTypes = await t.MIX_redisReadSet('set:addressTypes', false);
        if (addressTypes.code === 1) {
            t.addressTypes = addressTypes.data;
        }
    },
    mounted() {},
};
</script>