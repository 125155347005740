<template>
	<v-row class="pa-0 ma-0" fill-height>
		<v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="pb-3 px-3 pt-3 secondary">
			<v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
				<div class="mx-3"><v-icon class="white--text" :large="!GET_dense">icons8-city</v-icon></div>
				<div v-if="item.leadTitle.length > 40">
					<v-tooltip top :max-width="200">
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on" class="tooltiptext tooltip font-weight-light"><strong>Lead:</strong>{{ item.leadTitle.substring(0, 40) }}...</span>
						</template>
						<span>{{ item.leadTitle }}</span>
					</v-tooltip>
				</div>
				<div v-else-if="item.leadTitle.length <= 40">
					<span class="font-weight-light"><strong>Lead: </strong>{{ item.leadTitle }}</span>
				</div>

				<div class="ml-2 font-weight-light" v-if="item.leadTitle === ''">New</div>

				<v-spacer></v-spacer>
				<div>
					<v-btn class="ml-2 white--text" plain :large="!GET_dense" @click="closeItem" depressed>
						<v-icon>icons8-multiply</v-icon>
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<!-- Tabs -->
		<v-col v-if="$vuetify.breakpoint.mdAndUp" class="pa-0" fill-height cols="auto" style="width: 100% !important">
			<v-tabs background-color="white" v-model="tab" show-arrows>
				<v-tab href="#details">Details</v-tab>
				<v-tab href="#leadnotes">Notes</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab" fill-height>
				<!-- Details Tab -->
				<v-tab-item value="details" class="grey lighten-3">
					<lead-details dense :datadown="item" :itemnew="itemNew" v-on:save="saveItem" v-on:cancel="cancelItem" />
				</v-tab-item>
				<!-- Notes Tab -->
				<v-tab-item value="leadnotes" class="grey lighten-3">
					<lead-notes dense :datadown="item" :itemnew="itemNew" v-on:save="saveItem" v-on:cancel="cancelItem" />
				</v-tab-item>
			</v-tabs-items>
		</v-col>

        <!-- Mobile Tabs -->
        <v-row no-gutters v-if="$vuetify.breakpoint.smAndDown">
			<lead-details dense :datadown="item" :itemnew="itemNew" v-on:save="saveItem" v-on:close="closeItem" v-on:cancel="cancelItem" />
        </v-row>
	</v-row>
</template>

<script>
	import { mapGetters } from "vuex";
	import leadDetails from "@/views/leads/leadDetails.vue";
	import leadNotes from "@/views/leads/leadNotes.vue";
	import schema from "@/schema.js";
	// import quoteList from "@/views/quotes/quoteList.vue";
	// import organisationContacts from "@/views/organisations/organisationContacts.vue";
	// import contactList from "@/views/contacts/contactList.vue"

	export default {
		// * COMPONENTS
		components: {
			// "organisation-contacts": organisationContacts,
			"lead-details": leadDetails,
			"lead-notes": leadNotes,
			// "quote-list": quoteList,
			// "contact-list": contactList
		},
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			key: "lead",
			tab: "details",
			schema: null,
			// Item
			item: {},
			itemNew: false,
			editMode: false,
			// Validation
			rules: {},
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_taskBar: "GET_taskBar",
				GET_dense: "GET_dense",
				GET_destroy: "GET_destroy",
			}),
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				if (JSON.stringify(t.datadown) !== "{}") {
					this.readItem(t.datadown.id);
				} else {
					t.itemNew = true;
					t.editMode = true;
					t.item = { ...t.schema };
					t.item.id = t.MIX_generateId();
				}
			},
			// * VALIDATION RULES
			validateItem() {
				let t = this;
				t.rules = {
					// GENERAL DETAILS
					orgName: [(v) => !!v || "Organisation Name Required"],
					// orgRegisteredName: [ v => !!v || 'Registered Name Required'],
					// orgRegistrationNumber: [ v => !!v || 'Registration Number Required'],
					// orgTelephone: [ v => !!v || 'Telephone Required'],
					// orgWebsite: [ v => !!v || 'Website Required'],
					// orgEmail: [ v => !!v || 'Email Required'],
					// orgInvoiceEmail: [ v => !!v || 'Invoice Email Required'],
					// orgPurchasingEmail: [ v => !!v || 'Purchasing Email Required'],

					// ADDRESS DETAILS
					// orgAddressType: [ v => !!v || 'Address Type Required'],
					// orgAddressLine1: [ v => !!v || 'Address Line 1 Required'],
					// orgAddressLine2: [ v => !!v || 'Address Line 2 Required'],
					// orgAddressLine3: [ v => !!v || 'Address Line 3 Required'],
					// orgTown: [ v => !!v || 'Town Required'],
					// orgCounty: [ v => !!v || 'County Required'],
					// orgPostcode: [ v => !!v || 'Postcode Required'],

					// OTHER ADDRESS DETAILS
					// orgOtherAddressType: [ v => !!v || 'Address Type Required'],
					// orgOtherAddressLine1: [ v => !!v || 'Address Line 1 Required'],
					// orgOtherAddressLine2: [ v => !!v || 'Address Line 2 Required'],
					// orgOtherAddressLine3: [ v => !!v || 'Address Line 3 Required'],
					// orgOtherTown: [ v => !!v || 'Town Required'],
					// orgOtherCounty: [ v => !!v || 'County Required'],
					// orgOtherPostcode: [ v => !!v || 'Postcode Required'],

					// ORG INFO
					// orgEmployees: [ v => !!v || 'No of Employees Required'],
					// orgIndustry: [ v => !!v || 'Industry Required'],
					// orgSector: [ v => !!v || 'Sector Required'],
					// orgAccountNumber: [ v => !!v || 'Account Number Required'],
					//orgAccountTypes: [ v => !!v || 'Account Type Required'],
					// orgLocalAuthority: [ v => !!v || 'Local Authority Required'],
					// orgTurnover: [ v => !!v || 'Turnover Required'],

					// OWNER / SOURCE INFO
					// orgAccountOwner: [ v => !!v || 'Account Owner Required'],
					// orgSource: [ v => !!v || 'Source Required'],
					// orgSourceName: [ v => !!v || 'Source Name Required'],

					// SOCIAL INFO
					// orgTwitter: [ v => !!v || 'Twitter Required'],
					// orgFacebook: [ v => !!v || 'Facebook Required'],
					// orgLinkedIn: [ v => !!v || 'LinkedIn Required'],
					// orgInstagram: [ v => !!v || 'Instagram Required'],
				};

				// t.$nextTick(() => {
				//     if (t.$refs.form.validate()) {
				//         this.createItem()
				// 		this.editMode = false;
				//     } else {
				//         this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Fill out all the fields correctly in order to proceed.', show: true })
				//     }
				// })
			},
			// * CREATE ITEM
			async createItem(item) {
				let t = this;
				t.item.id = t.item.orgName.slice(0, 11).toUpperCase().replace(/ /g, "").trim();
				if (t.item.id.length < 10) {
					t.item.id = t.item.id + new Array(9 - t.item.id.length).fill("0").join("") + "1";
				}
				
				let result = await this.MIX_createItem(this.item, this.key, this.itemNew);
				if (result.code === 1) {
					this.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					this.readItem(item.id);
					t.$emit("refreshitems")

				}
			},
			// * READ ITEM
			async readItem(itemId) {
				let t = this;
				let result = await t.MIX_readItem(itemId, t.key);
				if (result.code === 1) {
					this.item = result.data;
					t.itemNew = false;
					t.loading = false;
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * DELETE ITEM
			async deleteItem() {
				let result = await this.MIX_deleteItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// Save Item
			async saveItem(item) {
				console.log('############################### Save item ')
				//console.log('itemdddddddd = ' + JSON.stringify(item))
				let result = await this.MIX_createItem(item, this.key, this.newItem);
				if (result.code === 1) {
					this.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				}
			},
			// Close Item
			closeItem() {
				this.$emit("closeitem");
			},
			cancelItem() {
				this.readItem(this.item.id);
			}
		},
		// * WATCH
		watch: {
			// Datadown Changes
			datadown() {
				this.initialiseItem();
			},
		},
		// * CREATED
		async created() {
			let t = this;
			t.loading = true;
			t.schema = schema.lead;
			t.initialiseItem();
		},
		// * MOUNTED
		async mounted() {},
	};
</script>

<style scoped>
	.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
		height: 22px;
	}
</style>
