<template>
  <!-- <v-card height="100%" class="d-flex flex-column"> -->

  <v-row class="pa-0 ma-0" style="padding-right:50px !important" fill-height>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-row height="100%">
          <v-col class="pa-0 ma-0 grey lighten-5" cols="3">
            <div>
              <!-- <pre>OrgIsGroup: {{ orgIsGroup }}</pre> -->
              <v-row dense class="pt-4">
                <v-col cols="12" class="d-flex align-center flex-column">
                  <!-- <span class="font-weight-light d-flex flex-column justify-center align-center"> -->

                  <v-avatar v-if="org.orgName && org.orgName !== ''"
                            color="text-h4 primary white--text font-weight-bold" size="150">
                    {{ org.orgName.match(/\b(\w)/g).join('') }}
                  </v-avatar>

                  <div width="100%" class="font-weight-bold d-flex align-end pt-4 primary--text">
                    {{ org.orgAccountTypes }}: <span class="font-weight-normal primary--text" v-if="org.orgName !== ''">{{
                      org.orgName
                    }}</span>
                    <v-spacer/>
                    <div v-if="orgIsGroup">
                      <v-icon @click="showFilter = !showFilter">icons8-filter</v-icon>
                    </div>
                  </div>
                  <div style="width: 100% !important" v-if="showFilter">
                    <div class="pl-3 pr-4">
                      <v-autocomplete
                          item-text="orgName"
                          item-value="id"
                          outlined
                          auto-select-first
                          v-model="selectedOrganisation"
                          label="Organisation"
                          :items="fullOrganisationList"
                          dense
                          hide-details="auto"
                          background-color="white"
                          color="primary"
                          clearable
                          @click:clear="clearSelectedOrganisation()">
                      </v-autocomplete>
                    </div>
                  </div>


                  <!-- </span> -->
                </v-col>
              </v-row>
              <!-- </div> -->
              <leadCustomerDetails :datadown="org"/>
              <div class="text-left primary--text text--darken-2 pl-3 text-h6 font-weight-light">Contacts</div>
              <div style="height: 50vh !important; overflow-y: scroll !important">
                <leadCustomerContacts :datadown="org" :orgIsGroup="orgIsGroup" :organisations="organisations"/>
              </div>
            </div>
          </v-col>

          <v-col class="pa-0 ma-0 grey lighten-3" cols="5">
            <div class=" primary--text pl-3 pt-4 text-h4 d-flex align-center font-weight-thin"
                 style="height: 5vh !important; width: 100% !important">
              Timeline
              <v-spacer/>
              <!-- <v-btn disabled depressed class="primary darken-2 white--text mr-3" @click="openItem({})" >
                  <v-icon>icons8-to-do</v-icon>
                  <span class="ml-3">New Action</span>
              </v-btn> -->
            </div>
            <div style="height: 95vh !important">
              <leadCustomerActions :orgIsGroup="orgIsGroup" :organisations="organisations" :refreshItems="refreshitems"
                                   :datadown="org"/>
            </div>
          </v-col>

          <v-col class="grey lighten-4 pa-0" cols="4">
            <div>
              <v-row no-gutters class="primary darken-2 mb-1 ml-0 pa-3">
                <v-col cols="12" class="d-flex align-start">
                  <v-btn text class="white--text" title="Log Activity" @click="logActivity()">
                    +
                    <v-icon>icons8-clock</v-icon>
                    <!-- Log Activity -->
                  </v-btn>
                  <v-btn text class="white--text" title="Add Action" @click="addAction()">
                    +
                    <v-icon>icons8-tasklist</v-icon>
                    <!-- Add Action -->
                  </v-btn>
                  <v-spacer/>
                  <v-btn @click="closeDialog()" color="white" class="white--text font-weight-bold" text>
                    Back to Customer
                    <v-icon color="white" size="20">icons8-forward</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="addingAction">
                <v-card class="elevation-0 grey lighten-4 ml-3 py-3 px-3 pl-0"
                        style="height:55vh !important; overflow-y: scroll !important" outlined>
                  <v-row dense>
                    <v-col class="d-flex align-center justify-center" cols="12" xs="12">
                      <div class="pl-1 font-weight-medium body-1 primary--text" v-if="newAction.actionCompleted === 0">
                        New Action
                      </div>
                      <div class="pl-1 font-weight-medium body-1 primary--text"
                           v-else-if="newAction.actionCompleted === 1">Log Activity
                      </div>
                      <v-spacer/>
                      <v-btn
                          @click="closeAddAction()"
                          icon
                          class="elevation-0 primary--text">
                        <v-icon>icons8-collapse-arrow</v-icon>
                      </v-btn>
                    </v-col>

                    <!-- <pre>{{ newAction }}</pre> -->

                    <v-col cols="12" xs="12">
                      <v-text-field
                          label="Subject *"
                          v-model="newAction.actionSubject"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          color="primary"
                          clearable
                          @click:clear="clearItem('actionSubject')">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <v-select
                          label="Type *"
                          v-model="newAction.actionType"
                          :items="actionTypes"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          color="primary"
                          clearable
                          :menu-props="{ top: false, offsetY: true }"
                          :attach="true"
                          @click:clear="clearItem('actionType')">
                      </v-select>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <v-select
                          label="Priority *"
                          v-model="newAction.actionPriority"
                          :items="priority"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          color="primary"
                          clearable
                          :menu-props="{ top: false, offsetY: true }"
                          :attach="true"
                          @click:clear="clearItem('actionPriority')">
                      </v-select>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y
                              max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field label="Action Date *" v-model="computedDate1" outlined dense hide-details="auto"
                                        background-color="white" color="primary" v-bind="attrs" v-on="on" clearable
                                        @click:clear="clearItem('actionStartDate')"></v-text-field>
                        </template>
                        <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1"
                                       @input="dateMenu1 = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <v-select
                          label="Status"
                          v-model="newAction.actionStatus"
                          :items="status"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          color="primary"
                          clearable
                          :menu-props="{ top: false, offsetY: true }"
                          :attach="true"
                          @click:clear="clearItem('actionStatus')">
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row v-if="newAction.actionType === 'Call'" dense>
                    <v-col cols="12" class="d-flex align-center">
                      <div class="text-left primary--text text-h6 font-weight-light">Call Related</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <v-select label="Call Type *" v-model="newAction.callType" :rules="rules.callType"
                                :items="callType" required outlined dense hide-details="auto" background-color="white"
                                color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true"
                                @click:clear="clearItem('callType')"></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <v-select label="Call Duration *" v-model="newAction.callDuration" :rules="rules.callDuration"
                                :items="minutes" required outlined dense hide-details="auto" background-color="white"
                                color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true"
                                @click:clear="clearItem('callDuration')"></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <v-select label="Call Purpose *" v-model="newAction.callPurpose" :rules="rules.callPurpose"
                                :items="callPurpose" required outlined dense hide-details="auto"
                                background-color="white" color="primary" clearable
                                :menu-props="{ top: true, offsetY: true }" :attach="true"
                                @click:clear="clearItem('callPurpose')"></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <v-select label="Call Result *" v-model="newAction.callResult" :rules="rules.callResult"
                                :items="callResult" required outlined dense hide-details="auto" background-color="white"
                                color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true"
                                @click:clear="clearItem('callResult')"></v-select>
                    </v-col>
                  </v-row>

                  <v-row v-if="newAction.actionType === 'Meeting'" dense>
                    <v-col cols="12" class="d-flex align-center">
                      <div class="text-left primary--text text-h6 font-weight-light">Meeting Related</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <!-- <v-select label="Meeting Host" v-model="newAction.actionMeetingHost" :rules="rules.actionMeetingHost" :items="users" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionMeetingHost')"></v-select> -->

                      <!-- <pre>{{ users }}</pre> -->

                      <v-autocomplete label="Meeting Host" v-model="selectedHost" :rules="rules.actionEventHost"
                                      :items="users" item-text="ownerUserName" auto-select-first return-object outlined
                                      dense hide-details="auto" background-color="white" color="primary" clearable
                                      @click:clear="clearSelectedOwner()">
                        <template v-slot:selection="{ item }">
                          {{ item.ownerUserName }}
                          <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                        </template>
                        <template v-slot:item="{ item }">
                          {{ item.ownerUserName }}
                          <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <v-select label="Meeting Type" v-model="newAction.actionMeetingType"
                                :rules="rules.actionMeetingType" :items="meetingTypes" required outlined dense
                                hide-details="auto" background-color="white" color="primary" clearable
                                :menu-props="{ top: true, offsetY: true }" :attach="true"
                                @click:clear="clearItem('actionMeetingType')"></v-select>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <!-- <v-select label="Meeting Participants" v-model="newAction.actionMeetingParticipants" :rules="rules.actionMeetingParticipants" :items="contacts" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionMeetingParticipants')"></v-select> -->


                      <v-combobox multiple small-chips hide-selected label="Meeting Participants"
                                  v-model="selectedEventParticipants" :search-input.sync="participantsAutocomplete"
                                  @change="participantsAutocomplete = ''" :items="contacts" outlined dense
                                  hide-details="auto" background-color="white" color="primary"
                                  :menu-props="{ top: false, offsetY: true }" :attach="true" clearable
                                  @click:clear="clearItem('quoteChannel')">
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                          <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                                        <span class="pr-2">
                                                            {{ item }}
                                                        </span>
                            <v-icon small @click="parent.selectItem(item)"> $delete</v-icon>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          {{ item.contactUserName }}
                          <div class="ml-2 caption grey--text">{{ item.contactUserEmail }}</div>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center">
                      <div class="text-left primary--text text-h6 font-weight-light">Meeting Address</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-0">
                      <vuetify-google-autocomplete prepend-icon="icons8-google" autocomplete="donotcomplete" dense
                                                   outlined background-color="white" label="Meeting Location"
                                                   name="myapp-name" ref="addressInput" types="" id="map"
                                                   classname="form-control" v-on:placechanged="getAddressDataMeeting"
                                                   country="gb" clearable></vuetify-google-autocomplete>
                    </v-col>
                    <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Address Line 1" v-model="newAction.meetingAddressLine1"
                                    :rules="rules.meetingAddressLine1" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('meetingAddressLine1')"></v-text-field>
                    </v-col>
                    <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Address Line 2" v-model="newAction.meetingAddressLine2"
                                    :rules="rules.meetingAddressLine2" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('meetingAddressLine2')"></v-text-field>
                    </v-col>
                    <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Address Line 3" v-model="newAction.meetingAddressLine3"
                                    :rules="rules.meetingAddressLine3" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('meetingAddressLine3')"></v-text-field>
                    </v-col>
                    <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Town" v-model="newAction.meetingAddressTown"
                                    :rules="rules.meetingAddressTown" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('meetingAddressTown')"></v-text-field>
                    </v-col>
                    <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                      <v-text-field label="County" v-model="newAction.meetingAddressCounty"
                                    :rules="rules.meetingAddressCounty" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('meetingAddressCounty')"></v-text-field>
                    </v-col>
                    <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Postcode" v-model="newAction.meetingAddressPostcode"
                                    :rules="rules.meetingAddressPostcode" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('meetingAddressPostcode')"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="newAction.actionType === 'Event'" dense>
                    <v-col cols="12" class="d-flex align-center">
                      <div class="text-left primary--text text-h6 font-weight-light">Event Related</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <!-- <v-select label="Event Host" v-model="item.actionEventHost" :rules="rules.actionEventHost" :items="users" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionEventHost')"></v-select> -->
                      <v-autocomplete label="Event Host" v-model="selectedHost" :rules="rules.actionEventHost"
                                      :items="users" item-text="ownerUserName" auto-select-first return-object outlined
                                      dense hide-details="auto" background-color="white" color="primary" clearable
                                      @click:clear="clearSelectedOwner()">
                        <template v-slot:selection="{ item }">
                          {{ item.ownerUserName }}
                          <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                        </template>
                        <template v-slot:item="{ item }">
                          {{ item.ownerUserName }}
                          <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <v-combobox multiple small-chips hide-selected label="Event Participants"
                                  v-model="selectedEventParticipants" :search-input.sync="participantsAutocomplete"
                                  @change="participantsAutocomplete = ''" :items="contacts" outlined dense
                                  hide-details="auto" background-color="white" color="primary"
                                  :menu-props="{ top: false, offsetY: true }" :attach="true" clearable
                                  @click:clear="clearItem('quoteChannel')">
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                          <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                                        <span class="pr-2">
                                                            {{ item }}
                                                        </span>
                            <v-icon small @click="parent.selectItem(item)"> $delete</v-icon>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          {{ item.contactUserName }}
                          <div class="ml-2 caption grey--text">{{ item.contactUserEmail }}</div>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <v-select label="Event Type" v-model="newAction.actionEventType" :rules="rules.actionEventType"
                                :items="eventTypes" required outlined dense hide-details="auto" background-color="white"
                                color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true"
                                @click:clear="clearItem('actionEventType')"></v-select>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center">
                      <div class="text-left primary--text text-h6 font-weight-light">Event Address</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-0">
                      <vuetify-google-autocomplete prepend-icon="icons8-google" autocomplete="donotcomplete" dense
                                                   outlined background-color="white" label="Event Location"
                                                   name="myapp-name" ref="addressInput" types="" id="map"
                                                   classname="form-control" v-on:placechanged="getAddressDataEvent"
                                                   country="gb" clearable></vuetify-google-autocomplete>
                    </v-col>
                    <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Address Line 1" v-model="newAction.eventAddressLine1"
                                    :rules="rules.eventAddressLine1" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('eventAddressLine1')"></v-text-field>
                    </v-col>
                    <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Address Line 2" v-model="newAction.eventAddressLine2"
                                    :rules="rules.eventAddressLine2" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('eventAddressLine2')"></v-text-field>
                    </v-col>
                    <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Address Line 3" v-model="newAction.eventAddressLine3"
                                    :rules="rules.eventAddressLine3" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('eventAddressLine3')"></v-text-field>
                    </v-col>
                    <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Town" v-model="newAction.eventAddressTown" :rules="rules.eventAddressTown"
                                    outlined dense hide-details="auto" background-color="white" color="primary"
                                    clearable @click:clear="clearItem('eventAddressTown')"></v-text-field>
                    </v-col>
                    <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                      <v-text-field label="County" v-model="newAction.eventAddressCounty"
                                    :rules="rules.eventAddressCounty" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('eventAddressCounty')"></v-text-field>
                    </v-col>
                    <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                      <v-text-field label="Postcode" v-model="newAction.eventAddressPostcode"
                                    :rules="rules.eventAddressPostcode" outlined dense hide-details="auto"
                                    background-color="white" color="primary" clearable
                                    @click:clear="clearItem('eventAddressPostcode')"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="newAction.actionType === 'Case'" dense>
                    <v-col cols="12" class="d-flex align-center">
                      <div class="text-left primary--text text-h6 font-weight-light">Case Related</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <v-select label="Case Type" v-model="newAction.actionCaseType" :rules="rules.actionCaseType"
                                :items="caseTypes" required outlined dense hide-details="auto" background-color="white"
                                color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true"
                                @click:clear="clearItem('actionCaseType')"></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="12">
                      <v-btn
                          @click="saveNewTask()"
                          block
                          class="elevation-0 primary">Save
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-card>

              </v-row>

              <!-- <pre>Panel {{ panel }}</pre> -->

              <v-expansion-panels :disabled="addingAction" accordion flat class="pa-0 ma-0" :value="panel"
                                  v-model="panel">
                <!-- // TASKS  -->
                <v-expansion-panel class="pa-0 ma-0 grey lighten-4 elevation-0">
                  <v-expansion-panel-header class="px-3 py-0">
                    <div class="text-left text-h6 font-weight-light"
                         :class="addingAction ? 'grey--text' : ' primary--text text--darken-2'">Actions
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div style="height: 53vh !important; overflow-y: scroll !important">
                      <leadCustomerTasks :orgIsGroup="orgIsGroup" :organisations="organisations" :account="account"
                                         v-on:refreshItems="refreshItems" :refreshItems="refreshitems" :key="actionsKey"
                                         :datadown="org"/>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- // LEADS  -->
                <v-expansion-panel class="grey lighten-4 pa-0 ma-0" style="border-top: 1px solid grey !important">
                  <v-expansion-panel-header class="px-3 py-0">
                    <div class="text-left text-h6 font-weight-light"
                         :class="addingAction ? 'grey--text' : ' primary--text text--darken-2'">Leads
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0 ma-0">
                    <div style="height: 53vh !important; overflow-y: scroll !important">
                      <leadCustomerLeads :orgIsGroup="orgIsGroup" :organisations="organisations" :datadown="org"/>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- // OPPORTUNITIES  -->
                <v-expansion-panel class="grey lighten-4 pa-0 ma-0" style="border-top: 1px solid grey !important">
                  <v-expansion-panel-header class="px-3 py-0">
                    <div class="text-left text-h6 font-weight-light"
                         :class="addingAction ? 'grey--text' : ' primary--text text--darken-2'">Opportunities
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0 ma-0">
                    <div style="height: 53vh !important; overflow-y: scroll !important">
                      <leadCustomerOpportunities :orgIsGroup="orgIsGroup" :organisations="organisations"
                                                 :datadown="org"/>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- // TICKETS  -->
                <v-expansion-panel class="grey lighten-4 pa-0 ma-0" style="border-top: 1px solid grey !important">
                  <v-expansion-panel-header class="px-3 py-0">
                    <div class="text-left text-h6 font-weight-light"
                         :class="addingAction ? 'grey--text' : ' primary--text text--darken-2'">Tickets
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0 ma-0">
                    <div style="height: 53vh !important; overflow-y: scroll !important">
                      <leadCustomerTickets :orgIsGroup="orgIsGroup" :organisations="organisations" :datadown="org"/>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- // LICENCES  -->
                <!-- <v-expansion-panel class="grey lighten-4 pa-0 ma-0" style="border-top: 1px solid grey !important">
                    <v-expansion-panel-header class="px-3 py-0">
                        <div class="text-left text-h6 font-weight-light" :class="addingAction ? 'grey--text' : ' primary--text text--darken-2'">Licences</div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0 ma-0">
                        <div style="height: 53vh !important; overflow-y: scroll !important" class="grey--text pb-6 d-flex font-weight-bold text-h5 align-center justify-center">
                            COMING SOON
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel> -->


                <!-- // CONTRACTS  -->
                <v-expansion-panel class="grey lighten-4 pa-0 ma-0" style="border-top: 1px solid grey !important">
                  <v-expansion-panel-header class="px-3 py-0">
                    <div class="text-left text-h6 font-weight-light"
                         :class="addingAction ? 'grey--text' : ' primary--text text--darken-2'">Contracts
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0 ma-0">
                    <div style="height: 53vh !important; overflow-y: scroll !important">
                      <leadCustomerContracts :orgIsGroup="orgIsGroup" :organisations="organisations" :datadown="org"/>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

          </v-col>


        </v-row>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import leadCustomerDetails from "@/views/sales/LeadCustomerDetails.vue";
import leadCustomerActions from "@/views/organisations/organisationActivities.vue";
import leadCustomerContacts from "@/views/sales/LeadCustomerContacts.vue";
import leadCustomerLeads from "@/views/organisations/organisationLeads.vue";
import leadCustomerContracts from "@/views/sales/LeadCustomerContracts.vue";
import leadCustomerTickets from "@/views/sales/LeadCustomerTickets.vue";
import leadCustomerTasks from "@/views/organisations/organisationActions.vue";
import leadCustomerOpportunities from "@/views/organisations/organisationOpportunities.vue";
// import notes from '@/views/base/notes.vue';
import moment from 'moment';
// import packageComponents from "@/views/packages/PackageComponents.vue";
import schema from "@/schema.js";

export default {
  // * COMPONENTS
  components: {
    "leadCustomerDetails": leadCustomerDetails,
    "leadCustomerActions": leadCustomerActions,
    "leadCustomerContacts": leadCustomerContacts,
    "leadCustomerLeads": leadCustomerLeads,
    "leadCustomerContracts": leadCustomerContracts,
    "leadCustomerTickets": leadCustomerTickets,
    "leadCustomerTasks": leadCustomerTasks,
    "leadCustomerOpportunities": leadCustomerOpportunities,
    // "notes": notes,
    // "package-components": packageComponents,
  },
  // * PROPS
  props: {
    datadown: {
      type: Object,
      default: function () {
        return {};
      },
    },
    account: {
      type: Boolean,
      default: false,
    }
  },
  // * DATA
  data: () => ({
    key: "lead",
    tab: "details",
    schema: null,
    // Item
    item: {},
    org: {},
    lead: {},
    itemNew: false,
    editMode: false,
    panel: 0,
    addingAction: false,
    newAction: {},
    date1: moment().format("YYYY-MM-DD"),
    dateMenu1: false,
    priority: ['Urgent', 'High', 'Medium', 'Low'],
    actionTypes: [],
    status: ['Open', 'Pending', 'Closed'],


    callType: ['Inbound', 'Outbound', 'Missed'],
    minutes: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'],
    callPurpose: ['None', 'Prospecting', 'Administrative', 'Negotiotiation', 'Demo', 'Project', 'Desk'],
    callResult: ['None', 'Interested', 'Not Interested', 'No Response', 'Busy', 'Requested More Info', 'Requested Call Back', 'Invalid Number'],

    //meeting dropdowns
    users: [],
    contacts: [],
    meetingTypes: ['In Person', 'Remote', 'Conference Call'],
    eventTypes: ['Tradeshow', 'Exhibition', 'Other'],
    caseTypes: ['Complaint', 'Question', 'Other'],

    selectedEventParticipants: [],
    selectedContacts: [],
    selectedHost: [],
    selectedOrganisation: null,

    organisations: [],
    fullOrganisationList: [],
    rules: {},
    showEventAddress: false,
    showMeetingAddress: false,


    participantsAutocomplete: '',
    actionsKey: 0,

    refreshitems: 0,

    orgIsGroup: false,

    showFilter: false

  }),
  // * COMPUTED
  computed: {
    ...mapGetters({
      GET_taskBar: "GET_taskBar",
      GET_dense: "GET_dense",
      GET_destroy: "GET_destroy",
    }),
    computedDate1() {
      return this.$moment(this.date1, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
  },
  // * METHODS
  methods: {
    // Close Item
    closeDialog() {
      // this.item = { ...this.schema }
      this.$emit('close');
    },
    refreshItems() {
      this.refreshitems++
    },
    // * READ ITEM
    async readOrg() {
      let t = this;
      var id = t.lead
      // //////console.log("reading item org " + JSON.stringify(id, null, 2))
      let result = await t.MIX_readItem(id.orgId, 'org');
      if (result.code === 1) {
        this.org = result.data;
        // //////console.log("item = " + JSON.stringify(t.org, null, 2))
      }
    },
    async getGroupHomes() {
      let t = this;

      let getOrganisations = await t.MIX_redisSearch('idx:orgs', `@orgGroupStructure:{Home} @orgGroupId:{${t.org.id}}`, 0, 9999)
      if (getOrganisations.code === 1) {
        var organisations = [];
        organisations = getOrganisations.data.documents.map((x) => x.value);
        organisations.push(t.org)
        t.organisations = organisations

        t.fullOrganisationList = organisations
        console.log('organisations = ' + JSON.stringify(t.organisations, null, 2))
        console.log('fullOrganisationList = ' + JSON.stringify(t.fullOrganisationList, null, 2))
      }
    },
    // * READ ITEM
    async readOrgAction() {
      let t = this;
      var id = t.lead
      let result = await t.MIX_readItem(id, 'org');
      if (result.code === 1) {
        this.org = result.data;
        //////console.log('org groupStructure = ' + this.org.orgGroupStructure)
        if (this.org.orgGroupStructure == 'Group') {
          this.orgIsGroup = true
          this.getGroupHomes()
        } else {
          this.orgIsGroup = false
        }
      }
    },
    async readLead() {
      let t = this;
      var id = {...t.datadown}
      let result = await t.MIX_readItem(id.id, t.key);
      if (result.code === 1) {
        this.lead = result.data;
        t.readOrg()
      }
    },
    addAction() {
      var t = this;

      t.addingAction = true
      t.panel = ''
      t.newAction = {}
      t.newAction = {...t.schema}
      t.newAction.actionDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
    },

    logActivity() {
      var t = this;

      t.addingAction = true
      t.panel = ''
      t.newAction = {}
      t.newAction = {...t.schema}
      t.newAction.actionDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
      t.newAction.actionCompleted = 1
    },
    closeAddAction() {
      var t = this;
      t.newAction = {}
      t.panel = 0
      t.addingAction = false
    },
    getAddressDataMeeting: function (addressData, placeResultData) {
      let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
      // //////console.log(JSON.stringify(placeResult, null, 2));
      this.newAction.meetingAddressName = placeResult.name;
      this.newAction.meetingAddressLine1 = placeResult.addressLine1;
      this.newAction.meetingAddressLine2 = placeResult.addressLine2;
      this.newAction.meetingAddressLine3 = placeResult.addressLine3;
      this.newAction.meetingAddressTown = placeResult.town;
      this.newAction.meetingAddressCounty = placeResult.county;
      this.newAction.meetingAddressPostcode = placeResult.postcode;
      this.newAction.meetingAddressCountry = placeResult.country;
      this.newAction.meetingAddressLat = placeResult.lat;
      this.newAction.meetingAddressLng = placeResult.lng;
      this.showMeetingAddress = true
    },
    getAddressDataEvent: function (addressData, placeResultData) {
      let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
      this.newAction.eventAddressName = placeResult.name;
      this.newAction.eventAddressLine1 = placeResult.addressLine1;
      this.newAction.eventAddressLine2 = placeResult.addressLine2;
      this.newAction.eventAddressLine3 = placeResult.addressLine3;
      this.newAction.eventAddressTown = placeResult.town;
      this.newAction.eventAddressCounty = placeResult.county;
      this.newAction.eventAddressPostcode = placeResult.postcode;
      this.newAction.eventAddressCountry = placeResult.country;
      this.newAction.eventAddressLat = placeResult.lat;
      this.newAction.eventAddressLng = placeResult.lng;
      this.showEventAddress = true
    },

    async saveNewTask() {
      let t = this;
      let item = t.newAction;

      item.orgId = t.org.id
      item.orgName = t.org.orgName
      item.id = t.MIX_generateId();

      let result = await t.MIX_createItem(item, 'action', true);
      if (result.code === 1) {
        t.MIX_alertBox({color: "success", timeout: 2000, message: "Saved", show: true});
        // t.readItem(item.id);
        t.addingAction = false;
        t.newAction = {}
        t.newAction = {...t.schema}
        t.panel = 0
        // t.actionsKey++

        t.refreshitems++
        // t.$emit("refreshitems");
      }
    },
    clearSelectedOrganisation() {
      console.log('clearing selected org')
      this.readOrgAction()
    }
  },
  // * WATCH
  watch: {
    dateMenu1() {
      this.newAction.actionDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
    },
    // Datadown Changes
    datadown() {
      var t = this;
      if (t.account == true) {
        t.lead = t.datadown.id
        t.readOrgAction()
      } else {
        t.readLead();
      }
    },
    selectedHost: {
      handler: function () {
        this.newAction.meetingHost = this.selectedHost.ownerUserId
      }
    },
    selectedOrganisation: {
      handler: function () {
        if (this.selectedOrganisation !== null) {
          console.log('chaning selected org')
          this.organisations = this.organisations.filter((item) => {
            return item.id = this.selectedOrganisation
          })
          console.log('org legnth = ' + this.organisations.length)
        }
      },
      deep: true,
    },
    // Selected Tags
    selectedEventParticipants: {
      handler: async function () {
        // Force Title Case on Tags
        for (let i = 0; i < this.selectedEventParticipants.length; i++) {
          // this.selectedEventParticipants[i] = this.selectedEventParticipants[i].contactUserName.toLowerCase(); //replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
          this.selectedEventParticipants[i] = this.selectedEventParticipants[i].contactUserName;
          if (this.selectedEventParticipants[i] === ' ') {
            this.selectedEventParticipants.splice(i, 1);
          } else {
            this.selectedEventParticipants[i] = this.selectedEventParticipants[i].trim();
          }
        }
        // Update Quote Tags and split out array with commas
        this.newAction.actionEventParticipants = this.selectedEventParticipants.join(',');
      },
      deep: true,
    },
  },
  // * CREATED
  async created() {
    let t = this;
    // //////console.log("opening")
    //////console.log("account = " + t.account)
    if (t.account == true) {
      t.lead = t.datadown.id
      t.readOrgAction()
    } else {
      t.readLead();
    }
  },
  // * MOUNTED
  async mounted() {
    var t = this;
    t.schema = schema.action
    let actionTypes = await t.MIX_redisReadSet('set:actionTypes', false);
    if (actionTypes.code === 1) {
      t.actionTypes = actionTypes.data;
    }
    // Get Users
    let users = await this.MIX_users(''); // Add Users to Dropdown for Owner
    this.users = users.map((x) => {
      return {
        ownerUserId: x.id,
        ownerUserName: x.userName,
        ownerUserEmail: x.userEmail,
      };
    });
    // Get Users
    let contacts = await this.MIX_contacts(''); // Add Users to Dropdown for Owner
    this.contacts = contacts.map((x) => {
      return {
        contactUserId: x.id,
        contactUserName: x.contactName,
        contactUserEmail: x.contactEmail,
      };
    });
  },
};
</script>

<style scoped>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 22px;
}

.v-expansion-panel-content__wrap {
  padding: 0 0px 0px !important;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 0px 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  flex: 1 1 auto;
  max-width: 100%;
}
</style>
