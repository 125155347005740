<template>
  <v-card :class="actionContract === true ? '' : '' " flat outlined>
    <v-col cols="12" class="align-start">
      <v-row class="pa-2 pb-0">
        <v-col cols="12" class="d-flex ma-0 pa-0 pl-1 align-start">
          <!-- @click="openContract" -->
          <div>
            <!-- <pre>{{ contract }}</pre> -->
            <div class="font-weight-bold text-start body-2 text-truncate primary--text"
                 style="max-width: 300px !important">
              {{ contract.orgName }}
            </div>
            <div class="font-weight-bold text-start body-2 text-truncate primary--text"
                 style="max-width: 300px !important">
              {{ contract.contractTitle }}
            </div>
            <div class=" text-start body-2 text-truncate primary--text" style="max-width: 300px !important">
              {{ $moment(contract.contractCommencementDate, 'X').format('DD-MMM-YYYY') }} - {{ contract.contractTerm }}
              months
            </div>
          </div>
          <v-spacer/>
          <div>
            <v-avatar v-if="contract.ownerUserName" color="caption primary white--text font-weight-regular" size="30">
              {{ contract.ownerUserName.match(/\b(\w)/g).join('') }}
            </v-avatar>
          </div>
        </v-col>
      </v-row>

      <v-row class="pa-2 pt-0" :class="reveal ? 'pb-2' : ''">
        <v-col cols="12" class="d-flex ma-0 pa-0 pr-3 justify-end align-center">
          <!-- <div> -->
          <div
              v-if="contract.salesPackages !== '' && contract.salesPackages !== undefined && contract.salesPackages !== null">
            <div class="d-flex align-center">
              <div v-for="item in contract.salesPackages.split(',')" :key="item">
                <PackageIcon :packagedata="getPackageDetails(item)"/>
              </div>
            </div>
          </div>
          <v-spacer/>
          <div v-if="contract.salesType"
               class="font-weight-regular body-2 pr-3 text-start text-uppercase"
               :class="contract.salesType === 'hot' ? 'error--text' : contract.salesType === 'warm' ? 'warning--text' : contract.salesType === 'cold' ? 'info--text' : 'grey--text'"
          >
            {{ contract.salesType }}
          </div>
          <!-- <v-spacer /> -->
          <div>
            <v-icon class="grey--text" size="15" @click="reveal = !reveal">icons8-expand-arrow</v-icon>
          </div>
          <!-- </div> -->
        </v-col>
      </v-row>

      <v-card v-if="reveal === true" class="mt-3 elevation-0" style="height: 100% !important; width: 100% !important">
        <v-divider/>
        <table class="ma-0 pa-0 mt-3 caption grey--text text--darken-2 " border="0" width="100%">
          <!-- <tr>
              <td class="font-weight-bold" width="40%" align="left">Exp Close:</td>
              <td class="" width="60%" align="left">{{ MIX_formatDateTime(contract.salesEstimatedCloseDate, 'X', 'MMM-YY') }}</td>
          </tr> -->
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Owner:</td>
            <td class="" width="60%" align="left">{{ contract.ownerUserName }}</td>
          </tr>
        </table>

        <div class="d-flex justify-stretch pt-3" style="width: 100% !important;">
          <div v-if="actionContract == false" style="width: 48% !important;">
            <v-btn block depressed @click="openContract" class="caption font-weight-bold primary--text">Account</v-btn>
          </div>
          <v-spacer/>
          <div style="width: 48% !important;">
            <v-btn block depressed @click="openContractItem" class="caption font-weight-bold primary--text">Edit</v-btn>
          </div>
        </div>
      </v-card>
    </v-col>

  </v-card>
</template>

<script>
export default {
  data: () => ({
    contract: {},
    reveal: false,
    orgType: '',
  }),
  props: {
    datadown: {
      type: Object,
      default: () => ({})
    },
    actionContract: {
      type: Boolean,
      default: false,
    }
  },
  computed: {},
  methods: {
    openContract() {
      // ////console.log('openContract');
      this.$emit('opencontract', this.contract);
    },
    openContractItem() {
      this.$emit('opencontractitem', this.contract);
    }
  },
  async created() {
    // var t = this
    this.contract = {...this.datadown};
    ////console.log("contract = " + JSON.stringify(this.contract, null, 2))
  },

}
</script>

<style scoped>
.borderClass {
  border-left: 5px solid #019EAF !important;
  border-color: #019EAF !important
}
</style>
