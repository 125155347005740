<template>
	<v-row class="pa-0 ma-0" fill-height>
		<v-row no-gutters class="py-3 px-3">
			<v-col class="font-weight-bold primary--text text-h6 d-flex align-center">
				<div class="mx-3"><v-icon>icons8-tags</v-icon></div>
				<!-- <div class="text-h7" v-if="item.productName.length > 65">
					<v-tooltip top :max-width="200">
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on" class="tooltiptext tooltip">{{ item.productName.substring(0, 65) }}...</span>
						</template>
						<span>{{ item.productName }}</span>
					</v-tooltip>
				</div> -->
				<!-- <div v-else-if="item.productName.length <= 65"> -->

                <!-- <div class="grey--text" v-if="item.productStatus === 'Draft'"><v-icon class="mr-2 grey--text">icons8-filled-circle</v-icon>{{ item.productStatus }}</div>
				<div class="warning--text" v-if="item.productStatus === 'Pending'"><v-icon class="mr-2 warning--text">icons8-filled-circle</v-icon>{{ item.productStatus }}</div>
				<div class="success--text" v-if="item.productStatus === 'Live'"><v-icon class="mr-2 success--text">icons8-filled-circle</v-icon>{{ item.productStatus }}</div>
				<div class="grey--text" v-if="item.productStatus === 'Discontinued'"><v-icon class="mr-2 grey--text">icons8-filled-circle</v-icon>{{ item.productStatus }}</div>
				<div class="grey--text" v-if="item.productStatus === 'Suspended'"><v-icon class="mr-2 grey--text">icons8-filled-circle</v-icon>{{ item.productStatus }}</div>
				<div class="error--text" v-if="item.productStatus === 'Deleted'"><v-icon class="mr-2 error--text">icons8-filled-circle</v-icon>{{ item.productStatus }}</div>
				<div class="grey--text" v-if="item.productStatus === 'Archived'"><v-icon class="mr-2 grey--text">icons8-filled-circle</v-icon>{{ item.productStatus }}</div> -->

				<div v-if="JSON.stringify(item) !== '{}'">
					<span>{{ item.productName.substring(0,50) }}...</span>
				</div>
				<!-- <div class="text-h7" v-else>New Product</div> -->
				<v-spacer></v-spacer>
				<div>
					<v-btn class="ml-2 primary--text" plain small @click="$emit('closeItem')" depressed>
						<v-icon>icons8-multiply</v-icon>
					</v-btn>
				</div>
			</v-col>
		</v-row>

		<v-col class="pa-0" fill-height :cols="viewCols.cols" :xs="viewCols.xs" :sm="viewCols.sm" :md="viewCols.md" :lg="viewCols.lg" :xl="viewCols.xl">
			<v-tabs background-color="white" v-model="tab" show-arrows>
				<v-tab href="#details">Details</v-tab>
				<v-tab :disabled="item.productCode === ''" href="#images">Images</v-tab>
				<v-tab :disabled="item.productCode === ''" href="#pricing">Pricing</v-tab>
				<v-tab :disabled="item.productCode === ''" href="#categories">Categories</v-tab>
				<v-tab v-if="item.hasOptions === '1'" href="#options">Options</v-tab>
				<v-tab v-if="item.hasVariants === '1'" href="#variants">Variants</v-tab>
				<v-tab :disabled="item.productCode === ''" href="#preview">Preview</v-tab>
				<!-- <v-tab href="#json">JSON</v-tab> -->
			</v-tabs>
			<v-tabs-items v-model="tab" fill-height class="grey lighten-3">
				<!-- Product Tab -->
				<v-tab-item value="details">
					<product-details :datadown="item" dense v-on:saveitem="saveItem" :key="productUpdateKey" />
				</v-tab-item>
				<!-- Images Tab -->
				<v-tab-item value="images">
					<product-images :editmode="editMode" :productdata="item" :id="item.id" v-on:saveitem="saveItem" :key="productUpdateKey"  />
				</v-tab-item>
				<!-- Pricing Tab -->
				<v-tab-item value="pricing">
					<product-pricing :datadown="item" v-on:saveitem="saveItem" dense :key="productUpdateKey" />
				</v-tab-item>
				<!-- Categories Tab -->
				<v-tab-item value="categories">
					<product-categories :datadown="item" v-on:saveitem="saveItem" dense :key="productUpdateKey" />
				</v-tab-item>
				<!-- Variants Tab -->
				<v-tab-item value="variants">
					<product-variants v-if="!loading" :editmode="editMode" :datadown="item" dense :key="productUpdateKey" />
				</v-tab-item>
				<!-- Options Tab -->
				<v-tab-item value="options">
					<product-options v-if="!loading" :datadown="item" v-on:saveitem="saveItem" dense :key="productUpdateKey" />
				</v-tab-item>
				<!-- Preview Tab -->
				<v-tab-item value="preview">
					<product-preview v-if="!loading" :editmode="editMode" :datadown="item" dense :key="productUpdateKey" />
				</v-tab-item>
				<!-- <v-tab-item value="json">
					<pre class="body-1 text-left">{{ item }}</pre>
				</v-tab-item> -->
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
	import { mapGetters } from "vuex";
	import ProductDetails from "@/views/products/productDetails.vue";
	import ProductVariants from "@/views/products/productVariants.vue";
	import ProductImages from "@/views/products/productImages.vue";
	import ProductPricing from "@/views/products/productPricing.vue";
	import ProductOptions from "@/views/products/productOptions.vue";
	import ProductPreview from "@/views/products/productPreview.vue";
	import ProductCategories from "@/views/products/productCategories.vue";
	
	import schema from "@/schema.js";
	export default {
		// * COMPONENTS
		components: {			
			"product-details": ProductDetails,
			"product-images": ProductImages,
			"product-pricing": ProductPricing,
			"product-categories": ProductCategories,
			"product-options": ProductOptions,
			"product-preview": ProductPreview,
			"product-variants": ProductVariants,
		},
		// * PROPS
		props: {
			activeitem: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			loading: false,
			key: "product",
			tab: "details",
			editMode: false,
			// Item
			item: {},
			itemNew: false,
			productUpdateKey: 0,
			productImagesKey: 0
		}),
		computed: {
			...mapGetters({
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
			viewCols() {
				return {
					cols: 12,
					xs: 12,
					sm: 12,
					md: 12,
					lg: 12,
					xl: 12,
				};
			},
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				if (JSON.stringify(t.activeitem) !== "{}") {
					//console.log('activeitem = ' + JSON.stringify(t.activeitem))
					this.readItem();
				} else {
					t.newItem = true;
					t.editMode = true;
					t.item = { ...t.schema };
					// t.item.id = t.MIX_generateId(); // Remove and use id from product code
				}
			},
			// * CREATE ITEM
			async createItem() {
				let result = await this.MIX_createItem(this.item, this.key, this.newItem);
				if (result.code === 1) {
					this.initialiseItem();
				}
			},
			// * READ ITEM
			async readItem() {
				let t = this;
				let result = await t.MIX_readItem(t.activeitem.id, t.key);
				if (result.code === 1) {
					this.item = result.data;
					t.editMode = false;
					t.newItem = false;
					t.loading = false;
					t.productUpdateKey++
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem();
				}
			},
			// * DELETE ITEM
			async deleteItem() {
				let result = await this.MIX_deleteItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem();
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem();
				}
			},
			// * CREATE RANDOM PRODUCT DATA
			async randomProductData() {
				let t = this;
				let randomProduct = await t.MIX_randomProductGenerator();
				t.item.id = this.MIX_generateId();
				t.item.productCode = "MOF" + randomProduct["id"];
				t.item.productName = randomProduct["product_name"];
				t.item.productType = "Custom";
				t.item.productWidth = Math.floor(Math.random() * 4000);
				t.item.productHeight = Math.floor(Math.random() * 4000);
				t.item.productDepth = Math.floor(Math.random() * 4000);
				t.item.productFrontWidth = Math.floor(Math.random() * 4000);
				t.item.productBackWidth = Math.floor(Math.random() * 4000);
				t.item.productDimensions = `Width: ${t.item.productWidth}mm, Height: ${t.item.productHeight}mm, Depth: ${t.item.productDepth}mm, Front Width: ${t.item.productFrontWidth}mm, Back Width: ${t.item.productBackWidth}`;
				t.item.productDescription = t.$lorem.generateSentences(5);
				// t.selectedSupplier = {
				// 	supplierName: "Metalliform",
				// 	supplierId: "19238901238091",
				// 	supplierAccNo: "MET001",
				// };
				t.item.orgId = t.selectedSupplier.id;
				t.item.orgName = t.selectedSupplier.orgName;
				t.item.productMainImage = `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/250`;
			},
			updateOptions(value) {
				this.item.optionName1 = value.optionName1;
				this.item.optionAttributes1 = value.optionAttributes1;
				this.item.optionName2 = value.optionName2;
				this.item.optionAttributes2 = value.optionAttributes2;
				this.item.optionDependancy2 = value.optionDependancy2;
				this.item.optionName3 = value.optionName3;
				this.item.optionAttributes3 = value.optionAttributes3;
				this.item.optionDependancy3 = value.optionDependancy3;
				this.item.optionName4 = value.optionName4;
				this.item.optionAttributes4 = value.optionAttributes4;
				this.item.optionDependancy4 = value.optionDependancy4;
				this.item.optionName5 = value.optionName5;
				this.item.optionAttributes5 = value.optionAttributes5;
				this.item.optionDependancy5 = value.optionDependancy5;
				this.item.optionName6 = value.optionName6;
				this.item.optionAttributes6 = value.optionAttributes6;
				this.item.optionDependancy6 = value.optionDependancy6;
				this.item.optionName7 = value.optionName7;
				this.item.optionAttributes7 = value.optionAttributes7;
				this.item.optionDependancy7 = value.optionDependancy7;
				this.item.optionName8 = value.optionName8;
				this.item.optionAttributes8 = value.optionAttributes8;
				this.item.optionDependancy8 = value.optionDependancy8;
				this.item.optionName9 = value.optionName9;
				this.item.optionAttributes9 = value.optionAttributes9;
				this.item.optionDependancy9 = value.optionDependancy9;
				this.item.optionName10 = value.optionName10;
				this.item.optionAttributes10 = value.optionAttributes10;
				this.item.optionDependancy10 = value.optionDependancy10;
				// console.log('Options Updated in Product.vue')
				// console.log('value = ' + JSON.stringify(value, null, 2))
				//Object.assign(this.item, value)
			},
			async saveItem(item) {
				// console.log('saveItem run')
				// console.log('item = ' + JSON.stringify(item, null, 2))
				//let result = await this.MIX_redisUpdateHash(this.key, item);
				let result = await this.MIX_createItem(item, this.key, this.newItem);
				//console.log('resultupdate = ' + JSON.stringify(result, null, 2))
				if (result.code === 1) {
					this.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					this.readItem();
					this.productUpdateKey++

				}
			}
		},
		// * WATCH
		watch: {
			activeitem() {
				this.initialiseItem();			
				// setTimeout(() => {
				// 	this.productUpdateKey++
				// }, 500);
			},
		},
		// * CREATED
		async created() {
			this.loading = true;
			this.schema = schema.product;
			this.initialiseItem();
		},
	};
</script>

<style scoped>
	.priceblock {
		flex: 1;
	}
</style>
