<template>
    <div style="padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow: scroll !important">
        <v-overlay v-model="sendPDFOverlay">
            <v-card class="grey lighten-3 primary--text pa-4" width="600">
                <v-col cols="12" class="d-flex justify-space-between ma-0 pa-0">
                    <v-card-title>Download/Send PDF</v-card-title>
                    <v-icon color="primary" class="mr-4" @click="sendPDFOverlay = false">icons8-multiply</v-icon>
                </v-col>
                <v-card-subtitle class="black--text">If you wish to email a copy of the invoice please provide an email below</v-card-subtitle>
                <v-card-text>
                    <v-text-field light outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable label="Email address" v-model="email.to" prepend-inner-icon="icons8-mail"></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-text-field light outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable label="Subject" v-model="email.subject" prepend-inner-icon="icons8-mail"></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-textarea light outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable label="Enter a message" v-model="email.body" prepend-inner-icon="icons8-mail"></v-textarea>
                </v-card-text>
                <v-card-text class="d-flex">
                    <v-col cols="6" class="d-flex justify-start">
                        <v-btn text class="primary white--text" :large="!dense" @click="createPdf(`https://mof-swapp.web.app/print-order/${item.id}`)" :loading="loading"
                            ><v-icon class="mr-2">icons8-export-pdf</v-icon>Download PDF</v-btn
                        >
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end">
                        <v-btn text class="primary white--text" :large="!dense" @click="sendPDF(item.id)" :loading="loading"><v-icon class="mr-2">icons8-mail</v-icon>Send Email</v-btn>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-overlay>
        <v-row class="apptext--text" dense>
            <v-col cols="12" class="d-flex align-center justify-center grey darken-1 py-4">
                <!-- <div class="ml-3">
                    <v-btn text class="grey lighten-5 primary--text" :large="!dense" @click="sendPDFOverlay = true" :loading="loading"
                        ><v-icon class="mr-2">icons8-export-pdf</v-icon>Download PDF</v-btn
                    >
                </div> -->
                <div class="ml-5">
                    <v-btn text class="grey lighten-5 primary--text" :large="!dense" @click="printPreview"><v-icon class="mr-2">icons8-print</v-icon>Print / PDF Quote</v-btn>
                </div>
                <!-- <div class="ml-2">
                    <v-btn text class="grey lighten-5 primary--text" :large="!dense" @click="printPreview" disabled><v-icon class="mr-2">icons8-available-updates</v-icon>Push to Interprise</v-btn>
                </div> -->
                <v-spacer />
                <v-btn-toggle v-model="toggleQuoteFeatures" multiple :dense="dense" class="mx-3">
                    <v-btn value="showTotal" title="Show/Hide Quote Total">
                        <v-icon class="grey--text text--darken-3">icons8-british-pound-circled</v-icon>
                    </v-btn>
                    <v-btn value="images" title="Toggle Product Images">
                        <v-icon class="grey--text text--darken-3">icons8-picture</v-icon>
                    </v-btn>
                    <v-btn value="features" title="Toggle Product Features">
                        <v-icon class="grey--text text--darken-3">icons8-verified-badge</v-icon>
                    </v-btn>
                    <v-btn value="location" title="Toggle Product Location">
                        <v-icon class="grey--text text--darken-3">icons8-building</v-icon>
                    </v-btn>
                    <v-btn value="discount" title="Show Discount">
                        <v-icon class="grey--text text--darken-3">icons8-low-price-pound</v-icon>
                    </v-btn>                    
                    <v-btn value="rrp" title="Show RRP">
                        <v-icon class="grey--text text--darken-3">icons8-rrp</v-icon>
                    </v-btn>
                </v-btn-toggle>

                <div class="mx-2 mr-3">
                    <v-menu offset-y :ref="item.id" :value="false" class="white" rounded style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="grey lighten-5 error--text" v-bind="attrs" v-on="on"><v-icon color="primary">icons8-menu-vertical</v-icon></v-btn>
                        </template>
                        <div class="white mt-1" style="width: 250px; max-height: 300px">
                            <div class="pa-5 white" style="width: 100% !important">
                                <div class="my-1">
                                    <v-btn text class="purple--text text--darken-2" @click="dialogQuotePrintKey++"><v-icon class="mr-2">icons8-available-updates</v-icon>Refresh Preview</v-btn>
                                </div>
                            </div>
                        </div>
                    </v-menu>
                </div>
            </v-col>
        </v-row>
        
        <div style="position: relative; overflow: auto !important; height: 700px">
            <quote-print :quoteid="item.id" :refreshlineitems="refreshlineitems" :key="dialogQuotePrintKey" :quotefeatures="toggleQuoteFeatures" />
        </div>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import quotePrint from '@/views/orders/orderPrint.vue';
import { mapGetters } from 'vuex';
import schema from '@/schema.js';
export default {
    name: 'quotePreview',
    // * COMPONENTS
    components: {
        'quote-print': quotePrint,
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        dense: {
            type: Boolean,
            default: false,
        },
        refreshlineitems: {
            type: Number,
            default: 0,
        },
    },
    // * DATA
    data: () => ({
        email: {
            to: '',
            subject: '',
            body: '',
        },
        dialogQuotePrintKey: 0,
        key: 'orderLineItem',
        index: 'idx:orderLineItems',
        // TODO - RG - Create a Server Schema for lineItems
        // Toggles
        toggleDeletedItems: false,
        toggleProductImages: false,
        toggleMoveItems: false,
        toggleMargin: false,
        toggleDiscount: false,
        mcdToggle: false,
        toggleQuoteFeatures: ['images'],
        // Filters
        searchQuery: '',
        filter: {},
        // Item
        item: {},
        // Items
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 999,
        // Headers
        //No Headers as not using table
        sortBy: '@lineOrder',
        dropKey: 0,
        loading: false,
        // items: [1, 2, 3, 4, 5],
        marginReasons: ['Market Price', 'Price Match', 'Competition', 'Other'],
        product: {
            lineType: 'product',
            productName: 'Item 1',
            productCode: 'EDW-31',
            supplierName: 'Metalliform',
            supplierAccNo: 'MET001',
            productImage: 'https://picsum.photos/id/341/250',
            qty: 1,
            cost: 100.0,
            sell: 142.86,
            RRP: 110.0,
            totalCost: 100.0,
            totalSell: 142.86,
            margin: 30,
            marginSell: 42.86,
            discount: 0,
            mcdRate: 0,
            lineTotal: 142.86,
            lineMargin: 30,
            lineMaringSell: 42.86,
            optional: false,
            includeTotal: true,
            marginReason: '',
            marginComments: '',
        },
        lineItems: [],
        sendPDFOverlay: false,
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_dense: 'GET_dense',
            GET_destroy: 'GET_destroy',
        }),
        quoteTotal() {
            let totalCost = Number(
                this.items
                    .filter((item) => item.lineType === 'Product' && item.lineIncludeTotal === '1')
                    .map((item) => Number(item.lineTotalCost))
                    .reduce((prev, curr) => prev + curr, 0)
            );
            let totalSell = Number(
                this.items
                    .filter((item) => item.lineType === 'Product' && item.lineIncludeTotal === '1')
                    .map((item) => Number(item.lineTotalSell))
                    .reduce((prev, curr) => prev + curr, 0)
            );
            // console.log('totalCost = ', totalCost);
            let totalSellMargin = Number(totalSell) - Number(totalCost);
            let totalMargin = (totalSellMargin / totalSell) * 100;
            return {
                totalCost: totalCost.toFixed(2),
                totalSell: totalSell.toFixed(2),
                totalSellMargin: totalSellMargin.toFixed(2),
                totalMargin: totalMargin.toFixed(2),
            };
        },
        // Computed Search Query
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleDeletedItems) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }
            // console.log('searchQuery = ' + searchQuery);

            return searchQuery;
        },
        // Computed Items
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
    },
    // * WATCH
    watch: {
        refreshlineitems() {
            // console.log('Line Items have been refreshed in quote preview')
            this.getLineItems();
        },
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
            },
            deep: true,
        },
    },
    // * METHODS
    methods: {
        async sendPDF(item) {
            let t = this;
			t.loading = true;
            t.email.attachment = `https://mof-swapp.web.app/print-order/${item}`;
            const email = await t.MIX_sendEmail(t.email);
            if (email.status === 200) {
                t.sendPDFOverlay = false;
				t.loading = false;
            } else {
                // console.error(email.message);
				t.loading = false;
            }
        },
        printPreview() {
            window.open(this.$router.resolve({ path: `/print-order/${this.item.id}?printdialog=true&quotefeatures=${this.toggleQuoteFeatures}` }).href, '_blank');
            //window.print();
        },
        async createPdf(url) {
            this.loading = true;
            return await this.MIX_generatePDF(url).then((response) => {
                response
                    .arrayBuffer()
                    .then((res) => {
                        const blob = new Blob([res], { type: 'application/pdf' });
                        this.$saveAs(blob, this.item.quoteNumber + ' - ' + this.item.orgId + '.pdf');
                        this.loading = false;
                    })
                    .catch((e) => alert(e));
            });
        },
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        alertme(value) {
            this.$refs[value].save();
            // console.log(value);
        },
        // On Insert
        onInsert(event) {
            this.items.splice(event.index, 0, event.data);
        },
        // Add Product
        addProduct() {
            this.lineItems.push(JSON.parse(JSON.stringify(this.product)));
        },
        // Add Section
        addSection() {
            this.items.unshift({
                sectionName: 'Add Section Title',
                lineType: 'section',
                lineBackgroundColour: 'black',
            });
        },
        // Update Margin
        updateMargin(index) {
            this.items[index].lineSell = ((this.items[index].lineCost / (100 - this.items[index].lineMarginPercent)) * 100).toFixed(2);
            this.calculateLineItem(index);
            // <div class="ml-2 priceblock"><v-text-field suffix="%" :disabled="product.cP1 === null || product.sP1 === null" dense label="New Margin" outlined background-color="white" hide-details="auto" @change="product.sP1 = ((product.cP1 / (100 - $event)) * 100).toFixed(2)"></v-text-field></div>
        },
        // Calculate Line Item
        calculateLineItem(index) {
            this.items[index].lineTotalCost = (Number(this.items[index].lineCost) * Number(this.items[index].lineQty)).toFixed(2).toString();
            this.items[index].lineTotalSell = (Number(this.items[index].lineSell) * Number(this.items[index].lineQty)).toFixed(2).toString();
            this.items[index].lineMarginValue = (Number(this.items[index].lineSell) - Number(this.items[index].lineCost)).toFixed(2).toString();
            this.items[index].lineMarginPercent = ((Number(this.items[index].lineMarginValue) / Number(this.items[index].lineSell)) * 100).toFixed(1).toString();
            // B11-((C11/100)*B11)
            if (this.items[index].lineMCDRate !== '0' && this.items[index].lineMCDRate !== '') {
                let lineTotal = (Number(this.items[index].lineTotalSell) - (Number(this.items[index].lineDiscount) / 100) * Number(this.items[index].lineTotalSell)).toFixed(2);
                lineTotal = ((lineTotal / (100 - Number(this.items[index].lineMCDRate))) * 100).toFixed(2);
                this.items[index].lineTotal = lineTotal.toString();
            } else {
                if (this.items[index].lineDiscount !== '0') {
                    this.items[index].lineTotal = (Number(this.items[index].lineTotalSell) - (Number(this.items[index].lineDiscount) / 100) * Number(this.items[index].lineTotalSell))
                        .toFixed(2)
                        .toString();
                } else {
                    this.items[index].lineTotal = this.items[index].lineTotalSell;
                }
            }
            this.items[index].lineTotalMarginValue = (Number(this.items[index].lineTotal) - Number(this.items[index].lineTotalCost)).toFixed(2).toString();
            this.items[index].lineTotalMarginPercent = ((Number(this.items[index].lineTotalMarginValue) / Number(this.items[index].lineTotal)) * 100).toFixed(1).toString();
            //this.items[index].lineMargin = (this.items[index].totall);
        },
        // Get Line Items
        async getLineItems() {
            // console.log('getLineItems Running in quote preview');
            //				console.log("index: " + this.index + "\n computedSearchQuery: " + this.computedSearchQuery + "\n itemsFrom: " + this.itemsFrom + "\n itemsTo: " + this.itemsTo);
            let itemsResult = await this.MIX_redisSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.itemsTo);
            // console.log("itemsResult = " + JSON.stringify(itemsResult, null, 2));
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents.map((x) => x.value).sort((a,b) => a.lineOrder - b.lineOrder)
                //					console.log("this.items = " + JSON.stringify(this.items, null, 2));
            }
        },
        // Edit Line
        editLine(index) {
            this.items[index].lineEdit = '1';
        },
        async saveLine(index) {
            this.items[index].lineEdit = '0';
            await this.MIX_createItem(this.items[index], 'lineItem', false);
            // TODO - RG - Add Save to Redis
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getLineItems();
            }
        },
    },
    created() {
        // TODO - RG - Add Loader
        this.item = schema.quote;
        this.item = { ...this.datadown };
        this.getLineItems();
    },
};
</script>
<style scoped lang="scss">
.wrapper {
    .list {
        border: 1px solid black;
        margin: 100px auto;
        width: 200px;

        .item {
            padding: 20px;
            margin: 10px;
            background-color: rgb(220, 220, 255);
            display: flex;
            align-items: center;
            justify-content: center;

            &.feedback {
                background-color: rgb(255, 220, 220);
                border: 2px dashed black;
            }

            &.drag-image {
                background-color: rgb(220, 255, 220);
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>

<style scoped>
.bottom {
    position: fixed;
    bottom: 0px !important;
}
.priceblock {
    flex: 1;
}
table {
    border-collapse: collapse;
}
th {
    padding: 5px !important;
}
td {
    padding: 5px !important;
}
/* .v-text-field--outlined >>> fieldset {
    border-color: #6f2d86;
} */
</style>
