<template>
    <div>
        <div class="mt-3 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field label="Search Products" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field>
                <!-- <div class="ml-1 mt-1 font-weight-bold body-2 primary--text">+ Custom Search</div> -->
            </div>
            <div class="ml-2" v-if="!listshrunk">
                <v-select outlined label="Filter by Type" v-model="filter.productType" :items="productTypes" dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
            </div>
            <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="refreshitems">
                    <v-icon>icons8-available-updates</v-icon>
                </v-btn>
            </div>
            <div class="ml-2" v-if="listshrunk">
                <v-btn-toggle v-model="toggleFeatures" multiple>
                    <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" value="images"><v-icon>icons8-picture</v-icon></v-btn>
                </v-btn-toggle>
            </div>
            <div class="ml-2" v-if="!listshrunk">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>icons8-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
          <!--    EXPORT CSV-->
          <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="exportBox = true">
              <v-icon>icons8-export-csv</v-icon>
            </v-btn>
          </div>
          <ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
            <template v-slot:header>
              <div class="full-width d-flex align-center">
                <div>Export CSV</div>
                <v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
                <v-spacer />
                <v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-cancel</v-icon></v-btn>
              </div>
            </template>
            <p>Please select the data you wish to export:</p>
            <table width="100%" cellspacing="5">
              <tr>
                <td width="100"><strong>Current page:</strong></td>
                <td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
                <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
              </tr>
              <tr>
                <td><strong>Current total:</strong></td>
                <td>{{ itemsTotal }} items (filtered)</td>
                <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
              </tr>
              <tr>
                <td><strong>All Items:</strong></td>
                <td>{{ totalCount }} items (without filter)</td>
                <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
              </tr>
              <tr>
                <td valign="top"><strong>Specific:</strong></td>
                <td>
                  <div class="d-flex align-center">
                    <div>From</div>
                    <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
                    <div>To</div>
                    <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
                  </div>
                  <div class="ml-n7">
                    <CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
                  </div>
                </td>
                <td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
              </tr>
            </table>
          </ExportBox>
          <!--      EXPORT END-->
        </div>
        <div class="mt-3 d-flex align-start">
            <div class="" v-if="!listshrunk">
                <div>
                    <v-autocomplete multiple outlined auto-select-first v-model="filter.quoteTags" :search-input.sync="tagAutocomplete" @change="tagAutocomplete = ''" label="Filter by Tag" :items="quoteTags" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearQuoteTagsFilter()"></v-autocomplete>
                </div>
                <div class="ml-1 mt-1 body-2 app--text">
                    Filter is set to <strong class="primary--text" @click="changeQuoteTagsOperator()" style="cursor: pointer">{{ filter.quoteTagsOperator }}</strong> of the above
                </div>
            </div>
            <div class="ml-2" v-if="!listshrunk">
                <v-autocomplete outlined auto-select-first v-model="filter.orgId" label="Filter by Organisation" :items="organisations" item-text="orgName" item-value="id" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('orgId', '')"></v-autocomplete>
            </div>

            <!-- <div class="ml-2" v-if="listexpanded">
                <v-select disabled outlined label="Filter By Category" dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
            </div>
            <div class="ml-2" v-if="listexpanded">
                <v-select disabled outlined label="Filter by Subcategory" dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
            </div>
            <div class="ml-2" v-if="listexpanded">
                <v-select disabled outlined label="Filter by Item Type" dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
            </div> -->
            <div class="ml-2" v-if="!listshrunk">
                <v-autocomplete :items="methodProductCategories()" label="Category" v-model="filter.productCategory" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('productCategory', '')" :attach="true"></v-autocomplete>
            </div>
            <div class="ml-2" v-if="!listshrunk">
                <v-autocomplete :disabled="filter.productCategory === ''" :items="methodProductSubcategories(filter.productCategory)" label="Subcategory" v-model="filter.productSubcategory" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('productSubcategory', '')" :attach="true"></v-autocomplete>
            </div>
            <div class="ml-2" v-if="!listshrunk">
                <v-autocomplete :disabled="filter.productCategory === ''" :items="methodProductItemTypes(filter.productCategory)" label="Item Type" v-model="filter.productItemType" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('productItemType', '')" :attach="true"></v-autocomplete>
            </div>
        </div>

        <!-- <pre>{{ computedSearchQuery }}</pre> -->

        <div class="d-flex align-center mt-3">
            <!-- Product Channel -->
            <v-btn-toggle multiple v-model="filter.productChannel" class="primary--text" depressed dense hide-details="auto" background-color="white" color="primary" label="Product Category">
                <v-btn value="Education">Education</v-btn>
                <v-btn value="Workplace">Workplace</v-btn>
            </v-btn-toggle>
            <v-spacer />
            <div class="d-flex align-center" v-if="section === 'quotes'">
            <v-btn @click="dialogCustomProduct = true" depressed class="accent secondary--text"> <v-icon class="mr-2">icons8-price-tag</v-icon>Add Custom</v-btn>
        </div>

            <!-- Product Category -->
            <!-- <v-btn-toggle multiple v-model="filter.productCategories" class="ml-3 primary--text" depressed dense hide-details="auto" background-color="white" color="primary" label="Product Category">
                <v-btn v-for="(item, index) in productCategories.slice(0, 6)" :key="index">{{ item }}</v-btn>
            </v-btn-toggle> -->
        </div>
        <div class="mt-2">
            <!-- TABLE BASED LIST -->
            <!-- {{ computedSearchQuery }} -->
            <!-- <pre>{{ datatableOptions }}</pre><br />
            computedSortBy: <pre>{{ computedSortBy }}</pre><br />
            computedSortDesc: <pre>{{ computedSortDesc }}</pre><br /> -->
            <v-data-table v-if="!listshrunk" sortable dense class="mt-3" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions" :loading="loading" :loading-text="'Loading Products'" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 50, 100] }">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn value="images" small>
                                <v-icon class="grey--text text--darken-3">icons8-picture</v-icon>
                            </v-btn>
                            <v-btn value="destroy" small v-if="['Developer-Admin'].includes(userLevel)">
                                <v-icon class="error--text">icons8-delete-trash</v-icon>
                            </v-btn>
                            <v-btn value="delete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                            <v-btn value="undelete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        <!-- <div class="mb-5 mx-5"><v-switch v-model="showImages" color="primary" label="Images" hide-details="auto" inset></v-switch></div>
					<div class="mb-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>-->
                    </div>
                </template>
                <template v-slot:item.productName="{ item }">
                    <span :title="item.productName">{{ item.productName.substring(0, 50) }}...</span>
                </template>
                <template v-slot:item.productLeadTime="{ item }">
                    <span v-if="item.productLeadTime && item.productLeadTime !== ''">{{ item.productLeadTime }}</span>
                    <v-icon v-else @click="showLeadNotes(item)">icons8-factory</v-icon>
                </template>

                <template v-slot:item.productImageId="{ item }">
                    <div v-if="toggleFeatures.includes('images')" class="my-2 rounded d-flex align-center justify-center" style="border: 1px solid lightgrey !important; width: 110px; height: 110px">
                        <div v-if="item.productImageId !== ''">
                            <v-img class="my-2" width="100" height="100" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/thumbnails/' + item.productImageId + '.jpeg?x=' + Date.now()" />
                        </div>
                        <div v-if="item.productImageId === ''"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
                    </div>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon class="error--text pa-1" v-if="toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1'">icons8-trash-restore</v-icon>
                    <!-- <v-icon class="primary--text ml-5" v-if="section === 'quotes'" @click="openItemPopup(item)">icons8-tags</v-icon> -->
                    <v-icon class="primary--text ml-5" v-if="section === 'quotes' && item.hasOptions === '0' && item.hasVariants === '0'" @click="addItemToQuote(item)">icons8-add-receipt</v-icon>
                    <v-icon class="primary--text ml-5" v-if="section === 'quotes' && item.hasOptions !== '0'" @click="showProductSelector(item)">icons8-add-receipt</v-icon>
                    <v-icon class="primary--text ml-5" v-if="section === 'products'" @click="openItem(item)">icons8-forward</v-icon>
                </template>
                <!-- <template v-slot:item.importStatus="{ item }">
					<v-icon :class="item.importStatus + '--text'">icons8-filled-circle</v-icon>
				</template> -->
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listshrunk" style="height: 67vh; overflow-y: scroll" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div v-if="toggleFeatures.includes('images')" class="my-2 rounded d-flex align-center justify-center" style="width: 90px; height: 9px">
                            <div v-if="item.productImageId !== ''">
                                <v-img class="my-2" width="80" height="80" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/thumbnails/' + item.productImageId + '.jpeg?x=' + Date.now()" />
                            </div>
                            <div v-if="item.productImageId === ''"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
                        </div>
                        <div class="ml-3">
                            <div class="body-2 font-weight-bold" style="width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.productName }}</div>
                            <span class="body-2">Supplier Code:</span> <span class="body-2">{{ item.productSupplierCode }}</span
                            ><br />
                            <span class="body-2">{{ item.orgName }}</span
                            ><br />
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <div class="text-right mr-3">
                                <v-icon class="primary--text" v-if="section === 'products'" @click="openItem(item)">icons8-forward</v-icon>
                                <!-- <v-icon class="primary--text ml-5" v-if="section === 'quotes'" @click="openItemPopup(item)">icons8-tags</v-icon> -->
                                <v-icon class="primary--text ml-5" title="Add To Quote" v-if="section === 'quotes' && item.hasOptions === '0' && item.hasVariants === '0'" @click="addItemToQuote(item)">icons8-add-receipt</v-icon>
                                <v-icon class="primary--text ml-5" title="Add To Quote" v-if="section === 'quotes' && item.hasOptions !== '0'" @click="showProductSelector(item)">icons8-add-receipt</v-icon>
                            </div>
                            <!-- <v-icon class="primary--text ml-5" v-if="section === 'quotes'" @click="openItemPopup(item)">icons8-tags</v-icon>
						<v-icon class="primary--text ml-5" v-if="section === 'quotes'" @click="addItemToQuote(item)">icons8-add-receipt</v-icon> -->
                        </div>
                    </div>
                </v-card>
            </div>
            <!-- LOAD MORE CARDS -->
            <v-btn v-if="listshrunk" class="mt-2" depressed block>Load More</v-btn>
        </div>
        <v-dialog v-model="dialogOptionsSelector" width="95%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <product-preview v-if="!loading" :quoteid="quoteid" :quotenumber="quotenumber" :datadown="item" v-on:close="closeProductPreview" v-on:additemtoquote="addItemToQuote" :key="dialogProductPreviewKey" />
                <!-- // TODO NEW PRODUCTS NOT ADDING -->
            </v-card>
        </v-dialog>
        <!-- // * ADD CUSTOM PRODUCT -->
        <v-dialog v-model="dialogCustomProduct" width="1024">
            <v-card class="mx-auto rounded">
                <!-- Preview Tab -->
                <product-custom :quoteid="quoteid" :quotenumber="quotenumber" v-on:closeitem="closeItem" :key="dialogCustomProductKey" />
                <!-- // TODO NEW PRODUCTS NOT ADDING -->
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogLeadTimeNotes" :key="dialogLeadTimeNotesKey" width="1024">
            <v-card class="mx-auto grey lighten-4" style="overflow: hidden !important">
                <v-row class="" dense>
                    <v-col cols="12" sm="12" class="">
                        <div class="d-flex align-center primary pa-4">
                            <div class="mx-2">
                                <v-icon class="white--text">icons8-factory</v-icon>
                            </div>
                            <div class="text-left white--text text-h5 font-weight-light">Supplier Lead Time Notes</div>
                            <v-spacer />
                            <div>
                                <v-btn class="ml-2 white--text" plain @click="closeSupplierDialog" depressed>
                                    <v-icon>icons8-multiply</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row dense class="pl-6 pt-1">
                    <v-col cols="12">
                        <div class="font-weight-bold">Supplier: {{ org.orgName }}</div>
                    </v-col>
                </v-row>
                <v-row dense class="pa-6 pt-1">
                    <v-col cols="12" class="grey lighten-5 rounded d-flex" style="height: 400px !important; overflow-y: hidden !important">
                        <div v-html="org.leadTimeNotes" class="ql-editor" style="width:100% !important; height: 100% !important; overflow-y: scroll !important;"></div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import pgsr from "@/import/pgsr.json";
import schema from "@/schema.js";
import productPreview from "@/views/products/productPreview.vue";
import productCustom from "@/views/products/productCustom.vue";
import { mapGetters } from "vuex";
import ExportBox from "@/components/general/ExportBox.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
import TextField from "@/components/form/TextField.vue";
import AppButton from "@/components/general/AppButton.vue";
export default {
    // * COMPONENTS
    components: {
      AppButton, TextField, CheckboxField, ExportBox,
        "product-preview": productPreview,
        "product-custom": productCustom,
    },
    // * PROPS
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        section: {
            type: String,
            default: "products",
        },
        quoteid: {
            type: String,
            default: "",
        },
        quotenumber: {
            type: String,
            default: "",
        },
    },
    data: () => ({
        key: "product",
        index: "idx:products",
        loading: false,
        schema: "",
        dialogOptionsSelector: false,
        dialogCustomProduct: false,
        dialogProductPreviewKey: 0,
        dialogCustomProductKey: 0,

        dialogLeadTimeNotes: false,
        dialogLeadTimeNotesKey: 0, 

        quoteTags: [], // Tags for the quote replace with product tags
        tagAutocomplete: "",
        // Toggles
        toggleFeatures: [],
        // Filters
        searchQuery: "",
        filter: {
            productType: "SPB",
            orgId: "",
            productCategories: [],
            quoteTagsOperator: "ANY",
            quoteTags: [],
            productChannel: [],
            productCategory: "",
            productSubcategory: "",
            productItemType: "",
            // TODO - RG - More Quote Filters to Add
        },
        organisations: [],
        productTypes: [],
        item: {},
        org: {},
        // Items
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Headers to display in the Data Table
        headers: [
            { text: "Image Id", value: "productImageId", sortable: true, hidden: false, expanded: false },
            { text: "ID", value: "id", sortable: false, hidden: true, expanded: false },
            { text: "Supplier Code", value: "productSupplierCode", sortable: true, hidden: false, expanded: false },
            { text: "Code", value: "productCode", sortable: true, hidden: true, expanded: false },
            { text: "Previous Code", value: "productPreviousCode", sortable: true, hidden: false, expanded: false },
            { text: "Name", value: "productName", sortable: true, hidden: false, expanded: false },
            { text: "Status", value: "productStatus", sortable: false, hidden: true, expanded: false },
            { text: "Available From", value: "productAvailableFrom", sortable: false, hidden: true, expanded: false },
            { text: "Available To", value: "productAvailableTo", sortable: false, hidden: true, expanded: false },
            { text: "Short Description", value: "productShortDescription", sortable: false, hidden: true, expanded: false },
            { text: "Description", value: "productDescription", sortable: false, hidden: true, expanded: false },
            { text: "Lead Time Weeks", value: "productLeadTime", align: "center", sortable: false, hidden: false, expanded: false },
            { text: "Lead Time Days", value: "productLeadTimeDays", sortable: false, hidden: true, expanded: false },
            { text: "MTO", value: "productMTO", sortable: false, hidden: true, expanded: false },
            { text: "Category", value: "productCategory", sortable: false, hidden: true, expanded: true },
            { text: "Subcategory", value: "productSubcategory", sortable: false, hidden: true, expanded: true },
            { text: "Item Type", value: "productItemType", sortable: false, hidden: true, expanded: false },
            { text: "Type", value: "productType", sortable: false, hidden: false, expanded: true },
            { text: "Range", value: "productRange", sortable: false, hidden: true, expanded: false },
            { text: "Tags", value: "productTags", sortable: false, hidden: true, expanded: false },
            { text: "Enabled", value: "productEnabed", sortable: false, hidden: true, expanded: false },
            { text: "Width", value: "productWidth", sortable: false, hidden: true, expanded: false },
            { text: "Height", value: "productHeight", sortable: false, hidden: true, expanded: false },
            { text: "Front Width", value: "productFrontWidth", sortable: false, hidden: true, expanded: false },
            { text: "Back Width", value: "productBackWidth", sortable: false, hidden: true, expanded: false },
            { text: "Seat Height", value: "productSeatHeight", sortable: false, hidden: true, expanded: false },
            { text: "Internal Leg Width", value: "productInternalLegWidth", sortable: false, hidden: true, expanded: false },
            { text: "Dimensions", value: "productDimensions", sortable: false, hidden: true, expanded: false },
            { text: "Supplier ID", value: "orgId", sortable: false, hidden: true, expanded: false },
            { text: "Supplier Name", value: "orgName", sortable: true, hidden: false, expanded: false },
            { text: "Has Options", value: "hasOptions", sortable: false, hidden: true, expanded: false },
            { text: "Has Variants", value: "hasVariants", sortable: false, hidden: true, expanded: false },
            { text: "Has Extras", value: "hasExtras", sortable: false, hidden: true, expanded: false },
            { text: "Is Extra", value: "isExtra", sortable: false, hidden: true, expanded: false },
            { text: "Is Extra Sold Sep", value: "isExtraSoldSeparately", sortable: false, hidden: true, expanded: false },
            { text: "MOQ", value: "MOQ", sortable: false, hidden: true, expanded: false },
            { text: "Pricing Type", value: "pricingType", sortable: false, hidden: true, expanded: false },
            { text: "RRP", value: "RRP", sortable: false, hidden: true, expanded: false }, // Recommended Retail Price
            { text: "MP", value: "MP", sortable: false, hidden: true, expanded: false }, // Market Price
            { text: "Cost Qty 1", value: "cQ1", sortable: false, hidden: true, expanded: false }, // Cost Qty 1
            { text: "Cost Price 1", value: "cP1", sortable: false, hidden: true, expanded: false }, // Cost Price 1
            { text: "Cost Qty 2", value: "cQ2", sortable: false, hidden: true, expanded: false }, // Cost Qty 2
            { text: "Cost Price 2", value: "cP2", sortable: false, hidden: true, expanded: false }, // Cost Price 2
            { text: "Cost Qty 3", value: "cQ3", sortable: false, hidden: true, expanded: false }, // Cost Qty 3
            { text: "Cost Price 3", value: "cP3", sortable: false, hidden: true, expanded: false }, // Cost Price 3
            { text: "Sell Qty 1", value: "sQ1", sortable: false, hidden: true, expanded: false }, // Sell Qty 1
            { text: "Sell Price 1", value: "sP1", sortable: false, hidden: true, expanded: false }, // Sell Price 1
            { text: "Sell Qty 2", value: "sQ2", sortable: false, hidden: true, expanded: false }, // Sell Qty 2
            { text: "Sell Price 2", value: "sP2", sortable: false, hidden: true, expanded: false }, // Sell Price 2
            { text: "Sell Qty 3", value: "sQ3", sortable: false, hidden: true, expanded: false }, // Sell Qty 3
            { text: "Sell Price 3", value: "sP3", sortable: false, hidden: true, expanded: false }, // Sell Price 3
            { text: "Cost Stocked", value: "cStocked", sortable: false, hidden: true, expanded: false }, // Cost Stocked
            { text: "Sell Stocked", value: "sStocked", sortable: false, hidden: true, expanded: false }, // Sell Stocked
            { text: "Cost Band A", value: "cBA", sortable: false, hidden: true, expanded: false }, // Cost Band A
            { text: "Cost Band B", value: "cBB", sortable: false, hidden: true, expanded: false }, // Cost Band B
            { text: "Cost Band C", value: "cBC", sortable: false, hidden: true, expanded: false }, // Cost Band C
            { text: "Cost Band D", value: "cBD", sortable: false, hidden: true, expanded: false }, // Cost Band D
            { text: "Cost Band E", value: "cBE", sortable: false, hidden: true, expanded: false }, // Cost Band E
            { text: "Cost Band F", value: "cBF", sortable: false, hidden: true, expanded: false }, // Cost Band F
            { text: "Sell Band A", value: "sBA", sortable: false, hidden: true, expanded: false }, // Sell Band A
            { text: "Sell Band B", value: "sBB", sortable: false, hidden: true, expanded: false }, // Sell Band B
            { text: "Sell Band C", value: "sBC", sortable: false, hidden: true, expanded: false }, // Sell Band C
            { text: "Sell Band D", value: "sBD", sortable: false, hidden: true, expanded: false }, // Sell Band D
            { text: "Sell Band E", value: "sBE", sortable: false, hidden: true, expanded: false }, // Sell Band E
            { text: "Sell Band F", value: "sBF", sortable: false, hidden: true, expanded: false }, // Sell Band F
            { text: "NPS", value: "NPS", sortable: false, hidden: true, expanded: false }, // NPS Core Product Pricing
            { text: "Discount Group1", value: "discountGroup1", sortable: false, hidden: true, expanded: false }, // Custom Pricing 1
            { text: "Discount Group2", value: "discountGroup2", sortable: false, hidden: true, expanded: false }, // Custom Pricing 2
            { text: "Discount Group3", value: "discountGroup3", sortable: false, hidden: true, expanded: false }, // Custom Pricing 3
            { text: "Discount Group4", value: "discountGroup4", sortable: false, hidden: true, expanded: false }, // Custom Pricing 4
            { text: "Discount Group5", value: "discountGroup5", sortable: false, hidden: true, expanded: false }, // Custom Pricing 5
            { text: "Discount Group6", value: "discountGroup6", sortable: false, hidden: true, expanded: false }, // Custom Pricing 6
            { text: "Discount Group7", value: "discountGroup7", sortable: false, hidden: true, expanded: false }, // Custom Pricing 7
            { text: "Discount Group8", value: "discountGroup8", sortable: false, hidden: true, expanded: false }, // Custom Pricing 8
            { text: "Discount Group9", value: "discountGroup9", sortable: false, hidden: true, expanded: false }, // Custom Pricing 9
            { text: "Discount Group10", value: "discountGroup10", sortable: false, hidden: true, expanded: false }, // Custom Pricing 10
            { text: "Option Name 1", value: "optionName1", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 1", value: "optionAttributes1", sortable: false, hidden: true, expanded: false },
            { text: "Option Name 2", value: "optionName2", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 2", value: "optionAttributes2", sortable: false, hidden: true, expanded: false },
            { text: "Option Depd 2", value: "optionDependancy2", sortable: false, hidden: true, expanded: false },
            { text: "Option Name 3", value: "optionName3", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 3", value: "optionAttributes3", sortable: false, hidden: true, expanded: false },
            { text: "Option Depd 3", value: "optionDependancy3", sortable: false, hidden: true, expanded: false },
            { text: "Option Name 4", value: "optionName4", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 4", value: "optionAttributes4", sortable: false, hidden: true, expanded: false },
            { text: "Option Depd 4", value: "optionDependancy4", sortable: false, hidden: true, expanded: false },
            { text: "Option Name 5", value: "optionName5", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 5", value: "optionAttributes5", sortable: false, hidden: true, expanded: false },
            { text: "Option Depd 5", value: "optionDependancy5", sortable: false, hidden: true, expanded: false },
            { text: "Option Name 6", value: "optionName6", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 6", value: "optionAttributes6", sortable: false, hidden: true, expanded: false },
            { text: "Option Depd 6", value: "optionDependancy6", sortable: false, hidden: true, expanded: false },
            { text: "Option Name 7", value: "optionName7", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 7", value: "optionAttributes7", sortable: false, hidden: true, expanded: false },
            { text: "Option Depd 7", value: "optionDependancy7", sortable: false, hidden: true, expanded: false },
            { text: "Option Name 8", value: "optionName8", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 8", value: "optionAttributes8", sortable: false, hidden: true, expanded: false },
            { text: "Option Depd 8", value: "optionDependancy8", sortable: false, hidden: true, expanded: false },
            { text: "Option Name 9", value: "optionName9", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 9", value: "optionAttributes9", sortable: false, hidden: true, expanded: false },
            { text: "Option Depd 9", value: "optionDependancy9", sortable: false, hidden: true, expanded: false },
            { text: "Option Name 10", value: "optionName10", sortable: false, hidden: true, expanded: false },
            { text: "Option Attr 10", value: "optionAttributes10", sortable: false, hidden: true, expanded: false },
            { text: "Option Depd 10", value: "optionDependancy10", sortable: false, hidden: true, expanded: false },
            { text: "Key", value: "key", sortable: false, hidden: true, expanded: false },
            { text: "Date Time (C)", value: "createdDateTime", sortable: false, hidden: true, expanded: false },
            { text: "User ID (C)", value: "createdUserId", sortable: false, hidden: true, expanded: false },
            { text: "User Name (C)", value: "createdUserName", sortable: false, hidden: true, expanded: false },
            { text: "User Email (C)", value: "createdUserEmail", sortable: false, hidden: true, expanded: false },
            { text: "Date Time (M)", value: "modifiedDateTime", sortable: false, hidden: true, expanded: false },
            { text: "User ID (M)", value: "modifiedUserId", sortable: false, hidden: true, expanded: false },
            { text: "User Name (M)", value: "modifiedUserName", sortable: false, hidden: true, expanded: false },
            { text: "User Email (M)", value: "modifiedUserEmail", sortable: false, hidden: true, expanded: false },
            { text: "Deleted", value: "deleted", sortable: false, hidden: true, expanded: false },
            { text: "Date Time (D)", value: "deletedDateTime", sortable: false, hidden: true, expanded: false },
            { text: "User ID (D)", value: "deletedUserId", sortable: false, hidden: true, expanded: false },
            { text: "User Name (D)", value: "deletedUserName", sortable: false, hidden: true, expanded: false },
            { text: "User Email (D)", value: "deletedUserEmail", sortable: false, hidden: true, expanded: false },
            { text: "Action", value: "action", align: "end", sortable: false, hidden: false, expanded: false, width: "105px" },
        ],
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["productName"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        productCategories: ["Desk", "Chair", "Storage", "Table", "Pods", "Screens", "Reception Counters", "Acoustics", "Worktop", "Display", "Accessory", "Floor Covering", "Graphics", "Lighting", "Learning & Play", "Components ", "Sport Equipment", "White Goods"],
        pgsr: {},
      //EXPORT DATA
      exportBox: false,
      exportItems: {
        itemsFrom: "1",
        itemsTo: "10",
      },
      exportLoading: false,
      exportFiltered: false,
      showInfo: false,
      totalCount: 0,
    }),
    computed: {
        ...mapGetters({
            GET_taskBar: "GET_taskBar",
            GET_FIREBASE_userAccessToken: "firebase_auth_store/GET_FIREBASE_userAccessToken",
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            // remove - from searchQuery
            if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
                if (this.toggleDeletedItems) {
                    searchQuery = "*";
                } else {
                    searchQuery = "@deleted:0";
                }
            } else {
                if (searchQuery.includes("-")) {
                    searchQuery = searchQuery.replace(/-/g, "");
                }
                if (searchQuery.startsWith("@")) {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }
            // Channel Filter
            if (JSON.stringify(this.filter.productChannel) !== "[]") {
                searchQuery = `${searchQuery} @productChannel:{${this.filter.productChannel.join("|")}}`;
            }
            // Category Filter
            if (this.filter.productCategory !== "") {
                searchQuery = `${searchQuery} @productCategory:{${this.filter.productCategory}}`;
            }
            // Sub Category Filter
            if (this.filter.productSubcategory !== "") {
                searchQuery = `${searchQuery} @productSubcategory:{${this.filter.productSubcategory}}`;
            }
            // Item Type Filter
            if (this.filter.productItemType !== "") {
                searchQuery = `${searchQuery} @productItemType:{${this.filter.productItemType}}`;
            }

            // Product Type Filter
            if (JSON.stringify(this.filter.productType) !== "") {
                searchQuery = `${searchQuery} @productType:{${this.filter.productType}}`;
            }
            // Organisation Filter
            if (this.filter.orgId !== "" && this.filter.orgId !== null && this.filter.orgId !== undefined) {
                searchQuery = `${searchQuery}  @orgId:{${this.filter.orgId}}`;
            }
            return searchQuery;
        },
        computedHeaders() {
            // let headers = this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.toggleFeatures.includes("images")) {
                headers = headers.filter((header) => header.value !== "productImageId");
            }
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        computedFields() {
            // console.log('computedHeaders = ' + JSON.stringify(this.computedHeaders))
            let computedFields = this.computedHeaders.filter((x) => x.hidden === false).map((y) => "@" + y.value);
            if (!computedFields.includes("@id")) {
                computedFields.push("@id");
            }
            if (!computedFields.includes("@productCode")) {
                computedFields.push("@productCode");
            }
            if (!computedFields.includes("@productSupplierCode")) {
                computedFields.push("@productSupplierCode");
            }
            if (!computedFields.includes("@productPreviousCode")) {
                computedFields.push("@productPreviousCode");
            }
            if (!computedFields.includes("@deleted")) {
                computedFields.push("@deleted");
            }
            if (!computedFields.includes("@hasOptions")) {
                computedFields.push("@hasOptions");
            }
            if (!computedFields.includes("@orgId")) {
                computedFields.push("@orgId");
            }
            if (!computedFields.includes("@hasVariants")) {
                computedFields.push("@hasVariants");
            }
            //if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
            computedFields = computedFields.join(",");
            return computedFields;
        },
        computedSortBy() {
            let sortBy = this.datatableOptions.sortBy;
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => "@" + x);
                sortBy = sortBy.join(",");
            }
            return sortBy;
        },
        computedSortDesc() {
            let sortDesc = this.datatableOptions.sortDesc[0];
            return sortDesc;
        },
    },
    methods: {
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = "";
            }, 1);
        },
        methodProductCategories: function () {
            return this.pgsr.map((category) => category.name);
        },
        methodProductSubcategories: function (categoryName) {
            console.log("categoryName = " + categoryName)
            console.log('pgsr = ' + JSON.stringify(this.pgsr, null, 2))
            let category = this.pgsr.find((category) => category.name === categoryName);
            let subcategories = category?.productSubcategory;
            return subcategories;
        },
        methodProductItemTypes: function (categoryName) {
            let category = this.pgsr.find((category) => category.name === categoryName);
            let itemTypes = category?.itemTypes;
            return itemTypes;
        },
        // * READ ITEM - MODIFIED FOR PRODUCT RETURN / Quoting
        async readItem(item) {
            let t = this;
            let result = await t.MIX_readItem(item.id, t.key);
            if (result.code === 1) {
                return result.data;
            } else {
                return "";
            }
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        async showLeadNotes(item) {
            var t = this;
            console.log('showing lead notes for = ' + JSON.stringify(item, null, 2))
            
            let result = await t.MIX_readItem(item.orgId, 'org');
            if (result.code === 1) {
                console.log('got org = ' + JSON.stringify(result.data, null, 2))
                var org = result.data
                this.dialogLeadTimeNotesKey++;
                this.org = { ...org };
                this.dialogLeadTimeNotes = true;
            }
        },
        closeSupplierDialog() {
            this.org = {}
            this.dialogLeadTimeNotes = false
            this.dialogLeadTimeNotesKey++
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit("openItem", item);
            //this.activeItem = item;
            // this.listexpanded = false;
        },
        closeItem() {
            this.dialogCustomProduct = false;
            this.dialogCustomProductKey++;
            this.$emit("refreshlineitems");
        },
        closeProductPreview() {
            this.dialogProductPreviewKey++;
            this.dialogOptionsSelector = false;
        },
        // * GET ITEMS
        async getItems() {
            // console.log('getItems Running')
            //console.log('index: ' + this.index + '\n computedSearchQuery: ' + this.computedSearchQuery + '\n itemsFrom: ' + this.itemsFrom + '\n datatableOptions.itemsPerPage: ' + this.datatableOptions.itemsPerPage + '\n computedFields: ' + this.computedFields + '\n sortBy: ' + this.sortBy)
            // console.log("this.computedSearchQuer = " + this.computedSearchQuery)
            // console.log("this.computedFields = " + this.computedFields)
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.computedSortBy, this.computedSortDesc);
            // console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2))
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                //console.log("items = " + JSON.stringify(itemsResult, null, 2));
            }
            this.loading = false;
        },
        async showProductSelector(item) {
            let t = this;
            item = await t.readItem(item);
            // console.log('got item: ' + JSON.stringify(item, null, 2));
            this.dialogProductPreviewKey++;
            this.item = { ...item };
            //alert(this.item.id)
            // console.log('launch popup')
            this.dialogOptionsSelector = true;
        },
        async addItemToQuote(item) {
            let t = this;
            //item = await t.readItem(item);
            // console.log('xxxxitem = ' + JSON.stringify(item, null, 2));
            // var lineItem = t.schema;
            // console.log("item: \n" + JSON.stringify(item, null, 2))
            // console.log('t.item.orgId; = ' + item.orgId)
            // console.log("lineItem: \n" + JSON.stringify(lineItem, null, 2))
            if (item.hasOptions === "0" && item.hasVariants === "0") {
                // TODO - RG - Need to take into consideration different types of pricing
                // TODO - RG - Product Group Categories required for Reporting
                // console.log('No Options or Variants');

                let itemFull = await this.readItem(item);
                // console.log('itemFull = ' + JSON.stringify(itemFull, null, 2));
                if (itemFull !== {}) {
                    let lineItem = await this.CALC_createLineItem(itemFull, "");
                    lineItem.id = this.MIX_generateId();
                    lineItem.quoteId = this.quoteid;
                    let saveLineItemResult = await t.MIX_createItem(lineItem, "lineItem", true);
                    if (saveLineItemResult.code === 1) {
                        t.closeItem();
                        t.MIX_alertBox({ color: "success", timeout: 2000, message: "Product added", show: true });
                    } else {
                        t.MIX_alertBox({ color: "error", timeout: 4000, message: "Error adding product", show: true });
                    }
                }
            } else {
                // TODO - RG - Add variant Code for Product Selection
                this.dialogOptionsSelector = false;
                // console.log('Has Options or Variants');

                // console.log('t.quoteid; = ' + t.quoteid);
                // let lineItem = await this.CALC_createLineItem(item, '');
                // lineItem.id = this.MIX_generateId();
                // item.quoteId = t.quoteid;
                let lineItemResult = await t.MIX_createItem(item, "lineItem", true);
                if (lineItemResult.code === 1) {
                    t.MIX_alertBox({ color: "success", timeout: 2000, message: "Product added to quote", show: true });
                } else {
                    t.MIX_alertBox({ color: "error", timeout: 4000, message: "Error adding to Quote", show: true });
                }
                // console.log('lineItemResult = ' + JSON.stringify(lineItemResult, null, 2));
            }
            t.$emit("refreshlineitems");
            this.dialogProductPreviewKey++;
        },// * GET ITEMS
        async getPGSR() {
            // console.log('getItems running...');
            let itemsResult = await this.MIX_redisReadString('PGSR');
            // console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2));
            if (itemsResult.code === 1) {
                var items = JSON.parse(itemsResult.data);
                this.pgsr = items['productCategory'];
                // console.log('this.items = ' + JSON.stringify(this.items, null, 2));
            }
        },
      // Export Box CSV
      async exportCSV(itemsFrom, itemsTo, filter, specific) {
        // console.table({ itemsFrom, itemsTo, filter, specific });
        if (itemsFrom > itemsTo) {
          this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
        } else {
          this.exportLoading = true;
          var itemsCount, tableData, csv, query, exportFileName;
          switch (true) {
              // Current Page / Current Total (filtered)
            case filter && !specific:
              itemsCount = itemsTo - itemsFrom;
              query = this.computedSearchQuery;
              break;
              // All (Not filtered)
            case !filter && !specific:
              itemsCount = this.totalCount;
              query = `*`;
              break;
              // Specific (filtered/ not filtered)
            case specific:
              itemsFrom = itemsFrom - 1;
              itemsCount = itemsTo - itemsFrom;
              if (this.exportFiltered) {
                query = this.computedSearchQuery;
              } else {
                query = `*`;
              }
              break;
          }
          // console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
          // let exportResult = await this.REDIS_searchFor("animal", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
          let exportResult = await this.MIX_redisAggregateSearch(this.index, query, itemsFrom, itemsCount, this.computedFields, this.computedSortBy, this.computedSortDesc);
          // console.log(exportResult)
          tableData = JSON.parse(JSON.stringify(exportResult.data.results));
          // console.table(tableData)
          csv = this.$papa.unparse(tableData);
          if (itemsFrom === 0) {
            itemsFrom = 1; // This is just for filename purposes
          }
          if (itemsFrom >= 10) {
            itemsFrom = itemsFrom + 1;
          }
          switch (true) {
            case !specific && filter:
              exportFileName = `Product_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case !specific && !filter:
              exportFileName = `Product_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && this.exportFiltered:
              exportFileName = `Product_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && !this.exportFiltered:
              exportFileName = `Product_Specific_${itemsFrom}_to_${itemsTo}`;
              break;
          }
          // console.log(exportFileName);
          // console.log(csv.length);
          this.$papa.download(csv, exportFileName);
          this.exportLoading = false;
        }
      },
      async getTotal(){
        let totalResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.computedSortBy, this.computedSortDesc);
        this.totalCount = totalResult.data.total;
      },
    },
    watch: {
        computedHeaders: {
            handler() {
                this.getItems();
            },
            deep: true,
        },
        refreshitems: function (newValue) {
            if (newValue) {
                this.getItems();
            }
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.itemsFrom = 0;
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === "productMainImage");
            //console.log('imagesIndex = ' + imagesIndex)
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        let t = this;
        t.loading = true;
        t.getPGSR()
        // Get Organisations
        t.organisations = await t.MIX_organisations("Supplier");
        // Get Product Types
        let productTypesResult = await t.MIX_redisReadSet("set:productTypes", true);
        if (productTypesResult.code === 1) {
            t.productTypes = productTypesResult.data;
            // console.log('productTypes = ' + JSON.stringify(t.productTypes, null, 2));
        }
        this.getItems();
        this.getTotal();
        this.schema = schema.lineItem;
    },
};
</script>
