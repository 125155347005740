<template>
	<div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
		<!-- Menu Buttons -->
		<v-row class="apptext--text mt-0" dense>
			<v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
				<div class="ml-3 d-flex align-center" v-if="loading">
					<v-progress-circular indeterminate color="accent"></v-progress-circular>
					<div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
				</div>
				<!-- <div>
                    editmode {{ editmode }} | loading {{ loading}}
                </div>   -->
				<v-spacer v-if="$vuetify.breakpoint.smAndDown" />

				<div v-if="$vuetify.breakpoint.smAndDown" class="white--text text-h6 font-weight-bold pl-3">
					<div>{{ item.orgName }}</div>
					<div @click="close()" class="font-weight-normal caption"><v-icon size="15" class="white--text pa-0">icons8-back</v-icon> Return to List</div>
				</div>

				<v-spacer />
				<div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
					<v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
					<v-btn text class="accent secondary--text" v-if="editmode" @click="validateForm()" :large="!dense"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
				</div>
				<!-- <div class="ml-3">
                    <v-btn :disabled="true" text class="grey lighten-5 secondary--text" @click="editmode = true" :large="!dense"><v-icon class="mr-2">icons8-receipt-approved</v-icon>Convert to Customer</v-btn>
                </div>
                <v-spacer />
                <div class="ml-2">
                    <v-btn :disabled="true" text class="grey lighten-5 success--text" :large="!dense"><v-icon class="mr-2">icons8-ok</v-icon>Approve</v-btn>
                </div>
                <div class="ml-2">
                    <v-btn :disabled="true" text class="grey lighten-5 error--text" :large="!dense"><v-icon class="mr-2">icons8-cancel</v-icon>Reject</v-btn>
                </div> -->
				<!-- <div class="mx-2 mr-3">
                    <v-menu offset-y :ref="item.id" :value="false" class="white" rounded style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="editmode" text class="grey lighten-5 error--text" v-bind="attrs" v-on="on"><v-icon color="primary">icons8-menu-vertical</v-icon></v-btn>
                        </template>
                        <div class="white mt-1" style="width: 250px; max-height: 300px">
                            <div class="pa-5 white" style="width: 100% !important">
                                <div class="my-1">
                                    <v-btn :disabled="true" text class="grey--text text--darken-2" :large="!dense"><v-icon class="mr-2">icons8-copy</v-icon>Copy to New</v-btn>
                                </div>
                                <div class="my-1">
                                    <v-btn :disabled="true" text class="error--text" :large="!dense"><v-icon class="mr-2">icons8-trash-can</v-icon>Delete</v-btn>
                                </div>
                            </div>
                        </div>
                    </v-menu>
                </div> -->
			</v-col>
		</v-row>
		<!-- Organisation View -->
		<v-row class="apptext--text grey lighten-3 pt-3 px-3" dense v-if="!editmode && !loading">
			<v-col v-if="!editmode" cols="12" class="d-flex align-center justify-start rounded">
				<v-row style="width: 100% !important" dense>
					<!-- //* GENERAL DETAILS VIEW -->
					<v-col v-if="item.orgName || item.orgRegisteredName || item.orgRegistrationNumber || item.orgTelephone" cols="12" sm="12" :md="inputCols">
						<!-- VIEW VERSION -->
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<v-col cols="12" sm="12">
								<div class="d-flex align-center">
									<span class="text-left primary--text text-h6 font-weight-light">Names</span>
									<v-spacer />
									<v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
								</div>
								<v-divider class="grey lighten-1 mb-1"></v-divider>
							</v-col>
							<v-col cols="12" class="" style="height: 100% !important">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Name:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgName }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Registered Name:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgRegisteredName }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Registration No:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgRegistrationNumber }}</td>
											</tr>
											<tr v-if="item.orgAccountTypes !== ''">
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Account Type:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgAccountTypes }}</td>
											</tr>
											<tr v-if="item.orgLocalAuthority !== ''">
												<td class="pl-0 apptext--text font-weight-bold grey lighten-5">Unitary Authority:</td>
												<td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgLocalAuthority }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-col>
					<v-col v-if="$vuetify.breakpoint.mdAndUp && item.orgImageId !== '' && item.orgImageId !== undefined && item.orgImageId !== null" cols="12" sm="12" :md="inputCols">
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<v-col cols="12" sm="12" class="d-flex align-center justify-center">
								<div class="" v-if="item.orgImageId !== '' && item.orgImageId !== undefined && item.orgImageId !== null"><v-img class="" width="350" contain :src="'https://storage.googleapis.com/mof-swapp-public/organisations/' + item.orgImageId + '.jpeg'" /></div>
								<div class="" v-if="item.orgImageId === '' || item.orgImageId === undefined || item.orgImageId === null"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
							</v-col>
						</v-row>
					</v-col>
					<!-- //* CONTACTS VIEW -->
					<v-col cols="12" sm="12" :md="inputCols">
						<!-- VIEW VERSION -->
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="text-left primary--text text-h6 font-weight-light">Contact</span>
									<v-spacer />
									<v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
								</div>
								<v-divider class="grey lighten-1 mb-1"></v-divider>
							</v-col>
							<v-col cols="12" class="" style="height: 100% !important">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Telephone:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
													<a :href="'callto:' + item.orgTelephone" target="_blank">{{ item.orgTelephone }}</a>
												</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Website:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
													<div v-if="JSON.stringify(item) !== '{}'">
														<a :href="item.orgWebsite" target="_blank">{{ item.orgWebsite.substring(0, 21) }}...</a>
													</div>
												</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Email:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
													<a :href="'mailto:' + item.orgEmail" target="_blank">{{ item.orgEmail }}</a>
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-col>
					<v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" sm="12" :md="inputCols">
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<!-- Card Heading -->
							<v-col cols="12" sm="12">
								<div class="d-flex align-center">
									<span class="text-left primary--text text-h6 font-weight-light">Profile Score</span>
									<v-spacer />
									<v-icon medium class="grey--text text--darken-2">icons8-identification-documents</v-icon>
								</div>
								<v-divider class="grey lighten-1 mb-1"></v-divider>
							</v-col>

							<!-- Card Body -->
							<v-col cols="12" style="height: 100% !important" class="mt-5 d-flex align-start justify-start">
								<div>
									<v-progress-circular :rotate="-90" :size="100" :width="15" :value="computedProfileScore.score" :color="computedProfileScore.color"> {{ computedProfileScore.score }} % </v-progress-circular>
								</div>
								<div class="ml-5">
									<span class="font-weight-bold" :class="computedProfileScore.color + '--text'">{{ computedProfileScore.name }}</span>
									<p>{{ computedProfileScore.message }}</p>
								</div>
							</v-col>
						</v-row>
					</v-col>
					<!-- //* ADDRESS DETAILS VIEW -->
					<v-col cols="12" sm="12" :md="inputCols">
						<!-- VIEW VERSION -->
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="text-left primary--text text-h6 font-weight-light">Address</span>
									<v-spacer />
									<v-icon medium class="grey--text text--darken-2">icons8-map-marker</v-icon>
								</div>
								<v-divider class="grey lighten-1 mb-1"></v-divider>
							</v-col>
							<v-col cols="12" style="height: 100% !important">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody style="height: 100px" class="grey lighten-5">
											<tr class="d-flex grey lighten-5">
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Address:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
													<a :href="`https://www.google.com/maps/search/?api=1&query=${item.orgName},${item.orgAddressLine1},${item.orgAddressLine2},${item.orgAddressLine3},${item.orgTown},${item.orgCounty},${item.orgPostcode}`" target="_blank">
														<span v-if="item.orgAddressLine1"
															>{{ item.orgAddressLine1 }}<span v-if="item.orgAddressLine2">, {{ item.orgAddressLine2 }}</span
															><br
														/></span>
														<span v-if="item.orgAddressLine3">{{ item.orgAddressLine3 }}<br /></span>
														<span v-if="item.orgTown"
															>{{ item.orgTown }}<span v-if="item.orgCounty">, {{ item.orgCounty }}</span
															><br
														/></span>
														<span v-if="item.orgPostcode">{{ item.orgPostcode }}<br /></span>
													</a>
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-col>
					<!-- //* INVOICE DETAILS VIEW -->
					<v-col cols="12" sm="12" :md="inputCols">
						<!-- VIEW VERSION -->
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="text-left primary--text text-h6 font-weight-light">Finance & Accounting</span>
									<v-spacer />
									<v-icon medium class="grey--text text--darken-2">icons8-calculator</v-icon>
								</div>
								<v-divider class="grey lighten-1 mb-1"></v-divider>
							</v-col>
							<v-col cols="12" class="" style="height: 100% !important">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Invoice Email:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
													<a :href="'mailto:' + item.orgInvoiceEmail" target="_blank">{{ item.orgInvoiceEmail }}</a>
												</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Purchasing Email:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
													<a :href="'mailto:' + item.orgPurchasingEmail" target="_blank">{{ item.orgPurchasingEmail }}</a>
												</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Credit Limit:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
													{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.orgAccountCreditLimit) }}
												</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Account On Hold:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
													<span v-if="item.orgAccountOnHold === '0'">No</span>
													<span v-if="item.orgAccountOnHold === '1'">Yes</span>
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-col>
					<!-- //* ORG INFO VIEW -->
					<v-col cols="12" sm="12" :md="inputCols">
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="text-left primary--text text-h6 font-weight-light">Organisation Info</span>
									<v-spacer />
									<v-icon medium class="grey--text text--darken-2">icons8-city</v-icon>
								</div>
								<v-divider class="grey lighten-1 mb-1"></v-divider>
							</v-col>
							<v-col cols="12" style="height: 100% !important">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Channel:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgChannel }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Employees:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgEmployees }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Industry:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgIndustry }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Sector:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgSector }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Turnover:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgTurnover }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="12" :md="inputCols">
						<!-- //* OWNER / SOURCE INFO VIEW -->
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="text-left primary--text text-h6 font-weight-light">Source Info</span>
									<v-spacer />
									<v-icon medium class="primary--text">icons8-secured-letter</v-icon>
								</div>
								<v-divider class="grey lighten-1 mb-1"></v-divider>
							</v-col>
							<v-col cols="12" style="height: 100% !important">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Source:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgSource }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Source Name:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgSourceName }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-col>
					<!-- //* SOCIAL INFO VIEW -->
					<v-col cols="12" sm="12" :md="inputCols">
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="text-left primary--text text-h6 font-weight-light">Social Networks</span>
									<v-spacer />
									<v-icon medium class="grey--text text--darken-2">icons8-people-working-together</v-icon>
								</div>
								<v-divider class="grey lighten-1 mb-1"></v-divider>
							</v-col>
							<v-col cols="12" style="height: 100% !important">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr v-if="item.orgTwitter !== ''">
												<td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
													<v-icon class="apptext--text mr-2">icons8-twitter</v-icon>
													<div class="body-2 pointer">
														<a :href="item.orgTwitter" target="_blank">{{ item.orgTwitter }}</a>
													</div>
												</td>
											</tr>
											<tr v-if="item.orgLinkedIn !== ''">
												<td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
													<v-icon class="apptext--text mr-2">icons8-linkedin</v-icon>
													<div class="body-2">
														<a :href="item.orgLinkedIn" target="_blank">{{ item.orgLinkedIn }}</a>
													</div>
												</td>
											</tr>
											<tr v-if="item.orgFacebook !== ''">
												<td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
													<v-icon class="apptext--text mr-2">icons8-facebook</v-icon>
													<div class="body-2">
														<a :href="item.orgFacebook" target="_blank">{{ item.orgFacebook }}</a>
													</div>
												</td>
											</tr>
											<tr v-if="item.orgInstagram !== ''">
												<td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
													<v-icon class="apptext--text mr-2">icons8-instagram</v-icon>
													<div class="body-2">
														<a :href="item.orgInstagram" target="_blank">{{ item.orgInstagram }}</a>
													</div>
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-col>
					<!-- METADATA INFO -->
					<v-col cols="12" sm="12" :md="inputCols">
						<v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="text-left primary--text text-h6 font-weight-light">Metadata</span>
									<v-spacer />
									<v-icon medium class="primary--text">icons8-secured-letter</v-icon>
								</div>
								<v-divider class="grey lighten-1 mb-1"></v-divider>
							</v-col>
							<v-col cols="12" style="height: 100% !important">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Assigned To:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.ownerUserName }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Created by:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.createdUserName }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Created:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ MIX_formatDateTime(item.createdDateTime, "x", "DD-MMM-YYYY HH:mm:ss") }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Modified by:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.modifiedUserName }}</td>
											</tr>
											<tr>
												<td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Mofdified:</td>
												<td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ MIX_formatDateTime(item.modifiedDateTime, "x", "DD-MMM-YYYY HH:mm:ss") }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<!-- Organisation Edit -->
		<v-row class="px-3 grey lighten-3" v-if="editmode && !loading">
			<!-- FORM -->
			<v-col cols="12" class="d-flex align-center">
				<v-form ref="form" lazy-validation>
					<v-row style="width: 100% !important" no-gutters>
						<!-- DETAILS EDIT -->
						<v-col cols="12" sm="12">
							<v-row dense>
								<v-col cols="12" sm="12" class="d-flex py-0 body-2 grey--text text--darken-2 text-left">
									<div>* Required Fields</div>
									<v-spacer />
									<div class="caption text-right"><strong>ID:</strong> {{ item.id }}</div>
								</v-col>
								<v-col cols="12" sm="12" class="mt-2 py-0" v-if="itemnew">
									<vuetify-google-autocomplete autocomplete="donotcomplete" dense outlined background-color="white" label="Find a Business" name="myapp-name" ref="addressInput" types="" id="map" classname="form-control" v-on:placechanged="getAddressData" country="gb" clearable> </vuetify-google-autocomplete>
								</v-col>
								<v-col cols="12" sm="6" class="py-0 d-flex align-center">
									<div class="text-left primary--text text-h6 font-weight-light">Details</div>
								</v-col>
								<v-col cols="12" sm="6" class="py-0 d-flex align-center justify-end">
									<v-checkbox v-model="item.orgApproved" hide-details="auto" class="pa-0 ma-0 mr-2" label="Approved" color="primary" true-value="1" false-value="0"></v-checkbox>
								</v-col>
								<v-col cols="12" sm="12">
									<v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col cols="12" xs="12" md="12">
									<v-text-field label="Name *" @keyup="updateOrgId" v-model="item.orgName" :rules="rules.orgName" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgName')" autocomplete="donoautocomplete"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" md="8">
									<v-text-field label="ID *" @keyup="checkIdExists" v-model="suggestedId" :rules="rules.orgId" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgName')" autocomplete="donoautocomplete"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" md="4" v-if="item.id !== suggestedId">
									<div style="height: 40px !important;" class="success white--text d-flex align-center justify-center" v-if="!suggestedIdAlreadyExists">ID is Unique</div>
									<div style="height: 40px !important;" class="error white--text d-flex align-center justify-center" v-else>ID Already Exists</div>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols">
									<v-select label="Account Type" multiple v-model="selectedAccountTypes" :rules="rules.orgAccountType" :items="accountTypes" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedAccountTypes()" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-select>
								</v-col>
								<v-col cols="12" :md="inputCols">
									<v-select label="Status" v-model="item.orgStatus" :rules="rules.orgStatus" :items="orgStatus" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('orgStatus')" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-select>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" class="">
									<v-autocomplete label="Country" v-model="filter.country" :items="countries" auto-select-first item-value="authority" outlined dense hide-details="auto" background-color="white" color="primary" no-data-text="Please select a Country first" clearable @click:clear="clearFilter('country')" autocomplete="donotautocomplete"></v-autocomplete>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" class="">
									<v-autocomplete label="Unitary Authority" v-model="item.orgLocalAuthority" :rules="rules.orgLocalAuthority" :items="computedLocalAuthority" item-text="authority" auto-select-first item-value="authority" outlined dense hide-details="auto" background-color="white" color="primary" no-data-text="Please select a Country first" clearable @click:clear="clearItem('localAuthorities')" autocomplete="donotautocomplete"></v-autocomplete>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12" xs="12" :md="inputCols" class="">
									<v-btn block @click="dialogCompaniesHouse = true" depressed color="secondary" :disabled="item.orgName === ''">Companies House Lookup</v-btn>
								</v-col>
								<v-col v-if="!listshrunk"></v-col>
								<v-col cols="12" xs="12" :md="inputCols">
									<v-text-field label="Registered Name" v-model="item.orgRegisteredName" disabled :rules="rules.orgRegisteredName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgRegisteredName')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols">
									<v-text-field label="Registration Number" v-model="item.orgRegistrationNumber" disabled :rules="rules.orgRegistrationNumber" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgRegistrationNumber')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" md="6">
									<!-- <v-autocomplete label="Parent Company" v-model="item.orgParent" :rules="rules.orgParent" :items="organisations" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgOtherAddressType')"></v-autocomplete> -->
								</v-col>
							</v-row>
							<!-- CONTACT DETAILS -->
							<v-row dense>
								<v-col cols="12" sm="6" class="d-flex align-center">
									<div class="text-left primary--text text-h6 font-weight-light">Contact Details</div>
									<v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleContactDetails = !toggleContactDetails">
										<span v-if="!toggleContactDetails">Show</span>
										<span v-if="toggleContactDetails">Hide</span>
									</v-btn>
								</v-col>
								<v-col cols="12" sm="12" v-if="toggleContactDetails">
									<v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>

								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
									<v-text-field label="Telephone *" v-model="item.orgTelephone" :rules="rules.orgTelephone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTelephone')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
									<v-text-field label="Other Telephone" v-model="item.orgOtherTelephone" :rules="rules.orgOtherTelephone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTelephone')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
									<v-text-field label="Email" v-model="item.orgEmail" :rules="rules.orgEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgEmail')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
									<v-text-field label="Website *" v-model="item.orgWebsite" :rules="rules.orgWebsite" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgWebsite')"></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<!-- ADDRESS EDIT-->
						<v-col cols="12" sm="12" class="mt-2">
							<v-row dense>
								<v-col cols="12" sm="6" class="d-flex align-center">
									<div class="text-left primary--text text-h6 font-weight-light">Address</div>
									<v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleAddress = !toggleAddress">
										<span v-if="!toggleAddress">Show</span>
										<span v-if="toggleAddress">Hide</span>
									</v-btn>
								</v-col>
								<v-col cols="12" sm="6" class="text-right"> </v-col>
								<v-col cols="12" sm="12" v-if="toggleAddress">
									<v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Address Line 1 *" v-model="item.orgAddressLine1" :rules="rules.orgAddressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAddressLine1')"></v-text-field>
								</v-col>
								<v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Address Line 2" v-model="item.orgAddressLine2" :rules="rules.orgAddressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAddressLine2')"></v-text-field>
								</v-col>
								<v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Address Line 3" v-model="item.orgAddressLine3" :rules="rules.orgAddressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAddressLine3')"></v-text-field>
								</v-col>
								<v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Town *" v-model="item.orgTown" :rules="rules.orgTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTown')"></v-text-field>
								</v-col>
								<v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
									<v-text-field label="County *" v-model="item.orgCounty" :rules="rules.orgCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgCounty')"></v-text-field>
								</v-col>
								<v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Postcode *" v-model="item.orgPostcode" :rules="rules.orgPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgPostcode')"></v-text-field>
								</v-col>
								<!-- <v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Lattitude" v-model="item.orgLat" :rules="rules.orgLat" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgLat')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Longitude" v-model="item.orgLng" :rules="rules.orgLng" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgLng')"></v-text-field>
                                </v-col> -->
							</v-row>
						</v-col>
						<!-- ASSIGNED TO EDIT -->
						<v-col cols="12" sm="12" class="mt-2">
							<v-row dense>
								<v-col cols="12" sm="6" class="d-flex align-center">
									<div class="text-left primary--text text-h6 font-weight-light">Assigned to</div>
									<v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleOwner = !toggleOwner">
										<span v-if="!toggleOwner">Show</span>
										<span v-if="toggleOwner">Hide</span>
									</v-btn>
								</v-col>
								<v-col cols="12" sm="12" v-if="toggleOwner">
									<v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col v-if="toggleOwner" cols="12" xs="12" :md="inputCols">
									<v-autocomplete label="Assigned to" v-model="selectedOwner" :items="users" item-text="ownerUserName" :rules="rules.orgOwner" auto-select-first return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOwner()">
										<template v-slot:selection="{ item }">
											{{ item.ownerUserName }}
											<div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
										</template>
										<template v-slot:item="{ item }">
											{{ item.ownerUserName }}
											<div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
										</template>
									</v-autocomplete>
								</v-col>
							</v-row>
						</v-col>
						<!-- LOGO -->
						<v-col cols="12" sm="12" class="mt-2">
							<v-row v-if="$vuetify.breakpoint.mdAndUp" dense>
								<v-col cols="12" sm="6" class="d-flex align-center">
									<div class="text-left primary--text text-h6 font-weight-light">Logo</div>
									<v-btn v-bind:disabled="item.createdDateTime === ''" class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleLogo = !toggleLogo">
										<span v-if="!toggleLogo">Show</span>
										<span v-if="toggleLogo">Hide</span>
									</v-btn>
								</v-col>
								<v-col cols="12" sm="12" v-if="toggleLogo">
									<v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleLogo" class="d-flex align-center justify-center">
									<div class="" v-if="item.orgImageId !== '' && item.orgImageId !== undefined && item.orgImageId !== null"><v-img class="my-2" width="100" height="100" contain :src="'https://storage.googleapis.com/mof-swapp-public/organisations/' + item.orgImageId + '.jpeg'" /></div>
									<div class="" v-if="item.orgImageId === '' || item.orgImageId === undefined || item.orgImageId === null"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleLogo">
									<ImageUpload accept="image/jpeg, image/jpg, image/png" label="Select file" buttonClass="primary" progressClass="py-4 pa-2" folderLocation="organisations/" :fileName="item.id" :compressionMaxSizeMB="1" :compressionmaxWidthOrHeight="500" :selectedItem="item" :private="false" :createThumbnail="false" v-on:update="updateItemImage()" />
                  <v-btn v-if="item.orgImageId !== '' && item.orgImageId !== undefined && item.orgImageId !== null" class="red darken-2 white--text ml-3" style="min-width: 95%;" depressed @click="removeImage">
                    Remove Image
                  </v-btn>
                </v-col>
							</v-row>
						</v-col>
						<!-- ORGANISATOIN INFO EDIT -->
						<v-col cols="12" sm="12" class="mt-2">
							<v-row dense>
								<v-col cols="12" sm="6" class="d-flex align-center">
									<div class="text-left primary--text text-h6 font-weight-light">Organisation Info</div>
									<v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleOrganisationInfo = !toggleOrganisationInfo">
										<span v-if="!toggleOrganisationInfo">Show</span>
										<span v-if="toggleOrganisationInfo">Hide</span>
									</v-btn>
								</v-col>
								<v-col cols="12" sm="6" class="text-right"> </v-col>
								<v-col cols="12" sm="12" v-if="toggleOrganisationInfo">
									<v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
									<v-select label="Channel" multiple v-model="selectedChannel" :rules="rules.orgChannel" :items="channels" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedChannel()" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
								</v-col>
								<v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
									<v-select label="Industry" v-model="selectedIndustry" :rules="rules.orgIndustry" :items="industries" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedIndustries()" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
									<!-- <v-text-field label="Industry" v-model="item.orgIndustry" :rules="rules.orgIndustry" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field> -->
								</v-col>
								<v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
									<v-text-field label="No. of Employees" v-model="item.orgEmployees" :rules="rules.orgEmployees" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgEmployees')"></v-text-field>
								</v-col>
								<!-- <v-col v-if="toggleOrganisationInfo && item.orgChannel.includes('Education')" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="No. of Pupils" v-model="item.orgPupils" :rules="rules.orgPupils" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgEmployees')"></v-text-field>
                                </v-col> -->
								<v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Turnover" v-model="item.orgTurnover" :rules="rules.orgTurnover" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTurnover')"></v-text-field>
								</v-col>
								<v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
									<v-autocomplete label="Source" v-model="item.orgSource" :items="sources" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgOtherAddressType')" auto-select-first></v-autocomplete>
								</v-col>
								<v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Name of Source" v-model="item.orgSourceName" :rules="rules.orgSourceName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgSourceName')"></v-text-field>
								</v-col>
								<v-col v-if="toggleOrganisationInfo && item.orgAccountTypes.includes('Supplier')" cols="12" xs="12" :md="inputCols">
									<v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field dense background-color="white" outlined v-model="computedDate1" label="Price File Change Date" hide-details="auto" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
										</template>
										<v-date-picker v-model="date1" no-title @input="dateMenu1 = false"></v-date-picker>
									</v-menu>
									<!-- <v-text-field label="Price File Change Date" v-model="item.orgPriceFileChange" :rules="rules.orgPriceFileChange" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgPriceFileChange')"></v-text-field> -->
								</v-col>
							</v-row>
						</v-col>
						<!-- FINANCE & ACCOUNTING EDIT -->
						<v-col cols="12" sm="12" class="mt-2">
							<v-row dense>
								<v-col cols="12" sm="6" class="d-flex align-center">
									<div class="text-left primary--text text-h6 font-weight-light">Finance & Accounting</div>
									<v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleFinance = !toggleFinance">
										<span v-if="!toggleFinance">Show</span>
										<span v-if="toggleFinance">Hide</span>
									</v-btn>
								</v-col>
								<v-col cols="12" sm="12" v-if="toggleFinance">
									<v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleFinance">
									<v-text-field label="Invoice Email" v-model="item.orgInvoiceEmail" :rules="rules.orgInvoiceEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInvoiceEmail')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleFinance">
									<v-text-field label="Purchasing Email" v-model="item.orgPurchasingEmail" :rules="rules.orgPurchasingEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgPurchasingEmail')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleFinance">
									<v-text-field label="Finance Account Number (Interprise)" v-model="item.orgFinanceAccountNumber" :rules="rules.orgFinanceAccountNumber" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgFinanceAccountNumber')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" :md="inputCols" v-if="toggleFinance">
									<v-text-field label="Credit Limit" v-model="item.orgAccountCreditLimit" :rules="rules.orgAccountCreditLimit" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAccountCreditLimit')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" md="12" v-if="toggleFinance" class="d-flex align-center">
									<div class="">Account On Hold:</div>
									<div class="ml-2">
										<v-radio-group v-model="item.orgAccountOnHold" row dense> <v-radio label="Yes" value="1"></v-radio> <v-radio label="No" value="0"></v-radio> </v-radio-group>
									</div>
								</v-col>
							</v-row>
						</v-col>
						<!-- //* SOCIAL INFO EDIT-->
						<v-col cols="12" sm="12" class="mt-2">
							<v-row dense>
								<v-col cols="12" sm="6" class="d-flex align-center">
									<div class="text-left primary--text text-h6 font-weight-light">Social</div>
									<v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleSocial = !toggleSocial">
										<span v-if="!toggleSocial">Show</span>
										<span v-if="toggleSocial">Hide</span>
									</v-btn>
								</v-col>
								<v-col cols="12" sm="12" v-if="toggleSocial">
									<v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>

								<v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Twitter" v-model="item.orgTwitter" :rules="rules.orgTwitter" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTwitter')"></v-text-field>
								</v-col>
								<v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Facebook Link" v-model="item.orgFacebook" :rules="rules.orgFacebook" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgFacebook')"></v-text-field>
								</v-col>
								<v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
									<v-text-field label="LinkedIn Profile" v-model="item.orgLinkedIn" :rules="rules.orgLinkedIn" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgLinkedIn')"></v-text-field>
								</v-col>
								<v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
									<v-text-field label="Instagram Handle" v-model="item.orgInstagram" :rules="rules.orgInstagram" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInstagram')"></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<!-- Tags Edit -->
						<v-col cols="12" sm="12" md="12" class="mt-2 mb-0">
							<v-row dense>
								<v-col cols="12" sm="6" class="d-flex align-center">
									<div class="text-left primary--text text-h6 font-weight-light">Tags</div>
									<v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleTags = !toggleTags">
										<span v-if="!toggleTags">Show</span>
										<span v-if="toggleTags">Hide</span>
									</v-btn>
								</v-col>
								<v-col cols="12" sm="12" v-if="toggleTags">
									<v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col v-if="toggleTags" cols="12" xs="12" md="12">
									<v-combobox multiple small-chips hide-selected label="Tags" v-model="selectedTags" :search-input.sync="tagAutocomplete" @change="tagAutocomplete = ''" :items="orgTags" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteChannel')">
										<template v-slot:selection="{ attrs, item, parent, selected }">
											<v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
												<span class="pr-2">
													{{ formatTag(item) }}
												</span>
												<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
											</v-chip>
										</template>
										<template v-slot:no-data>
											<v-list-item>
												<span class="subheading">Create</span>
												<v-chip color="grey lighten-3" label small>
													{{ formatTag(tagAutocomplete) }}
												</v-chip>
											</v-list-item>
										</template>
									</v-combobox>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>
		<!-- Save / Cancel Buttons -->
		<v-row dense v-if="editmode && !loading" :style="$vuetify.breakpoint.smAndDown ? 'position: fixed !important; bottom: 0px !important; width: 100vw !important' : ''">
			<v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'mb-5' : ''" class="mt-2 grey darken-1 pa-3 px-4 d-flex justify-right align-center">
				<div>
					<v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
				</div>
				<v-spacer />
				<div class="ml-2">
					<v-btn class="accent primary--text font-weight-bold" depressed @click="validateForm()"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
				</div>
			</v-col>
		</v-row>
		<!-- // * COMPANIES HOUSE -->
		<v-dialog v-model="dialogCompaniesHouse" width="1024">
			<v-card class="mx-auto rounded">
				<!-- Preview Tab -->
				<companies-house :datadown="item.orgName" :key="dialogCompaniesHouseKey" v-on:updatech="updateCHDetails" v-on:closech="closeCH" />
				<!-- // TODO NEW PRODUCTS NOT ADDING -->
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import companiesHouse from "@/views/organisations/companiesHouse.vue";
export default {
	components: {
		"companies-house": companiesHouse,
	},
	props: {
		datadown: {
			type: Object,
			default: () => {
				return {};
			},
		},
		dense: {
			type: Boolean,
			default: false,
		},
		itemnew: {
			type: Boolean,
			default: false,
		},
		listshrunk: {
			type: Boolean,
			default: false,
		},
		orgtype: {
			type: String,
			default: "",
		},
	},
	// * DATA
	data: (vm) => ({
		editmode: false,
		loading: true,
		// Item
		item: {},
		suggestedId: "",
		suggestedIdAlreadyExists: false,
		address: {},

		// Dropdowns
		orgStatus: [],
		localAuthority: [],
		countries: [],
		sources: [],
		filter: {
			orgStatus: "Active",
			country: "",
		},

		// Validation
		rules: {},

		// dialogs
		dialogCompaniesHouse: false,
		dialogCompaniesHouseKey: 0,

		// tags
		orgTags: [], // Required for orgs tags dropdown

		tagAutocomplete: "",
		selectedOwner: null,

		// Toggles
		toggleFinance: false,
		toggleContactDetails: true,
		toggleLogo: false,
		toggleAddress: true,
		addManualAddress: false,
		toggleOtherAddress: false,
		toggleOrganisationInfo: false,
		addNotesToggle: false,
		toggleOwner: true,
		toggleSocial: false,
		toggleTags: false,

		// Form items
		selectedAccountTypes: [],
		selectedIndustry: [],
		selectedTags: [],
		selectedChannel: [],
		accountTypes: [],
		industries: [],
		channels: [],

		date1: vm.$moment().format("YYYY-MM-DD"),
		dateMenu1: false,
	}),
	computed: {
		...mapGetters({
			GET_dense: "GET_dense",
		}),
		inputCols() {
			if (this.listshrunk) {
				return "4";
			} else {
				return "6";
			}
		},
		// Computed Dates
		computedDate1() {
			return this.$moment(this.date1, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},
		computedLocalAuthority() {
			if (this.filter.country === "") {
				return [];
			} else {
				let localAuthorities = this.localAuthority;
				// order by authority
				localAuthorities.sort((a, b) => {
					if (a.authority < b.authority) {
						return -1;
					}
					if (a.authority > b.authority) {
						return 1;
					}
					return 0;
				});

				return localAuthorities.filter((item) => {
					return item.country === this.filter.country;
				});
			}
		},
		computedProfileScore() {
			let score = 0;
			let totalScore = 14;
			if (this.item?.orgName !== "" && this.item?.orgName !== undefined && this.item?.orgName !== null) {
				score += 1;
			}
			if (this.item?.registeredName !== "" && this.item?.registeredName !== undefined && this.item?.registeredName !== null) {
				score += 1;
			}
			if (this.item?.registrationNumber !== "" && this.item?.registrationNumber !== undefined && this.item?.registrationNumber !== null) {
				score += 1;
			}
			if (this.item?.orgTelephone !== "" && this.item?.orgTelephone !== undefined && this.item?.orgTelephone !== null) {
				score += 1;
			}
			if (this.item?.orgOtherTelephone !== "" && this.item?.orgOtherTelephone !== undefined && this.item?.orgOtherTelephone !== null) {
				score += 1;
			}
			// if (this.item?.orgEmail !== '' && this.item?.orgEmail !== undefined && this.item?.orgEmail !== null) {
			//     score += 1;
			// }
			if (this.item?.orgWebsite !== "" && this.item?.orgWebsite !== undefined && this.item?.orgWebsite !== null) {
				score += 1;
			}
			if (this.iten?.orgAddressLine1 !== "" && this.item?.orgAddressLine1 !== undefined && this.item?.orgAddressLine1 !== null) {
				score += 1;
			}
			if (this.item?.orgAddressLine2 !== "" && this.item?.orgAddressLine2 !== undefined && this.item?.orgAddressLine2 !== null) {
				score += 1;
			}
			if (this.item?.orgTown !== "" && this.item?.orgTown !== undefined && this.item?.orgTown !== null) {
				score += 1;
			}
			if (this.item?.orgCounty !== "" && this.item?.orgCounty !== undefined && this.item?.orgCounty !== null) {
				score += 1;
			}
			if (this.item?.orgPostcode !== "" && this.item?.orgPostcode !== undefined && this.item?.orgPostcode !== null) {
				score += 1;
			}
			if (this.item?.orgCountry !== "" && this.item?.orgCountry !== undefined && this.item?.orgCountry !== null) {
				score += 1;
			}
			if (this.item?.orgLocalAuthority !== "" && this.item?.orgLocalAuthority !== undefined && this.item?.orgLocalAuthority !== null) {
				score += 1;
			}
			let scoring = Math.round((score / totalScore) * 100);
			switch (true) {
				case scoring >= 0 && scoring < 25:
					return {
						score: scoring,
						color: "error",
						name: "Weak",
						message: "This profile is weak, please add more information.",
					};
				case scoring >= 25 && scoring < 75:
					return {
						score: scoring,
						color: "warning",
						name: "Intermediate",
						message: "This profile is ok, please add more information.",
					};

				case scoring >= 75:
					return {
						score: scoring,
						color: "success",
						name: "Strong",
						message: "This profile is good",
					};
				default:
					return {
						score: 0,
						color: "info",
						name: "",
						message: "",
					};
			}
		},
	},
	// * METHODS
	methods: {
		// Load Data Down
		loadDataDown() {
			this.item = { ...this.datadown };
			if (JSON.stringify(this.item.orgAccountTypes) !== "[]") {
				this.selectedAccountTypes = this.item.orgAccountTypes.split(",");
			}
			if (this.itemnew) {
				this.suggestedId = '';
				this.item.id = '';
				this.editmode = true;
				// if selectedAccountTypes is not empty, then set the selectedAccountTypes
				if (JSON.stringify(this.selectedAccountTypes) === "[]") {
					this.selectedAccountTypes.push(this.orgtype);
				}
			} else {
				this.editmode = false;
				this.suggestedId = this.item.id;
				this.filter.country = this.item.orgCountry;
				this.checkIdExists();
			}
			if (this.item.orgTags !== "" && this.item.orgTags !== undefined && this.item.orgTags !== null) {
				this.selectedTags = this.item.orgTags.split(",");
			}
			if (this.item.orgIndustry !== "" && this.item.orgIndustry !== undefined && this.item.orgIndustry !== null) {
				this.selectedIndustry = this.item.orgIndustry.split(",");
			}
			if (this.item.orgChannel !== "" && this.item.orgChannel !== undefined && this.item.orgChannel !== null) {
				this.selectedChannel = this.item.orgChannel.split(",");
			}
			if (this.item.ownerUserId !== "" && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
				this.selectedOwner = { ownerUserId: this.datadown.ownerUserId, ownerUserName: this.datadown.ownerUserName, ownerUserEmail: this.datadown.ownerUserEmail };
			} else {
				this.selectedOwner = this.MIX_getCurrentOwner();
			}

		},
		// Get Address Data
		getAddressData: function (addressData, placeResultData, id) {
			// console.log(JSON.stringify(placeResultData, null, 2));
			console.log(id);
			this.item.orgName = placeResultData.name;
			this.updateOrgId();
			let premise = placeResultData.address_components.filter((component) => component.types.includes("premise"));
			let street_number = placeResultData.address_components.filter((component) => component.types.includes("street_number"));
			let route = placeResultData.address_components.filter((component) => component.types.includes("route"));
			let sublocality_level_1 = placeResultData.address_components.filter((component) => component.types.includes("sublocality_level_1"));
			let sublocality_level_2 = placeResultData.address_components.filter((component) => component.types.includes("sublocality_level_2"));
			let locality = placeResultData.address_components.filter((component) => component.types.includes("locality"));
			let administrative_area_level_2 = placeResultData.address_components.filter((component) => component.types.includes("administrative_area_level_2"));
			//let country = placeResultData.address_components.filter((component) => component.types.includes('country'));
			let postal_code = placeResultData.address_components.filter((component) => component.types.includes("postal_code"));
			let postal_town = placeResultData.address_components.filter((component) => component.types.includes("postal_town"));
			let formatted_phone_number = placeResultData.formatted_phone_number;

			var addressLine1 = "";
			var addressLine2 = "";
			// Address Line 1
			// console.log("premise", premise);
			if (JSON.stringify(premise) !== "[]") {
				addressLine1 = premise[0]["long_name"];
			}
			if (JSON.stringify(street_number) !== "[]") {
				addressLine1 = addressLine1 + " " + street_number[0]["long_name"];
			}
			if (JSON.stringify(route) !== "[]") {
				addressLine1 = addressLine1 + " " + route[0]["long_name"];
			}
			if (addressLine1 !== null && addressLine1 !== undefined && addressLine1 !== "") {
				this.item.orgAddressLine1 = addressLine1.trim();
			} else {
				this.item.orgAddressLine1 = placeResultData.formatted_address.split(",")[0];
			}
			// Address Line 2
			if (JSON.stringify(sublocality_level_1) !== "[]") {
				addressLine2 = sublocality_level_1[0]["long_name"];
			}
			if (JSON.stringify(sublocality_level_2) !== "[]") {
				addressLine2 = addressLine2 + " " + sublocality_level_2[0]["long_name"];
			}
			if (JSON.stringify(locality) !== "[]") {
				addressLine2 = addressLine2 + " " + locality[0]["long_name"];
			}
			if (addressLine2 !== null && addressLine2 !== undefined && addressLine2 !== "") {
				this.item.orgAddressLine2 = addressLine2.trim();
			}
			// Address Line 3
			this.item.orgAddressLine3 = "";
			// Check for Postal Town
			if (JSON.stringify(postal_town) !== "[]") {
				this.item.orgTown = postal_town[0]["long_name"];
			}
			// Check for County
			if (JSON.stringify(administrative_area_level_2) !== "[]") {
				this.item.orgCounty = administrative_area_level_2[0]["long_name"];
			}
			// Check for Country
			// if (JSON.stringify(country) !== '[]') {
			//     this.item.orgCountry = country[0]['long_name'];
			// }
			// Check for postcode
			if (JSON.stringify(postal_code) !== "[]") {
				this.item.orgPostcode = postal_code[0]["long_name"];
			}
			// Add LAttitude and Longitude
			// this.item.orgLat = placeResultData.geometry.location.lat;
			// this.item.orgLng = placeResultData.geometry.location.lng;
			// Check for Phone Number
			if (formatted_phone_number !== null && formatted_phone_number !== undefined && formatted_phone_number !== "") {
				this.item.orgTelephone = formatted_phone_number.replace(/\s/g, "");
			}
			// Add Website
			this.item.orgWebsite = placeResultData.website;
		},
		// Cancel Item
		cancelItem() {
			this.editmode = false;
			this.$emit("cancel");
		},
		// Close
		close() {
			this.$emit("close");
		},
    // Validate Email
    validateEmail(v) {
      const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regEx.test(v);
    },
    // Validate Telephone
    validateTelephone(v) {
      const regEx = /^[0-9]{10,11}$/;
      return regEx.test(v);
    },
    // Validate Website
    validateWebsite(v) {
      const regEx = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
      return regEx.test(v);
    },
		// Validate Form
		validateForm() {
			this.rules = {
				orgName: [(v) => !!v || "Name Required"],
				// orgId: [(v) => !!v || "ID Required"]
				// orgId must be a minimum of 20 characters
				orgId: [(v) => v.length >= 20 || 'ID must be at least 20 characters'],
				orgAccountType: [(v) => !!v || "Account Type Required"],
				orgTelephone: [(v) => this.validateTelephone(v) || "Telephone Invalid"],
				orgWebsite: [(v) => this.validateWebsite(v) || "Website Invalid"],
				orgEmail: [(v) => this.validateEmail(v) || 'Email is invalid'],
				orgAddressLine1: [(v) => !!v || "Address Line 1 required"],
				orgTown: [(v) => !!v || "Town Required"],
				orgCounty: [(v) => !!v || "County Required"],
				orgPostcode: [(v) => !!v || "Postcode Required"],
				orgChannel: [(v) => !!v || "Channel Required"],
				orgOwner: [(v) => !!v || "Assigned to Required"],
			};
			this.$nextTick(() => {
				if (this.$refs.form.validate()) {
					// alert("Form Validated - Saving")
					this.saveItem();
				} else {
					this.MIX_alertBox({ color: "error", timeout: 4000, message: "Please fill out all the fields", show: true });
				}
			});
		},
		// Save Item
		saveItem() {
			this.item.orgProfileScore = this.computedProfileScore.score;
			this.item.ownerUserId = this.selectedOwner.ownerUserId;
			this.item.ownerUserName = this.selectedOwner.ownerUserName;
			this.item.ownerUserEmail = this.selectedOwner.ownerUserEmail;
			if (JSON.stringify(this.selectedAccountTypes) !== "[]") {
				this.selectedAccountTypes = this.selectedAccountTypes.filter((item) => {
					return item !== "";
				});
				this.item.orgAccountTypes = this.selectedAccountTypes.join(",");
			}
			if (JSON.stringify(this.selectedChannel) !== "[]") {
				this.selectedChannel = this.selectedChannel.filter((item) => {
					return item !== "";
				});
				this.item.orgChannel = this.selectedChannel.join(",");
			}
			if (JSON.stringify(this.selectedTags) !== "[]") {
				this.selectedTags = this.selectedTags.filter((item) => {
					return item !== "";
				});
				this.item.orgTags = this.selectedTags.join(",");
			}
			if (this.selectedIndustry !== "") {
				this.item.orgIndustry = this.selectedIndustry;
			}
			this.$emit("save", this.item);
			this.editmode = false;
		},
		// Clear Selected Account Types
		clearSelectedAccountTypes() {
			setTimeout(() => {
				this.selectedAccountTypes = [];
			}, 1);
		},
		// Clear Selected Channel
		clearSelectedChannel() {
			setTimeout(() => {
				this.selectedChannel = [];
			}, 1);
		},
		// Clear Selected Industry
		clearSelectedIndustries() {
			setTimeout(() => {
				this.selectedIndustry = [];
			}, 1);
		},
		// Clear Selected Owner
		clearSelectedOwner() {
			setTimeout(() => {
				this.selectedOwner = null;
			}, 1);
		},
		// Clear Item Property
		clearItem(property) {
			setTimeout(() => {
				this.item[property] = "";
			}, 1);
		},
		// Clear Item Property
		clearFilter(property) {
			setTimeout(() => {
				this.filter[property] = "";
			}, 1);
		},
		updateCHDetails(value) {
			// console.log("updateCHDetails");
			// console.log("value = ", JSON.stringify(value, null, 2));
			this.item.orgRegisteredName = value.registeredName;
			this.item.orgRegistrationNumber = value.registrationNumber;
			this.dialogCompaniesHouse = false;
			this.dialogCompaniesHouseKey++;
		},
		closeCH() {
			this.dialogCompaniesHouse = false;
			this.dialogCompaniesHouseKey++;
		},
		formatTag: function (item) {
			if (item !== null) {
				return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
			} else {
				return "";
			}
		},
		updateItemImage() {
			this.item.orgImageId = this.item.id;
		},
		async checkIdExists() {			
			// this.item.id = this.suggestedId;
			let checkExists = await this.MIX_redisExists('org:'+this.suggestedId)
			if (checkExists.code === 1) {
				this.suggestedIdAlreadyExists = true;
			} else {
				this.suggestedIdAlreadyExists = false;
			}
		},
		async updateOrgId() {
			// Convert name to all uppercase and remove any spaces or special characters
			const upperCaseName = this.item.orgName.toUpperCase().replace(/[^A-Z0-9]/g, "");
			// Calculate how many zeros we need to add to the beginning of the name
			const numZeros = Math.max(19 - upperCaseName.length, 0);
			// Pad the name with zeros to make it at least 20 characters long and add "1" to the end
			const paddedName = upperCaseName + "0".repeat(numZeros) + "1";
			this.suggestedId = paddedName;
			await this.checkIdExists();
		},
    async removeImage(){
      this.item.orgImageId = null;
      this.$emit("save", this.item);
    }
	},
	// * WATCH
	watch: {
		datadown: {
			handler: function () {
				this.loadDataDown();
			},
			deep: true,
		},
		selectedAccountTypes: {
			handler: function () {
				this.item.orgAccountTypes = this.selectedAccountTypes.join(",");
			},
		},
		selectedIndustry: {
			handler: function () {
				this.item.orgIndustry = this.selectedIndustry.join(",");
			},
		},
		// Dates
		dateMenu1() {
			this.item.orgPriceFileChange = this.$moment(this.date1, "YYYY-MM-DD").format("X");
			// this.checkDates();
		},
		selectedChannel: {
			handler: function () {
				this.item.orgChannel = this.selectedChannel.join(",");
			},
		},
		filter: {
			handler: function () {
				this.item.orgCountry = this.filter.country;
			},
			deep: true,
		},
		// Selected Tags
		selectedTags: {
			handler: async function () {
				// Force Title Case on Tags
				for (let i = 0; i < this.selectedTags.length; i++) {
					this.selectedTags[i] = this.selectedTags[i].toLowerCase(); //replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
					this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, " ");
					if (this.selectedTags[i] === " ") {
						this.selectedTags.splice(i, 1);
					} else {
						this.selectedTags[i] = this.selectedTags[i].trim();
					}
					if (this.orgTags.indexOf(this.selectedTags[i]) === -1) {
						let addToSetResult = await this.MIX_redisAddToSet(`set:orgTags`, this.selectedTags[i]);
						if (addToSetResult.code === -1) {
							//console.log(addToSetResult.message);
						}
					}
				}
				// Update Quote Tags and split out array with commas
				this.item.orgTags = this.selectedTags.join(",");
			},
			deep: true,
		},
		itemnew: {
			handler: function () {
				if (this.itemnew) {
					this.editmode = true;
					this.selectedAccountTypes.push(this.orgtype);
				}
			},
		},
	},
	// * CREATED
	async created() {
		let t = this;
		t.loading = true;
		let users = await this.MIX_users(""); // Add Users to Dropdown for Owner
		this.users = users.map((x) => {
			return {
				ownerUserId: x.id,
				ownerUserName: x.userName,
				ownerUserEmail: x.userEmail,
			};
		});

    if(this.$route.name === 'Suppliers'){
      this.orgtype = 'Supplier';
    } else if(this.$route.name === 'Customers'){
      this.orgtype = 'Customer';
    }

		// Get Sources
		let sources = await t.MIX_redisReadSet("set:sources", true);
		if (sources.code === 1) {
			t.sources = sources.data;
		}
		// Get Local Authority
		let localAuthoritiesResult = await this.MIX_redisSearch("idx:localAuthority", `*`, 0, 9999);
		t.localAuthority = localAuthoritiesResult.data.documents.map((x) => x.value);
		// Get Countries
		t.countries = Array.from(new Set(t.localAuthority.map((x) => x.country)));
		// Get Account Types
		let accountTypes = await t.MIX_redisReadSet("set:orgAccountTypes", false);
		if (accountTypes.code === 1) {
			t.accountTypes = accountTypes.data;
		}
		// Get Organisation Status SortedSet
		let orgStatus = await t.MIX_redisReadSortedSet("sset:orgStatus", 0, 9999);
		if (orgStatus.code === 1) {
			t.orgStatus = orgStatus.data;
		}

		let orgTags = await t.MIX_redisReadSet("set:orgTags", false);
		if (orgTags.code === 1) {
			t.orgTags = orgTags.data;
		}

		let industries = await t.MIX_redisReadSet("set:orgIndustry", false);
		if (industries.code === 1) {
			t.industries = industries.data;
		}

		let organisations = await t.MIX_organisations();
		if (organisations.code === 1) {
			t.organisations = organisations.data;
		}

		let channels = await t.MIX_redisReadSet("set:channels", false);
		if (channels.code === 1) {
			t.channels = channels.data;
		}
		t.loadDataDown();
		t.loading = false;

		//t.item = { ...t.datadown };
	},
};
</script>

<style></style>
