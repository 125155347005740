<template>
    <v-row fill-height class="grey lighten-2" style="height: 100% !important; overflow-y: scroll !important" no-gutters>
        <v-col class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            <div class="d-flex align-center mb-1">
                <div class="d-flex align-center">
                    <div><v-icon class="grey--text" x-large>icons8-tags</v-icon></div>
                    <div class="text-left headingtext--text text-h4 font-weight-light ml-1" v-if="!listShrunk">Products</div>
                </div>
                <v-spacer v-if="!listShrunk"></v-spacer>
                <div class="ml-1">
                    <v-btn depressed block class="accent secondary--text" @click="openItem({})"><v-icon class="mr-3">icons8-tags</v-icon>New</v-btn>
                </div>
                <v-spacer v-if="listShrunk"></v-spacer>
                <div>
                    <v-btn class="primary--text ml-2" @click="listShrunk = false" depressed v-if="listShrunk">
                        <v-icon color="primary">icons8-show-sidepanel</v-icon>
                    </v-btn>
                    <v-btn class="primary--text ml-2" @click="listShrunk = true" depressed v-if="!listExpanded && !listShrunk">
                        <v-icon color="primary">icons8-hide-sidepanel</v-icon>
                    </v-btn>
                    <!-- <v-btn class="primary--text ml-2" depressed v-if="!listShrunk && ['Developer-Admin'].includes(userLevel)" @click="dialogImport = true">
                        <v-icon color="primary">icons8-import-csv</v-icon>
                    </v-btn>
                    <v-btn disabled class="primary--text ml-2" depressed v-if="!listShrunk && ['Developer-Admin'].includes(userLevel)">
                        <v-icon color="primary">icons8-export-csv</v-icon>
                    </v-btn> -->
                </div>
            </div>
            <!-- <v-divider class="grey lighten-1 mb-4"></v-divider> -->
            <!-- // * PRODUCTS LIST -->
            <product-list :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshitems="refreshItems" section="products" />
            <!-- App Copyright -->
            <appCopyright />
        </v-col>
        <!-- // * PRODUCT -->
        <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col style="border-left: lightgrey solid 2px !important" v-if="!listExpanded" class="grey lighten-3" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <product
                    v-if="!listExpanded"
                    v-on:refreshProducts="refreshItemsMethod()"
                    :activeitem="activeItem"
                    v-on:closeItem="
                        listExpanded = true;
                        listShrunk = false;
                    "
                />
            </v-col>
        </transition>
        <!-- // * TASKBAR COMPONENT -->
        <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col style="border-left: lightgrey solid 2px !important" class="" v-if="GET_taskBar" :cols="taskCols.cols" :xs="taskCols.xs" :sm="taskCols.sm" :md="taskCols.md" :lg="taskCols.lg" :xl="taskCols.xl">
                <taskbar />
            </v-col>
        </transition>
        <!-- // * IMPORT COMPONENT -->
        <v-dialog v-model="dialogImport" fullscreen class="white">
            <v-card>
                <import-csv v-on:close="dialogImport = false" :csvheaders="productCSVHeaders" :prefix="key" rediskey="product" />
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import importCSV from '@/views/base/importCSV.vue';
import taskBar from '@/views/tasks/taskbar.vue';
import product from '@/views/products/product.vue';
import productList from '@/views/products/productList.vue';
import schema from '@/schema.js';
import { mapGetters } from 'vuex';
export default {
    // * COMPONENTS
    components: {
        'product-list': productList,
        product: product,
        taskbar: taskBar,
        'import-csv': importCSV,
    },
    // * DATA
    data: () => ({
        activeItem: {},
        refreshItems: 0,
        listExpanded: true,
        listShrunk: false,
        // Item Columns
        itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        itemCols: { cols: 12, xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
        taskCols: { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
        // Dialogs
        dialogImport: false,
        // Schema
        schema: {},
        // Key
        key: 'product',
        routeId: '',
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_dense: 'GET_dense',
            GET_destroy: 'GET_destroy',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },
        productCSVHeaders() {
            let productSchema = [];
            for (const property in schema.product) {
                productSchema.push(property);
            }
            return productSchema;
        },
    },
    // * METHODS
    methods: {
        refreshCols() {
            switch (true) {
                // Expanded List with No Task Bar
                case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Expanded List with Task Bar
                case this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // List with Product Page (No Task Bar)
                case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // List with Product Page and Task Bar
                case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // Shrunk List with Product Page (No Task Bar)
                case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    this.itemCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Shrunk List with Product Page and Task Bar)
                case !this.listExpanded && this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;

                default:
                    this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
            }
        },
        refreshItemsMethod() {
            this.refreshItems++;
        },
        openItem(item) {
            this.activeItem = item;
            this.listExpanded = false;
        },
    },
    // * WATCH
    watch: {
        listExpanded() {
            this.refreshCols();
        },
        listShrunk() {
            this.refreshCols();
        },
        GET_taskBar() {
            this.refreshCols();
        },
    },
    // * CREATED
    created() {
        this.schema = schema;
    },
    // * MOUNTED
    mounted() {
        this.refreshCols();
        // Dynamic Route for New or Existing Item
        this.routeId = this.$route.params.id;
        if (this.routeId === 'new') {
            this.openItem({});
        } else if (this.routeId !== 'new' && this.routeId !== '' && this.routeId !== undefined) {
            this.openItem({ id: this.routeId });
        }
    },
};
</script>
