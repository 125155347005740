<template>
    <v-row fill-height class="grey lighten-2" style="height: 100% !important; overflow-y: scroll !important" no-gutters>
        <v-col class="align-center pa-3 mt-1" cols="12">
            <div class="d-flex align-center mb-1">
                <div class="d-flex align-center">
                    <div><v-icon class="grey--text" x-large>icons8-search-in-list</v-icon></div>
                    <div class="text-left headingtext--text text-h4 font-weight-light ml-1">Bulk Changes</div>
                </div>
            </div>

            <div class="mt-3" style="width: 100% !important">
                Please select the bulk change you wish to make: {{ changeType }}<br />
                <v-radio-group v-model="changeType" row>
                    <v-radio label="Product" value="product"></v-radio>
                    <v-radio label="Variant" value="variant"></v-radio>
                </v-radio-group>
                <br />
                <v-btn-toggle dense v-model="fieldTypes">
                    <v-btn v-if="changeType === 'product'" class="" active-class="primary white--text" value="Categories" v-bind:disabled="fieldTypes === 'Categories'">Categories</v-btn>
                    <v-btn v-if="changeType === 'product'" class="" active-class="primary white--text" value="Lead Times" v-bind:disabled="fieldTypes === 'Lead Times'">Lead Times</v-btn>
                    <v-btn class="" active-class="primary white--text" value="Simple" v-bind:disabled="fieldTypes === 'Simple'">Simple Pricing</v-btn>
                    <v-btn class="" active-class="primary white--text" value="Volume" v-bind:disabled="fieldTypes === 'Volume'">Volume Pricing</v-btn>
                    <v-btn class="" active-class="primary white--text" value="Band" v-bind:disabled="fieldTypes === 'Band'">Band Pricing</v-btn>
                </v-btn-toggle>
                <br /><br />
                <!-- fieldTypes: {{ fieldTypes }} -->
                <!-- computedSearchQuery: {{ computedSearchQuery}}<br />  -->

                <!-- fieldTypes : {{ fieldTypes }} -->
                <!-- Total Products: {{ productsTotal }}<br />
                 selectedProducts: {{ computedSelectedProducts }}<br /> -->
                <!-- {{ computedSelectedProductsTotal }} Selected<br /><br />
        fieldTypes: {{ fieldTypes }}
        progressId: {{ progressId }} -->

                <div class="mt-3 d-flex align-start">
                    <div class="">
                        <v-autocomplete outlined auto-select-first v-model="filter.orgId" label="Select Organisation" :items="organisations" item-text="orgName" item-value="id" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('orgId', '')"></v-autocomplete>
                    </div>
                    <div class="ml-2" v-if="changeType === 'variant'">
                        <v-text-field outlined v-model="filter.productCode" label="Product Code" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('productCode', '')"></v-text-field>
                    </div>
                    <div class="ml-2" v-if="changeType === 'product'">
                        <v-autocomplete
                            :items="filterProductCategories()"
                            label="Category"
                            v-model="filter.productCategory"
                            @change="
                                filterProductSubcategories();
                                filterProductItemTypes();
                            "                            
                            outlined
                            dense
                            hide-details="auto"
                            background-color="white"
                            color="primary"
                            clearable
                            @click:clear="clearFilter('category', '')"
                            :attach="true"
                        ></v-autocomplete>
                    </div>
                    <div  class="ml-2" v-if="changeType === 'product'">
                        <v-autocomplete :disabled="filter.productCategory === ''" :items="fproductSubcategories" label="Subcategory" v-model="filter.productSubcategory" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearBulkItem('productSubcategory')" :attach="true"></v-autocomplete>
                    </div>
                    <div  class="ml-2" v-if="changeType === 'product'">
                        <v-autocomplete :disabled="filter.productCategory === ''" :items="fproductItemTypes" label="Item Type" v-model="filter.productItemType" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearBulkItem('productItemType')" :attach="true"></v-autocomplete>
                    </div>
                    <div class="ml-2">
                        <v-btn depressed @click="getProducts()">Search</v-btn>
                    </div>
                    <v-spacer />
                    <div class="ml-2">
                        <v-btn :disabled="fieldTypes !== 'Simple' && fieldTypes !== 'Volume' && fieldTypes !== 'Band'" class="primary white--text" depressed @click="importDialog = true">Import</v-btn>
                    </div>
                    <div v-if="JSON.stringify(selectedProducts) !== '[]'" class="d-flex align-center">
                        <div class="ml-2">
                            <v-btn :disabled="fieldTypes !== 'Simple' && fieldTypes !== 'Volume' && fieldTypes !== 'Band'" class="primary white--text" depressed @click="exportCSV()">Export</v-btn>
                        </div>
                        <div class="ml-2">
                            <v-btn :disabled="fieldTypes === 'Simple' || fieldTypes === 'Volume' || fieldTypes === 'Band'" class="primary white--text" depressed @click="dialogCategories = true">Bulk Change</v-btn>
                        </div>
                    </div>
                    <!-- <div>progressId: {{ progressId }}</div> -->
                </div>
                <!-- <pre>{{ filter }}</pre> -->
                <v-data-table width="100%" item-key="id" v-model="selectedProducts" show-select :single-select="singleSelect" sortable dense class="mt-3" :headers="computedHeaders" :items="computedProducts" :options.sync="datatableOptions" :loading="loading" :loading-text="'Loading Products'" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 50, 100, 500, 1000] }">
                    <!-- <template v-slot:top>
                <div class="d-flex align-center" style="width: 100% !important">
                    <div class="py-5 pl-3 body-2">
                        Showing <strong>{{ productsFrom + 1 }}</strong>
                        <span v-if="productsTo !== productsTotal">
                            to <strong>{{ productsTotal }}</strong></span
                        >
                        of <strong>{{ productsTotal }}</strong>
                    </div>
                    <v-spacer />
                    <v-spacer />
                    <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                        <v-btn value="images" small>
                            <v-icon class="grey--text text--darken-3">icons8-picture</v-icon>
                        </v-btn>
                    </v-btn-toggle> -->
                    <!-- <div class="mb-5 mx-5"><v-switch v-model="showImages" color="primary" label="Images" hide-details="auto" inset></v-switch></div>
					<div class="mb-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>-->
                    <!-- </div>
            </template> -->
                    <!-- <template v-slot:item.productName="{ item }">
                <span :title="item.productName">{{ item.productName.substring(0, 50) }}...</span>
            </template> -->

                    <!-- <template v-slot:item.productImageId="{ item }">
                <div v-if="toggleFeatures.includes('images')" class="my-2 rounded d-flex align-center justify-center" style="border: 1px solid lightgrey !important; width: 110px; height: 110px">
                    <div v-if="item.productImageId !== ''">
                        <v-img class="my-2" width="100" height="100" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/thumbnails/' + item.productImageId + '.jpeg?x=' + Date.now()" />
                    </div>
                    <div v-if="item.productImageId === ''"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
                </div>
            </template> -->
                    <!-- <template v-slot:item.importStatus="{ item }">
					<v-icon :class="item.importStatus + '--text'">icons8-filled-circle</v-icon>
				</template> -->
                </v-data-table>
                <br /><br /><br />
                <!-- {{ selectedProducts }} -->
                <v-row>
                    <v-col cols="6">
                        <!-- <pre>{{ headers }}</pre> -->
                    </v-col>
                    <!-- <v-col cols="6">
                        <pre>{{ content }}</pre>
                    </v-col> -->
                </v-row>
                <!-- Categories Dialog -->
                <v-dialog v-model="dialogCategories" width="600" height="auto" v-if="!loading">
                    <v-card width="600" height="auto">
                        <v-card-title>Bulk Change</v-card-title>
                        <v-card-text>
                            The following changes will be applied to {{ computedSelectedProductsTotal }} products
                            <v-row dense class="mt-2">
                                <v-col cols="12" sm="12" v-if="fieldTypes === 'Categories'">
                                    <v-autocomplete :items="productStatus" label="Status" v-model="bulkItem.productStatus" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearBulkItem('productStatus')" :attach="true"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="fieldTypes === 'Categories'">
                                    <v-autocomplete
                                        :items="methodProductCategories()"
                                        label="Category"
                                        @change="
                                            methodProductSubcategories();
                                            methodProductItemTypes();
                                        "
                                        v-model="bulkItem.productCategory"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearBulkItem('productCategory')"
                                        :attach="true"
                                    ></v-autocomplete>
                                    <v-checkbox v-model="bulkItem.productCategory" value="Remove Value" class="pa-0 ma-0" label="Remove Value" dense hide-details="auto"></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="fieldTypes === 'Categories'">
                                    <v-autocomplete :disabled="bulkItem.productCategory === ''" :items="productSubcategories" label="Subcategory" v-model="bulkItem.productSubcategory" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearBulkItem('productSubcategory')" :attach="true"></v-autocomplete>
                                    <v-checkbox v-model="bulkItem.productSubcategory" value="Remove Value" class="pa-0 ma-0" label="Remove Value" dense hide-details="auto"></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="fieldTypes === 'Categories'">
                                    <v-autocomplete :disabled="bulkItem.productCategory === ''" :items="productItemTypes" label="Item Type" v-model="bulkItem.productItemType" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearBulkItem('productItemType')" :attach="true"></v-autocomplete>
                                    <v-checkbox v-model="bulkItem.productItemType" value="Remove Value" class="pa-0 ma-0" label="Remove Value" dense hide-details="auto"></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="fieldTypes === 'Categories'">
                                    <v-text-field label="Range" v-model="bulkItem.productRange" hide-details="auto" outlined dense background-color="white" color="primary" clearable @click:clear="clearBulkItem('productRange')"></v-text-field>
                                    <v-checkbox v-model="bulkItem.productRange" value="Remove Value" class="pa-0 ma-0" label="Remove Value" dense hide-details="auto"></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="fieldTypes === 'Lead Times'">
                                    <v-text-field label="Lead Time (Weeks)" type="number" v-model="bulkItem.productLeadTime" hide-details="auto" outlined dense background-color="white" color="primary"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" class="my-2">
                                    <v-checkbox v-model="bulkChangeConfirmation" class="pa-0 ma-0" label="Are you sure, this action cannot be undone ?" dense hide-details="auto"></v-checkbox>
                                </v-col>

                                <v-col cols="12" sm="12">
                                    <v-btn :disabled="!bulkChangeConfirmation" depressed @click="bulkChange()" class="primary white--text">Apply</v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="progressResult.status !== ''" class="d-flex justify-center flex-column align-center">
                                    <!-- </v-col> v-if="progressResult.status !== ''"> -->
                                    <v-progress-linear v-model="computedPercenteComplete" color="primary" :class="computedPercenteComplete < 50 ? 'black--text' : 'white--text'" height="25">
                                        <strong v-if="progressResult.status !== ''">{{ progressResult.count }}/{{ progressResult.totalItems }}</strong>
                                    </v-progress-linear>
                                    <div class="mt-4 font-weight-bold primary--text" v-if="progressResult.status === 'Completed'">Bulk Change Completed</div>
                                    <div class="mt-4" v-if="progressResult.status === 'Completed'"><v-btn depressed @click="closeCategoriesDialog()">Close</v-btn></div>

                                    <!-- <div>Progress: {{ progressResult.count }}/{{ progressResult.totalItems }} - {{ progressResult.status }}</div> -->
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="importDialog" width="30vw">
                    <v-card width="30vw">
                        <v-card-title class="headline primary white--text d-flex">Import</v-card-title>
                        <v-card-text class="text-left mt-4">
                            <span class="error--text font-weight-bold">Important: You should only import data using CSV files exported from this screen. Do not add/change or delete any field/column names in the CSV file. </span>
                            <br /><br />

                            Upload CSV File.
                            <div class="mt-5"><input type="file" accept=".csv" @change="handleFileUpload($event)" /></div>
                            <div class="mt-4" style="width: 100% !important">
                                <v-progress-linear v-model="computedPercenteComplete" color="primary" :class="computedPercenteComplete < 50 ? 'black--text' : 'white--text'" height="25">
                                    <strong v-if="progressResult.status !== ''">{{ progressResult.count }}/{{ progressResult.totalItems }}</strong>
                                </v-progress-linear>
                            </div>
                            <div class="mt-4 d-flex align-center justify-center">
                                <v-btn :disabled="progressResult.status !== 'Completed'" depressed @click="closeImportDialog()" class="primary white--text">Close</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </v-col>
    </v-row>
</template>

<script>
// import pgsr from "@/import/pgsr.json";

export default {
    name: "productsBulk",
    data: () => ({
        products: [],
        productsTotal: 0,
        productsFrom: 0,
        productsTo: 10,
        loading: false,
        importDialog: false,
        changeType: "product",
        file: "",
        content: [],
        parsed: false,
        progress: false,
        singleSelect: false,
        dialogCategories: false,
        toggleFeatures: [],
        fieldTypes: "Categories",
        bulkItem: {
            productCategory: "",
            productSubcategory: "",
            productItemType: "",
            productRange: "",
            productLeadTime: "",
        },
        bulkChangeConfirmation: false,
        fproductSubcategories: [],
        fproductItemTypes: [],
        productSubcategories: [],
        productItemTypes: [],
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: "@productName",
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["productName"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        // Headers to display in the Data Tabl
        headers: [],
        variantHeaders: [
            { text: "ID", value: "id", sortable: false, hidden: true, fieldType: "" },
            { text: "Variant Code", value: "variantSupplierCode", sortable: false, hidden: false, fieldType: "Standard" },
            { text: "Variant Previous Code", value: "variantPreviousCode", sortable: false, hidden: false, fieldType: "Standard" },
            { text: "Product Code", value: "productCode", sortable: false, hidden: false, fieldType: "Standard" },
            // { text: "Name", value: "variantDescription", sortable: false, hidden: true, fieldType: "Standard" },
            { text: "Status", value: "variantStatus", sortable: false, hidden: true, fieldType: "Standard" },
            { text: "Lead time (Weeks)", value: "variantLeadTime", align: "center", sortable: false, hidden: true, fieldType: "Lead Times" },
            { text: "MOQ", value: "MOQ", sortable: false, hidden: true, fieldType: "Pricing" },
            { text: "Pricing Type", value: "pricingType", sortable: false, hidden: true, fieldType: "Standard" },
            { text: "RRP", value: "RRP", sortable: false, hidden: true, fieldType: "Pricing" }, // Recommended Retail Price
            { text: "MP", value: "MP", sortable: false, hidden: true, fieldType: "Pricing" }, // Market Price
            { text: "Cost Qty 1", value: "cQ1", sortable: false, hidden: true, fieldType: "Pricing" }, // Cost Qty 1
            { text: "Cost Price 1", value: "cP1", sortable: false, hidden: true, fieldType: "Pricing" }, // Cost Price 1
            { text: "Cost Qty 2", value: "cQ2", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Cost Qty 2
            { text: "Cost Price 2", value: "cP2", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Cost Price 2
            { text: "Cost Qty 3", value: "cQ3", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Cost Qty 3
            { text: "Cost Price 3", value: "cP3", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Cost Price 3
            { text: "Sell Qty 1", value: "sQ1", sortable: false, hidden: true, fieldType: "Pricing" }, // Sell Qty 1
            { text: "Sell Price 1", value: "sP1", sortable: false, hidden: true, fieldType: "Pricing" }, // Sell Price 1
            { text: "Sell Qty 2", value: "sQ2", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Sell Qty 2
            { text: "Sell Price 2", value: "sP2", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Sell Price 2
            { text: "Sell Qty 3", value: "sQ3", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Sell Qty 3
            { text: "Sell Price 3", value: "sP3", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Sell Price 3
            { text: "Cost Stocked", value: "cStocked", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Stocked
            { text: "Sell Stocked", value: "sStocked", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Stocked
            { text: "Cost Band A", value: "cBA", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band A
            { text: "Cost Band B", value: "cBB", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band B
            { text: "Cost Band C", value: "cBC", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band C
            { text: "Cost Band D", value: "cBD", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band D
            { text: "Cost Band E", value: "cBE", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band E
            { text: "Cost Band F", value: "cBF", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band F
            { text: "Sell Band A", value: "sBA", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band A
            { text: "Sell Band B", value: "sBB", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band B
            { text: "Sell Band C", value: "sBC", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band C
            { text: "Sell Band D", value: "sBD", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band D
            { text: "Sell Band E", value: "sBE", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band E
            { text: "Sell Band F", value: "sBF", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band F
            { text: "NPS", value: "NPS", sortable: false, hidden: true, fieldType: "Pricing" }, // NPS Core Product Pricing
        ],
        productHeaders: [
            { text: "Image Id", value: "productImageId", sortable: false, hidden: false, fieldType: "" },
            { text: "ID", value: "id", sortable: false, hidden: true, fieldType: "" },
            { text: "Supplier Code", value: "productSupplierCode", sortable: false, hidden: false, fieldType: "Standard" },
            { text: "Code", value: "productCode", sortable: false, hidden: true, fieldType: "" },
            { text: "MOF Previous Code", value: "productPreviousCode", sortable: false, hidden: false, fieldType: "Standard" },
            // { text: "Name", value: "productName", sortable: false, hidden: false, fieldType: "Standard" },
            { text: "Status", value: "productStatus", sortable: false, hidden: true, fieldType: "Standard" },
            { text: "Available From", value: "productAvailableFrom", sortable: false, hidden: true, fieldType: "" },
            { text: "Available To", value: "productAvailableTo", sortable: false, hidden: true, fieldType: "" },
            { text: "Short Description", value: "productShortDescription", sortable: false, hidden: true, fieldType: "" },
            { text: "Description", value: "productDescription", sortable: false, hidden: true, fieldType: "" },
            { text: "Lead time (Weeks)", value: "productLeadTime", align: "center", sortable: false, hidden: true, fieldType: "Lead Times" },
            { text: "Channel", value: "productChannel", sortable: false, hidden: true, fieldType: "Categories" },
            { text: "Category", value: "productCategory", sortable: false, hidden: true, fieldType: "Categories" },
            { text: "Subcategory", value: "productSubcategory", sortable: false, hidden: true, fieldType: "Categories" },
            { text: "Item Type", value: "productItemType", sortable: false, hidden: true, fieldType: "Categories" },
            { text: "Type", value: "productType", sortable: false, hidden: false, fieldType: "Categories" },
            { text: "Range", value: "productRange", sortable: false, hidden: true, fieldType: "Categories" },
            { text: "MOQ", value: "MOQ", sortable: false, hidden: true, fieldType: "Pricing" },
            { text: "Pricing Type", value: "pricingType", sortable: false, hidden: true, fieldType: "Standard" },
            { text: "RRP", value: "RRP", sortable: false, hidden: true, fieldType: "Pricing" }, // Recommended Retail Price
            { text: "MP", value: "MP", sortable: false, hidden: true, fieldType: "Pricing" }, // Market Price
            { text: "Cost Qty 1", value: "cQ1", sortable: false, hidden: true, fieldType: "Pricing" }, // Cost Qty 1
            { text: "Cost Price 1", value: "cP1", sortable: false, hidden: true, fieldType: "Pricing" }, // Cost Price 1
            { text: "Cost Qty 2", value: "cQ2", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Cost Qty 2
            { text: "Cost Price 2", value: "cP2", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Cost Price 2
            { text: "Cost Qty 3", value: "cQ3", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Cost Qty 3
            { text: "Cost Price 3", value: "cP3", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Cost Price 3
            { text: "Sell Qty 1", value: "sQ1", sortable: false, hidden: true, fieldType: "Pricing" }, // Sell Qty 1
            { text: "Sell Price 1", value: "sP1", sortable: false, hidden: true, fieldType: "Pricing" }, // Sell Price 1
            { text: "Sell Qty 2", value: "sQ2", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Sell Qty 2
            { text: "Sell Price 2", value: "sP2", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Sell Price 2
            { text: "Sell Qty 3", value: "sQ3", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Sell Qty 3
            { text: "Sell Price 3", value: "sP3", sortable: false, hidden: true, fieldType: "Volume Pricing" }, // Sell Price 3
            { text: "Cost Stocked", value: "cStocked", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Stocked
            { text: "Sell Stocked", value: "sStocked", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Stocked
            { text: "Cost Band A", value: "cBA", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band A
            { text: "Cost Band B", value: "cBB", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band B
            { text: "Cost Band C", value: "cBC", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band C
            { text: "Cost Band D", value: "cBD", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band D
            { text: "Cost Band E", value: "cBE", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band E
            { text: "Cost Band F", value: "cBF", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Cost Band F
            { text: "Sell Band A", value: "sBA", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band A
            { text: "Sell Band B", value: "sBB", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band B
            { text: "Sell Band C", value: "sBC", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band C
            { text: "Sell Band D", value: "sBD", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band D
            { text: "Sell Band E", value: "sBE", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band E
            { text: "Sell Band F", value: "sBF", sortable: false, hidden: true, fieldType: "Band Pricing" }, // Sell Band F
            { text: "NPS", value: "NPS", sortable: false, hidden: true, fieldType: "Pricing" }, // NPS Core Product Pricing
        ],
        selectedProducts: [],
        // Filters
        searchQuery: "",
        filter: {
            productType: "SPB",
            orgId: "",
            productCode: "",
            productCategory: '',
            productSubcategory: '',
            productItemType: '',
            quoteTagsOperator: "ANY",
            quoteTags: [],
            productChannel: [],
        },
        organisations: [],
        productTypes: [],
        productStatus: [],
        pgsr: {},
        progressId: "",
        progressResult: {
            totalItems: "",
            count: "",
            error: "",
            status: "",
        },
    }),
    computed: {
        computedSelectedProducts() {
            return this.selectedProducts.map((x) => x.id);
        },
        computedSelectedProductsByHeaders() {
            // remove keys from selectedProducts that are not in headers
            const selectedProducts = this.selectedProducts.map((x) => {
                const obj = {};
                this.computedHeaders.forEach((y) => {
                    obj[y.value] = x[y.value];
                });
                obj.id = x.id;
                return obj;
            });

            return selectedProducts;
        },
        computedPercenteComplete() {
            let percentComplete = Math.ceil((Number(this.progressResult.count) / Number(this.progressResult.totalItems)) * 100);
            if (isNaN(percentComplete)) {
                percentComplete = 0;
            }
            return percentComplete;
        },
        computedSelectedProductsTotal() {
            return this.computedSelectedProducts.length;
        },
        computedHeaders() {
            // let headers = this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            let headers = this.headers;
            // headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            let fieldTypes = [];
            if (this.fieldTypes === "Simple") {
                fieldTypes.push("Pricing");
                fieldTypes.push("Standard");
            }
            if (this.fieldTypes === "Volume") {
                fieldTypes.push("Volume Pricing");
                fieldTypes.push("Standard");
            }
            if (this.fieldTypes === "Band") {
                fieldTypes.push("Band Pricing");
                fieldTypes.push("Standard");
            }
            if (this.fieldTypes === "Lead Times") {
                fieldTypes.push("Lead Times");
                fieldTypes.push("Standard");
            }

            if (this.fieldTypes === "Categories") {
                fieldTypes.push("Categories");
                fieldTypes.push("Standard");
            }
            // console.log('fieldTypes ', fieldTypes);
            headers = headers.filter((header) => fieldTypes.includes(header.fieldType)); //  === 'Standard' || header.fieldType === this.fieldTypes)); // Only return headers that are not hidden
            // console.log('headers => ' + JSON.stringify(headers, null, 2))
            return headers;
        },
        computedProducts() {
            var products = this.products; //.filter(x => x.);
            return products; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        computedFields() {
            // console.log('computedHeaders = ' + JSON.stringify(this.computedHeaders))
            let computedFields = this.computedHeaders.filter((x) => x.hidden === false).map((y) => "@" + y.value);
            if (!computedFields.includes("@id")) {
                computedFields.push("@id");
            }
            if (!computedFields.includes("@productCode")) {
                computedFields.push("@productCode");
            }
            if (!computedFields.includes("@productSupplierCode")) {
                computedFields.push("@productSupplierCode");
            }
            if (!computedFields.includes("@productPreviousCode")) {
                computedFields.push("@productPreviousCode");
            }
            if (!computedFields.includes("@deleted")) {
                computedFields.push("@deleted");
            }
            if (!computedFields.includes("@hasOptions")) {
                computedFields.push("@hasOptions");
            }
            if (!computedFields.includes("@hasVariants")) {
                computedFields.push("@hasVariants");
            }
            //if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
            computedFields = computedFields.join(",");
            return computedFields;
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            // remove - from searchQuery
            if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
                if (this.toggleDeletedItems) {
                    searchQuery = "*";
                } else {
                    searchQuery = "@deleted:0";
                }
            } else {
                if (searchQuery.includes("-")) {
                    searchQuery = searchQuery.replace(/-/g, "");
                }
                if (searchQuery.startsWith("@")) {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }

            if (this.fieldTypes === 'Simple') {
                searchQuery = `${searchQuery} @pricingType:{Simple}`;
            }
            if (this.fieldTypes === 'Volume') {
                searchQuery = `${searchQuery} @pricingType:{Volume}`;
            }
            if (this.fieldTypes === 'Band') {
                searchQuery = `${searchQuery} @pricingType:{Band}`;
            }
            if (this.filter.productCode !== "") {
                searchQuery = `${searchQuery} @productCode:${this.filter.productCode}`;
            }

            // Channel Filter
            if (JSON.stringify(this.filter.productChannel) !== "[]") {
                searchQuery = `${searchQuery} @productChannel:{${this.filter.productChannel.join("|")}}`;
            }
            // Product Category Filter
            if (this.filter.productCategory !== "" && this.changeType === "product") {
                searchQuery = `${searchQuery} @productCategory:{${this.filter.productCategory}}`;
            }
            // Product Subcategory Filter
            if (this.filter.productSubcategory !== "" && this.changeType === "product") {
                searchQuery = `${searchQuery} @productSubcategory:{${this.filter.productSubcategory}}`;
            }
            // Product Item Type Filter
            if (this.filter.productItemType !== "" && this.changeType === "product") {
                searchQuery = `${searchQuery} @productItemType:{${this.filter.productItemType}}`;
            }

            // Product Type Filter
            if (JSON.stringify(this.filter.productType) !== "" && this.changeType === "product") {
                searchQuery = `${searchQuery} @productType:{${this.filter.productType}}`;
            }
            // Organisation Filter
            if (this.filter.orgId !== "" && this.filter.orgId !== null && this.filter.orgId !== undefined) {
                searchQuery = `${searchQuery}  @orgId:{${this.filter.orgId}}`;
            }

            // Pricing Filter
            if (this.fieldType === "Simple") {
                searchQuery = `${searchQuery} @pricingType:{Simple}`;
            }

            return searchQuery;
        },
    },
    methods: {
        closeImportDialog() {
            this.importDialog = false;
            this.progressResult = {
                totalItems: "",
                count: "",
                error: "",
                status: "",
            };
            this.products = [];
        },
        closeCategoriesDialog() {
            this.dialogCategories = false;
            this.progressResult = {
                totalItems: "",
                count: "",
                error: "",
                status: "",
            };
            this.products = [];
        },
        clearFilter(property) {
            let t = this;
            setTimeout(() => {
                t.filter[property] = "";
                t.products = [];
            }, 1);
        },
        // Clear Item Property
        clearBulkItem(property) {
            setTimeout(() => {
                this.bulkItem[property] = "";
            }, 1);
        },
        async getProgressUpdate(progressId) {
            let t = this;
            let progressResult = {};
            const myInterval = setInterval(async () => {
                progressResult = await this.MIX_redisReadHash("progress:" + progressId);
                t.progressResult = progressResult.data;
                if (progressResult.data.status === "Completed") {
                    clearInterval(myInterval);
                    // t.getProducts();
                }
            }, 1);
        },
        async getProducts() {
            // console.log('getProducts');
            this.loading = true;
            let index = "idx:"+this.changeType+"s";
            console.log('index', index)
            console.log('computedSearchQuery ', this.computedSearchQuery)
            let productsResult = await this.MIX_redisSearch(index, this.computedSearchQuery, 0, 9999);
            console.log('total = ' + productsResult.data.total);
            if (productsResult === 0) {
                this.products = [];
                this.productsTotal = productsResult.data.total;
            } else {
                this.products = productsResult.data.documents.map((x) => x.value);
                this.productsTotal = productsResult.data.total;
            }
            this.loading = false;
        },
        methodProductCategories: function () {
            let productCategories = this.pgsr.map((category) => category.name);
            if (productCategories !== undefined) {
                productCategories.push("Remove Value");
            }
            return productCategories;
        },
        methodProductSubcategories: function () {
            let category = this.pgsr.find((category) => category.name === this.bulkItem.productCategory);
            let subcategories = category?.productSubcategory;
            if (subcategories !== undefined) {
                subcategories.push("Remove Value");
            }
            this.productSubcategories = subcategories;
        },
        methodProductItemTypes: function () {
            let category = this.pgsr.find((category) => category.name === this.bulkItem.productCategory);
            let itemTypes = category?.itemTypes;
            if (itemTypes !== undefined) {
                itemTypes.push("Remove Value");
            } else {
                itemTypes = [];
            }
            this.productItemTypes = itemTypes;
        },
        filterProductCategories: function () {
            let productCategories = this.pgsr.map((category) => category.name);
            if (productCategories !== undefined) {
                productCategories.push("Remove Value");
            }
            return productCategories;
        },
        filterProductSubcategories: function () {
            let category = this.pgsr.find((category) => category.name === this.filter.productCategory);
            let subcategories = category?.productSubcategory;
            if (subcategories !== undefined) {
                subcategories.push("Remove Value");
            }
            this.fproductSubcategories = subcategories;
        },
        filterProductItemTypes: function () {
            let category = this.pgsr.find((category) => category.name === this.filter.productCategory);
            let itemTypes = category?.itemTypes;
            if (itemTypes !== undefined) {
                itemTypes.push("Remove Value");
            } else {
                itemTypes = [];
            }
            this.fproductItemTypes = itemTypes;
        },
        async bulkChange() {
            let bulkData = [];
            for (var i = 0; i < this.computedSelectedProducts.length; i++) {
                //console.log('this.computedSelectedProducts' + this.computedSelectedProducts[i]);
                let productData = {};
                productData.id = this.computedSelectedProducts[i];
                if (this.bulkItem.productStatus !== "") {
                    productData.productStatus = this.bulkItem.productStatus;
                }
                if (this.bulkItem.productCategory !== "") {
                    productData.productCategory = this.bulkItem.productCategory;
                }
                if (this.bulkItem.productSubcategory !== "") {
                    productData.productSubcategory = this.bulkItem.productSubcategory;
                }
                if (this.bulkItem.itemType !== "") {
                    productData.itemType = this.bulkItem.itemType;
                }
                if (this.bulkItem.productRange !== "") {
                    productData.productRange = this.bulkItem.productRange;
                }
                if (this.bulkItem.productLeadTime !== "") {
                    productData.productLeadTime = this.bulkItem.productLeadTime;
                }
                if (this.bulkItem.productCategory === "Remove Value") {
                    productData.productCategory = "";
                }
                if (this.bulkItem.productSubcategory === "Remove Value") {
                    productData.productSubcategory = "";
                }
                if (this.bulkItem.itemType === "Remove Value") {
                    productData.itemType = "";
                }
                if (this.bulkItem.productRange === "Remove Value") {
                    productData.productRange = "";
                }
                // console.log('productData = ' + JSON.stringify(productData));
                bulkData.push(productData);
            }

            // console.log('bulkData = ' + JSON.stringify(bulkData, null, 2));
            this.progressId = this.MIX_generateId();
            this.getProgressUpdate(this.progressId);
            // console.log('start bulk update');
            await this.MIX_redisBulkHashUpdate("product", bulkData, this.progressId);
            // console.log('Bulk Change Complete ' + JSON.stringify(bulkChangeResult));
            //alert('Bulk Change Complete ' + JSON.stringify(bulkChangeResult));
        },
        exportCSV() {
            let selectedProducts = JSON.parse(JSON.stringify(this.computedSelectedProductsByHeaders));
            let csv = this.$papa.unparse(selectedProducts);
            this.$papa.download(csv, "Selected Products");
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
            this.parseFile();
        },
        parseFile() {
            this.$papa.parse(this.file, {
                header: true,
                quotes: false,
                quoteChar: '"',
                dynamicTyping: false,
                skipEmptyLines: true,
                step: async (results) => {
                    let result = results.data;
                    if (result.id !== "") {
                        this.content.push(result);
                        // console.log(JSON.parse(JSON.stringify(result)))
                        // await this.$axios.post(`${process.env.VUE_APP_API_HOST}products`, result);
                    }
                },
                complete: async () => {
                    this.progressId = this.MIX_generateId();
                    this.getProgressUpdate(this.progressId);
                    // console.log('start bulk import');
                    await this.MIX_redisBulkHashUpdate(this.changeType, this.content, this.progressId);
                    // console.log('Bulk Change Complete ' + JSON.stringify(bulkChangeResult));
                    this.parsed = true;
                    // this.importDialog = false;
                },
            });
        },
        async getPGSR() {
            // console.log('getItems running...');
            let itemsResult = await this.MIX_redisReadString('PGSR');
            // console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2));
            if (itemsResult.code === 1) {
                var items = JSON.parse(itemsResult.data);
                this.pgsr = items['productCategory'];
                // console.log('this.items = ' + JSON.stringify(this.items, null, 2));
            }
        },
    },
    watch: {
        changeType: {
            handler() {
                if (this.changeType === "product") {
                    this.headers = this.productHeaders;
                } else {
                    this.headers = this.variantHeaders;
                    this.fieldTypes = "Simple"
                }
            }, deep: true
        },
        computedSearchQuery: {
            handler() {
                this.getProducts();
            }, deep: true
        },
    },
    async created() {
        let t = this;
        t.loading = true;
        this.headers = this.productHeaders;
        t.getPGSR()
        // Get Organisations
        t.organisations = await t.MIX_organisations("Supplier");
        // Get Product Types
        let productTypesResult = await t.MIX_redisReadSet("set:productTypes", true);
        if (productTypesResult.code === 1) {
            t.productTypes = productTypesResult.data;
            // console.log('productTypes = ' + JSON.stringify(t.productTypes, null, 2));
        }
        // Get Channels
        let channelsResult = await t.MIX_redisReadSet("set:channels", true);
        if (channelsResult.code === 1) {
            t.channels = channelsResult.data;
        }
        // Get Status
        let productStatusResult = await t.MIX_redisReadSortedSet("sset:productStatus", 0, 99999);
        if (productStatusResult.code === 1) {
            t.productStatus = productStatusResult.data;
        }

        t.loading = false;
    },
};
</script>

<style scoped></style>
