<template>
    <v-row class="pa-0 ma-0" fill-height style="position: fixed !important">
        <v-row no-gutters class="pb-3 px-3 pt-3 secondary">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3"><v-icon class="white--text">icons8-bill</v-icon></div>
                <div v-if="item.quoteTitle.length > 40">
                    <v-tooltip top :max-width="200">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="tooltiptext tooltip font-weight-light"
                                ><strong
                                    >Quote: <span v-if="item.quoteNumber !== ''">{{ item.quoteNumber }} -</span> </strong
                                >{{ item.quoteTitle.substring(0, 40) }}...</span
                            >
                        </template>
                        <span>{{ item.quoteTitle }}</span>
                    </v-tooltip>
                </div>
                <div v-else-if="item.quoteTitle.length <= 40">
                    <span class="font-weight-light"
                        ><strong
                            >Order: <span v-if="item.quoteNumber !== ''">{{ item.quoteNumber }} -</span> </strong
                        >{{ item.quoteTitle }}</span
                    >
                </div>

                <div class="ml-2 font-weight-light" v-if="item.quoteTitle === ''">New</div>
                <v-chip v-if="item.quoteStatus === 'Draft'" small class="ml-3 grey darken-1" dark>Draft</v-chip>
                <v-chip v-if="item.quoteStatus === 'Awaiting Approval'" small class="ml-3 warning" dark>Awaiting Approval</v-chip>
                <v-chip v-if="item.quoteStatus === 'Rejected'" small class="ml-3 yellow primary--text" dark>Rejected</v-chip>
                <v-chip v-if="item.quoteStatus === 'Ready'" small class="ml-3 info" dark>Ready</v-chip>
                <v-chip v-if="item.quoteStatus === 'Sent'" small class="ml-3 primary" dark>Sent</v-chip>
                <v-chip v-if="item.quoteStatus === 'Won'" small class="ml-3 success" dark>Won</v-chip>
                <v-chip v-if="item.quoteStatus === 'Lost'" small class="ml-3 error" dark>Lost</v-chip>
                <v-chip v-if="item.quoteStatus === 'Deleted'" small class="ml-3 black" dark>Deleted</v-chip>
                <v-chip v-if="item.quoteStatus === 'Revision'" small class="ml-3 accent black--text" dark>Revision</v-chip>

                <v-spacer></v-spacer>
                <div>
                    <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                        <v-icon>icons8-multiply</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
            <v-tabs background-color="white" class="" v-model="tab" touchless>
                <v-tab href="#details">Details</v-tab>
                <v-tab :disabled="item.quoteNumber === ''" href="#lineitems">Line Items</v-tab>
                <v-tab :disabled="item.quoteNumber === ''" href="#terms">Terms</v-tab>
                <v-tab :disabled="item.quoteNumber === ''" href="#preview">Preview</v-tab>
                <v-tab :disabled="itemNew" href="#notes">Notes</v-tab>
                <!-- <v-tab :disabled="true" href="#group">Grouping</v-tab>
                <v-tab :disabled="true" href="#related">Related</v-tab>
                <v-tab :disabled="true" href="#history">History</v-tab> -->
            </v-tabs>
            <v-tabs-items v-model="tab" fill-height>
                <!-- Quote Details / Component -->
                <v-tab-item value="details" class="grey lighten-3">
                    <quote-details dense :datadown="item" v-on:save="createItem" v-on:cancel="cancelItem" />
                </v-tab-item>
                <!-- Quote Line Items / Component -->
                <v-tab-item value="lineitems" class="grey lighten-3">
                    <quote-line-items dense :listshrunk="listshrunk" :refreshlineitems="refreshlineitems" :datadown="item" v-on:showproducts="showProducts" v-on:showquotes="showQuotes" v-on:save="createItem" />
                </v-tab-item>
                <!-- Quote Preview Tab / Component -->
                <v-tab-item value="terms" class="grey lighten-3">
                    <quote-terms dense :datadown="item" v-on:save="saveItem" v-on:cancel="cancelItem" />
                </v-tab-item>
                <!-- Quote Terms Tab / Component -->
                <v-tab-item value="preview" class="grey lighten-3">
                    <quote-preview dense :refreshlineitems="refreshlineitems" :datadown="item" :key="quotePreviewKey" />
                </v-tab-item>
<!--                 Notes Tab-->
                <v-tab-item value="notes" class="grey lighten-3" fill-height>
                    <quote-notes dense :refreshlineitems="refreshlineitems" v-on:save="saveItem" :datadown="item" />
                    <!-- <notes :datadown="item" :listshrunk="listshrunk" v-on:save="saveItem" v-on:cancel="cancelItem" /> -->
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import quoteLineItems from '@/views/orders/orderLineItems.vue';
import quoteDetails from '@/views/orders/orderDetails.vue';
import quoteTerms from '@/views/orders/orderTerms.vue';
import quotePreview from '@/views/orders/orderPreview.vue';
import quoteNotes from '@/views/orders/orderNotes.vue';
// import notes from '@/views/base/notes.vue';
import schema from '@/schema.js';
export default {
    // * COMPONENTS
    components: {
        'quote-details': quoteDetails,
        'quote-line-items': quoteLineItems,
        'quote-preview': quotePreview,
        'quote-terms': quoteTerms,
        'quote-notes': quoteNotes,
        // 'notes': notes,
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        refreshlineitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        key: 'order',
        tab: 'details',
        schema: null,
        // Item
        item: {},
        itemNew: false,
        quoteTerms: '',
        quotePreviewKey: 0,
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_dense: 'GET_dense',
        }),
    },
    // * METHODS
    methods: {
        // * INITIALISE ITEM
        async initialiseItem() {
            let t = this;
            if (JSON.stringify(t.datadown) !== '{}') {
                this.readItem(t.datadown.id);
            } else {
                t.itemNew = true;
                t.item = { ...t.schema };
                t.item.id = t.MIX_generateId();
                t.item.quoteTerms = t.quoteTerms;
                t.$emit('quoteid', t.item.id);
            }
        },
        // TODO - RG - Validation Rules Required - validateItem()
        // * CREATE ITEM
        async createItem(item) {
            // console.log('Create Item - Quote')
            let t = this;
            if (item.quoteNumber === '') {
                let quoteNumberResult = await t.MIX_redisIncrementReadString('quoteNumber');
                item.quoteNumber = quoteNumberResult.data;
                item.quoteTerms = t.quoteTerms;
            }
            // console.log(JSON.stringify(item, null, 2) + '// ' + t.key + ' /// ' + t.itemNew)
            let result = await t.MIX_createItem(item, t.key, t.itemNew);
            // console.log('quote Result = ' + JSON.stringify(result, null, 2))
            if (result.code === 1) {
                t.$emit('quotenumber', item.quoteNumber);
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                t.readItem(item.id);
                t.$emit('refreshitems');
                //t.initialiseItem()
            }
        },
        // * READ ITEM
        async readItem(itemId) {
            let t = this;
            let result = await t.MIX_readItem(itemId, t.key);
            if (result.code === 1) {
                this.item = result.data;
                t.itemNew = false;
                t.loading = false;
            }
        },
        // * DESTROY ITEM
        async destroyItem() {
            let result = await this.MIX_destroyItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        // * DELETE ITEM
        async deleteItem() {
            let result = await this.MIX_deleteItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        // * RESTORE ITEM
        async restoreItem() {
            let result = await this.MIX_restoreItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        // Show Products
        showProducts() {
            this.$emit('showproducts', { quoteId: this.item.id, quoteNumber: this.item.quoteNumber });
        },
        // Show Quotes
        showQuotes() {
            this.$emit('showquotes');
            this.$emit('refreshlineitemsmethod');
        },
        // Close Item
        closeItem() {
            this.$emit('closeitem');
        },
        cancelItem() {
            this.readItem(this.item.id);
        },
        // Save Item
        async saveItem(item) {
            if (this.itemNew) {
                this.createItem(item);
            } else {
                let result = await this.MIX_createItem(item, this.key, this.itemNew);
                if (result.code === 1) {
                    this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                    this.readItem(item.id);
                }
            }
        },
    },
    // * WATCH
    watch: {
        datadown() {
            this.initialiseItem();
            
        },
        refreshlineitems() {
            // console.log('Line Items have been Refreshed in order.vue');
        },
        item: {
            handler() {
                this.quotePreviewKey++
                //					if (this.item.quoteNumber !== '') { this.tab = "lineitems" }
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        let t = this;
        // Get Quote Standard Terms
        let quoteTermOptionsResult = await this.MIX_readItem("options", "quoteTerms")
        t.quoteTerms = quoteTermOptionsResult.data.Standard;
        t.loading = true;
        t.schema = schema.quote;
        t.initialiseItem();
    },
    // * MOUNTED
    async mounted() {},
};
</script>
