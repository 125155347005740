<template>
	<div>
		<div class="mt-2 d-flex align-start">
			<div class="flex-grow-1">
				<v-text-field label="Search Leads" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field>
				<!-- <div class="ml-1 mt-1 font-weight-bold body-2 primary--text">+ Custom Search</div> -->
			</div>
			<div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
				<div><v-autocomplete multiple outlined auto-select-first v-model="filter.orgAccountTypes" :search-input.sync="tagOrgAccountTypes" @change="tagOrgAccountTypes = ''" label="Filter by Lead Type" :items="orgAccountTypes" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedAccountTypes()"></v-autocomplete></div>
				<div class="ml-1 mt-1 body-2 app--text">
					Filter is set to <strong class="primary--text" @click="changeAccountTypesOperator()" style="cursor: pointer">{{ filter.orgAccountTypesOperator }}</strong> of the above
				</div>
			</div>
			<div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
				<div><v-autocomplete multiple outlined auto-select-first v-model="filter.orgAccountTypes" :search-input.sync="tagOrgAccountTypes" @change="tagOrgAccountTypes = ''" label="Filter by Channel" :items="orgChannels" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedAccountTypes()"></v-autocomplete></div>
				<div class="ml-1 mt-1 body-2 app--text">
					Filter is set to <strong class="primary--text" @click="changeAccountTypesOperator()" style="cursor: pointer">{{ filter.orgAccountTypesOperator }}</strong> of the above
				</div>
			</div>
			<div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
				<div><v-autocomplete multiple outlined auto-select-first v-model="filter.orgTags" :search-input.sync="tagOrgTags" @change="tagOrgTags = ''" label="Filter by Tags" :items="orgTags" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOrgTags()"></v-autocomplete></div>
				<div class="ml-1 mt-1 body-2 app--text">
					Filter is set to <strong class="primary--text" @click="changeOrgTagsOperator()" style="cursor: pointer">{{ filter.orgTagsOperator }}</strong> of the above
				</div>
			</div>
			<div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
				<v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>icons8-table-properties</v-icon></v-btn>
					</template>
					<div class="white" style="width: 250px; max-height: 300px">
						<div class="pa-5 white" style="width: 100% !important">
							<div class="body-2 font-weight-bold">Show / hide Columns</div>
							<v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
						</div>
					</div>
				</v-menu>
			</div>
		</div>
		<!-- 			
		<div>	
			filter<br />
			<pre>{{ filter }}</pre>
			<br />
			tagOrgAccountTypes<br />
			<pre>{{ tagOrgAccountTypes }}</pre>
			<br />
			computedSearchQuery: {{ computedSearchQuery}}
			
		</div> -->

		<!-- Product Category -->
		<!-- <div class="d-flex align-center">
				<v-btn-toggle multiple v-model="filter.productCategories" class="primary--text" depressed dense hide-details="auto" background-color="white" color="primary" label="Product Category">
					<v-btn v-for="(item, index) in productCategories" :key="index" >{{ item}}</v-btn>
				</v-btn-toggle>
			</div> -->

		<div class="mt-2">
			<!-- <div class="d-flex align-center">
				<div>
					Showing <strong>{{ itemsFrom + 1 }}</strong>
					<span v-if="itemsTo !== itemsTotal"
						>to <strong>{{ itemsTo }}</strong></span
					>
					of <strong>{{ itemsTotal }}</strong>
				</div>
			</div> -->
			<!-- TABLE BASED LIST -->
			<v-data-table v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions"
				:footer-props="{'items-per-page-options': [5, 10, 15, 25, 50, 100]}">
				<template v-slot:top>
					<div class="d-flex align-center" style="width: 100% !important">
						<div class="py-5 pl-3 body-2">
							Showing <strong>{{ itemsFrom + 1 }}</strong>
							<span v-if="itemsTo !== itemsTotal"
								> to <strong>{{ itemsTo }}</strong></span
							>
							of <strong>{{ itemsTotal }}</strong>
						</div>
						<v-spacer />
						<v-spacer />
						<v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
							<!-- <v-btn value="images">
								<v-icon class="grey--text text--darken-3">icons8-picture</v-icon>
							</v-btn> -->
							<v-btn value="delete">
								<v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
							</v-btn>
						</v-btn-toggle>
						<!-- <div class="mb-5 mx-5"><v-switch v-model="showImages" color="primary" label="Images" hide-details="auto" inset></v-switch></div>
					<div class="mb-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>-->
					</div>
				</template>
				<template v-slot:item.leadType="{ item }">
					<v-chip v-if="item.leadType === 'hot'" small class="error white--text font-weight-bold">Hot</v-chip>
					<v-chip v-if="item.leadType === 'warm'" small class="warning white--text font-weight-bold" >Warm</v-chip>
					<v-chip v-if="item.leadType === 'cold'" small class="info white--text font-weight-bold" >Cold</v-chip>
				</template>
				<template v-slot:item.leadTimePriority="{ item }">
					<v-chip v-if="item.leadTimePriority === 'high'" small class="error white--text font-weight-bold">High</v-chip>
					<v-chip v-if="item.leadTimePriority === 'medium'" small class="warning white--text font-weight-bold" >Medium</v-chip>
					<v-chip v-if="item.leadTimePriority === 'low'" small class="success white--text font-weight-bold" >Low</v-chip>
				</template>

				<template v-slot:item.action="{ item }">
					<v-icon class="error--text pa-1" style="border: 1px red solid; border-radius: 3px" v-if="GET_destroy" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
					<v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
					<v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1'">icons8-trash-restore</v-icon>
					<v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
				</template>
			</v-data-table>
			<!-- CARD BASED LIST -->
            <div v-if="listshrunk || $vuetify.breakpoint.smAndDown" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-1 font-weight-medium " style="width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.leadTitle }}</div>
                            <div class="caption darkgrey--text">{{ item.leadChannel }}</div>
                            <div>
                                <v-chip v-if="item.leadTimePriority === 'high'" small class=" mr-1 error white--text font-weight-bold">High Priority</v-chip>
                                <v-chip v-if="item.leadTimePriority === 'medium'" small class="mr-1 warning white--text font-weight-bold" >Medium Priority</v-chip>
                                <v-chip v-if="item.leadTimePriority === 'low'" small class="mr-1 success white--text font-weight-bold" >Low Priority</v-chip>
                           
                                <v-chip v-if="item.leadType === 'hot'" small class="mr-1 error white--text font-weight-bold">Hot Lead</v-chip>
                                <v-chip v-if="item.leadType === 'warm'" small class="mr-1 warning white--text font-weight-bold" >Warm Lead</v-chip>
                                <v-chip v-if="item.leadType === 'cold'" small class="mr-1 info white--text font-weight-bold" >Cold Lead</v-chip>
                            </div>
                            <!-- <div class="body-2">{{ item.orgPostcode }}</div> -->
                            <!-- <br />
							<span class="body-2">{{ item.supplierName }}</span
							><br />  -->
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text" @click="openItem(item)">icons8-forward</v-icon>
                        </div>
                    </div>
                </v-card>
            </div>
            <!-- LOAD MORE CARDS -->
            <v-btn v-if="listshrunk || $vuetify.breakpoint.smAndDown" class="mt-2" depressed block @click="loadMore()">Load More</v-btn>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		props: {
			refreshitems: {
				type: Number,
				default: 0,
			},
			listshrunk: {
				type: Boolean,
				default: false,
			},
			listexpanded: {
				type: Boolean,
				default: false,
			},
			orgType: {
				type: String,
				default: "",
			},
		},
		data: () => ({
			key: "lead",
			index: "idx:leads",
			toggleDeletedItems: false,

			orgTags: [],
			orgAccountTypes: [],
            orgChannels: [],

			// tag
			tagOrgAccountTypes: "",
			tagOrgTags: "",

			filter: {
				orgAccountTypesOperator: "ANY",
				orgAccountTypes: [],
                orgChannelsOperator: "ANY",
                orgChannels: [],
				orgTagsOperator: "ANY",
				orgTags: [],
			},
			// Toggles
			toggleFeatures: [],

			showImages: false,
			items: [],
			itemsTotal: null,
			searchQuery: "",
			itemsFrom: 0,
			itemsTo: 9,
			// activeItem: {},
			// Headers to display in the Data Table
			headers: [
				{ text: "ID", value: "id", sortable: false, hidden: true, expandable: true },
				{ text: "Lead Country", value: "leadCountry", sortable: false, hidden: true, expandable: true },
				{ text: "Lead Local Authority", value: "leadLocalAuthority", sortable: true, hidden: true, expandable: true },
				{ text: "Lead Tags", value: "leadTags", sortable: false, hidden: true, expandable: true },
				{ text: "Lead Title", value: "leadTitle", sortable: false, hidden: false, expandable: false },
				{ text: "Lead Channel", value: "leadChannel", sortable: false, hidden: false, expandable: true },
				{ text: "Lead Type", value: "leadType", sortable: false, hidden: false, expandable: false },
				{ text: "Lead Time Priority", value: "leadTimePriority", sortable: false, hidden: false, expandable: false },

				{ text: "Lead Source", value: "leadSource", sortable: false, hidden: true, expandable: false },
				{ text: "Lead Source Name", value: "leadSourceName", sortable: false, hidden: true, expandable: false },
				{ text: "Lead Description", value: "leadDescription", sortable: false, hidden: true, expandable: false },
				{ text: "Lead Org Tags", value: "leadOrgTags", sortable: false, hidden: true, expandable: false },

				{ text: "Date Time (C)", value: "createdDateTime", sortable: false, hidden: true, expandable: false },
				{ text: "User ID (C)", value: "createdUserId", sortable: false, hidden: true, expandable: false },
				{ text: "User Name (C)", value: "createdUserName", sortable: false, hidden: true, expandable: false },
				{ text: "User Email (C)", value: "createdUserEmail", sortable: false, hidden: true, expandable: false },
				{ text: "Date Time (M)", value: "modifiedDateTime", sortable: false, hidden: true, expandable: false },
				{ text: "User ID (M)", value: "modifiedUserId", sortable: false, hidden: true, expandable: false },
				{ text: "User Name (M)", value: "modifiedUserName", sortable: false, hidden: true, expandable: false },
				{ text: "User Email (M)", value: "modifiedUserEmail", sortable: false, hidden: true, expandable: false },
				{ text: "Deleted", value: "deleted", sortable: false, hidden: true, expandable: false },
				{ text: "Date Time (D)", value: "deletedDateTime", sortable: false, hidden: true, expandable: false },
				{ text: "User ID (D)", value: "deletedUserId", sortable: false, hidden: true, expandable: false },
				{ text: "User Name (D)", value: "deletedUserName", sortable: false, hidden: true, expandable: false },
				{ text: "User Email (D)", value: "deletedUserEmail", sortable: false, hidden: true, expandable: false },
				{ text: "Action", value: "action", align: "end", sortable: false, hidden: false, expandable: false, width: "155px" },
			],
			// Fields to Retrieve from Database
			//fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
			sortBy: "@leadTitle",
			datatableOptions: {
				page: 1,
				itemsPerPage: 20,
				sortBy: ["leadTitle"],
				sortDesc: [false],
				groupBy: [],
				groupDesc: [],
				mustSort: false,
				multiSort: false,
			},
		}),
		computed: {
			...mapGetters({
				GET_taskBar: "GET_taskBar",
				GET_dense: "GET_dense",
				GET_destroy: "GET_destroy",
			}),
			computedSearchQuery() {
				let searchQuery = this.searchQuery;
				//searchQuery = searchQuery + `@deleted:0`;

				// if (this.orgType !== "") {
				// 	if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
				// 		if (this.toggleDeletedItems) {
				// 			searchQuery = `@orgAccountTypes:${this.orgType}`;
				// 		} else {
				// 			searchQuery = `@deleted:0  @orgAccountTypes:${this.orgType}`;
				// 		}
				// 	} else {
				// 		if (searchQuery.startsWith("@")) {
				// 			if (this.toggleDeletedItems) {
				// 				searchQuery = `${searchQuery}* @orgAccountTypes:${this.orgType}`;
				// 			} else {
				// 				searchQuery = `${searchQuery}*  @deleted:0  @orgAccountTypes:${this.orgType}`;
				// 			}
				// 		} else {
				// 			if (this.toggleDeletedItems) {
				// 				searchQuery = `${searchQuery}* @orgAccountTypes:${this.orgType}`;
				// 			} else {
				// 				searchQuery = `${searchQuery}*  @deleted:0  @orgAccountTypes:${this.orgType}`;
				// 			}
				// 		}
				// 	}
				// } else {
					if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
						if (this.toggleDeletedItems) {
							searchQuery = "*";
						} else {
							searchQuery = "@deleted:0";
						}
					} else {
						if (searchQuery.startsWith("@")) {
							if (this.toggleDeletedItems) {
								searchQuery = `${searchQuery}*`;
							} else {
								searchQuery = `${searchQuery}*  @deleted:0`;
							}
						} else {
							if (this.toggleDeletedItems) {
								searchQuery = `${searchQuery}*`;
							} else {
								searchQuery = `${searchQuery}*  @deleted:0`;
							}
						}
					}
				//}
				// // Account Type Filter
				// if (JSON.stringify(this.filter.orgAccountTypes) !== "[]") {
				// 	if (this.filter.orgAccountTypesOperator === "ANY") {
				// 		searchQuery = `${searchQuery} @orgAccountTypes:{${this.filter.orgAccountTypes.join("|")}}`;
				// 	} else {
				// 		let orgAccountTypes = [];
				// 		this.filter.orgAccountTypes.forEach((tag) => {
				// 			orgAccountTypes.push(`@orgAccountTypes:{${tag}}`);
				// 		});
				// 		searchQuery = `${searchQuery} ${orgAccountTypes.join(" ")}`;
				// 	}
				// }
				// // Channel Filter
				// if (JSON.stringify(this.filter.orgChannels) !== "[]") {
				// 	if (this.filter.orgChannelsOperator === "ANY") {
				// 		searchQuery = `${searchQuery} @orgChannel:{${this.filter.orgChannels.join("|")}}`;
				// 	} else {
				// 		let orgChannels = [];
				// 		this.filter.orgChannels.forEach((tag) => {
				// 			orgChannels.push(`@orgChannels:{${tag}}`);
				// 		});
				// 		searchQuery = `${searchQuery} ${orgChannels.join(" ")}`;
				// 	}
				// }
				// // Org Tags Filter
				// if (JSON.stringify(this.filter.orgTags) !== "[]") {
				// 	if (this.filter.orgTagsOperator === "ANY") {
				// 		searchQuery = `${searchQuery} @orgTags:{${this.filter.orgTags.join("|")}}`;
				// 	} else {
				// 		let orgTags = [];
				// 		this.filter.orgTags.forEach((tag) => {
				// 			orgTags.push(`@orgTags:{${tag}}`);
				// 		});
				// 		searchQuery = `${searchQuery} ${orgTags.join(" ")}`;
				// 	}
				// }

				return searchQuery;
			},
			computedHeaders() {
				let headers = this.headers;
				headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
				if (!this.listexpanded) {
					headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
				}
				return headers;
			},
			computedItems() {
				var items = this.items; //.filter(x => x.);
				return items; //.slice(this.organisationsFrom, this.organisationsTo);
			},
			computedFields() {
				let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => "@" + y.value);
				if (!computedFields.includes("@id")) {
					computedFields.push("@id");
				}
				//if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
				computedFields = computedFields.join(",");
				return computedFields;
			},
		},
		methods: {
			// * DESTROY ITEM FROM ITEMS
			async destroyItem(itemId) {
				let result = await this.MIX_destroyItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// * DELETE ITEM FROM ITEMS
			async deleteItem(itemId) {
				let result = await this.MIX_deleteItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// * RESTORE ITEM FROM ITEMS
			async restoreItem(itemId) {
				let result = await this.MIX_restoreItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// * OPEN ITEM
			openItem(item) {
				this.$emit("openItem", item);
				//this.activeItem = item;
				// this.listexpanded = false;
			},
			// * GET ITEMS
			async getItems() {
				let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
				if (itemsResult.code === 1) {
					this.itemsTotal = itemsResult.data.total;
					this.items = itemsResult.data.results;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}

				} else {
					// console.log("items = " + JSON.stringify(itemsResult, null, 2));
				}
			},
			// * Change Org Account Types Tag Operator
			changeAccountTypesOperator() {
				if (this.filter.orgAccountTypesOperator === "ALL") {
					this.filter.orgAccountTypesOperator = "ANY";
				} else {
					this.filter.orgAccountTypesOperator = "ALL";
				}
			},
			// Clear Selected Account Types
			clearSelectedAccountTypes() {
				setTimeout(() => {
					this.filter.orgAccountTypes = [];
				}, 1);
			},
            // Clear Selected Account Types
			clearSelectedOrgChannels() {
				setTimeout(() => {
					this.filter.orgChannels = [];
				}, 1);
			},
			// Clear Selected Org Tags
			clearSelectedOrgTags() {
				setTimeout(() => {
					this.filter.orgTags = [];
				}, 1);
			},
		},
		watch: {
			refreshitems: function (newValue) {
				if (newValue) {
					this.getItems();
				}
			},
			// If the search query changes then get updated dataset based on search query
			computedSearchQuery() {
				this.getItems();
			},
			toggleDeletedItems() {
				this.getItems();
			},
			showImages() {
				let imagesIndex = this.headers.findIndex((x) => x.value === "productMainImage");
				//console.log('imagesIndex = ' + imagesIndex)
				if (this.showImages) {
					this.headers[imagesIndex].hidden = false;
				} else {
					this.headers[imagesIndex].hidden = true;
				}
			},
			datatableOptions: {
				handler() {
					this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
					//console.log('organisationFrom=' + this.organisationFrom)
					this.getItems();
				},
				deep: true,
			},
		},
		async created() {
			let t = this;
			// Get Organisation Account Types
			let orgAccountTypes = await t.MIX_redisReadSet("set:orgAccountTypes", false);
			if (orgAccountTypes.code === 1) {
				t.orgAccountTypes = orgAccountTypes.data;
			}
            // Get Channels
            let orgChannels = await t.MIX_redisReadSet("set:channels", false);
            if (orgAccountTypes.code === 1) {
                t.orgChannels = orgChannels.data;
            }
			// Get Organisation Tags
			let orgTags = await t.MIX_redisReadSet("set:orgTags", false);
			if (orgTags.code === 1) {
				t.orgTags = orgTags.data;
			}
			if (t.orgType !== '') {
				t.filter.orgAccountTypes.push(t.orgType)
			}
			// Get Items
			this.getItems();


		},
	};
</script>
