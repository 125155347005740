var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"grey lighten-2",staticStyle:{"height":"calc(100vh - 46px) !important","overflow-y":"scroll !important"},attrs:{"fill-height":"","no-gutters":""}},[_c('v-col',{staticClass:"align-center pa-3",attrs:{"cols":_vm.itemsCols.cols,"xs":_vm.itemsCols.xs,"sm":_vm.itemsCols.sm,"md":_vm.itemsCols.md,"lg":_vm.itemsCols.lg,"xl":_vm.itemsCols.xl}},[_c('div',{staticClass:"d-flex align-center mb-1"},[_c('div',[_c('v-icon',{staticClass:"grey--text",attrs:{"x-large":""}},[_vm._v("icons8-people")])],1),_c('div',{staticClass:"text-left headingtext--text text-h4 font-weight-light ml-1"},[_vm._v("User Management")]),_c('v-spacer'),_c('div')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"flex-grow-1 my-3"},[_c('v-text-field',{attrs:{"label":"Search Users","outlined":"","dense":"","hide-details":"auto","background-color":"white","color":"primary","clearable":"","prepend-inner-icon":"icons8-search"},on:{"click:clear":function($event){_vm.searchQuery = ''}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)]),_c('v-col',{staticClass:"d-flex justify-end pa-8",attrs:{"cols":"5"}},[_c('div',{staticClass:"ml-2"},[_c('v-menu',{staticClass:"white",staticStyle:{"z-index":"99999 !important","width":"200px !important","height":"200px !important"},attrs:{"offset-y":"","close-on-content-click":false,"origin":"top right","left":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white grey--text text--darken-2",staticStyle:{"border":"1px solid grey !important","height":"40px !important"},attrs:{"depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("icons8-table-properties")])],1)]}}])},[_c('div',{staticClass:"white",staticStyle:{"width":"250px","max-height":"300px"}},[_c('div',{staticClass:"pa-5 white",staticStyle:{"width":"100% !important"}},[_c('div',{staticClass:"body-2 font-weight-bold"},[_vm._v("Show / hide Columns")]),_vm._l((_vm.headers),function(item,index){return _c('v-checkbox',{key:index,attrs:{"dense":"","hide-details":"auto","label":item.text,"true-value":false,"false-value":true},model:{value:(_vm.headers[index].hidden),callback:function ($$v) {_vm.$set(_vm.headers[index], "hidden", $$v)},expression:"headers[index].hidden"}})})],2)])])],1)])],1),(!_vm.listShrunk)?_c('v-data-table',{attrs:{"sortable":"","dense":"","headers":_vm.computedHeaders,"items":_vm.computedItems,"options":_vm.datatableOptions,"search":_vm.searchQuery},on:{"update:options":function($event){_vm.datatableOptions=$event}},scopedSlots:_vm._u([{key:"item.photoURL",fn:function(ref){
var item = ref.item;
return [(item.photoURL === '')?_c('v-avatar',{attrs:{"color":"primary white--text font-weight-bold","size":"38"}},[_vm._v(_vm._s(item.userName.match(/\b(\w)/g).join('')))]):_c('v-avatar',{attrs:{"size":"38"}},[_c('img',{attrs:{"src":item.photoURL}})])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"primary--text ml-5",on:{"click":function($event){return _vm.openItem(item)}}},[_vm._v("icons8-forward")])]}}],null,false,323716952)}):_vm._e(),(_vm.listShrunk)?_c('div',{staticStyle:{"height":"67vh","overflow-y":"scroll"}},_vm._l((_vm.computedItems),function(item,index){return _c('v-card',{key:index,staticClass:"my-2 mx-0 py-2",attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex align-center ml-1"},[_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"body-2 font-weight-bold",staticStyle:{"width":"180px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(_vm._s(item.userName))]),_vm._v(" Code: "),_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(item.userName))])]),_c('v-spacer'),_c('div',{staticClass:"text-right mr-3"},[_c('v-icon',{staticClass:"primary--text",on:{"click":function($event){_vm.openItem(item)
							_vm.listExpanded = false;}}},[_vm._v("icons8-forward")])],1)],1)])}),1):_vm._e()],1),(_vm.activeItem)?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn","mode":"out-in"}},[(!_vm.listExpanded)?_c('v-col',{staticClass:"grey lighten-3",staticStyle:{"border-left":"lightgrey solid 2px !important"},attrs:{"cols":"12","xs":_vm.adminCols.xs,"sm":_vm.adminCols.sm,"md":_vm.adminCols.md,"lg":_vm.adminCols.lg,"xl":_vm.adminCols.xl}},[(!_vm.listExpanded)?_c('UserView',{attrs:{"activeitem":_vm.activeItem},on:{"updateUsers":function($event){return _vm.updateUsers()},"closeItem":function($event){_vm.listExpanded = true}}}):_vm._e()],1):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn","mode":"out-in"}},[(_vm.GET_taskBar)?_c('v-col',{staticStyle:{"border-left":"lightgrey solid 2px !important"},attrs:{"cols":_vm.taskCols.cols,"xs":_vm.taskCols.xs,"sm":_vm.taskCols.sm,"md":_vm.taskCols.md,"lg":_vm.taskCols.lg,"xl":_vm.taskCols.xl}},[_c('Taskbar')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }