<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow: scroll !important" >
    <!-- :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important;' : 'height: calc(100vh - 120px) !important;'"> -->
        <!-- Menu Buttons -->
        <v-row v-if="!pu" class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <!-- <div>
                    editmode {{ editmode }} | loading {{ loading}}
                </div>   -->
                <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                <div v-if="$vuetify.breakpoint.smAndDown" class="white--text text-h6 font-weight-bold pl-3">
                    <div>{{ item.contactFullName }}</div>
                    <div @click="close()" class="font-weight-normal caption"><v-icon size="15" class="white--text pa-0">icons8-back</v-icon> Return to List</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="validateForm()" :large="!dense"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- <v-row dense>
            <v-progress-linear value="50" color="warning" height="20">
                <template v-slot:default="{ value }">
                    <span class="caption font-weight-bold white--text">Profile Score {{ Math.ceil(value) }}%</span>
                </template>
            </v-progress-linear>
        </v-row> -->
        <!-- Contact Details View -->
        <v-row class="apptext--text pt-3 px-3" dense v-if="!editmode && !loading">
            <v-col v-if="!editmode" cols="12" class="d-flex align-center justify-start rounded">
                <v-row style="width: 100% !important" dense>
                    <!-- Full Name / Job Title / Department / Marketing -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light">Names</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Full Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.contactTitle }} {{ item.contactFirstName }} {{ item.contactLastName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Job Title:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.contactJobTitle }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Job Level:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.contactJobLevel }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Department:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.contactJobDepartment }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" sm="12" :md="inputCols">
                        <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light">Profile Score</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-identification-documents</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>

                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important" class="mt-5 d-flex align-start justify-start">
                                <div>
                                    <v-progress-circular :rotate="-90" :size="100" :width="15" :value="computedProfileScore.score" :color="computedProfileScore.color"> {{ computedProfileScore.score }} % </v-progress-circular>
                                </div>
                                <div class="ml-5">
                                    <span class="font-weight-bold" :class="computedProfileScore.color + '--text'">{{ computedProfileScore.name }}</span>
                                    <p>{{ computedProfileScore.message }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Telepjhone Numbers and Email -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light">Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>

                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Email:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">
                                                    <a :href="'mailto:' + item.contactEmail" target="_blank">{{ item.contactEmail }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Alternate Email:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">
                                                    <a :href="'mailto:' + item.contactAlternateEmail" target="_blank">{{ item.contactAlternateEmail }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Mobile Phone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.contactMobilePhone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">DDI Phone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.contactDDIPhone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Switchboard Phone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.contactSwitchboard }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Extension No:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.contactExtension }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Address -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light">Address</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-map-marker</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody style="height: 100px" class="grey lighten-5">
                                            <tr class="d-flex grey lighten-5">
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Address:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">
                                                    <a :href="`https://www.google.com/maps/search/?api=1&query=${item.contactAddressLine1},${item.contactAddressLine2},${item.contactAddressLine3},${item.contactTown},${item.contactCounty},${item.contactPostcode}`" target="_blank">
                                                        <span v-if="item.contactAddressLine1"
                                                            >{{ item.contactAddressLine1 }}<span v-if="item.contactAddressLine2">, {{ item.contactAddressLine2 }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.contactAddressLine3">{{ item.contactAddressLine3 }}<br /></span>
                                                        <span v-if="item.contactTown"
                                                            >{{ item.contactTown }}<span v-if="item.contactCounty">, {{ item.contactCounty }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.contactPostcode">{{ item.contactPostcode }}<br /></span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Organisation -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light">Organisation</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-city</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Organisation Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.orgName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Reporting To:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.contactReportingTo }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Social Networks -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light">Social Networks</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-people-working-together</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important" v-if="item.contactTwitter || item.contactLinkedIn || item.contactFacebook || item.contactInstagram">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-if="item.contactTwitter">
                                                <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">icons8-twitter</v-icon>
                                                    <div class="body-2 pointer">
                                                        <a :href="item.contactTwitter" target="_blank">{{ item.contactTwitter }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="item.contactLinkedIn">
                                                <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">icons8-linkedin</v-icon>
                                                    <div class="body-2">
                                                        <a :href="item.contactLinkedIn" target="_blank">{{ item.contactLinkedIn }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="item.contactFacebook">
                                                <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">icons8-facebook</v-icon>
                                                    <div class="body-2">
                                                        <a :href="item.contactFacebook" target="_blank">{{ item.contactFacebook }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="item.contactInstagram">
                                                <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">icons8-instagram</v-icon>
                                                    <div class="body-2">
                                                        <a :href="item.contactInstagram" target="_blank">{{ item.contactInstagram }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Metadata -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light">Metadata</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">icons8-info-squared</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Assigned to:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.ownerUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Created by:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.createdUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Created:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YYYY HH:mm:ss') }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Modified by:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ item.modifiedUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Mofdified:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5">{{ MIX_formatDateTime(item.modifiedDateTime, 'x', 'DD-MMM-YYYY HH:mm:ss') }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- Contact Details Edit -->
        <v-row class="px-3 mt-3" v-if="editmode && !loading">
            <v-col cols="12" class="d-flex align-center">
                <v-form ref="form" v-on:submit.prevent="login()" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- GENERAL DETAILS EDIT-->
                        <v-col cols="12" sm="12" class="d-flex py-0 body-2 grey--text text--darken-2 text-left">
                            <div>* Required Fields</div>
                            <v-spacer />
                            <div class="caption text-right"><strong>ID:</strong> {{ item.id }}</div>
                        </v-col>
                        <!-- <pre>{{ item }}</pre> -->
                        <v-col cols="12" sm="12" class="mt-2 mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="2" class="my-1">
                                    <v-select label="Title" v-model="contactName.title" :items="titles" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearContactName('contactTwitter', '')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="5" class="my-1">
                                    <v-text-field label="First Name *" v-model="contactName.firstName" :rules="rules.contactFirstName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="5" class="my-1">
                                    <v-text-field label="Last Name *" v-model="contactName.lastName" :rules="rules.contactLastName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" class="my-1">
                                    <v-text-field label="Full Name *" v-model="item.contactFullName" :rules="rules.contactFullName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactFullName', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Email" v-model="item.contactEmail" :rules="rules.contactEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactEmail', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Alternate Email" v-model="item.contactAlternateEmail"  outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactAlternateEmail', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Mobile Phone" v-model="item.contactMobilePhone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactMobilePhone', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Direct Dial Phone" v-model="item.contactDDIPhone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactDDIPhone', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Switchboard Phone" v-model="item.contactSwitchboard" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactSwitchboard', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Extension No." v-model="item.contactExtension" :rules="rules.contactExtension" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactExtension', '')"></v-text-field>
                                </v-col>
                                <!-- //* MARKETING UNSUBSCRIBE -->
                                <v-col cols="12" xs="12" md="6" class="my-1 d-flex align-center">
                                    <v-radio-group v-model="item.contactUnsubscribe" label="Unsubscribed from mailing list:" row hide-details="auto">
                                        <v-radio label="Yes" value="1"></v-radio>
                                        <v-radio label="No" value="0"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- ORGANISATOIN EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Organisation</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleOrganisation = !toggleOrganisation">
                                        <span v-if="!toggleOrganisation">Show</span>
                                        <span v-if="toggleOrganisation">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleOrganisation">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleOrganisation" cols="12" xs="12" md="6" class="my-1">
                                    <v-autocomplete label="Organisation *" v-model="selectedOrganisation" :rules="rules.contactOrganisation" :items="organisations" item-text="orgName" return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOrganisation()"></v-autocomplete>
                                </v-col>
                                <v-col v-if="toggleOrganisation" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Reporting To" v-model="item.contactReportingTo" :rules="rules.contactReportingTo" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactReportingTo', '')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- JOB ROLE INFO EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Job Role</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleJobRole = !toggleJobRole">
                                        <span v-if="!toggleJobRole">Show</span>
                                        <span v-if="toggleJobRole">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleJobRole">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" class="my-1" v-if="toggleJobRole">
                                    <v-text-field label="Job Title" v-model="item.contactJobTitle" :rules="rules.contactJobTitle" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1" v-if="toggleJobRole">
                                    <v-autocomplete label="Job Level *" v-model="item.contactJobLevel" :rules="rules.contactJobLevel" :items="jobLevels" auto-select-first outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearselectedJobLevel()" autocomplete="donotautocomplete"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1" v-if="toggleJobRole">
                                    <v-autocomplete label="Department *" v-model="item.contactJobDepartment" :rules="rules.contactJobDepartment" :items="jobDepartments" auto-select-first outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearselectedJobDepartment()" autocomplete="donotautocomplete"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- ASSIGNED TO  -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Assigned to</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleOwner = !toggleOwner">
                                        <span v-if="!toggleOwner">Show</span>
                                        <span v-if="toggleOwner">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleOwner">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleOwner" cols="12" xs="12" :md="inputCols">
                                    <v-autocomplete label="Assigned to *" v-model="selectedOwner" :rules="rules.contactOwner" :items="users" item-text="ownerUserName" auto-select-first return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOwner()">
                                        <template v-slot:selection="{ item }">
                                            {{ item.ownerUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ item.ownerUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- //* ADDRESS DETAILS EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Address</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleAddress = !toggleAddress">
                                        <span v-if="!toggleAddress">Show</span>
                                        <span v-if="toggleAddress">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleAddress">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Address Line 1" v-model="item.contactAddressLine1" :rules="rules.orgAddressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Address Line 2" v-model="item.contactAddressLine2" :rules="rules.orgAddressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Address Line 3" v-model="item.contactAddressLine3" :rules="rules.orgAddressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Town" v-model="item.contactTown" :rules="rules.orgTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="County" v-model="item.contactCounty" :rules="rules.orgCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Postcode" v-model="item.contactPostcode" :rules="rules.orgPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- //* SOURCE EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Source</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleSource = !toggleSource">
                                        <span v-if="!toggleSource">Show</span>
                                        <span v-if="toggleSource">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleSource">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleSource" cols="12" xs="12" :md="inputCols">
                                    <v-autocomplete label="Source" v-model="item.contactSource" :items="sources" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactSource')" auto-select-first></v-autocomplete>
                                </v-col>
                                <v-col v-if="toggleSource" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Name of Source" v-model="item.contactSourceName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactSourceName')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- //* SOCIAL INFO EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Social</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleSocial = !toggleSocial">
                                        <span v-if="!toggleSocial">Show</span>
                                        <span v-if="toggleSocial">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleSocial">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>

                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Twitter" v-model="item.contactTwitter" :rules="rules.orgTwitter" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactTwitter')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Facebook Link" v-model="item.contactFacebook" :rules="rules.orgFacebook" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactFacebook')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="LinkedIn Profile" v-model="item.contactLinkedIn" :rules="rules.orgLinkedIn" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactLinkedIn')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Instagram Handle" v-model="item.contactInstagram" :rules="rules.orgInstagram" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactInstagram')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <!-- <v-col cols="12">
                <pre>{{ item }}</pre>
            </v-col> -->

            <!-- Save / Cancel Buttons -->
            <v-row v-if="!pu" dense :style="$vuetify.breakpoint.smAndDown ? 'position: fixed !important; bottom: 0px !important; width: 100vw !important' : ''">
                <v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'mb-5' : ''" class="mt-2 grey darken-1 pa-3 pr-4 d-flex justify-right align-center">
                    <div class="ml-1">
                        <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                    </div>
                    <v-spacer />
                    <div class="ml-2">
                        <v-btn class="accent primary--text font-weight-bold" depressed @click="validateForm()"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-row>
    </div>
</template>

<script>
import schema from '@/schema.js';
import { mapGetters } from 'vuex';
export default {
    // * PROPS
    props: {
        dense: {
            type: Boolean,
            default: false,
        },
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        org: {
            type: Object,
            default: function () {
                return {};
            },
        },
        fullContact: {
            type: Boolean,
            default: false,
        },
        quickContact: {
            type: Boolean,
            default: false,
        },
        pu: {
            type: Boolean,
            default: false,
        },
        newItem: {
            type: Boolean, 
            default: false
        }
    },
    // * DATA
    data: () => ({
        item: {},
        loading: true,
        // newItem: true,
        schema: {},
        key: 'contact',
        index: 'idx:contacts',
        editmode: false,
        // Items
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 9,

        // contactName
        contactName: {
            title: '',
            firstName: '',
            lastName: '',
        },

        activeItem: {},
        searchQuery: '',
        // dropdowns
        titles: ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'],

        options: [],
        optionSelected: {},
        // Validation
        rules: {},

        sources: [],

        // Users
        users: [],

        // Toggles
        toggleJobRole: true,
        toggleOrganisation: true,
        toggleSocial: false,
        toggleAddress: false,
        toggleSource: false,

        toggleJSON: false,
        toggleAddAddress: false,
        toggleAddOtherAddress: false,
        toggleOwner: true,
        toggleAddLeadStatus: false,
        toggleAddOrgInfo: false,
        toggleAddSocialInfo: false,
        addManualAddress: false, // TODO - RG - Is this a Toggle
        // Organisation Dropdown
        organisations: [],
        selectedOrganisation: null,
        selectedOwner: null,
        jobLevels: [],
        jobDepartments: [],
    }),
    // * COMPUTED
    computed: {
        // Map Getters
        ...mapGetters({
            GET_dense: 'GET_dense',
            GET_FIREBASE_userData: 'firebase_auth_store/GET_FIREBASE_userData',
        }),
        // Input Cols
        inputCols() {
            if (this.listshrunk) {
                return '4';
            } else {
                return '6';
            }
        },
        // Computed Profile Score
        computedProfileScore() {
            let score = 0;
            let totalScore = 8;
            if (this.item?.contactFirstName !== '' && this.item?.contactFirstName !== undefined && this.item?.contactFirstName !== null) {
                score += 1;
            }
            if (this.item?.contactLastName !== '' && this.item?.contactLastName !== undefined && this.item?.contactLastName !== null) {
                score += 1;
            }
            if (this.item?.contactEmail !== '' && this.item?.contactEmail !== undefined && this.item?.contactEmail !== null) {
                score += 1;
            }
            if (this.item?.contactMobilePhone !== '' && this.item?.contactMobilePhone !== undefined && this.item?.contactMobilePhone !== null) {
                score += 1;
            }
            if (this.item?.contactDDIPhone !== '' && this.item?.contactDDIPhone !== undefined && this.item?.contactDDIPhone !== null) {
                score += 1;
            }
            if (this.item?.contactJobTitle !== '' && this.item?.contactJobTitle !== undefined && this.item?.contactJobTitle !== null) {
                score += 1;
            }
            if (this.item?.contactJobLevel !== '' && this.item?.contactJobLevel !== undefined && this.item?.contactJobLevel !== null) {
                score += 1;
            }
            if (this.item?.contactJobDepartment !== '' && this.item?.contactJobDepartment !== undefined && this.item?.contactJobDepartment !== null) {
                score += 1;
            }
            let scoring = Math.round((score / totalScore) * 100);
            switch (true) {
                case scoring >= 0 && scoring < 25:
                    return {
                        score: scoring,
                        color: 'error',
                        name: 'Weak',
                        message: 'This profile is weak, please add more information.',
                    };
                case scoring >= 25 && scoring < 75:
                    return {
                        score: scoring,
                        color: 'warning',
                        name: 'Intermediate',
                        message: 'This profile is ok, please add more information.',
                    };

                case scoring >= 75:
                    return {
                        score: scoring,
                        color: 'success',
                        name: 'Strong',
                        message: 'This profile is strong',
                    };
                default:
                    return {
                        score: 0,
                        color: 'info',
                        name: '',
                        message: '',
                    };
            }
        },
    },
    // * METHODS
    methods: {
        // Load Data Down
        async loadDataDown() {
            this.schema = schema.contact;
            // console.log('this.datadown', JSON.stringify(this.datadown, null, 2));
            if (this.newItem === true) {
                // console.log('new item')
                this.item = { ...this.schema }
                this.editmode = true;
            } else {
                // console.log('existing item')
                var id = this.datadown.id
                // console.log('id = ' + JSON.stringify(id, null, 2))
                let itemResult = await this.MIX_readItem(id, "contact");
                if (itemResult.code === 1) {
                    this.item = itemResult.data
                }
                // console.log("item = " + JSON.stringify(this.item, null, 2))
                // this.item = { ...this.datadown };
                this.editmode = false;
            }
            if (this.pu === true) {
                console.log('popup')
                // this.item = { ...this.schema }
                this.editmode = true
            }

            // if (this.item.contactFirstName === '') {
            //     this.editmode = true;
            // } else {
            //     this.editmode = false;
            // }
            if (this.item.orgId !== '') {
                this.selectedOrganisation = {
                    id: this.datadown.orgId,
                    orgName: this.datadown.orgName,
                };
            } else if (this.org !== {} && JSON.stringify(this.org) !== '{}') {
                console.log("this.org = " + JSON.stringify(this.org, null, 2))
                this.selectedOrganisation = {
                    id: this.org.id,
                    orgName: this.org.orgName
                }
                console.log("selectedOrg = " + JSON.stringify(this.selectedOrganisation, null, 2))

            }
            this.contactName.title = this.item.contactTitle;
            this.contactName.firstName = this.item.contactFirstName;
            this.contactName.lastName = this.item.contactLastName;
            this.contactName.fullName = this.item.contactFirstName + ' ' + this.item.contactLastName;
            if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
                this.selectedOwner = { ownerUserId: this.datadown.ownerUserId, ownerUserName: this.datadown.ownerUserName, ownerUserEmail: this.datadown.ownerUserEmail };
            } else {
                this.selectedOwner = this.MIX_getCurrentOwner();
            }
        },
        // Validate Form
        validateForm() {
            this.rules = {
                contactFirstName: [(v) => !!v || 'Firstname Required'],
                contactLastName: [(v) => !!v || 'Lastname Required'],
                contactFullName: [(v) => !!v || 'Fullname Required'],
                // contactMobilePhone: [(v) => !!v || 'Mobile Phone Required'],
                // contactDDIPhone: [(v) => !!v || 'DDI Phone Required'],
                contactOrganisation: [(v) => !!v || 'Organisation Required'],
                // contactJobTitle: [(v) => !!v || 'Job Title Required'],
                contactJobLevel: [(v) => !!v || 'Job Level Required'],
                contactJobDepartment: [(v) => !!v || 'Job Department Required'],
                contactOwner: [(v) => !!v || 'Assigned to Required'],

            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    this.saveItem();
                } else {
                    this.MIX_alertBox({ color: "error", timeout: 4000, message: "Please fill out all the fields", show: true });                    
                }
            });
        },
        // Save Item
        saveItem() {
            this.item.contactProfileScore = this.computedProfileScore.score;
            this.item.ownerUserId = this.selectedOwner.ownerUserId;
            this.item.ownerUserName = this.selectedOwner.ownerUserName;
            this.item.ownerUserEmail = this.selectedOwner.ownerUserEmail;
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.editmode = false;
            this.$emit('cancel');
        },
        // Clear Selected Organisation
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrganisation = null;
            }, 1);
        },
        // Clear Selected Owner
        clearSelectedOwner() {
            setTimeout(() => {
                this.selectedOwner = null;
            }, 1);
        },
        // Clear Item Property
        clearItem(key, value) {
            setTimeout(() => {
                this.item[key] = value;
            }, 1);
        },
        // Clear Contact Name Property
        clearContactName(key, value) {
            setTimeout(() => {
                this.item[key] = value;
            }, 1);
        },
        // Close
        close() {
            this.$emit('close');
        },
    },
    // * WATCH
    watch: {
        // Watch Data Down
        datadown: {
            handler: function () {
                this.loadDataDown();
            },
            deep: true,
        },
        // Watch Selected Organisation
        selectedOrganisation: {
            handler: function () {
                this.item.orgId = this.selectedOrganisation.id;
                this.item.orgName = this.selectedOrganisation.orgName;
            },
            deep: true,
        },
        // Watch Contact Name
        contactName: {
            handler: function () {
                this.item.contactFullName = '';
                this.item.contactTitle = this.contactName.title;
                this.item.contactFirstName = this.contactName.firstName;
                this.item.contactLastName = this.contactName.lastName;
                if (this.contactName.title !== undefined && this.contactName.title !== null && this.contactName.title !== '') {
                    this.item.contactFullName = this.contactName.title;
                }
                if (this.contactName.firstName !== undefined && this.contactName.firstName !== null && this.contactName.firstName !== '') {
                    this.item.contactFullName =  this.item.contactFullName + ' ' +  this.contactName.firstName;
                }
                if (this.contactName.lastName !== undefined && this.contactName.lastName !== null && this.contactName.lastName !== '') {
                    this.item.contactFullName =  this.item.contactFullName + ' ' +  this.contactName.lastName;
                }
                this.item.contactFullName = this.item.contactFullName.trim();
            },
            deep: true,
        },
        item: {
            handler: function () {
                console.log("editing item ")
                this.$emit('itemDataUp', this.item)
            }
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.loading = true;
        console.log("contactDetails")
        // Get Users
        let users = await this.MIX_users(''); // Add Users to Dropdown for Owner
        this.users = users.map((x) => {
            return {
                ownerUserId: x.id,
                ownerUserName: x.userName,
                ownerUserEmail: x.userEmail,
            };
        });
        // Get Organisations
        t.organisations = await t.MIX_organisations();
        // Job Levels
        let jobLevels = await t.MIX_redisReadSet('set:jobLevels', false);
        if (jobLevels.code === 1) {
            t.jobLevels = jobLevels.data;
        }
        // Get Sources
        let sources = await t.MIX_redisReadSet('set:sources', true);
        if (sources.code === 1) {
            t.sources = sources.data;
        }
        // Departments
        let jobDepartments = await t.MIX_redisReadSet('set:jobDepartments', false);
        if (jobDepartments.code === 1) {
            t.jobDepartments = jobDepartments.data;
        }
        // Address Types
        let addressTypes = await t.MIX_redisReadSortedSet('sset:addressTypes', 0, 9999);
        if (addressTypes.code === 1) {
            t.addressTypes = addressTypes.data;
        }
        // Industries
        let industries = await t.MIX_redisReadSet('set:industries', false);
        if (industries.code === 1) {
            t.industries = industries.data;
        }
        // Load the Data into Item
        this.loadDataDown();
        t.loading = false;
    },
};
</script>
