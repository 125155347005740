<template>
	<v-row fill-height class="grey lighten-2" style="height: calc(100vh - 50px) !important; overflow-y: scroll !important" no-gutters>
		<v-col class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
			<div class="d-flex align-center mb-1">
				<div class="d-flex align-center" v-if="list === 'quotes'">
					<div><v-icon class="grey--text" x-large	>icons8-bill</v-icon></div>
					<div class="text-left headingtext--text text-h4 font-weight-light ml-1" v-if="!listShrunk" title="">Quotes</div>				
				</div>
				<div class="d-flex align-center" v-if="list === 'products'">
					<div><v-icon class="grey--text" x-large	>icons8-tags</v-icon></div>
					<div class="text-left headingtext--text text-h4 font-weight-light ml-1" v-if="!listShrunk">Product Selector</div>				
				</div>
				
				<v-spacer></v-spacer>
				<div v-if="list === 'quotes'">
					<v-btn @click="openItem({})" depressed class="accent secondary--text"> <v-icon class="mr-2">icons8-bill</v-icon>New</v-btn>
				</div>
				<div>
					<v-btn class="primary--text ml-1" @click="listShrunk = false" depressed v-if="listShrunk">
						<v-icon color="primary">icons8-show-sidepanel</v-icon>
					</v-btn>
					<v-btn class="primary--text ml-1"  @click="listShrunk = true" depressed v-if="!listExpanded && !listShrunk">
						<v-icon color="primary">icons8-hide-sidepanel</v-icon>
					</v-btn>
					<!-- TODO - RG - Quotes - Create the Importing and Exporting of Quotes to CSV -->
					<!-- <v-btn disabled class="primary--text ml-1" depressed  v-if="!listShrunk" @click="dialogImport = true">
						<v-icon color="primary">icons8-import-csv</v-icon>
					</v-btn>
					<v-btn disabled class="primary--text ml-1" depressed v-if="!listShrunk">
						<v-icon color="primary">icons8-export-csv</v-icon>
					</v-btn> -->
					<v-btn class="primary--text ml-1" depressed  @click="refreshItems++">
						<v-icon color="primary">icons8-available-updates</v-icon>
					</v-btn>
				</div>
			</div>
			<div v-if="list === 'products'">
				<span class="grey--text">Use the list below to add items to Quote {{ quoteNumber }}</span>
			</div>
			<!-- <v-divider class="grey lighten-1 mb-4"></v-divider> -->
			<!-- // * PRODUCTS LIST -->
			<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
				<product-list v-if="list === 'products'" :listshrunk="listShrunk" :listexpanded="listExpanded" :quoteid="quoteId" :quotenumber="quoteNumber" v-on:openItem="openItem" :refreshitems="refreshItems" section="quotes" v-on:refreshlineitems="refreshLineItemsMethod"  />
			</transition>

			<!-- // * QUOTES LIST COMPONENT -->
			<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
				<quote-list v-if="list === 'quotes'" :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshitems="refreshItems" v-on:closeitem="closeItem" />		
			</transition>

            <!-- App Copyright -->
            <appCopyright />
		</v-col>
		<!-- // * QUOTE COMPONENT -->
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: lightgrey solid 2px !important" v-if="!listExpanded" class="grey lighten-3" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
				<!-- Active Item <pre class="caption">{{ activeItem }}</pre> -->
				<quote :datadown="activeItem" :listshrunk="listShrunk" v-on:quoteid="updateQuoteId" :refreshlineitems="refreshLineItems" v-on:refreshlineitemsmethod="refreshLineItemsMethod" v-on:refreshitems="refreshItemsMethod" v-on:showproducts="showProducts" v-on:showquotes="showQuotes" v-on:quotenumber="updateQuoteNumber" v-on:closeitem="closeItem" />
			</v-col>
		</transition>
		<!-- // * TASKBAR COMPONENT -->
		<transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: lightgrey solid 2px !important" class="" v-if="GET_taskBar" :cols="taskCols.cols" :xs="taskCols.xs" :sm="taskCols.sm" :md="taskCols.md" :lg="taskCols.lg" :xl="taskCols.xl">
				<taskbar />
			</v-col>
		</transition>
	</v-row>
</template>

<script>
	import taskBar from "@/views/tasks/taskbar.vue";
	import quote from "@/views/quotes/quote.vue";
	import productList from "@/views/products/productList.vue";
	import quoteList from "@/views/quotes/quoteList.vue";
	import { mapGetters } from "vuex";
	export default {
		// * COMPONENTS
		components: {
			quote: quote,
			taskbar: taskBar,
			"product-list": productList,
			"quote-list": quoteList,
		},
		// * DATA
		data: () => ({
			listExpanded: true,
			listShrunk: false,
			list: "quotes",
			itemsCols: { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
			itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
			taskCols: { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
			refreshItems: 0,
			refreshLineItems: 0,
			activeItem: {},
			quoteId: "",
			quoteNumber: "",
			routeId:'',
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_taskBar: "GET_taskBar",
				GET_dense: "GET_dense",
			}),
		},
		// * METHODS
		methods: {
			// Open Item
			openItem(item) {
				this.activeItem = item;
				this.listExpanded = false;
				this.updateQuoteId(item.id);
				// this.list = "products";
			},
			updateQuoteId(quoteid) {
				this.quoteId = quoteid;
			},
			updateQuoteNumber(quotenumber) {
				this.quoteNumber = quotenumber;
			},
			refreshLineItemsMethod() {
				this.refreshLineItems++;
			},
			// Refresh Columns
			refreshCols() {
				switch (true) {
					// Expanded List with No Task Bar
					case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
						this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// Expanded List with Task Bar
					case this.listExpanded && !this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
						this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					// List with Product Page (No Task Bar)
					case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// List with Product Page and Task Bar
					case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
						this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					// Shrunk List with Product Page (No Task Bar)
					case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
						this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// Shrunk List with Product Page and Task Bar)
					case !this.listExpanded && this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					default:
						this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
						this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
				}
			},
			// Refresh Items Method
			refreshItemsMethod() {
				this.refreshItems++;
			},
			showProducts(value) {
				this.list = "products"
				this.quoteId = value.quoteId;
				this.quoteNumber = value.quoteNumber;
			},
			showQuotes() {
				this.list = "quotes"
			},
			closeItem() {
				this.listExpanded = true;
				this.listShrunk = false;
			},
		},
		// * WATCH
		watch: {
			listExpanded() {
				this.refreshCols();
			},
			listShrunk() {
				this.refreshCols();
			},
			GET_taskBar() {
				this.refreshCols();
			},
		},
		// * CREATED
		async created() {
			// if (this.quoteid === "") {
			// 	this.quoteId = this.$route.params.id;
			// 	if (this.quoteId !== "") {
			// 		this.openItem({ id: this.quoteId })
			// 	} else if (this.quoteId === "new") {
			// 		this.openItem({})
			// 	}
			// } else {
			// 	this.quoteId = this.quoteid;
			// 	this.openItem({ id: this.quoteId })
			// }
		},
		// * MOUNTED
		mounted() {
			this.refreshCols();
			// Dynamic Route for New or Existing Item
			this.routeId = this.$route.params.id;
			if (this.routeId === "new") {
				this.openItem({})
			} else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined) {
				this.openItem({ id: this.routeId })
			}			
		},
	};
</script>
