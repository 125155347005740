<template>
  <v-card flat outlined>
    <v-col cols="12" class="align-start">
      <v-row class="pa-2 pb-0">
        <v-col cols="12" class="d-flex ma-0 pa-0 pl-1 align-start">
          <div>
            <div class="font-weight-bold text-start body-2 text-truncate primary--text"
                 style="max-width: 180px !important">
              {{ opportunity.orgName }}
            </div>
            <div class="font-weight-bold text-start body-2 text-truncate grey--text"
                 style="max-width: 180px !important">
              {{ opportunity.salesTitle }}
            </div>
            <div v-if="opportunity.salesTitle" class="font-weight-regular text-start body-2 text-truncate grey--text"
                 style="max-width: 230px !important">
              {{ opportunity.salesDescription }}
            </div>
            <!-- <div v-if="opportunity.salesDescription" class="font-weight-regular body-2 text-start text-truncate grey--text" style="max-width: 150px !important">
                {{ opportunity.salesDescription }}
            </div> -->
            <div v-if="opportunity.salesValue"
                 class="font-weight-regular grey--text body-2 pr-3 text-start text-uppercase">
              {{
                new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }).format(opportunity.salesValue)
              }}
            </div>

          </div>
          <v-spacer/>
          <div>
            <v-avatar v-if="opportunity.ownerUserName" color="caption primary white--text font-weight-regular"
                      size="30">{{ opportunity.ownerUserName.match(/\b(\w)/g).join('') }}
            </v-avatar>
          </div>
        </v-col>
      </v-row>

      <v-row class="pa-2 pt-2" :class="reveal ? 'pb-2' : ''">
        <v-col cols="12" class="d-flex ma-0 pa-0 pr-3 align-center">
          <div
              v-if="opportunity.salesPackages !== '' && opportunity.salesPackages !== undefined && opportunity.salesPackages !== null">
            <div class="d-flex align-center">
              <div v-for="item in opportunity.salesPackages.split(',')" :key="item">
                <PackageIcon :packagedata="getPackageDetails(item)"/>
              </div>
            </div>
          </div>
          <v-spacer/>
          <div v-if="opportunity.salesProbability" class="font-weight-regular body-2 pr-3 text-start text-uppercase"
               :class="opportunity.salesProbability <= '25' ? 'error--text' : opportunity.salesProbability >= '26' && opportunity.salesProbability <= '74' ? 'warning--text' : opportunity.salesProbability > '74' ? 'success--text' : 'grey--text'">
            {{ opportunity.salesProbability }}%
          </div>
          <div>
            <v-icon class="grey--text" size="15" @click="reveal = !reveal">icons8-expand-arrow</v-icon>
          </div>
        </v-col>
      </v-row>

      <v-card v-if="reveal === true" class="mt-3 elevation-0" style="height: 100% !important; width: 100% !important">
        <v-divider/>
        <table class="ma-0 pa-0 mt-3 caption grey--text text--darken-2 " border="0" width="100%">
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Exp Close:</td>
            <td class="" width="60%" align="left">
              {{ MIX_formatDateTime(opportunity.salesEstimatedCloseDate, 'X', 'MMM-YY') }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Owner:</td>
            <td class="" width="60%" align="left">{{ opportunity.ownerUserName }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="50%" align="left">Latest Activity:</td>
            <td class="" width="60%" align="left"><a @click="openActivity">{{ latestActivity.actionSubject }}</a></td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Activity Type:</td>
            <td class="" width="60%" align="left">{{ latestActivity.actionType }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Latest Action:</td>
            <td class="" width="60%" align="left"><a @click="openAction">{{ latestAction.actionSubject }}</a></td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Action Type:</td>
            <td class="" width="60%" align="left">{{ latestAction.actionType }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Action Due:</td>
            <td class="" width="60%" align="left">{{ MIX_formatDateTime(latestAction.actionDate, 'X', 'DD-MMM-YYYY') }}</td>
          </tr>
        </table>
        <v-row class="d-flex justify-space-between pt-3" dense style="">
          <v-col  cols="2" class="" v-if="actionLead == false">
            <v-btn icon depressed @click="openOpportunity" class="caption font-weight-bold primary--text" title="Account"><v-icon>icons8-hotel-building</v-icon></v-btn>
          </v-col>
          <v-col  cols="2" class="">
            <v-btn icon depressed @click="createQuote" class="caption font-weight-bold primary--text" title="Create Quote"><v-icon>icons8-bill</v-icon></v-btn>
          </v-col>
          <v-col icon  cols="2" class="" v-if="actionLead == false">
            <v-btn icon depressed @click="activityDialog = true" class="caption font-weight-bold primary--text" title="Log Activity"><v-icon>icons8-tear-off-calendar</v-icon></v-btn>
          </v-col>
          <v-col cols="2" class="" v-if="actionLead == false">
            <v-btn icon depressed @click="actionDialog = true" class="caption font-weight-bold primary--text" title="Create Action"><v-icon>icons8-day-off</v-icon></v-btn>
          </v-col>
          <v-col cols="2" class="">
            <v-btn icon depressed @click="openOpportunityItem" class="caption font-weight-bold primary--text" title="Edit"><v-icon>icons8-edit</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!--activity / action Dialog-->
    <NewActionDialog :activityDialog="actionDialog" v-on:closeDialog="closeDialog" v-on:refreshItems="$emit('refreshItems')" :datadown="datadown"></NewActionDialog>

    <NewActivityDialog :activityDialog="activityDialog" v-on:closeDialog="closeDialog" v-on:refreshItems="$emit('refreshItems')" :datadown="datadown"></NewActivityDialog>
  </v-card>
</template>

<script>
import PackageIcon from '@/views/sales/PackageIcon.vue'
import NewActivityDialog from "@/views/sales/NewActivityDialog.vue";
import NewActionDialog from "@/views/sales/NewActionDialog.vue";

export default {
  data: () => ({
    opportunity: {},
    reveal: false,
    orgType: '',
    latestActivity: {
      actionSubject: 'N/A',
      actionType: 'N/A',
    },
    latestAction: {
      actionSubject: 'N/A',
      actionType: 'N/A',
    },
    actionDialog: false,
    activityDialog: false,
  }),
  components: {
    NewActionDialog, NewActivityDialog,
    PackageIcon,
  },
  props: {
    datadown: {
      type: Object,
      default: () => ({}),
    },
    actionLead: {
      type: Boolean,
      default: false,
    },
    // packages: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  computed: {},
  methods: {
    openOpportunity() {
      // ////console.log('openOpportunity');
      this.$emit('openopportunity', this.opportunity);
    },
    openOpportunityItem() {
      this.$emit('openopportunityitem', this.opportunity);
    },
    getPackageDetails(id) {
      let itemPackage = this.packages.find((item) => item.id === id);
      if (itemPackage !== '' && itemPackage !== undefined && itemPackage !== null) {
        return itemPackage;
      }
      // return this.packages.find((item) => {
      //     if (item.id === id) {
      //         return item
      //     } else {
      //         return '';
      //     }
      // });
    },
    createQuote(){
      this.$router.push('/quotes/new?orgId=' + this.opportunity.orgId + '&opportunityId=' + this.opportunity.id);
    },
    openActivity(){
      this.MIX_go('/customers/' + this.opportunity.orgId+'?tab=activities');
    },
    openAction(){
      this.MIX_go('/customers/' + this.opportunity.orgId+'?tab=actions');
    },
    async getLatestActivity() {
      const activityFields = '@actionType,@actionSubject,@actionDescription,@actionStatus,@actionPriority,@actionDate,@actionCompletedDateTime,@actionStageId,@actionStageName,@actionCompleted,@orgId,@orgName,@actionEndDate,@actionTags,@createdDateTime,@ownerUserName,@action,@id,@deleted,@actionId'
      let itemsResult = await this.MIX_redisAggregateSearch('idx:actions', `@actionCompleted:"1" @orgId:${this.opportunity.orgId}`, '0', '1', activityFields, '@createdDateTime', true);
      if (itemsResult.code === 1) {
        if(itemsResult.data.results[0] !== undefined){
          this.latestActivity = itemsResult.data.results[0];
        }else{
          this.latestActivity = {
            actionSubject: 'N/A',
            actionType: 'N/A',
          }
        }
      } else {
        // console.log(err)
      }
    },
    async getLatestAction() {
      const activityFields = '@actionType,@actionSubject,@actionDescription,@actionStatus,@actionPriority,@actionDate,@actionCompletedDateTime,@actionStageId,@actionStageName,@actionCompleted,@orgId,@orgName,@actionEndDate,@actionTags,@createdDateTime,@ownerUserName,@action,@id,@deleted,@actionId'
      let itemsResult = await this.MIX_redisAggregateSearch('idx:actions', `@actionCompleted:"0" @orgId:${this.opportunity.orgId}`, '0', '1', activityFields, '@createdDateTime', true);
      if (itemsResult.code === 1) {
        if(itemsResult.data.results[0] !== undefined){
          this.latestAction = itemsResult.data.results[0];
        }else{
          this.latestAction = {
            actionSubject: 'N/A',
            actionType: 'N/A',
          }
        }
      } else {
        // console.log(err)
      }
    },
    closeDialog(){
      this.activityDialog = false
      this.actionDialog = false
      this.addingAction = false
      this.newAction = {}
      this.newAction = {...this.schema}
    }
  },
  async created() {
    // let t = this;
    this.opportunity = {...this.datadown};
    // ////console.log('packages : ', this.packages);
    // // ////console.log("opportunity = " + JSON.stringify(this.opportunity, null, 2));
    await this.getLatestActivity();
    await this.getLatestAction();
    let itemResult = await this.MIX_readItem(this.opportunity.orgId, 'org');
    if (itemResult.code === 1) {
      // this.$emit('edit', itemResult.data);
      // // ////console.log("result = " + JSON.stringify(itemResult.data))
      this.orgType = itemResult.data.orgAccountTypes
    }
  },
};
</script>
