<template>
	<div>
		<v-row class="pa-3" no-gutters>
			<v-col cols="12" sm="12">
				<div class="text-left primary--text text-h6 font-weight-light">Fabrics ({{ itemsTotal }})</div>
				<v-divider class="grey lighten-1 mb-4"></v-divider>
				<div class="my-3 d-flex align-center">
					<div class="flex-grow-1"><v-text-field label="Search Fabrics" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field></div>
					<div class="mb-5 ml-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>
				</div>
				<!-- TABLE BASED LIST -->
				<v-data-table sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions"
					>
					<template v-slot:item.deleted="{ item }">
						<span v-if="item.deleted === 'true'">Deleted</span>
					</template>

					<template v-slot:item.action="{ item }">
						<v-icon class="error--text pa-1" style="border: 1px red solid; border-radius: 3px;" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
						<v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted  === 'false'">icons8-trash-can</v-icon>
						<v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === 'true'">icons8-trash-restore</v-icon>
						<v-icon class="primary--text ml-5" @click="editItem(item.id)">icons8-edit</v-icon>
					</template>
				</v-data-table>
			</v-col>
			<v-col cols="12" sm="12" class="mt-5">
				<div class="text-left primary--text text-h6 font-weight-light">Add Fabric</div>
				<v-divider class="grey lighten-1 mb-4"></v-divider>
			</v-col>
			<v-col cols="12" sm="12" class="white pa-3">
				<v-simple-table dense>
					<template v-slot:default>
						<tbody>
							<tr>
								<td width="50%">Fabric</td>
								<td width="50%" class="py-2">
									<div><v-autocomplete v-model="item" :items="fabrics" item-text="attributeName" return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete></div>
								</td>
							</tr>

							<tr>
								<td width="50%">Code</td>
								<td width="50%" class="py-2">
									<div><v-text-field v-model="item.attributeCode" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
								</td>
							</tr>
							<tr>
								<td width="50%">Name</td>
								<td width="50%" class="py-2">
									<div><v-text-field v-model="item.attributeName" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
								</td>
							</tr>
							<tr>
								<td width="50%">Price Band</td>
								<td width="50%" class="py-2">
									<div><v-autocomplete label="Price Band" v-model="item.priceBand" :items="priceBands" outlined background-color="white" hide-details="auto" dense /></div>
								</td>
							</tr>
							<tr v-if="costsToggle">
								<td width="50%">Additional Cost</td>
								<td width="50%" class="py-2">
									<div><v-text-field label="Add Cost" v-model="item.addCost" prefix="£" outlined dense hide-details="auto" background-color="white" color="primary" append-icon="mdi-close" @click:append="item.addCost = 0"></v-text-field></div>
								</td>
							</tr>
							<tr v-if="costsToggle">
								<td width="50%">Additional Sell</td>
								<td width="50%" class="py-2">
									<div><v-text-field label="Add Sell" v-model="item.addSell" prefix="£" outlined dense hide-details="auto" background-color="white" color="primary" append-icon="mdi-close" @click:append="item.addSell = 0"></v-text-field></div>
								</td>
							</tr>
							<tr>
								<td colspan="2" class="py-2">
									<v-btn class="primary--text font-weight-bold" depressed small @click="costsToggle = !costsToggle; item.addCost = 0; item.addSell = 0"><span v-if="!costsToggle">+ Add</span><span v-if="costsToggle">- Remove</span>&nbsp; Costs</v-btn>
								</td>
							</tr>

							<tr>
								<td colspan="2" class="py-2 text-right">
									<v-btn class="primary--text font-weight-bold" depressed @click="createItem"><span v-if="!addImageToggle">Add Fabric</span></v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import schema from "@/schema.js";
	import { mapGetters } from "vuex";
	export default {
		// * PROPS
		props: {
			supplier: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			item: {},
			newItem: true,
			key: "attribute",
			addImageToggle: false,
			costsToggle: false,
			index: "idx:attributes",
			toggleDeletedItems: false,
			schema: {},
			showImages: false,
			items: [],
			itemsTotal: null,
			searchQuery: "",
			itemsFrom: 0,
			itemsTo: 9,
			activeItem: {},
			fabrics: [], // Stores Fabrics for Dropdown

			// Price Bands
			priceBands: ["A", "B", "C", "D", "E", "F"],

			// Headers to display in the Data Table
			headers: [
				{ text: "Id", value: "id", hidden: true },
				{ text: "Code", value: "attributeCode", hidden: false },
				{ text: "Name", value: "attributeName", hidden: false },
				{ text: "Group", value: "attributeGroup", hidden: true },
				{ text: "Type", value: "attributeType", hidden: true },
				{ text: "Colour", value: "attributeColour", hidden: true },
				{ text: "Image", value: "attributeImage", hidden: true },
				{ text: "Parent ID", value: "attributeParentId", hidden: true },
				{ text: "Parent", value: "attributeParentName", hidden: true },
				{ text: "Option Group", value: "optionGroup", hidden: true },
				{ text: "Option Name", value: "optionName", hidden: true },
				{ text: "Supplier Name", value: "supplierName", hidden: false },
				{ text: "Supplier Code", value: "supplierAccNo", hidden: true },
				{ text: "Price Band", value: "priceBand", hidden: false },
				{ text: "Add Cost", value: "addCost", hidden: false },
				{ text: "Add Sell", value: "addSell", hidden: false },
				{ text: "Deleted", value: "deleted", hidden: true },
				{ text: "Action", value: "action", align: "end", hidden: false, width: "155px" },
			],
			// Fields to Retrieve from Database
			fields: "@id,@attributeCode,@attributeName,@attributeGroup,@attributeType,@attributeColour,@attributeImage,@attributeParentName,@attributeParentId,@optionGroup,@optionName,@supplierId,@supplierName,@supplierAccNo,@priceBand,@addCost,@addSell,@deleted",
			sortBy: "@attributeName",
			datatableOptions: {
				page: 1,
				itemsPerPage: 10,
				sortBy: ["attributeName"],
				sortDesc: [false],
				groupBy: [],
				groupDesc: [],
				mustSort: false,
				multiSort: false,
			},
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
			computedSearchQuery() {
				let searchQuery = this.searchQuery;
				if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
					if (this.toggleDeletedItems) {
						searchQuery = `@supplierId:${this.supplier.id}`;
					} else {
						searchQuery = `@deleted:0 @supplierId:${this.supplier.id}`;
					}
					return searchQuery;
				} else {
					if (searchQuery.startsWith("@")) {
						if (this.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @supplierId:${this.supplier.id}`;
							return searchQuery;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @supplierId:${this.supplier.id}`;
							return searchQuery;
						}
					} else {
						if (this.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @supplierId:${this.supplier.id}`;
							return searchQuery;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @supplierId:${this.supplier.id}`;
							return searchQuery;
						}
					}
				}
			},
			computedHeaders() {
				return this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
			},
			computedItems() {
				var items = this.items; //.filter(x => x.);
				return items; //.slice(this.organisationsFrom, this.organisationsTo);
			},
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				t.item = { ...t.schema };
			},		
			// * CREATE ITEM	
			async createItem() {
				let t = this;
				t.item.id = t.MIX_generateId();
				t.item.attributeGroup = "Fabric";
				t.item.attributeType = "Fabric";
				t.item.supplierId = this.supplier.id;
				t.item.supplierAccNo = this.supplier.accoutnNtNo;
				t.item.supplierName = this.supplier.tradingName;
				let result = await this.MIX_createItem(this.item, this.key, this.newItem);
				if (result.code === 1) { this.getItems(); this.initialiseItem() }
			},
			// * EDIT ITEM
			async editItem(itemId) {
				let result = await this.MIX_readItem(itemId, this.key)
				if (result.code === 1) { this.item = result.data }
			},
			// * DESTROY ITEM
			async destroyItem(itemId) {
				let result = await this.MIX_destroyItem(itemId, this.key);
				if (result.code === 1) { this.getItems() }
			},
			// * DELETE ITEM
			async deleteItem(itemId) {
				let result = await this.MIX_deleteItem(itemId, this.key);
				if (result.code === 1) { this.getItems() }
			},			
			// * RESTORE ITEM
			async restoreItem(itemId) {
				let result = await this.MIX_restoreItem(itemId, this.key);
				if (result.code === 1) { this.getItems() }
			},			
			async getItems() {
				console.log('getItems')
				console.log('this.index = ' + this.index)
				console.log('this.computedSearchQuery = ' + this.computedSearchQuery)
				let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.fields, this.sortBy, false);
				console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2))
				if (itemsResult.code === 1) {
					this.itemsTotal = itemsResult.data.total;
					this.items = itemsResult.data.results;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
				} else {
					this.MIX_alertBox({ color: "error", message: "Error retreiving items", timeout: 4000, show: true });
				}
			},
			// * GET FABRICS (for Dropdown)
			async getFabrics() {
				let result = await this.MIX_redisSearch('idx:fabrics', '@deleted:0', 0, 9999);
				//console.log('result = ' + JSON.stringify(result, null, 2));
				if (result.code === 1) {
					this.fabrics = result.data.documents.map(x => x.value);
					//console.log('this.fabrics = ' + JSON.stringify(this.fabrics, null, 2))
				}
			}
		},
		// * WATCH
		watch: {
			// If the search query changes then get updated dataset based on search query
			computedSearchQuery() {
				this.getItems();
			},
			toggleDeletedItems() {
				this.getItems();
			},
			datatableOptions: {
				handler() {
					this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
					this.getItems();
				},
				deep: true,
			},
		},
		// * CREATED
		created() {
			this.schema = schema.attribute;
			this.getFabrics(); // Get Fabrics for Fabric Dropdown
			this.getItems();
			this.initialiseItem();
		},
	};
</script>
