<template>
    <v-row no-gutters class="pa-3">
        <v-file-input dense outlined show-size counter v-bind:accept="accept" v-bind:label="label" v-model="file" :rules="rules"></v-file-input>
        <v-btn block v-bind:class="buttonClass" :disabled="loading" depressed  @click="uploadFile()">UPLOAD</v-btn>
        <v-col cols="12" class="mt-2 grey darken-2" v-if="loading  && value < 100">
            <v-progress-linear height="45" class="py-2 white--text" :buffer-value="value" large v-model="value" color="purple">
                 <div class="animate__animated animate__flash animate__infinite">{{ status }}</div> <div class="ml-2" v-if="value > 0">({{ Math.ceil(value) }})%</div>
            </v-progress-linear>
            <div v-if="!loading && value === 100" class="success white--text" style="width: 100% !important; height: 100%">Upload Completed</div>
        </v-col>
    </v-row>
</template>

<script>
import imageCompression from 'browser-image-compression';

export default {
    name: 'FileUpload',
    props: {
        accept: String,
        buttonClass: String,
        compressionMaxSizeMB: Number,
        compressionmaxWidthOrHeight: Number,
        fileName: String,
        folderLocation: String,
        label: String,
        private: Boolean,
        progressClass: String,
        createThumbnail: Boolean,
        selectedItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data: () => ({
        file: null,
        loading: false,
        status: "",
        loader: null,
        rules: [(value) => !value || value.size < 20000000 || 'Image size should be less than 2 MB!'],
        show: false,
        value: 0,
        bufferValue: 0,
    }),
    methods: {
        async uploadToGoogleStorage(file, imageType, subFolder) {
            return new Promise((resolve) => {
                let t = this;
                var upload
                t.status = "Uploading " + imageType;
                if (t.private === true) {
                    upload = t.$firebase.privateStorage.ref(t.folderLocation + subFolder + t.fileName + '.jpeg').put(file);
                } else {
                    upload = t.$firebase.publicStorage.ref(t.folderLocation + subFolder + t.fileName + '.jpeg').put(file);
                }
                upload.on('state_changed', 
                    (snapshot) => { 
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        t.value = progress;
                        console.log(`${imageType} Uploading`) 
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => { console.log('error: ', error) },
                    () => {
                        upload.snapshot.ref.getDownloadURL().then(() => {
                        console.log(`${imageType} Done`)
                        resolve();
                    });
                })

            })
        },
        async uploadFile() {
            let t = this;
            t.value = 0;
            t.loading = true;
            console.log('File', JSON.stringify(t.file, null, 2))
            
            const options = {
                maxSizeMB: t.compressionMaxSizeMB,
                maxWidthOrHeight: t.compressionmaxWidthOrHeight,
                useWebWorker: true,
                fileType: 'image/jpeg',
            };
            const thumbnailOptions = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 100,
                useWebWorker: true,
                fileType: 'image/jpeg',
            };
            //* Compresses the image realtime
            const compressedFile = await imageCompression(t.file, options);
            // //* Storage location in firebase
            var imageFile
            t.status = "Compressing Image";
            imageFile = await imageCompression(t.file, compressedFile);   
            console.log('Compressed Image', JSON.stringify(imageFile, null, 2))   
            t.status = "";
            await t.uploadToGoogleStorage(imageFile, "Image", "/");

            var thumbnailFile
            if (t.createThumbnail === true) {
                console.log('Create Thumbnail')
                thumbnailFile = await imageCompression(imageFile, thumbnailOptions);
                await t.uploadToGoogleStorage(thumbnailFile, "Thumbnail", "/thumbnails/");
            } else {
                console.log('Do not Create Thumbnail')
            }

            t.loading = false;
            t.$emit('update', t.fileName);
                  
            //  upload.on(
            //     'state_changed',
            //     (snapshot) => {
            //         var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //         t.value = progress;
            //         switch (snapshot.state) {
            //             case 'paused':
            //                 console.log('Upload is paused');
            //                 break;
            //             case 'running':
            //                 console.log('Upload is running');
            //                 break;
            //         }
            //     },
            //     (error) => {
            //         console.error(error);
            //     },
            //     () => {
            //         upload.snapshot.ref.getDownloadURL().then(() => {
            //             t.$emit('update');
            //             t.loading = false;        
            //         });
            //     }
            // );
        },
    },
};
</script>