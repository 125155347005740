import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

var firebaseConfig = {
    apiKey: "AIzaSyBs_ti-UThHx5xgoCmdoPwi0NpEoatULwU",
    authDomain: "mof-swapp.firebaseapp.com",
    projectId: "mof-swapp",
    storageBucket: "mof-swapp.appspot.com",
    messagingSenderId: "213619660504",
    appId: "1:213619660504:web:39418d935e83ed32f669ad",
    measurementId: "G-002F68GWLM"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;
const privateStorage = firebase.app().storage("gs://mof-swapp-private");
const publicStorage = firebase.app().storage("gs://mof-swapp-public");
const fv = firebase.firestore.FieldValue;

export {
    auth,
    currentUser,
    db,
    fv,
    privateStorage,
    publicStorage,
    storage,
};