<template>
    <div class="" style="height: calc(100vh - 150px) !important; overflow: scroll !important">
		<!-- Menu Buttons -->
        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px;">
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <div class="ml-3" v-if="!loading">
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" v-if="!editmode" depressed @click="editmode = 1"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>       				
                <v-spacer />
				<!-- HEADING AND BUTTONS -->
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="saveProduct"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
				</div>					
            </v-col>
        </v-row>  

        <v-row class="mx-1 pt-3 px-3 rounded apptext--text">
            <!-- Product Pricing -->
            <v-col cols="12" sm="12" class="d-flex align-start grey lighten-5" v-if="!editmode">
                <!-- Pricing Table -->
                <v-row class="">
                    <!-- SIMPLE PRICING -->
                    <v-col cols="12" class="grey lighten-5" v-if="item.pricingType === 'Simple'">
                        <div class="text-h7 font-weight-bold">Simple Pricing</div>
                        <v-simple-table style="width: 100%">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">MOQ</th>
                                        <th class="text-left">Cost Price</th>
                                        <th class="text-left">Sell Price</th>
                                        <th class="text-left">MP*</th>
                                        <th class="text-left">RRP*</th>
                                        <th class="text-left">Margin Sell</th>
                                        <th class="text-left">Margin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ item.MOQ }}</td>
                                        <td>£ {{ parseFloat(item.cP1).toFixed(2) }}</td>
                                        <td>£ {{ parseFloat(item.sP1).toFixed(2) }}</td>
                                        <td>
                                            £ {{ parseFloat(item.MP).toFixed(2) }} <span class="text--secondary">({{ (((item.MP - item.sP1) / item.MP) * 100).toFixed(2) }}%)</span>
                                        </td>
                                        <td>
                                            £ {{ parseFloat(item.RRP).toFixed(2) }} <span class="text--secondary">({{ (((item.RRP - item.sP1) / item.RRP) * 100).toFixed(2) }}%)</span>
                                        </td>
                                        <td>£ {{ parseFloat(item.sP1 - item.cP1).toFixed(2) }}</td>
                                        <td>{{ isNaN(((item.sP1 - item.cP1) / item.sP1) * 100) ? '0' : (((item.sP1 - item.cP1) / item.sP1) * 100).toFixed(2) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <span class="text--secondary body-2"><strong>*</strong> Sell Price discount shown in brackets</span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Pricing Form -->
            <v-col v-if="editmode" cols="12" sm="12" class="grey lighten-5">
                <div class="d-flex align-center" style="height: 50px !important">
                    <v-radio-group v-model="item.pricingType" row :dense="dense">
                        <v-radio hide-details="auto" label="Simple Pricing" value="Simple"></v-radio>
                        <v-radio hide-details="auto" label="Volume Pricing" value="Volume"></v-radio>
                        <v-radio hide-details="auto" label="Band Pricing" value="Band"></v-radio>
                    </v-radio-group>
                </div>
                <v-row class="grey lighten-5">
                    <!-- SIMPLE PRICING -->
                    <v-col cols="12" class="" v-if="item.pricingType === 'Simple'">
                        <div class="text-h7 font-weight-bold">Simple Pricing</div>
                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="3">
                                <v-text-field dense label="MOQ" outlined background-color="white" hide-details="auto" v-model="item.MOQ"></v-text-field></v-col>
                        </v-row>
                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="3">
                                <v-text-field prefix="£" dense label="Cost Price" outlined background-color="white" hide-details="auto" v-model="item.cP1" clearable @click:clear="clearItem('cP1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3">
                                <v-text-field prefix="£" dense label="Sell Price" outlined background-color="white" hide-details="auto" v-model="item.sP1" clearable @click:clear="clearItem('sP1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3">
                                <v-text-field suffix="%" dense label="Margin" @change="item.sP1 = ((item.cP1 / (100 - $event)) * 100).toFixed(2)" outlined :background-color="((item.sP1 - item.cP1) / item.sP1) * 100 < 20 ? 'red lighten-4' : 'green lighten-4'" hide-details="auto" :value="isNaN(((item.sP1 - item.cP1) / item.sP1) * 100) ? '0' : (((item.sP1 - item.cP1) / item.sP1) * 100).toFixed(2)"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3">
                                <v-text-field prefix="£" readonly dense label="Sell Margin" outlined background-color="purple lighten-5" hide-details="auto" :value="(item.sP1 - item.cP1).toFixed(2)"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="my-2" dense>
                            <!-- <v-col cols="12" xs="12" sm="3">
                                <v-text-field prefix="£" dense label="Market Price" outlined background-color="white" hide-details="auto" v-model="item.MP" clearable @click:clear="clearItem('MP')"></v-text-field>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="3">
                                <v-text-field suffix="%" dense label="Discount off Market Price" outlined background-color="purple lighten-5" hide-details="auto" :readonly="true" :value="(((item.MP - item.sP1) / item.MP) * 100).toFixed(2)"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3">
                                <v-text-field prefix="£" dense label="RRP" outlined background-color="white" hide-details="auto" v-model="item.RRP" clearable @click:clear="clearItem('RRP')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3">
                                <v-text-field suffix="%" dense label="Dicount off RRP" outlined background-color="purple lighten-5" hide-details="auto" :readonly="true" :value="(((item.RRP - item.sP1) / item.RRP) * 100).toFixed(2)"></v-text-field>
                            </v-col>
                        </v-row>
                        <div class="error--text font-weight-bold" v-if="((item.sP1 - item.cP1) / item.sP1) * 100 < 20">Margin too Low - Please increase margin or sell price</div>
                    </v-col>
                    <!-- VOLUME PRICING -->
                    <v-col cols="12" class="" v-if="item.pricingType === 'Volume'">
                        <div class="text-h7 font-weight-bold">Volume Pricing</div>
                        <div class="body-2 text-secondary">Enter the Volume Pricing Below</div>
                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix=" " dense label="Sell Qty 1" outlined background-color="white" hide-details="auto" v-model="item.sQ1" clearable @click:clear="clearItem('cP1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Sell Price 1" outlined background-color="white" hide-details="auto" v-model="item.sP1" clearable @click:clear="clearItem('cP1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix=" " dense label="Sell Qty 2" outlined background-color="white" hide-details="auto" v-model="item.sQ2" clearable @click:clear="clearItem('cP1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Sell Price 2" outlined background-color="white" hide-details="auto" v-model="item.sP2" clearable @click:clear="clearItem('cP1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix=" " dense label="Sell Qty 3" outlined background-color="white" hide-details="auto" v-model="item.sQ3" clearable @click:clear="clearItem('cP1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Sell Price 3" outlined background-color="white" hide-details="auto" v-model="item.sP3" clearable @click:clear="clearItem('cP1')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix=" " dense label="Cost Qty 1" outlined background-color="white" hide-details="auto" v-model="item.cQ1" clearable @click:clear="clearItem('cQ1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Cost Price 1" outlined background-color="white" hide-details="auto" v-model="item.cP1" clearable @click:clear="clearItem('cP1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix=" " dense label="Cost Qty 2" outlined background-color="white" hide-details="auto" v-model="item.cQ2" clearable @click:clear="clearItem('cQ2')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Cost Price 2" outlined background-color="white" hide-details="auto" v-model="item.cP2" clearable @click:clear="clearItem('cP2')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix=" " dense label="Cost Qty 3" outlined background-color="white" hide-details="auto" v-model="item.cQ3" clearable @click:clear="clearItem('cQ3')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Cost Price 3" outlined background-color="white" hide-details="auto" v-model="item.cP3" clearable @click:clear="clearItem('cP3')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field suffix="%" dense label="Margin 1 %" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP1"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Margin 1 Sell" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP1"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field suffix="%" dense label="Margin 2 %" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP2"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Margin 2 Sell" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP2"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field suffix="%" dense label="Margin 3 %" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP3"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Margin 3 Sell" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP3"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- BAND PRICING -->
                    <v-col cols="12" class="" v-if="item.pricingType === 'Band'">
                        <div class="text-h7 font-weight-bold">Band Pricing</div>
                        <div class="body-2 text-secondary">Enter the Band Pricing Below</div>
                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">Stocked</v-col>
                            <v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">A</v-col>
                            <v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">B</v-col>
                            <v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">C</v-col>
                            <v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">D</v-col>
                            <v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">E</v-col>
                        </v-row>
                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Sell Stocked" outlined background-color="white" hide-details="auto" v-model="item.sST" clearable @click:clear="clearItem('sST')"></v-text-field>
                            </v-col> 
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Sell Band A" outlined background-color="white" hide-details="auto" v-model="item.sBA" clearable @click:clear="clearItem('sBA')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Sell Band B" outlined background-color="white" hide-details="auto" v-model="item.sBB" clearable @click:clear="clearItem('sBB')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Sell Band C" outlined background-color="white" hide-details="auto" v-model="item.sBC" clearable @click:clear="clearItem('sBC')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Sell Band D" outlined background-color="white" hide-details="auto" v-model="item.sBD" clearable @click:clear="clearItem('sBD')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Sell Band E" outlined background-color="white" hide-details="auto" v-model="item.sBE" clearable @click:clear="clearItem('sBE')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Cost Stocked" outlined background-color="white" hide-details="auto" v-model="item.cST" clearable @click:clear="clearItem('cST')"></v-text-field>
                            </v-col> 
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Cost Band A" outlined background-color="white" hide-details="auto" v-model="item.cBA" clearable @click:clear="clearItem('cBA')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Cost Band B" outlined background-color="white" hide-details="auto" v-model="item.cBB" clearable @click:clear="clearItem('cBB')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Cost Band C" outlined background-color="white" hide-details="auto" v-model="item.cBC" clearable @click:clear="clearItem('cBC')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Cost Band D" outlined background-color="white" hide-details="auto" v-model="item.cBD" clearable @click:clear="clearItem('cBD')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Cost Band E" outlined background-color="white" hide-details="auto" v-model="item.cBE" clearable @click:clear="clearItem('cBE')"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Margin Stocked" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sST - item.cST).toFixed(2)"></v-text-field>
                                </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Margin Band A" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBA - item.cBA).toFixed(2)"></v-text-field>
                                </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Margin Band B" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBB - item.cBB).toFixed(2)"></v-text-field>
                                </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Margin Band C" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBC - item.cBC).toFixed(2)"></v-text-field>
                                </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Margin Band D" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBD - item.cBD).toFixed(2)"></v-text-field>
                                </v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field prefix="£" dense label="Margin Band E" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBE - item.cBE).toFixed(2)"></v-text-field>
                                </v-col>
                        </v-row>
                        <v-row class="my-2" dense>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field suffix="%" dense label="Margin Stocked" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sST - item.cST) / item.sST) * 100).toFixed(2)">
                                </v-text-field></v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field suffix="%" dense label="Margin Band A" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBA - item.cBA) / item.sBA) * 100).toFixed(2)">
                                </v-text-field></v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field suffix="%" dense label="Margin Band B" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBB - item.cBB) / item.sBB) * 100).toFixed(2)">
                                </v-text-field></v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field suffix="%" dense label="Margin Band C" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBC - item.cBC) / item.sBC) * 100).toFixed(2)">
                                </v-text-field></v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field suffix="%" dense label="Margin Band D" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBD - item.cBD) / item.sBD) * 100).toFixed(2)">
                                </v-text-field></v-col>
                            <v-col cols="12" xs="12" sm="2">
                                <v-text-field suffix="%" dense label="Margin Band E" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBE - item.cBE) / item.sBE) * 100).toFixed(2)">
                                </v-text-field></v-col>
                        </v-row>
                    </v-col>
                    <!-- NPS PRICING -->
                    <v-col cols="12" xs="12" sm="3" class="pr-0">
                        <v-text-field prefix="£" dense label="NPS Core Pricing" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.NPS"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <div>
                            <v-btn class="primary--text font-weight-bold" depressed @click="customPricingToggle = !customPricingToggle">
                                <span v-if="customPricingToggle">Hide Custom Pricing</span>
                                <span v-if="!customPricingToggle">Show Custom Pricing</span>
                            </v-btn>
                        </div>
                    </v-col>
                    <!-- Custom Pricing -->
                    <v-col cols="12" class="" v-if="customPricingToggle">
                        <div class="text-h7 font-weight-bold">Custom Pricing</div>
                        <div class="body-2 text-secondary">Custom Pricing is used for Contract Pricing</div>
                        <div class="pb-3 d-flex align-start flex-wrap" style="height: 100px !important">
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing1" label="NPS" hide-details="auto"></v-checkbox>
                            </div>
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing2" label="Dealer" hide-details="auto"></v-checkbox>
                            </div>
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing3" label="Super Dealer" hide-details="auto"></v-checkbox>
                            </div>
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing4" label="Spare" hide-details="auto"></v-checkbox>
                            </div>
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing5" label="Spare" hide-details="auto"></v-checkbox>
                            </div>
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing6" label="Spare" hide-details="auto"></v-checkbox>
                            </div>
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing7" label="Spare" hide-details="auto"></v-checkbox>
                            </div>
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing8" label="Spare" hide-details="auto"></v-checkbox>
                            </div>
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing9" label="Spare" hide-details="auto"></v-checkbox>
                            </div>
                            <div class="">
                                <v-checkbox class="mr-2" v-model="item.customPricing10" label="Spare" hide-details="auto"></v-checkbox>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" md="12" class="align-center pb-0">
                        <div class=" d-flex align-center text-h7 pb-2 font-weight-bold">Market Prices
                            <v-col cols="3">
                                <v-text-field prefix="£" class="ml-3" dense label="Market Price" outlined background-color="white" hide-details="auto" v-model="item.MP" clearable @click:clear="clearItem('MP')"></v-text-field>
                            </v-col>
                        </div>
                        <div class="flex-grow-1">
                            <v-text-field label="Market Price Link One" v-model="item.productWebLink1" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" md="12" class="d-flex align-center pb-0">
                        <div class="flex-grow-1">
                            <v-text-field label="Market Price Link Two" v-model="item.productWebLink2" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" md="12" class="d-flex align-center ">
                        <div class="flex-grow-1">
                            <v-text-field label="Market Price Link Three" v-model="item.productWebLink3" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <pre>{{ item }}</pre> -->
            <v-col cols="12" class="grey lighten-5">
                <p class="text-h6 font-weight-thin" v-if="infoToggle">Information</p>
                <ul v-if="infoToggle" class="body-2">
                    <li><strong>MOQ</strong> - Minimum Order Quantity</li>
                    <li><strong>Cost Price</strong> - This is the price of the product from the supplier</li>
                    <li><strong>Sell Price</strong> - This is the price the product is sold to the customer</li>
                    <li><strong>MP</strong> - Market Price - This is the typical price for the product in the market</li>
                    <li><strong>RRP</strong> - RRP - This is the recommended retail price for the product</li>
                    <li><strong>Margin Sell</strong> - This is the margin between Cost and Sell</li>
                    <li><strong>Margin</strong> - This is the percentage margin between Cost and Sell</li>
                </ul>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        loading: false,
        editmode: false,
        // item
        item: {},
        customPricingToggle: false,
        infoToggle: false,
    }),
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
    },
    // * METHODS
    methods: {
        // Initialise
        initialise: function () {
            this.loading = true;
            this.item = { ...this.datadown };
            this.loading = false;
        },
        // Save Product
        saveProduct() {

            this.item.MPLastUpdatedDateTime = ""
            this.item.MPLastUpdatedUserId =  ""

            this.item.MPLastUpdatedDateTime = this.$moment().format('X'),
            this.item.MPLastUpdatedUserId =  this.GET_FIREBASE_userAuth.uid

			this.$emit("saveitem", this.item);
			this.editmode = false;
		},
        // Clear Item
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
    },
    // * CREATED
    created() {
        this.initialise();
    },
    // * MOUNTED
    mounted() {
    },
};
</script>
