<template>
  <div class="" style="width: 100% !important">
    <v-row dense style="background-color: #757575" class="d-flex pa-3 mt-0 align-start">
      <v-col cols="1" class="pl-1">
        <v-btn depressed block class="primary darken-2 white--text" @click="openItem({})">
          <v-icon>icons8-business-network</v-icon>
          <span class="ml-3">New Lead</span>
        </v-btn>
      </v-col>
      <v-spacer/>
      <v-col cols="10">
        <v-row dense class="d-flex align-end">
          <v-spacer/>
          <v-col cols="2">
            <v-text-field
                label="Search Leads "
                v-model="searchQuery"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                color="primary"
                clearable
                prepend-inner-icon="icons8-search"
                @click:clear="clearSearchQuery()"
            ></v-text-field>
          </v-col>
          <v-col cols="2" v-if="listexpanded" class="pl-1">
            <v-select
                label="Filter by Type"
                :menu-props="{ top: false, offsetY: true }"
                background-color="white"
                hide-details="auto"
                v-model="filter.type"
                :items="types"
                outlined
                dense
                @click:clear="clearSelectedTypeFilter()"
                clearable>
            </v-select>
          </v-col>
          <v-col cols="2" v-if="listexpanded" class="pl-1 mr-3">
            <v-select
                label="Filter by Owner"
                :menu-props="{ top: false, offsetY: true }"
                background-color="white"
                hide-details="auto"
                item-text="ownerUserName"
                item-value="ownerUserId"
                v-model="filter.owner"
                :items="owners"
                outlined
                dense
                @click:clear="clearSelectedOwnersFilter()"
                clearable>
              <!-- class="overflow" -->
              <template v-slot:selection="data">
                {{ data.item.ownerUserName }}
              </template>
              <template v-slot:item="data">
                {{ data.item.ownerUserName }}
              </template>
            </v-select>
          </v-col>
<!--          <v-col cols="2" v-if="listexpanded" class="pl-1">-->
<!--            <v-select-->
<!--                label="Filter by Package"-->
<!--                :menu-props="{ top: false, offsetY: true }"-->
<!--                background-color="white"-->
<!--                hide-details="auto"-->
<!--                v-model="filter.package"-->
<!--                :items="packages"-->
<!--                item-text="packageName"-->
<!--                item-value="packageId"-->
<!--                outlined-->
<!--                @click:clear="clearSelectedPackagesFilter()"-->
<!--                dense-->
<!--                clearable>-->
<!--            </v-select>-->
<!--          </v-col>-->
<!--          <v-col cols="2" class="pl-1 mr-3">-->
<!--            <v-select-->
<!--                label="Filter by Status"-->
<!--                :menu-props="{ top: false, offsetY: true }"-->
<!--                background-color="white"-->
<!--                hide-details="auto"-->
<!--                v-model="filter.status"-->
<!--                :items="status"-->
<!--                outlined-->
<!--                @click:clear="clearSelectedStatusFilter()"-->
<!--                dense-->
<!--                clearable>-->
<!--            </v-select>-->
<!--          </v-col>-->
        </v-row>
      </v-col>
    </v-row>
    <v-col cols="12" class="pt-2">
      <v-card class="notscrolly d-flex transparent elevation-0 pa-0 ma-0" height="750" width="100%"
              style="overflow-x: scroll !important;">
        <v-col cols="12" class="d-flex font-weight-bold pl-1 white--text">
          <v-row>
            <v-card class="d-flex transparent elevation-0 pa-0 ma-0 white--text" width="94vw">
              <v-col style="max-width: 300px !important" v-for="(stage, index) in stages" :key="index"
                     class="px-1 pl-2 text-center d-flex flex-column">
                <v-card class="borderBottom"
                        :color="stage.stageOutcome === 'success' ? 'primary' : stage.stageOutcome === 'failure' ? 'primary darken-2' : ''"
                        fixed flat>
                  <v-col cols="12">
                    <v-row class="justify-start pa-0">
                      <v-col cols="12" class="d-flex justify-center align-center">
                        <div style="max-width: 220px !important">
                          <v-card-title
                              :class="stage.stageOutcome === 'success' ? 'white--text' : stage.stageOutcome === 'failure' ? 'white--text' : 'primary--text'"
                              class="body-2 pa-0 ma-0 font-weight-bold justify-center text-truncate"
                              style="max-width: 200px !important">
                            {{ stage.stageName }}
                          </v-card-title>
                          <v-card-title
                              :class="stage.stageOutcome === 'success' ? 'white--text' : stage.stageOutcome === 'failure' ? 'white--text' : 'primary--text'"
                              class="body-1 pa-0 ma-0 justify-center "
                              style="max-width: 200px !important">
                            <!-- <span class="pr-1"> £{{ computedItems(stage).total}} </span>
                            -  -->
                            {{ computedItems(stage).length }} Leads
                            <!-- <v-spacer /> -->
                          </v-card-title>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
                <v-col class="pa-0 ma-0 mt-2">
                  <drop-list
                      class="list"
                      :items="computedItems(stage)"
                      @insert="onInsert($event, stage)"
                  >
                    <template v-slot:item="{item}">
                      <drag @dragstart="dragS" @cut="remove(computedItems(stage), item)" :data="item" :key="item.id">
                        <lead-card
                            @click="openLead"
                            v-on:openlead="openLead"
                            v-on:openleaditem="openItem"
                            class="mb-2"
                            style=""
                            :datadown="item">
                        </lead-card>
                      </drag>
                    </template>
                    <template v-slot:feedback="{data}">
                      <lead-card
                          @click="openLead"
                          v-on:openlead="openLead"
                          v-on:openleaditem="openItem"
                          class="mb-2"
                          style=""
                          :key="data.id"
                          :datadown="data">
                      </lead-card>
                    </template>
                  </drop-list>
                </v-col>
              </v-col>
            </v-card>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
    <v-dialog v-model="opportunityDialog" width="700px" height="400px" style="overflow: hidden !important">
      <v-card height="400px" class="d-flex flex-column grey lighten-3">
        <div class="pa-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
          <div class="mx-3 mb-1">
            <v-icon class="white--text">icons8-business-network</v-icon>
          </div>
          <div class="font-weight-bold white--text text-h6 d-flex align-center">
                            <span class="font-weight-light">
                                <strong>Create Opportunity </strong>
                              <!-- <span v-if="item.leadTitle !== ''">{{ item.leadTitle.substring(0, 40) }}...</span> -->
                            </span>
            <!-- <div class="ml-2 font-weight-light" v-if="item.leadTitle === ''">New</div> -->
          </div>
          <v-spacer/>
          <div>
            <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="opportunityDialog = false" depressed>
              <v-icon>icons8-multiply</v-icon>
            </v-btn>
          </div>
        </div>
        <v-card-text class="grey lighten-3 pa-0">
          <v-row class="pa-0 ma-0" fill-height>
            <v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
              <!-- <lead-details :datadown="item" v-on:save="createItem" /> -->
              <div class="font-weight-normal pa-3 text-h7 grey--text">
                If you want to create an Opportunity with this Lead, please select the Stage and select SAVE. If not,
                please select CANCEL.
              </div>
            </v-col>
            <v-col cols="12">
              <v-select label="Opportunity Stage" v-model="opportunityStage" :items="opportunityStages" outlined dense
                        hide-details="auto" background-color="white" color="primary" clearable
                        @click:clear="clearItem('leadStages')" :menu-props="{ top: false, offsetY: true }"
                       >
                <template v-slot:selection="data">
                  {{ data.item.stageName }}
                </template>
                <template v-slot:item="data">
                  {{ data.item.stageName }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="grey lighten-1 pa-2">
          <v-col cols="12" class="d-flex">
            <v-col cols="6" class="d-flex justify-start pa-0">
              <!-- <v-btn v-if="showAddLink" class="grey darken-1 white--text font-weight-bold" depressed @click="showAddLink = false; contentListTable = true"> <v-icon class="mr-2">icons8-back</v-icon><span>Back</span> </v-btn> -->
            </v-col>
            <v-col cols="6" class="d-flex justify-end pa-0">
              <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="opportunityDialog = false">
                <v-icon class="mr-2">icons8-multiply</v-icon>
                <span>Cancel</span></v-btn>
              <v-btn class="primary white--text font-weight-bold ml-2" :disabled="!opportunityStage" depressed
                     @click="createOpportunity">
                <v-icon class="mr-2">icons8-save</v-icon>
                <span>Save</span></v-btn>
            </v-col>
          </v-col>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </div>
</template>

<script>
import leadCard from '@/views/sales/LeadCard.vue'
import {Drag, DropList} from "vue-easy-dnd";

export default {
  components: {
    'lead-card': leadCard,
    Drag,
    DropList
  },
  props: {
    refreshitems: {
      type: Number,
      default: 0,
    },
    datadown: {
      type: Object,
      default: () => ({}),
    },
    listshrunk: {
      type: Boolean,
      default: false,
    },
    listexpanded: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    key: 'lead',
    index: 'idx:leads',
    lead: {},
    toggleDeletedItems: false,
    showImages: false,
    items: [],
    itemsTotal: null,
    searchQuery: '',
    itemsFrom: 0,
    itemsTo: 100,
    opportunityDialog: false,
    opportunityStage: '',

    // activeItem: {},
    // Toggles
    toggleFeatures: [],
    // Headers to display in the Data Table
    headers: [
      {text: "ID", value: "id", sortable: false, hidden: true, expandable: true},
      {text: "Lead Country", value: "salesCountry", sortable: false, hidden: true, expandable: true},
      {text: "Lead Local Authority", value: "salesLocalAuthority", sortable: true, hidden: true, expandable: true},
      {text: "Lead Tags", value: "salesTags", sortable: false, hidden: true, expandable: true},
      {text: "Lead Title", value: "salesTitle", sortable: false, hidden: false, expandable: false},
      {text: "Lead Channel", value: "salesChannel", sortable: false, hidden: false, expandable: true},
      {text: "Lead Status", value: "salesStatus", sortable: false, hidden: false, expandable: true},
      {text: "Lead Type", value: "salesType", sortable: false, hidden: false, expandable: false},
      {text: "Lead Time Priority", value: "salesTimePriority", sortable: false, hidden: false, expandable: false},

      {text: "Lead Stage Id", value: "salesStageId", sortable: false, hidden: false, expandable: false},
      {text: "Lead Stage Name", value: "salesStageName", sortable: false, hidden: false, expandable: false},

      {text: "Lead Org", value: "orgName", sortable: false, hidden: false, expandable: false},
      {text: "Lead Org Id", value: "orgId", sortable: false, hidden: false, expandable: false},

      {text: "Lead Source", value: "salesSource", sortable: false, hidden: true, expandable: false},
      {text: "Lead Source Name", value: "salesSourceName", sortable: false, hidden: true, expandable: false},
      {text: "Lead Description", value: "salesDescription", sortable: false, hidden: false, expandable: false},
      {text: "Lead Org Tags", value: "salesOrgTags", sortable: false, hidden: true, expandable: false},

      {text: "Owner Time (C)", value: "ownerDateTime", sortable: false, hidden: true, expandable: false},
      {text: "Owner ID (C)", value: "ownerUserId", sortable: false, hidden: false, expandable: false},
      {text: "Owner Name (C)", value: "ownerUserName", sortable: false, hidden: false, expandable: false},

      {text: "Owner Time (C)", value: "createdDateTime", sortable: false, hidden: false, expandable: false},
      {text: "User ID (C)", value: "createdUserId", sortable: false, hidden: true, expandable: false},
      {text: "User Name (C)", value: "createdUserName", sortable: false, hidden: true, expandable: false},
      {text: "User Email (C)", value: "createdUserEmail", sortable: false, hidden: true, expandable: false},
      {text: "Date Time (M)", value: "modifiedDateTime", sortable: false, hidden: false, expandable: false},
      {text: "User ID (M)", value: "modifiedUserId", sortable: false, hidden: true, expandable: false},
      {text: "User Name (M)", value: "modifiedUserName", sortable: false, hidden: true, expandable: false},
      {text: "User Email (M)", value: "modifiedUserEmail", sortable: false, hidden: true, expandable: false},
      {text: "Deleted", value: "deleted", sortable: false, hidden: true, expandable: false},
      {text: "Date Time (D)", value: "deletedDateTime", sortable: false, hidden: true, expandable: false},
      {text: "User ID (D)", value: "deletedUserId", sortable: false, hidden: true, expandable: false},
      {text: "User Name (D)", value: "deletedUserName", sortable: false, hidden: true, expandable: false},
      {text: "User Email (D)", value: "deletedUserEmail", sortable: false, hidden: true, expandable: false},
      {
        text: "Action",
        value: "action",
        align: "end",
        sortable: false,
        hidden: false,
        expandable: false,
        width: "155px"
      },
    ],
    // Fields to Retrieve from Database
    // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
    sortBy: '@salesTitle',
    datatableOptions: {
      page: 1,
      itemsPerPage: 100,
      sortBy: ['salesTitle'],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    },

    leadStage: {},
    newOpportunity: {},

    // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
    filter: {
      type: [],
      owner: [],
      package: [],
      status: [],
    },

    types: ['cold', 'warm', 'hot'],
    owners: [],
    packages: [],
    status: [],

    stages: [
      {
        stageName: 'New',
        stagePipeline: 'lead',
        stageValue: '1',
        stageOutcome: 'noValue',
        id: 'r8D6As3eKcscVzqlhumA'
      },
      {
        stageName: 'Contacted',
        stagePipeline: 'lead',
        stageValue: '2',
        stageOutcome: 'noValue',
        id: '1'
      },
      {
        stageName: 'Qualified',
        stagePipeline: 'lead',
        stageValue: '3',
        stageOutcome: 'success',
        id: '2'
      },
      {
        stageName: 'Not Qualified',
        stagePipeline: 'lead',
        stageValue: '4',
        stageOutcome: 'failure',
        id: '3'
      }
    ],
    opportunityStages: {}
  }),
  computed: {
    computedSearchQuery() {
      let searchQuery = this.searchQuery;

      if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
        if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
          searchQuery = '*';
        } else {
          searchQuery = '@deleted:0';
        }
      } else {
        if (searchQuery.startsWith('@')) {
          if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
            searchQuery = `${searchQuery}*`;
          } else {
            searchQuery = `${searchQuery}*  @deleted:0`;
          }
        } else {
          if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
            searchQuery = `${searchQuery}*`;
          } else {
            searchQuery = `${searchQuery}*  @deleted:0`;
          }
        }
      }
      // ////console.log(searchQuery);

      return searchQuery;
    },
    // Computed Headers
    computedHeaders() {
      let headers = this.headers;
      headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
      if (!this.listexpanded) {
        headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
      }
      return headers;
    },
    computedFields() {
      let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
      if (!computedFields.includes('@id')) {
        computedFields.push('@id');
      }
      if (!computedFields.includes('@deleted')) {
        computedFields.push('@deleted');
      }
      // if (!computedFields.includes('@actionId')) {
      //     computedFields.push('@actionId');
      // }
      computedFields = computedFields.join(',');
      return computedFields;
    },
    computedType() {
      const types = [];
      this.items.filter((item) => {
        return types.push(item.actionType);
      });
      return types;
    },
    computedStatus() {
      const status = [];
      this.items.filter((item) => {
        return status.push(item.actionStatus);
      });
      return status;
    },
  },
  methods: {
    // Clear Selected Org Tags
    clearSelectedTypeFilter() {
      setTimeout(() => {
        this.filter.type = [];
      }, 1);
    },
    // Clear Selected Org Tags
    clearSelectedOwnersFilter() {
      setTimeout(() => {
        this.filter.owner = [];
      }, 1);
    },
    // Clear Selected Org Tags
    clearSelectedPackagesFilter() {
      setTimeout(() => {
        this.filter.package = [];
      }, 1);
    },
    // Clear Selected Org Tags
    clearSelectedStatusFilter() {
      setTimeout(() => {
        this.filter.status = [];
      }, 1);
    },
    computedItems(stage) {
      var t = this;
      var items = this.items
      // // ////console.log("stage = " + JSON.stringify(stage, null, 2))

      items = items.filter((item) => item.salesStageId === stage.stageId);

      // ////console.log("items = " + JSON.stringify(items, null, 2))

      if (JSON.stringify(this.filter.type) !== '[]') {
        // ////console.log("filter.type")
        items = items.filter((item) => t.filter.type.includes(item.salesType))
      }
      if (JSON.stringify(this.filter.owner) !== '[]') {
        // ////console.log("filter.owner = " + JSON.stringify(this.filter.owner, null, 2))
        items = items.filter((item) => t.filter.owner.includes(item.ownerUserId))
      }
      if (JSON.stringify(this.filter.status) !== '[]') {
        // ////console.log("filter.status")
        items = items.filter((item) => t.filter.status.includes(item.salesStatus))
      }
      if (JSON.stringify(this.filter.package) !== '[]') {
        // ////console.log("filter.package = " + JSON.stringify(this.filter.package, null, 2))
        items = items.filter((item) => t.filter.package.includes(item.packageId))
      }

      var total = 0

      items.forEach((item) => {
        var value = parseInt(item.salesValue)
        if (value) {
          total = total + value
        }
      })

      items.total = total

      return items
    },
    dragS(e) {
      this.activeDrag = e.data;
      // ////console.log("drag started = " + JSON.stringify(e.data, null, 2));
    },
    onDrop() { //slotId was removed from here
      // ////console.log("slotId = " + slotId);
      // let index = this.items.findIndex(x => x.slotId == slotId)
      // // ////console.log('index = ' + index + ' and id = ' + slotId);
      // this.availabilitySlots[index].bookings.push(this.activeDrag);
      this.activeDrag = {};
      //      // ////console.log("dropped = " + JSON.stringify(name, null, 2));
    },
    remove() { //n was removed here
      // ////console.log("removing = " + JSON.stringify(n, null, 2));
      //   let index = this.items.indexOf(n);
      //   this.items.splice(index, 1);
    },
    removeItemFromList(event) {
      var t = this;
      // var previousStage =
      // ////console.log(JSON.stringify(event, null, 2));
      switch (event.opportunityStage) {
        case "Initial Interest/Lead Generated":
          t.computedInitialInterestItems.splice(t.computedInitialInterestItems.indexOf(event), 1);
          break;
        default:
          break;
      }
    },
    async createOpportunity() {
      var t = this;

      // ////console.log('creating opportunity = ' + JSON.stringify(t.leadStage , null, 2) + JSON.stringify(t.opportunityStage, null, 2))

      t.newOpportunity.salesStageName = t.leadStage.stageName
      t.newOpportunity.salesStageId = t.leadStage.stageId
      // ////console.log('newOpportunity 1 = ' + JSON.stringify(t.newOpportunity, null, 2))

      // SAVE ITEM - LEAD
      t.saveItem(t.newOpportunity)

      // Get the item from the database
      let itemResult = await this.MIX_readItem(t.newOpportunity.id, 'lead');
      if (itemResult.code === 1) {
        // this.$emit('edit', itemResult.data);
        t.newOpportunity = itemResult.data

      }

      // CHANGE STATUS TO NEW OPPORTUNITY STATUS
      t.newOpportunity.salesStageName = t.opportunityStage.stageName
      t.newOpportunity.salesStageId = t.opportunityStage.stageId
      t.newOpportunity.key = "opportunity"

      // ////console.log('newOpportunity 2 = ' + JSON.stringify(t.newOpportunity, null, 2))

      t.saveOpportunity(t.newOpportunity)

      t.opportunityDialog = false


    },
    showOpportunityDialog(event, stage) {
      var t = this;
      t.newOpportunity = []
      t.newOpportunity = event.data
      t.leadStage = stage
      t.opportunityDialog++
    },
    onInsert(event, stage) {
      var t = this
      // ////console.log("onInsert event = " + JSON.stringify(event, null, 2));
      // ////console.log("onInsert stage = " + JSON.stringify(stage, null, 2));
      if (stage.stageOutcome === 'success') {
        t.showOpportunityDialog(event, stage)
      } else {
        if (event.data.length > 0) {
          // ////console.log("here 1")
          event.data.forEach((e, idx) => {
            // event.index is the starting point of the target droplist
            // event.index + idx = appending the items one after the other
            // eslint-disable-next-line no-undef
            this[listName].splice(event.index + idx, 0, e);
          });
        } else {
          // ////console.log("here 2")
          // here we have just one item
          // @see https://codesandbox.io/s/droplist-ozs8b
          // ////console.log("this.list before = " + JSON.stringify(this.computedItems(stage), null, 2));
          this.computedItems(stage).splice(event.index, 0, event.data);
          // this.removeItemFromList(event.data)
          event.data.salesStageName = stage.stageName;
          event.data.salesStageId = stage.stageId;
          // ////console.log("event after stage = " + JSON.stringify(event, null, 2))
          // ////console.log("this.list after = " + JSON.stringify(this.computedItems(stage), null, 2));
          this.saveItem(event.data)
        }
      }

      this.activeDrag = [];
    },
    async saveItem(event) {
      let t = this;
      let result = await t.MIX_createItem(event, 'lead', false);
      if (result.code === 1) {
        t.MIX_alertBox({color: "success", timeout: 2000, message: "Saved", show: true});
        // t.readItem(item.id);
        // t.$emit("refreshitems");
      }
    },
    async saveOpportunity(event) {
      let t = this;
      let result = await t.MIX_createItem(event, 'opportunity', true);
      if (result.code === 1) {
        t.MIX_alertBox({color: "success", timeout: 2000, message: "Saved", show: true});
        // t.readItem(item.id);
        // t.$emit("refreshitems");
      }
    },
    toggleSelected(listName, item) {
      if (listName !== this.selectedList) {
        this.activeDrag = [];
        this.selectedList = listName;
      }

      // Basic toggeling logic
      // If an item is in the list remove it
      // otherwise add it to the list
      const index = this.activeDrag.indexOf(item);
      if (index > -1) {
        this.activeDrag.splice(index, 1);
      } else {
        this.activeDrag.push(item);
      }
    },

    async editItem(id) {
      // Get the item from the database
      let itemResult = await this.MIX_readItem(id, 'lead');
      if (itemResult.code === 1) {
        this.$emit('edit', itemResult.data);
      } else {
        this.MIX_alertBox({color: 'error', timeout: 4000, message: 'Error Editing Lead', show: true});
      }
    },
    refreshItems: function () {
      this.getItems();
    },
    itemRowBackground: function (item) {
      return item.deleted === '1' ? 'purple lighten-5' : '';
    },
    // * DESTROY ITEM FROM ITEMS
    async destroyItem(itemId) {
      let result = await this.MIX_destroyItem(itemId, this.key);
      if (result.code === 1) {
        this.getItems();
      }
    },
    // * DELETE ITEM FROM ITEMS
    async deleteItem(itemId) {
      let result = await this.MIX_deleteItem(itemId, this.key);
      if (result.code === 1) {
        this.getItems();
      }
    },
    // * RESTORE ITEM FROM ITEMS
    async restoreItem(itemId) {
      let result = await this.MIX_restoreItem(itemId, this.key);
      if (result.code === 1) {
        this.getItems();
      }
    },
    // * OPEN ITEM
    openItem(item) {
      this.$emit('openItem', JSON.parse(JSON.stringify(item)));
    },
    // openLeadItem(item) {

    // },
    // * OPEN ITEM
    openLead(item) {
      // ////console.log("opening lead = " + JSON.stringify(item, null, 2));
      this.$emit('openLead', JSON.parse(JSON.stringify(item)));
    },
    clearSearchQuery() {
      this.searchQuery = ''
    },
    // * GET ITEMS
    async getItems() {
      // if (this.searchQuery.length !== 1) {
      let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
      if (itemsResult.code === 1) {
        this.itemsTotal = itemsResult.data.total;
        this.items = itemsResult.data.results;
        let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
        if (itemsTo < this.itemsTotal) {
          this.itemsTo = itemsTo;
        } else {
          this.itemsTo = this.itemsTotal;
        }
      }
      // }
    },
  },
  watch: {
    datadown: {
      handler: function () {
        this.lead = {...this.datadown};
      },
      deep: true,
    },
    refreshitems() {
      this.getItems();
    },
    // If the search query changes then get updated dataset based on search query
    computedSearchQuery() {
      this.getItems();
    },
    computedFields() {
      this.getItems();
    },
    toggleDeletedItems() {
      this.getItems();
    },
    showImages() {
      let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
      if (this.showImages) {
        this.headers[imagesIndex].hidden = false;
      } else {
        this.headers[imagesIndex].hidden = true;
      }
    },
    datatableOptions: {
      handler() {
        this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
        let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
        if (itemsTo < this.itemsTotal) {
          this.itemsTo = itemsTo;
        } else {
          this.itemsTo = this.itemsTotal;
        }
        this.getItems();
      },
      deep: true,
    },
  },
  async created() {
    var t = this
    this.lead = {...this.datadown};
    let stages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
    //console.log("stages = " + JSON.stringify(stages, null, 2));
    stages = stages.data.documents.map((x) => x.value);
    t.stages = stages.filter((item) => {
      return item.stagePipeline === 'Leads';
    })
    t.stages.sort((a, b) => a.stageValue.localeCompare(b.stageValue));

    t.opportunityStages = stages.filter((item) => {
      return item.stagePipeline === 'Opportunities'
    })
    t.opportunityStages = t.opportunityStages.map((x) => {
      return {
        stageId: x.id,
        stageName: x.stageName,
        stageOutcome: x.stageOutcome
      };
    })

    t.stages = t.stages.map((x) => {
      return {
        stageId: x.id,
        stageName: x.stageName,
        stageOutcome: x.stageOutcome
      };
    })

    let owners = await t.MIX_users();
    t.owners = owners.map((x) => {
      return {
        ownerUserId: x.id,
        ownerUserName: x.userName,
        ownerUserEmail: x.userEmail,
      };
    });
    // ////console.log("owners  = " + JSON.stringify(t.owners, null, 2))

    let status = await t.MIX_redisReadSortedSet('sset:leadStatus', 0, 9999);
    if (status.code === 1) {
      t.status = status.data;
    }

    let packages = await this.MIX_redisSearch('idx:packages', `*`, 0, 9999);
    packages = packages.data.documents.map((x) => x.value);

    // ////console.log("packages = " + JSON.stringify(packages, null, 2))

    t.packages = packages.map((x) => {
      return {
        packageId: x.id,
        packageName: x.packageName,
      };
    });


    this.getItems();
  },
};
</script>

<style scoped>
.borderBottom {
  border-bottom: 5px solid #3c0054 !important;
}

.list {
  /* border: 1px solid black; */
  /* margin: 100px auto; */
  /* width: 200px; */
  min-height: 600px;
}

.overflow {
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>