<template>
	<div class="" style="padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow: scroll !important">
		<v-row class="apptext--text" dense>
			<v-col cols="12" class="d-flex align-center justify-center grey darken-1 py-4">
				<div class="ml-3">
					<v-btn :disabled="item.quoteStatus !== 'Draft'" text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
					<v-btn :disabled="item.quoteStatus !== 'Draft'" text class="accent secondary--text" v-if="editmode" @click="saveItem" :large="!dense"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
				</div>
				<v-spacer />
			</v-col>
		</v-row>
		<!-- Item Form -->
		<v-row class="px-3 mt-3">
			<v-col cols="12" sm="12" md="12">
				<v-row>
					<v-col cols="12" sm="12">
						<div class="text-left primary--text text-h6 font-weight-light">Quote Terms</div>
						<v-divider class="grey lighten-1 mb-1"></v-divider>
					</v-col>
					<v-col cols="12" sm="3" v-if="editmode">
						<v-checkbox :disabled="terms.Custom" v-model="terms.Standard" label="Standard Terms" class="ma-0 pa-0 my-1" hide-details="auto"></v-checkbox>
						<v-checkbox :disabled="!editmode || terms.Custom" v-model="terms.IncludesDelivery" label="Includes Delivery" class="ma-0 pa-0 my-1" hide-details="auto"></v-checkbox>
						<v-checkbox :disabled="!editmode || terms.Custom" v-model="terms.ExcludesSkips" label="Excludes Skips" class="ma-0 pa-0 my-1" hide-details="auto"></v-checkbox>
						<v-checkbox :disabled="!editmode || terms.Custom" v-model="terms.IncludesMCD" label="Includes MCD" class="ma-0 pa-0 my-1" hide-details="auto"></v-checkbox>
						<v-checkbox :disabled="!editmode || terms.Custom" v-model="terms.ExcludesMCD" label="Excludes MCD" class="ma-0 pa-0 my-1" hide-details="auto"></v-checkbox>

						<br /><v-checkbox v-if="['Developer-Admin', 'Staff-Directors', 'Staff-Managers'].includes(GET_FIREBASE_userAccessToken.user_level)" :disabled="!editmode" v-model="terms.Custom" label="Custom Terms" class="ma-0 pa-0 my-1" hide-details="auto"></v-checkbox>
					</v-col>					
					<v-col cols="12" :sm="editmode ? 9:12">
						<div v-if="!terms.Custom" v-html="item.quoteTerms"></div>
						<div v-if="terms.Custom"><v-textarea class="mt-3" rows="15" label="Terms" v-model="item.quoteTerms" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('quoteTerms')"> </v-textarea></div>
					</v-col>
				</v-row>
			</v-col>
			<!-- <v-col cols="12">
				<pre>{{ quoteTermOptions }}</pre>
			</v-col> -->
			<v-col v-if="editmode" cols="12" class="mt-3 mb-5 grey darken-1 pa-3 d-flex justify-right align-center">
				<v-spacer />
				<div>
					<v-btn class="grey lighten-2 secondary--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
				</div>
				<div class="ml-2">
					<v-btn class="accent primary--text font-weight-bold" depressed @click="saveItem"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	/* eslint-disable no-unused-vars */
	import { mapGetters } from "vuex";
	import schema from "@/schema.js";
	export default {
		// * COMPONENTS
		components: {},
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
			dense: {
				type: Boolean,
				default: false,
			},
		},
		// * DATA
		data: () => ({
			editmode: false,
			// Item
			item: {},
			// terms
			terms: {
				Standard: true,
				Estimate: false,
				IncludesDelivery: false,
				ExcludesSkips: false,
				IncludesMCD: false,
				ExcludesMCD: false,
				Custom: false
			},
			quoteTermOptions: {}
		}),
		// * COMPUTED
		computed: {
            ...mapGetters({
                GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
            }),
        },
		// * WATCH
		watch: {
			datadown: {
				handler: function () {
					this.item = { ...this.datadown };
					if (this.item.approval === "0") {
						this.terms.estimate = true;
					}
				},
				deep: true,
			},
			editmode: {
				handler: function () {
					if (this.editmode) {
						this.terms.Standard = false;
						this.terms.IncludesDelivery = false;
						this.terms.ExcludesSkips = false;
						this.terms.IncludesMCD = false;
						this.terms.ExcludesMCD = false;
						this.terms.Custom = false;
						this.item.quoteTerms = "";
					}
				}
			},
			terms: {
				handler: function () {
					let terms = "";
					if (this.terms.Standard) {
						terms = terms + this.quoteTermOptions.Standard
					}
					if (this.terms.IncludesDelivery) {
						terms = terms + "<li>" + this.quoteTermOptions.IncludesDelivery + "</li>"
					}
					if (this.terms.ExcludesSkips) {
						terms = terms + "<li>" + this.quoteTermOptions.ExcludesSkips + "</li>"
					}
					if (this.terms.IncludesMCD) {
						terms = terms + "<li>" + this.quoteTermOptions.IncludesMCD + "</li>"
					}					
					if (this.terms.ExcludesMCD) {
						terms = terms + "<li>" + this.quoteTermOptions.ExcludesMCD + "</li>"
					}
					this.item.quoteTerms = terms
				},
				deep: true,
			}
		},
		// * METHODS
		methods: {
			saveItem() {
				this.$emit("save", this.item);
				this.editmode = false;
			},
			cancelItem() {
				this.editmode = false;
				this.$emit("cancel");
			},
			clearItem(property) {
				setTimeout(() => {
					this.item[property] = "";
				}, 1);
			},
		},
		async created() {
			let quoteTermOptionsResult = await this.MIX_readItem("options", "quoteTerms")
			this.quoteTermOptions = quoteTermOptionsResult.data;
			this.item = schema.order;
			this.item = { ...this.datadown };
		},
	};
</script>

<style scoped></style>
