<template>
    <div>
        <v-row class="pa-0 ma-0" fill-height>
            <v-row no-gutters class="py-3 px-3">
                <v-col class="font-weight-bold primary--text text-h6 d-flex align-center">
                    <div class="mx-3"><v-icon>icons8-list</v-icon></div>
                    <div class="text-h7" v-if="item.attName">{{ item.attName }}</div>
                    <div class="text-h7" v-else>New Attribute</div>
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn class="ml-2 primary--text" plain :small="GET_dense" @click="$emit('closeItem')" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-col class="pa-0" fill-height :cols="viewCols.cols" :xs="viewCols.xs" :sm="viewCols.sm" :md="viewCols.md" :lg="viewCols.lg" :xl="viewCols.xl">
                <v-tabs background-color="white" v-model="tab" show-arrows>
                    <v-tab href="#details">Details</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" fill-height class="grey lighten-3">
                    <!-- COLOUR DETAILS TAB -->
                    <v-tab-item value="details">
                        <!-- Menu Buttons -->
                        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
                            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                                <div class="ml-3 d-flex align-center" v-if="loading">
                                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                                </div>
                                <div class="ml-3">
                                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="$emit('closeItem')"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                                </div>
                                <v-spacer />
                                <!-- <div class="mr-3" v-if="!loading">
                                    <v-btn text class="error white--text" v-if="!editmode" @click="destroyItem()"><v-icon class="mr-2">icons8-trash-can</v-icon>Destroy</v-btn>
                                </div> -->
                                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                                    <v-btn text class="accent secondary--text" v-if="editmode" @click="validateForm()"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="pa-3" no-gutters>
                            <v-col cols="12" sm="12" class="my-3">
                                <div class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">
                                        Attribute Details
                                    </div>
                                    <v-spacer />
                                    <div><v-icon class="primary--text" large @click="toggleHelp = !toggleHelp">icons8-help</v-icon></div>
                                </div>
                                <div  v-if="toggleHelp" class="secondary lighten-5 pa-5 rounded">
                                    <h3>Help</h3>
                                    <div class="body-2">
                                        Attributes are the values available under each option and required for both Standard Options and Variant Options for example:<br />
                                    </div>
                                    <table border="0" width="100%" class="body-2 mt-3">
                                        <tr>
                                            <td><strong>Option Name:</strong> Fabric Band</td>
                                            <td><strong>Option Name:</strong> Colour</td>
                                            <td><strong>Option Name:</strong> Size</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Attribute Name:</strong> A</td>
                                            <td><strong>Attribute Name:</strong> Blue</td>
                                            <td><strong>Attribute Name:</strong> 100 x 200 x 300</td>
                                        </tr>
                                    </table>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" class="grey lighten-5 roundedtop mt-2 pt-3">
                                <div class="ml-4 primary--text caption">* Required Fields</div>
                                <v-form ref="form" lazy-validation>
                                    <v-simple-table dense class="apptext--text transparent">
                                        <template v-slot:default>
                                            <tbody>
                                                <tr>
                                                    <td width="50%"><strong>Supplier <span class="primary--text">*</span></strong><br />Select an organisation</td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ item.orgName }}</div>
                                                        <div v-else>
                                                            <v-autocomplete
                                                                v-if="editmode"
                                                                :items="orgs"
                                                                item-text="orgName"
                                                                return-object
                                                                label="Organisation Name"
                                                                :rules="rules.organisationName"
                                                                v-model="selectedOrg"
                                                                auto-select-first
                                                                @change="
                                                                    item.orgName = selectedOrg.orgName;
                                                                    item.orgId = selectedOrg.orgId;
                                                                "
                                                                @click:clear="clearSelectedOrg()"
                                                                outlined
                                                                dense
                                                                hide-details="auto"
                                                                background-color="white"
                                                                color="primary"
                                                                clearable
                                                            ></v-autocomplete>
                                                        </div>
                                                    </td>
                                                </tr>	
                                            <!-- <tr>
                                                    <td width="50%"><strong>Organisation ID</strong><br />Organisation ID</td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ item.orgId }}</div>
                                                        <div v-else>
                                                            <v-text-field label="Supplier ID" v-model="item.orgId" outlined dense hide-details="auto" background-color="white" color="primary" clearable disabled></v-text-field>
                                                        </div>
                                                    </td>
                                                </tr> -->
                                                <tr>
                                                    <td width="50%"><strong>Option Name <span class="primary--text">*</span></strong><br />Attribute belongs to Option e.g Frame Colour</td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ item.optionName }}</div>
                                                        <div v-else><v-autocomplete label="Option Name" auto-select-first v-model="optionSelected" :items="computedOptions" :rules="rules.optionName" return-object item-text="optionName" outlined dense hide-details="auto" background-color="white" color="primary" clearable :value="item.optionName"></v-autocomplete></div>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                    <td width="50%"><strong>Option ID</strong></td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ item.optionId }}</div>
                                                        <div v-else><v-text-field disabled v-model="item.optionId" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                    </td>
                                                </tr> -->

                                                <tr>
                                                    <td width="50%"><strong>Attribute Name <span class="primary--text">*</span></strong><br />Name of Attribute e.g Blue</td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ item.attName }}</div>
                                                        <div v-else><v-text-field label="Attribute Name" v-model="item.attName" :rules="rules.attName" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%"><strong>Type <span class="primary--text">*</span></strong><br />Enter type for example Colour or Size</td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ item.attType }}</div>
                                                        <div v-else><v-text-field label="Attribute Type" v-model="item.attType" :rules="rules.attType" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%"><strong>Code</strong><br />Code's are added to the end of the Product Code e.g ABC001-BL </td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ item.attCode }}</div>
                                                        <div v-else><v-text-field label="Attribute Code" v-model="item.attCode" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%"><strong>Enabled</strong></td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">
                                                            <span v-if="item.enabled === '1'">Yes</span>
                                                            <span v-else>No</span>
                                                        </div>
                                                        <div v-else><v-switch v-model.number="item.enabled" inset outlined dense hide-details="auto" background-color="#fafafa" color="primary" true-value="1" false-value="0" :value="item.enabled" class="ma-0 pa-0"></v-switch></div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="50%">
                                                        <strong>Parent Name</strong>
                                                        <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleParent = !toggleParent">
                                                            <span v-if="!toggleParent">Show</span>
                                                            <span v-if="toggleParent">Hide</span>
                                                        </v-btn>
                                                        <span v-if="toggleParent"><br />Enter the Parent Attribute Name this attribute depends on e.g Spray PU Edge</span>
                                                    </td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="toggleParent">
                                                            <div v-if="!editmode">{{ item.attParentId }}</div>
                                                            <div v-else><v-text-field label="Parent Attribute Name" v-model="item.attParentId" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                        </div>
                                                    </td>
                                                </tr>                                            
                                                <tr>
                                                    <td width="50%">
                                                        <strong>MOQ</strong>
                                                        <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleMOQ = !toggleMOQ">
                                                            <span v-if="!toggleMOQ">Show</span>
                                                            <span v-if="toggleMOQ">Hide</span>
                                                        </v-btn>
                                                        <span v-if="toggleMOQ"><br />Minimum Order Quantity</span>
                                                    </td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="toggleMOQ">
                                                            <div v-if="!editmode">{{ item.attMTO }}</div>
                                                            <div v-else><v-text-field label="Minimum Order Quantity (MOQ)" v-model="item.attMTO" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%">
                                                        <strong>Price Band</strong>
                                                        <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="togglePriceBand = !togglePriceBand">
                                                            <span v-if="!togglePriceBand">Show</span>
                                                            <span v-if="togglePriceBand">Hide</span>
                                                        </v-btn>
                                                        <span v-if="togglePriceBand"><br />Pricing band if any A-F</span>
                                                    </td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="togglePriceBand">
                                                            <div v-if="!editmode">{{ item.attPriceBand }}</div>
                                                            <div v-else><v-text-field label="Price Band" v-model="item.attPriceBand" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%">
                                                        <strong>Additional Cost</strong>
                                                            <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleAddCost = !toggleAddCost">
                                                            <span v-if="!toggleAddCost">Show</span>
                                                            <span v-if="toggleAddCost">Hide</span>
                                                        </v-btn>
                                                        <span v-if="toggleAddCost"><br />Supplier additional cost for this attribute</span>
                                                    </td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="toggleAddCost">
                                                            <div v-if="!editmode">£ {{ item.attAddCost }}</div>
                                                            <div v-else><v-text-field label="Additional Cost" prefix="£" v-model="item.attAddCost" :rules="rules.attAddCost" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                        </div> 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%">
                                                        <strong>Additional Sell</strong>
                                                            <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleAddSell = !toggleAddSell">
                                                            <span v-if="!toggleAddSell">Show</span>
                                                            <span v-if="toggleAddSell">Hide</span>
                                                        </v-btn>
                                                        <span v-if="toggleAddSell"><br />Additional sell price for this attribute</span>
                                                    </td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="toggleAddSell">
                                                            <div v-if="!editmode">£ {{ item.attAddSell }}</div>
                                                            <div v-else><v-text-field label="Additional Sell" prefix="£" v-model="item.attAddSell" :rules="rules.attAddSell" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%" class="pt-2" valign="top">
                                                        <strong>Description</strong>
                                                            <span v-if="toggleDescription"><br />Enter a description if required</span>
                                                            <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleDescription = !toggleDescription">
                                                            <span v-if="!toggleDescription">Show</span>
                                                            <span v-if="toggleDescription">Hide</span>
                                                        </v-btn>
                                                    </td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="toggleDescription">
                                                            <div v-if="!editmode">{{ item.attDescription }}</div>
                                                            <div v-else><v-textarea label="Attribute Description" v-model="item.attDescription" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-textarea></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                    <td width="50%"><strong>Hex Attribute (Swatch)</strong></td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ item.attHexColor }}</div>
                                                        <div v-else><v-text-field v-model="item.attHexColor" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%"><strong>Image (Swatch)</strong></td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ item.attImage }}</div>
                                                        <div v-else><v-text-field v-model="item.attImage" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                    </td>
                                                </tr> -->
                                                <tr>
                                                    <td width="50%"><strong>ID</strong><br /></td>
                                                    <td width="50%" class="py-2">
                                                        <div v-if="!editmode">{{ itemId }}</div>
                                                        <div v-else><v-text-field label="Attribute ID" v-model="itemId" readonly append-outer-icon="" outlined dense hide-details="auto" background-color="white" color="primary"></v-text-field></div>
                                                    </td>
                                                </tr>                                            
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-form>
                                <!-- <pre>{{ optionSelected }}</pre>
								<br /><br />								
								<pre>{{ item }}</pre> -->
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import schema from '@/schema.js';
export default {
    // * COMPONENTS
    components: {},
    // * PROPS
    props: {
        activeitem: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    // * DATA
    data: () => ({
        loading: false,
        addImageToggle: false,
        addPricingToggle: true,
        editmode: false,
        tab: 'details',
        itemNew: false,
        key: 'attribute',
		orgs: [],
		selectedOrg: {
            orgName: '',
            orgId: '',
        },
        schema: null,
        options: [],
        optionSelected: {},
        // Validation
        rules: {},
        // Active Item
        item: {},
        // Price Bands
        priceBands: ['A', 'B', 'C', 'D', 'E', 'F'],
        // toggle
        toggleHelp: false,
        toggleParent: false,
        toggleAddCost: false,
        toggleMOQ: false,
        togglePriceBand: false,
        toggleAddSell: false,
        toggleDescription: false
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
            GET_destroy: 'GET_destroy',
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
        }),
        viewCols() {
            return {
                cols: 12,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            };
        },
		itemId() {
            // remove spaces and special characters from attName
            return this.item.optionId + '_' + this.item.attName.replace(/[^a-zA-Z0-9]/g, '');
		},
        computedOptions() {
            return this.options.filter(option => option.orgId === this.selectedOrg.orgId).filter(option => {
                return {
                    text: option.optionName,
                    value: option.optionId,
                };
            });
        },
    },
    // * METHODS
    methods: {
        // Validate Select
        validateSelect(v) {
            console.log('validateSelect', JSON.stringify(v, null, 2));
            if (JSON.stringify(v) === '{}') {
                return false;
            } else {
                return true;
            }
        },
        // Validate Organisation
        validateOrganisation(v) {
            console.log('validateOrganisation', JSON.stringify(v, null, 2));
            if (JSON.stringify(v) === '{"orgName":"","orgId":""}') {
                return false;
            } else {
                return true;
            }
        },
        // Validate Number
        isNumber(v) {
            // console.log('isNumber', v);
            if (v === null || v === undefined || v === '') {
                return true;
            } else {
                return !isNaN(parseFloat(v)) && isFinite(v);
            }
        },
        // Validate Form
        validateForm() {
            // Validate Form
            this.rules = {
                organisationName: [(v) => this.validateOrganisation(v) || 'Organisation Name is required'],
                attName: [(v) => !!v || 'Attribute Name Required'],
                attType: [(v) => !!v || 'Attribute Type Required'],
                optionName: [(v) => this.validateSelect(v) || 'Option Name is required'],
                attAddCost: [(v) => this.isNumber(v) || 'Additional Cost must be a number'],
                attAddSell: [(v) => this.isNumber(v) || 'Additional Sell must be a number'],
            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    this.saveItem();
                } else {
                    this.MIX_alertBox({ color: "error", timeout: 4000, message: "Please fill out all the fields", show: true });                    
                }
            });
            //this.saveItem();
        },
        // * INITIALISE ITEM
        initialiseItem() {
            let t = this;
            if (JSON.stringify(t.activeitem) !== '{}') {
                this.readItem();
                t.editmode = false;    
            } else {
                t.itemNew = true;
                t.editmode = true;
				t.clearSelectedOrg();
                t.item = { ...t.schema };
                t.item.key = 'attribute';
            }
        },
        saveItem() {
            if (this.itemNew) {
                this.createItem();
            } else {
                this.updateItem();
            }
        },
        // * CREATE ITEM
        async createItem() {
            this.item.id = this.itemId;
			this.item.orgId = this.selectedOrg.orgId;
			this.item.orgName = this.selectedOrg.orgName;
            this.item.attName = this.item.attName.replace(/[^a-zA-Z0-9]/g, '');
            // this.item.id = this.item.attName.replace(/\s/g, '');
            let result = await this.MIX_createItem(this.item, this.key, this.itemNew);
            console.log('result createItem', JSON.stringify(result, null, 2));
            if (result.code === 1) {
				this.MIX_alertBox({ color: "success", timeout: 2000, message: "Attribute Saved", show: true });
				this.editmode = false;
                // this.initialiseItem();
            }
        },
        // * READ ITEM
        async readItem() {
            let t = this;
            let result = await t.MIX_readItem(t.activeitem.id, t.key);
            if (result.code === 1) {
                this.item = result.data;
                t.editmode = false;
                t.itemNew = false;
				t.selectedOrg = {
					orgId: t.item.orgId,
					orgName: t.item.orgName
				}
                t.optionSelected = {
                    id: t.item.optionId,
                    optionName: t.item.optionName,
                }
            }
        },
        // * UPDATE ITEM
        async updateItem() {
            // this.item.id = this.item.orgId + "_" + this.item.optionName.replace(/\s/g, '');
            this.item.id = this.itemId;
			this.item.orgId = this.selectedOrg.orgId;
			this.item.orgName = this.selectedOrg.orgName;
            let result = await this.MIX_createItem(this.item, this.key, false);
            if (result.code === 1) {
                this.initialiseItem();
            }
        },
        // * DESTROY ITEM
        async destroyItem() {
            let result = await this.MIX_destroyItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem();
            }
        },
        // * DELETE ITEM
        async deleteItem() {
            let result = await this.MIX_deleteItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem();
            }
        },
        // * RESTORE ITEM
        async restoreItem() {
            let result = await this.MIX_restoreItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem();
            }
        },
        // * GET OPTIONS
        async getOptions() {
            let result = await this.MIX_redisSearch('idx:options', '@deleted:0 @enabled:1', 0, 9999);
            if (result.code === 1) {
                this.options = result.data.documents.map((x) => {
                    return {
                        id: x.value.id,
                        optionName: x.value.optionName,
                        orgId: x.value.orgId
                    };
                });
            }
        },
        setOptionSelected() {
            this.item.optionId = this.optionSelected.id;
            this.item.optionName = this.optionSelected.optionName;
        },
		clearSelectedOrg() {
			let t = this;
            setTimeout(() => {
                t.selectedOrg.orgId = '';
				t.selectedOrg.orgName = '';
				t.item.orgId = '';
				t.item.orgName = '';
            }, 1);
		}        
    },
    // * WATCH
    watch: {
        activeitem() {
            this.initialiseItem();
        },
		// item: {
		// 	handler() {
		// 		this.itemId = this.item.orgId + "_" + this.item.optionName.replace(/\s/g, '');
		// 	}, deep: true
		// },        
        optionSelected: {
            handler: function () {
                this.item.optionId = this.optionSelected.id;
                this.item.optionName = this.optionSelected.optionName;
                // this.setOptionSelected();
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        this.loading = true;        
        this.schema = schema.attribute;
		let orgs = await this.MIX_organisations('Supplier');
		this.orgs = orgs.map(x => {
			return {
				orgId: x.id,
				orgName: x.orgName
			}
		});		
        this.initialiseItem();
        await this.getOptions();
        console.log('this.options', JSON.stringify(this.options, null, 2))
        this.setOptionSelected;
        this.loading = false;     
        console.log('selectOrg ' + JSON.stringify(this.selectedOrg))   
    },
};
</script>
