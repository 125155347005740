<template>
  <div class="d-flex flex-column ma-0 pa-0">
    <div class="px-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
      <div class="mb-1">
        <v-icon class="white--text" large>icons8-menu-squared</v-icon>
      </div>
      <div class="white--text text-h6 d-flex align-center">
                <span class="font-weight-normal ml-2">
					<span v-if="item.salesTitle === ''">New</span>
                    Opportunity
                  <!-- <span v-if="item.salesTitle !== ''">: {{ item.salesTitle.substring(0, 40) }}...</span> -->
                </span>
      </div>
      <v-spacer/>
      <div>
        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeDialog()" depressed>
          <v-icon>icons8-multiply</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="pa-0 ma-0 white">
      <opportunity-details :datadown="item" v-on:save="createItem" v-on:close="closeDialog()"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import opportunityDetails from "@/views/sales/OpportunityDetails.vue";
// import packageComponents from "@/views/packages/PackageComponents.vue";
import schema from "@/schema.js";

export default {
  // * COMPONENTS
  components: {
    "opportunity-details": opportunityDetails,
    // "package-components": packageComponents,
  },
  // * PROPS
  props: {
    datadown: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  // * DATA
  data: () => ({
    key: "opportunity",
    tab: "details",
    schema: null,
    // Item
    item: {},
    itemNew: false,
    editMode: false,

    // Form items
    accountTypes: [],
    addressTypes: [],
    industries: [],

    // Tags
    computedOrgTags: [],

    // Validation
    rules: {},

    // Toggles
    addAddressToggle: false,
    addManualAddress: false,
    addOtherAddressToggle: false,
    addLeadStatusToggle: false,
    addOrgInfoToggle: false,
    addSocialInfoToggle: false,
  }),
  // * COMPUTED
  computed: {
    ...mapGetters({
      GET_taskBar: "GET_taskBar",
      // GET_dense: "GET_dense",
      GET_destroy: "GET_destroy",
    }),
    computedFullName() {
      return this.item.contactFirstName + " " + this.item.contactLastName;
    },
  },
  // * METHODS
  methods: {
    // * INITIALISE ITEM
    initialiseItem() {
      let t = this;
      if (JSON.stringify(t.datadown) !== "{}") {
        this.readItem(t.datadown.id);
      } else {
        t.itemNew = true;
        t.editMode = true;
        t.item = {...t.schema};
        t.item.id = t.MIX_generateId();
      }
    },
    // * VALIDATION RULES
    // TODO - RG - I think this needs to be moved to contactDetails.vue
    validateItem() {
      let t = this;
      t.rules = {
        // GENERAL DETAILS
        orgName: [(v) => !!v || "Organisation Name Required"],
        // orgRegisteredName: [ v => !!v || 'Registered Name Required'],
        // orgRegistrationNumber: [ v => !!v || 'Registration Number Required'],
        // orgTelephone: [ v => !!v || 'Telephone Required'],
        // orgWebsite: [ v => !!v || 'Website Required'],
        // orgEmail: [ v => !!v || 'Email Required'],
        // orgInvoiceEmail: [ v => !!v || 'Invoice Email Required'],
        // orgPurchasingEmail: [ v => !!v || 'Purchasing Email Required'],

        // ADDRESS DETAILS
        // orgAddressType: [ v => !!v || 'Address Type Required'],
        // orgAddressLine1: [ v => !!v || 'Address Line 1 Required'],
        // orgAddressLine2: [ v => !!v || 'Address Line 2 Required'],
        // orgAddressLine3: [ v => !!v || 'Address Line 3 Required'],
        // orgTown: [ v => !!v || 'Town Required'],
        // orgCounty: [ v => !!v || 'County Required'],
        // orgPostcode: [ v => !!v || 'Postcode Required'],

        // OTHER ADDRESS DETAILS
        // orgOtherAddressType: [ v => !!v || 'Address Type Required'],
        // orgOtherAddressLine1: [ v => !!v || 'Address Line 1 Required'],
        // orgOtherAddressLine2: [ v => !!v || 'Address Line 2 Required'],
        // orgOtherAddressLine3: [ v => !!v || 'Address Line 3 Required'],
        // orgOtherTown: [ v => !!v || 'Town Required'],
        // orgOtherCounty: [ v => !!v || 'County Required'],
        // orgOtherPostcode: [ v => !!v || 'Postcode Required'],

        // ORG INFO
        // orgEmployees: [ v => !!v || 'No of Employees Required'],
        // orgIndustry: [ v => !!v || 'Industry Required'],
        // orgSector: [ v => !!v || 'Sector Required'],
        // orgAccountNumber: [ v => !!v || 'Account Number Required'],
        //orgAccountTypes: [ v => !!v || 'Account Type Required'],
        // orgLocalAuthority: [ v => !!v || 'Local Authority Required'],
        // orgTurnover: [ v => !!v || 'Turnover Required'],

        // OWNER / SOURCE INFO
        // orgAccountOwner: [ v => !!v || 'Account Owner Required'],
        // orgSource: [ v => !!v || 'Source Required'],
        // orgSourceName: [ v => !!v || 'Source Name Required'],

        // SOCIAL INFO
        // orgTwitter: [ v => !!v || 'Twitter Required'],
        // orgFacebook: [ v => !!v || 'Facebook Required'],
        // orgLinkedIn: [ v => !!v || 'LinkedIn Required'],
        // orgInstagram: [ v => !!v || 'Instagram Required'],
      };
      t.$nextTick(() => {
        if (t.$refs.form.validate()) {
          this.createItem();
          this.editMode = false;
        } else {
          this.MIX_alertBox({
            color: "error",
            timeout: 2000,
            message: "Fill out all the fields correctly in order to proceed.",
            show: true
          });
        }
      });
    },
    // * CREATE ITEM
    async createItem(item) {
      let t = this;
      let result = await t.MIX_createItem(item, t.key, t.itemNew);
      if (result.code === 1) {
        t.MIX_alertBox({color: "success", timeout: 2000, message: "Saved", show: true});
        t.readItem(item.id);
        t.$emit("refreshItems");
        t.closeDialog()
      }
    },
    // * READ ITEM
    async readItem(itemId) {
      let t = this;
      let result = await t.MIX_readItem(itemId, t.key);
      if (result.code === 1) {
        this.item = result.data;
        t.itemNew = false;
        t.loading = false;
      }
    },
    // * DESTROY ITEM
    async destroyItem() {
      let result = await this.MIX_destroyItem(this.item.id, this.key);
      if (result.code === 1) {
        this.readItem(this.item.id);
      }
    },
    // * DELETE ITEM
    async deleteItem() {
      let result = await this.MIX_deleteItem(this.item.id, this.key);
      if (result.code === 1) {
        this.readItem(this.item.id);
      }
    },
    // * RESTORE ITEM
    async restoreItem() {
      let result = await this.MIX_restoreItem(this.item.id, this.key);
      if (result.code === 1) {
        this.readItem(this.item.id);
      }
    },
    // Close Item
    closeDialog() {
      this.$emit("closeitem");
    },
    cancelItem() {
      this.readItem(this.item.id);
    }
  },
  // * WATCH
  watch: {
    // Datadown Changes
    datadown() {
      this.initialiseItem();
    },
    computedOrgTags() { //removed val form func
      var t = this;
      var splitVal = [];
      splitVal = t.item.orgTags.split(",");

      // ////console.log(JSON.stringify(val, null, 2));
      return splitVal;
    },
  },
  // * CREATED
  async created() {
    let t = this;
    t.loading = true;
    t.schema = schema.opportunity;
    t.initialiseItem();

    let accountTypes = await t.MIX_redisReadSet("set:orgAccountTypes", false);
    if (accountTypes.code === 1) {
      t.accountTypes = accountTypes.data;
    }

    let addressTypes = await t.MIX_redisReadSortedSet("sset:addressTypes", 0, 9999);
    if (addressTypes.code === 1) {
      t.addressTypes = addressTypes.data;
    }

    let industries = await t.MIX_redisReadSet("set:industries", true);
    if (industries.code === 1) {
      t.industries = industries.data;
    }
  },
  // * MOUNTED
  async mounted() {
  },
};
</script>

<style scoped>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 22px;
}
</style>
