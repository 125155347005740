<template>
    <v-row class="pa-0 ma-0" fill-height>
        <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="pb-3 px-3 pt-3" :class="item.orgAccountOnHold === '1' ? 'warning' : 'secondary'">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3"><v-icon class="white--text">icons8-city</v-icon></div>
                <div v-if="item.orgName.length > 40">
                    <v-tooltip top :max-width="200">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="tooltiptext tooltip font-weight-light"
                                ><strong>{{ orgtype }}:</strong>{{ item.orgName.substring(0, 40) }}...</span
                            >
                        </template>
                        <span>{{ item.orgName }}</span>
                    </v-tooltip>
                </div>
                <div v-else-if="item.orgName.length <= 40">
                    <span class="font-weight-light"
                        ><strong>{{ orgtype }}: </strong>{{ item.orgName }}</span
                    >
                </div>
                <div class="ml-2 font-weight-light" v-if="item.orgName === ''">New</div>

                <v-spacer></v-spacer>
				<div class="ml-2" v-if="item.orgAccountOnHold === '1'">ON HOLD</div>
                <div>
                    <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                        <v-icon>icons8-multiply</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <!-- Tabs -->
        <v-col v-if="$vuetify.breakpoint.mdAndUp" class="pa-0" fill-height cols="auto" style="width: 100% !important">
            <v-tabs background-color="white" v-model="tab" show-arrows>
                <v-tab href="#details">Details</v-tab>
                <v-tab :disabled="itemNew" href="#description">Description</v-tab>
                <v-tab :disabled="itemNew" href="#people">People</v-tab>
                <v-tab :disabled="itemNew" href="#addresses">Addresses+</v-tab>
                <!-- TODO - RG - Add more tabs depending on Account Type -->
                <v-tab :disabled="itemNew" href="#quotes">Quotes</v-tab>
<!--              TODO: SBT - uncomment below tabs when sales is complete-->
<!--                <v-tab :disabled="itemNew" href="#activities">Activities</v-tab>-->
<!--                <v-tab :disabled="itemNew" href="#actions">Actions</v-tab>-->
<!--              <v-tab :disabled="itemNew" href="#leads">Leads</v-tab>-->
<!--              <v-tab :disabled="itemNew" href="#opportunities">Opportunities</v-tab>-->
            </v-tabs>
            <!-- <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut animate__faster">
            <v-row dense no-gutters class="warning py-2">
                <v-col cols="12" class="text-left ml-6 white--text font-weight-bold">This {{ orgtype }} is on Hold</v-col>
            </v-row>
        </transition>		 -->
            <v-tabs-items v-model="tab" fill-height>
                <!-- Details Tab -->
                <v-tab-item value="details" class="grey lighten-3">
                    <organisation-details dense :datadown="item" :itemnew="itemNew" v-on:save="saveItem" v-on:cancel="cancelItem" :listshrunk="listshrunk" :orgtype="orgtype" />
                </v-tab-item>
                <!-- Notes Tab -->
                <v-tab-item value="description" class="grey lighten-3" fill-height>
                    <notes :datadown="item" :listshrunk="listshrunk" v-on:save="saveItem" v-on:cancel="cancelItem"  />
                </v-tab-item>
                <!-- Contacts Tab -->
                <v-tab-item value="people" class="grey lighten-3">
                    <organisation-contacts :org="item" :listshrunk="listshrunk" />
                </v-tab-item>
                <!-- More Addresses Tab -->
                <v-tab-item value="addresses" class="grey lighten-3">
                    <organisation-addresses :datadown="item" :listshrunk="listshrunk" />
                </v-tab-item>
                <!-- Quotes Tab -->
                <v-tab-item value="quotes" class="grey lighten-3">
                    <!-- Quotes Menu Buttons -->
                    <v-row class="apptext--text mt-0" dense>
                        <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4 px-2">
                            <div class="ml-2">
                                <v-btn depressed outlined class="grey lighten-5 secondary--text" @click="MIX_go('/quotes/new?orgId='+item.id)"><v-icon class="mr-2">icons8-bill</v-icon>New Quote</v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" class="px-4"> <quote-list :orgid="item.id" /> </v-col>
                    </v-row>
                </v-tab-item>
              <!-- Activity Tab -->
              <v-tab-item value="activities" class="grey lighten-3">
<!--                :orgIsGroup="orgIsGroup" :organisations="organisations"-->
                <leadCustomerActions :refreshItems="refreshitems" :datadown="item" />
              </v-tab-item>
              <!-- Actions Tab -->
              <v-tab-item value="actions" class="grey lighten-3">
<!--                :orgIsGroup="orgIsGroup" :organisations="organisations" :account="account"-->
                <leadCustomerTasks v-on:refreshItems="refreshitems" :refreshItems="refreshitems" :key="actionsKey" :datadown="item" />
              </v-tab-item>
              <!-- Leads Tab -->
              <v-tab-item value="leads" class="grey lighten-3">
                <!--                :orgIsGroup="orgIsGroup" :organisations="organisations"-->
                <leadCustomerLeads :datadown="item" />
              </v-tab-item>
              <!-- Actions Tab -->
              <v-tab-item value="opportunities" class="grey lighten-3">
                <!--                :orgIsGroup="orgIsGroup" :organisations="organisations" -->
                <leadCustomerOpportunities  :datadown="item" />
              </v-tab-item>
            </v-tabs-items>
        </v-col>

        <!-- Mobile Tabs -->
        <v-row no-gutters  v-if="$vuetify.breakpoint.smAndDown">
            <organisation-details dense :datadown="item" :itemnew="itemNew" v-on:save="saveItem" v-on:close="closeItem" v-on:cancel="cancelItem" :listshrunk="listshrunk" :orgtype="orgtype" />
        </v-row>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import organisationDetails from '@/views/organisations/organisationDetails.vue';
import organisationAddresses from '@/views/organisations/organisationAddresses.vue';
import organisationContacts from '@/views/organisations/organisationContacts.vue';
import quoteList from '@/views/quotes/quoteList.vue';
import notes from '@/views/base/notes.vue';
import leadCustomerActions from '@/views/organisations/organisationActivities.vue';
import leadCustomerTasks from '@/views/organisations/organisationActions.vue';
import leadCustomerLeads from '@/views/organisations/organisationLeads.vue';
import leadCustomerOpportunities from '@/views/organisations/organisationOpportunities.vue';
// import contactList from "@/views/contacts/contactList.vue"

import schema from '@/schema.js';
export default {
    // * COMPONENTS
    components: {
        'organisation-contacts': organisationContacts,
        'organisation-details': organisationDetails,
        notes: notes,
        'organisation-addresses': organisationAddresses,
        'quote-list': quoteList,
        'leadCustomerActions': leadCustomerActions,
        'leadCustomerTasks': leadCustomerTasks,
        'leadCustomerLeads': leadCustomerLeads,
      'leadCustomerOpportunities': leadCustomerOpportunities
        // "contact-list": contactList
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        orgtype: {
            type: String,
            default: '',
        },
    },
    // * DATA
    data: () => ({
        key: 'org',
        tab: 'details',
        schema: null,
        // Item
        item: {},
        itemNew: false,
        editMode: false,
        // Validation
        rules: {},
        refreshitems: 0,
        actionsKey: 0,
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
        }),
    },
    // * METHODS
    methods: {
        // * INITIALISE ITEM
        initialiseItem() {
            let t = this;
            if (JSON.stringify(t.datadown) !== '{}') {
                this.readItem(t.datadown.id);
            } else {
                t.itemNew = true;
                t.editMode = true;
                t.item = { ...t.schema };
                t.item.id = t.MIX_generateId();
            }
        },
        // * VALIDATION RULES
        validateItem() {
            let t = this;
            t.rules = {
                // GENERAL DETAILS
                orgName: [(v) => !!v || 'Organisation Name Required'],
                // orgRegisteredName: [ v => !!v || 'Registered Name Required'],
                // orgRegistrationNumber: [ v => !!v || 'Registration Number Required'],
                // orgTelephone: [ v => !!v || 'Telephone Required'],
                // orgWebsite: [ v => !!v || 'Website Required'],
                // orgEmail: [ v => !!v || 'Email Required'],
                // orgInvoiceEmail: [ v => !!v || 'Invoice Email Required'],
                // orgPurchasingEmail: [ v => !!v || 'Purchasing Email Required'],

                // ADDRESS DETAILS
                // orgAddressType: [ v => !!v || 'Address Type Required'],
                // orgAddressLine1: [ v => !!v || 'Address Line 1 Required'],
                // orgAddressLine2: [ v => !!v || 'Address Line 2 Required'],
                // orgAddressLine3: [ v => !!v || 'Address Line 3 Required'],
                // orgTown: [ v => !!v || 'Town Required'],
                // orgCounty: [ v => !!v || 'County Required'],
                // orgPostcode: [ v => !!v || 'Postcode Required'],

                // OTHER ADDRESS DETAILS
                // orgOtherAddressType: [ v => !!v || 'Address Type Required'],
                // orgOtherAddressLine1: [ v => !!v || 'Address Line 1 Required'],
                // orgOtherAddressLine2: [ v => !!v || 'Address Line 2 Required'],
                // orgOtherAddressLine3: [ v => !!v || 'Address Line 3 Required'],
                // orgOtherTown: [ v => !!v || 'Town Required'],
                // orgOtherCounty: [ v => !!v || 'County Required'],
                // orgOtherPostcode: [ v => !!v || 'Postcode Required'],

                // ORG INFO
                // orgEmployees: [ v => !!v || 'No of Employees Required'],
                // orgIndustry: [ v => !!v || 'Industry Required'],
                // orgSector: [ v => !!v || 'Sector Required'],
                // orgAccountNumber: [ v => !!v || 'Account Number Required'],
                //orgAccountTypes: [ v => !!v || 'Account Type Required'],
                // orgLocalAuthority: [ v => !!v || 'Local Authority Required'],
                // orgTurnover: [ v => !!v || 'Turnover Required'],

                // OWNER / SOURCE INFO
                // orgAccountOwner: [ v => !!v || 'Account Owner Required'],
                // orgSource: [ v => !!v || 'Source Required'],
                // orgSourceName: [ v => !!v || 'Source Name Required'],

                // SOCIAL INFO
                // orgTwitter: [ v => !!v || 'Twitter Required'],
                // orgFacebook: [ v => !!v || 'Facebook Required'],
                // orgLinkedIn: [ v => !!v || 'LinkedIn Required'],
                // orgInstagram: [ v => !!v || 'Instagram Required'],
            };

            // t.$nextTick(() => {
            //     if (t.$refs.form.validate()) {
            //         this.createItem()
            // 		this.editMode = false;
            //     } else {
            //         this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Fill out all the fields correctly in order to proceed.', show: true })
            //     }
            // })
        },
        // * CREATE ITEM
        async createItem(item) {
            let t = this;
            console.log('createItem = ', item.id);
            // remove special characters and spaces
            item.id = item.orgName.slice(0, 21).toUpperCase().replace(/[^a-zA-Z0-9]/g, '').trim();
            console.log('new createItem = ', item.id);
            if (item.id.length < 20) {
                item.id = item.id + new Array(19 - item.id.length).fill('0').join('') + '1';
            }
            console.log('new 2 createItem = ', item.id);

            let result = await this.MIX_createItem(item, this.key, this.itemNew);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                this.readItem(item.id);
                t.$emit('refreshitems');
            }
        },
        // * READ ITEM
        async readItem(itemId) {
            let t = this;
            let result = await t.MIX_readItem(itemId, t.key);
            if (result.code === 1) {
                this.item = result.data;
                t.itemNew = false;
                t.loading = false;
            }
        },
        // * DESTROY ITEM
        async destroyItem() {
            let result = await this.MIX_destroyItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        // * DELETE ITEM
        async deleteItem() {
            let result = await this.MIX_deleteItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        // * RESTORE ITEM
        async restoreItem() {
            let result = await this.MIX_restoreItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        // Save Item
        async saveItem(item) {
            if (this.itemNew) {
                this.createItem(item);
            } else {
                let result = await this.MIX_createItem(item, this.key, this.itemNew);
                if (result.code === 1) {
                    this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                    this.readItem(item.id);
                }
            }
            this.$emit('updateQuote', item);
        },
        // Close Item
        closeItem() {
            this.$emit('closeitem');
        },
        cancelItem() {
            this.readItem(this.item.id);
        },
    },
    // * WATCH
    watch: {
        // Datadown Changes
        datadown() {
            this.initialiseItem();
        },
      tab(){
          this.refreshitems++;
      }
    },
    // * CREATED
    async created() {
        let t = this;
        t.loading = true;
        t.schema = schema.organisation;
        t.initialiseItem();
        if(this.$route.query.tab === 'activities'){
          this.tab = 'activities'
        } else if(this.$route.query.tab === 'actions'){
          this.tab = 'actions'
        }
    },
    // * MOUNTED
    async mounted() {},
};
</script>

<style scoped>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 22px;
}
</style>
