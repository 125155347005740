import Vue from "vue";
import VueRouter from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// store
//import store from "@/store.js";

// import firebaseStore from "@/firebase/firebase_auth_store.js"

//import Home from '@/views/Home.vue'
import Quotes from '@/views/quotes/quotes.vue'

import Orders from '@/views/orders/orders.vue'

// import Builder from '@/views/temp/builder.vue'
// import Search from '@/views/temp/search.vue'
import Home from '@/views/home/Home.vue'
import About from '@/views/About.vue'
import Admin from '@/views/admin/Admin.vue'
import Users from '@/views/admin/Users.vue'
import Sales from '@/views/sales/Sales.vue'

// products
import Products from '@/views/products/products.vue';
import Product from '@/views/products/product.vue';
import ProductsBulk from '@/views/products/productsBulk.vue';

// fabrics
import Fabrics from '@/views/fabrics/fabrics.vue';
import Colours from '@/views/colours/colours.vue';
import Options from '@/views/options/options.vue';
import Attributes from '@/views/attributes/attributes.vue';

import Categories from '@/views/categories/categories.vue';

// organisations 
import Organisations from '@/views/organisations/organisations.vue'

// leads
import Leads from '@/views/leads/leads.vue'

// suppliers 
import Suppliers from '@/views/suppliers/suppliers.vue'

// contacts 
import Contacts from '@/views/contacts/contacts.vue'

// auth routes
import Register from "@/views/auth//register.vue";
import Login from "@/views/auth/login.vue";
import ResetPassword from "@/views/auth/reset-password.vue";
import Holding from "@/views/auth/holding.vue";
import NotAuthorised from "@/views/auth/notAuthorised.vue";

// Print Routes
import Print from "@/views/Print.vue";
import PrintOrder from "@/views/PrintOrder.vue";

// Datasheet Routes
import Datasheet from "@/views/Datasheet.vue";

// Delete Data
import DeleteData from "@/views/import/DeleteData.vue";

// Import Data
import ImportData from "@/views/import/ImportData.vue";

// import CSVImport from "@/views/csvImport.vue";

Vue.use(VueRouter);

const routes = [
	{ path: "/", name: "Home", component: Home, meta: { requiresAuth: true } },
	{ path: "/organisations/:id?", name: "Organisations", component: Organisations, meta: { hideNavbar: false, requiresAuth: true , userGroups: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] } },
    { path: "/leads", name: "Leads", component: Leads, meta: { hideNavbar: false, requiresAuth: true , userGroups: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] } },
	{ path: "/suppliers/:id?", name: "Suppliers", component: Suppliers, meta: { hideNavbar: false, requiresAuth: true, userGroups: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] } },
	{ path: "/customers/:id?", name: "Customers", component: Organisations, meta: { hideNavbar: false, requiresAuth: true, userGroups: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers']  }, },
	{ path: "/prospects/:id?", name: "Prospects", component: Organisations, meta: { hideNavbar: false, requiresAuth: true, userGroups: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] } },
	{ path: "/contacts/:id?", name: "Contacts", component: Contacts, meta: { hideNavbar: false, requiresAuth: true, userGroups: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] } },
    { path: '/quotes/:id?', name: 'Quotes', component: Quotes, meta: { hideNavbar: false, requiresAuth: true, userGroups: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] } },
    { path: '/orders/:id?', name: 'Orders', component: Orders, meta: { hideNavbar: false, requiresAuth: true, userGroups: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] } },
    { path: '/about', name: 'About', component: About, meta: { hideNavbar: false, requiresAuth: true } },
	// { path: '/search', name: 'Search', component: Search, meta: { requiresAuth: true } },  
    // { path: '/builder', name: 'Builder', component: Builder },
    { path: '/sales/:id?', name: 'Sales', component: Sales, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
	{ path: '/products/:id?', name: 'Products', component: Products },
    { path: '/productsbulk', name: 'ProductsBulk', component: ProductsBulk },
	{ path: '/product', name: 'Product', component: Product },
	{ path: '/fabrics', name: 'Fabrics', component: Fabrics },
	{ path: '/colours', name: 'Colours', component: Colours },
	{ path: '/options', name: 'Options', component: Options },
	{ path: '/attributes', name: 'Attributes', component: Attributes },	
	{ path: '/categories', name: 'Categories', component: Categories },	
    { path: "/admin", name: "Admin", component: Admin, meta: { hideNavbar: false, requiresAuth: true } },
    { path: "/users", name: "Users", component: Users, meta: { hideNavbar: false, requiresAuth: true, userGroups: ['Administration', 'Developers'], userLevels: ['Developer-Admin','Staff-Admin'] } },  

	// * PRINT ROUTES
	{ path: '/print/:id?', name: 'Print', component: Print, meta: { hideNavbar: true, requiresAuth: false }, },
    { path: '/print-order/:id?', name: 'PrintOrder', component: PrintOrder, meta: { hideNavbar: true, requiresAuth: false }, },

    // * DATASHEET ROUTES
	{ path: '/datasheet/:id?', name: 'Datasheet', component: Datasheet, meta: { hideNavbar: true, requiresAuth: false }, },
	
    // * IMPORT / DELETE ROUTES
    { path: '/deletedata', name: 'DeleteData', component: DeleteData, meta: { hideNavbar: false, requiresAuth: true } },
    { path: '/importdata', name: 'ImportData', component: ImportData, meta: { hideNavbar: false, requiresAuth: true } },

    // *  AUTH ROUTES
    // ? register for normal user
    { path: "/register/:id?", name: "Register", component: Register, meta: { hideNavbar: true, requiresAuth: false } },
    { path: "/login", name: "Login", component: Login, meta: { hideNavbar: true, requiresAuth: false } },
    { path: "/reset", name: "ResetPassword", component: ResetPassword, meta: { hideNavbar: true, requiresAuth: false } },
    { path: "/holding", name: "Holding", component: Holding, meta: { hideNavbar: false, requiresAuth: false } },
    { path: "/notauthorised", name: "NotAuthorised", component: NotAuthorised, meta: { hideNavbar: false, requiresAuth: false } },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        navigator.serviceWorker.register('/service-worker.js').then(reg => {
            reg.update();
        });
        const auth = getAuth();
        await onAuthStateChanged(auth, (user) => {
            if (user !== null) {
                user.getIdToken(true).then((idToken) => {
                    var token = JSON.parse(new Buffer(idToken.split('.')[1], 'base64').toString('utf8'));
                    if (token.user_status === 'Approved') {
                        let authFail = 0;
                        // User Group Check
                        // console.log('token.user_group = ' + token.user_group);
                        if (to.meta.userGroups !== '' && to.meta.userGroups !== undefined && to.meta.userGroups !== null) {
                            if (!to.meta.userGroups.includes(token.user_group)) {
                                authFail++
                            }
                        }
                        // console.log('token.user_level', token.user_level);
                        // console.log('to.meta.userLevels', to.meta.userLevels);                       
                        // User Level Check
                        if (to.meta.userLevels !== '' && to.meta.userLevels !== undefined && to.meta.userLevels !== null) {
                            if (!to.meta.userLevels.includes(token.user_level)) {
                                authFail++
                            }
                        }
                        // console.log('authFail=', authFail)
                        // If UserGroup or UserLevel check fails, redirect to NotAuthorised
                        if (authFail === 0) {
                            next();
                        } else {
                            next('/notauthorised');
                        }
                    } else {
                        next('/holding');
                    }
                }).catch(error => {
                    console.error(error);
                    next('/login');
                })
            } else {
                next('/login');
            }
        })
    } else {
        next();
    }
})

export default router