<template>
    <div>
        <!-- Menu Buttons -->
         <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="mx-3 d-flex align-center" style="width: 100% !important;">
                    <v-spacer />
                    <v-btn text class="grey lighten-5 secondary--text mr-2" @click="addNote">
                        <v-icon class="mr-2">icons8-note</v-icon>
                        Add Note
                    </v-btn>
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true">
                        <v-icon class="mr-2">icons8-edit</v-icon>
                        Edit
                    </v-btn>
                    
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="editmode = false">
                        <v-icon class="mr-2">icons8-save</v-icon>
                        Save
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <!-- <v-row class="apptext--text px-3 mt-3" dense v-for="(item, index) in items" :key="index">
            <v-col cols="12" sm="12" class="my-1">
                <v-row class="grey lighten-5 pb-3 rounded" no-gutters fill-height>
                    <v-col cols="12">
                        <div class="d-flex align-center pb-2">
                            <v-btn v-if="editmode && GET_FIREBASE_userAccessToken.user_id == item.createdUserId" @click="editNotes(item.id)" class="primary--text" icon><v-icon>icons8-edit</v-icon></v-btn>
                            <div class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">
                                {{ item.noteTitle }}
                                <div class="font-weight-bold body-2 grey--text">
                                    {{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YYYY HH:mm')}}
                                    - {{ item.createdUserName }}
                                </div>
                            </div>
                            <v-spacer />
                            <v-avatar class="primary white--text font-weight-bold mr-1" size="30">
                                {{ getUserName(item.createdUserId).match(/\b(\w)/g).join('') }}
                            </v-avatar>
                        </div>
                        <v-divider class="grey lighten-2 mb-1 mx-2"></v-divider>
                    </v-col>
                    <v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2">
                        <div v-html="item.noteContent" class="ql-editor" style="width:100% !important; height: 100% !important; overflow-y: hidden !important;"></div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row> -->


        <v-row dense style="max-width: 800px !important" >
            <v-col cols="12" class="d-flex align-center pr-3 justify-start">
                <v-timeline dense clipped>
                    <v-timeline-item v-for="(item, index) in items" :key="index" dense>
                        <template v-slot:icon>
                            <v-avatar class="primary white--text font-weight-bold">
                                {{ getUserName(item.createdUserId) }}
                            </v-avatar>
                        </template>
                        <div>
                            <v-card class="elevation-0">

                                <v-row class="grey lighten-5 pb-3 rounded pl-1" no-gutters fill-height>
                                    <v-col cols="8" class="pl-2">
                                        <div class="primary--text font-weight-light text-h6">{{ item.noteTitle }}</div>
                                        <div class="font-weight-bold body-2 grey--text">
                                            {{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YYYY HH:mm')}}
                                            - {{ item.createdUserName }}
                                        </div>
                                    </v-col>
                                    <v-col class="d-flex align-center" cols="4">
                                        <v-spacer />
                                        <v-btn v-if="editmode && GET_FIREBASE_userAccessToken.user_id == item.createdUserId" @click="editNotes(item)" class="primary--text" small icon>
                                            <v-icon>icons8-edit</v-icon>
                                        </v-btn>
                                        <v-btn v-if="editmode && ['Developer-Admin','Staff-Directors'].includes(GET_FIREBASE_userAccessToken.user_level)" @click="deleteNote(item)" class="error--text" small icon>
                                            <v-icon>icons8-trash-can</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-divider class="grey lighten-2 mb-1 mx-2"></v-divider>
                                    </v-col>
                                    <v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2">
                                        <div v-html="item.noteContent"></div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </div>
                    </v-timeline-item>
                </v-timeline>
            </v-col>
        </v-row>
        <!-- <v-row class="apptext--text px-3 mt-3" dense v-if="item.firstLevelApprovalNotes && item.firstLevelApprovalNotes !== ''">
            <v-col cols="12" sm="12" class="my-1">
                <v-row class="grey lighten-5 pb-3 rounded" no-gutters fill-height>
                    <v-col cols="12">
                        <div class="d-flex align-center pb-2">
                            <v-btn v-if="editmode && GET_FIREBASE_userAccessToken.user_id == item.firstLevelApprovalUserId" @click="editNotes('firstLevelApprovalNotes')" class="primary--text" icon><v-icon>icons8-edit</v-icon></v-btn>
                            <div class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">
                                First Level Approval Notes
                                <div class="font-weight-bold body-2 grey--text" v-if="item.firstLevelApprovalDateTime && item.firstLevelApprovalDateTime !== ''">{{ MIX_formatDateTime(item.firstLevelApprovalDateTime, 'X', 'DD-MMM-YYYY HH:mm')}}</div>
                            </div>
                            <v-spacer />
                            <v-avatar class="primary white--text font-weight-bold mr-1" size="30">
                                {{ getUserName(item.firstLevelApprovalUserId).match(/\b(\w)/g).join('') }}
                            </v-avatar>
                        </div>
                        <v-divider class="grey lighten-2 mb-1 mx-2"></v-divider>
                    </v-col>
                    <v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2">
                        <div v-html="item.firstLevelApprovalNotes" class="ql-editor" style="width:100% !important; height: 100% !important; overflow-y: hidden !important;"></div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense v-if="item.firstLevelRejectionNotes && item.firstLevelRejectionNotes !== ''">
            <v-col cols="12" sm="12" class="my-1">
                <v-row class="grey lighten-5 pb-3 rounded" no-gutters fill-height>
                    <v-col cols="12">
                        <div class="d-flex align-center pb-2">
                            <v-btn v-if="editmode && GET_FIREBASE_userAccessToken.user_id == item.firstLevelRejectionUserId" @click="editNotes('firstLevelRejectionNotes')" class="primary--text" icon><v-icon>icons8-edit</v-icon></v-btn>
                            <div class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">
                                First Level Rejection Notes
                                <div class="font-weight-bold body-2 grey--text" v-if="item.firstLevelRejectionDateTime && item.firstLevelRejectionDateTime !== ''">{{ MIX_formatDateTime(item.firstLevelRejectionDateTime, 'X', 'DD-MMM-YYYY HH:mm')}}</div>
                            </div>
                            <v-spacer />
                            <v-avatar class="primary white--text font-weight-bold mr-1" size="30">
                                {{ getUserName(item.firstLevelRejectionUserId).match(/\b(\w)/g).join('') }}
                            </v-avatar>
                        </div>
                        <v-divider class="grey lighten-2 mb-1 mx-2"></v-divider>
                    </v-col>
                    <v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2">
                        <div v-html="item.firstLevelRejectionNotes" class="ql-editor" style="width:100% !important; height: 100% !important; overflow-y: hidden !important;"></div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense v-if="item.secondLevelApprovalNotes && item.secondLevelApprovalNotes !== ''">
            <v-col cols="12" sm="12" class="my-1">
                <v-row class="grey lighten-5 pb-3 rounded" no-gutters fill-height>
                    <v-col cols="12">
                        <div class="d-flex align-center justify-center pa-2">
                            <v-btn v-if="editmode && GET_FIREBASE_userAccessToken.user_id == item.secondLevelApprovalUserId" @click="editNotes('secondLevelApprovalNotes')" class="primary--text" icon><v-icon>icons8-edit</v-icon></v-btn>
                            <div class="text-left primary--text text-h6 font-weight-light pl-2" colspan="12">
                                Second Level Approval Notes
                                <div class="font-weight-bold body-2 grey--text" v-if="item.secondLevelApprovalDateTime && item.secondLevelApprovalDateTime !== ''">{{ MIX_formatDateTime(item.secondLevelApprovalDateTime, 'X', 'DD-MMM-YYYY HH:mm')}}</div>
                            </div>
                            <v-spacer />
                            <v-avatar class="primary white--text font-weight-bold mr-1" size="50">
                                {{ getUserName(item.secondLevelApprovalUserId).match(/\b(\w)/g).join('') }}
                            </v-avatar>
                        </div>
                        <v-divider class="grey lighten-2 mb-1 mx-2"></v-divider>
                    </v-col>
                    <v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1">
                        <div v-html="item.secondLevelApprovalNotes" class="ql-editor" style="width:100% !important; height: 50px !important; overflow-y: hidden !important;"></div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense v-if="item.secondLevelRejectionNotes && item.secondLevelRejectionNotes !== ''">
            <v-col cols="12" sm="12" class="my-1">
                <v-row class="grey lighten-5 pb-3 rounded" no-gutters fill-height>
                    <v-col cols="12">
                        <div class="d-flex align-center pb-2">
                            <v-btn v-if="editmode && GET_FIREBASE_userAccessToken.user_id == item.secondLevelRejectionUserId" @click="editNotes('secondLevelRejectionNotes')" class="primary--text" icon><v-icon>icons8-edit</v-icon></v-btn>
                            <div class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">
                                Second Level Rejection Notes
                                <div class="font-weight-bold body-2 grey--text" v-if="item.secondLevelRejectionDateTime && item.secondLevelRejectionDateTime !== ''">{{ MIX_formatDateTime(item.secondLevelRejectionDateTime, 'X', 'DD-MMM-YYYY HH:mm')}}</div>
                            </div>
                            <v-spacer />
                            <v-avatar class="primary white--text font-weight-bold mr-1" size="30">
                                {{ getUserName(item.secondLevelRejectionUserId).match(/\b(\w)/g).join('') }}
                            </v-avatar>
                        </div>
                        <v-divider class="grey lighten-2 mb-1 mx-2"></v-divider>
                    </v-col>
                    <v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2">
                        <div v-html="item.secondLevelRejectionNotes" style="width:100% !important; height: 100% !important; overflow-y: hidden !important;"></div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense>
            <v-col cols="12" sm="12" class="my-1">
                <v-row class="grey lighten-5 pb-3 rounded" no-gutters fill-height>
                    <v-col cols="12">
                        <div class="d-flex align-center pa-2">
                            <v-btn v-if="editmode" @click="editNotes('notes')" class="primary--text" icon><v-icon>icons8-edit</v-icon></v-btn>
                            <div class="text-left primary--text text-h6 font-weight-light pl-2" colspan="12">Quote Notes</div>
                        </div>
                        <v-divider class="grey lighten-2 mb-1 mx-2"></v-divider>
                    </v-col>
                    <v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2">
                        <div v-html="item.notes" class="ql-editor" style="width:100% !important; height: 100% !important; overflow-y: hidden !important;"></div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row> -->


        <v-dialog v-model="editDialog" persistent :key="editDialogKey" width="60%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <div class="grey lighten-3" style=" overflow: hidden !important">
                    <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
                        <v-col cols="12" class="d-flex align-center justify-start primary py-4">
                            <div v-if="!newItem" class="white--text d-flex align-center text-h5 font-weight-bold ml-4 mt-2">
                                <v-icon class="white--text mr-2">icons8-edit</v-icon>
                                <span>Edit Notes</span>
                            </div>
                            <div v-else class="white--text d-flex align-center text-h5 font-weight-bold ml-4 mt-2">
                                <v-icon class="white--text mr-2">icons8-note</v-icon>
                                <span>Add Note</span>
                            </div>
                            <v-spacer />
                        </v-col>
                    </v-row>


                    <v-row class="px-4 py-2 d-flex justify-center align-center">
                        <v-col cols="12" class="d-flex align-start justify-start">
                            <v-text-field dense label="Note Title" v-model="item.noteTitle" outlined  hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('noteTitle')"> </v-text-field>
                        </v-col>
                        <v-col cols="12" class="d-flex pt-0 align-start justify-start">
                            <v-textarea rows="4" label="Note Content" v-model="item.noteContent" outlined  hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('noteContent')"> </v-textarea>
                        </v-col>
                        <!-- <pre>{{ item }}</pre> -->
                    </v-row>
                
                    
                    <v-row class="px-3 pb-3 mt-4 primary">
                        <v-col cols="12" class="d-flex justify-right align-center">
                            <v-spacer />
                            <div>
                                <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="closeNotesDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                            </div>
                            <div>
                                <v-btn class="accent ml-2 primary--text font-weight-bold" depressed @click="saveNotes"> <v-icon class="mr-2">icons8-save</v-icon><span>Save </span></v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
// import { VueEditor } from 'vue2-editor';
import { mapGetters } from 'vuex';
import schema from '@/schema.js';
export default {
    // * COMPONENTS
    components: {
        // VueEditor
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    // * DATA
    data: () => ({
        key: 'note', 
        index: 'idx:notes',
        editmode: false,
        editMode: false,
        item: {},
        schema: null,
        quote: {},
        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'align': ''},{ 'align': 'center'},{ 'align': 'justify'}, { 'align': 'right' }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],  
            [{ 'color': [] }, { 'background': [] }],
            ['link'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
        ],
        users: [],

        editDialog: false,
        editDialogKey: 0,
        editField: '',
        editedNotes: '',


        itemsFrom: 0,
        itemsTo: 999,

        items: {},
        itemsTotal: '',

        newItem: false
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            // GET_taskBar: 'GET_taskBar',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        // Computed Search Query
        computedSearchQuery() {
            let t = this;
            let searchQuery = t.searchQuery;
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (t.toggleDeletedItems) {
                    searchQuery = `* @quoteId:${t.quote.id}`;
                } else {
                    searchQuery = `@deleted:0 @quoteId:${t.quote.id}`;
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (t.toggleDeletedItems) {
                        searchQuery = `${searchQuery}* @quoteId:${t.quote.id}`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0 @quoteId:${t.quote.id}`;
                    }
                } else {
                    if (t.toggleDeletedItems) {
                        searchQuery = `${searchQuery}* @quoteId:${t.quote.id}`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0 @quoteId:${t.quote.id}`;
                    }
                }
            }
            // console.log("searchQuery = " + searchQuery);

            return searchQuery;
        },
    },
    // * METHODS
    methods: {
        cancelItem() {
            this.editmode = false;
            this.$emit('cancel');
        },
        // Save Item
        saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
        addNote() {
            var t = this;
            t.editDialog = true 
            t.editDialogKey++
            t.newItem = true
            t.item = { ...t.schema };
            t.item.quoteId = t.quote.id
            t.item.id = t.MIX_generateId()
        },
        editNotes(item) {
            var t = this;

            t.editDialog = true 
            t.editDialogKey++
            t.newItem = false
            t.item = item


            // t.editedNotes = notes 

            // switch (notes) {
            //     case 'notes':
            //         t.editField = t.item.notes 
            //         break;
            //     case 'firstLevelApprovalNotes':
            //         t.editField = t.item.firstLevelApprovalNotes
            //         break;
            //     case 'firstLevelRejectionNotes':
            //         t.editField = t.item.firstLevelRejectionNotes
            //         break;
            //     case 'secondLevelApprovalNotes':
            //         t.editField = t.item.secondLevelApprovalNotes
            //         break;
            //     case 'secondLevelRejectionNotes':
            //         t.editField = t.item.secondLevelRejectionNotes
            //         break;
            //     default: 
            //         break;
            // }
        },
        async saveNotes() {
            var t = this;
            t.editDialog = false, 
            t.editDialogKey++
            
            if (t.item.id === '') {
                t.item.id = t.MIX_generateId()
            }

            let result = await this.MIX_createItem(this.item, this.key, this.newItem);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                // this.readItem(item.id);
            }

            this.getNotes()

        },
        async deleteNote(note) {
            var itemId = note.id

			let result = await this.MIX_deleteItem(itemId, this.key);
			if (result.code === 1) { this.getNotes() }
        },
        closeNotesDialog() {
            var t = this;

            t.editDialog = false
            t.editDialogKey++ 
            t.editField = ''
            t.item = t.schema
        },
        getUserName(userId) {
            var t = this;

            var users = t.users 

            users = users.filter((item) => {
                return item.id == userId
            })
            if (users.length > 0) {
                return users[0].userName.match(/\b(\w)/g).join('')
            } else {
                return ''
            }

        },
        async getNotes() {
            let itemsResult = await this.MIX_redisOrderedSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.itemsTo, 'createdDateTime');
            //console.log("itemsResult = " + JSON.stringify(itemsResult, null, 2));
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.documents.map((x) => x.value);
                this.items = this.items.reverse()
                // for (var i = 0; i < this.items.length; i++) {
                //     if (this.items[i].lineFeatures !== undefined && this.items[i].lineFeatures !== null && this.items[i].lineFeatures !== '') {
                //         this.items[i].lineFeatures = this.items[i].lineFeatures.split(',');
                //         this.items[i].lineFeatures = this.items[i].lineFeatures.filter((x) => x !== '');
                //     }
                // }

                // this.checkApproval()
            }
        }
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.quote = { ...this.datadown };
                this.getNotes()
            },
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.quote = { ...t.datadown };
        t.schema = schema.note;
        t.item = { ...t.schema };
        t.item.quoteId = t.quote.id

        t.getNotes()

        let usersResult = await this.MIX_redisSearch('idx:users', `*`, 0, 9999);
        t.users = usersResult.data.documents.map((x) => x.value);
    },
};
</script>

<style>
    .quillWrapper .ql-toolbar { position: -webkit-sticky; position: sticky; top: 0; z-index: 2; background-color: #fff; }
</style>