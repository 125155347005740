<template>
	<div>
		<v-row class="pa-3" no-gutters>
			<v-col cols="12" sm="12">
				<div class="text-left primary--text text-h6 font-weight-light">Options ({{ itemsTotal }})</div>
				<v-divider class="grey lighten-1 mb-4"></v-divider>
				<div class="font-weight-light body-2">Use the table and form below to manage options for this Supplier. These options will be available to build products from this supplier.</div>
				<div class="my-3 d-flex align-center">
					<div class="flex-grow-1"><v-text-field label="Search Options" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field></div>

					<div class="ml-2">
						<v-btn depressed outlined class="primary--text" @click="getItems()"><v-icon class="" color="primary">icons8-available-updates</v-icon></v-btn>
					</div>
					<div class="ml-2">
						<v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed outlined class="primary--text" v-bind="attrs" v-on="on"><v-icon class="" color="primary">icons8-table-properties</v-icon></v-btn>
							</template>
							<v-card class="pa-5" width="250">
								<div class="body-2 font-weight-bold">Show / hide Columns</div>
								<v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
							</v-card>
						</v-menu>
					</div>

					<div class="mb-5 ml-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>
				</div>
				<!-- TABLE BASED LIST -->
				<v-data-table sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions">
					<template v-slot:item.deleted="{ item }">
						<span v-if="item.deleted === '1'">Yes</span>
						<span v-if="item.deleted === '0'">No</span>
					</template>
					<template v-slot:item.optionVariant="{ item }">
						<span v-if="item.optionVariant === '1'">Yes</span>
						<span v-if="item.optionVariant === '0'">No</span>
					</template>
					<template v-slot:item.createdDateTime="{ item }">
						<span>{{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YY HH:mm:ss') }}</span>
					</template>
					<template v-slot:item.modifiedDateTime="{ item }">
						<span>{{ MIX_formatDateTime(item.modifiedDateTime, 'x', 'DD-MMM-YY HH:mm:ss') }}</span>
					</template>
					<template v-slot:item.deletedDateTime="{ item }">
						<span>{{ MIX_formatDateTime(item.deletedDateTime, 'x', 'DD-MMM-YY HH:mm:ss') }}</span>
					</template>
					<template v-slot:item.action="{ item }">
						<v-icon class="error--text pa-1" style="border: 1px red solid; border-radius: 3px" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
						<v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0'">icons8-trash-can</v-icon>
						<v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1'">icons8-trash-restore</v-icon>
						<v-icon class="primary--text ml-5" @click="editItem(item.id)">icons8-edit</v-icon>
					</template>
				</v-data-table>
			</v-col>
			<v-col cols="12" sm="12" class="mt-5">
				<div class="text-left primary--text text-h6 font-weight-light">Add Option</div>
				<v-divider class="grey lighten-1 mb-4"></v-divider>
			</v-col>
			<v-col cols="12" sm="12" class="white pa-3">
				<v-simple-table dense>
					<template v-slot:default>
						<tbody>
							<tr>
								<td width="50%" class="font-weight-bold grey--text text--darken-2">Option<br /><span class="caption grey--text text--darken-1">Please select the Option to add to this supplier</span></td>
								<td width="50%" class="py-2">
									<div><v-autocomplete v-model="item" :items="options" return-object item-text="optionName" outlined dense hide-details="auto" background-color="white" color="primary" clearable :value="item.optionName"></v-autocomplete></div>
								</td>
							</tr>
							<tr>
								<td width="50%" class="font-weight-bold grey--text text--darken-2">Allow Variants<br /><span class="caption grey--text text--darken-1">This allows the option to be used to create variants of this product.</span></td>
								<td width="50%" class="py-2">
									<div><v-switch v-model="item.optionVariant" inset outlined dense hide-details="auto" background-color="white" color="primary" true-value="1" false-value="0" :value="item.enabled"></v-switch></div>
								</td>
							</tr>
							<tr>
								<td width="50%" class="font-weight-bold grey--text text--darken-2">Enabled<br /><span class="caption grey--text text--darken-1">Disabled Options will not appear for selection.</span></td>
								<td width="50%" class="py-2">
									<div><v-switch v-model="item.enabled" inset outlined dense hide-details="auto" background-color="white" color="primary" true-value="1" false-value="0" :value="item.enabled"></v-switch></div>
								</td>
							</tr>
							<tr>
								<td colspan="2" class="py-2 text-right">
									<v-btn class="primary--text font-weight-bold" depressed @click="createItem"><span v-if="!addImageToggle">Add Option</span></v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<!-- <pre>{{ item }}</pre> -->
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import schema from "@/schema.js";
	import { mapGetters } from "vuex";
	export default {
		// * PROPS
		props: {
			editmode: {
				type: Boolean,
				default: false,
			},
			org: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			item: {},
			newItem: true,
			key: "option",
			addImageToggle: false,
			costsToggle: false,
			index: "idx:options",
			toggleDeletedItems: false,
			schema: {},
			showImages: false,
			items: [],
			itemsTotal: null,
			searchQuery: "",
			itemsFrom: 0,
			itemsTo: 9,
			activeItem: {},
			options: [],
			optionSelected: {},

			// Headers to display in the Data Table
			headers: [
				{ text: "Id", value: "id", hidden: true },
				{ text: "Name", value: "optionName", hidden: false },
				{ text: "Type", value: "optionType", hidden: false },
				{ text: "Allow Variants", value: "optionVariant", hidden: false },
				{ text: "Key", value: "key", hidden: false },
				{ text: "Supplier ID", value: "orgId", hidden: false }, // should be orgName
				{ text: "Supplier Name", value: "orgName", hidden: false }, // should be orgName
				{ text: "Supplier Option", value: "optionSupplier", hidden: false },
				{ text: "Date Time (C)", value: "createdDateTime", hidden: true },
				{ text: "User ID (C)", value: "createdUserId", hidden: true },
				{ text: "User Name (C)", value: "createdUserName", hidden: true },
				{ text: "User Email (C)", value: "createdUserEmail", hidden: true },
				{ text: "Date Time (M)", value: "modifiedDateTime", hidden: true },
				{ text: "User ID (M)", value: "modifiedUserId", hidden: true },
				{ text: "User Name (M)", value: "modifiedUserName", hidden: true },
				{ text: "User Email (M)", value: "modifiedUserEmail", hidden: true },
				{ text: "Deleted", value: "deleted", hidden: true },
				{ text: "Date Time (D)", value: "deletedDateTime", hidden: true },
				{ text: "User ID (D)", value: "deletedUserId", hidden: true },
				{ text: "User Name (D)", value: "deletedUserName", hidden: true },
				{ text: "User Email (D)", value: "deletedUserEmail", hidden: true }, 
				{ text: "Action", value: "action", align: "end", hidden: false, width: "155px" },
			],
			// Fields to Retrieve from Database
			sortBy: "@optionName",
			datatableOptions: {
				page: 1,
				itemsPerPage: 10,
				sortBy: ["optionName"],
				sortDesc: [false],
				groupBy: [],
				groupDesc: [],
				mustSort: false,
				multiSort: false,
			},
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
			computedFields() {
				let computedFields = this.headers.filter(x => x.hidden === false).map(y => '@'+ y.value);
				if (!computedFields.includes("@id")) { computedFields.push ("@id") }
				computedFields = computedFields.join(',');
				return computedFields
			},			
			computedSearchQuery() {
				let searchQuery = this.searchQuery;
				if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
					if (this.toggleDeletedItems) {
						searchQuery = `@orgId:${this.org.id}`;
					} else {
						searchQuery = `@deleted:0 @orgId:${this.org.id}`;
					}
					return searchQuery;
				} else {
					if (searchQuery.startsWith("@")) {
						if (this.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @orgId:${this.org.id}`;
							return searchQuery;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @orgId:${this.org.id}`;
							return searchQuery;
						}
					} else {
						if (this.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @orgId:${this.org.id}`;
							return searchQuery;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @orgId:${this.org.id}`;
							return searchQuery;
						}
					}
				}
			},
			computedHeaders() {
				return this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
			},
			computedItems() {
				var items = this.items; //.filter(x => x.);
				return items; //.slice(this.organisationsFrom, this.organisationsTo);
			},
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				t.item = { ...t.schema };
			},
			// * CREATE ITEM
			async createItem() {
				let t = this;
				t.item.orgId = this.org.id;
				t.item.orgName = this.org.orgName;
				t.item.id = t.item.orgId + "_" + this.item.optionName.replace(/\s/g, "");
				t.item.optionSupplier = 1;
				//console.log('createItem = ' + JSON.stringify(t.item, null, 2))
				let result = await this.MIX_createItem(this.item, this.key, this.newItem);
				if (result.code === 1) {
					let attributesResult = await this.getAttributesByOption(this.item.optionName.replace(/\s/g, ""));
					//console.log(JSON.stringify(attributesResult, null, 2))
					let documents = [];
					let document = {};
					for (let i = 0; i < attributesResult.data.documents.length; i++) {
						document = attributesResult.data.documents[i].value; //.map(x => x.value)
						document.id = this.org.id + "_" + document.id;
						document.attParentId = this.org.id + "_" + document.attParentId;
						document.attSupplier = 1;
						document.optionId = this.org.id + "_" + document.optionId;
						document.orgId = this.org.id;
						document.orgName = this.org.orgName;
						documents.push(document);
					}
					let bulkResult = await t.MIX_redisBulkHashUpdate("attribute", documents);
					if (bulkResult.code === -1) {
						console.log("bulkResult = " + JSON.stringify(bulkResult, null, 2));
					}
					//console.log('documents = ' + JSON.stringify(documents, null, 2))
					this.getItems();
					this.initialiseItem();
				}
			},
			// * EDIT ITEM
			async editItem(itemId) {
				let result = await this.MIX_readItem(itemId, this.key);
				if (result.code === 1) {
					this.item = result.data;
				}
			},
			// * DESTROY ITEM
			async destroyItem(itemId) {
				let result = await this.MIX_destroyItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// * DELETE ITEM
			async deleteItem(itemId) {
				let result = await this.MIX_deleteItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// * RESTORE ITEM
			async restoreItem(itemId) {
				let result = await this.MIX_restoreItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			async getItems() {
				console.log("getItems -> supplierOptions");
				console.log("computedFields = " + this.computedFields);
				// console.log("this.index = " + this.index);
				console.log("this.computedSearchQuery = " + this.computedSearchQuery);
				let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
				// console.log("itemsResult = " + JSON.stringify(itemsResult, null, 2));
				if (itemsResult.code === 1) {
					this.itemsTotal = itemsResult.data.total;
					this.items = itemsResult.data.results;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
				}
			},
			// * GET ALL OPTIONS
			async getOptions() {
				let result = await this.MIX_redisSearch("idx:options", "@deleted:0 @enabled:1", 0, 9999);
				//console.log("result = " + JSON.stringify(result, null, 2));
				if (result.code === 1) {
					this.options = result.data.documents.map((x) => {
						return {
							...x.value,
						};
					});
				}
			},
			// * GET ATTRIBUTES BY OPTIONS
			async getAttributesByOption(optionId) {
				console.log("option Id = " + optionId);
				let result = await this.MIX_redisSearch("idx:attributes", `@deleted:0 @enabled:1 @optionId:${optionId}`, 0, 9999);
				return result;
			},
		},
		// * WATCH
		watch: {
			// If the search query changes then get updated dataset based on search query
			computedSearchQuery() {
				this.getItems();
			},
			toggleDeletedItems() {
				this.getItems();
			},
			computedFields: {
				handler() {
					this.getItems();
				},deep: true
			},			
			datatableOptions: {
				handler() {
					this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
					this.getItems();
				},
				deep: true,
			},
		},
		// * CREATED
		created() {
			this.schema = schema.option;
			this.getItems();
			this.initialiseItem();
			this.getOptions();
		},
	};
</script>
