<template>
	<div>
		<div class="mt-2 d-flex align-start">
			<div class="flex-grow-1">
				<v-text-field label="Search People" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field>
			</div>
			<!-- TODO - RG - Test Filter by Tags -->
			<!-- Filter Tags -->
			<!-- <div v-if="!listshrunk && listexpanded && $vuetify.breakpoint.mdAndUp" class="ml-2">
				<div><v-autocomplete multiple outlined auto-select-first v-model="filter.tags" label="Filter by Tags" :search-input.sync="tagsAutocomplete" @change="tagsAutocomplete = ''" :items="tags" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('tags', [])"></v-autocomplete></div>
			</div> -->
			<!-- Filter Organisations -->
			<div v-if="!listshrunk && orgid === '' && $vuetify.breakpoint.mdAndUp" class="ml-2">
				<div><v-autocomplete outlined auto-select-first v-model="filter.orgId" label="Filter by Organisation" :items="organisations" item-text="orgName" item-value="id" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('orgId', '')"></v-autocomplete></div>
			</div>
			<!-- Filter Job Level -->
			<div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
				<div><v-autocomplete multiple outlined auto-select-first v-model="filter.jobLevel" label="Filter by Job Levels" :search-input.sync="jobLevelAutocomplete" @change="jobLevelAutocomplete = ''" :items="jobLevels" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('jobLevel', [])"></v-autocomplete></div>
			</div>
			<!-- Filter Job Department -->
			<div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
				<div><v-autocomplete multiple outlined auto-select-first v-model="filter.jobDepartment" label="Filter by Department" :search-input.sync="jobDepartmentAutocomplete" @change="jobDepartmentAutocomplete = ''" :items="jobDepartments" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('jobDepartment', [])"></v-autocomplete></div>
			</div>
			<!-- Refresh Button -->
			<div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
				<v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="refreshItems">
					<v-icon>icons8-available-updates</v-icon>
				</v-btn>
			</div>
			<!-- Column Selector -->
			<div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp && ['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)" class="ml-2">
				<v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>icons8-table-properties</v-icon></v-btn>
					</template>
					<div class="white" style="width: 250px; max-height: 300px">
						<div class="pa-5 white" style="width: 100% !important">
							<div class="body-2 font-weight-bold">Show / hide Columns</div>
							<v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
						</div>
					</div>
				</v-menu>
			</div>

<!--    EXPORT CSV-->
      <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="exportBox = true">
          <v-icon>icons8-export-csv</v-icon>
        </v-btn>
      </div>
    <ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
      <template v-slot:header>
        <div class="full-width d-flex align-center">
          <div>Export CSV</div>
          <v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
          <v-spacer />
          <v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-cancel</v-icon></v-btn>
        </div>
      </template>
      <p>Please select the data you wish to export:</p>
      <table width="100%" cellspacing="5">
        <tr>
          <td width="100"><strong>Current page:</strong></td>
          <td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
          <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
        </tr>
        <tr>
          <td><strong>Current total:</strong></td>
          <td>{{ itemsTotal }} items (filtered)</td>
          <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
        </tr>
        <tr>
          <td><strong>All Items:</strong></td>
          <td>{{ totalCount }} items (without filter)</td>
          <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
        </tr>
        <tr>
          <td valign="top"><strong>Specific:</strong></td>
          <td>
            <div class="d-flex align-center">
              <div>From</div>
              <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
              <div>To</div>
              <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
            </div>
            <div class="ml-n7">
              <CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
            </div>
          </td>
          <td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
        </tr>
      </table>
    </ExportBox>
<!--      EXPORT END-->
    </div>
		<!-- Alphabet Search -->
		<v-row v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" fill-width class="ma-0 mt-1">
			<!-- // loop through each letter of the alphabet and create a button -->
			<v-btn-toggle v-model="filter.alpha" class="grey lighten-2 d-flex align-center flex-wrap">
				<v-btn v-for="letter in alphabet" :key="letter" text :value="letter" height="30" class="white mr-1 mt-1" depressed x-small
					><span class="body-2 font-weight-medium">{{ letter }}</span></v-btn
				>
				<v-btn value="" class="mt-1 grey lighten-3" height="29" text depressed x-small><span class="body-2 font-weight-medium">All</span></v-btn>
			</v-btn-toggle>
			<v-spacer />
			<v-btn-toggle v-model="filter.profileScore" dense class="grey lighten-2 d-flex align-center flex-wrap">
				<div class="mx-3 mt-1 caption">PROFILE SCORE:</div>
				<v-btn class="mt-1" value="weak" active-class="error white--text" height="29" text depressed x-small small>Weak</v-btn>
				<v-btn class="mt-1" value="intermediate" active-class="warning white--text" height="29" text depressed x-small small>Intermediate</v-btn>
				<v-btn class="mt-1" value="strong" active-class="success white--text" height="29" text depressed x-small small>Strong</v-btn>
			</v-btn-toggle>
		</v-row>
        <!-- <pre>{{ computedItems }}</pre> -->
		<div class="mt-2">
			<!-- TABLE BASED LIST -->
			<v-data-table v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions" :loading="loading" loading-text="Loading People" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 50, 100] }">
				<template v-slot:top>
					<div class="d-flex align-center" style="width: 100% !important">
						<div class="py-5 pl-3 body-2" v-if="!loading">
							Showing <strong>{{ itemsFrom + 1 }}</strong>
							<span v-if="itemsTo !== itemsTotal">
								to <strong>{{ itemsTo }}</strong></span
							>
							of <strong>{{ itemsTotal }}</strong>
						</div>
						<v-spacer />
						<v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
							<v-btn value="destroy" small v-if="['Developer-Admin'].includes(userLevel)">
								<v-icon class="error--text">icons8-delete-trash</v-icon>
							</v-btn>
							<v-btn value="delete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
								<v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
							</v-btn>
							<v-btn value="undelete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
								<v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
							</v-btn>
						</v-btn-toggle>
					</div>
				</template>

				<template v-slot:item.orgName="{ item }">
					<div style="width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.orgName }}</div>
				</template>

				<template v-slot:item.action="{ item }">
					<v-icon class="error--text pa-1" v-if="toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
					<v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
					<v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon>
					<v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
				</template>

				<!-- Profile Score -->
				<template v-slot:item.contactProfileScore="{ item }">
					<v-chip small v-if="Number(item.contactProfileScore) >= 0 && Number(item.contactProfileScore < 25)" class="error white--text">{{ item.contactProfileScore }} %</v-chip>
					<v-chip small v-if="Number(item.contactProfileScore) >= 25 && Number(item.contactProfileScore < 75)" class="warning white--text">{{ item.contactProfileScore }} %</v-chip>
					<v-chip small v-if="Number(item.contactProfileScore) >= 75 && Number(item.contactProfileScore <= 100)" class="success white--text">{{ item.contactProfileScore }} %</v-chip>
					<v-chip small v-if="item.contactProfileScore === '' || item.contactProfileScore === null || item.contactProfileScore === undefined" class="white">-</v-chip>
				</template>
			</v-data-table>
			<!-- CARD BASED LIST -->
			<div v-if="listshrunk || $vuetify.breakpoint.smAndDown" class="">
				<v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
					<div class="d-flex align-center ml-1">
						<div class="ml-3">
							<div class="body-1 font-weight-medium" style="width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.contactFullName }}</div>
							<div class="caption darkgrey--text">{{ item.orgName }}</div>
							<div class="caption darkgrey--text">{{ item.contactMobilePhone }}</div>
							<div class="caption darkgrey--text">{{ item.contactEmail }}</div>
							<!-- <div class="body-2">{{ item.orgPostcode }}</div> -->
							<!-- <br />
							<span class="body-2">{{ item.supplierName }}</span
							><br />  -->
						</div>
						<v-spacer></v-spacer>
						<div class="text-right mr-3">
							<v-icon class="primary--text" @click="openItem(item)">icons8-forward</v-icon>
						</div>
					</div>
				</v-card>
			</div>
			<!-- LOAD MORE CARDS -->
			<v-btn v-if="(listshrunk && computedItems.length > 9) || ($vuetify.breakpoint.smAndDown && computedItems.length > 9)" class="mt-2" depressed block @click="loadMore()">Load More</v-btn>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import ExportBox from "@/components/general/ExportBox.vue";
import AppButton from "@/components/general/AppButton.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
import TextField from "@/components/form/TextField.vue";
export default {
  components: {TextField, CheckboxField, AppButton, ExportBox},
	props: {
		refreshitems: {
			type: Number,
			default: 0,
		},
		listshrunk: {
			type: Boolean,
			default: true,
		},
		listexpanded: {
			type: Boolean,
			default: false,
		},
		orgType: {
			type: String,
			default: "",
		},
		orgid: {
			type: String,
			default: "",
		},
	},
	data: () => ({
		loading: true,
		key: "contact",
		index: "idx:contacts",
		toggleDeletedItems: false,
		showImages: false,
		items: [],
		itemsTotal: null,
		searchQuery: "",
		alphabet: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
		itemsFrom: 0,
		itemsTo: 9,
		// activeItem: {},
		// Toggles
		toggleFeatures: [],
		// Headers to display in the Data Table
		headers: [
			{ text: "ID", value: "id", sortable: false, hidden: true, expanded: true },
			{ text: "Title", value: "contactTitle", sortable: false, hidden: true, expanded: true },
			{ text: "Full Name", value: "contactFullName", sortable: false, hidden: false, expanded: false },
			{ text: "First Name", value: "contactFirstName", sortable: false, hidden: true, expanded: true },
			{ text: "Last Name", value: "contactLastName", sortable: false, hidden: true, expanded: true },
			{ text: "Email", value: "contactEmail", sortable: false, hidden: false, expanded: true },
			{ text: "Secondary Email", value: "contactAlternateEmail", sortable: false, hidden: true, expanded: true },
			{ text: "Marketing Preferences", value: "contactMarketingOptOut", sortable: false, hidden: true, expanded: true },
			{ text: "Phone", value: "contactMobilePhone", sortable: false, hidden: false, expanded: false },
			{ text: "Other Phone", value: "contactDDIPhone", sortable: false, hidden: true, expanded: true },
			{ text: "Job Title", value: "contactJobTitle", sortable: false, hidden: false, expanded: true },
			{ text: "Job Level", value: "contactJobLevel", sortable: false, hidden: false, expanded: true },
			{ text: "Department", value: "contactJobDepartment", sortable: false, hidden: false, expanded: true },

			{ text: "Address Type", value: "contactAddressType", sortable: false, hidden: true, expanded: true },
			{ text: "Address Line 1", value: "contactAddressLine1", sortable: false, hidden: true, expanded: true },
			{ text: "Address Line 2", value: "contactAddressLine2", sortable: false, hidden: true, expanded: true },
			{ text: "Address Line 3", value: "contactAddressLine3", sortable: false, hidden: true, expanded: true },
			{ text: "Town", value: "contactTown", sortable: false, hidden: true, expanded: true },
			{ text: "County", value: "contactCounty", sortable: false, hidden: true, expanded: true },
			{ text: "Postcode", value: "contactPostcode", sortable: false, hidden: true, expanded: true },

			{ text: "Employees", value: "contactLeadStatus", sortable: false, hidden: true, expanded: true },
			{ text: "Industry", value: "contactSource", sortable: false, hidden: true, expanded: true },
			{ text: "Organisation Id", value: "orgId", sortable: false, hidden: true, expanded: true },
			{ text: "Organisation", value: "orgName", sortable: false, hidden: false, expanded: false },
			{ text: "Reporting To", value: "contactReportingTo", sortable: false, hidden: true, expanded: true },

			{ text: "Profile Score", value: "contactProfileScore", align: "center", sortable: false, hidden: false, expanded: false },

			{ text: "Twitter", value: "contactTwitter", sortable: false, hidden: true, expanded: true },
			{ text: "Facebook", value: "contactFacebook", sortable: false, hidden: true, expanded: true },
			{ text: "Linked In", value: "contactLinkedIn", sortable: false, hidden: true, expanded: true },
			{ text: "Instagram", value: "contactInstagram", sortable: false, hidden: true, expanded: true },
			{ text: "Instagram", value: "contactInstagram", sortable: false, hidden: true, expanded: true },

			{ text: "Date Time (C)", value: "createdDateTime", sortable: false, hidden: true, expanded: true },
			{ text: "User ID (C)", value: "createdUserId", sortable: false, hidden: true, expanded: true },
			{ text: "User Name (C)", value: "createdUserName", sortable: false, hidden: true, expanded: true },
			{ text: "User Email (C)", value: "createdUserEmail", sortable: false, hidden: true, expanded: true },
			{ text: "Date Time (M)", value: "modifiedDateTime", sortable: false, hidden: true, expanded: true },
			{ text: "User ID (M)", value: "modifiedUserId", sortable: false, hidden: true, expanded: true },
			{ text: "User Name (M)", value: "modifiedUserName", sortable: false, hidden: true, expanded: true },
			{ text: "User Email (M)", value: "modifiedUserEmail", sortable: false, hidden: true, expanded: true },
			{ text: "Deleted", value: "deleted", sortable: false, hidden: true, expanded: true },
			{ text: "Date Time (D)", value: "deletedDateTime", sortable: false, hidden: true, expanded: true },
			{ text: "User ID (D)", value: "deletedUserId", sortable: false, hidden: true, expanded: true },
			{ text: "User Name (D)", value: "deletedUserName", sortable: false, hidden: true, expanded: true },
			{ text: "User Email (D)", value: "deletedUserEmail", sortable: false, hidden: true, expanded: true },
			{ text: "Action", value: "action", align: "end", sortable: false, hidden: false, expanded: false, width: "155px" },
		],
		// Fields to Retrieve from Database
		//fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
		sortBy: "@contactFirstName",
		datatableOptions: {
			page: 1,
			itemsPerPage: 10,
			sortBy: ["orgName"],
			sortDesc: [false],
			groupBy: [],
			groupDesc: [],
			mustSort: false,
			multiSort: false,
		},

		// productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
		// Dropdowns
		jobLevels: [],
		jobDepartments: [],
		organisations: [],
		tags: [],
		filter: {
			productCategories: [],
			alpha: "",
			jobLevel: [],
			jobDepartment: [],
			orgId: "",
			tags: [],
			profileScore: "",
		},
		jobLevelAutocomplete: "",
		jobDepartmentAutocomplete: "",
		tagsAutocomplete: "",

    //EXPORT DATA
    exportBox: false,
    exportItems: {
      itemsFrom: "1",
      itemsTo: "10",
    },
    exportLoading: false,
    exportFiltered: false,
    showInfo: false,
    totalCount: 0,
	}),
	computed: {
		...mapGetters({
			GET_taskBar: "GET_taskBar",
			GET_FIREBASE_userAccessToken: "firebase_auth_store/GET_FIREBASE_userAccessToken",
		}),
		userLevel() {
			return this.GET_FIREBASE_userAccessToken.user_level;
		},
		userGroup() {
			return this.GET_FIREBASE_userAccessToken.user_group;
		},
		userStatus() {
			return this.GET_FIREBASE_userAccessToken.user_status;
		},
		alphaOptions() {
			let alphaOptions = [];
			for (let i = 0; i < this.alphabet.length; i++) {
				alphaOptions.push(this.filter.alpha + this.alphabet[i] + "*");
			}
			return alphaOptions;
		},
		computedSearchQuery() {
			let searchQuery = this.searchQuery;

			if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
				if (this.toggleFeatures.includes("undelete")) {
					searchQuery = "*";
				} else {
					searchQuery = "@deleted:0";
				}
			} else {
				if (searchQuery.startsWith("@")) {
					if (this.toggleFeatures.includes("undelete")) {
						searchQuery = `${searchQuery}*`;
					} else {
						searchQuery = `${searchQuery}*  @deleted:0`;
					}
				} else {
					if (this.toggleFeatures.includes("undelete")) {
						searchQuery = `${searchQuery}*`;
					} else {
						searchQuery = `${searchQuery}*  @deleted:0`;
					}
				}
			}
			// Props Filter
			if (this.orgid !== "" && this.orgid !== null && this.orgid !== undefined) {
				searchQuery = `${searchQuery}  @orgId:{${this.orgid}}`;
			}
			// Alphabet Filter
			if (this.filter.alpha !== "" && this.filter.alpha !== null && this.filter.alpha !== undefined) {
				let alphaOptions = this.alphaOptions.join(",");
				alphaOptions = alphaOptions.replace(/,/g, "|");
				searchQuery = `${searchQuery}  @contactFirstName:${alphaOptions}`;
			}
			// Job Levels Filter
			if (JSON.stringify(this.filter.jobLevel) !== "[]") {
				let jobLevels = this.filter.jobLevel.join(",");
				jobLevels = jobLevels.replace(/,/g, "|");
				// console.log('jobLevels ' +  JSON.stringify(jobLevels, null, 2))
				searchQuery = `${searchQuery}  @contactJobLevel:{${jobLevels}}`;
			}
			// Job Department Filter
			if (JSON.stringify(this.filter.jobDepartment) !== "[]") {
				let jobDepartments = this.filter.jobDepartment.join(",");
				jobDepartments = jobDepartments.replace(/,/g, "|");
				searchQuery = `${searchQuery}  @contactJobDepartment:{${jobDepartments}}`;
			}
			// Organisation Filter
			if (this.filter.orgId !== "") {
				searchQuery = `${searchQuery}  @orgId:{${this.filter.orgId}}`;
			}

			// Profile Score Filter
			if (this.filter.profileScore !== "") {
				if (this.filter.profileScore === "weak") {
					searchQuery = `${searchQuery}  @contactProfileScore: [0 +inf] @contactProfileScore: [-inf (25]`;
				} else if (this.filter.profileScore === "intermediate") {
					searchQuery = `${searchQuery}  @contactProfileScore: [25 +inf] @contactProfileScore: [-inf (75]`;
				} else if (this.filter.profileScore === "strong") {
					searchQuery = `${searchQuery}  @contactProfileScore: [75 +inf] @contactProfileScore: [-inf (100]`;
				}
			}

			// Page Filters
			return searchQuery;
		},
		// Computed Headers
		computedHeaders() {
			let headers = this.headers;
			headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
			if (!this.listexpanded) {
				headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
			}
			return headers;
		},
		computedItems() {
			var items = this.items; //.filter(x => x.);
			return items; //.slice(this.organisationsFrom, this.organisationsTo);
		},
		computedFields() {
			let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => "@" + y.value);
			if (!computedFields.includes("@id")) {
				computedFields.push("@id");
			}
			if (!computedFields.includes("@orgId")) {
				computedFields.push("@orgId");
			}
			if (!computedFields.includes("@deleted")) {
				computedFields.push("@deleted");
			}
			//if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
			computedFields = computedFields.join(",");
			return computedFields;
		},
    // Computed Profile Score
    computedProfileScore() {
      let score = 0;
      let totalScore = 8;
      if (this.item?.contactFirstName !== '' && this.item?.contactFirstName !== undefined && this.item?.contactFirstName !== null) {
        score += 1;
      }
      if (this.item?.contactLastName !== '' && this.item?.contactLastName !== undefined && this.item?.contactLastName !== null) {
        score += 1;
      }
      if (this.item?.contactEmail !== '' && this.item?.contactEmail !== undefined && this.item?.contactEmail !== null) {
        score += 1;
      }
      if (this.item?.contactMobilePhone !== '' && this.item?.contactMobilePhone !== undefined && this.item?.contactMobilePhone !== null) {
        score += 1;
      }
      if (this.item?.contactDDIPhone !== '' && this.item?.contactDDIPhone !== undefined && this.item?.contactDDIPhone !== null) {
        score += 1;
      }
      if (this.item?.contactJobTitle !== '' && this.item?.contactJobTitle !== undefined && this.item?.contactJobTitle !== null) {
        score += 1;
      }
      if (this.item?.contactJobLevel !== '' && this.item?.contactJobLevel !== undefined && this.item?.contactJobLevel !== null) {
        score += 1;
      }
      if (this.item?.contactJobDepartment !== '' && this.item?.contactJobDepartment !== undefined && this.item?.contactJobDepartment !== null) {
        score += 1;
      }
      let scoring = Math.round((score / totalScore) * 100);
      switch (true) {
        case scoring >= 0 && scoring < 25:
          return {
            score: scoring,
            color: 'error',
            name: 'Weak',
            message: 'This profile is weak, please add more information.',
          };
        case scoring >= 25 && scoring < 75:
          return {
            score: scoring,
            color: 'warning',
            name: 'Intermediate',
            message: 'This profile is ok, please add more information.',
          };

        case scoring >= 75:
          return {
            score: scoring,
            color: 'success',
            name: 'Strong',
            message: 'This profile is strong',
          };
        default:
          return {
            score: 0,
            color: 'info',
            name: '',
            message: '',
          };
      }
    },
	},
	methods: {
		// * DESTROY ITEM FROM ITEMS
		async destroyItem(itemId) {
			let result = await this.MIX_destroyItem(itemId, this.key);
			if (result.code === 1) {
				this.getItems();
			}
		},
		// * DELETE ITEM FROM ITEMS
		async deleteItem(itemId) {
			let result = await this.MIX_deleteItem(itemId, this.key);
			if (result.code === 1) {
				this.getItems();
			}
		},
		// * RESTORE ITEM FROM ITEMS
		async restoreItem(itemId) {
			let result = await this.MIX_restoreItem(itemId, this.key);
			if (result.code === 1) {
				this.getItems();
			}
		},
		// * OPEN ITEM
		openItem(item) {
			this.$emit("openItem", JSON.parse(JSON.stringify(item)));
			//this.activeItem = item;
			// this.listexpanded = false;
		},
		// Refresh Items
		refreshItems() {
			this.getItems();
		},
		// * GET ITEMS
		async getItems() {
			this.loading = true;
			// console.log("this.index = " + this.index);
			// console.log("this.computedSearchQuery = " + this.computedSearchQuery);
			// console.log("this.itemsFrom = " + this.itemsFrom);
			// console.log("this.datatableOptions.itemsPerPage = " + this.datatableOptions.itemsPerPage);
			// console.log("this.computedFields = " + this.computedFields);
			// console.log("this.sortBy = " + this.sortBy);
			let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
			if (itemsResult.code === 1) {
				this.itemsTotal = itemsResult.data.total;
				this.items = itemsResult.data.results;
				let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
				if (itemsTo < this.itemsTotal) {
					this.itemsTo = itemsTo;
				} else {
					this.itemsTo = this.itemsTotal;
				}
			} else {
				//console.log("items = " + JSON.stringify(itemsResult, null, 2));
			}
			this.loading = false;
		},
		async loadMore() {
			this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
			this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
			this.getItems();
		},
		// * Clear Tag Filter
		clearFilter(key, value) {
			setTimeout(() => {
				this.filter[key] = value;
			}, 1);
		},
    //export CSV
    // Export Box CSV
    async exportCSV(itemsFrom, itemsTo, filter, specific) {
      // console.table({ itemsFrom, itemsTo, filter, specific });
      if (itemsFrom > itemsTo) {
        this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
      } else {
        this.exportLoading = true;
        var itemsCount, tableData, csv, query, exportFileName;
        switch (true) {
            // Current Page / Current Total (filtered)
          case filter && !specific:
            itemsCount = itemsTo - itemsFrom;
            query = this.computedSearchQuery;
            break;
            // All (Not filtered)
          case !filter && !specific:
            itemsCount = this.totalCount;
            query = `*`;
            break;
            // Specific (filtered/ not filtered)
          case specific:
            itemsFrom = itemsFrom - 1;
            itemsCount = itemsTo - itemsFrom;
            if (this.exportFiltered) {
              query = this.computedSearchQuery;
            } else {
              query = `*`;
            }
            break;
        }
        // console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
        // let exportResult = await this.REDIS_searchFor("animal", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
        let exportResult = await this.MIX_redisAggregateSearch(this.index, query, itemsFrom, itemsCount, this.computedFields, this.sortBy, false);
        // console.log(exportResult)
        tableData = JSON.parse(JSON.stringify(exportResult.data.results));
        // console.table(tableData)
        csv = this.$papa.unparse(tableData);
        if (itemsFrom === 0) {
          itemsFrom = 1; // This is just for filename purposes
        }
        if (itemsFrom >= 10) {
          itemsFrom = itemsFrom + 1;
        }
        switch (true) {
          case !specific && filter:
            exportFileName = `People_Filtered_${itemsFrom}_to_${itemsTo}`;
            break;
          case !specific && !filter:
            exportFileName = `People_Filtered_${itemsFrom}_to_${itemsTo}`;
            break;
          case specific && this.exportFiltered:
            exportFileName = `People_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
            break;
          case specific && !this.exportFiltered:
            exportFileName = `People_Specific_${itemsFrom}_to_${itemsTo}`;
            break;
        }
        // console.log(exportFileName);
        // console.log(csv.length);
        this.$papa.download(csv, exportFileName);
        this.exportLoading = false;
      }
    },
    async getTotal(){
      let totalResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
      // console.log(totalResult)
      this.totalCount = totalResult.data.total;
    },
	},
	watch: {
		filter: {
			handler: function () {
				this.datatableOptions.page = 1;
				this.itemsFrom = 0;
				this.getItems();
			},
			deep: true,
		},
		refreshitems: function () {
			this.getItems();
		},
		// If the search query changes then get updated dataset based on search query
		computedSearchQuery: {
			handler: function () {
				this.datatableOptions.page = 1;
				this.itemsFrom = 0;
				this.getItems();
			}, deep: true
		},
		toggleDeletedItems() {
			this.getItems();
		},
		showImages() {
			let imagesIndex = this.headers.findIndex((x) => x.value === "productMainImage");
			//console.log('imagesIndex = ' + imagesIndex)
			if (this.showImages) {
				this.headers[imagesIndex].hidden = false;
			} else {
				this.headers[imagesIndex].hidden = true;
			}
		},
		datatableOptions: {
			handler() {
				this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
				let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
				if (itemsTo < this.itemsTotal) {
					this.itemsTo = itemsTo;
				} else {
					this.itemsTo = this.itemsTotal;
				}
				//console.log('organisationFrom=' + this.organisationFrom)
				this.getItems();
			},
			deep: true,
		},
		listshrunk() {
			if (this.listshrunk) {
				this.datatableOptions.itemsPerPage = 6;
				this.itemsFrom = 0;
				this.getItems();
			}
		},
		listexpanded() {
			if (this.listexpanded === false) {
				this.datatableOptions.itemsPerPage = 6;
				this.itemsFrom = 0;
				this.getItems();
			}
		},
	},
	async created() {
		let t = this;
		t.loading = true;
		// Get Organisations
		t.organisations = await t.MIX_organisations();
		// Job Levels
		let jobLevels = await t.MIX_redisReadSet("set:jobLevels", false);
		if (jobLevels.code === 1) {
			t.jobLevels = jobLevels.data;
		}
		// Departments
		let jobDepartments = await t.MIX_redisReadSet("set:jobDepartments", false);
		if (jobDepartments.code === 1) {
			t.jobDepartments = jobDepartments.data;
		}
		if (this.$vuetify.breakpoint.smAndDown) {
			this.datatableOptions.itemsPerPage = 6;
		}
		await this.getItems();
    await this.getTotal();
	},
};
</script>
