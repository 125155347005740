<template>
    <v-row no-gutters class="pa-3">
        <v-file-input dense outlined show-size counter v-bind:accept="accept" v-bind:label="label" v-model="file" :rules="rules"></v-file-input>
        <v-btn block v-bind:class="buttonClass" :loading="loading" :disabled="loading || file === null" @click="uploadFile()">UPLOAD</v-btn>
        <v-col cols="12" class="py-3">
            <v-progress-linear indeterminate color="primary" v-model="value" :active="show" v-bind:class="progressClass">
                <strong>{{ Math.ceil(value) }}%</strong>
            </v-progress-linear>
        </v-col>
    </v-row>
</template>

<script>
import { getAuth, updateProfile } from 'firebase/auth';
import imageCompression from 'browser-image-compression';

export default {
    name: 'FileUpload',
    props: {
        accept: String,
        buttonClass: String,
        compressionMaxSizeMB: Number,
        compressionmaxWidthOrHeight: Number,
        fileName: String,
        folderLocation: String,
        label: String,
        private: Boolean,
        progressClass: String,
        selectedItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data: () => ({
        file: null,
        loading: false,
        loader: null,
        rules: [(value) => !value || value.size < 2000000 || 'Image size should be less than 2 MB!'],
        show: false,
        value: 0,
    }),
    methods: {
        async uploadFile() {
            let t = this;
            t.loading = true;
            const options = {
                maxSizeMB: t.compressionMaxSizeMB,
                maxWidthOrHeight: t.compressionmaxWidthOrHeight,
                useWebWorker: true,
            };
            //* Compresses the image realtime
            const compressedFile = await imageCompression(t.file, options);
            //* Storage location in firebase
            const upload =
                t.private === true
                    ? t.$firebase.privateStorage.ref(t.folderLocation + '/' + t.selectedItem.id + '/' + t.fileName + '.jpg').put(compressedFile)
                    : t.$firebase.publicStorage.ref(t.folderLocation + '/' + t.selectedItem.id + '/' + t.fileName + '.jpg').put(compressedFile);
            upload.on(
                'state_changed',
                (snapshot) => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    t.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    console.error(error);
                },
                () => {
                    upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        const auth = getAuth();
                        updateProfile(auth.currentUser, {
                            photoURL: downloadURL,
                        })
                            .then(() => {
                                t.$firebase.db.collection('users').doc(t.selectedItem.id).update({
                                    photoURL: downloadURL,
                                });
                                t.$emit('update');
                                t.loading = false;
                            })
                            .catch((error) => {
                                t.loading = false;
                                console.error(error);
                            });
                    });
                }
            );
        },
    },
};
</script>