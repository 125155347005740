<template>
    <div class="grey lighten-3" style="overflow: hidden !important" >
        <!--style="height: calc(100vh - 150px) !important; overflow: scroll !important"-->
        <v-row class="" dense>
            <v-col cols="12" sm="12" class="">
                <div class="d-flex align-center primary pa-4">
                    <div class="mx-2">
                        <v-icon class="white--text">icons8-price-tag</v-icon>
                    </div>
                    <div class="text-left white--text text-h5 font-weight-light">Add Custom Product</div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" class="pa-4">
                <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                Please use the form below to add a Custom Product. Custom products will be saved to the system and can be used in other quotes.
            <!-- <pre>{{ item }}</pre> -->
            </v-col>

            <v-col cols="12" sm="4" class="pl-4 d-flex align-start justify-center">
                <div flat class="white rounded" depressed style="border: 1px solid grey !important; height: 100% !important; width: 100% !important">
                    <div  v-if="item.productType !== 'Delivery'" class="d-flex align-center justify-center" style="height: 100% !important; width: 100% !important">
                        <!-- <v-btn class="grey lighten-1" depressed> <v-icon class="mr-2 primary--text">icons8-picture</v-icon><span class="primary--text">Add image</span> </v-btn> -->        
                        <div class="" v-if="item.productImageId !== ''">
                            <div><v-img class="my-2" width="250" height="250" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/' + item.productImageId + '.jpeg?x=' + Date.now()"/></div>
                            <!-- <div v-if="product.productImageId === ''"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div> -->
                        </div>
                        <div v-if="item.productImageId == ''">
                            <ImageUpload
                                accept="image/jpeg, image/jpg, image/png"
                                label="Select file"
                                buttonClass="primary"
                                progressClass="py-4 pa-2"
                                folderLocation="products/"
                                :fileName="item.id"
                                :compressionMaxSizeMB="1"
                                :compressionmaxWidthOrHeight="500"
                                :selectedItem="item"
                                :private="false"
                                :createThumbnail="true"
                                v-on:update="updateItemImage()"
                            />
                        </div>

                
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="8" class="pl-1 pr-4">
                <v-row class="" dense>
                    <!-- PRODUCT DETAILS -->
                    <v-col cols="12" xs="12" sm="3" class="">
                        <v-text-field dense class="" v-model="item.productCode" label="Product Code" outlined background-color="white" hide-details="auto" />
                    </v-col>
                    <v-col cols="12" xs="12" sm="9" class="">
                        <v-text-field dense class="" v-model="item.productName" label="Product Name" outlined background-color="white" hide-details="auto" />
                    </v-col>
                    <v-col cols="12" xs="12" sm="8" class="d-flex align-center">
                        <v-select small dense v-model="item.productType" @change="changingProductType()" :items="productTypes" label="Product Type" outlined background-color="white" hide-details="auto" :menu-props="{ top: false, offsetY: true }" :attach="true" />
                    </v-col>
                    <v-col v-if="item.productType !== 'Delivery'" cols="12" xs="12" sm="4" class="">
                        <v-select dense class="" v-model="item.productMTO" :items="mtoItems" label="Made to Order" outlined background-color="white" hide-details="auto" />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" class="">
                        <v-textarea small dense class="" v-model="item.productShortDescription" label="Short Description" outlined background-color="white" rows="3" hide-details="auto" />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" class="d-flex align-center">
                        <div v-if="item.productType !== 'Delivery'" class="priceblock">
                            <v-text-field dense label="MOQ" v-model="item.MOQ" placeholder="Min Order Quantity" outlined background-color="white" hide-details="auto" />
                        </div>
                        <div v-if="item.productType !== 'Delivery'" class="priceblock ml-2">
                            <v-text-field dense label="Market Price" v-model="item.MP" outlined prefix="£" background-color="white" hide-details="auto" />
                        </div>
                        <div v-if="item.productType !== 'Delivery'" class="priceblock ml-2 mr-2">
                            <v-text-field dense label="RRP Price" v-model="item.RRP" outlined prefix="£" background-color="white" hide-details="auto" />
                        </div>
                        <div class="priceblock">
                            <v-text-field dense label="Cost Price" @change="reCalculate('cP1')" v-model="item.cP1" outlined prefix="£" background-color="white" hide-details="auto" />
                        </div>
                        <div class="priceblock ml-2">
                            <v-text-field dense label="Sell Price" @change="reCalculate('sP1')" v-model="item.sP1" outlined prefix="£" background-color="white" hide-details="auto" />
                        </div>
                        <div class="priceblock ml-2">
                            <v-text-field dense label="Margin" @change="reCalculate('margin')" v-model="margin" outlined background-color="white" hide-details="auto" />
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" class="d-flex align-center">
                        <v-autocomplete
                            v-if="item.productType !== 'Delivery'"
                            auto-select-first
                            :items="orgs"
                            item-text="orgName"
                            return-object
                            label="Supplier Name"
                            v-model="selectedOrg"
                            @change="
                                item.orgName = selectedOrg.orgName;
                                item.orgId = selectedOrg.id;
                            "
                            @click:clear="clearSelectedOrg()"
                            outlined
                            dense
                            hide-details="auto"
                            background-color="white"
                            color="primary"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col v-if="item.productType !== 'Delivery'" cols="12" xs="12" sm="6" class="d-flex align-center">
                        <v-text-field dense v-model="item.productWebLink" label="Associated Web Link" outlined background-color="white" hide-details="auto" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" class="px-3">
                <div class="text-left primary--text text-h6 font-weight-light">Quote</div>
                <div class="d-flex">
                    <v-checkbox :label="'Add this product to Quote:' + quotenumber" v-model="quoteCheck" dense hide-details="auto"></v-checkbox>
                    <div class="priceblock ml-4" style="width: 100px"><v-text-field dense label="Qty" v-model="qty" outlined background-color="white" hide-details="auto" /></div>
                </div>
            </v-col>
        </v-row>
        <v-row class="px-3 pb-3 primary">
            <v-col cols="12" class="d-flex justify-right align-center">
                <v-spacer />
                <div>
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <div class="ml-2">
                    <v-btn class="accent primary--text font-weight-bold" depressed @click="saveItem"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="6">
                <span v-for="(value, name) in item" :key="name"
                    >{{ name }}: <span class="font-weight-bold success--text">{{ value }}<br /></span
                ></span>
            </v-col>
            <v-col cols="6">
                <span v-for="(value, name) in lineItem" :key="name"
                    >{{ name }}: <span class="font-weight-bold success--text">{{ value }}<br /></span
                ></span>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>

// TODO - RG - Add Image Compression and Upload to Custom Products

import schema from '@/schema.js';
export default {
    // PROPS
    props: {
        quoteid: {
            type: String,
            default: '',
        },
        quotenumber: {
            type: String,
            default: '',
        },        
    },
    // DATA
    data: () => ({
        quoteCheck: true,
        item: {},
        qty: 1,
        lineItem: {},
        mtoItems: [
            { text: 'Yes', value: '1' },
            { text: 'No', value: '0' },
        ],
        productStatus: [],
        productTypes: [], //'Custom', 'Misc', 'Installation', 'Delivery'],
        margin: 0,
        orgs: [],
        selectedOrg: {
            orgName: '',
            id: '',
        },
        productSchema: {},
        lineItemSchema: {},
    }),
    // METHODS
    methods: {
        initialise() {
            let t = this;
            t.item = { ...t.productSchema };
            // Set Item Defaults
            t.item.id = t.MIX_generateId();
            t.item.productType = 'Custom';
            t.item.productStatus = 'Live';
            t.item.productLeadTimeDays = '0';
            t.lineItem = { ...t.lineItemSchema };
        },
        async updateLineItem() {
            this.lineItem = await this.CALC_createLineItem(this.item, this.qty)
        },
        // Clear Selected
        clearSelectedOrg() {
            setTimeout(() => {
                this.selectedOrg = { id: '', orgName: '' };
            }, 1);
        },
        closeItem() {
            this.$emit('closeitem');
        },
        cancelItem() {
            this.item = {};
            this.lineItem = {};
            this.$emit('closeitem');
        },
        updateItemImage() {
            console.log('updateItemImage', this.item)
            this.item.productImageId = this.item.id;
        },
        async saveItem() {
            // Save Product
            let t = this;
            t.item.productSupplierCode = t.item.productCode;
            t.lineItem.id = t.MIX_generateId();
            t.lineItem.quoteId = t.quoteid;
            t.lineItem.lineAuthRequired = '1'
            let saveProductResult = await this.MIX_createItem(this.item, "product", true);
            console.log('saveProductResult', JSON.stringify(saveProductResult, null, 2));
            if (saveProductResult.code === 1) {
                if (this.quoteCheck) {
                    let saveLineItemResult = await t.MIX_createItem(this.lineItem, 'lineItem', true);
                    if (saveLineItemResult.code === 1) {
                        t.closeItem();
                        t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Product added', show: true });
                    } else {
                        t.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error adding product', show: true });
                    }
                } else {
                    t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Product added', show: true });
                }
            } else {
                t.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error adding product', show: true });
            }
        },
        // Re Calculate
        reCalculate(fieldChanged) {
            let addMargin = 0;
            switch (fieldChanged) {
                case 'cP1':
                    addMargin = (100 - Number(this.margin)) / 100;
                    this.item.sP1 = Number(this.item.cP1 / addMargin).toFixed(2);
                    break;
                case 'sP1':
                    this.margin = (((Number(this.item.sP1) - Number(this.item.cP1)) / Number(this.item.sP1)) * 100).toFixed(2);
                    break;
                case 'margin':
                    // Formula =B3/((100-C3)/100) where B3 is Cost Price and C3 is Margin
                    addMargin = (100 - Number(this.margin)) / 100;
                    this.item.sP1 = Number(this.item.cP1 / addMargin).toFixed(2);
                    break;
            }
        },
        changingProductType() {
            if (this.item.productType == 'Delivery') {
                this.item.productName = 'Delivery'
            } else if (this.item.productName == 'Delivery') {
                this.item.productName = ''
            }
            
        }
    },
    // WATCH
    watch: {
        item: {
            handler: function () {
                this.updateLineItem();
            },
            deep: true,
        },
        qty: {
            handler: function () {
                this.updateLineItem();
            },
            deep: true,
        },
    },
    // CREATED
    async created() {
        let t = this;
        t.loading = true;
        t.productSchema = await schema.product;
        t.lineItemSchema = await schema.lineItem;
        // Get Product Types
        let productTypesResult = await t.MIX_redisReadSet('set:productTypes', true);
        if (productTypesResult.code === 1) {
            t.productTypes = productTypesResult.data;
        }
        let productStatusResult = await t.MIX_redisReadSortedSet('sset:productStatus', 0, 99999);
        if (productStatusResult.code === 1) {
            t.productStatus = productStatusResult.data;
        }
        t.orgs = await t.MIX_organisations('Supplier');
        t.initialise();
        t.loading = false;
    },
};
</script>

<style scoped>
.priceblock {
    flex: 1;
}
</style>
