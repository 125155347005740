<template>
    <div class="grey lighten-2" style="width: 100% !important; height: 100% !important; overflow-y: scroll !important" fill-height>
        <!-- <v-progress-linear v-if="loading" indeterminate color="primary darken-2"></v-progress-linear> -->
        <v-row dense class="">
            <v-col class="align-center pa-5 mt-1" cols="12" xs="12">
                <div class="d-flex align-center mb-1">
                    <div><v-icon class="grey--text" x-large>icons8-delete-trash</v-icon></div>
                    <div class="text-left headingtext--text text-h4 font-weight-light ml-2">Bulk Delete</div>
                </div>
            </v-col>
            <v-col cols="12" xs="12" md="12" class="px-5">
                <div class="mt-3" style="width: 100% !important">Please select the supplier who's data you wish to delete:<br /></div>
                <v-autocomplete :items="orgs" item-text="orgName" @change="getTotals" label="Select Supplier" dense v-model="selectedOrg" return-object auto-select-first @click:clear="clearSelectedOrg()" outlined hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
                <br />
                <p class="error--text font-weight-bold">Please not this option is not reversable</p>
                <v-simple-table v-if="selectedOrg !== ''">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th width="25%">Supplier</th>
                                <th width="5%" align="center">Totals</th>
                                <th width="20%">Action</th>
                                <th width="50%">Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td valign="middle">{{ selectedOrg.orgName }} Options</td>
                                <td valign="middle" align="center">
                                    <v-progress-circular v-if="optionsTotal === '' && selectedOrg !== ''" indeterminate color="purple"></v-progress-circular>
                                    <div class="text-h6" v-if="optionsTotal !== ''">{{ optionsTotal }}</div>
                                </td>
                                <td valign="middle" class="py-2">
                                    <v-btn :disabled="actionRunning || optionsTotal === 0" block @click="confirmDialog = confirmOptionsDelete" class="error"
                                        ><v-icon>icons8-delete-trash</v-icon>
                                        <div class="ml-2"></div>
                                        Delete Options</v-btn
                                    >
                                </td>
                                <td valign="middle">
                                    <v-progress-linear v-model="computedOptionsPercenteComplete" color="primary" :class="computedOptionsPercenteComplete < 50 ? 'black--text' : 'white--text'" height="35">
                                        <div class="font-weight-bold white--text" v-if="optionsProgressResult.status !== ''">{{ optionsProgressResult.count }}/{{ optionsProgressResult.totalItems }}</div>
                                        <div class="font-weight-bold white--text ml-4" v-if="optionsProgressResult.status === 'Completed'">Bulk Delete Completed</div>
                                    </v-progress-linear>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">{{ selectedOrg.orgName }} Attributes</td>
                                <td valign="middle" align="center">
                                    <v-progress-circular v-if="attributesTotal === '' && selectedOrg !== ''" indeterminate color="purple"></v-progress-circular>
                                    <div class="text-h6" v-if="attributesTotal !== ''">{{ attributesTotal }}</div>
                                </td>
                                <td valign="middle" class="py-2">
                                    <v-btn :disabled="actionRunning || attributesTotal === 0" block @click="confirmDialog = confirmAttributesDelete" class="error"
                                        ><v-icon>icons8-delete-trash</v-icon>
                                        <div class="ml-2"></div>
                                        Delete Attributes</v-btn
                                    >
                                </td>
                                <td valign="middle">
                                    <v-progress-linear v-model="computedAttributesPercenteComplete" color="primary" :class="computedAttributesPercenteComplete < 50 ? 'black--text' : 'white--text'" height="35">
                                        <div class="font-weight-bold white--text" v-if="attributesProgressResult.status !== ''">{{ attributesProgressResult.count }}/{{ attributesProgressResult.totalItems }}</div>
                                        <div class="font-weight-bold white--text ml-4" v-if="attributesProgressResult.status === 'Completed'">Bulk Delete Completed</div>
                                    </v-progress-linear>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">{{ selectedOrg.orgName }} Products</td>
                                <td valign="middle" align="center">
                                    <v-progress-circular v-if="productsTotal === '' && selectedOrg !== ''" indeterminate color="purple"></v-progress-circular>
                                    <div class="text-h6" v-if="productsTotal !== ''">{{ productsTotal }}</div>
                                </td>
                                <td valign="middle" class="py-2">
                                    <v-btn :disabled="actionRunning || productsTotal === 0" block @click="confirmDialog = confirmProductsDelete" class="error"
                                        ><v-icon>icons8-delete-trash</v-icon>
                                        <div class="ml-2"></div>
                                        Delete Products</v-btn
                                    >
                                </td>
                                <td valign="middle">
                                    <v-progress-linear v-model="computedProductsPercenteComplete" color="primary" :class="computedProductsPercenteComplete < 50 ? 'black--text' : 'white--text'" height="35">
                                        <div class="font-weight-bold white--text" v-if="productsProgressResult.status !== ''">{{ productsProgressResult.count }}/{{ productsProgressResult.totalItems }}</div>
                                        <div class="font-weight-bold white--text ml-4" v-if="productsProgressResult.status === 'Completed'">Bulk Delete Completed</div>
                                    </v-progress-linear>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle">{{ selectedOrg.orgName }} Variants</td>
                                <td valign="middle" align="center">
                                    <v-progress-circular v-if="variantsTotal === '' && selectedOrg !== ''" indeterminate color="purple"></v-progress-circular>
                                    <div class="text-h6" v-if="variantsTotal !== ''">{{ variantsTotal }}</div>
                                </td>
                                <td valign="middle" class="py-2">
                                    <v-btn :disabled="actionRunning || variantsTotal === 0" block @click="confirmDialog = confirmVariantsDelete" class="error"
                                        ><v-icon>icons8-delete-trash</v-icon>
                                        <div class="ml-2"></div>
                                        Delete Variants</v-btn
                                    >
                                </td>
                                <td valign="middle">
                                    <v-progress-linear v-model="computedVariantsPercenteComplete" color="primary" :class="computedVariantsPercenteComplete < 50 ? 'black--text' : 'white--text'" height="35">
                                        <div class="font-weight-bold white--text" v-if="variantsProgressResult.status !== ''">{{ variantsProgressResult.count }}/{{ variantsProgressResult.totalItems }}</div>
                                        <div class="font-weight-bold white--text ml-4" v-if="variantsProgressResult.status === 'Completed'">Bulk Delete Completed</div>
                                    </v-progress-linear>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <!-- :rules="rules.productSupplier" -->
        </v-row>
        <confirm-dialog :datadown="confirmDialog" v-on:method="confirmMethodHandler" />
    </div>
</template>

<script>
export default {
    name: "DeleteData",
    data: () => ({
        // loading status for each type
        loading: false,
        optionsLoading: false,
        attributesLoading: false,
        productsLoading: false,
        variantsLoading: false,
        // Data IDs for each type
        optionsIds: [],
        attributesIds: [],
        productsIds: [],
        variantsIds: [],
        // Totals for each type
        optionsTotal: "",
        attributesTotal: "",
        productsTotal: "",
        variantsTotal: "",
        // Results for each type
        optionsResult: "",
        attributesResult: "",
        productsResult: "",
        variantsResult: "",
        // Progress IDs for each type
        optionsProgressId: "",
        attributesProgressId: "",
        productsProgressId: "",
        variantsProgressId: "",
        // Options Progress Result
        optionsProgressResult: {
            status: "",
            count: "",
            totalItems: "",
        },
        // Attributes Progress Result
        attributesProgressResult: {
            status: "",
            count: "",
            totalItems: "",
        },
        // Products Progress Result
        productsProgressResult: {
            status: "",
            count: "",
            totalItems: "",
        },
        // Variants Progress Result
        variantsProgressResult: {
            status: "",
            count: "",
            totalItems: "",
        },
        // Action Running Status
        actionRunning: true,
        orgs: [],
        selectedOrg: "",
        // Dialogs
        confirmDialog: {},
        confirmOptionsDelete: {
            show: true,
            title: "WARNING - Delete Options",
            message: 'Are you sure you want to permenantly delete the options <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p>',
            color: "error",
            actionText1: "No",
            actionText2: "Yes",
            id: "",
            method: "deleteOptions",
            action: "",
        },
        confirmAttributesDelete: {
            show: true,
            title: "WARNING - Delete Attributes",
            message: 'Are you sure you want to permenantly delete the attributes <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p>',
            color: "error",
            actionText1: "No",
            actionText2: "Yes",
            id: "",
            method: "deleteAttributes",
            action: "",
        },
        confirmProductsDelete: {
            show: true,
            title: "WARNING - Delete Products",
            message: 'Are you sure you want to permenantly delete the products <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p>',
            color: "error",
            actionText1: "No",
            actionText2: "Yes",
            id: "",
            method: "deleteProducts",
            action: "",
        },
        confirmVariantsDelete: {
            show: true,
            title: "WARNING - Delete Variants",
            message: 'Are you sure you want to permenantly delete the variants <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p>',
            color: "error",
            actionText1: "No",
            actionText2: "Yes",
            id: "",
            method: "deleteVariants",
            action: "",
        },
    }),
    computed: {
        computedOptionsPercenteComplete() {
            let percentComplete = Math.ceil((Number(this.optionsProgressResult.count) / Number(this.optionsProgressResult.totalItems)) * 100);
            if (isNaN(percentComplete)) {
                percentComplete = 0;
            }
            return percentComplete;
        },
        computedAttributesPercenteComplete() {
            let percentComplete = Math.ceil((Number(this.attributesProgressResult.count) / Number(this.attributesProgressResult.totalItems)) * 100);
            if (isNaN(percentComplete)) {
                percentComplete = 0;
            }
            return percentComplete;
        },
        computedProductsPercenteComplete() {
            let percentComplete = Math.ceil((Number(this.productsProgressResult.count) / Number(this.productsProgressResult.totalItems)) * 100);
            if (isNaN(percentComplete)) {
                percentComplete = 0;
            }
            return percentComplete;
        },
        computedVariantsPercenteComplete() {
            let percentComplete = Math.ceil((Number(this.variantsProgressResult.count) / Number(this.variantsProgressResult.totalItems)) * 100);
            if (isNaN(percentComplete)) {
                percentComplete = 0;
            }
            return percentComplete;
        },
    },
    methods: {
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrg = "";
            }, 1);
        },
        async getTotals() {
            this.actionRunning = true;
            // Count Option Numbers
            this.optionsLoading = true;
            let url = `${process.env.VUE_APP_MOFSERVER}/destroy/keys?index=idx:options&searchquery=@orgId:{${this.selectedOrg.orgId}}`;
            let optionsTotalResult = await this.$axios.get(url);
            if (optionsTotalResult.data.code === 1) {
                this.optionsIds = optionsTotalResult.data.data;
                this.optionsTotal = optionsTotalResult.data.data.length;
            }
            this.optionsLoading = false;
            // Count Attribute Numbers
            this.attributesLoading = true;
            url = `${process.env.VUE_APP_MOFSERVER}/destroy/keys?index=idx:attributes&searchquery=@orgId:{${this.selectedOrg.orgId}}`;
            let attributesTotalResult = await this.$axios.get(url);
            if (attributesTotalResult.data.code === 1) {
                this.attributesIds = attributesTotalResult.data.data;
                this.attributesTotal = attributesTotalResult.data.data.length;
            }
            this.attributesLoading = false;
            // Count Product Numbers
            this.productsLoading = true;
            url = `${process.env.VUE_APP_MOFSERVER}/destroy/keys?index=idx:products&searchquery=@orgId:{${this.selectedOrg.orgId}}`;
            let productsTotalResult = await this.$axios.get(url);
            if (productsTotalResult.data.code === 1) {
                this.productsIds = productsTotalResult.data.data;
                this.productsTotal = productsTotalResult.data.data.length;
            }
            this.productsLoading = false;
            // Count Variable Numbers
            this.variantsLoading = true;
            url = `${process.env.VUE_APP_MOFSERVER}/destroy/keys?index=idx:variants&searchquery=@orgId:{${this.selectedOrg.orgId}}`;
            let variantsTotalResult = await this.$axios.get(url);
            if (variantsTotalResult.data.code === 1) {
                this.variantsIds = variantsTotalResult.data.data;
                this.variantsTotal = variantsTotalResult.data.data.length;
            }
            this.variantsLoading = false;
            this.actionRunning = false;
        },
        async deleteOptions() {
            try {
                this.actionRunning = true;
                this.optionsProgressId = this.MIX_generateId();
                this.getOptionsProgressUpdate(this.optionsProgressId);
                let url = `${process.env.VUE_APP_MOFSERVER}/destroy/bulkdestroy/?progressid=${this.optionsProgressId}`;
                let optionsResult = await this.$axios.post(url, this.optionsIds);
                if (optionsResult.data.code === 1) {
                    this.optionsResult = optionsResult.data.data;
                }
                this.actionRunning = false;
                this.getTotals();
            } catch (error) {
                this.optionsResult = "An Error Occured - Please Contact Support";
                this.actionRunning = false;
                console.log(error);
            }
        },
        async deleteAttributes() {
            try {
                this.actionRunning = true;
                this.attributesProgressId = this.MIX_generateId();
                this.getAttributesProgressUpdate(this.attributesProgressId);
                let url = `${process.env.VUE_APP_MOFSERVER}/destroy/bulkdestroy/?progressid=${this.attributesProgressId}`;
                let attributesResult = await this.$axios.post(url, this.attributesIds);
                if (attributesResult.data.code === 1) {
                    this.attributesResult = attributesResult.data.data;
                }
                this.actionRunning = false;
                this.getTotals();
            } catch (error) {
                this.attributesResult = "An Error Occured - Please Contact Support";
                this.actionRunning = false;
                console.log(error);
            }
        },
        async deleteProducts() {
            try {
                this.actionRunning = true;
                this.productsProgressId = this.MIX_generateId();
                this.getProductsProgressUpdate(this.productsProgressId);
                let url = `${process.env.VUE_APP_MOFSERVER}/destroy/bulkdestroy/?progressid=${this.productsProgressId}`;
                let productsResult = await this.$axios.post(url, this.productsIds);
                if (productsResult.data.code === 1) {
                    this.productsResult = productsResult.data.data;
                }
                this.actionRunning = false;
                this.getTotals();
            } catch (error) {
                this.productsResult = "An Error Occured - Please Contact Support";
                this.actionRunning = false;
                console.log(error);
            }
        },
        async deleteVariants() {
            try {
                this.actionRunning = true;
                this.variantsProgressId = this.MIX_generateId();
                this.getVariantsProgressUpdate(this.variantsProgressId);
                let url = `${process.env.VUE_APP_MOFSERVER}/destroy/bulkdestroy/?progressid=${this.variantsProgressId}`;
                let variantsResult = await this.$axios.post(url, this.variantsIds);
                if (variantsResult.data.code === 1) {
                    this.variantsResult = variantsResult.data.data;
                }
                this.actionRunning = false;
                this.getTotals();
            } catch (error) {
                this.variantsResult = "An Error Occured - Please Contact Support";
                this.actionRunning = false;
                console.log(error);
            }
        },
        confirmMethodHandler(value) {
            this.confirmDialog = {};
            if (value.action === 2) {
                this[value.method](value.id);
            }
        },
        async getOptionsProgressUpdate(progressId) {
            let t = this;
            let optionsProgressResult = {};
            const myInterval = setInterval(async () => {
                optionsProgressResult = await this.MIX_redisReadHash("progress:" + progressId);
                t.optionsProgressResult = optionsProgressResult.data;
                if (optionsProgressResult.data.status === "Completed") {
                    clearInterval(myInterval);
                }
            }, 100);
        },
        async getAttributesProgressUpdate(progressId) {
            let t = this;
            let attributesProgressResult = {};
            const myInterval = setInterval(async () => {
                attributesProgressResult = await this.MIX_redisReadHash("progress:" + progressId);
                t.attributesProgressResult = attributesProgressResult.data;
                if (attributesProgressResult.data.status === "Completed") {
                    clearInterval(myInterval);
                }
            }, 100);
        },
        async getProductsProgressUpdate(progressId) {
            let t = this;
            let productsProgressResult = {};
            const myInterval = setInterval(async () => {
                productsProgressResult = await this.MIX_redisReadHash("progress:" + progressId);
                t.productsProgressResult = productsProgressResult.data;
                if (productsProgressResult.data.status === "Completed") {
                    clearInterval(myInterval);
                }
            }, 100);
        },
        async getVariantsProgressUpdate(progressId) {
            let t = this;
            let variantsProgressResult = {};
            const myInterval = setInterval(async () => {
                variantsProgressResult = await this.MIX_redisReadHash("progress:" + progressId);
                t.variantsProgressResult = variantsProgressResult.data;
                if (variantsProgressResult.data.status === "Completed") {
                    clearInterval(myInterval);
                }
            }, 100);
        },
    },
    watch: {
        selectedOrg: {
            handler: function () {
                this.optionsProgressResult = { status: "", count: "", totalItems: "" }
                this.attributesProgressResult = { status: "", count: "", totalItems: "" }
                this.productsProgressResult = { status: "", count: "", totalItems: "" }
                this.variantsProgressResult = { status: "", count: "", totalItems: "" }                
            },
            deep: true
        }
    },
    async created() {
        this.loading = true;
        let orgs = await this.MIX_organisations("Supplier");
        orgs = orgs.filter((x) => x.orgApproved === "1");
        this.orgs = orgs.map((x) => {
            return {
                orgId: x.id,
                orgName: x.orgName,
            };
        });
        this.loading = false;
    },
};
</script>
