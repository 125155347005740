<template>
    <div class="d-flex flex-column justify-start grey lighten-5" style="padding: 0px !important; margin: 0px !important; overflow-x: hidden !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 168px) !important;' : 'height: calc(100vh - 120px) !important;'">
        <!-- Menu Buttons -->
        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <div class="ml-3">
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" v-if="editmode" depressed @click="editmode = false"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="validateForm()" :large="!dense"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- Product Details View -->
        <v-row class="apptext--text px-3 pt-5" dense v-if="!editmode && !loading">
            <v-col cols="12" sm="8" class="">
                <div class="ml-4">
                    <span class="font-weight-bold">Product Name:</span>
                    <div class="text--secondary">{{ item.productName }}</div>
                    <div class="mt-2">
                        <span class="font-weight-bold">Code:</span> <span class="">{{ item.productSupplierCode }}</span> | <span class="font-weight-bold">Supplier:</span> <span class="">{{ item.orgName }}</span>
                    </div>
                    <div class="text--secondary">{{ item.productShortDescription }}</div>
                </div>
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-center align-center px-5 pb-3 grey lighten-5">
                <div v-if="item.productImageId !== ''" class="black d-flex align-center white pa-1" style="border: 1px solid lightgrey !important; border-radius: 5px !important"><v-img class="" width="250" height="250" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/' + item.productImageId + '.jpeg?x=' + Date.now()" /></div>
                <div v-if="item.productImageId === ''" class="black d-flex align-center white pa-1" style="border: 1px solid lightgrey !important; border-radius: 5px !important"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
            </v-col>
            <!-- Prouct Pricing -->
            <v-col cols="12" sm="12" class="grey lighten-5 mt-4 rounded">
                <div class="text-left primary--text text-h6 font-weight-light">Pricing</div>
                <v-simple-table :dense="dense" class="">
                    <template v-slot:default>
                        <thead>
                            <tr class="font-weight-bold">
                                <td>Cost</td>
                                <td>Sell</td>
                                <td>Margin %</td>
                                <td>Sell Margin</td>
                                <td>RRP</td>
                                <td>Min Order</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>£{{ item.cP1 }}</td>
                                <td>£{{ item.sP1 }}</td>
                                <td>{{ isNaN(((item.sP1 - item.cP1) / item.sP1) * 100) ? '0' : (((item.sP1 - item.cP1) / item.sP1) * 100).toFixed(2) }} %</td>
                                <td>£{{ (item.sP1 - item.cP1).toFixed(2) }}</td>
                                <td>£{{ item.RRP }}</td>
                                <td>{{ item.MOQ }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <!-- Dimensions Overview -->
            <v-col cols="12" sm="12" class="grey lighten-5 pa-3 mt-5 rounded" v-if="item.productDimensions !== ''">
                <v-simple-table :dense="dense">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left primary--text text-h6 font-weight-light" colspan="2">Dimensions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2">{{ item.productDimensions }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Width</td>
                                <td>{{ item.productWidth }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Height</td>
                                <td>{{ item.productHeight }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Depth</td>
                                <td>{{ item.productDepth }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Front Width</td>
                                <td>{{ item.productFrontWidth }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Back Width</td>
                                <td>{{ item.productBackWidth }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <!-- Product Meta Data -->
            <v-col cols="12" sm="12" class="grey lighten-5 mt-4">
                <div class="text-left primary--text text-h6 font-weight-light">Meta</div>
                <v-simple-table :dense="dense">
                    <template v-slot:default>
                        <thead>
                            <tr class="font-weight-bold">
                                <td>Created By</td>
                                <td>Craeted Date</td>
                                <td>Modified By</td>
                                <td>Modified Date</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ item.createdUserName }}</td>
                                <td>{{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YYYY | HH:MM:SS') }}</td>
                                <td>{{ item.modifiedUserName }}</td>
                                <td>{{ MIX_formatDateTime(item.modifiedDateTime, 'x', 'DD-MMM-YYYY | HH:MM:SS') }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <!-- Products Details Edit -->
        <v-row class="px-3" v-if="editmode && !loading">
            <v-col cols="12" sm="12" class="grey lighten-5 pa-3 pb-5" v-if="editmode">
                <!-- ITEM FORM -->
                <v-form ref="form" v-on:submit.prevent="login()" lazy-validation>
                    <v-row dense>
                        <!-- CLIENT -->
                        <v-col cols="12" xs="12" md="12">
                            <v-row no-gutters>
                                <!-- Form - Details -->
                                <v-col cols="12" xs="12" md="12" class="d-flex align-end">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                    <v-spacer />
                                    <div class="caption text-right"><strong>ID:</strong> {{ item.id }}</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 my-1"></v-divider>
                                </v-col>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class=""><v-checkbox label="Has Options" v-model="item.hasOptions" outlined :dense="dense" hide-details="auto" color="primary" true-value="1" false-value="0" /></div>
                                    <div class="ml-2"><v-checkbox label="Has Variants" v-model="item.hasVariants" outlined :dense="dense" hide-details="auto" color="primary" true-value="1" false-value="0" /></div>
                                    <div class="ml-2"><v-checkbox label="Is Extra" v-model="item.isExtra" outlined :dense="dense" hide-details="auto" color="primary" true-value="1" false-value="0" /></div>
                                    <div class="ml-2"><v-checkbox :disabled="item.isExtra === '1' ? false : true" label="Is Sold Separately" v-model="item.isExtraSoldSeparately" outlined :dense="dense" hide-details="auto" color="primary" true-value="1" false-value="0" /></div>
                                </v-col>
                                <!-- Form - Product Name -->
                                <v-col cols="12" xs="12" md="12" class="mt-3">
                                    <v-text-field label="Name" v-model="item.productName" :rules="rules.productName" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productName')"></v-text-field>
                                </v-col>
                                <!-- Form - Supplier Product Code -->
                                <v-col cols="12" xs="12" md="6" class="mt-3 pr-3">
                                    <v-text-field label="Supplier Product Code" v-model="item.productSupplierCode" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productSupplierCode')"></v-text-field>
                                    <span class="caption">{{ computedProductCode }}</span>
                                </v-col>
                                <!-- Form - Previous Code -->
                                <v-col cols="12" xs="12" md="6" class="mt-3">
                                    <v-text-field label="MOF Previous Code" v-model="item.productPreviousCode" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productPreviousCode')"></v-text-field>
                                    <span class="caption">{{ computedProductPreviousCode }}</span>
                                </v-col>
                                <!-- Form - Product Code -->
                                <!-- <v-col cols="12" xs="12" md="4" class="mt-3 pl-3">
                                    <v-text-field disabled label="Product Code" v-model="computedProductCode" :rules="rules.productCode" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productCode')"></v-text-field>
                                </v-col> -->
                                <!-- Form - Product Status -->
                                <v-col cols="12" xs="12" md="4" class="mt-3 pr-3">
                                    <v-select label="Status" v-model="item.productStatus" :items="productStatus" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productStatus')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                                </v-col>
                                <!-- Form - Product Type -->
                                <v-col cols="12" xs="12" md="4" class="mt-3 pr-3">
                                    <v-select label="Type" v-model="item.productType" :items="productTypes" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productType')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                                </v-col>
                                <v-col cols="12" md="4" class="mt-3">
                                    <v-text-field label="Lead Time Weeks" type="number" v-model="item.productLeadTime" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productLeadTime')"></v-text-field>
                                </v-col>
                                <!-- Form - Product Short Description -->
                                <v-col cols="12" xs="12" md="12" class="mt-3">
                                    <v-textarea label="Short Description" rows="2" v-model="item.productShortDescription" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productShortDescription')"></v-textarea>
                                </v-col>
                                <!-- Form - Supplier -->
                                <v-col cols="12" xs="12" md="12" class="mt-3">
                                    <div class="text-left primary--text text-h6 font-weight-light">Supplier</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" class="mt-3">
                                    <v-row class="">
                                        <v-col cols="12" xs="12" md="8">
                                            <v-autocomplete
                                                v-if="editmode"
                                                :items="orgs"
                                                item-text="orgName"
                                                return-object
                                                label="Organisation"
                                                v-model="selectedOrg"
                                                :rules="rules.productSupplier"
                                                auto-select-first
                                                @change="
                                                    item.orgName = selectedOrg.orgName;
                                                    item.orgId = selectedOrg.orgId;
                                                "
                                                @click:clear="clearSelectedOrg()"
                                                outlined
                                                :dense="dense"
                                                hide-details="auto"
                                                background-color="white"
                                                color="primary"
                                                clearable
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="4">
                                            <v-text-field label="Supplier ID" v-model="item.orgId" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <!-- Form - Product Long Description -->
                                <v-col cols="12" xs="12" md="12" class="mt-3 d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Long Description</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleDescription = !toggleDescription">
                                        <span v-if="!toggleDescription">Show</span>
                                        <span v-if="toggleDescription">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleDescription">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" class="mt-3" v-if="toggleDescription">
                                    <v-textarea label="Full Description" rows="2" v-model="item.productDescription" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productDescription')"></v-textarea>
                                </v-col>
                                <!-- Form - Dimensions -->
                                <v-col cols="12" xs="12" md="12" class="d-flex align-center mt-3">
                                    <div class="text-left primary--text text-h6 font-weight-light">Dimensions (mm)</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleDimensions = !toggleDimensions">
                                        <span v-if="!toggleDimensions">Show</span>
                                        <span v-if="toggleDimensions">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleDimensions">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" v-if="toggleDimensions" class="mt-3">
                                    <div class="d-flex align-center justify-start">
                                        <div class="priceblock">
                                            <v-text-field label="Width" v-model="item.productWidth" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productWidth')"></v-text-field>
                                        </div>
                                        <div class="ml-2 priceblock">
                                            <v-text-field label="Height" v-model="item.productHeight" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productHeight')"></v-text-field>
                                        </div>
                                        <div class="ml-2 priceblock">
                                            <v-text-field label="Depth" v-model="item.productDepth" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productDepth')"></v-text-field>
                                        </div>
                                        <div class="ml-2 priceblock">
                                            <v-text-field label="Front Width" v-model="item.productFrontWidth" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productFrontWidth')"></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center justify-start mt-3">
                                        <div class="priceblock">
                                            <v-text-field label="Back Width" v-model="item.productBackWidth" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productBackWidth')"></v-text-field>
                                        </div>
                                        <div class="ml-2 priceblock">
                                            <v-text-field label="Seat Height" v-model="item.productSeatHeight" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productSeatHeight')"></v-text-field>
                                        </div>
                                        <div class="ml-2 priceblock">
                                            <v-text-field label="Internal Leg Width" v-model="item.productInternalLegWidth" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productInternalLegWidth')"></v-text-field>
                                        </div>
                                        <div class="ml-2 priceblock">&nbsp;</div>
                                    </div>
                                    <v-textarea label="Dimensions Text" v-model="item.productDimensions" outlined :dense="dense" hide-details="auto" class="mt-3" background-color="white" color="primary" rows="1" clearable @click:clear="clearItem('productDimensions')"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
                <br />
            </v-col>
        </v-row>
        <v-spacer />
        <!-- Save / Cancel Buttons -->
        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 px-4">
                <div>
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="editmode = false"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <v-spacer />
                <div class="ml-2">
                    <v-btn class="accent primary--text font-weight-bold" depressed @click="saveItem"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        loading: true,
        editmode: false,
        rules: {},
        // item
        item: {},
        itemNew: false,
        orgs: [],
        selectedOrg: {
            orgName: '',
            orgId: '',
        },
        // Dropdowns
        productTypes: [],
        productStatus: [],
        // Toggle
        toggleDescription: false,
        toggleDimensions: false,
    }),
    // * METHODS
    methods: {
        // Clear Selected
        // clearSelectedOrganisation() {
        // 	setTimeout(() => {
        // 		this.selectedOrganisation = { id: "", orgName: "" };
        // 	}, 1);
        // },
        // Validate Form
        validateSelect(v) {
            if (JSON.stringify(v) === '{}') {
                return false;
            } else {
                return true;
            }
        },
        validateForm() {
            this.rules = {
                productName: [(v) => !!v || 'Product Name Required'],
                productCode: [(v) => !!v || 'Product Code Required'],
                productSupplier: [(v) => this.validateSelect(v) || 'Supplier is required'],
            };
            this.$nextTick(async () => {
                if (this.$refs.form.validate()) {
                    // check if product code exists
                    this.item.id = `${this.item.orgId}_${this.item.productCode}`;
                    let productExists = await this.MIX_redisExists(`product:${this.item.id}`);
                    if (productExists.code === 1 && this.itemNew) {
                        this.MIX_alertBox({ color: 'warning', timeout: 4000, message: 'Product Code Already Exists', show: true });
                    } else {
                        console.log('next Tick save')
                        this.saveItem();
                    }
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill out all the fields', show: true });
                }
            });
        },
        // Load Data Down
        loadDataDown() {
            let t = this;
            t.item = { ...t.datadown };
            t.selectedOrg = {
                orgId: t.item.orgId,
                orgName: t.item.orgName,
            };
            if (t.item.productName === '') {
                t.editmode = true;
            } else {
                t.editmode = false;
            }
            // console.log('t.selectedOrg = ' + JSON.stringify(t.selectedOrg));
            // t.item.productStatus = 'Live';
        },
        // Save Item
        saveItem() {
            this.item.productCode = this.computedProductCode;
            this.item.productPreCode = this.computedProductPreviousCode;
            //  console.log('this.item' + JSON.stringify(this.item, null, 2))
            this.$emit('saveitem', this.item);
            this.editmode = false;
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
    },
    // * COMPUTED
    computed: {
        // computedProductCategories() {
        // 	return this.pgsr.productCategory.map(category => category.name);
        // },
        computedProductCode() {
            // return this.item.productSupplierCode with no special characters or spaces
            return this.item.productSupplierCode.replace(/[^a-zA-Z0-9]/g, '');
        },     
        computedProductPreviousCode() {
            // return this.item.productPreviousCode with no special characters or spaces
            return this.item.productPreviousCode.replace(/[^a-zA-Z0-9]/g, '');
        }            
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.loadDataDown();
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.loading = true;
        // Get Product Types
        let productTypesResult = await t.MIX_redisReadSet('set:productTypes', true);
        if (productTypesResult.code === 1) {
            t.productTypes = productTypesResult.data;
        }
        let productStatusResult = await t.MIX_redisReadSortedSet('sset:productStatus', 0, 99999);
        if (productStatusResult.code === 1) {
            t.productStatus = productStatusResult.data;
        }
        let orgs = await this.MIX_organisations('Supplier');
        this.orgs = orgs.map((x) => {
            return {
                orgId: x.id,
                orgName: x.orgName,
            };
        });
        // Load the Data into Item
        this.loadDataDown();
        t.loading = false;
        //console.log('this.orgs = ' + JSON.stringify(this.orgs, null, 2))
    },
    // * MOUNTED
    async mounted() {},
};
</script>
