<template>
	<div style="padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow: scroll !important">
		<!-- Menu Buttons -->
		<v-row class="apptext--text" dense>
			<v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
				<div class="ml-3 d-flex align-center" v-if="loading">
					<v-progress-circular indeterminate color="accent"></v-progress-circular>
					<div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
				</div>
				<!-- TODO - RG - Quote - Convert To Order -->
				<!-- <div class="ml-3">
                    <v-btn :disabled="true" text class="grey lighten-5 secondary--text" @click="editmode = true"><v-icon class="mr-2">icons8-receipt-approved</v-icon>Convert to Order</v-btn>
                </div> -->
				<v-spacer />
				<!-- <div class="ml-3" v-if="['Draft','Declined'].includes(item.quoteStatus)">
                    <v-btn :disabled="editmode" text class="grey lighten-5 secondary--text" @click="ready"><v-icon class="mr-2">icons8-ok</v-icon>Ready</v-btn>
                </div> -->

				<!-- <div class="ml-3" v-if="Number(item.totalSell) > 50000 &&  item.quoteStatus === 'Awaiting Approval' && ['Developer-Admin','Staff-Directors'].includes(userLevel)">
                    <v-btn text class="primary white--text" @click="approveQuote" ><v-icon class="mr-2">icons8-verified-badge</v-icon>Approve Quote</v-btn>
                </div> -->

				<!-- <div class="ml-3" 
                    
                    v-if="item.quoteStatus === 'Awaiting Approval' && 
                          item.quoteFirstLevelStatus === 'Awaiting Approval' &&
                          firstLevelApprovalUsers.includes(GET_FIREBASE_userAccessToken.user_id)
                ">
                    <v-btn text :disabled="quoteReadyForApproval == false" class="grey lighten-5 secondary--text" @click="approveQuoteFirstLevel"><v-icon class="mr-2">icons8-verified-badge</v-icon>Approve Quote</v-btn>
                </div>
                <div class="ml-3" 
                   
                    v-if="item.quoteStatus === 'Awaiting Approval' && 
                          item.quoteApprovalLevel == '2' &&
                          secondLevelApprovalUsers.includes(GET_FIREBASE_userAccessToken.user_id) && 
                          item.quoteFirstLevelStatus == 'Approved'
                    ">
                    <v-btn :disabled="!quoteReadyForApproval" text class="grey lighten-5 secondary--text" @click="approveQuoteSecondLevel"><v-icon class="mr-2">icons8-verified-badge</v-icon>Approve Quote</v-btn>
                </div> -->

				<!-- <div class="ml-3" v-if="Number(item.totalSell) > 50000 &&  item.quoteStatus === 'Awaiting Approval' && ['Developer-Admin','Staff-Directors'].includes(userLevel)">
                    <v-btn text class="yellow secondary--text" @click="declineQuote" ><v-icon class="mr-2">icons8-box-important</v-icon>Decline Quote</v-btn>
                </div> -->
				<!-- <div class="ml-3" v-if="  item.quoteStatus === 'Ready'">
                    <v-btn text class="grey lighten-5 secondary--text" @click="changeQuoteStatus('Sent')"><v-icon class="mr-2">icons8-mail</v-icon>Send</v-btn>
                </div>      -->
				<!-- <div class="ml-3" v-if="  item.quoteStatus === 'Sent'">
                    <v-btn text class="success white--text" @click="changeQuoteStatus('Accepted')"><v-icon class="mr-2">icons8-ok</v-icon>Accept</v-btn>
                </div>      -->
				<!-- <div class="ml-3" v-if="  item.quoteStatus === 'Sent'">
                    <v-btn text class="error white--text" @click="changeQuoteStatus('Rejected')"><v-icon class="mr-2">icons8-cancel</v-icon>Reject</v-btn>
                </div>      -->
				<div class="ml-3">
					<!-- || item.quoteCurrentVersion === '0' -->
					<v-btn :disabled="['Sent', 'Accepted', 'Ready', 'Rejected'].includes(item.quoteStatus) && !['Developer-Admin', 'Staff-Directors'].includes(userLevel)" text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true">
						<v-icon class="mr-2">icons8-edit</v-icon>
						Edit
					</v-btn>
					<v-btn :disabled="['Sent', 'Accepted', 'Rejected'].includes(item.quoteStatus) && !['Developer-Admin', 'Staff-Directors'].includes(userLevel)" text class="accent secondary--text" v-if="editmode" @click="validateForm()"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
				</div>
				<!-- <div class="ml-3">
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="validateForm()"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                </div> -->
				<div class="mx-2 mr-3">
					<v-menu offset-y :ref="item.id" :value="false" class="white" rounded style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
						<template v-slot:activator="{ on, attrs }">
							<v-btn :disabled="editmode" text class="grey lighten-5 error--text" v-bind="attrs" v-on="on"><v-icon color="primary">icons8-menu-vertical</v-icon></v-btn>
						</template>
						<div class="white mt-1" style="width: 250px; max-height: 300px">
							<div class="pa-5 white" style="width: 100% !important">
								<div class="my-1">
									<v-btn :disabled="['Sent', 'Accepted', 'Rejected'].includes(item.quoteStatus)" @click="quoteRevision()" text class="grey--text text--darken-2"><v-icon class="mr-2">icons8-versions</v-icon>Create Revision</v-btn>
								</div>
								<div class="my-1">
									<v-btn @click="quoteCopy()" text class="grey--text text--darken-2"><v-icon class="mr-2">icons8-copy</v-icon>Copy to New</v-btn>
								</div>
								<!-- <div class="my-1">
                                    <v-btn disabled text class="error--text"><v-icon class="mr-2">icons8-trash-can</v-icon>Delete</v-btn>
                                </div> -->
							</div>
						</div>
					</v-menu>
				</div>
			</v-col>
		</v-row>
		<v-row class="apptext--text pt-3 px-3" dense v-if="!editmode && !loading">
			<!-- Quote Info Table -->
			<v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 rounded">
				<v-simple-table :dense="dense">
					<template v-slot:default>
						<thead>
							<tr>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Quote No</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Rev</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Status</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Approved</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Quote Date</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Expires</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Requested Delivery</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="apptext--text font-weight-normal body-1 white" align="left">{{ item.quoteNumber }}</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">
									<span v-if="item.quoteVersion > 0">Rev. {{ quoteVersion }}</span>
								</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">{{ item.quoteStatus }}</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">
									<span class="success--text" v-if="item.approved == '1'"> <v-icon class="success--text">icons8-ok</v-icon> Yes </span>
									<span class="warning--text" v-if="item.approved == '0'"> <v-icon class="warning--text">icons8-box-important</v-icon> No </span>
								</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">{{ MIX_formatDateTime(item.quoteDate, "X", "DD-MMM-YYYY") }}</td>
								<td :class="MIX_formatDateTime(item.quoteExpiryDate, 'X', 'X') < MIX_formatDateTime('', 'X', 'X') ? 'error--text' : 'success--text'" class="font-weight-normal body-1 white" align="left">{{ MIX_formatDateTime(item.quoteExpiryDate, "X", "DD-MMM-YYYY") }}</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">
									<span v-if="item.quoteDeliveryDate !== '0'">{{ MIX_formatDateTime(item.quoteDeliveryDate, "X", "DD-MMM-YYYY") }}</span
									><span v-else-if="item.quoteDeliveryDate === '0'">TBC</span>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<v-simple-table :dense="dense">
					<template v-slot:default>
						<thead>
							<tr>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Line Items</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Total Cost</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Total Sell</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Sell Margin</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">Profit Margin</td>
								<td class="grey lighten-2 secondary--text apptext--text font-weight-bold" align="left">NPS Pricing</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="apptext--text font-weight-normal body-1 white" align="left">{{ item.totalItems }}</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.totalCost) }}</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.totalSell) }}</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.totalSellMargin) }}</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">{{ item.totalMargin }} %</td>
								<td class="apptext--text font-weight-normal body-1 white" align="left">
									<span v-if="item.quoteNPS === '1'">Yes</span>
									<span v-if="item.quoteNPS === '0'">No</span>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
		<!-- Quote Details View -->
		<v-row class="apptext--text px-3 mt-3" dense v-if="!editmode && !loading">
			<v-col cols="12" sm="12" class="my-1">
				<v-row class="grey lighten-5 pb-3 rounded" no-gutters fill-height>
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<div class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">Details</div>
						</div>
						<v-divider class="grey lighten-2 mb-1"></v-divider>
					</v-col>
					<!-- Title / Type / Channel -->
					<v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2">
						<v-simple-table :dense="dense">
							<template v-slot:default>
								<tbody>
									<tr>
										<td width="25%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Title:</td>
										<td width="75%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.quoteTitle }}</td>
									</tr>
									<tr>
										<td width="25%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Type:</td>
										<td width="75%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.quoteType }}</td>
									</tr>
									<tr>
										<td width="25%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Channel:</td>
										<td width="75%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.quoteChannel }}</td>
									</tr>
									<tr>
										<td width="25%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Win %:</td>
										<td width="75%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.quoteWinPercentage }}%</td>
									</tr>
                  <tr>
                    <td width="25%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Quote:</td>
                    <td width="75%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2"><a @click="goToQuote">Click here to go to original quote</a></td>
                  </tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row class="apptext--text px-3" dense v-if="!editmode && !loading">
			<!-- Customer -->
			<v-col cols="12" sm="12" md="6" class="my-1">
				<v-row class="grey lighten-5 rounded pa-2 pb-4" no-gutters fill-height>
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<div class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">Customer</div>
							<v-spacer />
							<div>
								<v-btn x-small class="elevation-0 ml-1" height="40"><v-icon class="primary--text">icons8-city</v-icon></v-btn>
							</div>
						</div>

						<v-divider class="grey lighten-2 mb-1"></v-divider>
					</v-col>
					<v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2">
						<v-simple-table :dense="dense">
							<template v-slot:default>
								<tbody>
									<tr>
										<td width="50%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Name:</td>
										<td width="50%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgName }}</td>
									</tr>
									<tr>
										<td width="50%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Reference:</td>
										<td width="50%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.quoteClientRef }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
			</v-col>
			<!-- Quote Client -->
			<v-col cols="12" sm="12" md="6" v-if="!editmode && !loading" class="my-1">
				<v-row class="grey lighten-5 rounded pa-2 pb-4" no-gutters fill-height>
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<span class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">Person</span>
							<v-spacer />
							<div>
								<v-btn x-small class="elevation-0 ml-1" height="40"><v-icon class="primary--text">icons8-identification-documents</v-icon></v-btn>
							</div>
						</div>
						<v-divider class="grey lighten-2 mb-1"></v-divider>
					</v-col>
					<v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2">
						<v-simple-table :dense="dense">
							<template v-slot:default>
								<tbody>
									<tr>
										<td width="50%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Name:</td>
										<td width="50%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.contactName }}</td>
									</tr>
									<tr>
										<td width="50%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Email:</td>
										<td width="50%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.contactEmail }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
			</v-col>
			<!-- Invoice Address -->
			<v-col cols="12" sm="12" md="6" v-if="!editmode && !loading" class="my-1">
				<v-row class="grey lighten-5 rounded pa-2 pb-4" no-gutters fill-height>
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<span class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">Invoice Address</span>
							<v-spacer />
							<v-btn x-small class="elevation-0 ml-1" height="40"><v-icon class="primary--text">icons8-map-marker</v-icon></v-btn>
						</div>
						<v-divider class="grey lighten-2 mb-1"></v-divider>
					</v-col>
					<v-col cols="8" class="font-weight-normal grey--text text--darken-3 body-1 pl-4">
						<span v-if="item.orgInvoiceAddressLine1"
							>{{ item.orgInvoiceAddressLine1 }}<span v-if="item.orgInvoiceAddressLine2">, {{ item.orgInvoiceAddressLine3 }}</span
							><br
						/></span>
						<span v-if="item.orgInvoiceAddressLine3">{{ item.orgInvoiceAddressLine3 }}<br /></span>
						<span v-if="item.orgInvoiceTown"
							>{{ item.orgInvoiceTown }}<span v-if="item.orgInvoiceCounty">, {{ item.orgInvoiceCounty }}</span
							><br
						/></span>
						<span v-if="item.orgInvoicePostcode">{{ item.orgInvoicePostcode }}<br /></span>
					</v-col>
				</v-row>
			</v-col>
			<!-- Deliery Address -->
			<v-col cols="12" sm="12" md="6" v-if="!editmode && !loading" class="my-1">
				<v-row class="grey lighten-5 rounded pa-2 pb-4" no-gutters fill-height>
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<span class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">Delivery Address</span>
							<v-spacer />
							<v-btn x-small class="elevation-0 ml-1" height="40"><v-icon class="primary--text">icons8-map-marker</v-icon></v-btn>
						</div>
						<v-divider class="grey lighten-2 mb-1"></v-divider>
					</v-col>
					<v-col cols="8" class="font-weight-normal grey--text text--darken-3 body-1 pl-4">
						<span v-if="item.orgDeliveryAddressLine1"
							>{{ item.orgDeliveryAddressLine1 }} <span v-if="item.orgDeliveryAddressLine2">, {{ item.orgDeliveryAddressLine3 }}</span
							><br
						/></span>
						<span v-if="item.orgDeliveryAddressLine3">{{ item.orgDeliveryAddressLine3 }}<br /></span>
						<span v-if="item.orgDeliveryTown"
							>{{ item.orgDeliveryTown }}<span v-if="item.orgDeliveryCounty">, {{ item.orgDeliveryCounty }}</span
							><br
						/></span>
						<span v-if="item.orgDeliveryPostcode">{{ item.orgDeliveryPostcode }}<br /></span>
					</v-col>
				</v-row>
			</v-col>
			<!-- Internal Note -->
			<v-col cols="12" sm="12" md="12" v-if="!editmode && !loading && item.quoteInternalNote !== ''">
				<v-row class="grey lighten-5 rounded pa-2 pb-4" no-gutters fill-height>
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<span class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">Internal Note</span>
						</div>
						<v-divider class="grey lighten-2 mb-1"></v-divider>
					</v-col>
					<v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-4">
						{{ item.quoteInternalNote }}
					</v-col>
				</v-row>
			</v-col>
			<!-- Customer Note -->
			<v-col cols="12" sm="12" md="12" v-if="!editmode && !loading && item.quoteCustomerNote !== ''">
				<v-row class="grey lighten-5 rounded pa-2 pb-4" no-gutters fill-height>
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<span class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">Customer Note</span>
						</div>
						<v-divider class="grey lighten-2 mb-1"></v-divider>
					</v-col>
					<v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-4">
						{{ item.quoteCustomerNote }}
					</v-col>
				</v-row>
			</v-col>
			<!-- Organisation Tags -->
			<v-col cols="12" sm="12" md="12" v-if="!editmode && !loading">
				<v-row class="grey lighten-5 rounded pa-2 pb-4" no-gutters fill-height>
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<span class="text-left primary--text text-h6 font-weight-light pl-2 pt-2" colspan="12">Organisation Tags</span>
						</div>
						<v-divider class="grey lighten-2 mb-1"></v-divider>
					</v-col>
					<v-col cols="12" class="font-weight-normal grey--text text--darken-3 body-1 pl-2 mt-2" v-if="item.orgTags !== undefined">
						<v-chip color="grey lighten-3" label class="mr-1" v-for="id in item.orgTags.split(',')" :key="id">{{ lookupOrganisation(id) }}</v-chip>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" class="mt-5"> </v-col>
		</v-row>
		<!-- QUOTE FORM -->
		<v-form ref="form" v-on:submit.prevent="login()" lazy-validation>
			<v-row class="px-3 mt-3" v-if="editmode && !loading">
				<v-col cols="12" sm="12" md="12">
					<v-row dense>
						<v-col cols="12" sm="12" class="d-flex py-0 body-2 grey--text text--darken-2 text-left">
							<div>* Required Fields</div>
							<v-spacer />
							<div class="caption text-right"><strong>ID:</strong> {{ item.id }}</div>
						</v-col>

						<!-- CLIENT -->
						<v-col cols="12" sm="12">
							<div class="text-left primary--text text-h6 font-weight-light">Customer</div>
							<v-divider class="grey lighten-1 mb-1"></v-divider>
						</v-col>
						<v-col cols="12" sm="6" class="d-flex align-center my-1">
							<v-autocomplete label="Customer" v-model="selectedOrganisation" :items="organisations" :rules="rules.quoteOrganisation" item-text="orgName" auto-select-first return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOrganisation()"></v-autocomplete>
							<v-btn @click="dialogOrganisation = true" class="ml-2 secondary white--text" depressed>New</v-btn>
						</v-col>
						<v-col cols="12" sm="6" class="d-flex align-start my-1">
							<v-text-field label="Customer Ref." v-model="item.quoteClientRef" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('quoteClientRef')"> </v-text-field>
						</v-col>
						<v-col cols="12" sm="12" class="d-flex align-center">
							<v-autocomplete label="Contact" v-model="selectedContact" :items="contacts" no-data-text="Please Select Customer First" item-text="contactName" auto-select-first return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedContact()">
								<template v-slot:selection="{ item }">
									{{ item.contactName }}
									<div class="ml-2 caption grey--text">{{ item.contactEmail }}</div>
								</template>
								<template v-slot:item="{ item }">
									{{ item.contactName }}
									<div class="ml-2 caption grey--text">{{ item.contactEmail }}</div>
								</template>
							</v-autocomplete>
							<v-btn @click="dialogContact = true" class="ml-2 secondary white--text" depressed>New</v-btn>
						</v-col>
					</v-row>
				</v-col>
				<!-- Details -->
				<v-col cols="12" sm="12" md="12" v-if="editmode">
					<v-row dense>
						<v-col cols="12" sm="12">
							<div class="text-left primary--text text-h6 font-weight-light">Details</div>
							<v-divider class="grey lighten-1 mb-1"></v-divider>
						</v-col>
						<v-col cols="12" sm="9" class="my-1">
							<v-text-field label="Quote Title" v-model="item.quoteTitle" :rules="rules.quoteTitle" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('quoteTitle')"> </v-text-field>
						</v-col>
						<v-col cols="12" sm="3" class="my-1">
							<v-text-field label="Quote No. will be generated" v-model="item.quoteNumber" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" disabled> </v-text-field>
						</v-col>
						<v-col cols="12" sm="4" class="my-1">
							<v-select label="Quote Type" v-model="item.quoteType" :items="quoteTypes" :rules="rules.quoteType" item-text="orgName" return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteType')"> </v-select>
						</v-col>
						<v-col cols="12" sm="4" class="my-1">
							<v-select label="Channel" v-model="item.quoteChannel" :items="segments" :rules="rules.quoteChannel" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteChannel')"> </v-select>
						</v-col>
						<v-col cols="12" sm="4" class="my-1 d-flex align-center">
							<!-- <v-checkbox  label="NPS Pricing" v-model="item.quoteNPS" true-value="1" false-value="0" class="pa-0 ma-0" hide-details="auto"></v-checkbox> -->
						</v-col>
						<v-col cols="12" sm="6" class="my-1">
							<v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field dense background-color="white" outlined v-model="computedDate1" label="Quote Date" hide-details="auto" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="date1" no-title @input="dateMenu1 = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" xs="12" sm="6" class="my-1">
							<v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field dense background-color="white" outlined v-model="computedDate2" label="Quote Expiry Date" hide-details="auto" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="date2" no-title @input="dateMenu2 = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="6" class="my-1">
							<v-select label="Requested Delivery Date" v-model="requestedDeliveryDate" :items="requestedDeliveryDateOptions" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteChannel')"> </v-select>
						</v-col>
						<v-col cols="12" xs="12" sm="6" class="my-1">
							<v-menu v-if="requestedDeliveryDate == 'Date'" v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field dense background-color="white" outlined v-model="computedDate3" label="Requested Delivery Date" hide-details="auto" append-icon="mdi-calendar" clearable @click:clear="date3 = ''" readonly v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="date3" no-title @input="dateMenu3 = false"></v-date-picker>
							</v-menu>
						</v-col>

						<v-col cols="12" sm="12" class="my-1 d-flex align-end">
							<div class="font-weight-light grey--text text--darken-2 pr-3 pb-1">Quote Win Percentage:</div>
							<div class="font-weight-medium grey--text text--darken-2 pr-3 pb-1">{{ item.quoteWinPercentage }}%</div>
							<v-slider v-model="item.quoteWinPercentage" class="align-center mt-4" thumb-label thumb-size="25" hide-details></v-slider>
						</v-col>

						<v-col cols="12" xs="12" sm="12" class="my-1" v-if="!quoteDatesValid">
							<div class="warning--text font-weight-bold">Approval Required for Expiry Dates greater than 30 days</div>
						</v-col>
						<!-- OWNER -->
						<v-col cols="12" sm="12" md="6" class="my-1">
							<v-autocomplete label="Owner" v-model="selectedOwner" :items="users" item-text="ownerUserName" auto-select-first return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOwner()">
								<template v-slot:selection="{ item }">
									{{ item.ownerUserName }}
									<div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
								</template>
								<template v-slot:item="{ item }">
									{{ item.ownerUserName }}
									<div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
								</template>
							</v-autocomplete>
						</v-col>
						<!-- PREPARED BY -->
						<v-col cols="12" sm="12" md="6" class="my-1">
							<v-autocomplete label="Prepared By" v-model="selectedPreparedBy" :items="preparedByUsers" item-text="preparedByUserName" auto-select-first return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedPreparedBy()">
								<template v-slot:selection="{ item }">
									{{ item.preparedByUserName }}
									<div class="ml-2 caption grey--text">{{ item.preparedByUserEmail }}</div>
								</template>
								<template v-slot:item="{ item }">
									{{ item.preparedByUserName }}
									<div class="ml-2 caption grey--text">{{ item.preparedByUserEmail }}</div>
								</template>
							</v-autocomplete>
						</v-col>
					</v-row>
				</v-col>
				<!-- ADDRESSES -->
				<v-col cols="12" xs="12" sm="6" v-if="editmode">
					<div class="d-flex align-center">
						<div class="text-left primary--text text-h6 font-weight-light">Invoice Address</div>
						<v-spacer />
					</div>
					<v-divider class="grey lighten-1 mb-5"></v-divider>
					<v-autocomplete class="my-3" v-model="selectedInvoiceAddress" @change="updateSelectedInvoiceAddress" auto-select-first label="Select Address" :items="addresses" return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearInvoiceAddress()">
						<template v-slot:selection="{ item }">
							{{ item.addressName }}
							<div class="ml-2 caption grey--text">{{ item.addressType }}</div>
						</template>
						<template v-slot:item="{ item }">
							{{ item.addressName }}
							<div class="ml-2 caption grey--text">{{ item.addressType }}</div>
						</template>
					</v-autocomplete>
					<v-text-field label="Address Line 1" class="mb-2" v-model="item.orgInvoiceAddressLine1" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInvoiceAddressLine1')"> </v-text-field>
					<v-text-field label="Address Line 2" class="mb-2" v-model="item.orgInvoiceAddressLine2" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInvoiceAddressLine2')"> </v-text-field>
					<v-text-field label="Address Line 3" class="mb-2" v-model="item.orgInvoiceAddressLine3" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInvoiceAddressLine3')"> </v-text-field>
					<v-text-field label="Town" class="mb-2" v-model="item.orgInvoiceTown" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInvoiceTown')"> </v-text-field>
					<v-text-field label="County" class="mb-2" v-model="item.orgInvoiceCounty" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInvoiceCounty')"> </v-text-field>
					<v-text-field label="Postcode" v-model="item.orgInvoicePostcode" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInvoicePostcode')"> </v-text-field>
				</v-col>
				<v-col cols="12" xs="12" sm="6" v-if="editmode">
					<div class="d-flex align-center">
						<div class="text-left primary--text text-h6 font-weight-light">Delivery Address</div>
						<v-spacer />
					</div>
					<v-divider class="grey lighten-1 mb-5"></v-divider>
					<v-autocomplete class="my-3" v-model="selectedDeliveryAddress" @change="updateSelectedDeliveryAddress" auto-select-first label="Select Address" :items="addresses" return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearDeliveryAddress()">
						<template v-slot:selection="{ item }">
							{{ item.addressName }}
							<div class="ml-2 caption grey--text">{{ item.addressType }}</div>
						</template>
						<template v-slot:item="{ item }">
							{{ item.addressName }}
							<div class="ml-2 caption grey--text">{{ item.addressType }}</div>
						</template>
					</v-autocomplete>
					<v-text-field label="Address Line 1" class="mb-2" v-model="item.orgDeliveryAddressLine1" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgDeliveryAddressLine1')"> </v-text-field>
					<v-text-field label="Address Line 2" class="mb-2" v-model="item.orgDeliveryAddressLine2" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgDeliveryAddressLine2')"> </v-text-field>
					<v-text-field label="Address Line 3" class="mb-2" v-model="item.orgDeliveryAddressLine3" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgDeliveryAddressLine3')"> </v-text-field>
					<v-text-field label="Town" class="mb-2" v-model="item.orgDeliveryTown" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgDeliveryTown')"> </v-text-field>
					<v-text-field label="County" class="mb-2" v-model="item.orgDeliveryCounty" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgDeliveryCounty')"> </v-text-field>
					<v-text-field label="Postcode" v-model="item.orgDeliveryPostcode" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgDeliveryPostcode')"> </v-text-field>
				</v-col>
				<!-- ORGANISATION TAGS -->
				<v-col cols="12" sm="12" v-if="editmode">
					<div class="text-left primary--text text-h6 font-weight-light">Organisation Tags</div>
					<v-divider class="grey lighten-1 mb-1"></v-divider>
					<v-autocomplete class="mt-3" multiple small-chips auto-select-first label="Organisation Tags" v-model="orgTags" :search-input.sync="orgTagAutocomplete" @change="orgTagAutocomplete = ''" :items="organisations" item-text="orgName" item-value="id" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteChannel')">
						<template v-slot:selection="{ attrs, item, parent, selected }">
							<v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
								<span class="pr-2">
									{{ item.orgName }}
								</span>
								<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
							</v-chip>
						</template>
					</v-autocomplete>
				</v-col>
				<!-- Description Edit -->
				<v-col cols="12" sm="12" md="12" v-if="editmode">
					<v-row>
						<v-col cols="12" sm="12">
							<div class="text-left primary--text text-h6 font-weight-light">Internal Note</div>
							<v-divider class="grey lighten-1 mb-1"></v-divider>
							<v-textarea class="mt-3" rows="3" label="Internal Note" v-model="item.quoteInternalNote" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('quoteDescription')"> </v-textarea>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="12" md="12" v-if="editmode">
					<v-row>
						<v-col cols="12" sm="12">
							<div class="text-left primary--text text-h6 font-weight-light">Customer Note (Displayed on Quote)</div>
							<v-divider class="grey lighten-1 mb-1"></v-divider>
							<v-textarea class="mt-3" rows="3" label="Customer Note" v-model="item.quoteCustomerNote" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('quoteDescription')"> </v-textarea>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="12" md="12" v-if="editmode && ['Developer-Admin', 'Staff-Directors', 'Staff-Manager'].includes(userLevel)">
					<v-row>
						<v-col cols="12" sm="12">
							<div class="text-left primary--text text-h6 font-weight-light">Status and Approval</div>
							<v-divider class="grey lighten-1 mb-1"></v-divider>
						</v-col>
						<v-col cols="12" sm="4" class="my-1">
							<v-select label="Status" v-model="item.quoteStatus" :items="quoteStatus" :rules="rules.quoteStatus" outlined :dense="dense" hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteStatus')"> </v-select>
						</v-col>
						<v-col cols="12" sm="4" class="my-1 d-flex align-center">
							<v-checkbox label="Approved" v-model="item.approved" true-value="1" false-value="0" hide-details="auto" class="pa-0 ma-0 mr-3"></v-checkbox>
						</v-col>
					</v-row>
				</v-col>

				<v-col v-if="editmode" cols="12" class="mt-3 mb-5 grey darken-1 pa-3 d-flex justify-right align-center">
					<v-spacer />
					<div>
						<v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
					</div>
					<div class="ml-2">
						<v-btn class="accent primary--text font-weight-bold" depressed @click="validateForm()"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
					</div>
				</v-col>
				<!-- <v-col cols="12">
				<pre class="caption" style="max-width: 300px !important; overflow-y:hidden;">{{ item }}</pre>
			</v-col> -->
			</v-row>
		</v-form>
		<!-- // * ADD NEW ADDRESS -->
		<v-dialog v-model="dialogOrganisation" width="1024">
			<v-card class="mx-auto rounded">
				<organisation v-on:updateQuote="updateQuote" :datadown="{}" v-on:closeitem="closeItem" orgtype="Customer" :key="dialogOrganisationKey" />
			</v-card>
		</v-dialog>
		<!-- dialogContact -->
		<v-dialog v-model="dialogContact" width="1024">
			<v-card class="mx-auto rounded">
				<contact v-on:updateQuote="updateQuoteContact" :datadown="{}" :org="selectedOrganisation" v-on:closeitem="closeContact" orgtype="Customer" :key="dialogContactKey" />
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import organisation from "@/views/organisations/organisation.vue";
import contact from "@/views/contacts/contact.vue";
import { mapGetters } from "vuex";
export default {
	// * COMPONENTS
	components: {
		organisation,
		contact,
	},
	// * PROPS
	props: {
		dense: {
			type: Boolean,
			default: false,
		},
		datadown: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	// * DATA
	data: (vm) => ({
		loading: true,
		// Dates
		date1: vm.$moment().format("YYYY-MM-DD"),
		date2: vm.$moment().add(14, "days").format("YYYY-MM-DD"),
		date3: vm.$moment().format("YYYY-MM-DD"),
		dateMenu1: false,
		dateMenu2: false,
		dateMenu3: false,
		quoteDatesValid: true,
		// editmode
		editmode: false,

		// Toggles
		toggleJSON: false,
		toggleForm: true,
		toggleInvoiceAddress: false,
		toggleDeliveryAddress: false,

		// dialogs
		dialogOrganisation: false,
		dialogOrganisationKey: 0,

		dialogContact: false,
		dialogContactKey: 0,

		// Autocomplete
		orgTagAutocomplete: "",
		orgTags: [],

		firstLevelApprovalUsers: ["DGOGjDzCmnZLDHfv0BWR2G1SobT2"],
		secondLevelApprovalUsers: ["DGOGjDzCmnZLDHfv0BWR2G1SobT2"],
		quoteReadyForApproval: false,

		menu: false,
		model: [
			{
				text: "Foo",
				color: "blue",
			},
		],
		x: 0,
		search: null,
		y: 0,

		item: {},

		// Organisation Dropdown
		organisations: [], // Required for organisations dropdown
		selectedOrganisation: null,
		// {
		//     orgId: '',
		//     orgName: '',
		// },
		organisation: {}, // Required to get Address

		addresses: [],
		contacts: [], // Required for contacts dropdown
		salesUsers: [], // Required for sales user dropdown

		users: [],
		preparedByUsers: [],
		selectedInvoiceAddress: {},
		selectedDeliveryAddress: {},

		// TODO - RG - Implement Tags / Organisation Tags
		quoteTags: [], // Required for quote tags dropdown

		rules: {},

		segments: [],
		quoteTypes: [],
		quoteValidity: [],
		quoteStatus: [],
		quoteStandardTerms: "",
		selectedTags: [],
		selectedContact: {
			id: "",
			contactName: "",
			contactEmail: "",
		},
		selectedOwner: {
			ownerUserId: "",
			ownerUserName: "",
			ownerUserEmail: "",
		},
		selectedPreparedBy: {
			preparedByUserId: "",
			preparedByUserName: "",
			preparedByUserEmail: "",
		},
		requestedDeliveryDate: "",
		requestedDeliveryDateOptions: ["TBC", "Date"],
	}),
	// * COMPUTED
	computed: {
		...mapGetters({
			GET_taskBar: "GET_taskBar",
			GET_FIREBASE_userAccessToken: "firebase_auth_store/GET_FIREBASE_userAccessToken",
		}),
		userLevel() {
			return this.GET_FIREBASE_userAccessToken.user_level;
		},
		userGroup() {
			return this.GET_FIREBASE_userAccessToken.user_group;
		},
		userStatus() {
			return this.GET_FIREBASE_userAccessToken.user_status;
		},

		// Computed Dates
		computedDate1() {
			return this.$moment(this.date1, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},
		computedDate2() {
			return this.$moment(this.date2, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},
		computedDate3() {
			return this.$moment(this.date3, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},
		quoteVersion() {
			const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

			var version = this.item.quoteVersion;
			var revision = "";

			version = version - 1;

			revision = alphabet[version];

			return revision;
		},
	},
	// * METHODS
	methods: {
		// Quote Revision
		async quoteRevision() {
			let revisionResult = await this.MIX_quoteRevision("order:" + this.item.id);
			if (revisionResult.code === 1) {
				this.MIX_go(`/quotes/${revisionResult.data}`);
				this.MIX_alertBox({ color: "success", timeout: 2000, message: "Revision Created", show: true });
			} else {
				this.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Creating Revision", show: true });
			}
		},
		// Quote Copy
		async quoteCopy() {
			let copyResult = await this.MIX_quoteCopy("order:" + this.item.id);
			if (copyResult.code === 1) {
				this.MIX_go(`/quotes/${copyResult.data}`);
				this.MIX_alertBox({ color: "success", timeout: 2000, message: "Copy Created", show: true });
			} else {
				this.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Creating Copy", show: true });
			}
		},
		// Validate Form
		validateForm() {
			this.rules = {
				quoteOrganisation: [(v) => !!v || "Organisation Required"],
				quoteTitle: [(v) => !!v || "Quote Title Required"],
				quoteStatus: [(v) => !!v || "Status Required"],
				quoteType: [(v) => !!v || "Type Required"],
				quoteChannel: [(v) => !!v || "Channel Required"],
				quoteDate: [(v) => !!v || "Quote Date Required"],
			};
			this.$nextTick(() => {
				if (this.$refs.form.validate()) {
					this.saveItem();
				} else {
					this.MIX_alertBox({ color: "error", timeout: 4000, message: "Please fill out all the fields", show: true });
				}
			});
		},
		saveItem() {
			this.item.orgTags = this.orgTags.join(",");
			// alert('pretend save');
			this.$emit("save", this.item);
			this.editmode = false;
		},
		cancelItem() {
			this.editmode = false;
			this.$emit("cancel");
		},
		clearItem(property) {
			setTimeout(() => {
				this.item[property] = "";
			}, 1);
		},
		clearSelectedOrganisation() {
			setTimeout(() => {
				this.selectedOrganisation = null; //{ id: '', orgName: '' };
			}, 1);
		},
		clearSelectedContact() {
			setTimeout(() => {
				this.selectedContact = { id: "", contactName: "", contactEmail: "" };
			}, 1);
		},
		clearSelectedOwner() {
			setTimeout(() => {
				this.selectedOwner = { ownerUserId: "", ownerUserName: "", ownerUserEmail: "" };
			}, 1);
		},
		clearSelectedPreparedBy() {
			setTimeout(() => {
				this.selectedPreparedBy = { preparedByUserId: "", preparedByUserName: "", preparedByUserEmail: "" };
			}, 1);
		},
		lookupOrganisation(id) {
			// lookup organisation by id
			// console.log('this.organisations = ' + JSON.stringify(this.organisations, null, 2))
			let organisations = this.organisations;
			let organisation = organisations.find((org) => {
				return org.id === id;
			});
			if (organisation !== "" && organisation !== undefined && organisation !== null) {
				return organisation.orgName;
			} else {
				return "";
			}
		},
		changeQuoteStatus(status) {
			this.item.quoteStatus = status;
			this.$emit("save", this.item);
		},
		async getOrganisation(orgId) {
			let t = this;
			let organisationResult = await t.MIX_redisReadHash(`org:${orgId}`);
			t.organisation = organisationResult.data;
			t.contacts = await t.MIX_contacts(t.item.orgId); // FIXME - RG - This is incorrect
			let addressResult = await t.MIX_redisSearch("idx:addresses", `@orgId:{${orgId}}`, 0, 9999);
			if (addressResult.code === 1) {
				t.addresses = addressResult.data.documents.map((x) => x.value);
				t.addresses.unshift({
					addressType: "Main",
					addressName: t.organisation.orgName,
					addressLine1: t.organisation.orgAddressLine1,
					addressLine2: t.organisation.orgAddressLine2,
					addressTown: t.organisation.orgTown,
					addressCounty: t.organisation.orgCounty,
					addressPostcode: t.organisation.orgPostcode,
					addressCountry: t.organisation.orgCountry,
					addressLat: t.organisation.orgLat,
					addressLng: t.organisation.orgLng,
					orgId: t.organisation.id,
					orgName: t.organisation.orgName,
					createdDateTime: t.organisation.createdDateTime,
					createdUserId: t.organisation.createdUserId,
					createdUserName: t.organisation.createdUserName,
					createdUserEmail: t.organisation.modifiedUserEmail,
					modifiedDateTime: t.organisation.modifiedDateTime,
					modifiedUserId: t.organisation.modifiedUserId,
					modifiedUserName: t.organisation.modifiedUserName,
					modifiedUserEmail: t.organisation.modifiedUserEmail,
					deleted: "0",
					deletedDateTime: "",
					deletedUserId: "",
					deletedUserName: "",
					deletedUserEmail: "",
					key: "address",
					id: t.MIX_generateId,
				});
			}
		},
		updateSelectedInvoiceAddress() {
			if (this.selectedInvoiceAddress !== null) {
				this.item.orgInvoiceAddressLine1 = this.selectedInvoiceAddress.addressLine1;
				this.item.orgInvoiceAddressLine2 = this.selectedInvoiceAddress.addressLine2;
				this.item.orgInvoiceAddressLine3 = this.selectedInvoiceAddress.addressLine3;
				this.item.orgInvoiceTown = this.selectedInvoiceAddress.addressTown;
				this.item.orgInvoiceCounty = this.selectedInvoiceAddress.addressCounty;
				this.item.orgInvoicePostcode = this.selectedInvoiceAddress.addressPostcode;
			}
		},
		updateSelectedDeliveryAddress() {
			if (this.selectedDeliveryAddress !== null) {
				this.item.orgDeliveryAddressLine1 = this.selectedDeliveryAddress.addressLine1;
				this.item.orgDeliveryAddressLine2 = this.selectedDeliveryAddress.addressLine2;
				this.item.orgDeliveryAddressLine3 = this.selectedDeliveryAddress.addressLine3;
				this.item.orgDeliveryTown = this.selectedDeliveryAddress.addressTown;
				this.item.orgDeliveryCounty = this.selectedDeliveryAddress.addressCounty;
				this.item.orgDeliveryPostcode = this.selectedDeliveryAddress.addressPostcode;
			}
		},
		clearInvoiceAddress() {
			setTimeout(() => {
				this.selectedInvoiceAddress = {};
				this.item.orgInvoiceAddressLine1 = "";
				this.item.orgInvoiceAddressLine2 = "";
				this.item.orgInvoiceAddressLine3 = "";
				this.item.orgInvoiceTown = "";
				this.item.orgInvoiceCounty = "";
				this.item.orgInvoicePostcode = "";
			}, 1);
		},
		clearDeliveryAddress() {
			setTimeout(() => {
				this.selectedDeliveryAddress = {};
				this.item.orgDeliveryAddressLine1 = "";
				this.item.orgDeliveryAddressLine2 = "";
				this.item.orgDeliveryAddressLine3 = "";
				this.item.orgDeliveryTown = "";
				this.item.orgDeliveryCounty = "";
				this.item.orgDeliveryPostcode = "";
			}, 1);
		},
		formatTag: function (item) {
			if (item !== null) {
				return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
			} else {
				return "";
			}
		},
		// TODO - RG - When Saving check quote does not already exist
		loadDataDown() {
			console.log("loadDataDown running");
			this.item = { ...this.datadown };
      // console.log(this.item);
			if (this.item.quoteNumber === "") {
				this.editmode = true;
			}
			if (this.item.orgId !== "" && this.item.orgId !== undefined && this.item.orgId !== null) {
				this.selectedOrganisation = { id: this.datadown.orgId, orgName: this.datadown.orgName };
			} else {
				this.selectedOrganisation = null; //{ id: '', orgName: '' };
			}
			if (this.item.contactId !== "") {
				this.selectedContact = { id: this.datadown.contactId, contactName: this.datadown.contactName, contactEmail: this.datadown.contactEmail };
			} else {
				this.selectedContact = { id: "", contactName: "", contactEmail: "" };
			}
			if (this.item.ownerUserId !== "") {
				this.selectedOwner = { ownerUserId: this.datadown.ownerUserId, ownerUserName: this.datadown.ownerUserName, ownerUserEmail: this.datadown.ownerUserEmail };
			} else {
				this.selectedOwner = this.MIX_getCurrentOwner();
			}
			if (this.item.preparedByUserId) {
				if (this.item.preparedByUserId !== "") {
					this.selectedPreparedBy = { preparedByUserId: this.datadown.preparedByUserId, preparedByUserName: this.datadown.preparedByUserName, preparedByUserEmail: this.datadown.preparedByUserEmail };
				}
			}
			if (this.item.orgTags !== "" && this.item.orgTags !== undefined && this.item.orgTags !== null) {
				this.orgTags = this.item.orgTags.split(",");
			}
			if (this.item.quoteDate === "" || this.item.quoteDate === null || this.item.quoteDate === undefined) {
				this.item.quoteDate = this.$moment(this.date1, "YYYY-MM-DD").format("X");
			}
			if (this.item.quoteExpiryDate === "" || this.item.quoteExpiryDate === null || this.item.quoteExpiryDate === undefined) {
				this.item.quoteExpiryDate = this.$moment(this.date2, "YYYY-MM-DD").format("X");
			}
			if (this.item.quoteDeliveryDate === "" || this.item.quoteDeliveryDate === null || this.item.quoteDeliveryDate === undefined) {
				this.item.quoteDeliveryDate = this.$moment(this.date3, "YYYY-MM-DD").format("X");
				this.requestedDeliveryDate = "Date";
			} else if (this.item.quoteDeliveryDate === "0") {
				this.requestedDeliveryDate = "TBC";
			} else if (this.item.quoteDeliveryDate !== "") {
				this.requestedDeliveryDate = "Date";
			}
			this.checkDates();
			// if (this.item.quoteNumber === "") {
			// 	this.editmode = true;
			// } else {
			// 	this.editmode = false;
			// }
		},
		checkDates() {
			var diffDays = this.$moment(this.item.quoteExpiryDate, "X").diff(this.$moment(this.item.quoteDate, "X"), "days");
			if (diffDays > 30) {
				this.quoteDatesValid = false;
			} else {
				this.quoteDatesValid = true;
			}
		},
		async closeItem() {
			this.dialogOrganisationKey++;
			this.dialogOrganisation = false;
			this.organisations = await this.MIX_organisations("Customer");
		},
		async closeContact() {
			this.dialogContactKey++;
			this.dialogContact = false;
			this.contacts = await this.MIX_contacts(this.item.orgId); // FIXME - RG - This is incorrect
			// refresh contact dropdown
		},
		async updateQuoteContact(item) {
			this.dialogContactKey++;
			this.dialogContact = false;
			this.contacts = await this.MIX_contacts(this.item.orgId); // FIXME - RG - This is incorrect
			this.selectedContact = { id: item.contactId, contactName: item.contactName, contactEmail: item.contactEmail };
		},
		async updateQuote(item) {
			this.dialogOrganisationKey++;
			this.dialogOrganisation = false;
			this.organisations = await this.MIX_organisations("Customer");
			this.selectedOrganisation = { id: item.id, orgName: item.orgName };
		},
		ready() {
			// If the quote is not approved then set to awaiting approval otherwise set to ready
			console.log("this.item.totalSell = " + this.item.totalSell);
			if (Number(this.item.totalSell) < 50000) {
				this.item.approved = "1";
				this.item.quoteStatus = "Ready";
			} else {
				console.log("here2");
				this.item.quoteStatus = "Awaiting Approval";
			}
			// console.log('this.item.approved = ', this.item.approved)
			this.$emit("save", this.item);
		},
		approveQuote() {
			this.item.quoteStatus = "Ready";
			this.item.approved = "1";
			this.$emit("save", this.item);
		},
		declineQuote() {
			this.item.quoteStatus = "Declined";
			this.item.approved = "0";
			this.$emit("save", this.item);
		},
    goToQuote(){
      this.$router.push('/quotes/'+this.item.quoteID)
    }
	},
	// * WATCH
	watch: {
		datadown: {
			handler: function () {
				this.loadDataDown();
			},
			deep: true,
		},
		selectedOrganisation: {
			// TODO - RG - CHANGE THIS TO METHOD CALL ON CHANGE RATHER THAN WATCHER TO TRY AND FIX ADDRESS OVERWRITE
			handler: function () {
				setTimeout(() => {
					this.item.orgId = this.selectedOrganisation.id;
					this.item.orgName = this.selectedOrganisation.orgName;
					this.getOrganisation(this.item.orgId);
				}, 1);
			},
			deep: true,
		},
		selectedContact: {
			handler: function () {
				setTimeout(() => {
					this.item.contactId = this.selectedContact.id;
					this.item.contactName = this.selectedContact.contactName;
					this.item.contactEmail = this.selectedContact.contactEmail;
				}, 1);
			},
			deep: true,
		},
		selectedOwner: {
			handler: function () {
				setTimeout(() => {
					this.item.ownerUserId = this.selectedOwner.ownerUserId;
					this.item.ownerUserName = this.selectedOwner.ownerUserName;
					this.item.ownerUserEmail = this.selectedOwner.ownerUserEmail;
				}, 1);
			},
			deep: true,
		},
		selectedPreparedBy: {
			handler: function () {
				setTimeout(() => {
					this.item.preparedByUserId = this.selectedPreparedBy.preparedByUserId;
					this.item.preparedByUserName = this.selectedPreparedBy.preparedByUserName;
					this.item.preparedByUserEmail = this.selectedPreparedBy.preparedByUserEmail;
				});
			},
		},
		// Dates
		dateMenu1() {
			this.item.quoteDate = this.$moment(this.date1, "YYYY-MM-DD").format("X");
			this.checkDates();
		},
		dateMenu2() {
			this.item.quoteExpiryDate = this.$moment(this.date2, "YYYY-MM-DD").format("X");
			this.checkDates();
		},
		dateMenu3() {
			this.item.quoteDeliveryDate = this.$moment(this.date3, "YYYY-MM-DD").format("X");
			this.checkDates();
		},
		requestedDeliveryDate() {
			if (this.requestedDeliveryDate == "TBC") {
				this.item.quoteDeliveryDate = "0";
			} else if (this.requestedDeliveryDate == "Date") {
				this.item.quoteDeliveryDate = this.$moment(this.date3, "YYYY-MM-DD").format("X");
			}
		},
		// Selected Tags
		selectedTags: {
			handler: async function () {
				// Force Title Case on Tags
				for (let i = 0; i < this.selectedTags.length; i++) {
					this.selectedTags[i] = this.selectedTags[i].toLowerCase(); //replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
					this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, " ");
					if (this.selectedTags[i] === " ") {
						this.selectedTags.splice(i, 1);
					} else {
						this.selectedTags[i] = this.selectedTags[i].trim();
					}
					if (this.quoteTags.indexOf(this.selectedTags[i]) === -1) {
						let addToSetResult = await this.MIX_redisAddToSet(`set:quoteTags`, this.selectedTags[i]);
						if (addToSetResult.code === -1) {
							//console.log(addToSetResult.message);
						}
					}
				}
				// Update Quote Tags and split out array with commas
				this.item.quoteTags = this.selectedTags.join(",");
			},
			deep: true,
		},
	},
	// * CREATED
	async created() {
		let t = this;
		t.loading = true;
		//t.item = { ...t.datadown };
    if(this.$route.query.orgId !== undefined){
      // this.selectedOrganisation = await this.lookupOrganisation(this.$route.query.orgId);
      const orgResult = await t.MIX_redisReadHash(`org:`+this.$route.query.orgId);
      // console.log(orgResult)
      this.selectedOrganisation = orgResult.data;

    }

		// Get Customers
		t.organisations = await t.MIX_organisations("Customer");
		//console.log('t.organisations = ' + JSON.stringify(t.organisations));
		// Get Quote Channels
		let segmentsResult = await t.MIX_redisReadSet("set:channels", true);
		if (segmentsResult.code === 1) {
			t.segments = segmentsResult.data;
		}
		// Get Quote Types
		let quoteTypesResult = await t.MIX_redisReadSet("set:quoteTypes", true);
		if (quoteTypesResult.code === 1) {
			t.quoteTypes = quoteTypesResult.data;
		}
		// Get Quote Validity
		let quoteValidityResult = await t.MIX_redisReadSortedSet("sset:quoteValidity", 0, 9999);
		if (quoteValidityResult.code === 1) {
			t.quoteValidity = quoteValidityResult.data;
		}
		// Get Quote Status
		let quoteStatusResult = await t.MIX_redisReadSortedSet("sset:quoteStatus", 0, 9999);
		if (quoteStatusResult.code === 1) {
			t.quoteStatus = quoteStatusResult.data;
		}
		// Get Quote Tags
		let quoteTagsResult = await t.MIX_redisReadSet("set:quoteTags", false);
		if (quoteTagsResult.code === 1) {
			t.quoteTags = quoteTagsResult.data;
		}
		let users = await this.MIX_users(""); // Add Users to Dropdown for Owner
		this.users = users.map((x) => {
			return {
				ownerUserId: x.id,
				ownerUserName: x.userName,
				ownerUserEmail: x.userEmail,
			};
		});

		let preparedByUsers = await this.MIX_users("");
		this.preparedByUsers = preparedByUsers.map((x) => {
			return {
				preparedByUserId: x.id,
				preparedByUserName: x.userName,
				preparedByUserEmail: x.userEmail,
			};
		});

		this.loadDataDown();
		// add spinners
		t.loading = false;
	},
	// * MOUNTED
	async mounted() {
		// Dates
	},
};
</script>
