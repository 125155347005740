<template>
    <div>
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field :label="orgtype ? 'Search ' + orgtype + 's' : 'Search Organisations'" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field>
            </div>
            <!-- <div v-if="!listshrunk && listexpanded && $vuetify.breakpoint.mdAndUp" class="ml-2">
                <div><v-autocomplete multiple outlined auto-select-first v-model="filter.orgAccountTypes" :search-input.sync="tagOrgAccountTypes" @change="tagOrgAccountTypes = ''" label="Filter by Account Type" :items="orgAccountTypes" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedAccountTypes()"></v-autocomplete></div>
                <div class="ml-1 mt-1 body-2 app--text">
                    Filter is set to <strong class="primary--text" @click="changeAccountTypesOperator()" style="cursor: pointer">{{ filter.orgAccountTypesOperator }}</strong> of the above
                </div>
            </div> -->
          <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
            <!-- <pre>{{ orgAccountOwner }}</pre> -->
            <div><v-autocomplete auto-select-first v-model="filter.orgStatus" outlined label="Filter by Status" dense hide-details="auto" background-color="white" color="primary" :items="orgStatus"  clearable :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-autocomplete></div>
          </div>
            <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
                <div><v-autocomplete multiple outlined auto-select-first v-model="filter.orgChannels" :search-input.sync="tagOrgAccountTypes" @change="tagOrgAccountTypes = ''" label="Filter by Channel" :items="orgChannels" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedAccountTypes()"></v-autocomplete></div>
                <div class="ml-1 mt-1 body-2 app--text">
                    Filter is set to <strong class="primary--text" @click="changeChannelsOperator()" style="cursor: pointer">{{ filter.orgAccountTypesOperator }}</strong> of the above
                </div>
            </div>
            <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
                <div><v-autocomplete multiple outlined auto-select-first v-model="filter.orgTags" :search-input.sync="tagOrgTags" @change="tagOrgTags = ''" label="Filter by Tags" :items="orgTags" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOrgTags()"></v-autocomplete></div>
                <div class="ml-1 mt-1 body-2 app--text">
                    Filter is set to <strong class="primary--text" @click="changeTagsOperator()" style="cursor: pointer">{{ filter.orgTagsOperator }}</strong> of the above
                </div>
            </div>
            <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
                <!-- <pre>{{ orgAccountOwner }}</pre> -->
                <div><v-autocomplete auto-select-first v-model="orgAccountOwner" outlined label="Filter by Owner" dense hide-details="auto" background-color="white" color="primary" :items="users" item-text="ownerUserName" return-object clearable @click:clear="clearSelectedOwner()" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-autocomplete></div>
            </div>
            <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="refreshItems">
                    <v-icon>icons8-available-updates</v-icon>
                </v-btn>
            </div>
			<!-- TODO - HIGH - SECURITY - RG - Show Column Selector to Staff-Directors / Staff-Admin / Developer-Admin only -->			
            <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>icons8-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
          <!--    EXPORT CSV-->
          <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="exportBox = true">
              <v-icon>icons8-export-csv</v-icon>
            </v-btn>
          </div>
          <ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
            <template v-slot:header>
              <div class="full-width d-flex align-center">
                <div>Export CSV</div>
                <v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
                <v-spacer />
                <v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-cancel</v-icon></v-btn>
              </div>
            </template>
            <p>Please select the data you wish to export:</p>
            <table width="100%" cellspacing="5">
              <tr>
                <td width="100"><strong>Current page:</strong></td>
                <td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
                <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
              </tr>
              <tr>
                <td><strong>Current total:</strong></td>
                <td>{{ itemsTotal }} items (filtered)</td>
                <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
              </tr>
              <tr>
                <td><strong>All Items:</strong></td>
                <td>{{ totalCount }} items (without filter)</td>
                <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
              </tr>
              <tr>
                <td valign="top"><strong>Specific:</strong></td>
                <td>
                  <div class="d-flex align-center">
                    <div>From</div>
                    <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
                    <div>To</div>
                    <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
                  </div>
                  <div class="ml-n7">
                    <CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
                  </div>
                </td>
                <td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
              </tr>
            </table>
          </ExportBox>
          <!--      EXPORT END-->
        </div>
                <!-- Alphabet Search -->
        <v-row v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" fill-width class="ma-0">
            <!-- // loop through each letter of the alphabet and create a button -->
            <v-btn-toggle v-model="filter.alpha" class="grey lighten-2  d-flex align-center flex-wrap">
                <v-btn v-for="letter in alphabet" :key="letter" text :value="letter" height="30" class="white mr-1 mt-1 " depressed x-small><span class="body-2 font-weight-medium">{{ letter }}</span></v-btn>
                <v-btn value="" class="mt-1 grey lighten-3" height="29" text depressed x-small><span class="body-2 font-weight-medium">All</span></v-btn>
            </v-btn-toggle>
            <v-spacer />
            <v-btn-toggle v-model="filter.profileScore" dense class="grey lighten-2 d-flex align-center flex-wrap">
                <div class="mx-3 mt-1 caption">PROFILE SCORE:</div>
                <v-btn class="mt-1" value="weak" active-class="error white--text" height="29" text depressed x-small small>Weak</v-btn>
                <v-btn class="mt-1" value="intermediate" active-class="warning white--text" height="29" text depressed x-small small>Intermediate</v-btn>
                <v-btn class="mt-1" value="strong" active-class="success white--text" height="29" text depressed x-small small>Strong</v-btn>
            </v-btn-toggle>
        </v-row>
        <div class="my-2">
            <!-- TABLE BASED LIST -->
			<!-- computedSearchQuery: {{ computedSearchQuery}} -->
            <v-data-table v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions"
                :loading="loading" :loading-text="'Loading' + orgtype" :footer-props="{'items-per-page-options': [5, 10, 15, 25, 50, 100]}">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2" v-if="!loading">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <!-- TODO - HIGH - SECURITY - Hide/Show Buttons -->
						<!-- 
							Show Delete / Restore Button for Staff-Directors / Staff-Managers / Staff-Admin / Developer-Admin
							Show Destroy Option Button Developer-Admin Only
						-->							
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn value="destroy" small v-if="['Developer-Admin'].includes(userLevel)">
                                <v-icon class="error--text">icons8-delete-trash</v-icon>
                            </v-btn>
                            <v-btn value="delete" small v-if="['Developer-Admin','Staff-Admin','Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                            <v-btn value="undelete" small v-if="['Developer-Admin','Staff-Admin','Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:item.orgTelephone="{ item }">
                    <div v-if="item.orgTelephone !== ''" @click="MIX_goExternal('callto:' + item.orgTelephone)" class="d-flex align-end">
                        <div>{{ item.orgTelephone }}</div>
                        <div v-if="listexpanded"><v-icon class="primary--text ml-1">icons8-external-link</v-icon></div>
                    </div>
                </template>
                <template v-slot:item.orgWebsite="{ item }">
                    <div v-if="item.orgWebsite !== ''" @click="MIX_goExternal(item.orgWebsite)" class="d-flex align-end">
                        <div>{{ item.orgWebsite }}</div>
                        <div v-if="listexpanded"><v-icon class="primary--text ml-1">icons8-external-link</v-icon></div>
                    </div>
                </template>
                <template v-slot:item.orgEmail="{ item }">
                    <div v-if="item.orgEmail !== ''" @click="MIX_goExternal('mailto:' + item.orgEmail)" class="d-flex align-end">
                        <div>{{ item.orgEmail }}</div>
                        <div v-if="listexpanded"><v-icon class="primary--text ml-1">icons8-secured-letter</v-icon></div>
                    </div>
                </template>
                <template v-slot:item.orgAccountOnHold="{ item }">
                    <div>
						<span v-if="item.orgAccountOnHold === '0'">No</span>
						<span v-if="item.orgAccountOnHold === '1'">Yes</span>
                    </div>
                </template>
                <!-- Profile Score -->
                <template v-slot:item.orgProfileScore="{ item }">
                    <v-chip small v-if="Number(item.orgProfileScore) >= 0 && Number(item.orgProfileScore < 25)" class="error white--text">{{ item.orgProfileScore }} %</v-chip>
                    <v-chip small v-if="Number(item.orgProfileScore) >= 25 && Number(item.orgProfileScore < 75)" class="warning white--text">{{ item.orgProfileScore }} %</v-chip>
                    <v-chip small v-if="Number(item.orgProfileScore) >= 75 && Number(item.orgProfileScore <= 100)" class="success white--text">{{ item.orgProfileScore }} %</v-chip>
                    <v-chip small v-if="item.orgProfileScore === '' || item.orgProfileScore === null || item.orgProfileScore === undefined" class="white">-</v-chip>
                </template>

                <template v-slot:item.action="{ item }">
                    <v-icon class="error--text pa-1" v-if="toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="confirmDeleteDialog(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listshrunk || $vuetify.breakpoint.smAndDown" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-1 font-weight-medium " style="width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.orgName }}</div>
                            <div class="caption darkgrey--text">{{ item.orgAccountTypes }}</div>
                            <div class="body-2">{{ item.orgPostcode }}</div>
                            <!-- <br />
							<span class="body-2">{{ item.supplierName }}</span
							><br />  -->
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text" @click="openItem(item)">icons8-forward</v-icon>
                        </div>
                    </div>
                </v-card>
            </div>
            <!-- LOAD MORE CARDS -->
            <v-btn v-if="listshrunk || $vuetify.breakpoint.smAndDown" class="mt-2" depressed block @click="loadMore()">Load More</v-btn>
        </div>
        <confirm-dialog :datadown="confirmDialog" v-on:method="confirmMethodHandler" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExportBox from "@/components/general/ExportBox.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
import TextField from "@/components/form/TextField.vue";
import AppButton from "@/components/general/AppButton.vue";
export default {
  components: {AppButton, TextField, CheckboxField, ExportBox},
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: true,
        },
        orgtype: {
            type: String,
            default: '',
        },
    },
    // DATA
    data: () => ({
        loading: true,
        key: 'org',
        index: 'idx:orgs',
        toggleDeletedItems: false,

        orgTags: [],
        orgAccountTypes: [],
        orgChannels: [],

        alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],

        // tag
        tagOrgAccountTypes: '',
        tagOrgTags: '',

        users: [],
        orgStatus: [],

        filter: {
            orgAccountTypesOperator: 'ANY',
            orgAccountTypes: [],
            orgChannelsOperator: 'ANY',
            orgChannels: [],
            orgTagsOperator: 'ANY',
            orgTags: [],
            orgStatus: 'Active',
            alpha: '',
            profileScore: '',
        },
        orgAccountOwner: {
            ownerUserId: '',
            ownerUserName: '',
            ownerUserEmail: '',
        },
        // Toggles
        toggleFeatures: [],

        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'id', sortable: false, hidden: true, expandable: true },
            { text: 'Name', value: 'orgName', sortable: false, hidden: false, expandable: true },
            { text: 'Registered Name', value: 'orgRegistrationNumber', sortable: false, hidden: true, expandable: true },
            { text: 'Telephone', value: 'orgTelephone', sortable: false, hidden: false, expandable: true },
            { text: 'Website', value: 'orgWebsite', sortable: false, hidden: true, expandable: false },
            { text: 'Email', value: 'orgEmail', sortable: false, hidden: true, expandable: true },
            { text: 'Invoice Email', value: 'orgInvoiceEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Purchasing Email', value: 'orgPurchasingEmail', sortable: false, hidden: true, expandable: false },

            { text: 'Address Line 1', value: 'orgAddressLine1', sortable: false, hidden: true, expandable: true },
            { text: 'Address Line 2', value: 'orgAddressLine2', sortable: false, hidden: true, expandable: true },
            { text: 'Address Line 3', value: 'orgAddressLine3', sortable: false, hidden: true, expandable: true },
            { text: 'Town', value: 'orgTown', sortable: false, hidden: true, expandable: true },
            { text: 'County', value: 'orgCounty', sortable: false, hidden: false, expandable: true },
            { text: 'Postcode', value: 'orgPostcode', sortable: false, hidden: false, expandable: true },


            { text: 'Employees', value: 'orgEmployees', sortable: false, hidden: true, expandable: false },
            { text: 'Industry', value: 'orgIndustry', sortable: false, hidden: true, expandable: false },
            { text: 'Sector', value: 'orgSector', sortable: false, hidden: true, expandable: false },
            { text: 'Account Number', value: 'orgAccountNumber', sortable: false, hidden: true, expandable: false },
            { text: 'Account Types', value: 'orgAccountTypes', sortable: false, hidden: true, expandable: false },
            { text: 'Account On Hold', value: 'orgAccountOnHold', sortable: false, hidden: false, expandable: false },
            { text: 'Local Authority', value: 'orgLocalAuthority', sortable: false, hidden: true, expandable: false },
            { text: 'Turnover', value: 'orgTurnover', sortable: false, hidden: true, expandable: false },
            { text: 'Notes', value: 'orgNotes', sortable: false, hidden: true, expandable: false },

            { text: 'Profile Score', value: 'orgProfileScore', align: 'center', sortable: false, hidden: false, expandable: false },

            { text: 'Account Owner', value: 'ownerUserName', sortable: false, hidden: false, expandable: false },
			{ text: 'Channel', value: 'orgChannel', sortable: false, hidden: false, expandable: false },
            
			{ text: 'Source', value: 'orgSource', sortable: false, hidden: true, expandable: false },
            { text: 'Source Name', value: 'orgSourceName', sortable: false, hidden: true, expandable: false },

            { text: 'Twitter', value: 'orgTwitter', sortable: false, hidden: true, expandable: false },
            { text: 'Facebook', value: 'orgFacebook', sortable: false, hidden: true, expandable: false },
            { text: 'Linked In', value: 'orgLinkedIn', sortable: false, hidden: true, expandable: false },
            { text: 'Instagram', value: 'orgInstagram', sortable: false, hidden: true, expandable: false },

            { text: 'Date Time (C)', value: 'createdDateTime', sortable: false, hidden: true, expandable: false },
            { text: 'User ID (C)', value: 'createdUserId', sortable: false, hidden: true, expandable: false },
            { text: 'User Name (C)', value: 'createdUserName', sortable: false, hidden: true, expandable: false },
            { text: 'User Email (C)', value: 'createdUserEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Date Time (M)', value: 'modifiedDateTime', sortable: false, hidden: true, expandable: false },
            { text: 'User ID (M)', value: 'modifiedUserId', sortable: false, hidden: true, expandable: false },
            { text: 'User Name (M)', value: 'modifiedUserName', sortable: false, hidden: true, expandable: false },
            { text: 'User Email (M)', value: 'modifiedUserEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expandable: false },
            { text: 'Date Time (D)', value: 'deletedDateTime', sortable: false, hidden: true, expandable: false },
            { text: 'User ID (D)', value: 'deletedUserId', sortable: false, hidden: true, expandable: false },
            { text: 'User Name (D)', value: 'deletedUserName', sortable: false, hidden: true, expandable: false },
            { text: 'User Email (D)', value: 'deletedUserEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expandable: false, width: '155px' },
        ],
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@orgName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['orgName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        // Dialogs
        confirmDialog: {},
        confirmDelete: {
            show: true,
            title: "WARNING - Delete Options",
            message: 'Are you sure you want to permenantly delete the options <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p>',
            color: "error",
            actionText1: "No",
            actionText2: "Yes",
            id: "",
            method: "deleteItem",
            action: "",
        },
      //EXPORT DATA
      exportBox: false,
      exportItems: {
        itemsFrom: "1",
        itemsTo: "10",
      },
      exportLoading: false,
      exportFiltered: false,
      showInfo: false,
      totalCount: 0,
    }),
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
		userLevel() { return this.GET_FIREBASE_userAccessToken.user_level },
		userGroup() { return this.GET_FIREBASE_userAccessToken.user_group },
		userStatus() { return this.GET_FIREBASE_userAccessToken.user_status },
        alphaOptions() {
            let alphaOptions = [];
            for (let i = 0; i < this.alphabet.length; i++) {
                alphaOptions.push(this.filter.alpha + this.alphabet[i] + '*');
            }
            return alphaOptions;
        },        
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            //searchQuery = searchQuery + `@deleted:0`;
            // console.log('this.orgtype', this.orgtype);
            // if ((this.orgtype !== '') && (this.orgtype !== 'Organisation')) {
            //     if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
            //         if (this.toggleFeatures.includes('undelete')) {
            //             searchQuery = `@orgAccountTypes:{${this.orgtype}}`;
            //         } else {
            //             searchQuery = `@deleted:0  @orgAccountTypes:{${this.orgtype}}`;
            //         }
            //     } else {
            //         if (searchQuery.startsWith('@')) {
            //             if (this.toggleFeatures.includes('undelete')) {
            //                 searchQuery = `${searchQuery}* @orgAccountTypes:{${this.orgtype}}`;
            //             } else {
            //                 searchQuery = `${searchQuery}*  @deleted:0  @orgAccountTypes:{${this.orgtype}}`;
            //             }
            //         } else {
            //             if (this.toggleFeatures.includes('undelete')) {
            //                 searchQuery = `${searchQuery}* @orgAccountTypes:{${this.orgtype}}`;
            //             } else {
            //                 searchQuery = `${searchQuery}*  @deleted:0  @orgAccountTypes:{${this.orgtype}}`;
            //             }
            //         }
            //     }
            // } else {
                if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                    if (this.toggleFeatures.includes('undelete')) {
                        searchQuery = '*';
                    } else {
                        searchQuery = '@deleted:0';
                    }
                } else {
                    if (searchQuery.startsWith('@')) {
                        if (this.toggleFeatures.includes('undelete')) {
                            searchQuery = `${searchQuery}*`;
                        } else {
                            searchQuery = `${searchQuery}*  @deleted:0`;
                        }
                    } else {
                        if (this.toggleFeatures.includes('undelete')) {
                            searchQuery = `${searchQuery}*`;
                        } else {
                            searchQuery = `${searchQuery}*  @deleted:0`;
                        }
                    }
                }
            //}
            // Alphabet Filter
            if (this.filter.alpha !== '' && this.filter.alpha !== null && this.filter.alpha !== undefined) {
                let alphaOptions = this.alphaOptions.join(',');
                alphaOptions = alphaOptions.replace(/,/g, '|');
                searchQuery = `${searchQuery}  @id:${alphaOptions}`;
            }
            // Account Type Filter
            if (JSON.stringify(this.filter.orgAccountTypes) !== '[]') {
                if (this.filter.orgAccountTypesOperator === 'ANY') {
                    searchQuery = `${searchQuery} @orgAccountTypes:{${this.filter.orgAccountTypes.join('|')}}`;
                } else {
                    let orgAccountTypes = [];
                    this.filter.orgAccountTypes.forEach((tag) => {
                        orgAccountTypes.push(`@orgAccountTypes:{${tag}}`);
                    });
                    searchQuery = `${searchQuery} ${orgAccountTypes.join(' ')}`;
                }
            }
            // Channel Filter
            if (JSON.stringify(this.filter.orgChannels) !== '[]') {
                if (this.filter.orgChannelsOperator === 'ANY') {
                    searchQuery = `${searchQuery} @orgChannel:{${this.filter.orgChannels.join('|')}}`;
                } else {
                    let orgChannels = [];
                    this.filter.orgChannels.forEach((tag) => {
                        orgChannels.push(`@orgChannels:{${tag}}`);
                    });
                    searchQuery = `${searchQuery} ${orgChannels.join(' ')}`;
                }
            }
            // Org Tags Filter
            if (JSON.stringify(this.filter.orgTags) !== '[]') {
                if (this.filter.orgTagsOperator === 'ANY') {
                    searchQuery = `${searchQuery} @orgTags:{${this.filter.orgTags.join('|')}}`;
                } else {
                    let orgTags = [];
                    this.filter.orgTags.forEach((tag) => {
                        orgTags.push(`@orgTags:{${tag}}`);
                    });
                    searchQuery = `${searchQuery} ${orgTags.join(' ')}`;
                }
            }
             // Profile Score Filter
            if (this.filter.profileScore !== '') {
                if (this.filter.profileScore === 'weak') {
                    searchQuery = `${searchQuery}  @orgProfileScore: [0 +inf] @orgProfileScore: [-inf (25]`;
                } else if (this.filter.profileScore === 'intermediate') {
                    searchQuery = `${searchQuery}  @orgProfileScore: [25 +inf] @orgProfileScore: [-inf (75]`;
                } else if (this.filter.profileScore === 'strong') {
                    searchQuery = `${searchQuery}  @orgProfileScore: [75 +inf] @orgProfileScore: [-inf (100]`;
                }
            }
            // If filter for user specific quotes
            if (this.orgAccountOwner?.ownerUserId !== '' && this.orgAccountOwner?.ownerUserId !== null && this.orgAccountOwner?.ownerUserId !== undefined) {
                searchQuery = `${searchQuery} @ownerUserId:${this.orgAccountOwner.ownerUserId}`;
            }

            //org status filter
            if (this.filter.orgStatus !== '' && this.filter.orgStatus !== null && this.filter.orgStatus !== undefined) {
                if(this.filter.orgStatus === 'Archived') {
                  searchQuery = `${searchQuery} @orgStatus:${this.filter.orgStatus}`;
                }else if(this.filter.orgStatus === 'Deleted') {
                  searchQuery = `${searchQuery} @orgStatus:${this.filter.orgStatus}`;
                }else if(this.filter.orgStatus === 'Draft') {
                  searchQuery = `${searchQuery} @orgStatus:${this.filter.orgStatus}`;
                }else{
                  searchQuery = `${searchQuery} @orgStatus:-Archived AND -Deleted AND -Draft`;
                }
            }

            // console.log('searchQuery = ' + JSON.stringify(searchQuery, null, 2))

            return searchQuery;
        },
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            // TODO - FILTERING - Check if all necessary fields are present
			if (!computedFields.includes('@id')) { computedFields.push('@id') }
			if (!computedFields.includes('@deleted')) { computedFields.push('@deleted') }
			if (!computedFields.includes('@orgChannel')) { computedFields.push('@orgChannel') }
            //if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
            computedFields = computedFields.join(',');
            return computedFields;
        },
    },
    methods: {
        
        clearSelectedOwner() {
            setTimeout(() => {
                this.orgAccountOwner = {
                    ownerUserId: '',
                    ownerUserName: '',
                    ownerUserEmail: '',  
                };
            }, 1);
        },
        confirmDeleteDialog(itemId) {
            var t = this;
            t.confirmDelete.id = itemId
            t.confirmDialog = t.confirmDelete
        },
        confirmMethodHandler(value) {
            this.confirmDialog = {};
            this.confirmDelete.id = ''
            // console.log('value = ' + JSON.stringify(value, null, 2))
            if (value.action === 2) {
                this[value.method](value.id);
            }
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', item);
            //this.activeItem = item;
            // this.listexpanded = false;
        },
		// Refresh Items
		refreshItems() {
			this.getItems();
		},
        // * GET ITEMS
        async getItems() {
            this.loading = true;
			// console.log('getItems running')
            // console.log('this.computedSearchQuery', this.computedSearchQuery);
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                //console.log("items = " + JSON.stringify(itemsResult, null, 2));
            }
            this.loading = false;

        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        // * Change Org Account Types Tag Operator
        changeAccountTypesOperator() {
            if (this.filter.orgAccountTypesOperator === 'ALL') {
                this.filter.orgAccountTypesOperator = 'ANY';
            } else {
                this.filter.orgAccountTypesOperator = 'ALL';
            }
        },
        // * Change Org Channels Tag Operator
        changeChannelsOperator() {
            if (this.filter.orgChannelsOperator === 'ALL') {
                this.filter.orgChannelsOperator = 'ANY';
            } else {
                this.filter.orgChannelsOperator = 'ALL';
            }
        },
        // * Change Org Tags Operator
        changeTagsOperator() {
            if (this.filter.orgTagsOperator === 'ALL') {
                this.filter.orgTagsOperator = 'ANY';
            } else {
                this.filter.orgTagsOperator = 'ALL';
            }
        },                
        // Clear Selected Account Types
        clearSelectedAccountTypes() {
            setTimeout(() => {
                this.filter.orgAccountTypes = [];
            }, 1);
        },
        // Clear Selected Account Types
        clearSelectedOrgChannels() {
            setTimeout(() => {
                this.filter.orgChannels = [];
            }, 1);
        },
        // Clear Selected Org Tags
        clearSelectedOrgTags() {
            setTimeout(() => {
                this.filter.orgTags = [];
            }, 1);
        },
      // Export Box CSV
      async exportCSV(itemsFrom, itemsTo, filter, specific) {
        // console.table({ itemsFrom, itemsTo, filter, specific });
        if (itemsFrom > itemsTo) {
          this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
        } else {
          this.exportLoading = true;
          var itemsCount, tableData, csv, query, exportFileName;
          switch (true) {
              // Current Page / Current Total (filtered)
            case filter && !specific:
              itemsCount = itemsTo - itemsFrom;
              query = this.computedSearchQuery;
              break;
              // All (Not filtered)
            case !filter && !specific:
              itemsCount = this.totalCount;
              query = `*`;
              break;
              // Specific (filtered/ not filtered)
            case specific:
              itemsFrom = itemsFrom - 1;
              itemsCount = itemsTo - itemsFrom;
              if (this.exportFiltered) {
                query = this.computedSearchQuery;
              } else {
                query = `*`;
              }
              break;
          }
          // console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
          // let exportResult = await this.REDIS_searchFor("animal", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
          let exportResult = await this.MIX_redisAggregateSearch(this.index, query, itemsFrom, itemsCount, this.computedFields, this.sortBy, false);
          // console.log(exportResult)
          tableData = JSON.parse(JSON.stringify(exportResult.data.results));
          // console.table(tableData)
          csv = this.$papa.unparse(tableData);
          if (itemsFrom === 0) {
            itemsFrom = 1; // This is just for filename purposes
          }
          if (itemsFrom >= 10) {
            itemsFrom = itemsFrom + 1;
          }
          switch (true) {
            case !specific && filter:
              exportFileName = `Customers_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case !specific && !filter:
              exportFileName = `Customers_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && this.exportFiltered:
              exportFileName = `Customers_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && !this.exportFiltered:
              exportFileName = `Customers_Specific_${itemsFrom}_to_${itemsTo}`;
              break;
          }
          // console.log(exportFileName);
          // console.log(csv.length);
          this.$papa.download(csv, exportFileName);
          this.exportLoading = false;
        }
      },
      async getTotal(){
        let totalResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
        // console.log(totalResult)
        this.totalCount = totalResult.data.total;
      },
    },
    watch: {
        orgAccountOwner: {
            handler: async function () {
                this.getItems();
            }, deep: true
        },
        refreshitems() {
			this.refreshItems()
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery: {
			handler: function () {
				this.datatableOptions.page = 1;
				this.itemsFrom = 0;
				this.getItems();
			}, deep: true
		},
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            //console.log('imagesIndex = ' + imagesIndex)
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
        listshrunk() {
            if (this.listshrunk) {
                this.datatableOptions.itemsPerPage = 10;
                this.itemsFrom = 0
                this.getItems()
            }
        },
        listexpanded() {
            if (this.listexpanded === false) {
                this.datatableOptions.itemsPerPage = 10;
                this.itemsFrom = 0
                this.getItems()
            } else if (this.listexpanded === true) {
                this.datatableOptions.itemsPerPage = 10;
                this.itemsFrom = 0
                this.getItems()
            }
        }
    },
    async created() {
        let t = this;
        t.loading = true;
        // Get Organisation Account Types
        let orgAccountTypes = await t.MIX_redisReadSet('set:orgAccountTypes', false);
        if (orgAccountTypes.code === 1) {
            t.orgAccountTypes = orgAccountTypes.data;
        }
        // Get Channels
        let orgChannels = await t.MIX_redisReadSet('set:channels', false);
        if (orgChannels.code === 1) {
            t.orgChannels = orgChannels.data;
        }
        // Get Users
        let users = await this.MIX_users(''); // Add Users to Dropdown for Owner
        this.users = users.map((x) => {
            return {
                ownerUserId: x.id,
                ownerUserName: x.userName,
                ownerUserEmail: x.userEmail,
            };
        });
        // Get Organisation Tags
        let orgTags = await t.MIX_redisReadSet('set:orgTags', false);
        if (orgTags.code === 1) {
            t.orgTags = orgTags.data;
        }
        if (t.orgtype !== '' && t.orgtype !== 'Organisation') {
            t.filter.orgAccountTypes.push(t.orgtype);
        }
        if (this.$vuetify.breakpoint.smAndDown) {
            this.datatableOptions.itemsPerPage = 10;
        }

      // Get Organisation Status SortedSet
      let orgStatus = await t.MIX_redisReadSortedSet("sset:orgStatus", 0, 9999);
      if (orgStatus.code === 1) {
        t.orgStatus = orgStatus.data;
      }
        this.getTotal();
        // Get Items
        this.getItems();
    },
};
</script>
