<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; overflow: scroll !important" :style="$vuetify.breakpoint.mdAndUp ?  'height: calc(100vh - 150px) !important;' : 'height: calc(100vh - 120px) !important;' ">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div v-if="$vuetify.breakpoint.smAndDown" class="white--text text-h6 font-weight-bold pl-3">
                    <div>{{ item.leadTitle }}</div>
                    <div @click="close()" class="font-weight-normal caption"><v-icon size="15" class="white--text pa-0">icons8-back</v-icon> Return to List</div>
                </div>


                <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                <div class="ml-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''">
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="saveItem" :large="!dense"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text pt-3 px-3" dense>
            <!-- Header -->
            <!-- <v-col cols="12" class="font-weight-bold d-flex align-center grey lighten-5 rounded">
				<div class="text-left primary--text text-h6 font-weight-light">Details</div>
			</v-col> -->
            <!-- Main Content -->
            <v-col v-if="!editmode" cols="12" class="d-flex align-center grey lighten-5 rounded">
                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12" md="6" class="mb-3 pr-2">
                        <!-- VIEW VERSION -->
                        <v-row class="grey lighten-5 rounded pa-2" no-gutters fill-height>
                            <v-col cols="12" sm="12">
                                <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Title:</td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.leadTitle }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Channel:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.leadChannel }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Registration No:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgRegistrationNumber }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* CONTACTS VIEW -->
                    <v-col  cols="12" sm="12" md="6" class="mb-3 pl-1">
                        <!-- VIEW VERSION -->
                        <v-row class="grey lighten-5 rounded pa-2" no-gutters fill-height>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="primary--text mr-3">icons8-phone</v-icon>
                                    <v-icon medium class="primary--text mr-3">icons8-secured-letter</v-icon>
                                    <v-icon medium class="primary--text">icons8-external-link</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold grey lighten-5">Telephone No:</td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgTelephone }}</td>
                                            </tr>
                                            <tr class="">
                                                <td class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Website:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgWebsite }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Email:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgEmail }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* INVOICE DETAILS VIEW -->
                    <v-col  cols="12" sm="12" md="6" class="mb-3 pr-2">
                        <!-- VIEW VERSION -->
                        <v-row class="grey lighten-5 rounded pa-2" no-gutters fill-height>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Invoice Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="primary--text">icons8-secured-letter</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold grey lighten-5">Invoice Email:</td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgInvoiceEmail }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* PURCHASING DETAILS VIEW -->
                    <v-col v-if="item.orgPurchasingEmail" cols="12" sm="12" md="6" class="mb-3 pl-1">
                        <!-- VIEW VERSION -->
                        <v-row class="grey lighten-5 rounded pa-2" no-gutters fill-height>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Purchasing Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="primary--text">icons8-secured-letter</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold grey lighten-5">Purchasing Email:</td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgPurchasingEmail }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* ADDRESS DETAILS VIEW -->
                    <v-col v-if="item.orgAddressLine1" cols="12" sm="12" md="6" class="pr-2 mb-3">
                        <!-- VIEW VERSION -->
                        <v-row class="grey lighten-5 rounded pa-2" no-gutters fill-height>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Address</span>
                                    <v-spacer />
                                    <v-icon medium class="primary--text">icons8-map-marker</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody style="height: 100px" class="grey lighten-5">
                                            <tr class="d-flex grey lighten-5">
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold grey lighten-5">{{ item.orgAddressType }} Address:</td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
                                                    <span v-if="item.orgAddressLine1"
                                                        >{{ item.orgAddressLine1 }}<span v-if="item.orgAddressLine2">, {{ item.orgAddressLine2 }}</span
                                                        ><br
                                                    /></span>
                                                    <span v-if="item.orgAddressLine3">{{ item.orgAddressLine3 }}<br /></span>
                                                    <span v-if="item.orgTown"
                                                        >{{ item.orgTown }}<span v-if="item.orgCounty">, {{ item.orgCounty }}</span
                                                        ><br
                                                    /></span>
                                                    <span v-if="item.orgPostcode">{{ item.orgPostcode }}<br /></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* OTHER ADDRESS DETAILS VIEW -->
                    <v-col v-if="item.orgOtherAddressLine1" cols="12" sm="12" md="6" class="pl-1 mb-3">
                        <v-row class="grey lighten-5 rounded pa-2" no-gutters style="height: 80px !important">
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Other Address</span>
                                    <v-spacer />
                                    <v-icon medium class="primary--text">icons8-map-marker</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody style="height: 100px" class="grey lighten-5">
                                            <tr class="d-flex grey lighten-5">
                                                <td width="40%" class="pl-0 pr-0 apptext--text font-weight-bold grey lighten-5">{{ item.orgOtherAddressType }} Address:</td>

                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
                                                    <span v-if="item.orgOtherAddressLine1"
                                                        >{{ item.orgOtherAddressLine1 }}<span v-if="item.orgOtherAddressLine2">, {{ item.orgOtherAddressLine2 }}</span
                                                        ><br
                                                    /></span>
                                                    <span v-if="item.orgOtherAddressLine3">{{ item.orgOtherAddressLine3 }}<br /></span>
                                                    <span v-if="item.orgOtherTown"
                                                        >{{ item.orgOtherTown }}<span v-if="item.orgOtherCounty">, {{ item.orgOtherCounty }}</span
                                                        ><br
                                                    /></span>
                                                    <span v-if="item.orgOtherPostcode">{{ item.orgOtherPostcode }}<br /></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* ORG INFO VIEW -->
                    <v-col v-if="item.orgEmployees || item.orgIndustry || item.orgSector || item.orgAccountNumber || item.orgLocalAuthority || item.orgTurnover" cols="12" sm="12" md="6" class="mb-3">
                        <v-row class="grey lighten-5 rounded pa-2" no-gutters fill-height>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Organisation Info</span>
                                    <!-- <v-spacer /> -->
                                    <!-- <v-icon medium class="primary--text mr-3">icons8-secured-letter</v-icon> -->
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr class="">
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Channel:</td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgChannel }}</td>
                                            </tr>
                                            <tr class="">
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Employees:</td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgEmployees }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Industry:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgIndustry }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Sector:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgSector }}</td>
                                            </tr>
                                            <tr class="">
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Account Type:</td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgAccountTypes }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Local Authority:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgLocalAuthority }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Turnover:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgTurnover }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <!-- <v-col cols="6" class="pl-4">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
                                                <td width="33%" class="pl-2 apptext--text font-weight-bold grey lighten-5">Purchasing Email:</td><td width="67%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgPurchasingEmail }}</td>
                                            </tr>
											<tr>
                                                <td>:</td><td>{{ item.orgName }}</td>
                                            </tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col> -->
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pr-2 mb-3">
                        <!-- //* OWNER / SOURCE INFO VIEW -->
                        <v-row v-if="item.orgSource || item.orgSourceName" class="grey lighten-5 rounded pa-2" no-gutters fill-height>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Source Info</span>
                                    <v-spacer />
                                    <v-icon medium class="primary--text">icons8-secured-letter</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold grey lighten-5">Account Number:</td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgAccountNumber }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Source:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgSource }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Source Name:</td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgSourceName }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <!-- //* SOCIAL INFO VIEW -->
                        <v-row v-if="item.orgTwitter || item.orgFacebook || item.orgLinkedIn || item.orgInstagram" class="grey lighten-5 rounded pa-2" no-gutters fill-height>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Social Info</span>
                                    <v-spacer />
                                    <v-icon medium class="primary--text mr-3">icons8-secured-letter</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" class="pl-0 apptext--text font-weight-bold grey lighten-5"><v-icon class="apptext--text">icons8-twitter</v-icon>: {{ item.orgTwitter }}<br /></td>
                                                <td width="60%" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
                                                    <v-icon class="apptext--text">icons8-linkedin</v-icon>: <span> {{ item.orgLinkedIn }}<br /></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 apptext--text font-weight-bold grey lighten-5">
                                                    <v-icon class="apptext--text">icons8-facebook</v-icon>: <span> {{ item.orgFacebook }}<br /></span>
                                                </td>
                                                <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
                                                    <v-icon class="apptext--text">icons8-instagram</v-icon>: <span> {{ item.orgInstagram }}<br /></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <!-- <v-col cols="6" class="font-weight-normal apptext--text body-1 pl-2">
								<div class="my-2">
									<span v-if="item.orgTwitter"><v-icon class="apptext--text">icons8-twitter</v-icon>: {{ item.orgTwitter }}<br /></span>
								</div>
								<div class="mb-2">
									<span v-if="item.orgFacebook"
										><v-icon class="apptext--text">icons8-facebook</v-icon>: <span> {{ item.orgFacebook }}<br /></span
									></span>
								</div>
							</v-col>
							<v-col cols="6" class="font-weight-normal apptext--text body-1 pl-2">
								<div class="my-2">
									<span v-if="item.orgLinkedIn" class="pb-2"
										><v-icon class="apptext--text">icons8-linkedin</v-icon>: <span> {{ item.orgLinkedIn }}<br /></span
									></span>
								</div>
								<div class="my-2">
									<span v-if="item.orgInstagram"
										><v-icon class="apptext--text">icons8-instagram</v-icon>: <span> {{ item.orgInstagram }}<br /></span
									></span>
								</div>
							</v-col> -->
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="pl-1 mb-3"> </v-col>
                    <!-- //* NOTES VIEW -->
                    <v-col v-if="item.orgNotes" cols="12" sm="12" md="12" class="mb-3 fill-height">
                        <v-row class="grey lighten-5 rounded pa-2" no-gutters fill-height>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Notes</span>
                                    <v-spacer />
                                    <!-- <v-icon medium class="primary--text mr-3">icons8-secured-letter</v-icon> -->
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="font-weight-normal apptext--text body-1 pl-0">
                                <span v-if="item.orgNotes">{{ item.orgNotes }}<br /></span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="editmode" cols="12" class="d-flex align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- //* GENERAL DETAILS EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="pb-0">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Title</span>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-text-field label="Lead Title" v-model="leadTitle" :rules="rules.leadTitle" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadTitle')"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Country and Local Authority</span>
                                </v-col>
                                <v-col cols="12" xs="12" md="8">
                                    <v-btn-toggle class="flex-wrap " style="width: 100% !important" v-model="countryFilter" dense>
                                        <v-btn :width="$vuetify.breakpoint.mdAndUp ? '' : '50%'" v-for="country in countries" :key="country" :value="country" height="44" active-class="primary white--text" depressed
                                            ><div class="mx-3">{{ country }}</div></v-btn
                                        >
                                    </v-btn-toggle>
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                    <!-- <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Local Authority</span> -->
                                    <v-autocomplete label="Local Authority" v-model="item.leadLocalAuthority" :rules="rules.leadLocalAuthority" :items="computedLocalAuthority" item-text="authority" auto-select-first item-value="authority" outlined dense hide-details="auto" background-color="white" color="primary" no-data-text="Please select a Country first" clearable @click:clear="clearItem('localAuthorities')"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" class="pb-0">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Channel</span>
                                    <!-- <v-divider class="grey lighten-1 mb-1"></v-divider> -->
                                </v-col>
                                <v-col cols="12">
                                    <v-btn-toggle class="flex-wrap" style="width: 100% !important" v-model="item.leadChannel"  dense>
                                        <v-btn value="Education" active-class="primary white--text" style="width: 50% !important" :large="!GET_dense" depressed><div class="mx-3">Education</div></v-btn>
                                        <v-btn value="Workplace" active-class="primary white--text" style="width: 50% !important" :large="!GET_dense" depressed><div class="mx-3">Workplace</div></v-btn>
                                    </v-btn-toggle>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" class="pb-0">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Tags - <span class="darkgrey--text font-weight-normal body-2"> Please select one or more tags which best reflect this lead in order</span></span>
                                    <!-- <v-divider class="grey lighten-1 mb-1"></v-divider> -->
                                </v-col>
                                <v-col cols="12">
                                    <v-btn-toggle v-if="item.leadChannel === 'Education'" v-model="tags" class="d-flex flex-wrap justify-stretch grey lighten-3" multiple dense>
                                        <v-btn v-for="tag in leadEducationTags" :key="tag" :value="tag" active-class="primary white--text" small depressed
                                            ><div class="mx-3">{{ tag }}</div></v-btn
                                        >
                                    </v-btn-toggle>
                                    <v-btn-toggle v-else-if="item.leadChannel === 'Workplace'" v-model="tags" style="width: 100% !important" class="d-flex flex-wrap grey lighten-3" multiple dense>
                                        <v-btn v-for="tag in leadWorkplaceTags" :key="tag" :value="tag" active-class="primary white--text" small depressed
                                            ><div class="mx-3">{{ tag }}</div></v-btn
                                        >
                                    </v-btn-toggle>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Type</span>
                                            <v-btn-toggle v-model="item.leadType" class="" style="width: 100% !important" :color="chosenOneType" dense>
                                                <v-btn value="cold" active-class="info white--text" style="width: 33.33% !important" :large="!GET_dense">Cold</v-btn>
                                                <v-btn value="warm" active-class="warning white--text" style="width: 33.33% !important" :large="!GET_dense">Warm</v-btn>
                                                <v-btn value="hot" active-class="error white--text" style="width: 33.33% !important" :large="!GET_dense">Hot</v-btn>
                                            </v-btn-toggle>
                                        </v-col>
                                        <!-- TODO - EB - Change colour to reflect -->
                                        <v-col cols="12" md="6">
                                            <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Time Priority</span>
                                            <v-btn-toggle v-model="item.leadTimePriority" class="" style="width: 100% !important" :color="chosenOneTime" dense>
                                                <v-btn value="low" active-class="success white--text" style="width: 33.33% !important" :large="!GET_dense" depressed>Low</v-btn>
                                                <v-btn value="medium" active-class="warning white--text" style="width: 33.33% !important" :large="!GET_dense" depressed>Medium</v-btn>
                                                <v-btn value="high" active-class="error white--text" style="width: 33.33% !important" :large="!GET_dense" depressed>High</v-btn>
                                            </v-btn-toggle>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <!-- TODO - EB - Dropdown autocomplete -->
                                <v-col cols="12">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Source Details</span>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-autocomplete label="Source" v-model="item.leadSource" :rules="rules.leadSource" :items="sources" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgOtherAddressType')" auto-select-first></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <!-- <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Source Name</span> -->
                                    <v-text-field label="Source Name" v-model="item.leadSourceName" :rules="rules.leadSourceName" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadSourceName')"></v-text-field>
                                </v-col>
                            </v-row>
                            <!-- <v-row dense class="pt-6">
=								<v-col cols="12" xs="12">
									<v-textarea label="Description" v-model="item.leadDescription" :rules="rules.leadDescription" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadDescription')"></v-textarea>
								</v-col>
                            </v-row> -->
                            <v-row dense>
                                <!-- TODO - EB - dropwdown autcomplete multiselect -->
                                <v-col cols="12" xs="12">
                                    <v-autocomplete label="Tag Organisations" v-model="selectedOrgTags" :rules="rules.leadOrgTags" :items="organisations" multiple outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadOrgTags')">
                                        <template v-slot:selection="data">
                                            {{ data.item.orgName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.orgName }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <!-- TODO - EB - dropwdown autocomplete multiselect -->
                                <v-col cols="12" xs="12">
                                    <v-autocomplete label="Tag Contacts" v-model="selectedContactTags" :rules="rules.leadContactTags" :items="contacts" multiple outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadContactTags')">
                                        <template v-slot:selection="data">
                                            {{ data.item.contactName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.contactName }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <!-- TODO - EB - Dropdown of statuses -->
                                <v-col cols="12" xs="12" md="6">
                                    <!-- <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Status</span> -->
                                    <v-select label="Lead Status" v-model="item.leadStatus" :rules="rules.leadStatus" :items="leadStatuses" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStatus')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                                </v-col>
                                <!-- TODO - EB - Default to creator/autocomplete of all users -->
                                <v-col cols="12" xs="12" md="6">
                                    <!-- <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Owner</span> -->
                                    <v-select label="Lead Owner" v-model="selectedLeadOwner" :rules="rules.leadOwner" :items="users" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStatus')" :menu-props="{ top: false, offsetY: true }" :attach="true">
                                        <template v-slot:selection="data">
                                            {{ data.item.userName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.userName }}
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>

                            <!-- <v-row dense>
								<v-col cols="12" xs="12" md="4">
                                   
									<v-select label="Status" v-model="item.leadStatus" :rules="rules.leadStatus" :items="leadStatuses" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStatus')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
								</v-col>
								<v-col cols="12" xs="12" md="4" class="">
									<v-text-field label="Type" v-model="item.leadType" :rules="rules.leadType" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadType')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" md="4">
									<v-text-field label="Channel" v-model="item.leadChannel" :rules="rules.leadChannel" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadChannel')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" md="4">
									<v-text-field label="Time Priority" v-model="item.leadTimePriority" :rules="rules.leadTimePriority" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadTimePriority')"></v-text-field>
								</v-col>
                            </v-row> -->
                        </v-col>
                        <!-- <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
								<v-col cols="12" class="pb-2">
									<span class="text-left primary--text text-h6 font-weight-light" colspan="12">Description</span>
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col cols="12" xs="12">
									<v-text-field label="Description" v-model="item.leadDescription" :rules="rules.leadDescription" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadDescription')"></v-text-field>
								</v-col>
                            </v-row>
                        </v-col>
						<v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
								<v-col cols="12" class="pb-2">
									<span class="text-left primary--text text-h6 font-weight-light" colspan="12">Area</span>
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
                            </v-row>
                            <v-row dense>
								<v-col cols="12" xs="12" md="6">
									<v-text-field label="Country" v-model="item.leadCountry" :rules="rules.leadCountry" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadCountry')"></v-text-field>
								</v-col>
                            </v-row>

                            <v-row dense>
								<v-col cols="12" class="pb-2">
									<span class="text-left primary--text text-h6 font-weight-light" colspan="12">Source</span>
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col cols="12" xs="12" md="6">
									<v-text-field label="Source" v-model="item.leadSource" :rules="rules.leadSource" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadSource')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" md="6">
									<v-text-field label="Source Name" v-model="item.leadSourceName" :rules="rules.leadSourceName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadSourceName')"></v-text-field>
								</v-col>
                            </v-row>
                            

                            <v-row dense>
								<v-col cols="12" class="pb-2">
									<span class="text-left primary--text text-h6 font-weight-light" colspan="12">Tags</span>
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
								</v-col>
								<v-col cols="12" xs="12" md="4">
									<v-text-field label="Tag Organisations" v-model="item.leadOrgTags" :rules="rules.leadOrgTags" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadOrgTags')"></v-text-field>
								</v-col>
								<v-col cols="12" xs="12" md="4">
									<v-autocomplete label="Tag Contacts" v-model="item.leadContactTags" :rules="rules.leadContactTags" :items="addressTypes" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadContactTags')"></v-autocomplete>
								</v-col>
								<v-col cols="12" xs="12" md="4">
									<v-text-field label="Tags" v-model="item.leadTags" :rules="rules.leadTags" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadTags')"></v-text-field>
								</v-col>
                            </v-row>

                            <v-row dense>
								<v-col cols="12" xs="12" md="6">
									<v-text-field label="Owner" v-model="item.leadOwners" :rules="rules.leadOwners" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadOwners')"></v-text-field>
								</v-col>
							</v-row>
						</v-col> -->
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <!-- Save / Cancel Buttons -->
        <v-row dense>
            <v-col v-if="editmode" cols="12" class="mt-2 mb-5 grey darken-1 pa-3 pr-4 pl-4 d-flex justify-right align-center">
                <div>
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <v-spacer />
                <div class="ml-2">
                    <v-btn class="accent primary--text font-weight-bold" depressed @click="saveItem"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12">
                <pre>{{ item }}</pre>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        itemnew: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        editmode: false,

        // Item
        item: {},

        // Validation
        rules: {},

        // tags
        orgTags: [], // Required for orgs tags dropdown

        tagAutocomplete: '',

        // Toggles
        addAddressToggle: false,
        addManualAddress: false,
        addOtherAddressToggle: false,
        addOrgInfoToggle: false,
        addNotesToggle: false,
        addOwnerInfoToggle: false,
        addSocialToggle: false,
        addTagsToggle: false,

        // Form items
        selectedLeadStatus: [],
        selectedOrgTags: [],
        selectedContactTags: [],
        leadStatuses: [],
        sources: [],
        leadTitle: '',
        selectedLeadOwner: '',

        countryFilter: '',
        tags: [],

        localAuthority: [],
        countries: [],
        leadEducationTags: [],
        leadWorkplaceTags: [],

        englishLocalAuthorities: [],
        welshLocalAuthorities: [],
        scotlandLocalAuthorities: [],
        northernIrelandLocalAuthorities: [],
        localAuthorities: [],
        organisations: [],
        contacts: [],
        users: [],

        chosenOneTime: '',
        chosenOneType: '',
    }),
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
        }),
        computedLocalAuthority() {
            if (this.countryFilter === '') {
                return [];
            } else {
                return this.localAuthority.filter((item) => {
                    return item.country === this.countryFilter;
                });
            }
            // 	return this.localAuthority.filter(function (item) {
            // 		return item.country === this.countryFilter;
            // 	});
            // } else {
            //}
        },
    },
    // * METHODS
    methods: {
        cancelItem() {
            this.editmode = false;
            this.$emit('cancel');
        },
        // Save Item
        saveItem() {
            console.log('save item in leadDetails.vue.vue');
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Clear Selected
        clearSelectedLeadStatus() {
            setTimeout(() => {
                this.selectedLeadStatus = [];
            }, 1);
        },
        // Clear Selected Channel
        clearSelectedChannel() {
            setTimeout(() => {
                this.selectedChannel = [];
            }, 1);
        },
        // Clear Selected Industry
        clearSelectedIndustries() {
            setTimeout(() => {
                this.selectedIndustry = [];
            }, 1);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        formatTag: function (item) {
            if (item !== null) {
                return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            } else {
                return '';
            }
        },
        close() {
            this.$emit('close');
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
                if (this.item.leadStatus !== '') {
                    this.selectedLeadStatus = this.item.leadStatus.split(',');
                }
                // if (this.item.orgTags !== "") {
                // 	this.selectedTags = this.item.orgTags.split(",");
                // }
                // if (this.item.orgIndustry !== "") {
                //     this.selectedIndustry = this.item.orgIndustry.split(",");
                // }
                // if (this.item.orgChannel !== "") {
                //     this.selectedChannel = this.item.orgChannel.split(",");
                // }
            },
        },
        selectedOrgTags: {
            handler: function () {
                this.item.leadOrgTags = '';
                for (let i = 0; i < this.selectedOrgTags.length; i++) {
                    this.item.leadOrgTags = this.item.leadOrgTags + this.selectedOrgTags[i].orgName + ',';
                }
                console.log('selectedOrgTags = ' + JSON.stringify(this.selectedOrgTags, null, 2));
            },
            deep: true,
        },
        selectedContactTags: {
            handler: function () {
                this.item.leadContactTags = '';
                for (let i = 0; i < this.selectedContactTags.length; i++) {
                    this.item.leadContactTags = this.item.leadContactTags + this.selectedContactTags[i].contactName + ',';
                }
                console.log('selectedContactTags = ' + JSON.stringify(this.selectedContactTags, null, 2));
            },
            deep: true,
        },
        selectedLeadStatus: {
            handler: function () {
                this.item.leadStatus = this.selectedLeadStatus.join(',');
            },
        },
        selectedIndustry: {
            handler: function () {
                this.item.orgIndustry = this.selectedIndustry.join(',');
            },
        },
        selectedChannel: {
            handler: function () {
                this.item.orgChannel = this.selectedChannel.join(',');
            },
        },
        tags: {
            handler: function () {
                this.item.leadTags = this.tags.join(',');
            },
        },
        selectedLeadOwner: {
            handler: function () {
                this.item.leadOwner = this.selectedLeadOwner.userName;
                console.log('selectedLeadOwner = ' + JSON.stringify(this.selectedLeadOwner.userName, null, 2));
            },
        },
        // Selected Tags
        selectedTags: {
            handler: async function () {
                // Force Title Case on Tags
                for (let i = 0; i < this.selectedTags.length; i++) {
                    this.selectedTags[i] = this.selectedTags[i].toLowerCase(); //replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
                    this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, ' ');
                    if (this.selectedTags[i] === ' ') {
                        this.selectedTags.splice(i, 1);
                    } else {
                        this.selectedTags[i] = this.selectedTags[i].trim();
                    }
                    if (this.orgTags.indexOf(this.selectedTags[i]) === -1) {
                        let addToSetResult = await this.MIX_redisAddToSet(`set:orgTags`, this.selectedTags[i]);
                        if (addToSetResult.code === -1) {
                            //console.log(addToSetResult.message);
                        }
                    }
                }
                // Update Quote Tags and split out array with commas
                this.item.orgTags = this.selectedTags.join(',');
            },
            deep: true,
        },
        itemnew: {
            handler: function () {
                if (this.itemnew) {
                    this.editmode = true;
                }
            },
        },
        leadTitle: {
            handler: function () {
                this.item.leadTitle = this.leadTitle;
                console.log('lead title = ' + this.item.leadTitle);
            },
        },
        // item: {
        //     handler() {
        //         var leadTitle = '';
        //         if (this.item.leadTags !== '') {
        //             for (let i = 0; i < this.item.leadTags.length; i++) {
        //                 if (this.item.leadTags[i] === ',') {
        //                     leadTitle = leadTitle + ' ';
        //                 } else {
        //                     leadTitle = leadTitle + this.item.leadTags[i];
        //                 }
        //             }
        //         }
        //         if (this.item.leadLocalAuthority !== '') {
        //             leadTitle = leadTitle + ' in ' + this.item.leadLocalAuthority;
        //         }
        //         this.leadTitle = leadTitle;
        //     },
        //     deep: true,
        // },
    },
    // * CREATED
    async created() {
        let t = this;
        t.item = { ...t.datadown };
        t.loading = true;
        if (t.itemnew) {
            t.editmode = true;
        }
        // Get Local Authority
        let localAuthoritiesResult = await this.MIX_redisSearch('idx:localAuthority', `*`, 0, 9999);
        t.localAuthority = localAuthoritiesResult.data.documents.map((x) => x.value);
        // Get Countries
        t.countries = Array.from(new Set(t.localAuthority.map((x) => x.country)));
        // Get Lead Education Tags
        let leadEducationTagsResult = await t.MIX_redisReadSet('set:leadTagsEducation', 0, 9999);
        if (leadEducationTagsResult.code === 1) {
            t.leadEducationTags = leadEducationTagsResult.data;
        }
        // Get Lead Workplace Tags
        let leadWorkplaceTagsResult = await t.MIX_redisReadSet('set:leadTagsWorkplace', 0, 9999);
        if (leadWorkplaceTagsResult.code === 1) {
            t.leadWorkplaceTags = leadWorkplaceTagsResult.data;
        }

        let leadStatuses = await t.MIX_redisReadSortedSet('sset:leadStatus', 0, 9999);
        if (leadStatuses.code === 1) {
            t.leadStatuses = leadStatuses.data;
        }

        let organisations = await t.MIX_organisations();
        t.organisations = organisations;

        let contacts = await t.MIX_contacts();
        t.contacts = contacts;

        let users = await t.MIX_users();
        t.users = users;

        let sources = await t.MIX_redisReadSet('set:sources', true);
        if (sources.code === 1) {
            t.sources = sources.data;
        }

        let englishLocalAuthorities = await t.MIX_redisReadSet('set:englishLocalAuthorities', true);
        if (englishLocalAuthorities.code === 1) {
            t.englishLocalAuthorities = englishLocalAuthorities.data;
        }

        let welshLocalAuthorities = await t.MIX_redisReadSet('set:welshLocalAuthorities', true);
        if (welshLocalAuthorities.code === 1) {
            t.welshLocalAuthorities = welshLocalAuthorities.data;
        }
        let scottishLocalAuthorities = await t.MIX_redisReadSet('set:scottishLocalAuthorities', true);
        if (scottishLocalAuthorities.code === 1) {
            t.scotlandLocalAuthorities = scottishLocalAuthorities.data;
        }
        let northernIrelandLocalAuthorities = await t.MIX_redisReadSet('set:northernIrelandLocalAuthorities', true);
        if (northernIrelandLocalAuthorities.code === 1) {
            t.northernIrelandLocalAuthorities = northernIrelandLocalAuthorities.data;
        }

        t.item = { ...t.datadown };
        this.selectedLeadStatus = this.item.leadStatus.split(',');
        // this.selectedIndustry = this.item.orgIndustry.split(",");
        // this.selectedChannel = this.item.orgChannel.split(",");


    },
};
</script>

<style scoped>
.greenBtnToggle {
    background-color: #e8f5e9 !important;
}
</style>
