import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// * Import modular stores
import firebase_auth_store from "@/firebase/firebase_auth_store.js";

// Persisted State Required for Maintaining Login State
import createPersistedState from "vuex-persistedstate";


export default new Vuex.Store({
	plugins: [ createPersistedState() ],
	state: {
		alertBox: { color: "success", timeout: 2000, message: "Success", show: false }, // alertBox state
		appUpdate: { show: false },
		taskBar: false,
		dense: false,	
		destroy: true,	
        puDialog: { show: false, dynamicComponent: 'blank', width: '500px', height: '500px' },
	},
	getters: {
        GET_currentUser(state) { return state.currentUser;},
        // USER DATA    
        GET_currentUserData(state) { return {
            userId: state.currentUser.id,
            userName: state.currentUser.userName,
            userEmail: state.currentUser.userEmail
        }},
		GET_alertBox(state) { return state.alertBox }, // alertBox getters
		GET_appUpdate(state) { return state.appUpdate }, // appUpdate getters
		GET_taskBar(state) { return state.taskBar }, // taskBar getters
		GET_dense(state) { return state.dense },
		GET_destroy(state) { return state.destroy },		
	},
	mutations: {
		MUT_alertBox(state, payload) { state.alertBox =  payload }, // alertBox mutation
		MUT_appUpdate(state, payload) { state.appUpdate =  payload }, // appUpdate mutation
		MUT_taskBar(state) { state.taskBar = !state.taskBar }, // taskBar mutations
		MUT_dense(state, payload) {
			state.dense = payload;
			localStorage.setItem('dense', payload);
		},
        SET_currentUser(state, payload) {
          state.currentUser = payload;
        },
		MUT_destroy(state, payload) {
			state.destroy = payload;
			localStorage.setItem('destroy', payload);
		},		

	},
	actions: {
		ACT_alertBox({commit}, payload) { commit('MUT_alertBox', payload) }, // alertBox action
		ACT_appUpdate({commit}, payload) { commit('MUT_appUpdate', payload) }, // appUpdate action		
		ACT_dense({commit}, payload) { commit('MUT_dense', payload) },
		ACT_taskBar({commit}) { commit('MUT_taskBar') }, // taskBar action
		ACT_destroy({commit}, payload) { commit('MUT_destroy', payload) },
	},
	modules: {
        // alertbox_store: alertbox_store,
        firebase_auth_store: firebase_auth_store,

    },
});
