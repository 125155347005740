<template>
	<div class="white" style="width: 100% !important; height: 100vh !important: overflow-y: hidden !important" fluid>
		<quote-print />
	</div>
</template>

<script>
	import quotePrint from "@/views/orders/orderPrint.vue";
	export default {
		// * COMPONENTS
		components: {
			"quote-print": quotePrint
		},
	}
</script>