<template>
    <v-row fill-height class="grey lighten-2" style="height: 100% !important; overflow-y: scroll !important" no-gutters>
        <v-col class="align-center pa-3 mt-3" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            <div class="d-flex align-center">
                <div><v-icon class="grey--text" x-large>icons8-sorting</v-icon></div>
                <div class="text-left headingtext--text text-h4 font-weight-light ml-1" v-if="!listShrunk">Categories</div>
                <v-spacer v-if="!listShrunk"></v-spacer>
                <div class="ml-1">
                    <v-btn depressed block class="accent secondary--text" @click="openItem({})"><v-icon class="mr-3">icons8-sorting</v-icon>New</v-btn>
                </div>
                <v-spacer v-if="listShrunk"></v-spacer>
                <div>
                    <v-btn class="primary--text ml-1" @click="listExpanded = true" depressed v-if="!listExpanded && !listShrunk">
                        <v-icon color="primary">icons8-forward</v-icon>
                    </v-btn>
                    <v-btn class="primary--text ml-1" depressed @click="getItems()">
                        <v-icon color="primary">icons8-available-updates</v-icon>
                    </v-btn>
                    <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn depressed class="primary--text ml-1" v-bind="attrs" v-on="on" v-if="!listShrunk"><v-icon class="" color="primary">icons8-table-properties</v-icon></v-btn>
                        </template>
                        <v-card class="pa-5" width="250">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </v-card>
                    </v-menu>
                </div>
            </div>
            <div class="d-flex align-center my-3">
                <!-- <div class=""> -->
                    <v-text-field label="Search Categories" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field>
                <!-- </div> -->
            </div>
            <!-- <pre>Itmes: {{ items }}</pre> -->
            <!-- TABLE BASED LIST -->
            <v-data-table 
                v-if="!listShrunk" 
                sortable 
                dense 
                class="" 
                :headers="computedHeaders" 
                :items="computedItems" 
                >
                <template v-slot:top>
                    <div class="d-flex align-center">
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <!-- <v-btn value="destroy" small v-if="['Developer-Admin'].includes(userLevel)">
                                <v-icon class="error--text">icons8-delete-trash</v-icon>
                            </v-btn> -->
                            <v-btn value="delete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                            <!-- <v-btn value="undelete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                            </v-btn> -->
                        </v-btn-toggle>
                    </div>
                </template>                
                
                <template v-slot:item.action="{ item }">
                    <!-- <v-icon class="error--text pa-1" v-if="toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon> -->
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item)" v-if="toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="primary--text " @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>
            <!-- <pre>Computed Items: {{ computedItems }}</pre> -->
            <!-- CARD BASED LIST -->
            <div v-if="listShrunk" style="height: 67vh; overflow-y: scroll" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-2 font-weight-bold" style="width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.attributeName }}</div>
                            Code: <span class="body-2">{{ item.attributeCode }}</span>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text" @click="openItem(item)">icons8-forward</v-icon>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-col>

        <transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col style="border-left: lightgrey solid 2px !important" v-if="!listExpanded" class="grey lighten-3" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <category-item v-if="!listExpanded" v-on:refreshItems="getItems" v-on:updateItem="update" v-on:createItem="create" :activeitem="activeItem" v-on:closeItem="listExpanded = true" />
            </v-col>
        </transition>
        <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col style="border-left: lightgrey solid 2px !important" class="" v-if="GET_taskBar" :cols="taskCols.cols" :xs="taskCols.xs" :sm="taskCols.sm" :md="taskCols.md" :lg="taskCols.lg" :xl="taskCols.xl">
                <taskbar />
            </v-col>
        </transition>
        <v-dialog v-model="dialog" fullscreen>
            <v-card>
                csvHeaders: {{ csvHeaders }}

                <import-csv v-on:close="dialog = false" :csvheaders="csvHeaders" :prefix="key" rediskey="attribute" />
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import schema from '@/schema.js';
import importCSV from '@/views/base/importCSV.vue';
import taskBar from '@/views/tasks/taskbar.vue';
import category from '@/views/categories/category.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        'category-item': category,
        taskbar: taskBar,
        'import-csv': importCSV,
    },
    data: () => ({
        key: 'attribute',
        index: 'idx:attributes',
        dialog: false,
        schema: {},
        toggleFeatures: [],
        showImages: false,
        items: [],
        filter: {
            orgId: '',
        },
        itemsTotal: null,
        listExpanded: true,
        listShrunk: false,
        searchQuery: '',
        organisations: [],        
        itemsFrom: 0,
        itemsTo: 10,
        activeItem: {},
        // Headers to display in the Data Table
        headers: [
            { text: 'Name', value: 'name', hidden: false },
            { text: 'Product Subcategory', value: 'productSubcategory', hidden: false },
            { text: 'Item Types', value: 'itemTypes', hidden: false },
            { text: 'Installation', value: 'installation', hidden: false },
            { text: 'Action', value: 'action', align: 'end', hidden: false, width: '155px' },
        ],
        // Fields to Retrieve from Database
        sortBy: '@attName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['attName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        itemCols: { cols: 12, xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
        taskCols: { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
    }),
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@orgId')) {
                computedFields.push('@orgId');
            }
            if (!computedFields.includes('@attSupplier')) {
                computedFields.push('@attSupplier');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleDeletedItems) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0`;
                    }
                } else {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0`;
                    }
                }
            }
            // Organisation Filter
            if (this.filter.orgId !== '' && this.filter.orgId !== null && this.filter.orgId !== undefined) {
                searchQuery = `${searchQuery}  @orgId:{${this.filter.orgId}}`;
            }            
            return searchQuery;
        },
        computedHeaders() {
            return this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
        },
        computedItems() {
            var items = []
            items = this.items; //.filter(x => x.);

            // search items 
            if (this.searchQuery !== '') {
                items = items.filter((item) => {
                    return Object.keys(item).some((key) => {
                        return String(item[key]).toLowerCase().includes(this.searchQuery.toLowerCase());
                    });
                });
            }

            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        csvHeaders() {
            let schema = [];
            for (const property in this.schema) {
                schema.push(property);
            }
            return schema;
        },
    },
    methods: {
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(item) {
            var index = this.items.findIndex((x) => x.name === item.name);
            this.items.splice(index, 1);
            this.saveString()
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            console.log('opening item = ' + JSON.stringify(item, null, 2));
            var index = this.items.findIndex((x) => x.name === item.name);
            this.activeItem = item;
            this.activeItem.index = index
            this.listExpanded = false;
        },
        create(item) {
            // var t = this; 

            console.log('creating item = ' + JSON.stringify(item, null, 2));

            this.items.push(item);

            this.saveString()
        },
        update(item) {
            // var t = this; 

            console.log('updating item = ' + JSON.stringify(item, null, 2));

            var index = item.index 
            this.items[index].name = item.name;
            this.items[index].productSubcategory = item.productSubcategory;
            this.items[index].itemTypes = item.itemTypes;
            this.items[index].installation = item.installation;

            // remove index
            delete this.items[index].index

            this.saveString()
        },
        // * SAVE STRING
        async saveString() {

            var wholeString = {
                'productCategory': this.items,
            };

            let deleteResult = await this.MIX_redisDeleteString('PGSR');

            if (deleteResult.code === 1) {
                let result = await this.MIX_redisCreateString('PGSR', JSON.stringify(wholeString), null);
                if (result.code === 1) {
                    this.getItems();
                }
            }
        },
        // * GET ITEMS
        async getItems() {
            let itemsResult = await this.MIX_redisReadString('PGSR');
            if (itemsResult.code === 1) {
                var items = JSON.parse(itemsResult.data);
                this.items = items['productCategory'];
            }
        },
        refreshCols() {
            switch (true) {
                // Expanded List with No Task Bar
                case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Expanded List with Task Bar
                case this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // List with Product Page (No Task Bar)
                case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // List with Product Page and Task Bar
                case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // Shrunk List with Product Page (No Task Bar)
                case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    this.itemCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Shrunk List with Product Page and Task Bar)
                case !this.listExpanded && this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;

                default:
                    this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
            }
        },
        exportCSV() {
            // console.log('exportCSV')
            let csv = this.$papa.unparse(this.items);
            this.$papa.download(csv, 'My title');
            //console.log('uparse ' + JSON.stringify(csv, null, 2))
        },
    },
    watch: {
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'fabricMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                let itemsPerPage = this.datatableOptions.itemsPerPage;
                if (itemsPerPage === -1) {
                    itemsPerPage = this.itemsTotal;
                } else {
                    itemsPerPage = this.datatableOptions.itemsPerPage;
                }
                this.itemsFrom = this.datatableOptions.page * itemsPerPage - itemsPerPage;
                let itemsTo = this.datatableOptions.page * itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
        listExpanded() {
            this.refreshCols();
        },
        listShrunk() {
            this.refreshCols();
        },
        GET_taskBar() {
            this.refreshCols();
        },
        computedFields: {
            handler() {
                this.getItems();
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.attribute;
        t.getItems();
		// Get Organisations
        t.organisations = await t.MIX_organisations("Supplier");                
    },
    mounted() {
        this.refreshCols();
    },
};
</script>
