<template>
    <v-container class="mt-8">
        <v-col cols="12" class="d-flex justify-center">
            <v-img src="@/assets/logo.png" max-width="150" />
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
            <span class="text-h6 primary--text"
                >Welcome to Ministry of Furniture</span
            >
        </v-col>
        <v-col cols="12" class="d-flex flex-column align-center secondary--text">
            <span v-if="userStatus === 'Pending'" class="mb-4"
                >Your account status is currently
                <span class="warning--text font-weight-bold">PENDING</span>,
                please wait for your account to be Approved.</span>
                <span v-if="userStatus === 'Approved'" class="mb-4"
                >Your account status is currently
                <span class="success--text font-weight-bold">APPROVED</span>,
                please goto home.</span>
                <span> If you are waiting more than 48 hours for your account to be approved please contact
                <a
                    :href="'mailto:support@ministryoffurniture.com'"
                    class="font-weight-bold"
                    >support@ministryoffurniture.com</a
                ></span
            >
        </v-col>
    </v-container>
</template>

<script>
import { doc, onSnapshot } from "firebase/firestore";
import { mapGetters } from "vuex";
export default {
    name: "Holding",
    data: () => ({
        userStatus: null,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
    },
    async created() {
        await onSnapshot(
            doc(
                this.$firebase.db,
                "users",
                this.GET_FIREBASE_userAuth.multiFactor.user.uid
            ),
            (doc) => {
                this.userStatus = doc.data().userStatus;
            }
        );
    },
};
</script>