<template>
    <div class="d-flex flex-column" fill-height style="height: calc(100vh - 80px) !important">
        <v-list nav dense>
            <v-list-item link @click="MIX_go('/contacts')" :disabled="userStatus !== 'Approved'" v-if="!['Customer-User', 'Supplier-User', 'Guest-User'].includes(userLevel)">
                <v-list-item-icon>
                    <v-icon>icons8-user-groups</v-icon>
                </v-list-item-icon>
                <v-list-item-title>People</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="MIX_go('/customers')" :disabled="userStatus !== 'Approved'" v-if="!['Customer-User', 'Supplier-User', 'Guest-User'].includes(userLevel)">
                <v-list-item-icon>
                    <v-icon>icons8-hotel-building</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Customers</v-list-item-title>
            </v-list-item>
            <!-- :disabled="userStatus !== 'Approved'"  -->
            <!-- <v-list-item disabled link @click="MIX_go('/leads')" v-if="!['Customer-User', 'Supplier-User', 'Guest-User'].includes(userLevel)">
                <v-list-item-icon>
                    <v-icon>icons8-business-network</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Leads</v-list-item-title>
            </v-list-item> -->
            <v-list-item link @click="MIX_go('/prospects')" :disabled="userStatus !== 'Approved'" v-if="!['Customer-User', 'Supplier-User', 'Guest-User'].includes(userLevel)">
                <v-list-item-icon>
                    <v-icon>icons8-business-group</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Prospects</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item link @click="MIX_go('/leads')" disabled v-if="!['Customer-User', 'Supplier-User', 'Guest-User'].includes(userLevel)">
                <v-list-item-icon>
                    <v-icon>icons8-pipeline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Opportunities</v-list-item-title>
            </v-list-item> -->
<!--          TODO: SBT - Add Sales back in when ready-->
<!--            <v-list-item link @click="MIX_go('/sales')" :disabled="userStatus !== 'Approved'" v-if="!['Customer-User', 'Supplier-User', 'Guest-User'].includes(userLevel)">-->
<!--              <v-list-item-icon>-->
<!--                <v-icon>icons8-profit</v-icon>-->
<!--              </v-list-item-icon>-->
<!--              <v-list-item-title>Sales</v-list-item-title>-->
<!--            </v-list-item>-->

            <v-list-item link @click="MIX_go('/quotes')" :disabled="userStatus !== 'Approved'" v-if="!['Customer-User', 'Supplier-User', 'Guest-User'].includes(userLevel)">
                <v-list-item-icon>
                    <v-icon>icons8-bill</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Quotes</v-list-item-title>
            </v-list-item>
<!--          TODO: SBT - Add Orders back in when ready-->
<!--            <v-list-item link @click="MIX_go('/orders')" :disabled="userStatus !== 'Approved'" v-if="!['Developers'].includes(userLevel)">-->
<!--				<v-list-item-icon>-->
<!--					<v-icon>icons8-receipt-approved</v-icon>-->
<!--				</v-list-item-icon>-->
<!--				<v-list-item-title>Orders</v-list-item-title>-->
<!--			</v-list-item>-->
            <v-list-item link @click="MIX_go('/suppliers')" :disabled="userStatus !== 'Approved'" v-if="!['Customer-User', 'Supplier-User', 'Guest-User'].includes(userLevel)">
                <v-list-item-icon>
                    <v-icon>icons8-factory</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Suppliers</v-list-item-title>
            </v-list-item>
            <v-list-item @click="MIX_go('products')" v-if="['Administration', 'Purchasing', 'Marketing', 'Board', 'Design', 'Developers'].includes(userGroup)">
                <v-list-item-icon>
                    <v-icon>icons8-tags</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Products</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item @click="MIX_go('fabrics')" :disabled="userStatus !== 'Approved'">
                <v-list-item-icon>
                    <v-icon>icons8-cloth</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Fabrics</v-list-item-title>
            </v-list-item>
            <v-list-item @click="MIX_go('colours')" :disabled="userStatus !== 'Approved'">
                <v-list-item-icon>
                    <v-icon>icons8-color-mode</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Colours</v-list-item-title>
            </v-list-item> -->
            <v-list-item @click="MIX_go('options')" v-if="['Administration', 'Purchasing', 'Marketing', 'Board', 'Design', 'Developers'].includes(userGroup)" :disabled="userStatus !== 'Approved'">
                <v-list-item-icon>
                    <v-icon>icons8-sewing-patch</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Options</v-list-item-title>
            </v-list-item>
            <v-list-item @click="MIX_go('attributes')" v-if="['Administration', 'Purchasing', 'Marketing', 'Board', 'Design', 'Developers'].includes(userGroup)" :disabled="userStatus !== 'Approved'">
                <v-list-item-icon>
                    <v-icon>icons8-list</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Attributes</v-list-item-title>
            </v-list-item>
            <v-list-item @click="MIX_go('categories')" v-if="['Administration', 'Purchasing', 'Marketing', 'Board', 'Design', 'Developers'].includes(userGroup)" :disabled="userStatus !== 'Approved'">
                <v-list-item-icon>
                    <v-icon>icons8-sorting</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Categories</v-list-item-title>
            </v-list-item>
            <v-list-item @click="MIX_go('users')" v-if="['Developer-Admin', 'Staff-Admin'].includes(userLevel)" :disabled="userStatus !== 'Approved'">
                <v-list-item-icon>
                    <v-icon>icons8-people</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Users</v-list-item-title>
            </v-list-item>

            
            <v-list-item @click="MIX_go('deletedata')" v-if="['Developer-Admin', 'Staff-Admin'].includes(userLevel)" :disabled="userStatus !== 'Approved'">
                <v-list-item-icon>
                    <v-icon>icons8-delete-trash</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Delete Data</v-list-item-title>
            </v-list-item>
            <v-list-item @click="MIX_go('importdata')" v-if="['Developer-Admin', 'Staff-Admin'].includes(userLevel)" :disabled="userStatus !== 'Approved'">
                <v-list-item-icon>
                    <v-icon>icons8-upload-document</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Import Data</v-list-item-title>
            </v-list-item>
            <v-list-item @click="MIX_go('productsbulk')" v-if="['Developer-Admin', 'Staff-Admin'].includes(userLevel)" :disabled="userStatus !== 'Approved'">
                <v-list-item-icon>
                    <v-icon>icons8-search-in-list</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Bulk Product Changes</v-list-item-title>
            </v-list-item>


            <!--
			<v-list-item @click="MIX_go('admin')"  :disabled="userStatus !== 'Approved'">
				<v-list-item-icon>
					<v-icon>icons8-lock</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Admin</v-list-item-title>
			</v-list-item> 
		
			<v-list-item @click="MIX_FIREBASE_logout()" :disabled="userStatus !== 'Approved'">
				<v-list-item-icon>
					<v-icon>icons8-logout</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Log Out</v-list-item-title>
			</v-list-item>

			<v-list-item link @click="MIX_go('/organisations')" :disabled="userStatus !== 'Approved'">
				<v-list-item-icon>
					<v-icon>icons8-city</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Organisations</v-list-item-title>
			</v-list-item> -->
        </v-list>
        <!-- <v-spacer></v-spacer>
	<transition  name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
		<v-row class="ma-2 pa-2 flex-grow-0" no-gutters align="end" v-if="!mini">
			<v-img width="80" height="80" contain src="@/assets/logo.png" />
		</v-row>
	</transition> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'SideNavigation',
    props: {
        mini: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        user: {
            displayName: '',
        },
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },
    },
    mounted() {},
};
</script>

<style scoped></style>
