<template>
  <v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important"
         no-gutters>
    <v-col class="align-center mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md"
           :lg="itemsCols.lg" :xl="itemsCols.xl">

      <div class="d-flex align-center pa-3 pb-0">
        <div class="d-flex align-center pr-8 pb-2">
          <div>
            <v-icon class="primary--text" x-large>icons8-bill</v-icon>
          </div>
          <div class="text-left primary--text pl-1 text-h4 font-weight-thin">Sales</div>
        </div>
        <!-- <v-spacer></v-spacer> -->
        <v-btn style="height: 50px !important; width: 200px !important" :ripple="false" @click="showLeads()"
               :color="tab === 'leads' ? '#757575' : '#DFDFDF'" class="mx-1 mb-0 elevation-0 pageButton">
          <div class="d-flex flex-column align-center">
            <div><span class="text-capitalize text-h7"
                       :class="tab === 'leads' ? 'white--text' : 'grey--text'">Leads</span></div>
          </div>
        </v-btn>
        <v-btn style="height: 50px !important; width: 200px !important" :ripple="false" @click="showOpportunities()"
               :color="tab === 'opportunities' ? '#757575' : '#DFDFDF'" class="mx-1 mb-0 elevation-0 pageButton">
          <div class="d-flex flex-column align-center">
            <div><span class="text-capitalize text-h7"
                       :class="tab === 'opportunities' ? 'white--text' : 'grey--text'">Opportunities</span></div>
          </div>
        </v-btn>
        <!--                <v-btn style="height: 50px !important; width: 200px !important" :ripple="false" @click="showRenewals()" :color="tab === 'renewals' ? '#757575' : '#DFDFDF'" class="mx-1 mb-0 elevation-0 pageButton">-->
        <!--                    <div class="d-flex flex-column align-center">-->
        <!--                        <div><span class="text-capitalize text-h7" :class="tab === 'renewals' ? 'primary--text' : 'grey--text'">Renewals</span></div>-->
        <!--                    </div>-->
        <!--                </v-btn>-->
        <!-- <v-btn style="height: 50px !important; width: 200px !important" :ripple="false" disabled :color="tab === 'orders' ? '#757575' : '#DFDFDF'" class="mx-1 mb-0 elevation-0 pageButton">
            <div class="d-flex flex-column align-center">
                <div><span class="text-capitalize text-h7" :class="tab === 'orders' ? 'primary--text' : 'grey--text'">Orders</span></div>
            </div>
        </v-btn> -->
        <v-spacer/>
<!--        EDIT STAGES-->
        <div class="mr-3 pb-3" v-if="userLevel === 'Developer-Admin' || userLevel === 'Staff-Admin'">
            <v-btn depressed block class="primary darken-2 white--text" @click="stagesDialog = true" >
                <v-icon>icons8-menu-squared</v-icon>
                <span class="ml-3">Edit Stages</span>
            </v-btn>
        </div>
      </div>
      <LeadsTab v-if="tab === 'leads'" :listexpanded="listExpanded" v-on:openItem="openLead"
                v-on:openLead="openFullLead" :refreshitems="refreshItems" :orgtype="orgtype"/>
      <OpportunitiesTab v-else-if="tab === 'opportunities'" :listexpanded="listExpanded" v-on:openItem="openOpportunity"
                        v-on:openOpportunity="openFullLead" :refreshitems="refreshItems" :orgtype="orgtype"/>
      <RenewalsTab v-else-if="tab === 'renewals'" :listexpanded="listExpanded" v-on:openItem="openRenewal"
                   v-on:openRenewal="openFullLead" :refreshitems="refreshItems" :orgtype="orgtype"/>


    </v-col>
    <v-dialog v-if="activeLead" v-model="leadDialog" width="1366px" height="1024px" style="overflow: hidden !important">
      <Lead v-on:refreshItems="refreshitems" :key="dialogLeadKey" :listshrunk="listShrunk" :datadown="activeLead"
            v-on:closeitem="closeLead" :orgtype="orgtype"/>
    </v-dialog>
<!--    <v-dialog v-if="activeAction" v-model="actionDialog" width="1366px" height="1024px" style="overflow: hidden !important">-->
<!--      <Action v-on:refreshItems="refreshitems" :key="dialogLeadKey" :listshrunk="listShrunk" :datadown="activeLead"-->
<!--            v-on:closeitem="closeLead" :orgtype="orgtype"/>-->
<!--    </v-dialog>-->
    <v-dialog v-if="activeOpportunity" v-model="opportunityDialog" width="1366px" style="overflow: hidden !important;"
              persistent>
      <Opportunity v-on:refreshItems="refreshitems" :key="dialogOpportunityKey" :listshrunk="listShrunk"
                   :datadown="activeOpportunity" v-on:closeitem="closeOpportunity" :orgtype="orgtype"/>
    </v-dialog>
    <v-dialog v-if="activeRenewal" v-model="renewalDialog" width="1366px" style="overflow: hidden !important;"
              persistent>
      <Renewal v-on:refreshItems="refreshitems" :key="dialogRenewalKey" :listshrunk="listShrunk"
               :datadown="activeRenewal" v-on:closeitem="closeRenewal" :orgtype="orgtype"/>
    </v-dialog>
    <!-- Organisation Component -->
    <!-- <transition v-if="activeFullLead" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in"> -->
    <v-dialog fullscreen v-model="fullLeadDialog" height="100vh" style="overflow: hidden !important">
      <FullLead v-on:refreshItems="refreshitems" :listshrunk="listShrunk" :datadown="activeFullLead"
                v-on:close="closeLeadDialog() "/>
    </v-dialog>
    <!-- </transition> -->

    <v-dialog v-model="stagesDialog" max-width="1024px">
      <stages v-on:close="closeDialog()"/>
    </v-dialog>
  </v-row>
</template>

<script>
import {mapGetters} from 'vuex';
import Lead from '@/views/sales/Lead.vue';
import FullLead from '@/views/sales/FullLead.vue';
import Opportunity from '@/views/sales/Opportunity.vue';
// import Renewal from '@/views/sales/Renewal.vue';
import LeadsTab from '@/views/sales/LeadList.vue'
import OpportunitiesTab from '@/views/sales/OpportunityList.vue'
import RenewalsTab from '@/views/sales/RenewalsList.vue'
import stages from "@/views/sales/Stages.vue";
// import Action from "@/views/sales/Action.vue";

export default {
  name: 'Sales',
  components: {
    // Action,
    'Lead': Lead,
    'FullLead': FullLead,
    'Opportunity': Opportunity,
    // 'Renewal': Renewal,
    'LeadsTab': LeadsTab,
    'OpportunitiesTab': OpportunitiesTab,
    'RenewalsTab': RenewalsTab,
    'stages': stages
  },
  data: () => ({
    editMode: false,
    orgtype: "",
    orgIcon: "",
    search: '',
    show: false,
    tab: 'leads',
    orgs: [],
    orgDialog: false,
    refreshItems: 0,
    stagesDialog: false,
    activeItem: {},
    activeLead: {},
    leadDialog: false,
    activeFullLead: {},
    fullLeadDialog: false,
    activeOpportunity: {},
    opportunityDialog: false,
    activeRenewal: {},
    renewalDialog: false,
    extendWidth: '',
    routeId: '',

    dialogLeadKey: 0,
    dialogOpportunityKey: 0,
    dialogRenewalKey: 0,


    // Item Columns
    itemsCols: {cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
    itemCols: {cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8},
    taskCols: {cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
    listExpanded: true,
    listShrunk: false,
  }),
  computed: {
    ...mapGetters({
      GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
      GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
    }),
    userLevel() {
      return this.GET_FIREBASE_userAccessToken.user_level;
    },
  },
  methods: {
    showLeads() {
      this.tab = 'leads';
    },
    showOpportunities() {
      this.tab = 'opportunities';
    },
    showRenewals() {
      this.tab = 'renewals'
    },
    showOrders() {
      this.tab = 'orders';
    },
    // refreshItems() {
    //     this.refreshItems++
    // },

    refreshitems() {
      ////console.log("refreshing items")
      this.refreshItems++
    },
    closeDialog() {
      let t = this;
      t.stagesDialog = false;
      t.refreshItems++
      // t.getItems();
    },
    closeLeadDialog() {
      let t = this;
      t.fullLeadDialog = false,
          t.refreshItems++
    },
    refreshCols() {
      switch (true) {
          // Expanded List with No Task Bar
        case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
          this.itemsCols = {cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12}; // 12
          this.itemCols = {cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0}; // 0
          this.taskCols = {cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0}; // 0
          break;
          // Expanded List with Task Bar
        case this.listExpanded && !this.listShrunk && this.GET_taskBar:
          this.itemsCols = {cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10}; // 10
          this.itemCols = {cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0}; // 0
          this.taskCols = {cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2}; // 2
          break;
          // List with Product Page (No Task Bar)
        case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
          this.itemsCols = {cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6}; // 6
          this.itemCols = {cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6}; // 6
          this.taskCols = {cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0}; // 0
          break;
          // List with Product Page and Task Bar
        case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
          this.itemsCols = {cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4}; // 4
          this.itemCols = {cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6}; // 6
          this.taskCols = {cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2}; // 2
          break;
          // Shrunk List with Product Page (No Task Bar)
        case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
          this.itemsCols = {cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3}; // 2
          this.itemCols = {cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9}; // 10
          this.taskCols = {cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0}; // 0
          break;
          // Shrunk List with Product Page and Task Bar)
        case !this.listExpanded && this.listShrunk && this.GET_taskBar:
          this.itemsCols = {cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2}; // 2
          this.itemCols = {cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8}; // 8
          this.taskCols = {cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2}; // 2
          break;
        default:
          this.itemsCols = {cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4}; // 12
          this.itemCols = {cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8}; // 8
          this.taskCols = {cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0}; // 0
      }
    },
    openItem(item) {
      this.activeItem = item;
      this.listExpanded = false;
    },
    closeLead() {
      this.activeLead = {}
      this.leadDialog = false
      this.dialogLeadKey++
    },
    closeOpportunity() {
      this.activeOpportunity = {}
      this.opportunityDialog = false
      this.dialogOpportunityKey++
    },
    closeRenewal() {
      this.activeRenewal = {}
      this.renewalDialog = false
      this.dialogRenewalKey++
    },
    openLead(item) {
      // // ////console.log("opening Lead = " + JSON.stringify(item, null, 2))
      this.activeLead = item;
      this.leadDialog = true
      // this.listExpanded = false;
    },
    openFullLead(item) {
      ////console.log("opening full elad" + JSON.stringify(item, null, 2))
      // this.activeFullLead = item;
      // this.fullLeadDialog = true
      this.MIX_go('/customers/' + item.orgId)
    },
    openOpportunity(item) {
      // // ////console.log("opening Opportunity = " + JSON.stringify(item, null, 2))
      this.activeOpportunity = item;
      this.opportunityDialog = true
      // this.listExpanded = false;
    },
    openRenewal(item) {
      // // ////console.log("opening Opportunity = " + JSON.stringify(item, null, 2))
      this.activeRenewal = item;
      this.renewalDialog = true
      // this.listExpanded = false;
    },
    closeItem() {
      this.listExpanded = true;
      this.listShrunk = false;
    },
  },
  watch: {
    listExpanded() {
      this.refreshCols()
    },
    listShrunk() {
      this.refreshCols()
    },
    // GET_taskBar() { this.refreshCols() }
  },
  mounted() {
    // Dynamic Route for New or Existing Item
    this.routeId = this.$route.params.id;
    var salesType = this.routeId;
    ////console.log("salesType = " + JSON.stringify(salesType, null, 2))
    switch (salesType) {
      case "Leads":
        this.tab = "leads";
        break;
      case "Opportunities":
        this.tab = "opportunities";
        break;
      case "Renewals":
        this.tab = "renewals";
        break;
      default:
        break;
    }


    // if (this.routeId === "new") {
    // 	this.openItem({})
    // } else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined) {
    // 	this.openItem({ id: this.routeId })
    // }
  },
  created(){
    if(this.$route.query.new === 'true'){
      this.leadDialog = true
    } else if(this.$route.query.newOpp === 'true'){
      this.leadDialog = false
      this.opportunityDialog = true
    }
  }
};
</script>

<style scoped>

.pageButton {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.pageButton::before {
  display: none
}

</style>
