<template>
    <v-container fluid fill-height class="d-flex primary pa-0 ma-0">
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3" class="primary d-flex flex-column align-center">
            <v-img contain class="mb-8" max-height="120" :src="require('@/assets/logo.png')"></v-img>
            <div v-if="redisType === 'Staging'" class="text-h5 float-left pl-4 font-weight-bold white--text">{{redisType}}</div>
            <div class="text-h6 float-left pl-4 font-weight-light white--text">{{MIX_getVersion()}}</div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 9 : 12" class="white d-flex align-self-stretch justify-center">
            <v-card width=550 class="pa-8 rounded-sm elevation-0 align-self-center">
                <v-card-title class="d-flex justify-start">
                    <span class="headline py-4">Login</span>
                </v-card-title>
                <v-card-subtitle class="d-flex justify-start pb-10 black--text"> Enter your email and password below </v-card-subtitle>
                <v-card-text class="pt-6">
                    <v-form ref="loginForm" v-on:submit.prevent="login()" class="" v-model="valid" lazy-validation>
                        <v-text-field outlined class="primary--text" v-model="userEmail" label="Email" type="email" append-icon="mdi-email" :rules="emailRules" required></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="userPassword"
                            label="Password"
                            :type="show ? 'text' : 'password'"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="passwordRules"
                            autocomplete="off"
                            required
                            v-on:keyup.enter="login"
                            @click:append="show = !show"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions class="px-4">
                    <v-btn block x-large class="primary white--text mb-8" @click="login" :disabled="userEmail === '' || userPassword === '' || loading" :loading="loading">Login</v-btn>
                </v-card-actions>
                <div class="body-1 float-left pl-4 font-weight-bold secondary--text" style="cursor: pointer" @click="$router.push('/reset')">Reset Password</div>
                <div class="body-1 float-right px-4">Don't have an account? <span class="font-weight-bold secondary--text" style="cursor: pointer" @click="$router.push('/register')">Sign up</span></div>
            </v-card>
        </v-col>
        <div v-if="$vuetify.breakpoint.smAndDown" height="200" class="primary d-flex justify-center align-center">
            <v-img contain class="my-8" max-height="70" :src="require('@/assets/logo.png')"></v-img>
        </div>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'Login',
    data: () => ({
        emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
        inputBackgroundColor: 'white',
        loading: false,
        passwordRules: [(v) => !!v || 'Password is required'],
        valid: true,
        show: false,
        userEmail: '',
        userPassword: '',
        redisType: 'Production',
        version: '',
    }),
    methods: {
        ...mapActions({ ACT_FIREBASE_userAuth: 'firebase_auth_store/ACT_FIREBASE_userAuth' }),
        async checkuserAuth(userID) {
            let t = this;
            let userDataResult = await t.MIX_firestore_read(userID, 'users');
            if (userDataResult.code === 1) {
                return userDataResult;
            } else {
                t.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Account not found', show: true });
                return userDataResult;
            }
        },
        login() {
            let t = this;
            t.loading = true;
            t.userEmail = t.userEmail.toLowerCase();
            t.rules = {
                emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
                passwordRules: [(v) => !!v || 'Password is required'],
            };
            t.$nextTick(() => {
                if (t.$refs.loginForm.validate()) {
                    t.$firebase.auth
                        .signInWithEmailAndPassword(t.userEmail, t.userPassword)
                        .then(async (data) => {
                            var checkUserResult = await t.checkuserAuth(data.user.uid);
                            if (checkUserResult.code === 1) {
                                await t.MIX_FIREBASE_userAuth(data.user);
                            }
                        })
                        .then(() => {
                            t.$router.push('/').catch((error) => {
                                console.log(error.message);
                            });
                        })
                        .catch((err) => {
                            t.MIX_alertBox({ color: 'error', timeout: 3000, message: err, show: true });
                            t.loading = false;
                        });
                } else {
                    t.MIX_alertBox({ color: 'error', timeout: 3000, message: 'The email password combination is incorrect.', show: true });
                    t.loading = false;
                }
            });
        },
      checkRedis(){
        if (process.env.VUE_APP_MOFSERVER === 'https://api.ministryoffurniture.com/api/v1') {
          this.redisType = 'Production';
        } else if(process.env.VUE_APP_MOFSERVER === 'https://mof-server-staging-lj2opgvzfq-nw.a.run.app/api/v1'){
          this.redisType = 'Staging';
        }else{
          this.redisType = 'Local';
        }
      }
    },
  created(){
    this.checkRedis();
    // this.version = require('../package.json').version;
  }
};
</script>