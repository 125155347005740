<template>
    <div>
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="mx-4 d-flex align-center" style="width: 100% !important">
                    <!-- <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="saveItem"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn> -->
                    <!-- <v-spacer /> -->
                    <v-btn text class="grey lighten-2 secondary--text" v-if="editmode" @click="cancelItem"><v-icon class="mr-2">icons8-multiply</v-icon>Cancel</v-btn>
                    <div class="ml-2">
                        <v-btn depressed outlined class="grey lighten-5 secondary--text" @click="openDialog()">Add Contact</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="pa-3" no-gutters>
            <v-col cols="12" sm="12">
                <div class="d-flex align-center pb-2">
                    <div class="text-left primary--text text-h6 font-weight-light">Contacts</div>
                    <v-spacer />
                </div>
            </v-col>
            <!-- <pre>{{ activeItem }}</pre>
            <pre>{{ item }}</pre> -->
            <!-- <div class=""> -->
            <div style="width: 100% !important; height: 700px !important; overflow-y: scroll !important">
                <v-col cols="12" class="pa-0 ma-0">
                    <contact-list :orgid="org.id" :refreshitems="refreshItems" v-on:openItem="openItem"/>
                </v-col>
            </div>
            <!-- </div> -->

            <v-dialog v-model="addContactDialog" persistent  scrollable max-width="900px">
                <v-card>
                    <div style="overflow: hidden !important" class="grey lighten-3">
                        <v-row class="primary" dense>
                            <v-col cols="12" sm="12" class="">
                                <div class="d-flex align-center primary pa-4 pb-3">
                                    <div class="mx-2">
                                        <v-icon class="white--text">icons8-map</v-icon>
                                    </div>
                                    <div class="text-left white--text text-h5 font-weight-light">Add New Contact</div>
                                    <v-spacer />
                                    <div>
                                        <v-btn class="ml-2 white--text" plain @click="closeDialog()" depressed>
                                            <v-icon>icons8-multiply</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <div class="mt-1" style="height: 500px; overflow-y: scroll !important">
                            <organisation-contact :key="orgContactKey" :newItem="newItem" :datadown="activeItem" :org="org" :fullContact="true" :editMode="true" :pu="true" v-on:save="createItem" v-on:itemDataUp="contactItem"  />
                        </div>
                        <v-row class="grey darken-1" dense>
                            <v-col cols="12" class="mt-2 grey darken-1 pa-3 pr-4 d-flex justify-right align-center">
                                <v-spacer />
                                <div>
                                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="closeDialog()"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                                </div>
                                <div class="ml-2">
                                    <v-btn class="accent primary--text font-weight-bold" depressed @click="validateForm(item)"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import schema from '@/schema.js';
import organisationContact from '@/views/contacts/contactDetails.vue';
import contactList from '@/views/contacts/contactList.vue';
import { mapGetters } from 'vuex';
export default {
    // * COMPONENTS
    components: {
        'organisation-contact': organisationContact,
        'contact-list': contactList,
    },
    // * PROPS
    props: {
        editmode: {
            type: Boolean,
            default: false,
        },
        org: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    // * DATA
    data: () => ({
        item: {},
        newItem: true,
        key: 'org',
        addImageToggle: false,
        costsToggle: false,
        index: 'idx:orgs',
        showDeletedItems: false,
        schema: {},
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        activeItem: {},
		refreshItems: 0,

        orgContactKey: 0,

        options: [],
        optionSelected: {},

        addContactDialog: false,

        // Headers to display in the Data Table
        headers: [
            { text: 'Id', value: 'id', hidden: true },
            { text: 'Code', value: 'attCode', hidden: false },
            { text: 'Name', value: 'attName', hidden: false },
            { text: 'Type', value: 'attType', hidden: true },
            { text: 'MTO', value: 'attMTO', hidden: false },
            { text: 'Price Band', value: 'attPriceBand', hidden: true },
            { text: 'Add Cost', value: 'attAddCost', hidden: false },
            { text: 'Add Sell', value: 'attAddSell', hidden: false },
            { text: 'Enabled', value: 'enabled', hidden: true },
            { text: 'Parent ID', value: 'attParentId', hidden: true },
            { text: 'Description', value: 'attDescription', hidden: true },
            { text: 'Hex Color', value: 'attHexColor', hidden: false },
            { text: 'Image', value: 'attImage', hidden: true },
            { text: 'Option Id', value: 'optionId', hidden: true },
            { text: 'Option Name', value: 'optionName', hidden: false },
            { text: 'Supplier ID', value: 'orgId', hidden: true },
            { text: 'Supplier Name', value: 'orgName', hidden: true },
            { text: 'Date Time (C)', value: 'createdDateTime', hidden: true },
            { text: 'User ID (C)', value: 'createdUserId', hidden: true },
            { text: 'User Name (C)', value: 'createdUserName', hidden: true },
            { text: 'User Email (C)', value: 'createdUserEmail', hidden: true },
            { text: 'Date Time (M)', value: 'modifiedDateTime', hidden: true },
            { text: 'User ID (M)', value: 'modifiedUserId', hidden: true },
            { text: 'User Name (M)', value: 'modifiedUserName', hidden: true },
            { text: 'User Email (M)', value: 'modifiedUserEmail', hidden: true },
            { text: 'Deleted', value: 'deleted', hidden: true },
            { text: 'Date Time (D)', value: 'deletedDateTime', hidden: true },
            { text: 'User ID (D)', value: 'deletedUserId', hidden: true },
            { text: 'User Name (D)', value: 'deletedUserName', hidden: true },
            { text: 'User Email (D)', value: 'deletedUserEmail', hidden: true },
            { text: 'Action', value: 'action', align: 'end', hidden: false, width: '155px' },
        ],

        sortBy: '@contactFirstName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['orgName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
            GET_FIREBASE_userData: 'firebase_auth_store/GET_FIREBASE_userData',
        }),
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@optionId')) {
                computedFields.push('@optionId');
            }
            if (!computedFields.includes('@optionName')) {
                computedFields.push('@optionName');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.showDeletedItems) {
                    searchQuery = `@orgId:{${this.org.id}}`;
                } else {
                    searchQuery = `@deleted:0 @orgId:{${this.org.id}}`;
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.showDeletedItems) {
                        searchQuery = `${searchQuery}* @orgId:{${this.org.id}}`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0 @orgId:{${this.org.id}}`;
                    }
                } else {
                    if (this.showDeletedItems) {
                        searchQuery = `${searchQuery}* @orgId:{${this.org.id}}`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0 @orgId:{${this.org.id}}`;
                    }
                }
            }
            // Filter By optionId
            if (this.optionSelected?.optionId) {
                searchQuery = `${searchQuery} @optionId:${this.optionSelected.optionId}`;
            }
            return searchQuery;
        },
        computedHeaders() {
            return this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
        },
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        computedFilterByOption() {
            var items = this.items;
            const key = 'optionId';
            items = items.map((x) => ({ optionId: x.optionId, optionName: x.optionName }));
            items = [...new Map(items.map((item) => [item[key], item])).values()];
            return items;
        },
    },
    // * METHODS
    methods: {
        // * INITIALISE ITEM
        initialiseItem() {
            let t = this;
            t.item = { ...t.schema };
        },

        contactItem(item) {
            this.item = item
        },

        // * CREATE ITEM
        async createItem(item) {
            let t = this;
            var itemNew = false
            // console.log("saving org contact = " + JSON.stringify(item, null, 2))
            if (item.id === '') {
                itemNew = true
                item.id = t.MIX_generateId();
            }
            let result = await t.MIX_createItem(item, 'contact', itemNew);
            if (result.code === 1) {
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                // t.readItem(item.id);
                t.getItems();
                t.addContactDialog = false,
                t.item = {}
                t.activeItem = {}
            }
        },

        validateForm(item) {
          let score = 0;
          let error = ""

          // console.log("validating form")
          // console.log(item.orgAccountType)

          if (item.orgName === '' || item.orgName === null || item.orgName === undefined) {
            score += 1;
            error = "Organisation Name is required"
          }

          // if (item.orgId >= 20) {
          //   score += 1;
          //   error = "ID must be at least 20 characters"
          // }

          // if (item.orgAccountType === '' || item.orgAccountType === null || item.orgAccountType === undefined) {
          //   score += 1;
          //   error = "Account type is required"
          // }
          //
          // if (item.orgTelephone === '' || item.orgTelephone === null || item.orgTelephone === undefined) {
          //   score += 1;
          //   error = "Telephone is required"
          // }
          //
          // if (item.orgWebsite === '' || item.orgWebsite === null || item.orgWebsite === undefined) {
          //   score += 1;
          //   error = "Website is required"
          // }

          // if (item.orgAddressLine1 === '' | item.orgAddressLine1 === null || item.orgAddressLine1 === undefined) {
          //   score += 1;
          //   error = "Address Line 1 is required"
          // }
          //
          // if (item.orgTown === '' || item.orgTown === null || item.orgTown === undefined) {
          //   score += 1;
          //   error = "Town is required"
          // }
          //
          // if (item.orgCounty === '' || item.orgCounty === null || item.orgCounty === undefined) {
          //   score += 1;
          //   error = "County is required"
          // }
          //
          // if (item.orgPostcode === '' || item.orgPostcode === null || item.orgPostcode === undefined) {
          //   score += 1;
          //   error = "Postcode is required"
          // }

          // if (item.orgChannel === '' || item.orgChannel === null || item.orgChannel === undefined) {
          //   score += 1;
          //   error = "Channel is required"
          // }

          // if (item.orgOwner === '' || item.orgOwner === null || item.orgOwner === undefined) {
          //   score += 1;
          //   error = "Owner is required"
          // }

          if(score === 0){
            this.createItem(item);
          }else{
            this.MIX_alertBox({ color: "error", timeout: 4000, message: error, show: true });
          }
        },

        openItem(item) {
            var t = this
            t.addContactDialog = true
            t.activeItem = item
            t.newItem = false
        },

        // // * CREATE ITEM
        // async createItem() {
        // 	let t = this;
        // 	t.item.id = t.MIX_generateId();
        // 	t.item.orgId = this.org.id;
        // 	t.item.orgName = this.org.orgName;
        // 	let result = await this.MIX_createItem(this.item, this.key, this.newItem);
        // 	if (result.code === 1) {
        // 		this.getItems();
        // 		this.initialiseItem();
        // 	}
        // },
        // * EDIT ITEM
        async editItem(itemId) {
            let result = await this.MIX_readItem(itemId, this.key);
            if (result.code === 1) {
                this.item = result.data;
            }
        },
        // * DESTROY ITEM
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        async getItems() {
            //console.log("getItems");
            //console.log("this.index = " + this.index);
            //console.log("this.this.computedFields = " + this.computedFields);
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            //console.log("itemsResult = " + JSON.stringify(itemsResult, null, 2));
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
			this.refreshItems++
        },
        // * GET FABRICS (for Dropdown)
        async getFabrics() {
            let result = await this.MIX_redisSearch('idx:fabrics', '@deleted:0', 0, 9999);
            //console.log('result = ' + JSON.stringify(result, null, 2));
            if (result.code === 1) {
                this.fabrics = result.data.documents.map((x) => x.value);
                //console.log('this.fabrics = ' + JSON.stringify(this.fabrics, null, 2))
            }
        },
        // * GET ALL OPTIONS (for Dropdown)
        async getOptions() {
            let result = await this.MIX_redisSearch('idx:options', '@deleted:0 @enabled:1', 0, 9999);
            //console.log("result = " + JSON.stringify(result, null, 2));
            if (result.code === 1) {
                this.options = result.data.documents.map((x) => {
                    return {
                        ...x.value,
                    };
                });
            }
        },
        openDialog() {
            this.addContactDialog = true 
            this.activeItem = {}
            this.item = {}
            this.newItem = true
        },
        closeDialog() {
            this.addContactDialog = false,
            this.activeItem = {}
            this.item = {}
            this.orgContactKey++
        }
    },
    // * WATCH
    watch: {
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        showDeletedItems() {
            this.getItems();
        },
        computedFields: {
            handler() {
                this.getItems();
            },
            deep: true,
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        let t = this;
        this.schema = schema.attribute;
        this.getFabrics(); // Get Fabrics for Fabric Dropdown
        this.getItems();
        this.initialiseItem();
        this.getOptions();

        let activities = await t.MIX_redisReadSortedSet('sset:activities', 0, 9999);
        if (activities.code === 1) {
            t.activities = activities.data;
        }
    },
};
</script>
