<template>
    <v-row fill-height class="grey lighten-2" style="height: 100% !important; overflow-y: scroll !important" no-gutters>
        <v-col v-if="!mobileShow" class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            <div class="d-flex align-center mb-1">
                <div class="d-flex align-center">
                    <div><v-icon class="grey--text" x-large>icons8-user-groups</v-icon></div>
                    <div class="text-left headingtext--text text-h4 font-weight-light ml-1" v-if="!listShrunk">People</div>
                </div>
                <v-spacer v-if="!listShrunk"></v-spacer>
                <div class="ml-1">
                    <v-btn depressed block class="accent secondary--text" @click="openItem({})"><v-icon class="mr-3">icons8-user-groups</v-icon>New</v-btn>
                </div>

                <v-spacer v-if="listShrunk"></v-spacer>
                <div v-if="$vuetify.breakpoint.mdAndUp">
                    <v-btn class="primary--text ml-1" @click="listShrunk = false" depressed v-if="listShrunk">
                        <v-icon color="primary">icons8-show-sidepanel</v-icon>
                    </v-btn>
                    <v-btn class="primary--text ml-1" @click="listShrunk = true" depressed v-if="!listExpanded && !listShrunk">
                        <v-icon color="primary">icons8-hide-sidepanel</v-icon>
                    </v-btn>
                    <!-- TODO - RG - Add Import Facility -->
                    <!-- <v-btn disabled class="primary--text ml-2" depressed v-if="!listShrunk && ['Developer-Admin', 'Staff-Admin'].includes(userLevel)">
                        <v-icon color="primary">icons8-import-csv</v-icon>
                    </v-btn> -->
                    <!-- TODO - RG - Add Export Facility -->
<!--                    <v-btn @click="exportCSV" class="primary&#45;&#45;text ml-2" depressed v-if="!listShrunk && ['Developer-Admin', 'Staff-Admin'].includes(userLevel)">-->
<!--                        <v-icon color="primary">icons8-export-csv</v-icon>-->
<!--                    </v-btn>-->
                </div>
            </div>
            <!-- Contact List Component -->
            <contact-list :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshitems="refreshItems" />

            <!-- App Copyright -->
            <appCopyright />
        </v-col>
        <!-- Contact Component -->
        <transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col :style="$vuetify.breakpoint.mdAndUp ? 'border-left: lightgrey solid 2px !important' : ''" v-if="!listExpanded" class="grey lighten-3" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <contact v-if="!listExpanded" v-on:refreshitems="refreshItemsMethod" :datadown="activeItem" v-on:closeitem="closeItem" :listShrunk="listShrunk" />
            </v-col>
        </transition>
        <!-- Taskbar Component -->
        <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col style="border-left: lightgrey solid 2px !important" class="" v-if="GET_taskBar" :cols="taskCols.cols" :xs="taskCols.xs" :sm="taskCols.sm" :md="taskCols.md" :lg="taskCols.lg" :xl="taskCols.xl">
                <taskbar />
            </v-col>
        </transition>
    </v-row>
</template>

<script>
import taskBar from '@/views/tasks/taskbar.vue';
import contact from '@/views/contacts/contact.vue';
import contactList from '@/views/contacts/contactList.vue';
import { mapGetters } from 'vuex';
export default {
    // * COMPONENTS
    components: {
        contact: contact,
        'contact-list': contactList,
        taskbar: taskBar,
    },
    // * DATA
    data: () => ({
        listExpanded: true,
        listShrunk: false,
        list: 'contacts',
        itemsCols: { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
        itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
        taskCols: { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
        refreshItems: 0,
        activeItem: {},
        routeId: '',
        mobileShow: false,
      itemsFrom: 0,
      itemsTo: 0,
      // Export Box Data
      totalCount: 0,
      itemsTotal: 0,
      exportBox: false,
      exportItems: {
        itemsFrom: "1",
        itemsTo: "10",
      },
      exportLoading: false,
      exportFiltered: false,

    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },
    },
    // * METHODS
    methods: {
        // Open Item
        openItem(item) {
            this.activeItem = item;
            this.listExpanded = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.mobileShow = true;
            }
        },
        // Refresh Columns
        refreshCols() {
            switch (true) {
                // Expanded List with No Task Bar
                case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Expanded List with Task Bar
                case this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // List with Product Page (No Task Bar)
                case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // List with Product Page and Task Bar
                case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // Shrunk List with Product Page (No Task Bar)
                case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
                    this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Shrunk List with Product Page and Task Bar)
                case !this.listExpanded && this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                default:
                    this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
                    this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
            }
        },
        // Refresh Items Method
        refreshItemsMethod() {
            this.refreshItems++;
        },
        closeItem() {
            this.listExpanded = true;
            this.listShrunk = false;
            if (this.$vuetify.breakpoint.smAndDown) {
                this.mobileShow = false;
            }
        },
    },
    // * WATCH
    watch: {
        listExpanded() {
            this.refreshCols();
        },
        listShrunk() {
            this.refreshCols();
        },
        GET_taskBar() {
            this.refreshCols();
        },
    },
    // * MOUNTED
    mounted() {
        this.refreshCols();
        // Dynamic Route for New or Existing Item
        this.routeId = this.$route.params.id;
        if (this.routeId === 'new') {
            this.openItem({});
        } else if (this.routeId !== 'new' && this.routeId !== '' && this.routeId !== undefined) {
            this.openItem({ id: this.routeId });
        }
    },
};
</script>
