<template>
    <div>
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field label="Search Quotes" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field>
            </div>
            <div class="flex-grow-1 pl-2">
                <v-text-field label="Quote Number" v-model="numberSearchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="numberSearchQuery = ''"></v-text-field>
            </div>
            <div class="ml-2" v-if="!listshrunk">
                <div>
                    <v-autocomplete multiple outlined auto-select-first v-model="filter.orgTags" :search-input.sync="orgTagAutocomplete" @change="orgTagAutocomplete = ''" label="Organisation Tags" :items="organisations" item-text="orgName" item-value="id" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearQuoteTagsFilter()">
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                <span class="pr-2">
                                    {{ item.orgName }}
                                </span>
                                <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </div>
                <div class="ml-1 mt-1 body-2 app--text">
                    Filter is set to <strong class="primary--text" @click="changeQuoteTagsOperator()" style="cursor: pointer">{{ filter.orgTagsOperator }}</strong> of the above
                </div>
            </div>
            <!-- TODO - RG - Complete Filters for Quotes -->
            <div class="ml-2" v-if="!listshrunk && orgid === ''"><v-autocomplete auto-select-first v-model="filter.orgId" outlined label="Customer" dense hide-details="auto" background-color="white" color="primary" :items="organisations" item-text="orgName" item-value="id" clearable @click:clear="clearFilter('orgId', '')" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-autocomplete></div>
            <div class="ml-2" v-if="listexpanded"><v-autocomplete :disabled="filter.orgId === ''" auto-select-first v-model="filter.contactId" outlined label="Quote" dense hide-details="auto" background-color="white" color="primary" :items="contacts" item-text="contactName" item-value="id" clearable @click:clear="clearFilter('contactId', '')" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-autocomplete></div>
            <div class="ml-2" v-if="listexpanded"><v-autocomplete auto-select-first v-model="selectedOwner" outlined label="Assigned to" dense hide-details="auto" background-color="white" color="primary" :items="users" item-text="ownerUserName" return-object clearable @click:clear="clearSelectedOwner()" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-autocomplete></div>
            <div class="ml-2" v-if="listexpanded"><v-select v-model="filter.channel" outlined label="Channel" dense hide-details="auto" background-color="white" color="primary" :items="channels" clearable @click:clear="clearFilter('channel', '')" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-select></div>
            <div class="ml-2" v-if="listexpanded"><v-select v-model="filter.quoteType" outlined label="Quote Type" dense hide-details="auto" background-color="white" color="primary" :items="quoteTypes" clearable @click:clear="clearFilter('quoteType', '')" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-select></div>

            <div class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>icons8-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
          <!--    EXPORT CSV-->
          <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="exportBox = true">
              <v-icon>icons8-export-csv</v-icon>
            </v-btn>
          </div>
          <ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
            <template v-slot:header>
              <div class="full-width d-flex align-center">
                <div>Export CSV</div>
                <v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
                <v-spacer />
                <v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-cancel</v-icon></v-btn>
              </div>
            </template>
            <p>Please select the data you wish to export:</p>
            <table width="100%" cellspacing="5">
              <tr>
                <td width="100"><strong>Current page:</strong></td>
                <td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
                <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
              </tr>
              <tr>
                <td><strong>Current total:</strong></td>
                <td>{{ itemsTotal }} items (filtered)</td>
                <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
              </tr>
              <tr>
                <td><strong>All Items:</strong></td>
                <td>{{ totalCount }} items (without filter)</td>
                <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
              </tr>
              <tr>
                <td valign="top"><strong>Specific:</strong></td>
                <td>
                  <div class="d-flex align-center">
                    <div>From</div>
                    <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
                    <div>To</div>
                    <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
                  </div>
                  <div class="ml-n7">
                    <CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
                  </div>
                </td>
                <td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
              </tr>
            </table>
          </ExportBox>
          <!--      EXPORT END-->
        </div>
        <div class="d-flex align-start mt-3 flex-wrap" v-if="!listshrunk">
            <!-- Quote Installs -->
            <v-btn-toggle v-model="filter.quoteInstall" class="primary--text" depressed dense hide-details="auto" background-color="white" color="primary" label="">
                <v-btn value="1"><v-icon class="mr-2">icons8-van</v-icon>
                    <span v-if="filter.quoteInstall === ''">Show Installs</span>
                    <span v-else>Hide Installs</span>
                </v-btn>
            </v-btn-toggle>
            <v-btn depressed class="ml-2 mb-3" @click="filter.quoteStatus = ['Draft', 'Awaiting Approval', 'Ready', 'Sent', 'Accepted']; filter.quoteNumber = ''">Reset Filter</v-btn>
              <!-- Approval Levels -->
              <!-- <div v-if="filter.quoteStatus.includes('Awaiting Approval')" class="d-flex flex-column ml-2 mr-2">
                <v-btn-toggle v-if="filter.quoteStatus.includes('Awaiting Approval')" v-model="filter.quoteApprovalLevel" class="primary--text" depressed dense hide-details="auto" background-color="white" color="primary" label="">
                    <v-btn value="1">Approval First Level</v-btn>
                    <v-btn value="2">Approval Second Level</v-btn>
                </v-btn-toggle>
            </div> -->
            <!-- Quote Status -->
            <v-btn-toggle multiple v-model="filter.quoteStatus" class="primary--text d-flex flex-wrap ml-2" depressed dense hide-details="auto" background-color="grey lighten-2" color="primary" label="">
                <v-btn v-for="status in quoteStatus.slice(0, 5)" :value="status" :key="status">{{ status }}</v-btn>
            </v-btn-toggle>  
            <v-btn-toggle multiple v-model="filter.quoteStatus" class="primary--text d-flex flex-wrap ml-2" depressed dense hide-details="auto" background-color="grey lighten-2" color="primary" label="">
                <v-btn v-for="status in quoteStatus.slice(5,7)" :value="status" :key="status">{{ status }}</v-btn>
            </v-btn-toggle>  
            <v-btn-toggle multiple v-model="filter.quoteStatus" class="primary--text d-flex flex-wrap ml-2" depressed dense hide-details="auto" background-color="grey lighten-2" color="primary" label="">
                <v-btn v-for="status in quoteStatus.slice(7,11)" :value="status" :key="status">{{ status }}</v-btn>
            </v-btn-toggle>  
        </div>

        <div class="mt-2">
            <!-- TABLE BASED LIST -->
            <v-data-table v-if="!listshrunk" sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions"
                :loading="loading" loading-text="Loading Quotes" :footer-props="{'items-per-page-options': [5, 10, 15, 25, 50, 100]}">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2" v-if="!loading && itemsTotal > 0">            
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn value="destroy" small v-if="['Developer-Admin'].includes(userLevel)">
                                <v-icon class="error--text">icons8-delete-trash</v-icon>
                            </v-btn>
                            <v-btn value="delete" small v-if="['Developer-Admin','Staff-Admin','Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                            <v-btn value="undelete" small v-if="['Developer-Admin','Staff-Admin','Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:item.quoteTitle="{ item }">
                    <div v-if="item.quoteTitle.length > 30">
                        <v-tooltip top :max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="tooltiptext tooltip">{{ item.quoteTitle.substring(0, 30) }}...</span>
                            </template>
                            <span>{{ item.quoteTitle }}</span>
                        </v-tooltip>
                    </div>
                    <div v-else>{{ item.quoteTitle }}</div>
                </template>
                <template v-slot:item.quoteInstall="{ item }">
                    <span v-if="item.quoteInstall === '1'">Yes</span>
                    <span v-else>No</span>
                </template>

                <template v-slot:item.quoteVersion="{ item }">
					<!-- QV: {{ item.quoteVersion }} -->
                    <span v-if="item.quoteVersion > 0">{{ quoteVersion(item.quoteVersion) }}</span>
                </template>

                <template v-slot:item.quoteCurrentVersion="{ item }">
					<!-- CV: {{ item.quoteCurrentVersion }} -->
                    <!-- <span v-if="parseInt(item.quoteVersion) > 0 && parseInt(item.quoteCurrentVersion) === 1">Yes</span>
					<span v-if="parseInt(item.quoteVersion) === '' && parseInt(item.quoteCurrentVersion) === 0">Yes</span>
					<span v-else>No</span> -->
					<span v-if="item.quoteStatus !== 'Revision'">Y</span>
					<span v-else>N</span>
                </template>
                
                <template v-slot:item.quoteInstallApprovalStatus="{ item }" >
                    <div
                        :class="
                        item.quoteInstallApprovalStatus == 'Rejected' ? 'error white--text' : 
                        ( item.quoteInstallApprovalStatus == 'Pending' || item.quoteInstallApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                        item.quoteInstallApprovalStatus == 'Approved' ? 'success white--text' : 
                        'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold" style="width: 100% !important; height: 100% !important">
                            <span v-if="item.quoteInstallApprovalStatus == 'Rejected'">R</span>
                            <span v-else-if="item.quoteInstallApprovalStatus == 'Pending' || item.quoteInstallApprovalStatus == 'Awaiting Approval'">P</span>
                            <span v-else-if="item.quoteInstallApprovalStatus == 'Approved'">A</span>
                            <span v-else>N/A</span>
                    </div>
                </template>

                <template v-slot:item.quoteSupplyChainApprovalStatus="{ item }" >
                    <div
                        :class="
                        item.quoteSupplyChainApprovalStatus == 'Rejected' ? 'error white--text' : 
                        ( item.quoteSupplyChainApprovalStatus == 'Pending' || item.quoteSupplyChainApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                        item.quoteSupplyChainApprovalStatus == 'Approved' ? 'success white--text' : 
                        'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold" style="width: 100% !important; height: 100% !important">
                            <span v-if="item.quoteSupplyChainApprovalStatus == 'Rejected'">R</span>
                            <span v-else-if="item.quoteSupplyChainApprovalStatus == 'Pending' || item.quoteSupplyChainApprovalStatus == 'Awaiting Approval'">P</span>
                            <span v-else-if="item.quoteSupplyChainApprovalStatus == 'Approved'">A</span>
                            <span v-else>N/A</span>
                    </div>
                </template>
                <template v-slot:item.quoteSalesManagerApprovalStatus="{ item }" >
                    <div
                        :class="
                        item.quoteSalesManagerApprovalStatus == 'Rejected' ? 'error white--text' : 
                        ( item.quoteSalesManagerApprovalStatus == 'Pending' || item.quoteSalesManagerApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                        item.quoteSalesManagerApprovalStatus == 'Approved' ? 'success white--text' : 
                        'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold" style="padding: 0px !important; height: 100% !important">
                            <span v-if="item.quoteSalesManagerApprovalStatus == 'Rejected'">R</span>
                            <span v-else-if="item.quoteSalesManagerApprovalStatus == 'Pending' || item.quoteSalesManagerApprovalStatus == 'Awaiting Approval'">P</span>
                            <span v-else-if="item.quoteSalesManagerApprovalStatus == 'Approved'">A</span>
                            <span v-else>N/A</span>
                    </div>
                </template>


                <template v-slot:item.quoteMDDirectorApprovalStatus="{ item }" >
                    <div
                        :class="
                        item.quoteMDDirectorApprovalStatus == 'Rejected' ? 'error white--text' : 
                        ( item.quoteMDDirectorApprovalStatus == 'Pending' || item.quoteMDDirectorApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                        item.quoteMDDirectorApprovalStatus == 'Approved' ? 'success white--text' : 
                        'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold pa-0 ma-0" style="width: 100% !important; height: 100% !important">
                            <span v-if="item.quoteMDDirectorApprovalStatus == 'Rejected'">R</span>
                            <span v-else-if="item.quoteMDDirectorApprovalStatus == 'Pending' || item.quoteMDDirectorApprovalStatus == 'Awaiting Approval'">P</span>
                            <span v-else-if="item.quoteMDDirectorApprovalStatus == 'Approved'">A</span>
                            <span v-else>N/A</span>
                    </div>
                </template>
                <template v-slot:item.quoteFDDirectorApprovalStatus="{ item }" >
                    <div
                        :class="
                        item.quoteFDDirectorApprovalStatus == 'Rejected' ? 'error white--text' : 
                        ( item.quoteFDDirectorApprovalStatus == 'Pending' || item.quoteFDDirectorApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                        item.quoteFDDirectorApprovalStatus == 'Approved' ? 'success white--text' : 
                        'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold pa-0 ma-0" style="width: 100% !important; height: 100% !important">
                            <span v-if="item.quoteFDDirectorApprovalStatus == 'Rejected'">R</span>
                            <span v-else-if="item.quoteFDDirectorApprovalStatus == 'Pending' || item.quoteFDDirectorApprovalStatus == 'Awaiting Approval'">P</span>
                            <span v-else-if="item.quoteFDDirectorApprovalStatus == 'Approved'">A</span>
                            <span v-else>N/A</span>
                    </div>
                </template>
                <!-- <template v-slot:item.quoteFirstLevelApprovedBy="{ item }" >
                    <div v-if="item.quoteStatus !== 'Draft' && item.quoteApprovalLevel" class="d-flex">
                        <span v-for="(user, index) in firstLevelApprovalUsers" :key="index">
                            <v-avatar 
                            :class="
                            item.quoteFirstLevelApprovedBy.includes(user.id) ? 'success white--text' : 
                            item.quoteFirstLevelDeniedBy.includes(user.id) ? 'error white--text' : 
                            'warning white--text'" class=" font-weight-bold mr-1" size="30">{{user.name }}</v-avatar>
                        </span>
                    </div>
                </template>

                <template v-slot:item.quoteSecondLevelApprovedBy="{ item }">
                    <div v-if="item.quoteStatus !== 'Draft' && item.quoteApprovalLevel">
                        <div v-if="item.quoteStatus !== 'Draft' && item.quoteApprovalLevel === '2'" class="d-flex">
                            <span v-for="(user, index) in secondLevelApprovalUsers" :key="index">
                                <v-avatar 
                                :class="
                                item.quoteSecondLevelApprovedBy.includes(user.id) ? 'success white--text' : 
                                item.quoteSecondLevelDeniedBy.includes(user.id) ? 'error white--text' : 
                                'warning white--text'" class=" font-weight-bold mr-1" size="30">{{user.name }}</v-avatar>
                            </span>
                        </div>
                    </div>
                </template> -->

                <template v-slot:item.quoteDate="{ item }">{{ $moment(item.quoteDate, 'X').format('DD-MMM-YYYY') }}</template>
                <template v-slot:item.quoteWinPercentage="{ item }">
                    <span v-if="item.quoteWinPercentage">{{ item.quoteWinPercentage }}%</span><span v-else>-</span>
                </template>
                <template v-slot:item.totalSell="{ item }">£{{ item.totalSell }}</template>

                <template v-slot:item.quoteDeliveryDate="{ item }">
                    <span v-if="item.quoteDeliveryDate !== '0'">{{ MIX_formatDateTime(item.quoteDeliveryDate, 'X', 'DD-MMM-YYYY') }}</span><span v-else-if="item.quoteDeliveryDate === '0'">TBC</span>
                </template>

                <template v-slot:item.productMainImage="{ item }">
                    <v-img width="100" height="100" contain :src="item.productMainImage" />
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon title="Show Revisions" class="primary--text ml-5" v-if="Number(item.quoteVersion) > 0 && item.quoteCurrentVersion === '1'" @click="showRevisions(item)">icons8-versions</v-icon>
                    <v-icon class="error--text pa-1" v-if="toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" v-if="orgid === ''" @click="openItem(item)">icons8-forward</v-icon>
                    <v-icon class="primary--text ml-5" v-if="orgid !== ''" @click="MIX_go('/quotes/' + item.id)">icons8-forward</v-icon>
                </template>

            </v-data-table>

            <!-- CARD BASED LIST -->
            <div class="text-center body-2 font-weight-normal grey--text text--darken-2" v-if="listshrunk">
            </div>
            <div v-if="listshrunk" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-1" v-if="showImages">
                            <v-img width="100" height="100" contain :src="item.productMainImage" />
                        </div>

                        <div class="ml-3">
                            <div class="body-1 font-weight-medium" style="width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.quoteTitle }}</div>
                            <div class="caption darkgrey--text">{{ item.orgName }}</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text" @click="openItem(item)">icons8-forward</v-icon>
                        </div>
                    </div>
                </v-card>
            </div>
            <!-- LOAD MORE CARDS -->
            <v-btn v-if="listshrunk" class="mt-2" depressed block>Load More</v-btn>
			<br /><br /><br />

        </div>
        <confirm-dialog :datadown="confirmDialog" v-on:method="confirmMethodHandler" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExportBox from "@/components/general/ExportBox.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
import TextField from "@/components/form/TextField.vue";
import AppButton from "@/components/general/AppButton.vue";
export default {
  components: {AppButton, TextField, CheckboxField, ExportBox},
    props: {
        orgid: {
            type: String,
            default: '',
        },
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        section: {
            type: String,
            default: 'quotes',
        },
    },
    data: () => ({
        loading: true,        
        key: 'quote',
        index: 'idx:quotes',
        // Toggles
        toggleDeletedItems: false,
        showImages: false,
        toggleFeatures: [],
        orgTags: [], // Tags for the quote
        orgTagAutocomplete: '',
        // Statuses
        statuses: [],
        // Users
        users: [],
        // Organisations
        organisations: [],
		contacts: [],
        // Channels
        channels: [],
        quoteStatus: [],
        quoteTypes: [],
        // Filters
        searchQuery: '',
        numberSearchQuery: '',
        selectedOwner: {
            ownerUserId: '',
            ownerUserName: '',
            ownerUserEmail: '',
        },
        filter: {
            quoteNumber: '',
            orgTagsOperator: 'ANY',
            orgTags: [],
            orgId: '',
            channel: '',
            quoteType: '',
            contactId: '',
            quoteInstall: '',
            quoteStatus: ['Draft', 'Awaiting Approval', 'Ready', 'Sent', 'Accepted'],
            quoteApprovalLevel: '',
            // TODO - RG - More Quote Filters to Add
        },

        firstLevelApprovalUsers : [
            { id: 'DGOGjDzCmnZLDHfv0BWR2G1SobT2', name: 'EB'}, 
            { id: 'Pr2QauqNq9bMhi94MYXCgPx3q8x2', name: 'AH' }, 
            { id: 'leQaGXlzfoSJmCIB1KNPmLkb3sX2', name: 'CP' }, 
            { id: 'CwmRwYA4CzZ57qH8tkSFJyEs6Mw1', name: 'LH' }, 
            { id: 'dAmKL8xoxNXBxTdfWcwofuN1fBQ2', name: 'SG' } 
        ],
        secondLevelApprovalUsers: [
            { id: 'DGOGjDzCmnZLDHfv0BWR2G1SobT2', name: 'EB' }, 
            { id: 'ai7FXnC6oVOLYjmmpTkB3aE4vpm2', name: 'GH' }, 
            { id: '3gIclPqXRtOz5lVlIE5y8o9j3Yu1', name: 'BL' },
        ],

        // Items
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 19,
        // Headers to display in the Data Table
        headers: [
            { text: 'id', align: 'start', sortable: false, value: 'id', hidden: true, expanded: true },
            { text: 'Quote No.', align: 'start', sortable: false, value: 'quoteNumber', hidden: false, expanded: false },
            { text: 'Client Reference', align: 'start', sortable: false, value: 'quoteClientRef', hidden: true, expanded: true },
            { text: 'Title', align: 'start', sortable: false, value: 'quoteTitle', hidden: false, expanded: false },
            { text: 'Total Sell', align: 'start', sortable: false, value: 'totalSell', hidden: false, expanded: false },    
            { text: 'Rev', align: 'center', sortable: false, value: 'quoteVersion', hidden: false, expanded: false },    
            { text: 'Current', align: 'center', sortable: false, value: 'quoteCurrentVersion', hidden: false, expanded: true },    
            { text: 'Description', align: 'start', sortable: false, value: 'quoteDescription', hidden: true, expanded: true },
            { text: 'Channel', align: 'start', sortable: false, value: 'quoteChannel', hidden: true, expanded: false },
            { text: 'Type', align: 'start', sortable: false, value: 'quoteType', hidden: true, expanded: false },
            { text: 'Install', align: 'start', sortable: false, value: 'quoteInstall', hidden: true, expanded: false },
            { text: 'Date', align: 'start', sortable: false, value: 'quoteDate', hidden: false, expanded: false },
            { text: 'Delivery Date', align: 'start', sortable: false, value: 'quoteDeliveryDate', hidden: true, expanded: true },
            { text: 'Validity Days', align: 'start', sortable: false, value: 'quoteDeliveryDate', hidden: true, expanded: true },
            { text: 'Org Tags', align: 'start', sortable: false, value: 'orgTags', hidden: true, expanded: true },
            { text: 'Orgisation ID', align: 'start', sortable: false, value: 'orgId', hidden: true, expanded: true },
            { text: 'Organisation', align: 'start', sortable: false, value: 'orgName', hidden: false, expanded: false },
            { text: 'Contact ID', align: 'start', sortable: false, value: 'contactId', hidden: true, expanded: true },
            { text: 'Contact', align: 'start', sortable: false, value: 'contactName', hidden: true, expanded: true },
            { text: 'Contact Email', align: 'start', sortable: false, value: 'contactEmail', hidden: true, expanded: true },
            { text: 'Owner User ID', align: 'start', sortable: false, value: 'ownerUserId', hidden: true, expanded: true },
            { text: 'Owner', align: 'start', sortable: false, value: 'ownerUserName', hidden: false, expanded: true },
            { text: 'Owner User Email', align: 'start', sortable: false, value: 'ownerUserEmail', hidden: true, expanded: true },
            { text: 'Win %', align: 'start', sortable: false, value: 'quoteWinPercentage', hidden: false, expanded: true },

            { text: 'Approved', align: 'start', sortable: false, value: 'approved', hidden: true, expanded: true },
            { text: 'Approval Level', align: 'start', sortable: false, value: 'quoteApprovalLevel', hidden: true, expanded: true },


            { text: 'Install Approval', align: 'center', sortable: false, value: 'quoteInstallApprovalStatus', hidden: false, expanded: true, width: '125px', style: "padding: 0px !important, margin: 0px !important" },
            { text: 'Supply Chain Approval', align: 'center', sortable: false, value: 'quoteSupplyChainApprovalStatus', hidden: false, expanded: true, width: '125px' },
            { text: 'Sales Manager Approval', align: 'center', sortable: false, value: 'quoteSalesManagerApprovalStatus', hidden: false, expanded: true, width: '125px' },
            { text: 'MD Director Approval', align: 'center', sortable: false, value: 'quoteMDDirectorApprovalStatus', hidden: false, expanded: true, width: '125px' },
            { text: 'FD Director Approval', align: 'center', sortable: false, value: 'quoteFDDirectorApprovalStatus', hidden: false, expanded: true, width: '125px' },
            { text: 'Status', align: 'start', sortable: false, value: 'quoteStatus', hidden: false, expanded: true },



            { text: 'Delivery AddressLine1', align: 'start', sortable: false, value: 'orgDeliveryAddressLine1', hidden: true, expanded: true },
            { text: 'Delivery AddressLine2', align: 'start', sortable: false, value: 'orgDeliveryAddressLine2', hidden: true, expanded: true },
            { text: 'Delivery AddressLine3', align: 'start', sortable: false, value: 'orgDeliveryAddressLine3', hidden: true, expanded: true },
            { text: 'Delivery Town', align: 'start', sortable: false, value: 'orgDeliveryTown', hidden: true, expanded: true },
            { text: 'Delivery County', align: 'start', sortable: false, value: 'orgDeliveryCounty', hidden: true, expanded: true },
            { text: 'Delivery Postcode', align: 'start', sortable: false, value: 'orgDeliveryPostcode', hidden: true, expanded: true },
            { text: 'Invoice AddressLine1', align: 'start', sortable: false, value: 'orgInvoiceAddressLine1', hidden: true, expanded: true },
            { text: 'Invoice AddressLine2', align: 'start', sortable: false, value: 'orgInvoiceAddressLine2', hidden: true, expanded: true },
            { text: 'Invoice AddressLine3', align: 'start', sortable: false, value: 'orgInvoiceAddressLine3', hidden: true, expanded: true },
            { text: 'Invoice Town', align: 'start', sortable: false, value: 'orgInvoiceTown', hidden: true, expanded: true },
            { text: 'Invoice County', align: 'start', sortable: false, value: 'orgInvoiceCounty', hidden: true, expanded: true },
            { text: 'Invoice Postcode', align: 'start', sortable: false, value: 'orgInvoicePostcode', hidden: true, expanded: true },
            { text: 'Key', value: 'key', sortable: false, hidden: true, expanded: true },
            { text: 'Date Time (C)', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (C)', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (C)', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Date Time (M)', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (M)', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (M)', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (M)', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Date Time (D)', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (D)', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (D)', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (D)', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, width: '175px' },
        ],
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@quoteTitle', // TODO - RG - Check this is needed along with datatableOptions
        datatableOptions: {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['quoteDate'],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        // Dialogs
        confirmDialog: {},
        confirmDestroy: {
            show: true,
            title: 'WARNING - Destroy Quote',
            message: 'Are you sure you want to destroy this quote? <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p>',
            color: 'error',
            actionText1: 'No',
            actionText2: 'Yes',
            id: '',
            method: 'destroyItem',
            action: '',
        },

      //EXPORT DATA
      exportBox: false,
      exportItems: {
        itemsFrom: "1",
        itemsTo: "10",
      },
      exportLoading: false,
      exportFiltered: false,
      showInfo: false,
      totalCount: 0,


    }),
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
		userLevel() { return this.GET_FIREBASE_userAccessToken.user_level },
		userGroup() { return this.GET_FIREBASE_userAccessToken.user_group },
		userStatus() { return this.GET_FIREBASE_userAccessToken.user_status },    
        // Computed Search
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.filter.quoteStatus.includes('Deleted')) {
                    searchQuery = '';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.filter.quoteStatus.includes('Deleted')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.filter.quoteStatus.includes('Deleted')) {
                        searchQuery = `${searchQuery}* `;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }
            console.log('quoteStatus = ' + JSON.stringify(this.filter.quoteStatus, null, 2))

            if (this.numberSearchQuery !== '') {
                searchQuery = `${searchQuery} @quoteNumber:{${this.numberSearchQuery}*}`;
            }
            // If Component passed orgid, then filter for organisation specific quotes
            if (this.orgid !== '') {
                searchQuery = `${searchQuery} @orgId:{${this.orgid}}`;
            }
            // If filter for organisation specific quotes
            if (this.filter.orgId !== '') {
                searchQuery = `${searchQuery} @orgId:{${this.filter.orgId}}`;
            }
            // If filter for contact specific quotes
            if (this.filter.contactId !== '') {
                searchQuery = `${searchQuery} @contactId:{${this.filter.contactId}}`;
            }
            console.log('selectedOwner', JSON.stringify(this.selectedOwner, null, 2))
            // If filter for user specific quotes
            if (this.selectedOwner !== null && this.selectedOwner.ownerUserId && this.selectedOwner.ownerUserId !== '' && this.selectedOwner.ownerUserId !== null && this.selectedOwner.ownerUserId !== undefined) {
                searchQuery = `${searchQuery} @ownerUserId:{${this.selectedOwner.ownerUserId}}`;
            }
            // If filter for channel specific quotes
            if (this.filter.channel !== '') {
                searchQuery = `${searchQuery} @quoteChannel:{${this.filter.channel}}`;
            }
            // If filter for quoteType specific quotes
            if (this.filter.quoteType !== '') {
                searchQuery = `${searchQuery} @quoteType:{${this.filter.quoteType}}`;
    
            // If filter for quoteNumber show only that quote
            } else if (this.filter.quoteNumber !== '') {
                searchQuery = `${searchQuery} @quoteNumber:{${this.filter.quoteNumber}}`;
            }
            // If filter for quoteInstall - quotes with install
            if (this.filter.quoteInstall !== '' && this.filter.quoteInstall !== null & this.filter.quoteInstall !== undefined) {
                searchQuery = `${searchQuery} @quoteInstall:${this.filter.quoteInstall}`;
            }
            // if filter for quoteStatus specific quotes
            if (JSON.stringify(this.filter.quoteStatus) !== '[]') {
                searchQuery = `${searchQuery} @quoteStatus:{${this.filter.quoteStatus.join('|')}}`;
            }

            // if (this.filter.quoteApprovalLevel !== '') {
            //     searchQuery = `${searchQuery} @quoteApprovalLevel:${this.filter.quoteApprovalLevel}`
            // }

            // Tag Filter
            if (JSON.stringify(this.filter.orgTags) !== '[]') {
                if (this.filter.orgTagsOperator === 'ANY') {
                    searchQuery = `${searchQuery} @orgId:{${this.filter.orgTags.join('|')}}`;
                } else {
                    let orgTags = [];
                    this.filter.orgTags.forEach((tag) => {
                        orgTags.push(`@orgId:{${tag}}`);
                    });
                    searchQuery = `${searchQuery} ${orgTags.join(' ')}`;
                }
            }

            console.log(searchQuery)
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        // Computed items
        computedItems() {
            var items = this.items; //.filter(x
            
            if (this.filter.quoteApprovalLevel !== '' && this.filter.quoteApprovalLevel !== undefined) {
                items = items.filter((item)  => { 
                    return item.quoteApprovalLevel == this.filter.quoteApprovalLevel
                })
            }
            console.log(items.length)

            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        // Computed Fields
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@orgId')) {
                computedFields.push('@orgId');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            if (!computedFields.includes('@version')) {
                computedFields.push('@version');
            }
            if (!computedFields.includes('@quoteApprovalLevel')) {
                computedFields.push('@quoteApprovalLevel')
            }
            if (!computedFields.includes('@quoteFirstLevelDeniedBy')) {
                computedFields.push('@quoteFirstLevelDeniedBy')
            }
            if (!computedFields.includes('@quoteSecondLevelDeniedBy')) {
                computedFields.push('@quoteSecondLevelDeniedBy')
            }
            //if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
            computedFields = computedFields.join(',');
            return computedFields;
        },
		// Computed Sort By
		computedSortBy () {
            let sortBy = this.datatableOptions.sortBy;
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => '@' + x);
                sortBy = sortBy.join(',');
            }
            return sortBy;
        },
		// Computed Sort Desc
        computedSortDesc() {
            let sortDesc = this.datatableOptions.sortDesc[0];
            return sortDesc;
        },
    },
    methods: {
        quoteVersion(quoteVersion) {
            const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

            var version = quoteVersion
            var revision = ''

            version = version - 1

            revision = alphabet[version]

            return revision
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            //				console.log('Going to Destroy Item: ' + itemId);
            let result = await this.MIX_destroyItem(itemId, 'quote');
            if (result.code === 1) {
                let lineItemResult = await this.MIX_redisSearch('idx:lineItems', '@quoteId:' + itemId, 0, 9999);
                //					console.log('Line Items: ' + JSON.stringify(lineItemResult, null, 2));
                if (lineItemResult.code === 1 && lineItemResult.data.total > 0) {
                    //						console.log('Going to Destroy line Items');
                    let lineItemIds = lineItemResult.data.documents.map((x) => x.value.id);
                    for (var i = 0; i < lineItemIds.length; i++) {
                        await this.MIX_destroyItem(lineItemIds[i], 'lineItem');
                    }
                    this.getItems();
                    this.$emit('closeitem');
                } else {
                    this.getItems();
                    this.$emit('closeitem');
                }
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            //console.log("quoteList.vue -> openItem");
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
            //this.activeItem = item;
            // this.listexpanded = false;
        },
        // * GET ITEMS
        async getItems() {
            this.loading = true;
            // console.log('getItems Running')
            // console.log('index: ' + this.index + '\n computedSearchQuery: ' + this.computedSearchQuery + '\n itemsFrom: ' + this.itemsFrom + '\n datatableOptions.itemsPerPage: ' + this.datatableOptions.itemsPerPage + '\n computedFields: ' + this.computedFields + '\n sortBy: ' + this.computedSortBy + '\n sortDesc: ' + this.computedSortDesc);
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.computedSortBy, this.computedSortDesc);
            // console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2))
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                //console.log("items = " + JSON.stringify(itemsResult, null, 2));
            }
            this.loading = false;            
        },
        // * Clear Tag Filter
        clearQuoteTagsFilter() {
            setTimeout(() => {
                this.filter.orgTags = [];
            }, 1);
        },
        clearSelectedOwner() {
            setTimeout(() => {
                this.selectedOwner = {
                    ownerUserId: '',
                    ownerUserName: '',
                    ownerUserEmail: '',  
                };
            }, 1);
        },
        // * Change Quotes Tag Operator
        changeQuoteTagsOperator() {
            if (this.filter.orgTagsOperator === 'ALL') {
                this.filter.orgTagsOperator = 'ANY';
            } else {
                this.filter.orgTagsOperator = 'ALL';
            }
        },
        confirmMethodHandler(value) {
            this.confirmDialog = {};
            if (value.action === 2) {
                this[value.method](value.id);
            }
        },
        // * Clear Filter
        clearFilter(key, value) {
            setTimeout(() => {
                this.filter[key] = value;
            }, 1);
        },
        showRevisions(item) {
            this.filter.quoteNumber = item.quoteNumber;
            if (this.filter.quoteStatus.includes("Revision")) {
                // do nothing
            } else {
                this.filter.quoteStatus.push ('Revision')
            }
            this.getItems();
        },
      // Export Box CSV
      async exportCSV(itemsFrom, itemsTo, filter, specific) {
        // console.table({ itemsFrom, itemsTo, filter, specific });
        if (itemsFrom > itemsTo) {
          this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
        } else {
          this.exportLoading = true;
          var itemsCount, tableData, csv, query, exportFileName;
          switch (true) {
              // Current Page / Current Total (filtered)
            case filter && !specific:
              itemsCount = itemsTo - itemsFrom;
              query = this.computedSearchQuery;
              break;
              // All (Not filtered)
            case !filter && !specific:
              itemsCount = this.totalCount;
              query = `*`;
              break;
              // Specific (filtered/ not filtered)
            case specific:
              itemsFrom = itemsFrom - 1;
              itemsCount = itemsTo - itemsFrom;
              if (this.exportFiltered) {
                query = this.computedSearchQuery;
              } else {
                query = `*`;
              }
              break;
          }
          // console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
          // let exportResult = await this.REDIS_searchFor("animal", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
          // console.log(query)
          // console.log(itemsCount)
          let exportResult = await this.MIX_redisAggregateSearch(this.index, query, itemsFrom, itemsCount, this.computedFields, this.sortBy, false);
          // console.log(exportResult)
          tableData = JSON.parse(JSON.stringify(exportResult.data.results));
          // console.table(tableData)
          csv = this.$papa.unparse(tableData);
          if (itemsFrom === 0) {
            itemsFrom = 1; // This is just for filename purposes
          }
          if (itemsFrom >= 10) {
            itemsFrom = itemsFrom + 1;
          }
          switch (true) {
            case !specific && filter:
              exportFileName = `Quote_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case !specific && !filter:
              exportFileName = `Quote_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && this.exportFiltered:
              exportFileName = `Quote_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && !this.exportFiltered:
              exportFileName = `Quote_Specific_${itemsFrom}_to_${itemsTo}`;
              break;
          }
          // console.log(exportFileName);
          // console.log(csv.length);
          this.$papa.download(csv, exportFileName);
          this.exportLoading = false;
        }
      },
      async getTotal(){
        let totalResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
        // console.log(totalResult)
        this.totalCount = totalResult.data.total;
      },
    },
    watch: {
        quoteid() {
            //console.log('quoteid changed');
        },
        headers:  {
            handler: async function () {
                this.getItems();
            }, deep: true
        },
        refreshitems: function () {
            this.getItems();
        },
        selectedOwner: {
            handler: async function () {
                this.getItems();
            }, deep: true
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            //console.log('imagesIndex = ' + imagesIndex)
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
        listshrunk() {
            if (this.listshrunk) {
                this.datatableOptions.itemsPerPage = 6;
                this.itemsFrom = 0;
                this.getItems();
            }
        },
        listexpanded() {
            if (this.listexpanded === false) {
                this.datatableOptions.itemsPerPage = 10;
                this.itemsFrom = 0;
                this.getItems();
            } else if (this.listexpanded === true) {
                this.datatableOptions.itemsPerPage = 10;
                this.itemsFrom = 0;
                this.getItems();
            }
        },
        filter: {
            handler: async function () {
                if (this.filter.orgId !== '') {
                    this.contacts = await this.MIX_contacts(this.filter.orgId);
                }
            },
            deep: true,
        },
    },
    async created() {
        let t = this;
        t.loading = true;        
        if (this.orgid == '') {
            this.selectedOwner = this.MIX_getCurrentOwner();
        }
        let orgTagsResult = await t.MIX_redisReadSet('set:orgTags', false);
        if (orgTagsResult.code === 1) {
            t.orgTags = orgTagsResult.data;
        }
        // Get Quote Status
        let quoteStatusResult = await t.MIX_redisReadSortedSet('sset:quoteStatus', 0, 9999);
        if (quoteStatusResult.code === 1) {
            t.quoteStatus = quoteStatusResult.data;
        }
        // Get Customers
        t.organisations = await t.MIX_organisations('Customer');
        // Get Users
        let users = await this.MIX_users(''); // Add Users to Dropdown for Owner
        this.users = users.map((x) => {
            return {
                ownerUserId: x.id,
                ownerUserName: x.userName,
                ownerUserEmail: x.userEmail,
            };
        });
        // Get Quote Channels
        let channelsResult = await t.MIX_redisReadSet('set:channels', true);
        if (channelsResult.code === 1) {
            t.channels = channelsResult.data;
        }
        // Get Quote Types
        let quoteTypesResult = await t.MIX_redisReadSet('set:quoteTypes', true);
        if (quoteTypesResult.code === 1) {
            t.quoteTypes = quoteTypesResult.data;
        }
        t.getTotal();
        t.getItems();
    },
};
</script>
