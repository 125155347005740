<!--
CheckNetwork

Checks if the user is connected to the internet and shows a RED banner warning the user.
Checks if the user has a slow internet connection (2g or slow-2g) and shows an ORANGE banner warning the user.

 -->
<template>
    <div>
        <v-overlay v-if="!onLine" opacity="0.7" z-index="99998" />

        <v-alert v-if="!onLine" class="d-flex rounded-0" icon="mdi-wifi-off" type="error">
            <span> To use this app you must be connected to WIFI </span>
        </v-alert>

        <v-alert v-if="onLine && networkType && (networkType === '2g' || networkType === 'slow-2g')" class="d-flex rounded-0" icon="mdi-wifi-off" type="warning">
            <span> Your WIFI is slow, some things might not work </span>
        </v-alert>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // Detect if user has an internet connection
            onLine: navigator.onLine,
            // Detect network connection, eg. 2g / slow-2g
            networkType: false,
        };
    },
    methods: {
        onConnectionChange() {
            this.networkType = navigator.connection.effectiveType;
        },
        updateOnlineStatus(e) {
            const { type } = e;
            this.onLine = type === 'online';
        },
    },
    mounted() {
        //console.log( 'navigator.onLine = ' +  navigator.onLine)
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        if (navigator.connection) {
            this.networkType = navigator.connection.effectiveType;
            navigator.connection.addEventListener('change', this.onConnectionChange);
        }
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
        if (navigator.connection) {
            this.networkType = navigator.connection.effectiveType;
            navigator.connection.removeEventListener('change', this.onConnectionChange);
        }
    },
};
</script>

<style>
.v-alert {
    border-radius: 0 !important;
    bottom: 0 !important;
    margin-bottom: 0 !important;
    padding: 16px !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 99999 !important;
}

.v-alert .v-icon {
    align-self: center !important;
    font-size: 32px !important;
}
</style>
