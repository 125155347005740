<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field label="Search Addresses " v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field>
            </div>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>icons8-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>icons8-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <v-row class="pa-3 mt-1">
            <!-- TABLE BASED LIST -->
            <v-data-table :item-class="itemRowBackground" sortable dense class="" style="width: 100%" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions"
                >>
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn value="edit" small>
								<v-icon class="grey--text text--darken-3">icons8-edit</v-icon>
							</v-btn>
                            <v-btn value="destroy" small v-if="['Developer-Admin'].includes(userLevel)">
                                <v-icon class="error--text">icons8-delete-trash</v-icon>
                            </v-btn>
                            <v-btn value="delete" small v-if="['Developer-Admin','Staff-Admin','Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                            <v-btn value="undelete" small v-if="['Developer-Admin','Staff-Admin','Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        <!-- <div class="mb-5 mx-5"><v-switch v-model="showImages" color="primary" label="Images" hide-details="auto" inset></v-switch></div>
					<div class="mb-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>-->
                    </div>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon class="grey--text text--darken-2 ml-5" @click="editItem(item.id)" v-if="toggleFeatures.includes('edit')">icons8-edit</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">icons8-trash-restore</v-icon>
                    <v-icon style="border: 1px red solid; border-radius: 3px" class="error--text ml-5" @click="destroyItem(item.id)" v-if="toggleFeatures.includes('destroy')">icons8-delete-trash</v-icon>
                </template>
            </v-data-table>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        key: 'address',
        index: 'idx:addresses',
        org: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'Name', value: 'addressName', sortable: false, hidden: false, expanded: false },
            { text: 'Type', value: 'addressType', sortable: false, hidden: false, expanded: false },
            { text: 'Address Line 1', value: 'addressLine1', sortable: false, hidden: false, expanded: false },
            { text: 'Address Line 2', value: 'addressLine2', sortable: false, hidden: false, expanded: false },
            { text: 'Address Line 3', value: 'addressLine3', sortable: false, hidden: true, expanded: true },
            { text: 'Town', value: 'addressTown', sortable: false, hidden: false, expanded: false },
            { text: 'County', value: 'addressCounty', sortable: false, hidden: false, expanded: false },
            { text: 'Postcode', value: 'addressPostcode', sortable: false, hidden: false, expanded: false },
            { text: 'Org Id', value: 'orgId', sortable: false, hidden: true, expanded: true },
            { text: 'Organisation', value: 'orgName', sortable: false, hidden: true, expanded: true },
            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@addressName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['addressName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        filter: {
            productCategories: [],
        },
    }),
    computed: {
         ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
		userLevel() { return this.GET_FIREBASE_userAccessToken.user_level },
		userGroup() { return this.GET_FIREBASE_userAccessToken.user_group },
		userStatus() { return this.GET_FIREBASE_userAccessToken.user_status },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete')) {
                    searchQuery = `@orgId:{${this.org.id}}`;
                } else {
                    searchQuery = `@deleted:0 @orgId:{${this.org.id}}`;
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete')) {
                        searchQuery = `${searchQuery}* @orgId:{${this.org.id}}`;
                    } else {
                        searchQuery = `${searchQuery}* @orgId:{${this.org.id}} @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete')) {
                        searchQuery = `${searchQuery}* @orgId:{${this.org.id}}`;
                    } else {
                        searchQuery = `${searchQuery}* @orgId:{${this.org.id}} @deleted:0`;
                    }
                }
            }
            // Page Filters
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            if (!computedFields.includes('@orgId')) {
                computedFields.push('@orgId');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
    },
    methods: {
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, "address");
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Editing Address", show: true });
            }
            //alert(JSON.stringify(itemFullResult));
            // Emit the item to the parent component edit
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
            //this.activeItem = item;
            // this.listexpanded = false;
        },
        // * GET ITEMS
        async getItems() {
            // console.log('addressList.vue > getItems Running');
            // console.log('index: ' + this.index + '\n computedSearchQuery: ' + this.computedSearchQuery + '\n itemsFrom: ' + this.itemsFrom + '\n datatableOptions.itemsPerPage: ' + this.datatableOptions.itemsPerPage + '\n computedFields: ' + this.computedFields + '\n sortBy: ' + this.sortBy);
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            // console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                //console.log("items = " + JSON.stringify(itemsResult, null, 2));
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.org = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            //console.log('imagesIndex = ' + imagesIndex)
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        this.org = { ...this.datadown };
        this.getItems();
    },
};
</script>