<template>
  <div style="width: 100% !important; height: 100% !important" class="grey lighten-3"
       :style="$vuetify.breakpoint.mdAndUp ? heroImage : ''">
    <v-row class="px-3">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 10 : 12" class="mt-5">
        <!-- <v-img v-if="$vuetify.breakpoint.mdAndUp" width="220" height="104" contain src="@/assets/logo.png" /> -->


        <v-row class="" dense>
          <v-col cols="12" class="rounded text-left pb-0">
            <v-card :class="$vuetify.breakpoint.mdAndUp ? 'grey lighten-3' : 'grey lighten-5'"
                    class="pa-3 mb-0  elevation-0">
              <div class="">
                <div :class="$vuetify.breakpoint.smAndDown ? 'text-h6' : 'text-h5 px-1'"
                     class="font-weight-light secondary--text text-h5">Lets Get Started
                </div>
                <div :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'text-h7 px-1'" class="my-5">
                  Hi <strong>{{ GET_FIREBASE_userAuth.displayName }}</strong>
                  and welcome to the Ministry of Furniture Smart Workplace App (SWAPP).
                  <div v-if="$vuetify.breakpoint.mdAndUp" class="mt-3">This App has been custom built for Ministry of
                    Furniture, and we are just getting started, so expect more features as we continue to build this
                    software. Below you will find some areas to get started, and you can use the menu on the left hand
                    side to navigate through the app.
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          <!-- </v-row>-->

<!--          TODO: SBT - uncomment when Sales is ready-->
<!--          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" class="pt-0 mt-0">-->
<!--            <v-row class="mt-1" dense>-->
<!--              <v-col cols="6">-->
<!--                <v-card class="pa-3  elevation-0">-->

<!--                  <div class="d-flex align-center">-->
<!--                    <div>-->
<!--                      <v-icon class="primary&#45;&#45;text" x-large>icons8-day-off</v-icon>-->
<!--                    </div>-->
<!--                    <div class="text-h5 font-weight-li primary&#45;&#45;text ml-2">Activities</div>-->
<!--                  </div>-->

<!--                  <homeActivities v-on:refreshItems="refreshitems++" :refreshItems="refreshitems" style="min-height: 40vh; max-height: 40vh; overflow-y: scroll !important; overflow-x: hidden !important;"></homeActivities>-->

<!--                </v-card>-->
<!--              </v-col>-->

<!--              <v-col cols="6">-->
<!--                <v-card class="pa-3  elevation-0">-->

<!--                  <div class="d-flex align-center">-->
<!--                    <div>-->
<!--                      <v-icon class="primary&#45;&#45;text" x-large>icons8-day-off</v-icon>-->
<!--                    </div>-->
<!--                    <div class="text-h5 font-weight-li primary&#45;&#45;text ml-2">Actions</div>-->
<!--                  </div>-->

<!--                  <homeActions v-on:refreshItems="refreshitems++" :refreshItems="refreshitems" style="min-height: 40vh; max-height: 40vh; overflow-y: scroll !important; overflow-x: hidden !important;"></homeActions>-->

<!--                </v-card>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-col>-->

          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" class="pt-0 mt-0">
            <v-row class="mt-1" dense>
              <v-col cols="4" v-for="card in cards" :key="card.id">
                <v-card class="pa-3  elevation-0" v-if="card.userGroup.includes(userGroup)">

                  <div class="d-flex align-center">
                    <div>
                      <v-icon class="primary--text" x-large>{{ card.icon }}</v-icon>
                    </div>
                    <div class="text-h5 font-weight-li primary--text ml-2">{{ card.title }}</div>
                  </div>

                  <div class="body-1 mt-2 ml-2 text-left apptext--text">{{ card.subtitle }}</div>

                  <v-card-actions class="pt-2 justify-end">
                    <v-btn :disabled="card.disabled" @click="MIX_go(card.link)" class="accent secondary--text mt-3"
                           depressed>{{ card.buttonText }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="pt-0 mt-0">
            <v-row class="mt-1" dense>
              <v-col v-for="card in cardsMobile" :key="card.id" cols="12">
                <v-card class="pa-3  elevation-0">

                  <div class="d-flex align-center">
                    <div class="d-flex align-center">
                      <div>
                        <v-icon class="primary--text" medium>{{ card.icon }}</v-icon>
                      </div>
                      <div class="text-h6 font-weight-light primary--text ml-2">{{ card.title }}</div>
                    </div>
                    <v-spacer/>
                    <v-btn :disabled="card.disabled" @click="MIX_go(card.link)" class="accent secondary--text mt-3"
                           depressed>
                      <span class="caption">{{ card.buttonText }}</span>
                    </v-btn>
                  </div>

                  <div class="body-2 mt-2 ml-1 text-left apptext--text">{{ card.subtitle }}</div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="4" class="rounded text-right">
            <v-card height="200" class="pa-3">

                            <div class="d-flex align-center">
                <div><v-icon class="primary--text" x-large>icons8-bill</v-icon></div>
                <div class="text-h5 font-weight-li primary--text ml-2">Quotes</div>
              </div>

              <div class="body-1 mt-2 ml-2 text-left apptext--text">Looking to create a new Quote, click New Quote or use the menu left.</div>

                            <v-card-actions>
                                <v-btn @click="MIX_go('/quotes/new')" class="accent secondary--text mt-3" depressed>New Quote</v-btn>
                            </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="4" class="rounded text-right">
            <v-card class="pa-3"
              ><div class="d-flex align-center">
                <div><v-icon class="primary--text" x-large>icons8-hotel-building</v-icon></div>
                <div class="text-h5 font-weight-li primary--text ml-2">Customers</div>
              </div>

              <div class="body-1 mt-2 ml-2 text-left apptext--text">Looking to create a new Customer, click New Customer or use the menu left.</div>

              <v-spacer />
              <v-btn @click="MIX_go('/customers/new')" class="accent secondary--text mt-3" depressed>New Customer</v-btn>
            </v-card>
          </v-col> -->
          <!-- </v-row>

          <v-row class="my-2" dense> -->
          <!-- <v-col cols="4" class="rounded text-right">
            <v-card class="pa-3"
              ><div class="d-flex align-center">
                <div><v-icon class="primary--text" x-large>icons8-tags</v-icon></div>
                <div class="text-h5 font-weight-li primary--text ml-2">Products</div>
              </div>

              <div class="body-1 mt-2 ml-2 text-left apptext--text">Looking to create a new Product, click New Product or use the menu left.</div>

              <v-spacer />
              <v-btn @click="MIX_go('/products')" class="accent secondary--text mt-3" depressed>View Products</v-btn>
            </v-card>
          </v-col>
          <v-col cols="4" class="rounded text-right">
            <v-card class="pa-3"
              ><div class="d-flex align-center">
                <div><v-icon class="primary--text" x-large>icons8-user-groups</v-icon></div>
                <div class="text-h5 font-weight-li primary--text ml-2">People</div>
              </div>

              <div class="body-1 mt-2 ml-2 text-left apptext--text">Looking to create a new Person, click New Person or use the menu left.</div>

              <v-spacer />
              <v-btn @click="MIX_go('/contacts')" class="accent secondary--text mt-3" depressed>View Person</v-btn>
            </v-card>
          </v-col> -->
          <!-- </v-row>
          <v-row class="my-2" dense> -->
          <!-- <v-col cols="4" class="rounded text-right">
            <v-card class="pa-3"
              ><div class="d-flex align-center">
                <div><v-icon class="primary--text" x-large>icons8-business-network</v-icon></div>
                <div class="text-h5 font-weight-li primary--text ml-2">Leads</div>
              </div>

              <div class="body-1 mt-2 ml-2 text-left apptext--text">Looking to create a new Quote, click New Quote or use the menu left.</div>

              <v-spacer />
              <v-btn @click="MIX_go('/leads')" class="accent secondary--text mt-3" depressed>New Lead</v-btn>
            </v-card>
          </v-col>
          <v-col cols="4" class="rounded text-right">
            <v-card class="pa-3"
              ><div class="d-flex align-center">
                <div><v-icon class="primary--text" x-large>icons8-pipeline</v-icon></div>
                <div class="text-h5 font-weight-li primary--text ml-2">Opportunities</div>
              </div>

              <div class="body-1 mt-2 ml-2 text-left apptext--text">Looking to create a new Opportunity, click New Opportunity or use the menu left.</div>

              <v-spacer />
              <v-btn disabled class="accent secondary--text mt-3" depressed>New Opportunity</v-btn>
            </v-card>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-0">
        <!-- App Copyright -->
        <appCopyright :colour="'white'"/>
      </v-col>
      <!-- <v-col cols="6" v-if="$vuetify.breakpoint.mdAndUp" class="" fill-height>
        <div class="d-flex align-center" fill-height>
          <v-row class="" densefill-height>
            <v-col cols="6" fill-height></v-col>
            <v-col cols="6" class="grey lighten-3 pt-5 pr-6">
              <div class="d-flex align-center">
                <div><v-icon class="primary--text" x-large>icons8-notification</v-icon></div>
                <div class="text-h5 font-weight-li primary--text ml-2">Notifications</div>
              </div>

              <v-card outlined class=""> -->
      <!-- <v-card-title class="primary d-flex align-center pa-3"> -->
      <!-- </v-card>
      <v-card v-for="(i, index) in 9" :key="index" class="mt-4 pa-3 d-flex align-center white lighten-4">
        <div>
          <strong>Authorise Quote</strong><br />
          Quote: 11233 - Sommerset School...<br />
          <span class="body-2 font-weight-bold grey--text text--darken-2">20-04-2022 @ 10:40</span>
        </div>
        <v-spacer /><v-btn class="accent secondary--text" depressed>Go</v-btn>
      </v-card>
    </v-col> -->
      <!-- <v-col cols="6">
      <v-card rounded class="">
        <v-card-title class="secondary d-flex align-center pa-3">
        <div class="text-h5  font-weight-li white--text ml-2">Notifications</div><v-spacer /><v-icon class="white--text" x-large>icons8-notification</v-icon></v-card-title>

      </v-card>
      <v-card v-for="(i, index) in 6" :key="index" class="mt-3 pa-3 d-flex align-center yellow lighten-4">
        <div>
          <strong>Authorise Quote</strong><br />
          Quote: 11233 - Sommerset School...<br />
          <span class="body-2 font-weight-bold grey--text text--darken-2">20-04-2022 @ 10:40</span>
        </div>
        <v-spacer /><v-btn class="accent secondary--text" depressed>Go</v-btn>
      </v-card>

    </v-col> -->
      <!-- </v-row>
    </div>
  </v-col> -->
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
// import homeActions from "@/views/home/homeActions.vue";
// import homeActivities from "@/views/home/homeActivities.vue";

export default {
  name: "Home",
  data: () => ({
    loading: false,
    images: ["background2.jpg", "background1.jpg"],
    refreshitems: 0,
    cards: [
      {
        icon: 'icons8-bill',
        title: 'Quotes',
        subtitle: 'Looking to create a new Quote, click New Quote or use the menu on the left.',
        link: '/quotes/new',
        buttonText: 'New Quote',
        disabled: false,
        userGroup: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers']
      },
      {
        icon: 'icons8-hotel-building',
        title: 'Customers',
        subtitle: 'Looking to create a new Customer, click New Customer or use the menu on the left.',
        link: '/customers/new',
        buttonText: 'New Customer',
        disabled: false,
        userGroup: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers']
      },
      {
        icon: 'icons8-tags',
        title: 'Products',
        subtitle: 'Looking to create a new Product, click New Product or use the menu on the left.',
        link: '/products',
        buttonText: 'New Product',
        disabled: false,
        userGroup: ['Administration', 'Purchasing', 'Marketing', 'Design', 'Developers']
      },
      {
        icon: 'icons8-user-groups',
        title: 'People',
        subtitle: 'Looking to create a new Person, click New Person or use the menu on the left.',
        link: '/contacts',
        buttonText: 'New Person',
        disabled: false,
        userGroup: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers']
      },
      {
        icon: 'icons8-business-network',
        title: 'Leads',
        subtitle: 'Looking to create a new Lead, click New Lead or use the menu on the left.',
        link: '/sales/leads?new=true',
        buttonText: 'New Lead',
        disabled: false,
        userGroup: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers']
      },
      {
        icon: 'icons8-pipeline',
        title: 'Opportunities',
        subtitle: 'Looking to create a new Opportunity, click New Opportunity or use the menu on the left.',
        link: '/sales/Opportunities?newOpp=true',
        buttonText: 'New Opportunity',
        disabled: false,
        userGroup: ['Sales', 'Administration', 'Purchasing', 'Marketing', 'Board', 'Developers']
      },
    ],
    cardsMobile: [
      {
        icon: 'icons8-hotel-building',
        title: 'Customers',
        subtitle: 'Looking to create a new Customer, click New Customer or use the menu on the left.',
        link: '/customers/new',
        buttonText: 'New Customer',
        disabled: false
      },
      {
        icon: 'icons8-user-groups',
        title: 'People',
        subtitle: 'Looking to create a new Person, click New Person or use the menu on the left.',
        link: '/contacts',
        buttonText: 'New Person',
        disabled: false
      },
      {
        icon: 'icons8-business-network',
        title: 'Leads',
        subtitle: 'Looking to create a new Lead, click New Lead or use the menu on the left.',
        link: '/leads',
        buttonText: 'New Lead',
        disabled: false
      },
    ]
  }),
  components: {
    // homeActions,
    // homeActivities
  },
  computed: {
    ...mapGetters({
      GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
      GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
    }),
    userLevel() {
      return this.GET_FIREBASE_userAccessToken.user_level
    },
    userGroup() {
      return this.GET_FIREBASE_userAccessToken.user_group
    },
    userStatus() {
      return this.GET_FIREBASE_userAccessToken.user_status
    },
    heroImage() {
      return {
        backgroundSize: `100% 100%`,
        backgroundImage: `url(${require("../../assets/background" + Math.ceil(Math.random() * 2) + ".jpg")})`,
      };
    },
  },
  mounted() {
    console.log();
  },
  methods: {},
};
</script>
