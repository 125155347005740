<template>
	<div>
		<v-row class="pa-3" no-gutters>
			<v-col cols="12" sm="12">
				<div class="text-left primary--text text-h6 font-weight-light">Colours ({{ itemsTotal }})</div>
				<v-divider class="grey lighten-1 mb-4"></v-divider>
				<div class="my-3 d-flex align-center">
					<div class="flex-grow-1"><v-text-field label="Search Colours" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field></div>
					<div class="ml-2"><v-autocomplete label="Filter by Option" v-model="optionSelected" :items="computedFilterByOption" item-text="optionName" return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search"></v-autocomplete></div>
					<div class="ml-2">
						<v-btn depressed outlined class="primary--text" @click="getItems()"><v-icon class="" color="primary">icons8-available-updates</v-icon></v-btn>
					</div>
					<div class="ml-2">
						<v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important;" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
									<v-btn depressed outlined class="primary--text" v-bind="attrs" v-on="on"><v-icon class="" color="primary">icons8-table-properties</v-icon></v-btn>
							</template>
							<v-card class="pa-5" width="250" >
								<div class="body-2 font-weight-bold">Show / hide Columns</div>
								<v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
							</v-card>
						</v-menu>
					</div>
					<div class="mb-5 ml-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>
				</div>
				<!-- TABLE BASED LIST -->
				<v-data-table sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions">
					<template v-slot:item.deleted="{ item }">
						<span v-if="item.deleted === 'true'">Deleted</span>
					</template>

					<template v-slot:item.action="{ item }">
						<v-icon class="error--text pa-1" style="border: 1px red solid; border-radius: 3px" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
						<v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === 'false'">icons8-trash-can</v-icon>
						<v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === 'true'">icons8-trash-restore</v-icon>
						<v-icon class="primary--text ml-5" @click="editItem(item.id)">icons8-edit</v-icon>
					</template>
				</v-data-table>
			</v-col>
			<v-col cols="12" sm="12" class="mt-5">
				<div class="text-left primary--text text-h6 font-weight-light">Add Fabric (NEED TO REDO)</div>
				<v-divider class="grey lighten-1 mb-4"></v-divider>
			</v-col>
			<v-col cols="12" sm="12" class="white pa-3">
				<v-simple-table dense>
					<template v-slot:default>
						<tbody>
							<tr>
								<td width="50%">Fabric</td>
								<td width="50%" class="py-2">
									<div><v-autocomplete v-model="item" :items="fabrics" item-text="attributeName" return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete></div>
								</td>
							</tr>

							<tr>
								<td width="50%">Code</td>
								<td width="50%" class="py-2">
									<div><v-text-field v-model="item.attributeCode" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
								</td>
							</tr>
							<tr>
								<td width="50%">Name</td>
								<td width="50%" class="py-2">
									<div><v-text-field v-model="item.attributeName" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
								</td>
							</tr>
							<tr>
								<td width="50%">Price Band</td>
								<td width="50%" class="py-2">
									<div><v-autocomplete label="Price Band" v-model="item.priceBand" :items="priceBands" outlined background-color="white" hide-details="auto" dense /></div>
								</td>
							</tr>
							<tr v-if="costsToggle">
								<td width="50%">Additional Cost</td>
								<td width="50%" class="py-2">
									<div><v-text-field label="Add Cost" v-model="item.addCost" prefix="£" outlined dense hide-details="auto" background-color="white" color="primary" append-icon="mdi-close" @click:append="item.addCost = 0"></v-text-field></div>
								</td>
							</tr>
							<tr v-if="costsToggle">
								<td width="50%">Additional Sell</td>
								<td width="50%" class="py-2">
									<div><v-text-field label="Add Sell" v-model="item.addSell" prefix="£" outlined dense hide-details="auto" background-color="white" color="primary" append-icon="mdi-close" @click:append="item.addSell = 0"></v-text-field></div>
								</td>
							</tr>
							<tr>
								<td colspan="2" class="py-2">
									<v-btn
										class="primary--text font-weight-bold"
										depressed
										small
										@click="
											costsToggle = !costsToggle;
											item.addCost = 0;
											item.addSell = 0;
										"
										><span v-if="!costsToggle">+ Add</span><span v-if="costsToggle">- Remove</span>&nbsp; Costs</v-btn
									>
								</td>
							</tr>

							<tr>
								<td colspan="2" class="py-2 text-right">
									<v-btn class="primary--text font-weight-bold" depressed @click="createItem"><span v-if="!addImageToggle">Add Fabric</span></v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				computedFilterByOption: <br /><pre>{{ optionSelected }}</pre>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import schema from "@/schema.js";
	import { mapGetters } from "vuex";
	export default {
		// * PROPS
		props: {
			editmode: {
				type: Boolean,
				default: false,
			},
			org: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			item: {},
			newItem: true,
			key: "attribute",
			addImageToggle: false,
			costsToggle: false,
			index: "idx:attributes",
			toggleDeletedItems: false,
			schema: {},
			showImages: false,
			items: [],
			itemsTotal: null,
			searchQuery: "",
			itemsFrom: 0,
			itemsTo: 9,
			activeItem: {},
			fabrics: [], // Stores Fabrics for Dropdown
			options: [],
			optionSelected: {},

			// Price Bands
			priceBands: ["A", "B", "C", "D", "E", "F"],

			// Headers to display in the Data Table
			headers: [
				{ text: "Id", value: "id", hidden: true },
				{ text: "Code", value: "attCode", hidden: false },
				{ text: "Name", value: "attName", hidden: false },
				{ text: "Type", value: "attType", hidden: true },
				{ text: "MTO", value: "attMTO", hidden: false },
				{ text: "Price Band", value: "attPriceBand", hidden: true },
				{ text: "Add Cost", value: "attAddCost", hidden: false },
				{ text: "Add Sell", value: "attAddSell", hidden: false },
				{ text: "Enabled", value: "enabled", hidden: true },
				{ text: "Parent ID", value: "attParentId", hidden: true },
				{ text: "Description", value: "attDescription", hidden: true },
				{ text: "Hex Color", value: "attHexColor", hidden: false },
				{ text: "Image", value: "attImage", hidden: true },
				{ text: "Option Id", value: "optionId", hidden: true },
				{ text: "Option Name", value: "optionName", hidden: false },
				{ text: "Supplier ID", value: "orgId", hidden: true },
				{ text: "Supplier Name", value: "orgName", hidden: true },
				{ text: "Date Time (C)", value: "createdDateTime", hidden: true },
				{ text: "User ID (C)", value: "createdUserId", hidden: true },
				{ text: "User Name (C)", value: "createdUserName", hidden: true },
				{ text: "User Email (C)", value: "createdUserEmail", hidden: true },
				{ text: "Date Time (M)", value: "modifiedDateTime", hidden: true },
				{ text: "User ID (M)", value: "modifiedUserId", hidden: true },
				{ text: "User Name (M)", value: "modifiedUserName", hidden: true },
				{ text: "User Email (M)", value: "modifiedUserEmail", hidden: true },
				{ text: "Deleted", value: "deleted", hidden: true },
				{ text: "Date Time (D)", value: "deletedDateTime", hidden: true },
				{ text: "User ID (D)", value: "deletedUserId", hidden: true },
				{ text: "User Name (D)", value: "deletedUserName", hidden: true },
				{ text: "User Email (D)", value: "deletedUserEmail", hidden: true }, 
				{ text: "Action", value: "action", align: "end", hidden: false, width: "155px" },
			],
			// Fields to Retrieve from Database
			sortBy: "@attName",
			datatableOptions: {
				page: 1,
				itemsPerPage: 10,
				sortBy: ["attName"],
				sortDesc: [false],
				groupBy: [],
				groupDesc: [],
				mustSort: false,
				multiSort: false,
			},
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
			computedFields() {
				let computedFields = this.headers.filter(x => x.hidden === false).map(y => '@'+ y.value);
				if (!computedFields.includes("@id")) { computedFields.push ("@id") }
				if (!computedFields.includes("@optionId")) { computedFields.push ("@optionId") }
				if (!computedFields.includes("@optionName")) { computedFields.push ("@optionName") }
				computedFields = computedFields.join(',');
				return computedFields
			},
			computedSearchQuery() {
				let searchQuery = this.searchQuery;
				if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
					if (this.toggleDeletedItems) {
						searchQuery = `@orgId:${this.org.id}`;
					} else {
						searchQuery = `@deleted:0 @orgId:${this.org.id}`;
					}
				} else {
					if (searchQuery.startsWith("@")) {
						if (this.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @orgId:${this.org.id}`;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @orgId:${this.org.id}`;
						}
					} else {
						if (this.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @orgId:${this.org.id}`;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @orgId:${this.org.id}`;
						}
					}
				}
				// Filter By optionId
				if (this.optionSelected?.optionId) {
					searchQuery = `${searchQuery} @optionId:${this.optionSelected.optionId}`;
				}
				return searchQuery;
			},
			computedHeaders() {
				return this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
			},
			computedItems() {
				var items = this.items; //.filter(x => x.);
				return items; //.slice(this.organisationsFrom, this.organisationsTo);
			},
			computedFilterByOption() {
				var items = this.items;
				const key = 'optionId';
				items = items.map(x => ({ optionId: x.optionId, optionName: x.optionName }))
				items = [...new Map(items.map(item => [item[key], item])).values()];
				return items
			}
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				t.item = { ...t.schema };
			},
			// * CREATE ITEM
			async createItem() {
				let t = this;
				t.item.id = t.MIX_generateId();
				t.item.attributeGroup = "Fabric"; // TODO
				t.item.attributeType = "Fabric"; // TODO
				t.item.orgId = this.org.id;
				t.item.orgName = this.org.orgName;
				let result = await this.MIX_createItem(this.item, this.key, this.newItem);
				if (result.code === 1) {
					this.getItems();
					this.initialiseItem();
				}
			},
			// * EDIT ITEM
			async editItem(itemId) {
				let result = await this.MIX_readItem(itemId, this.key);
				if (result.code === 1) {
					this.item = result.data;
				}
			},
			// * DESTROY ITEM
			async destroyItem(itemId) {
				let result = await this.MIX_destroyItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// * DELETE ITEM
			async deleteItem(itemId) {
				let result = await this.MIX_deleteItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// * RESTORE ITEM
			async restoreItem(itemId) {
				let result = await this.MIX_restoreItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			async getItems() {
				//console.log("getItems");
				//console.log("this.index = " + this.index);
				//console.log("this.this.computedFields = " + this.computedFields);
				let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
				//console.log("itemsResult = " + JSON.stringify(itemsResult, null, 2));
				if (itemsResult.code === 1) {
					this.itemsTotal = itemsResult.data.total;
					this.items = itemsResult.data.results;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
				} else {
					this.MIX_alertBox({ color: "error", message: "Error retreiving items", timeout: 4000, show: true });
				}
			},
			// * GET FABRICS (for Dropdown)
			async getFabrics() {
				let result = await this.MIX_redisSearch("idx:fabrics", "@deleted:0", 0, 9999);
				//console.log('result = ' + JSON.stringify(result, null, 2));
				if (result.code === 1) {
					this.fabrics = result.data.documents.map((x) => x.value);
					//console.log('this.fabrics = ' + JSON.stringify(this.fabrics, null, 2))
				}
			},
			// * GET ALL OPTIONS (for Dropdown)
			async getOptions() {
				let result = await this.MIX_redisSearch("idx:options", "@deleted:0 @enabled:1", 0, 9999);
				//console.log("result = " + JSON.stringify(result, null, 2));
				if (result.code === 1) {
					this.options = result.data.documents.map((x) => {
						return {
							...x.value,
						};
					});
				}
			},			
		},
		// * WATCH
		watch: {
			// If the search query changes then get updated dataset based on search query
			computedSearchQuery() {
				this.getItems();
			},
			toggleDeletedItems() {
				this.getItems();
			},
			computedFields: {
				handler() {
					this.getItems();
				},deep: true
			},
			datatableOptions: {
				handler() {
					this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
					this.getItems();
				},
				deep: true,
			},
		},
		// * CREATED
		created() {
			this.schema = schema.attribute;
			this.getFabrics(); // Get Fabrics for Fabric Dropdown
			this.getItems();
			this.initialiseItem();
			this.getOptions();
		},
	};
</script>
