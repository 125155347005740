const mixin = {
    data: () => ({
        //apiUrl: 'https://mof-server-lj2opgvzfq-ew.a.run.app/api/v1'
        apiUrl:  process.env.VUE_APP_MOFSERVER,
    }),
    methods: {
        //* Get Redis database information
        async MIX_redisGetInfo() {
            let t = this;
            try {
                return t.$axios.get(t.apiUrl + '/info')
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not get info", data: null, error: error };
            }
        },
		// * Get Redis EXISTS
		async MIX_redisExists(key) { 
			let t = this;
			try {
				return t.$axios.get(t.apiUrl + `/keyexists?key=${key}`)
					.then(response => {
						return response.data
					})
			} catch (error) {
				return { code: -1, mesage: "Error: could not get info", data: null, error: error };
			} 
		},
        //* Get Redis KEYS
        async MIX_redisKeys(key) {
            let t = this;
            try {
                return t.$axios.get(t.apiUrl + `/keys?key=${key}`)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not get key(s)", data: null, error: error };
            }
        },
        //* Create a Redis HASH
        async MIX_redisCreateHash(key, value) {
            // console.log('redisCreateHash', key, value);
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/createhash?key=${key}`, value)
                    .then(response => {
                        // console.log('reponse', JSON.stringify(response, null, 2))
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not create hash", data: null, error: error };
            }
        },
        //* Create a Redis HASH INDEX
        async MIX_redisCreateHashIndex(index, prefix) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/createhashindex?index=${index}`, prefix)
                    .then(response => {
                        //console.log('response.data ', response.data)
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not create hash index", data: null, error: error };
            }
        },
        //* Read a Redis HASH
        async MIX_redisReadHash(key) {
            let t = this;
            try {
                return t.$axios.get(t.apiUrl + `/readhash?key=${key}`)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not create hash", data: null, error: error };
            }
        },
        //* Update a Redis HASH
        async MIX_redisUpdateHash(key, value) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/updatehash?key=${key}`, value)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not update hash", data: null, error: error };
            }
        },
        //* Update a Hash with a Deleted value (Does not Delete from Redis)
        async MIX_redisDeleteHash(key) {
            let t = this;
            try {
                return t.$axios.get(t.apiUrl + `/deletehash?key=${key}`)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not delete hash", data: null, error: error };
            }
        },
        //* Destroy a Redis HASH (This permenantly deletes the Redis hash)
        async MIX_redisDestroyHash(key) {
            let t = this;
            try {
                return t.$axios.get(t.apiUrl + `/deletehash?key=${key}`)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not destroy hash", data: null, error: error };
            }
        },		
        //* Expire a Redis HASH
        //! Destructive - this will remove the entry after the expiration period!!
        async MIX_redisExpireHash(key, expiry) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/expirehash?key=${key}&expire=${expiry}`)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not set expiry on hash", data: null, error: error };
            }
        },
        //* Create a Redis STRING (Optionally, can pass an expiry value)
        //! Destructive - if you pass an expiry please remember that after the expiration it will delete the entry!!
        async MIX_redisCreateString(key, value, expiry) {
            let t = this;
            const isExpiry = expiry ? t.apiUrl + `/createString?key=${key}&expiry=${expiry}&value=${value}` : t.apiUrl + `/createString?key=${key}&value=${value}`
            console.log('isExpiry', isExpiry)
            try {
                return t.$axios.post(isExpiry)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not create string", data: null, error: error };
            }
        },
        //* Read a Redis STRING
        async MIX_redisReadString(key) {
            let t = this;
            try {
                return t.$axios.get(t.apiUrl + `/readstring?key=${key}`)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not get string", data: null, error: error };
            }
        },
        //* Update a Redis STRING
        async MIX_redisUpdateString(key, value) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/updatestring?key=${key}&value=${value}`)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not update string", data: null, error: error };
            }
        },
        //* Delete a Redis STRING
        async MIX_redisDeleteString(key) {
            let t = this;
            try {
                return t.$axios.get(t.apiUrl + `/deletestring?key=${key}`)
                    .then(response => {
						return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not delete string", data: null, error: error };
            }
        },
		//* Read a Redis Sorted Set
		async MIX_redisReadSortedSet(key, min, max) {
			let t = this;
			try {
				return t.$axios.get(t.apiUrl + `/readset?key=${key}&min=${min}&max=${max}`)
					.then(response => {
						return response.data
					})
			} catch (error) {
				return { code: -1, mesage: "Error: could not read set", data: null, error: error };
			}
		},
		//* Redis Search / Use to return a full list of results from an index e.g products
		async MIX_redisSearch(index, searchQuery, from, to) {
			let t = this;
			try {
				//console.log(`index: ${index}, searchQuery: ${searchQuery}, from: ${from}, to: ${to}`)
				return t.$axios.get(t.apiUrl + `/search/?index=${index}&searchquery=${searchQuery}&from=${from}&size=${to}`)
					.then(response => {
						//return response.data
						return { code: 1, mesage: "Success: in Redis Search", data: response.data, error: null };
					})
			} catch (error) {
				return { code: -1, mesage: "Error in Redis Search", data: null, error: error };
			}
		},	
		//* Redis Ordered Search / Use to return a full list of results from an index e.g products
		async MIX_redisOrderedSearch(index, searchQuery, from, to, sortField) {
			let t = this;
			try {
				//console.log(`index: ${index}, searchQuery: ${searchQuery}, from: ${from}, to: ${to}`)
				return t.$axios.get(t.apiUrl + `/orderedsearch/?index=${index}&searchquery=${searchQuery}&from=${from}&size=${to}&sortfield=${sortField}`)
					.then(response => {
						//return response.data
						return { code: 1, mesage: "Success: in Redis Search", data: response.data, error: null };
					})
			} catch (error) {
				return { code: -1, mesage: "Error in Redis Search", data: null, error: error };
			}
		},        
		//* Redis Aggregate Search / Use to return a paged list of results from an index e.g products
		//MIX_redisAggregateSearch('idx:products', '*', 0, 9, '@id,@productName', '@productName', false );
		async MIX_redisAggregateSearch(index, searchQuery, from, to, fields, sortBy, sortDesc) {
			let t = this;
			try {
				console.log(`index: ${index}, searchQuery: ${searchQuery}, from: ${from}, to: ${to}, fields: ${fields}, sortBy: ${sortBy}, sortDesc: ${sortDesc}`)
				return t.$axios.get(t.apiUrl + `/aggregatesearch/?index=${index}&searchquery=${searchQuery}&from=${from}&size=${to}&fields=${fields}&sortby=${sortBy}&sortdesc=${sortDesc}`)
					.then(response => {
						return response.data
						//return { code: 1, mesage: "Success: deleted string", data: response.data, error: null };
					})
			} catch (error) {
				return { code: -1, mesage: "Error: could not delete string", data: null, error: error };
			}
		},
		//* Redis HASH Bulk Create/Update
		async MIX_redisBulkHashUpdate(key, data, progressId) {
            console.log('MIX_redisBulkHashUpdate')
			let t = this;
			try {
				//console.log('key = ' + key)
				return t.$axios.post(t.apiUrl + `/bulkhash/?key=${key}&progressid=${progressId}`, data)
					.then(response => {
						return response.data
					})
			} catch (error) {
				return { code: -1, mesage: "Error: could not delete string", data: null, error: error };
			}
		},
		// * Redis SORT Set - Used for Returning a list of sorted results from a set only works with sets, sorted sets 
		async MIX_redisReadSet(key, sortDesc) {
			let t = this;
			try {
				return t.$axios.get(t.apiUrl + `/sortset/?key=${key}&sort=ALPHA&sortDesc=${sortDesc}}`)
					.then(response => {
						return response.data
					})
			} catch (error) {
				return { code: -1, mesage: "Error: could not SORT set", data: null, error: error };
			}	
		},
		// * Redis Add to Set
		async MIX_redisAddToSet(key, value) {
			let t = this;
			try {
				console.log('MIX_redisAddToSet='. value)
				return t.$axios.get(t.apiUrl + `/addtoset/?key=${key}&value=${value}`)
					.then(response => {
						return response.data
					})
			} catch (error) {
				return { code: -1, mesage: "Error: could not add to set", data: null, error: error };
			}	
		},
		// * Redis Increment and Read String
		async MIX_redisIncrementReadString(key) {
			let t = this;
			try {
				return t.$axios.get(t.apiUrl + `/incrementreadstring/?key=${key}`)
					.then(response => {
						return response.data
					})
			} catch (error) {
				return { code: -1, mesage: "Error: could not add to set", data: null, error: error };
			}	
		}		
	}

}

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};