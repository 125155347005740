<template>
    <div>
        <!-- Menu Buttons -->
         <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="mx-4 d-flex align-center" style="width: 100% !important;">
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="saveItem"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                    <v-spacer />
                    <v-btn text class="grey lighten-2 secondary--text" v-if="editmode" @click="cancelItem"><v-icon class="mr-2">icons8-multiply</v-icon>Cancel</v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="apptext--text pt-4 mx-3 fill-height" dense>
            <v-col cols="12" class="grey lighten-5 rounded pa-3 d-flex" v-if="!editmode" style="height: calc(100vh - 275px) !important; overflow-y: hidden !important">
                <div v-html="item.notes" class="ql-editor" style="width:100% !important; height: 100% !important; overflow-y: hidden !important;"></div>
            </v-col>
            <v-col cols="12" class="d-flex align-start justify-start" v-if="editmode">
                <VueEditor class="white" v-model="item.notes" :editorToolbar="customToolbar" style="width: 100% !important; height: calc(100vh - 275px) !important; overflow-y: scroll !important" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
export default {
    // * COMPONENTS
    components: {
        VueEditor
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    // * DATA
    data: () => ({
        editmode: false,
        item: {},
        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'align': ''},{ 'align': 'center'},{ 'align': 'justify'}, { 'align': 'right' }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],  
            [{ 'color': [] }, { 'background': [] }],
            ['link'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
        ],
    }),
    // * METHODS
    methods: {
        cancelItem() {
            this.editmode = false;
            this.$emit('cancel');
        },
        // Save Item
        saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
            },
        },
    },
    // * CREATED
    created() {
        let t = this;
        t.item = { ...t.datadown };
    },
};
</script>

<style>
    .quillWrapper .ql-toolbar { position: -webkit-sticky; position: sticky; top: 0; z-index: 2; background-color: #fff; }
</style>