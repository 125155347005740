<template>
    <div>
        <v-row class="pa-0 ma-0" fill-height>
            <v-row no-gutters class="pb-3 px-3 pt-3 secondary">
                <v-col class="font-weight-bold white--text text-h6 d-flex align-center">
                    <div class="mx-3"><v-icon class="white--text" :large="!GET_dense">icons8-verified-account</v-icon></div>
                    <div>
                        <v-tooltip top :max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="tooltiptext tooltip font-weight-light"><strong>User: </strong>{{ activeitem.userName }}</span>
                            </template>
                        </v-tooltip>
                    </div>
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn class="ml-2 white--text" plain :large="!GET_dense" @click="$emit('closeItem')" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>

            <v-col class="pa-0" fill-height :cols="viewCols.cols" :xs="viewCols.xs" :sm="viewCols.sm" :md="viewCols.md" :lg="viewCols.lg" :xl="viewCols.xl">
                <v-tabs background-color="white" v-model="tab" show-arrows>
                    <v-tab href="#details">Details</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" fill-height class="grey lighten-3">
                    <v-row class="apptext--text mt-0" dense>
                        <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                            <div class="ml-3">
                                <v-btn text class="grey lighten-5 secondary--text" v-if="!editMode" @click="editMode = true" :large="!dense"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                                <v-btn text class="accent secondary--text" v-if="editMode" @click="updateUser()" :large="!dense"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-tab-item value="details">
                        <v-row class="pt-6 d-flex justify-center" no-gutters>
                            <v-avatar v-if="activeitem.photoURL === ''" color="text-h5 primary white--text font-weight-bold" size="150">{{ activeitem.userName.match(/\b(\w)/g).join('') }}</v-avatar>
                            <v-avatar v-else size="150"><img :src="activeitem.photoURL" /></v-avatar>
                        </v-row>
                        <v-row class="pa-3" no-gutters>
                            <v-col cols="12" sm="12" class="pa-3">
                                <div class="text-left primary--text text-h6 font-weight-light">User Details</div>
                            </v-col>
                            <v-col cols="12" sm="12" class="white pa-3">
                                <v-simple-table :dense="GET_dense">
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" class="font-weight-bold">Name</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editMode">{{ activeitem.userName }}</div>
                                                    <div v-else>
                                                        <v-text-field
                                                            v-model="activeitem.userName"
                                                            outlined
                                                            :dense="GET_dense"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            color="primary"
                                                            clearable
                                                        ></v-text-field>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" class="font-weight-bold">Email</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editMode">{{ activeitem.userEmail }}</div>
                                                    <div v-else>
                                                        <v-text-field
                                                            v-model="activeitem.userEmail"
                                                            outlined
                                                            :dense="GET_dense"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            color="primary"
                                                            clearable
                                                        ></v-text-field>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" class="font-weight-bold">User Level</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editMode">{{ activeitem.userLevel }}</div>
                                                    <div>
                                                        <v-select v-if="editMode" :items="userLevelItems" v-model="activeitem.userLevel" label="User Level" outlined></v-select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" class="font-weight-bold">User Group</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editMode">{{ activeitem.userGroup }}</div>
                                                    <div>
                                                        <v-select v-if="editMode" :items="userGroupItems" v-model="activeitem.userGroup" label="User Level" outlined></v-select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" class="font-weight-bold">User Status</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editMode">{{ activeitem.userStatus }}</div>
                                                    <div>
                                                        <v-select v-if="editMode" :items="userStatusItems" v-model="activeitem.userStatus" label="User Status" outlined></v-select>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row class="pa-3" no-gutters v-if="activeitem.id === GET_FIREBASE_userAuth.multiFactor.user.uid">
                            <v-col cols="12" sm="12" class="pa-3">
                                <div class="text-left primary--text text-h6 font-weight-light">Upload Profile Photo</div>
                            </v-col>
                            <v-col cols="12" sm="12" class="white pa-3">
                                <FileUpload
                                    accept="image/jpeg, image/jpg"
                                    label="Select file"
                                    buttonClass="primary"
                                    progressClass="pa-2"
                                    folderLocation="profile"
                                    :fileName="form.id"
                                    :compressionMaxSizeMB="1"
                                    :compressionmaxWidthOrHeight="1920"
                                    :selectedItem="activeitem"
                                    :private="false"
                                    v-on:update="updateUsers()"
                                />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        activeitem: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data: () => ({
        collectionName: 'users',
        dense: false,
        editMode: false,
        file: null,
        form: {
            id: '',
            userName: '',
            userEmail: '',
            userTelephone: '',
            userStatus: '',
            privacy: false,
            privacyPolicyConsent: true,
            createdDateTime: '',
            createdUserId: '',
            createdUserName: '',
            createdUserEmail: '',
            modifiedDateTime: '',
            modifiedUserId: '',
            modifiedUserName: '',
            modifiedUserEmail: '',
            deleted: false,
        },
        loader: null,
        loading: false,
        userLevelItems: ['Developer-Admin', 'Staff-Directors', 'Staff-Admin', 'Staff-Manager', 'Staff-User', 'Customer-User', 'Supplier-User', 'Guest-User'],
        userStatusItems: ['Approved', 'Pending', 'Deleted'],
        userGroupItems: ['Guests', 'Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers', 'Customers', 'Suppliers'],
        show: false,
        tab: 'details',
        uploadRules: [(value) => !value || value.size < 20000000 || 'Image size should be less than 20 MB!'],
        value: 0,
    }),
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
        }),
        viewCols() {
            return {
                cols: 12,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            };
        },
    },
    watch: {
        loader() {
            const l = this.loader;
            this[l] = !this[l];
            setTimeout(() => (this[l] = false), 10000);
            this.loader = null;
        },
    },
    methods: {
        async openDocument() {
            console.log('openDocument');
            let documentReadResult = await this.MIX_firestore_read(this.activeitem.id, this.collectionName);
            console.log('documentReadResult = ' + JSON.stringify(documentReadResult));
            if (documentReadResult.code === 1) {
                console.log(JSON.stringify(documentReadResult, null, 2));
                this.form = documentReadResult.data;
            } else {
                this.MIX_alertBox({ color: 'error', message: 'Error opening items', timeout: 4000, show: true });
            }
        },
        async updateUser() {
            let documentReadResult = await this.MIX_firestore_update(this.activeitem.id, this.collectionName, this.activeitem);
            if (documentReadResult.code === 1) {
                console.log(JSON.stringify(documentReadResult, null, 2));
                this.form = documentReadResult.data;
                this.editMode = false;
                this.$emit('updateUsers');
                this.$emit('closeItem');
            } else {
                this.MIX_alertBox({ color: 'error', message: 'Error updating items', timeout: 4000, show: true });
                this.editMode = false;
            }
        },
        updateUsers() {
            this.$emit('updateUsers');
            this.$emit('closeItem');
        },
    },
    mounted() {
        this.openDocument();
    },
};
</script>
