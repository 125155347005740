<template>
    <div>
        <v-row class="pa-0 ma-0" fill-height>
            <v-row no-gutters class="py-3 px-3">
                <v-col class="font-weight-bold primary--text text-h6 d-flex align-center">
                    <div class="mx-3"><v-icon>icons8-sewing-patch</v-icon></div>
                    <div class="text-h7" v-if="item.optionName">Option: {{ item.optionName }}</div>
                    <div class="text-h7" v-else>New Option</div>
                    <v-spacer></v-spacer>
                    <!-- <div class="ml-4" v-if="!itemNew && GET_destroy"><v-icon class="error--text pa-1" style="border: 1px red solid; border-radius: 3px" @click="destroyItem()">icons8-delete-trash</v-icon></div>
                    <div class="ml-4" v-if="item.deleted === 'false'"><v-icon class="grey--text text--darken-2" @click="deleteItem()">icons8-trash-can</v-icon></div>
                    <div class="ml-4" v-if="item.deleted === 'true'"><v-icon class="grey--text text--darken-2" @click="restoreItem()">icons8-trash-restore</v-icon></div> -->
                    <div>
                        <v-btn class="ml-2 primary--text" plain :small="GET_dense" @click="$emit('closeItem')" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-col class="pa-0" fill-height :cols="viewCols.cols" :xs="viewCols.xs" :sm="viewCols.sm" :md="viewCols.md" :lg="viewCols.lg" :xl="viewCols.xl">
                <v-tabs background-color="white" v-model="tab" show-arrows>
                    <v-tab href="#details">Details</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" fill-height>
                    <!-- Option -->
                    <v-tab-item value="details" class="grey lighten-3">
                        <!-- Menu Buttons -->
                        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
                            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                                <div class="ml-3 d-flex align-center" v-if="loading">
                                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                                </div>
                                <div class="ml-3">
                                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" v-if="!loading" depressed  @click="$emit('closeItem')"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                                </div>
                                <v-spacer />
                                <!-- <div class="mr-3" v-if="!loading">
									<v-btn text class="error white--text" v-if="!editmode" @click="destroyItem()"><v-icon class="mr-2">icons8-trash-can</v-icon>Destroy</v-btn>
                                </div> -->
								<div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                                    <v-btn text class="accent secondary--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- Option Details -->


                        <!-- Option Form -->
                        <v-row class="pa-3 mt-3" no-gutters v-if="!loading">
                            <v-col cols="12" sm="12" class="grey lighten-5 roundedtop mt-2 py-3">
                                <v-simple-table dense class="apptext--text transparent">
                                    <template v-slot:default>
                                        <tbody>
                                           <tr>
                                                <td width="50%"><strong>Organisation</strong><br />Organisation</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editmode">{{ item.orgName }}</div>
                                                    <div v-else>
                                                        <v-autocomplete
                                                            v-if="editmode"
                                                            :items="orgs"
                                                            item-text="orgName"
                                                            return-object
                                                            label="Supplier Name"
                                                            v-model="selectedOrg"
                                                            auto-select-first
                                                            @change="
                                                                item.orgName = selectedOrg.orgName;
                                                                item.orgId = selectedOrg.orgId;
                                                            "
                                                            @click:clear="clearSelectedOrg()"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                            background-color="white"
                                                            color="primary"
                                                            clearable
                                                        ></v-autocomplete>
                                                    </div>
                                                </td>
                                            </tr>         
                                            <!-- <tr>
                                                <td width="50%"><strong>Organisation ID</strong><br />Organisation ID</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editmode">{{ item.orgId }}</div>
                                                    <div v-else>
                                                        <v-text-field label="Supplier ID" v-model="item.orgId" outlined dense hide-details="auto" background-color="white" color="primary" clearable disabled></v-text-field>
                                                    </div>
                                                </td>
                                            </tr> -->

                                            <tr>
                                                <td width="50%"><strong>Name</strong><br />The name used to label the option.</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editmode">{{ item.optionName }}</div>
                                                    <div v-else><v-text-field v-model="item.optionName" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%"><strong>Type</strong><br />The type of option, e.g Colour, Fabric</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editmode">{{ item.optionType }}</div>
                                                    <div v-else><v-text-field v-model="item.optionType" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
                                                    <!-- <div v-else><v-select v-model="item.optionType" :items="optionTypes" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-select></div> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%"><strong>Variant</strong><br />A Variant Option allows the option to be used in the creation of variants.</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editmode">
                                                        <span v-if="item.optionVariant == '1'">Yes</span>
                                                        <span v-if="item.optionVariant == '0'">No</span>
                                                    </div>
                                                    <div v-else>
                                                        <v-radio-group v-model="item.optionVariant" row>
                                                            <v-radio label="No" value="0"></v-radio>
                                                            <v-radio label="Yes" value="1"></v-radio>
                                                        </v-radio-group>
                                                        <!-- <v-radio v-model="" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-radio>-->
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%"><strong>Enabled</strong><br />Use this to enable or disable the option from appearing.</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editmode">
                                                        <span v-if="item.enabled == '1'">Yes</span>
                                                        <span v-if="item.enabled == '0'">No</span>
                                                    </div>
                                                    <div v-else class="pb-3"><v-switch v-model="item.enabled" inset outlined dense hide-details="auto" background-color="#fafafa" color="primary" true-value="1" false-value="0" :value="item.enabled"></v-switch></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%"><strong>ID</strong><br />This field is readonly</td>
                                                <td width="50%" class="py-2">
                                                    <div v-if="!editmode">{{ item.id }}</div>
                                                    <div v-else><v-text-field v-model="itemId" outlined dense hide-details="auto" disabled background-color="white" color="primary" clearable></v-text-field></div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import schema from '@/schema.js';
export default {
    // * COMPONENTS
    components: {},
    // * PROPS
    props: {
        activeitem: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    // * DATA
    data: () => ({
        loading: false,
        addImageToggle: false,
        addPricingToggle: true,
        editmode: false,
		orgs: [],
		itemId: '',
        selectedOrg: {
            orgName: '',
            orgId: '',
        },
        tab: 'details',
        itemNew: false,
        key: 'option',
        schema: null,
        // Active Item
        item: {},
        // Price Bands
        priceBands: ['A', 'B', 'C', 'D', 'E', 'F'],
        optionTypes: ['Colour', 'Fabric', 'Dimensions', 'Edge', 'Lever', 'Arms'], // TODO - Need to replace with
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
            GET_destroy: 'GET_destroy',
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
        }),
        viewCols() {
            return {
                cols: 12,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            };
        },
    },
    // * METHODS
    methods: {
        // * INITIALISE ITEM
        initialiseItem() {
            let t = this;
            if (JSON.stringify(t.activeitem) !== '{}') {
                this.readItem();
                t.editmode = false;
            } else {
                t.itemNew = true;
                t.editmode = true;
				t.clearSelectedOrg();
                t.item = { ...t.schema };
                t.item.key = 'option';
            }
        },
		saveItem() {
			if (this.itemNew) {
				this.createItem();
			} else {
				this.updateItem();
			}
		},
        // * CREATE ITEM
        async createItem() {
            // this.item.id = this.item.optionName.replace(/\s/g, '');
			this.item.id = this.itemId;
            let result = await this.MIX_createItem(this.item, this.key, this.itemNew);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                this.initialiseItem();
            }
        },
        // * READ ITEM
        async readItem() {
            let t = this;
            let result = await t.MIX_readItem(t.activeitem.id, t.key);
            if (result.code === 1) {
                this.item = result.data;
                t.editmode = false;
                t.itemNew = false;
				t.selectedOrg = {
					orgId: t.item.orgId,
					orgName: t.item.orgName
				}
				
            }
        },
		// * UPDATE ITEM
        async updateItem() {
            // this.item.id = this.item.orgId + "_" + this.item.optionName.replace(/\s/g, '');
			this.item.id = this.itemId;
            let result = await this.MIX_createItem(this.item, this.key, false);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                this.initialiseItem();
            }
        },		
        // * DESTROY ITEM
        async destroyItem() {
            let result = await this.MIX_destroyItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem();
            }
        },
        // * DELETE ITEM
        async deleteItem() {
            let result = await this.MIX_deleteItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem();
            }
        },
        // * RESTORE ITEM
        async restoreItem() {
            let result = await this.MIX_restoreItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem();
            }
        },
		clearSelectedOrg() {
			let t = this;
            setTimeout(() => {
                t.selectedOrg.orgId = '';
				t.selectedOrg.orgName = '';
				t.item.orgId = '';
				t.item.orgName = '';
            }, 1);
		}
    },
    // * WATCH
    watch: {
        activeitem() {
            this.initialiseItem();
        },
		item: {
			handler() {
				this.itemId = this.item.orgId + "_" + this.item.optionName.replace(/\s/g, '');
			}, deep: true
		}
    },
    // * CREATED
    async created() {
        this.loading = true;
        this.schema = schema.option;
		let orgs = await this.MIX_organisations('Supplier');
		this.orgs = orgs.map(x => {
			return {
				orgId: x.id,
				orgName: x.orgName
			}
		});
        this.initialiseItem();
        this.loading = false;
    },
};
</script>
