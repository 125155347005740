<template>
    <div style="height: calc(100vh - 150px) !important; overflow: scroll !important">
        <!-- Menu Buttons -->
        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px;">
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <div class="ml-3">
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="editmode = false"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>                
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="saveItem()" :large="!dense"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="ma-3 pt-3 rounded apptext--text">
            <!-- Product Categories -->
            <v-col v-if="!editmode" cols="12" sm="12" class="pb-5 d-flex align-center grey lighten-5">
                <v-simple-table dense class="white" style="width: 100% !important">
                    <template v-slot:default>
                        <thead>
                            <tr class="font-weight-bold">
                                <td>Category</td>
                                <td>Subcategory</td>
                                <td>Item Type</td>
                                <td>Range</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ item.productCategory }}</td>
                                <td>{{ item.productSubcategory }}</td>
                                <td>{{ item.productItemType }}</td>
                                <td>{{ item.productRange }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <!-- TODO - RG - Add PReview for Product Categories -->
            </v-col>
            <v-col cols="12" sm="12" class="d-flex align-center grey lighten-5" v-if="!editmode">
                <div class="text-left primary--text text-h6 font-weight-light">Tags</div>
            </v-col>
            <v-col cols="12" sm="12" class="d-flex align-center px-5 grey lighten-5" v-if="!editmode && item.productTags !== undefined">
                <v-chip color="grey lighten-3" label class="mr-1" v-for="tag in item.productTags.split(',')" :key="tag">{{ tag }}</v-chip>
            </v-col>

            <v-col v-if="editmode" cols="12" sm="12" class="pa-0 pb-5">
                <v-row dense class="">
                    <v-col cols="12" sm="12" class="d-flex align-end">
                        <div class="mr-2">Fit Type:</div>
                        <v-radio-group v-model="item.productFitType" row hide-details="none">
                            <v-radio label="Loose" value="L"></v-radio>
                            <v-radio label="Fitted" value="F"></v-radio>
                        </v-radio-group>
                        <v-spacer />
                        <div class="ml-2"><v-checkbox label="Made to Order" v-model="item.productMTO" outlined hide-details="none" class="pa-0 ma-0" color="primary" true-value="1" false-value="0" /></div>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-select multiple label="Channel" v-model="selectedProductChannel" :items="channels" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteChannel')"> </v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-autocomplete :items="methodProductCategories()" label="Category" v-model="item.productCategory" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productCategory')" :attach="true"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-autocomplete :disabled="item.productCategory === ''" :items="methodProductSubcategories(item.productCategory)" label="Subcategory" v-model="item.productSubcategory" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productSubcategory')" :attach="true"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-autocomplete :disabled="item.productCategory === ''" :items="methodProductItemTypes(item.productCategory)" label="Item Type" v-model="item.productItemType" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productItemType')" :attach="true"></v-autocomplete>
                    </v-col>
                    <!-- <v-col cols="12" sm="3" class="d-flex align-center">
                        <v-btn depressed class="secondary" block @click="categoryDialog = !categoryDialog">Edit Categories</v-btn>
                    </v-col> -->
                    <v-col cols="12" sm="12" class="d-flex align-center">
                        <div class="flex-grow-1"><v-text-field label="MOF Web Link" v-model="item.productMOFWebLink" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productMOFWebLink')" /></div>
                        <div class="ml-2"><v-btn depressed class="secondary" @click="MIX_goExternal(item.productMOFWebLink)">Test</v-btn></div>
                    </v-col>
                    <v-col cols="12" sm="12" class="d-flex align-center">
                         <div class="flex-grow-1"><v-text-field label="Supplier Web Link" v-model="item.productSupplierWebLink" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productSupplierWebLink')" /></div>
                        <div class="ml-2"><v-btn depressed class="secondary" @click="MIX_goExternal(item.productSupplierWebLink)">Test</v-btn></div>
                    </v-col>
                </v-row>
                <v-row class="" dense>
                    <v-col cols="12" sm="6">
                        <v-text-field label="Range" v-model="item.productRange" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productRange')"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field label="Recyclability" v-model="item.productRecyclability" suffix="%" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productRecyclability')"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field label="Recycled Content" v-model="item.productRecycledContent" suffix="%" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('productRecyclability')"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" class="d-flex align-center">
                        <div class="text-left primary--text text-h6 font-weight-light mb-2">Tags</div>
                    </v-col>
                    <v-col cols="12" sm="12" class="d-flex align-center">
                        <v-combobox multiple small-chips hide-selected label="Tags" v-model="selectedTags" :search-input.sync="tagAutocomplete" @change="tagAutocomplete = ''" :items="productTags" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteChannel')">
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                    <span class="pr-2">
                                        {{ formatTag(item) }}
                                    </span>
                                    <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                </v-chip>
                            </template>
                            <template v-slot:no-data>
                                <v-list-item>
                                    <span class="subheading">Create</span>
                                    <v-chip color="grey lighten-3" label small>
                                        {{ formatTag(tagAutocomplete) }}
                                    </v-chip>
                                </v-list-item>
                            </template>
                            <!-- TODO - RG - Items not appearing -->
                            <!-- <template v-slot:item="{ item }">
								<v-chip color="grey lighten-3 grey--text text--darken-4" dark label small>
									{{ item }}
								</v-chip>
							</template> -->
                        </v-combobox>
                    </v-col>
                </v-row>
            </v-col>

            <!-- <v-col><pre>{{ item.productTags }}</pre></v-col> -->
        </v-row>


        <v-dialog v-model="categoryDialog" width="1024">
            <v-card class="mx-auto rounded">
                <!-- Preview Tab -->
                <categoriesDialog v-on:closeitem="closeItem" :key="categoryDialogKey" />
                <!-- // TODO NEW PRODUCTS NOT ADDING -->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import pgsr from "@/import/pgsr.json";
import categoriesDialog from "@/views/products/categories.vue";
export default {
    components: {
        'categoriesDialog': categoriesDialog,
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        loading: false,
        editmode: false,
        // item
        item: {},
        // Autocomplete
        tagAutocomplete: '',
        // tags
        productTags: [],
        selectedTags: [],
        productFitTypes: ['L', 'F'],
        channels: [],
        // TODO - RG - MOVE PGSR into REDIS
        pgsr: {},
        selectedProductChannel: [],
        categoryDialog: false,
        categoryDialogKey: 0,
        
    }),
    // * METHODS
    methods: {
        methodProductCategories: function () {
            return this.pgsr.map((category) => category.name);
        },
        methodProductSubcategories: function (categoryName) {
            let category = this.pgsr.find((category) => category.name === categoryName);
			let subcategories = category?.productSubcategory;
            return subcategories;
        },
        methodProductItemTypes: function (categoryName) {
            let category = this.pgsr.find((category) => category.name === categoryName);
			let itemTypes = category?.itemTypes;
            return itemTypes;
        },
        // Clear Selected
        // clearSelectedOrganisation() {
        // 	setTimeout(() => {
        // 		this.selectedOrganisation = { id: "", orgName: "" };
        // 	}, 1);
        // },
        // Save Item
        saveItem: function () {
            console.log('this.selectedProductChannel = ', this.selectedProductChannel);
            if (JSON.stringify(this.selectedProductChannel) !== '[]') {
                this.item.productChannel = this.selectedProductChannel.join(',');
            }
            this.$emit('saveitem', this.item);
            this.editmode = false;
        },
        // Clear Item
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        formatTag: function (item) {
            if (item !== null) {
                item = item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
                item = item.replace(/[\W_]+/g, ' ');
                return item; //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            } else {
                return '';
            }
        },
        // * GET ITEMS
        async getPGSR() {
            // console.log('getItems running...');
            let itemsResult = await this.MIX_redisReadString('PGSR');
            // console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2));
            if (itemsResult.code === 1) {
                var items = JSON.parse(itemsResult.data);
                this.pgsr = items['productCategory'];
                // console.log('this.items = ' + JSON.stringify(this.items, null, 2));
            }
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
                if (this.item.productTags !== '') {
                    this.selectedTags = this.item.productTags.split(',');
                }
                if (this.item.productChannel !== '' && this.item.productChannel !== undefined && this.item.productChannel !== null) {
                    this.selectedProductChannel = this.item.productChannel.split(',');
                }      
            },
            deep: true,
        },
        // Selected Tags
        selectedTags: {
            handler: async function () {
                // Force Title Case on Tags
                for (let i = 0; i < this.selectedTags.length; i++) {
                    this.selectedTags[i] = this.selectedTags[i].toLowerCase(); //.replace(/\w\S*/g, function(txt){return txthis.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
                    this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, ' ');
                    if (this.selectedTags[i] === ' ') {
                        this.selectedTags.splice(i, 1);
                    } else {
                        this.selectedTags[i] = this.selectedTags[i].trim();
                    }
                    if (this.productTags.indexOf(this.selectedTags[i]) === -1) {
                        let addToSetResult = await this.MIX_redisAddToSet(`set:productTags`, this.selectedTags[i]);
                        if (addToSetResult.code === -1) {
                            console.log(addToSetResult.message);
                        }
                    }
                }
                // Update Quote Tags and split out array with commas
                this.item.productTags = this.selectedTags.join(',');
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.loading = true;
        t.getPGSR()
        // Get Product Tags
        let productTagsResult = await t.MIX_redisReadSet('set:productTags', false);
        if (productTagsResult.code === 1) {
            t.productTags = productTagsResult.data;
        }
        // Get Channels
        let channelsResult = await t.MIX_redisReadSet('set:channels', true);
        if (channelsResult.code === 1) {
            t.channels = channelsResult.data;
        }
        t.item = { ...t.datadown };
        if (t.item.productTags !== '') {
            t.selectedTags = t.item.productTags.split(',');
        }
        if (t.item.productChannel !== '' && t.item.productChannel !== undefined && t.item.productChannel !== null) {
            t.selectedProductChannel = t.item.productChannel.split(',');
        }      
        t.loading = false;
    },
};
</script>
