// Does the Product have options
// Check for Attribute Additional Costs / Sell
// If Volume - Apply Volume Pricing
// If Banding - Apply Banding Pricing
// Check the Discount Groups
// Check for NCD Pric
// Apply the Margin
// Apply the MCD Discount
//Check Codes and Choices
// Return the Line Item
// Chosen Options and Attributes
const schema = require('@/schema.js');

const calculate = {
	data: () => ({
        lineItem: {}
	}),
	methods: {
        /* eslint-disable */
        // Calculate the Band Pricing
        calculateBandPricing: function(original, item) {
            // console.log('Calculate Band Pricing')
            var fabricBand, cost, sell

            console.log('original = ' + JSON.stringify(original, null, 2))
            console.log('item = ' + JSON.stringify(item, null, 2))
            // Get Fabric Band from Options Selected
            switch(true) {
                case (item?.optionValue1.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue1.split('_')[2];
                    break;
                case (item?.optionValue2.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue2.split('_')[2];
                    break;
                case (item?.optionValue3.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue3.split('_')[2];
                    break;
                case (item?.optionValue4.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue4.split('_')[2];
                    break;
                case (item?.optionValue5.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue5.split('_')[2];
                    break;
                case (item?.optionValue6.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue6.split('_')[2];
                    break;
                case (item?.optionValue7.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue7.split('_')[2];
                    break;
                case (item?.optionValue8.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue8.split('_')[2];
                    break;
                case (item?.optionValue9.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue9.split('_')[2];
                    break;
                case (item?.optionValue10.split('_')[1] === "FabricBand"):
                    fabricBand = item?.optionValue10.split('_')[2];
                    break;
                default:
                    fabricBand = "";
                    break;  
            }
            // Get Cost and Sell Based on Fabric Band
            switch (fabricBand) {
                case "A":
                    cost = Number(original.cBA).toFixed(2).toString();
                    sell = Number(original.sBA).toFixed(2).toString();
                    break;
                case "B":
                    cost = Number(original.cBB).toFixed(2).toString();
                    sell = Number(original.sBB).toFixed(2).toString();
                    break;
                case "C":
                    cost = Number(original.cBC).toFixed(2).toString();
                    sell = Number(original.sBC).toFixed(2).toString();
                    break;
                case "D":
                    cost = Number(original.cBD).toFixed(2).toString();
                    sell = Number(original.sBD).toFixed(2).toString();
                    break;
                case "E":
                    cost = Number(original.cBE).toFixed(2).toString();
                    sell = Number(original.sBE).toFixed(2).toString();
                    break;
                case "F":
                    cost = Number(original.cBF).toFixed(2).toString();
                    sell = Number(original.sBF).toFixed(2).toString();
                    break;
                default:
                    cost = Number(original.cP1).toFixed(2).toString();
                    sell = Number(original.sP1).toFixed(2).toString();
                    break;

            }
            // Return Cost and Sell
            return { cost: cost, sell: sell }
        },
        // Calculate the Volume Pricing
        calculateVolumePricing: function(qty, item) {
            var cost, sell
            // cost switch
            switch (true) {
                // if qty is less than cost qty 1 and cost price 1 is not empty
                case (qty < item.cQ2 && item.cP1 !== ""):
                    cost = Number(item.cP1).toFixed(2); // cost price 1
                    break;
                // if qty is less than cost qty 1 and cost price is empty
                case (qty < item.cQ2 && item.cP1 === ""):
                    cost = 0; // cost is 0
                    break;
                // if qty is >= cost qty 2 and cost price 2 is not empty and qty 3 is empty
                case (qty >= item.cQ2 && item.cP2 !== "" && item.cQ3 === ""):
                    cost = Number(item.cP2).toFixed(2); // cost price 2
                    break;
                // if qty is >= cost qty 2 and cost price 2 is empty and qty 3 is empty
                case (qty >= item.cQ2 && item.cP2 === "" && item.cQ3 === ""):
                    cost = Number(item.cP1).toFixed(2); // cost price 1
                    break;
                // if qty is >= cost qty 2 and price 2 is not empty and qty 3 is not empty
                case (qty >= item.cQ2 && qty < item.cQ3 && item.cP2 !== "" && item.cQ3 !== ""):
                    cost = Number(item.cP2).toFixed(2); // cost price 2
                    break;
                // if qty is >= cost qty 3 and price 3 is not empty
                case (qty >= item.cQ3 && item.cP3 !== ""):
                    cost = Number(item.cP3).toFixed(2); // cost price 3
                    break;
                // if nothing matches return 0
                default:
                    // console.log('No Cost Match')
                    cost = 0;
            }
            // sell switch
            switch (true) {
                // if qty is less than sell qty 1 and sell price 1 is not empty
                case (Number(qty) < Number(item.sQ2) && item.sP1 !== ""):
                    sell = Number(item.sP1).toFixed(2); // sell price 1
                    break;
                // if qty is less than sell qty 1 and sell price is empty
                case (Number(qty) < Number(item.sQ2) && item.sP1 === ""):
                    sell = 0; // sell is 0
                    break;
                // if qty is >= sell qty 2 and sell price 2 is not empty and qty 3 is empty
                case (Number(qty) >= Number(item.sQ2) && item.sP2 !== "" && item.sQ3 === ""):
                    sell = Number(item.sP2).toFixed(2); // sell price 2
                    break;
                // if qty is >= sell qty 2 and sell price 2 is empty and qty 3 is empty
                case (Number(qty) >= Number(item.sQ2) && item.sP2 === "" && item.sQ3 === ""):
                    sell = Number(item.sP1).toFixed(2); // sell price 1
                    break;
                // if qty is >= sell qty 2 and price 2 is not empty and qty 3 is not empty
                case (Number(qty) >= Number(item.sQ2) && qty < item.sQ3 && item.sP2 !== "" && item.sQ3 !== ""):
                    sell = Number(item.sP2).toFixed(2); // sell price 2
                    break;
                // if qty is >= sell qty 3 and price 3 is not empty
                case (Number(qty) >= Number(item.sQ3) && item.sP3 !== ""):
                    sell = Number(item.sP3).toFixed(2); // sell price 3
                    break;
                // if nothing matches return 0
                default:
                    // console.log('No Sell Match')
                    sell = 0;
            }
            // console.log(`qty: ${qty}, cost: ${cost}, sell: ${sell}`)
            return { qty: qty, cost: cost, sell: sell }
        },   
        // Main Calculate Pricing Function
        calculatePricing(original, item, fieldChanged) {
            var cost, sell, qty, margin, discount, nps, rrp, originalCost, originalSell

            console.log("calculatePricing original = " + JSON.stringify(original, null, 2))
            console.log("calculatePricing item = " + JSON.stringify(item, null, 2))

            // Pricing Object
            let pricing = {};
            
            nps = Number(original.NPS).toFixed(2);            
            rrp = Number(original.RRP).toFixed(2);

            // Conditions
            // console.log('item.fieldsChanged = ' + item.fieldsChanged)
            // If any fields have changed then add them to array fieldsChanged for checking later
            var fieldsChanged
            if (item.fieldsChanged !== null && item.fieldsChanged !== undefined && item.fieldsChanged !== '') {
                fieldsChanged = item.fieldsChanged.split(',');
            } else {
                fieldsChanged = [];
            }

            // Whats Changed
            // console.log('fieldChanged = ' +  JSON.stringify(fieldChanged, null, 2))

            // get the Price Type - Simple, Volume, Banding
            let pricingType = original.pricingType;

            // Quantity - changing the quantity of a line item
            // if lineQty field changed then use lineQty as long as its greater than Minimum Order Quantity

            // if lineNPS field changed, don't change the quantity
            // if (fieldsChanged.includes('lineNPS')) {
                console.log('item qty = ' + item.lineQty)
            if (item.lineQty !== '' && item.lineQty !== 'NaN' && item.lineQty !== null && item.lineQty !== undefined) {
                qty = Number(item.lineQty);
            } else {
                qty = 1;
            }
            // } 
            // else {
            //     qty = Number(original.MOQ);
            // }

            if (fieldsChanged.includes('lineQty')) {
               let newQty = Number(item.lineQty).toFixed(0);
               // console.log('new Qty = ' + newQty)
                if (Number(newQty) > Number(original.MOQ)) {
                    qty = Number(newQty);
                } else if(original.MOQ !== '' && original.MOQ !== null && original.MOQ !== undefined){
                    qty = Number(original.MOQ);
                }else{
                    qty = Number(newQty);
                }
            }    
            // console.log('qty = ' + qty)
            // TODO - RG - Stop Going lower than minimum order qty - check this is working

            if (fieldsChanged.includes('lineNPS') && fieldChanged !== "restore" ) {
                pricingType = 'NPS';
            }
            // NOT SURE IF REQUIRED
            //if (item.lineQty === '') { qty = Number(original.MOQ).toFixed(0) } else { qty = Number(item.lineQty).toFixed(0) };
            
            // work out the cost and sell price based on the pricing type and whether or not the base price has been changed
            console.log(`\npricingType = ${pricingType}`)
            switch (pricingType) {
                case 'Simple':
                    // Simple Pricing
                    // console.log('Simple Pricing')
                    originalCost = Number(original.cP1).toFixed(2);
                    originalSell = Number(original.sP1).toFixed(2);
                    break;
                case 'Volume':
                    // Volume Pricing
                    // console.log('Volume Pricing')
                    var volPricing = this.calculateVolumePricing(qty, original);
                    originalCost = Number(volPricing.cost).toFixed(2);
                    originalSell = Number(volPricing.sell).toFixed(2);
                    break;
                case 'Band':
                    // Band Pricing - Band Pricing is Defined by Original Pricing
                    // console.log('Band Pricing')
                    let bandPricing = this.calculateBandPricing(original, item)
                    originalCost = Number(bandPricing.cost).toFixed(2);
                    originalSell = Number(bandPricing.sell).toFixed(2);
                    break;
                case 'NPS':
                    // NPS Pricing
                    // console.log('NPS Pricing')
                    originalCost = Number(original.cP1).toFixed(2);
                    originalSell = Number(original.NPS).toFixed(2);
                    break;                    
            }

            // if the base price has been changed then ignore original pricing ** Must think about different volumes here to come back to it
            // changing the cost price
            if (fieldsChanged.includes('lineCost')) {
                cost = item.lineCost;
            } else {
                cost = originalCost;
            }
            // console.log(`\ncost = ${cost}`);
            
            // changing the sell price
            if (fieldsChanged.includes('lineSell')) {
                sell = item.lineSell;
            } else {
                sell = originalSell;
            }
            // console.log(`\nsell = ${sell}`);
        
            // changing the margin - consider revising
            if (fieldChanged === "lineMarginPercent") {
                let addMargin = (100-Number(item.lineMarginPercent))/100;
                sell = Number(cost / addMargin).toFixed(2);
                margin = (((sell-cost)/sell)*100).toFixed(2);
            };
   
            // TODO - RG - Add in Discount Group
            // adding a discount to a line item - consider revising
            if (item.lineDiscount === '') { 
                discount = 0
            } else { 
                discount = Number(item.lineDiscount).toFixed(2) 
            };            
            if (fieldChanged === "lineDiscount") {
                // Apply Discount
                if (discount > 0) {
                    // If discount work out new Sale Price based on discount
                    sell = (sell - (sell * (discount/100))).toFixed(2) // calculate sell including discount
                    pricing.lineDiscount = discount.toString();
                    // TODO - RG - calculate.js - Main Contractors Discount
                } else {
                    pricing.lineDiscount = "";
                }
            }
   
            // restoring the price to the original price
            if (fieldChanged === "restore") {
                nps = Number(original.NPS).toFixed(2);
                rrp = Number(original.RRP).toFixed(2);
                // qty = Number(original.MOQ).toFixed(0)
                cost = originalCost;
                sell = originalSell;
                margin = (((sell-cost)/sell)*100).toFixed(2);
                discount = 0;
            }          

            if (fieldChanged === 'MCDDiscount') {
                sell = item.lineSellBeforeMCD
            }
            
            // TODO - RG - Add Authorisation Flag - If required
            pricing.lineNPS = Number(nps).toFixed(2).toString();
            pricing.lineRRP =  Number(rrp).toFixed(2).toString();
            pricing.lineRRPBase = Number(original.RRP).toFixed(2).toString();
            pricing.lineCostBase = originalCost.toString();
            pricing.lineSellBase = originalSell.toString();
            pricing.lineCost = cost.toString();
            if (fieldChanged !== 'MCDDiscount') {
                pricing.lineSell = sell.toString();
            }
            // Apply Margin if Changed
            // Qty
            pricing.lineQty = qty.toString();       
            pricing.lineMOQ = Number(original.MOQ).toFixed(0)       
            // Standard Pricing Items (do not change)
            pricing.lineMP =  Number(original.MP).toFixed(2).toString();

            // Line Margin
            pricing.lineMarginValue = (sell - cost).toFixed(2).toString();
            pricing.lineMarginValueBase = (originalSell - originalCost).toFixed(2).toString();
            pricing.lineMarginPercent = (((sell - cost) / sell) * 100).toFixed(2).toString();
            pricing.lineMarginPercentBase = (((originalSell - originalCost) / originalSell) * 100).toFixed(2).toString();

            // Authorisation Group
            // TODO - Check this is working
            let authGroup = '0'
            if (item.lineProductType == 'Custom') { 
                authGroup = '1'
                // console.log('custom auth')
            } 
            if (Number(sell).toFixed(2) !== (originalSell)) { 
                authGroup = '1' 
                // console.log('custom sell')
            }
            if (Number(cost).toFixed(2) !== (originalCost)) { 
                authGroup = '1' 
                // console.log('custom cost')
            }
            if (Number(pricing.lineMarginPercent) < Number(pricing.lineMarginPercentBase)) { 
                authGroup = '1' 
                // console.log('custom lineMarginPercent')
            }
            if (Number(pricing.lineSell) !== Number(pricing.lineSellBase)) { 
                authGroup = '1' 
                // console.log('custom lineSell')
            }
            pricing.lineAuthRequired = authGroup;


            // Totals
            let totalCost = (cost * qty).toFixed(2);
            let totalSell = (sell * qty).toFixed(2);
            console.log('totalSell = ', totalSell)
            console.log('sell = ', sell)
            console.log('qty = ', qty)

            let totalMarginValue = (totalSell - totalCost).toFixed(2);
            let totalMarginPercent = ((totalMarginValue / totalSell) * 100).toFixed(2);
            pricing.lineTotalCost = totalCost.toString();

            if (fieldChanged === 'MCDDiscount') {
                totalSell = (item.lineSell * qty).toFixed(2)
                pricing.lineTotalSell = totalSell.toString();
            } else {
                pricing.lineTotalSell = totalSell.toString();
                console.log('pricing.lineTotalSell = ', pricing.lineTotalSell)
                console.log('totalSell = ', totalSell)
            }

            pricing.lineTotalMarginValue = totalMarginValue.toString();
            pricing.lineTotalMarginPercent = totalMarginPercent.toString();
            pricing.lineTotal = totalSell.toString();
    
            // console.log('pricing = ', JSON.stringify(pricing, null, 2))
            return pricing;
        },
        // The following methods can be called outside this file
        CALC_lineItem: async function(lineItem, item, optionsSelected, quoteId) {      
            let t = this;
            // Check for NPS
            //let quoteDetailResult = await t.MIX_readItem(quoteId, "quote");
            // let quoteNPS = quoteDetailResult.data.quoteNPS;
            console.log('lineItem = ' + JSON.stringify(lineItem, null, 2))
            console.log('item = ' + JSON.stringify(item, null, 2))
            console.log('optionsSelected = ' + JSON.stringify(optionsSelected, null, 2))
            console.log('quoteId = ' + JSON.stringify(quoteId, null, 2))
            if (lineItem === '') { 
                lineItem = { ...this.lineItem } 
                lineItem.id = this.MIX_generateId(); // If new line then generate Id
                lineItem.quoteId = quoteId;
            }
            item.optionValue1 = optionsSelected.optionValue1;
            item.optionValue2 = optionsSelected.optionValue2;
            item.optionValue3 = optionsSelected.optionValue3;
            item.optionValue4 = optionsSelected.optionValue4;
            item.optionValue5 = optionsSelected.optionValue5;
            item.optionValue6 = optionsSelected.optionValue6;
            item.optionValue7 = optionsSelected.optionValue7;
            item.optionValue8 = optionsSelected.optionValue8;
            item.optionValue9 = optionsSelected.optionValue9;
            item.optionValue10 = optionsSelected.optionValue10;

                // console.log('CALC_lineItem 1');
                lineItem.lineDescription = item.customDescription;
                // Check if New Line Item or Existing by Line Id
                lineItem.lineMCDRate = "0";
                lineItem.lineOptional = "0", // This is an optional line item
                lineItem.lineIncludeTotal = "1"; // Include the Line Total in Quote Total 0 / 1
                lineItem.lineMarginReason =  ""; // Reasons for Margin
                lineItem.lineMarginComments = "";  
                let product = {}
                let variant = {}
                // Get the Key Type Product or Variant
                let key = item.key; 
                console.log('key = ' + key);       
                if (key === 'product') {
                    // Get the Product
                    let productResult = await this.MIX_readItem(item.id, 'product');
                    //consolee.log('productResult = ', JSON.stringify(productResult, null, 2));
                    if (productResult.code === 1) { 
                        // Store the Product
                        product = productResult.data; 
                        // Start the Line Items
                        //lineItem.lineDescription = product.productName;
                        lineItem.lineImageId = product.productImageId;  
                        lineItem.lineCode = item.productSupplierCode;
                        lineItem.lineCodeId = product.id; 
                        lineItem.lineParentCode = ""; // no parent for products
                        lineItem.lineParentCodeId = "";
                        // Calculate the Pricing Type
                        let pricing = this.calculatePricing(product, item, "");

                        lineItem = { ...lineItem, ...pricing }
                        // // console.log('pricing = ', JSON.stringify(pricing, null, 2))
                        // Add Supplier Details
                        lineItem.orgId = product.orgId;
                        lineItem.orgName = product.orgName;
                        
                        // TOD is this needed here
                      // Add the Organisation Details to Line Item
                        lineItem.lineEdit = "0";
                        lineItem.lineOrder = "999";
                        lineItem.lineReference = "";
                        lineItem.lineType = "Product";
                        lineItem.lineMOQ = "1";
                        lineItem.SOPType = "Quote";


            
                        // PGSR
                        lineItem.lineChannel = product.productChannel
                        lineItem.lineCategory = product.productCategory
                        lineItem.lineSubcategory = product.productSubcategory
                        lineItem.lineItemType = product.productItemType
                        lineItem.lineRange = product.productRange
                        lineItem.lineFitType = product.productFitType

                        lineItem.lineProductType = product.productType
                        

                        //consolee.log('lineItem = ', JSON.stringify(lineItem, null, 2))


                    
                    }
                } else {
                    // Get the Variant's Parent Product
                    let productResult = await this.MIX_readItem(item.productId, 'product');
                    if (productResult.code === 1) { 
                        product = productResult.data; 
                        lineItem.optionName1 = product.optionName1;
                        lineItem.optionName2 = product.optionName2;
                        lineItem.optionName3 = product.optionName3;
                        lineItem.optionName4 = product.optionName4;
                        lineItem.optionName5 = product.optionName5;
                        lineItem.optionName6 = product.optionName6;
                        lineItem.optionName7 = product.optionName7;
                        lineItem.optionName8 = product.optionName8;
                        lineItem.optionName9 = product.optionName9;
                        lineItem.optionName10 = product.optionName10;
                        // PGSR
                        lineItem.lineChannel = product.productChannel
                        lineItem.lineCategory = product.productCategory
                        lineItem.lineSubcategory = product.productSubcategory
                        lineItem.lineItemType = product.productItemType
                        lineItem.lineRange = product.productRange
                        lineItem.lineFitType = product.productFitType
                        lineItem.lineProductType = product.productType

                        
                    }
                    // // console.log('item.id = ' + item.id);
                    // Get the Variant
                    let variantResult = await this.MIX_readItem(item.id, 'variant');
                    // console.log('variantResult = ' + JSON.stringify(variantResult, null, 2));
                    if (variantResult.code === 1) { 
                        // Store the Variant
                        variant = variantResult.data;
                        // Start the Line Items
                        //lineItem.lineDescription = product.productName;
                        lineItem.lineImageId = variant.variantImageId;                    
                        lineItem.lineCode = variant.variantSupplierCode
                        lineItem.lineCodeId = variant.id; 
                        lineItem.lineParentCode = variant.productCode;
                        lineItem.lineParentCodeId = variant.productId;
                        // Standard Pricing
                        var nps, moq, qty, cost, costBase, sell, sellBase, rrpBase
                        nps = Number(variant.NPS).toFixed(2);
                        moq = Number(variant.MOQ).toFixed(0);
                        qty = Number(variant.MOQ).toFixed(0);
                        cost = Number(variant.cP1).toFixed(2);
                        costBase = Number(variant.cP1).toFixed(2);
                        // if (quoteNPS === "1") {
                        //     variant.pricingType = "NPS";
                        //     sell = Number(variant.NPS).toFixed(2);
                        //     sellBase = Number(variant.NPS).toFixed(2);
                        // } else {
                            sell = Number(variant.sP1).toFixed(2);
                            sellBase = Number(variant.sP1).toFixed(2);    
                        // }
                        rrpBase = Number(variant.RRP).toFixed(2);                        
                        // Pricing Options
                        let pricingItem = {}
                        pricingItem.lineNPS = nps.toString();
                        pricingItem.lineMOQ = moq.toString();;
                        pricingItem.lineQty = qty.toString();;
                        pricingItem.lineCost = cost.toString();
                        pricingItem.lineCostBase = cost.toString();
                        pricingItem.lineSell = sell.toString();
                        pricingItem.lineSellBase = sell.toString();
                        pricingItem.lineRRPBase = rrpBase.toString();
                        pricingItem.lineMarginPercent = ((Number(sell)-Number(cost))/Number(sell))*100;
                        pricingItem.lineMarginPercentBase = ((Number(sell)-Number(cost))/Number(sell))*100;
                        pricingItem.lineMarginValueBase = ((Number(sell)-Number(cost)));
                        pricingItem.optionValue1 = optionsSelected.optionValue1;
                        pricingItem.optionValue2 = optionsSelected.optionValue2;
                        pricingItem.optionValue3 = optionsSelected.optionValue3;
                        pricingItem.optionValue4 = optionsSelected.optionValue4;
                        pricingItem.optionValue5 = optionsSelected.optionValue5;
                        pricingItem.optionValue6 = optionsSelected.optionValue6;
                        pricingItem.optionValue7 = optionsSelected.optionValue7;
                        pricingItem.optionValue8 = optionsSelected.optionValue8;
                        pricingItem.optionValue9 = optionsSelected.optionValue9;
                        pricingItem.optionValue10 = optionsSelected.optionValue10;
                        pricingItem.lineDiscount = 0;
                        // Calculate the Pricing Type
                        let pricing = this.calculatePricing(variant, pricingItem, "");
                        lineItem = { ...lineItem, ...pricing }
                        // Add Supplier Details
                        lineItem.orgId = variant.orgId;
                        lineItem.orgName = variant.orgName;   
                        // // console.log('lineItem = ', JSON.stringify(lineItem, null, 2))
                        lineItem.optionValue1 = optionsSelected.optionValue1;
                        lineItem.optionValue2 = optionsSelected.optionValue2;
                        lineItem.optionValue3 = optionsSelected.optionValue3;
                        lineItem.optionValue4 = optionsSelected.optionValue4;
                        lineItem.optionValue5 = optionsSelected.optionValue5;
                        lineItem.optionValue6 = optionsSelected.optionValue6;
                        lineItem.optionValue7 = optionsSelected.optionValue7;
                        lineItem.optionValue8 = optionsSelected.optionValue8;
                        lineItem.optionValue9 = optionsSelected.optionValue9;
                        lineItem.optionValue10 = optionsSelected.optionValue10;


            
                        // Add the Organisation Details to Line Item
                        lineItem.lineEdit = "0";
                        lineItem.lineOrder = "999";
                        lineItem.lineReference = "";
                        lineItem.lineType = "Product";
                        lineItem.lineMOQ = "",
                        lineItem.SOPType = "Quote";
                        // console.log('calc_lineitem lineItem = ', JSON.stringify(lineItem, null, 2))
                        
                        return lineItem;
        
                    }
                }                  
            // } else {
            //     // // console.log('CALC_lineItem 2');
            //     lineItem.optionValue1 = optionsSelected.optionValue1;
            //     lineItem.optionValue2 = optionsSelected.optionValue2;
            //     lineItem.optionValue3 = optionsSelected.optionValue3;
            //     lineItem.optionValue4 = optionsSelected.optionValue4;
            //     lineItem.optionValue5 = optionsSelected.optionValue5;
            //     lineItem.optionValue6 = optionsSelected.optionValue6;
            //     lineItem.optionValue7 = optionsSelected.optionValue7;
            //     lineItem.optionValue8 = optionsSelected.optionValue8;
            //     lineItem.optionValue9 = optionsSelected.optionValue9;
            //     lineItem.optionValue10 = optionsSelected.optionValue10;                
            // }       

            return lineItem;
        },
        CALC_lineItemUpdate: async function(item, fieldChanged) {      
            let product = {}
            let variant = {}
            let lineItem = item;
            // console.log('item.quoteId' + item.quoteId);
            // Check for NPS
            // let quoteDetailResult = await this.MIX_readItem(item.quoteId, "quote");
            // let quoteNPS = quoteDetailResult.data.quoteNPS;
            // // console.log('quoteNPS = ', quoteNPS)

            if (item.lineParentCodeId !== '') {
                // This is a variant
                let productResult = await this.MIX_readItem(item.lineParentCodeId, 'product');
                if (productResult.code === 1) { 
                    product = productResult.data; 
                }
                let variantResult = await this.MIX_readItem(item.lineCodeId, 'variant');
                if (variantResult.code === 1) { 
                    // Store the Variant
                    variant = variantResult.data; 
                    // if (quoteNPS === "1") {
                    //     variant.pricingType = "NPS";
                    // }
                    let pricing = this.calculatePricing(variant, item, fieldChanged);
                    lineItem = { ...lineItem, ...pricing }
                }
            } else {
                let productResult = await this.MIX_readItem(item.lineCodeId, 'product');
                if (productResult.code === 1) { 
                    product = productResult.data; 
                    // if (quoteNPS === "1") {
                    //     product.pricingType = "NPS";
                    // }
                    let pricing = this.calculatePricing(product, item, fieldChanged);
                    // console.log('pricing = ', JSON.stringify(pricing, null, 2))
                    lineItem = { ...lineItem, ...pricing }
                }

                // This is a product
            }
            return lineItem
        },
        // CALC_createLineItem - used for Custom Product
        CALC_createLineItem: async function(item, qty) {
            let t = this;
            if ((qty === undefined) || (qty === null) || (qty === '')) {
                qty = Number(item.MOQ);
            }
            // console.log('creating line item = ' + JSON.stringify(item, null, 2))
            // console.log('qty = ', qty)
            let lineItem = schema.lineItem;
            lineItem.lineCode = item.productSupplierCode;
            lineItem.lineCodeId = item.id;
            // lineParentCode Not Required
            // lineParentCodeId Not Required
            // Quote Details
            // lineItem.quoteId = t.quoteId;
            // Supplier Details
            lineItem.orgId = item.orgId;
            lineItem.orgName = item.orgName;
            // Line Details
            lineItem.lineImageId = item.productImageId;
            lineItem.lineDescription = item.productName;
            lineItem.lineEdit = '0';
            lineItem.lineExtend = '0';
            lineItem.lineOrder = '999';
            lineItem.lineReference = '',
            lineItem.lineProductType = item.productType;
            lineItem.lineType = 'Product',
            lineItem.lineSOPType = 'Quote';
            lineItem.lineQty = qty.toString();
            lineItem.lineMOQ = Number(item.MOQ).toString();
            lineItem.lineCost = Number(item.cP1).toFixed(2).toString();
            lineItem.lineCostBase = Number(item.cP1).toFixed(2).toString();
            lineItem.lineSell = Number(item.sP1).toFixed(2).toString();
            lineItem.lineSellBase = Number(item.sP1).toFixed(2).toString();
            lineItem.lineMarginPercent = (((Number(item.sP1) - Number(item.cP1)) / Number(item.sP1)) * 100).toFixed(2).toString();
            lineItem.lineMarginValue = (Number(item.sP1) - Number(item.cP1)).toFixed(2).toString();
            lineItem.lineRRP = Number(item.RRP).toFixed(2).toString();
            lineItem.lineRRPBase = Number(item.RRP).toFixed(2).toString();
            lineItem.lineMP = Number(item.MP).toFixed(2).toString();
            lineItem.lineNPS = Number(item.NPS).toFixed(2).toString();
            lineItem.lineNPSEnabled = item.NPSEnabled;
            lineItem.lineTotalCost = (Number(item.cP1) * Number(qty)).toFixed(2).toString();
            lineItem.lineTotalSell = (Number(item.sP1) * Number(qty)).toFixed(2).toString();
            console.log('lineItem.lineTotalSell = ', lineItem.lineTotalSell)
            console.log('item.sP1 = ', item.sP1)
            console.log('qty = ', qty)

            lineItem.lineTotalMarginPercent = (((Number(item.sP1) - Number(item.cP1)) / Number(item.sP1)) * 100).toFixed(2).toString();
            lineItem.lineTotalMarginValue = ((Number(item.sP1) - Number(item.cP1)) * Number(qty)).toFixed(2).toString();
            lineItem.lineDiscount = '0';
            lineItem.lineMCDRate = '0';
            lineItem.lineTotal = (Number(item.sP1) * Number(qty)).toFixed(2).toString();
            lineItem.lineFieldsChanged = "";
            lineItem.lineOptional = '0';
            lineItem.lineIncludeTotal = '1';
            lineItem.lineMarginReason = '';
            lineItem.lineMarginComments = '';
            lineItem.lineFeatures = "";
            lineItem.lineRoomTag = "";
            lineItem.lineFloorTag = "";
	        lineItem.optionName1 = "";
	        lineItem.optionName2 = "";
	        lineItem.optionName3 = "";
	        lineItem.optionName4 = "";
	        lineItem.optionName5 = "";
	        lineItem.optionName6 = "";
	        lineItem.optionName7 = "";
	        lineItem.optionName8 = "";
	        lineItem.optionName9 = "";
	        lineItem.optionName10 = "";
	        lineItem.optionValue1 = "";
	        lineItem.optionValue2 = "";
	        lineItem.optionValue3 = "";
	        lineItem.optionValue4 = "";
	        lineItem.optionValue5 = "";
	        lineItem.optionValue6 = "";
	        lineItem.optionValue7 = "";
	        lineItem.optionValue8 = "";
	        lineItem.optionValue9 = "";
	        lineItem.optionValue10 = "";

            
            // PGSR
            lineItem.lineChannel = item.productChannel
            lineItem.lineCategory = item.productCategory
            lineItem.lineSubcategory = item.productSubcategory
            lineItem.lineItemType = item.productItemType
            lineItem.lineRange = item.productRange
            lineItem.lineFitType = item.productFitType


            return lineItem
        }
    },
    created() {
        this.lineItem = schema.lineItem;
    }
}


export default {
	install(Vue) {
		Vue.mixin(calculate);
	},
};
