<template>
    <div>
        <v-row class="pa-0 ma-0" fill-height>
            <v-row no-gutters class="py-3 px-3">
                <v-col class="font-weight-bold primary--text text-h6 d-flex align-center">
                    <div class="mx-3"><v-icon>icons8-sorting</v-icon></div>
                    <div class="text-h7" v-if="item.name">{{ item.name }}</div>
                    <div class="text-h7" v-else>New Category</div>
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn class="ml-2 primary--text" plain :small="GET_dense" 
                        @click="
                            $emit('closeItem')
                            clearForm()
                        " depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-col class="pa-0" fill-height :cols="viewCols.cols" :xs="viewCols.xs" :sm="viewCols.sm" :md="viewCols.md" :lg="viewCols.lg" :xl="viewCols.xl">
                <v-tabs background-color="white" v-model="tab" show-arrows>
                    <v-tab href="#details">Details</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" fill-height class="grey lighten-3">
                    <!-- COLOUR DETAILS TAB -->
                    <v-tab-item value="details">
                        <!-- Menu Buttons -->
                        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
                            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                                <div class="ml-3 d-flex align-center" v-if="loading">
                                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                                </div>
                                <div class="ml-3">
                                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="$emit('closeItem')"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                                </div>
                                <v-spacer />
                                <!-- <div class="mr-3" v-if="!loading">
                                    <v-btn text class="error white--text" v-if="!editmode" @click="destroyItem()"><v-icon class="mr-2">icons8-trash-can</v-icon>Destroy</v-btn>
                                </div> -->
                                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                                    <v-btn text class="accent secondary--text" v-if="editmode" @click="validateForm()"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- <pre>{{ item }}</pre> -->
                        <v-row class="pa-3" no-gutters>
                            <v-col cols="12" sm="12" class="mt-3 mb-1">
                                <div class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">
                                        Category Details
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" class="pr-1 pb-2">
                                
                                <div class="pa-5 grey lighten-5 rounded">
                                    <table border="0" width="100%" class="body-2">
                                        <tr>
                                            <td>
                                                <div v-if="!editmode"><strong>Category Name:</strong> {{ item.name }}</div>
                                                <v-text-field v-if="editmode" label="Category Name" v-model="item.name" class="body-2 mb-1" hide-details="auto" outlined dense></v-text-field>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div v-if="!editmode"><strong>Installation:</strong> {{ item.installation }}</div>
                                                <v-text-field v-if="editmode" type="number" label="Installation" v-model="item.installation" class="body-2 mb-1" hide-details="auto" outlined dense></v-text-field>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="6" class="pr-1">
                                
                                <div class="pa-5 grey lighten-5 rounded">
                                    <strong>Subcategories</strong>
                                    <ul>
                                        <li v-for="(subcat, index) in item.productSubcategory" :key="index">
                                            <div v-if="!editmode" class="body-2">{{ subcat }}</div>
                                            <div v-if="editmode" class="d-flex mb-1 align-center">
                                                <v-text-field v-model="item.productSubcategory[index]" class="body-2" hide-details="auto" outlined dense></v-text-field>
                                                <v-btn class="elevation-0 ml-1 grey--text" icon plain @click="removeSubcategory(index)"><v-icon>icons8-multiply</v-icon></v-btn>
                                            </div>
                                        </li>
                                    </ul>
                                    <v-btn v-if="editmode" class="elevation-0 mt-2 primary" block @click="addSubcategory()">Add</v-btn>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="6" class="pl-1">
                                
                                <div class="pa-5 grey lighten-5 rounded">
                                    <strong>Item Types</strong>
                                    <ul>
                                        <li v-for="(type, index) in item.itemTypes" :key="index">
                                            <div v-if="!editmode" class="body-2">{{ type }}</div>
                                            <div v-if="editmode" class="d-flex mb-1 align-center">
                                                <v-text-field v-model="item.itemTypes[index]" class="body-2" hide-details="auto" outlined dense></v-text-field>
                                                <v-btn class="elevation-0 ml-1 grey--text" icon plain @click="removeItemType(index)"><v-icon>icons8-multiply</v-icon></v-btn>
                                            </div>
                                        </li>
                                    </ul>
                                    <v-btn v-if="editmode" class="elevation-0 mt-2 primary" block @click="addItemType()">Add</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import schema from '@/schema.js';
export default {
    // * COMPONENTS
    components: {},
    // * PROPS
    props: {
        activeitem: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    // * DATA
    data: () => ({
        loading: false,
        addImageToggle: false,
        addPricingToggle: true,
        editmode: false,
        tab: 'details',
        itemNew: false,
        key: 'attribute',
		orgs: [],
		selectedOrg: {
            orgName: '',
            orgId: '',
        },
        schema: null,
        options: [],
        optionSelected: {},
        // Validation
        rules: {},
        // Active Item
        item: {},
        // Price Bands
        priceBands: ['A', 'B', 'C', 'D', 'E', 'F'],
        // toggle
        toggleHelp: false,
        toggleParent: false,
        toggleAddCost: false,
        toggleMOQ: false,
        togglePriceBand: false,
        toggleAddSell: false,
        toggleDescription: false
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
            GET_destroy: 'GET_destroy',
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
        }),
        viewCols() {
            return {
                cols: 12,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            };
        },
    },
    // * METHODS
    methods: {  
        // Validate Form
        validateForm() {
            // Validate Form
            this.rules = {
                // organisationName: [(v) => this.validateOrganisation(v) || 'Organisation Name is required'],
                // attName: [(v) => !!v || 'Attribute Name Required'],
                // attType: [(v) => !!v || 'Attribute Type Required'],
                // optionName: [(v) => this.validateSelect(v) || 'Option Name is required'],
            };
            // this.$nextTick(() => {
            //     if (this.$refs.form.validate()) {
                    this.saveItem();
            //     } else {
            //         this.MIX_alertBox({ color: "error", timeout: 4000, message: "Please fill out all the fields", show: true });                    
            //     }
            // // });
            //this.saveItem();
        },
        addSubcategory() {
            this.item.productSubcategory.push('');
        },
        removeSubcategory(index) {
            this.item.productSubcategory.splice(index, 1);
        },
        addItemType() {
            this.item.itemTypes.push('');
        },  
        removeItemType(index) {
            this.item.itemTypes.splice(index, 1);
        },
        // * INITIALISE ITEM
        initialiseItem() {
            let t = this;
            if (t.activeitem.index !== -1) {
                t.item = { ...t.activeitem };
            } else {
                t.itemNew = true;
                t.editmode = true;
                t.item = { ...t.schema };
            }
        },
        saveItem() {
            if (this.itemNew) {
                this.createItem();
            } else {
                this.updateItem();
            }
            this.clearForm()
        },
        clearForm() {
            this.item = { ...this.schema };
            this.itemNew = false;
            this.editmode = false;
        },
        // * CREATE ITEM
        async createItem() {
            this.item.installation = Number(this.item.installation);
            this.$emit('createItem', this.item);
            
        },     
    },
    // * WATCH
    watch: {
        activeitem() {
            this.initialiseItem();
        },  
    },
    // * CREATED
    async created() {
        this.loading = true;        
        this.schema = schema.category;
        this.initialiseItem();
        this.loading = false;     
    },
};
</script>
