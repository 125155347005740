<template>
  <v-dialog v-model="activityDialog" width="500px" style="overflow: hidden !important;" persistent>
    <div class="primary d-flex align-center justify-center pa-3">
      <div class="pl-1 font-weight-medium body-1 white--text" v-if="newAction.actionCompleted === 0">New Action</div>
      <div class="pl-1 font-weight-medium body-1 white--text" v-else-if="newAction.actionCompleted === 1">Log Activity</div>
      <v-spacer />
      <v-btn
          @click="$emit('closeDialog')"
          icon small
          class="elevation-0 white--text"><v-icon>icons8-cancel</v-icon></v-btn>
    </div>
    <v-card class="lightgrey d-flex flex-column ma-0 pa-6" style="overflow: hidden !important; border-top-left-radius: 0 !important; border-top-right-radius: 0 !important;">
      <v-row dense>

        <!-- <pre>{{ newAction }}</pre> -->

        <v-col cols="6" xs="12">
          <v-select
              label="Activity Type *"
              v-model="newAction.actionType"
              :items="actionTypes"
              required
              outlined
              dense
              hide-details="auto"
              background-color="white"
              color="primary"
              clearable
              :menu-props="{ top: false, offsetY: true }"

              @click:clear="clearItem('actionType')">
          </v-select>
        </v-col>
        <v-col cols="6" xs="12">
          <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field label="Activity Date *" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('actionStartDate')"></v-text-field>
            </template>
            <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" xs="12">
          <v-text-field
              label="Activity Title *"
              v-model="newAction.actionSubject"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              color="primary"
              clearable
              @click:clear="clearItem('actionSubject')">
          </v-text-field>
        </v-col>
        <v-col cols="12" xs="12">
          <v-textarea
              label="Activity Description *"
              v-model="newAction.actionDescription"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              color="primary"
              clearable
              @click:clear="clearItem('actionSubject')">
          </v-textarea>
        </v-col>
        <!--          Select User-->
        <v-col cols="12" xs="12">
          <v-select label="Assigned To" v-model="selectedActionOwner" :items="users" outlined item-text="ownerUserName" return-object
                    dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }"
          >
            <template v-slot:selection="{ item }">
              {{ item.ownerUserName }}
              <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
            </template>
            <template v-slot:item="{ item }">
              {{ item.ownerUserName }}
              <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
            </template>
          </v-select>
        </v-col>
        <!--          <v-col cols="12" xs="12">-->
        <!--            <v-select-->
        <!--                label="Priority *"-->
        <!--                v-model="newAction.actionPriority"-->
        <!--                :items="priority"-->
        <!--                required-->
        <!--                outlined-->
        <!--                dense-->
        <!--                hide-details="auto"-->
        <!--                background-color="white"-->
        <!--                color="primary"-->
        <!--                clearable-->
        <!--                :menu-props="{ top: false, offsetY: true }"-->

        <!--                @click:clear="clearItem('actionPriority')">-->
        <!--            </v-select>-->
        <!--          </v-col>-->
        <!--          <v-col cols="12" xs="12" >-->
        <!--            <v-select-->
        <!--                label="Status"-->
        <!--                v-model="newAction.actionStatus"-->
        <!--                :items="status"-->
        <!--                required-->
        <!--                outlined-->
        <!--                dense-->
        <!--                hide-details="auto"-->
        <!--                background-color="white"-->
        <!--                color="primary"-->
        <!--                clearable-->
        <!--                :menu-props="{ top: false, offsetY: true }"-->

        <!--                @click:clear="clearItem('actionStatus')">-->
        <!--            </v-select>-->
        <!--          </v-col>-->
      </v-row>

      <v-row v-if="newAction.actionType === 'Call'" dense>
        <v-col cols="12" xs="12" md="6">
          <v-select label="Call Type *" v-model="newAction.callType" :rules="rules.callType" :items="callType" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }"  @click:clear="clearItem('callType')"></v-select>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-select label="Call Duration *" v-model="newAction.callDuration" :rules="rules.callDuration" :items="minutes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }"  @click:clear="clearItem('callDuration')"></v-select>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-select label="Call Purpose *" v-model="newAction.callPurpose" :rules="rules.callPurpose" :items="callPurpose" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }"  @click:clear="clearItem('callPurpose')"></v-select>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-select label="Call Result *" v-model="newAction.callResult" :rules="rules.callResult" :items="callResult" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }"  @click:clear="clearItem('callResult')"></v-select>
        </v-col>
      </v-row>

      <v-row v-if="newAction.actionType === 'Meeting'" dense>
        <v-col cols="12" xs="12" md="6" >
          <!-- <v-select label="Meeting Host" v-model="newAction.actionMeetingHost" :rules="rules.actionMeetingHost" :items="users" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionMeetingHost')"></v-select> -->

          <!-- <pre>{{ users }}</pre> -->

          <v-autocomplete label="Meeting Host" v-model="selectedHost" :rules="rules.actionEventHost" :items="users" item-text="ownerUserName" auto-select-first return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOwner()">
            <template v-slot:selection="{ item }">
              {{ item.ownerUserName }}
              <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
            </template>
            <template v-slot:item="{ item }">
              {{ item.ownerUserName }}
              <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-select label="Meeting Type" v-model="newAction.actionMeetingType" :rules="rules.actionMeetingType" :items="meetingTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }"  @click:clear="clearItem('actionMeetingType')"></v-select>
        </v-col>
        <v-col cols="12" xs="12">
          <!-- <v-select label="Meeting Participants" v-model="newAction.actionMeetingParticipants" :rules="rules.actionMeetingParticipants" :items="contacts" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionMeetingParticipants')"></v-select> -->

          <v-textarea
              label="Meeting Participants"
              v-model="selectedEventParticipants"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              color="primary"
              clearable>
          </v-textarea>
        </v-col>
        <v-col cols="12" class="d-flex align-center">
          <div class="text-left primary--text text-h6 font-weight-light">Meeting Address</div>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider class="grey lighten-1 mb-1"></v-divider>
        </v-col>
        <v-col cols="12" sm="12" class="pb-0">
          <vuetify-google-autocomplete prepend-icon="icons8-google" autocomplete="donotcomplete" dense outlined background-color="white" label="Meeting Location" name="myapp-name" ref="addressInput" types="" id="map" classname="form-control" v-on:placechanged="getAddressDataMeeting" country="gb" clearable> </vuetify-google-autocomplete>
        </v-col>
        <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
          <v-text-field label="Address Line 1" v-model="newAction.meetingAddressLine1" :rules="rules.meetingAddressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressLine1')"></v-text-field>
        </v-col>
        <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
          <v-text-field label="Address Line 2" v-model="newAction.meetingAddressLine2" :rules="rules.meetingAddressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressLine2')"></v-text-field>
        </v-col>
        <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
          <v-text-field label="Address Line 3" v-model="newAction.meetingAddressLine3" :rules="rules.meetingAddressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressLine3')"></v-text-field>
        </v-col>
        <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
          <v-text-field label="Town" v-model="newAction.meetingAddressTown" :rules="rules.meetingAddressTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressTown')"></v-text-field>
        </v-col>
        <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
          <v-text-field label="County" v-model="newAction.meetingAddressCounty" :rules="rules.meetingAddressCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressCounty')"></v-text-field>
        </v-col>
        <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
          <v-text-field label="Postcode" v-model="newAction.meetingAddressPostcode" :rules="rules.meetingAddressPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressPostcode')"></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="newAction.actionType === 'Event'" dense>
        <v-col cols="12" xs="12">
          <!-- <v-select label="Event Host" v-model="item.actionEventHost" :rules="rules.actionEventHost" :items="users" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionEventHost')"></v-select> -->
          <v-autocomplete label="Event Host" v-model="selectedHost" :rules="rules.actionEventHost" :items="users" item-text="ownerUserName" auto-select-first return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOwner()">
            <template v-slot:selection="{ item }">
              {{ item.ownerUserName }}
              <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
            </template>
            <template v-slot:item="{ item }">
              {{ item.ownerUserName }}
              <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12">
          <v-textarea
              label="Event Participants"
              v-model="selectedEventParticipants"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              color="primary"
              clearable>
          </v-textarea>
          <!--            <v-combobox multiple small-chips hide-selected label="Event Participants" v-model="selectedEventParticipants" :search-input.sync="participantsAutocomplete" @change="participantsAutocomplete = ''" :items="contacts" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" clearable @click:clear="clearItem('quoteChannel')">-->
          <!--              <template v-slot:selection="{ attrs, item, parent, selected }">-->
          <!--                <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>-->
          <!--                                                        <span class="pr-2">-->
          <!--                                                            {{ item }}-->
          <!--                                                        </span>-->
          <!--                  <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>-->
          <!--                </v-chip>-->
          <!--              </template>-->
          <!--              <template v-slot:item="{ item }">-->
          <!--                {{ item.contactUserName }}-->
          <!--                <div class="ml-2 caption grey&#45;&#45;text">{{ item.contactUserEmail }}</div>-->
          <!--              </template>-->
          <!--            </v-combobox>-->
        </v-col>
        <v-col cols="12" xs="12">
          <v-select label="Event Type" v-model="newAction.actionEventType" :rules="rules.actionEventType" :items="eventTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" @click:clear="clearItem('actionEventType')"></v-select>
        </v-col>
        <v-col cols="12" class="d-flex align-center">
          <div class="text-left primary--text text-h6 font-weight-light">Event Address</div>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider class="grey lighten-1 mb-1"></v-divider>
        </v-col>
        <v-col cols="12" sm="12" class="pb-0">
          <vuetify-google-autocomplete  prepend-icon="icons8-google" autocomplete="donotcomplete" dense outlined background-color="white" label="Event Location" name="myapp-name" ref="addressInput" types="" id="map" classname="form-control" v-on:placechanged="getAddressDataEvent" country="gb" clearable> </vuetify-google-autocomplete>
        </v-col>
        <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
          <v-text-field label="Address Line 1" v-model="newAction.eventAddressLine1" :rules="rules.eventAddressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressLine1')"></v-text-field>
        </v-col>
        <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
          <v-text-field label="Address Line 2" v-model="newAction.eventAddressLine2" :rules="rules.eventAddressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressLine2')"></v-text-field>
        </v-col>
        <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
          <v-text-field label="Address Line 3" v-model="newAction.eventAddressLine3" :rules="rules.eventAddressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressLine3')"></v-text-field>
        </v-col>
        <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
          <v-text-field label="Town" v-model="newAction.eventAddressTown" :rules="rules.eventAddressTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressTown')"></v-text-field>
        </v-col>
        <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
          <v-text-field label="County" v-model="newAction.eventAddressCounty" :rules="rules.eventAddressCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressCounty')"></v-text-field>
        </v-col>
        <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
          <v-text-field label="Postcode" v-model="newAction.eventAddressPostcode" :rules="rules.eventAddressPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressPostcode')"></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="newAction.actionType === 'Case'" dense>
        <v-col cols="12" xs="12">
          <v-select label="Case Type" v-model="newAction.actionCaseType" :rules="rules.actionCaseType" :items="caseTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" @click:clear="clearItem('actionCaseType')"></v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" xs="12" md="12" class="d-flex justify-end">
          <!--            <v-btn-->
          <!--                @click="activityDialog = false"-->

          <!--                class="elevation-0 primary mr-3">Close</v-btn>-->
          <v-btn
              @click="saveNewTask()"

              class="elevation-0 primary">Save</v-btn>
        </v-col>
      </v-row>

    </v-card>
  </v-dialog>
</template>
<script>

import moment from "moment/moment";
import schema from "@/schema.js";

export default {
  data() {
    return {
      actions: {},
      key: 'action',
      index: 'idx:actions',
      items: [],
      type: [],
      selectedActionOwner: [],
      newAction: {},
      priority: ['Urgent', 'High', 'Medium', 'Low'],

      addTask: false,
      actionTypes: [],

      date1: moment().format("YYYY-MM-DD"),
      dateMenu1: false,
      date2: moment().add(1, "days").format("YYYY-MM-DD"),
      dateMenu2: false,
      date3: moment().add(1, "days").format("YYYY-MM-DD"),
      dateMenu3: false,
      addingAction: false,
      status: ['Open', 'Pending', 'Closed'],


      callType: ['Inbound', 'Outbound', 'Missed'],
      minutes: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'],
      callPurpose: ['None', 'Prospecting', 'Administrative', 'Negotiotiation', 'Demo', 'Project', 'Desk'],
      callResult: ['None', 'Interested', 'Not Interested', 'No Response', 'Busy', 'Requested More Info', 'Requested Call Back', 'Invalid Number'],

      //meeting dropdowns
      users: [],
      contacts: [],
      meetingTypes: ['In Person', 'Remote', 'Conference Call'],
      eventTypes: ['Tradeshow', 'Exhibition', 'Other'],
      caseTypes: ['Complaint', 'Question', 'Other'],

      selectedEventParticipants: [],
      selectedContacts: [],
      selectedHost: [],
      selectedOrganisation: null,
      fullOrganisationList: [],
      rules: {},
      showEventAddress: false,
      showMeetingAddress: false,


      participantsAutocomplete: '',

      activityFilter: ''
    }
  },
  props:{
    activityDialog: {
      type: Boolean,
      default: false
    },
    orgId: {
      type: String,
      default: ''
    },
  },
  computed:{
    computedDate1() {
      return this.$moment(this.date1, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
    computedDate2() {
      return this.$moment(this.date2, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
    computedDate3() {
      return this.$moment(this.date3, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
  },
  components: {

  },
  methods: {
    async saveNewTask() {
      const t = this;

      const action = t.newAction;

      // // // //console..log("saving action + " + JSON.stringify(action, null, 2))

      let result = await t.MIX_createItem(action, t.key, true);
      if (result.code === 1) {
        // t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
        // t.readItem(item.id);
        t.$emit("refreshItems");
        // t.closeDialog()
        // // // //console..log('saved action')
        t.newAction = []
        t.addTask = false
        t.$emit("closeDialog");
      }

    },
    logActivity() {
      var t = this;

      t.addingAction = true
      // t.panel = ''
      t.newAction = {}
      t.newAction = {... t.schema}
      t.newAction.orgId = t.orgId
      t.newAction.actionCompletedDateTime = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
      t.newAction.actionCompleted = 1
      if (this.newAction.ownerUserId !== '' && this.newAction.ownerUserId !== undefined && this.newAction.ownerUserId !== null) {
        this.selectedActionOwner = {
          ownerUserId: this.datadown.ownerUserId,
          ownerUserName: this.datadown.ownerUserName,
          ownerUserEmail: this.datadown.ownerUserEmail
        };
      } else {
        this.selectedActionOwner = this.MIX_getCurrentOwner();
      }
    },
    getAddressDataMeeting: function (addressData, placeResultData) {
      let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
      // //////console.log(JSON.stringify(placeResult, null, 2));
      this.newAction.meetingAddressName = placeResult.name;
      this.newAction.meetingAddressLine1 = placeResult.addressLine1;
      this.newAction.meetingAddressLine2 = placeResult.addressLine2;
      this.newAction.meetingAddressLine3 = placeResult.addressLine3;
      this.newAction.meetingAddressTown = placeResult.town;
      this.newAction.meetingAddressCounty = placeResult.county;
      this.newAction.meetingAddressPostcode = placeResult.postcode;
      this.newAction.meetingAddressCountry = placeResult.country;
      this.newAction.meetingAddressLat = placeResult.lat;
      this.newAction.meetingAddressLng = placeResult.lng;
      this.showMeetingAddress = true
    },
    getAddressDataEvent: function (addressData, placeResultData) {
      let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
      this.newAction.eventAddressName = placeResult.name;
      this.newAction.eventAddressLine1 = placeResult.addressLine1;
      this.newAction.eventAddressLine2 = placeResult.addressLine2;
      this.newAction.eventAddressLine3 = placeResult.addressLine3;
      this.newAction.eventAddressTown = placeResult.town;
      this.newAction.eventAddressCounty = placeResult.county;
      this.newAction.eventAddressPostcode = placeResult.postcode;
      this.newAction.eventAddressCountry = placeResult.country;
      this.newAction.eventAddressLat = placeResult.lat;
      this.newAction.eventAddressLng = placeResult.lng;
      this.showEventAddress = true
    },
  },
  watch: {
    dateMenu1() {
      this.newAction.actionCompletedDateTime = this.$moment(this.date1, 'YYYY-MM-DD').format('X');

    },
    dateMenu2() {
      this.newAction.actionEndDate = this.$moment(this.date2, 'YYYY-MM-DD').format('X');
      // this.checkDates();
    },
    dateMenu3() {
      this.newAction.actionCloseDate = this.$moment(this.date3, 'YYYY-MM-DD').format('X');
      // this.checkDates();
    },
    selectedActionOwner: {
      handler: function () {
        console.log(this.selectedActionOwner);
        this.newAction.ownerUserId = this.selectedActionOwner.ownerUserId;
        this.newAction.ownerUserName = this.selectedActionOwner.ownerUserName;
        this.newAction.ownerUserEmail = this.selectedActionOwner.ownerUserEmail;
      },
    },
    activityDialog() {
      if(this.activityDialog === true){
        this.logActivity();
      }else{
        this.$emit("closeDialog");
      }
    },
  },
  async created(){
    const t = this
    t.schema = {... schema.action}
    t.actions = {...t.datadown};
    t.newAction = {...t.schema}
    let actionTypes = await t.MIX_redisReadSet('actionTypes', false);
    if (actionTypes.code === 1) {
      t.actionTypes = actionTypes.data;
    }

    if (this.newAction.ownerUserId !== '' && this.newAction.ownerUserId !== undefined && this.newAction.ownerUserId !== null) {
      this.selectedActionOwner = {
        ownerUserId: this.datadown.ownerUserId,
        ownerUserName: this.datadown.ownerUserName,
        ownerUserEmail: this.datadown.ownerUserEmail
      };
    } else {
      this.selectedActionOwner = this.MIX_getCurrentOwner();
    }

    let users = await t.MIX_users();
    t.users = users.map((x) => {
      return {
        ownerUserId: x.id,
        ownerUserName: x.userName,
        ownerUserEmail: x.userEmail,
      };
    });
  }
};
</script>