<template>
    <v-app>
        <alertbox />
        <AppUpdate />
        <CheckNetwork />
        <v-overlay v-model="supportModal">
            <v-card :class="$vuetify.breakpoint.mdAndUp ? 'pa-4': 'pa-1'" class="primary" :width="$vuetify.breakpoint.mdAndUp ? '600' : '300'">
                <v-col cols="12" class="d-flex justify-space-between ma-0 pa-0">
                    <v-card-title>Contact App Support</v-card-title>
                    <v-icon color="white" class="mr-4" @click="supportModal = false">icons8-multiply</v-icon>
                </v-col>
                <v-card-subtitle>Please fill in the form below to contact app support</v-card-subtitle>
                <v-card-text>
                    <v-text-field dense outlined label="Name" hide-details="auto" class="mb-2" v-model="form.name"></v-text-field>
                    <v-text-field dense outlined label="Email" v-model="form.email"></v-text-field>
                    <v-textarea dense outlined label="Message" v-model="form.message"></v-textarea>
                    <v-btn block class="white elevation-0 primary--text" @click="sendMessage()">SEND</v-btn>
                </v-card-text>
            </v-card>
        </v-overlay>
        <v-app-bar app color="primary" class="elevation-0" style=" z-index: 100 !important" dark clipped-left v-if="!$route.meta.hideNavbar">
            <!--Home icon-->
            <div v-if="$vuetify.breakpoint.mdAndUp" style="width: 120px;"><v-icon @click="MIX_go('/')" class="" color="white">icons8-home-page</v-icon></div>
            <div v-else><v-icon @click="MIX_go('/')" class="" color="white">icons8-home-page</v-icon></div>

            <div style="width: 100% !important" class="d-flex align-center">
                <v-img v-if="$vuetify.breakpoint.mdAndUp" width="50" height="50" contain src="@/assets/logo.png" />
                <v-img v-else width="70" class="ml-6" height="40" contain src="@/assets/logo.png" />
                <v-chip label class="primary darken-2" v-if="redisType === 'Staging' || redisType === 'Local'">{{ redisType }}</v-chip>
                <!-- <div class="" style="margin-right: -100px"></div> -->
            </div>
            <div>
                <!-- <v-icon @click="ACT_taskBar" color="white">icons8-to-do</v-icon> -->
                <v-menu offset-y left origin="top right" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="primary--text" color="" v-bind="attrs" v-on="on" :small="GET_dense" depressed plain>
                            <v-icon class="" color="white">icons8-menu</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="d-flex flex-column align-start">
                        <v-list-item class="caption grey--text text--darken-2" dense>
                            <div class="d-flex flex-column align-start justify-start">
                            <div class="primary--text text-subtitle-1">User Info</div>
                            <table width="100%">
                                <tr><td><strong>User: </strong></td><td>{{ GET_FIREBASE_userAuth.displayName }}</td></tr>
                                <tr><td><strong>Email:</strong></td><td>{{ GET_FIREBASE_userAuth.email }}</td></tr>
                                <tr><td><strong>Level:</strong></td><td>{{ userLevel }}</td></tr>
                                <tr><td><strong>Group:</strong></td><td>{{ userGroup }}</td></tr>
                                <tr><td><strong>Status:</strong></td><td>{{ userStatus }}</td></tr>
                                <tr><td><strong>Database:</strong></td><td>{{ redisType }}</td></tr>
                            </table>
                            </div>
                        </v-list-item>
                        <v-list-item class="mt-2" style="width: 100%">
                            <v-btn block compressed depressed class="primary--text" @click="supportModal = true"> <v-icon color="primary" class="mr-2">icons8-school</v-icon>Support </v-btn>
                        </v-list-item>
                        <v-list-item style="width: 100%">
                            <v-btn block compressed depressed class="primary--text" @click="refreshApp()"> <v-icon color="primary" class="mr-4">icons8-reboot</v-icon>Reload</v-btn>
                        </v-list-item>
                        <v-list-item style="width: 100%">
                            <v-btn block compressed depressed class="primary--text" @click="MIX_FIREBASE_logout"> <v-icon color="primary" class="mr-2">icons8-logout</v-icon>Log Out </v-btn>
                        </v-list-item>
                        <v-list-item style="width: 100%">
                            <v-btn block compressed depressed plain class="primary--text d-flex flex-column mt-2">Version {{ version }} </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>
        <!-- Horizontal Navigation -->
        <v-banner app sticky class=" secondary pa-0 elevation-0 ma-0" v-if="$vuetify.breakpoint.smAndDown && !$route.meta.hideNavbar" style="z-index: 99 !important">
            
            <v-row no-gutters > 
                <v-col cols="12">
                    <v-tabs  fixed-tabs show-arrows icons-and-text class="elevation-0 pa-0 ma-0"  v-model="menutab" background-color="secondary" dark color="white" active-class="secondary" hide-slider>
                        <v-tab v-for="(item, index2) in menuMobile" :key="index2" :to="item.path" class="pt-2 white--text">
                            <span class="caption font-weight-bold">{{ item.name }}</span>
                            <v-icon class="navicons white--text">{{ item.icon }}</v-icon>
                        </v-tab> 
                    </v-tabs>
                </v-col>
            </v-row>

        </v-banner>

        <!-- //TODO - EB - fix popup -->
        <v-dialog v-model="addContactDialog" scrollable max-width="900px">
            <v-card>
                <v-card-actions class="text-right">
                    <div class="primary--text text-h6 font-weight-light">New Quick Contact</div>
                    <v-spacer />
                    <v-btn color="primary" text @click="addContactDialog = false">Close</v-btn>
                </v-card-actions>
                <organisation-contact :quickContact="true" :editMode="true" :pu="true" />
                <!-- <v-card-actions class="primary text-right">
                    <v-spacer />
                    <v-btn color="white" text @click="addQuickContactDialog = false">Save</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
        <v-navigation-drawer app mini-variant @transitionend="mini = !mini" expand-on-hover left class="" clipped style="z-index: 101 !important" v-if="!$route.meta.hideNavbar">
            <!--:mini-variant.sync="mini">-->
            <side-nav :mini="mini" />
        </v-navigation-drawer>

        <v-main>
            <v-container fluid fill-height class="grey lighten-2 d-flex fill-height align-start pa-0" :style="containerStyle" style="width: 100% !important;">
                <router-view :key="$route.fullPath" />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { version } from '../package.json';
import { mapActions, mapGetters } from 'vuex';
import organisationContact from '@/views/contacts/contactDetails.vue';
import sideNavigation from '@/views/navigation/sideNavigation.vue';
import CheckNetwork from '@/components/connection/CheckNetwork.vue';
export default {
    name: 'App',
    components: {
        'side-nav': sideNavigation,
        'organisation-contact': organisationContact,
        CheckNetwork,
    },
    data: () => ({
        file: null,
        form: {
            name: '',
            email: '',
            message: '',
        },
        menuMobile: [
            { name: 'Customers', icon: "icons8-hotel-building", path: '/customers' },
            { name: 'People', icon: "icons8-user-groups", path: '/contacts' },
            // { name: 'Leads', icon: "icons8-business-network", path: '/leads' },
        ],
        // mini: true,
        version: version,
        supportModal: false,
        addContactDialog: false,
        mini: true,
        redisType: 'Production',
        menutab: 1
    }),
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
            GET_appUpdate: 'GET_appUpdate',
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },

        containerStyle() {
            if (!this.$route.meta.hideNavbar && this.$vuetify.breakpoint.mdAndUp) {
                return {
                    position: 'fixed !important',
                    scrolling: 'none !important',
                    maxWidth: 'calc(100vw - 55px) !important',
                };
            } else {
                return '';
            }
        },
    },
    methods: {
        bla() {
            this.mini = !this.mini;
        },
        ...mapActions({
            ACT_taskBar: 'ACT_taskBar',
            ACT_dense: 'ACT_dense',
            ACT_destroy: 'ACT_destroy',
        }),
        log: (v) => console.log(v),
        refreshApp() {
            window.location.reload();
        },
        sendMessage() {
            let t = this;
            document.location = 'mailto:ministryoffurniture@vindico1.eu.teamwork.com' + '?subject=' + 'Ministry of Furniture SWAPP Support: ' + t.form.name + ' - ' + t.form.email + '&body=' + t.form.message;
        },
        checkRedis(){
          if (process.env.VUE_APP_MOFSERVER === 'https://api.ministryoffurniture.com/api/v1') {
            this.redisType = 'Production';
          } else if(process.env.VUE_APP_MOFSERVER === 'https://mof-server-staging-lj2opgvzfq-nw.a.run.app/api/v1'){
            this.redisType = 'Staging';
          }else{
            this.redisType = 'Local';
          }
        }
    },
    created() {
      //remove any alert boxes in store on app load
      this.MIX_alertBox({ show: false, message: "", color: "", timeout: "1" });
        var dense = localStorage.getItem('dense');
        if (dense === 'true') {
            this.ACT_dense(true);
        } else {
            this.ACT_dense(false);
        }
        var destroy = localStorage.getItem('destroy');
        if (destroy === 'true') {
            this.ACT_destroy(true);
        } else {
            this.ACT_destroy(false);
        }
        this.checkRedis();
    },
    async mounted() {
        console.log('MOF-SERVER: ' + process.env.VUE_APP_MOFSERVER);
    },
};
</script>

<style>
.roundedtop {
    border-radius: 5px 5px 0px 0px !important;
}
.roundedbottom {
    border-radius: 0px 0px 5px 5px !important;
}
.truncate-200 {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mdi-close {
    color: lightgrey !important;
}
.pointer {
    cursor: pointer;
}
a {
    text-decoration: none;
}
    .v-banner__wrapper {
        padding: 0px !important;
        margin: 0px !important;
    }
    .v-banner__content {
        padding: 0px !important;
        margin: 0px !important;

    }
</style>
