<template>
  <div class="" style="padding: 0px !important; margin: 0px !important; width: 99% !important">
    <v-row class="apptext--text" dense style="z-index: 0 !important">
      <!-- <div class="primary darken-1 white--text text-bold pa-3 text-h5" style="width: 100% !important">
          <span class="font-weight-light">
              <strong>{{ item.orgAccountTypes }}: </strong>
              <span v-if="item.orgName !== ''">{{ item.orgName }}</span>
          </span>
      </div> -->
      <!-- Main Content -->
      <v-col cols="12" class="d-flex align-start rounded">
        <!-- //* VIEW MODE -->
        <v-row class="px-3 pt-3" style="width: 100% !important" no-gutters>
          <!-- VIEW VERSION -->
          <!-- //* GENERAL DETAILS VIEW -->
          <v-col cols="12" sm="12" class="mb-3">
            <v-row class="white rounded pa-3 fill-height" no-gutters>
              <v-col cols="12" sm="12">
                <div class="d-flex align-center">
                  <span class="text-left primary--text  text--darken-2 text--darken-2 text-h6 font-weight-light">Customer Details</span>
                  <v-spacer/>
                  <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                </div>
                <v-divider class="grey lighten-1 mb-1"></v-divider>
              </v-col>
              <v-col cols="12" class="" style="height: 100% !important">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Telephone:</td>
                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                        <a :href="'callto:' + item.orgTelephone" target="_blank">{{ item.orgTelephone }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Website:
                      </td>
                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                        <a :href="item.orgWebsite" target="_blank"
                           v-if="item.orgWebsite !== '' && item.orgWebsite !== undefined">{{
                            item.orgWebsite.substring(0, 21)
                          }}...</a>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Email:</td>
                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                        <a :href="'mailto:' + item.orgEmail" target="_blank">{{ item.orgEmail }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Stage:</td>
                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                        {{ item.orgStageName }}
                      </td>
                    </tr>

                    <tr>
                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Address:</td>
                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                        <a class="text-decoration-none"
                           :href="`https://www.google.com/maps/search/?api=1&query=${item.orgName},${item.orgAddressLine1},${item.orgAddressLine2},${item.orgAddressLine3},${item.orgTown},${item.orgCounty},${item.orgPostcode}`"
                           target="_blank">
                                                        <span v-if="item.orgAddressLine1"
                                                        >{{ item.orgAddressLine1 }}
                                                          <!-- <span v-if="item.orgAddressLine2">, {{ item.orgAddressLine2 }}</span> -->
                                                            <br
                                                            /></span>
                          <span v-if="item.orgAddressLine3">{{ item.orgAddressLine3 }}<br/></span>
                          <span v-if="item.orgTown">{{ item.orgTown }}
                            <!-- <span v-if="item.orgCounty">, {{ item.orgCounty }}</span> -->
                                                            <br
                                                            /></span>
                          <span v-if="item.orgPostcode">{{ item.orgPostcode }}<br/></span>
                        </a>
                      </td>
                    </tr>
                    <!-- <tr>
                        <td class="pl-0 apptext--text font-weight-bold grey lighten-5">Local Authority:</td>
                        <td class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgLocalAuthority }}</td>
                    </tr> -->
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>

          <!-- //* CONTACTS VIEW -->
          <!-- <v-col cols="12" sm="12"  class="mb-3"> -->
          <!-- VIEW VERSION -->
          <!-- <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
              <v-col cols="12">
                  <div class="d-flex align-center">
                      <span class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Contact</span>
                      <v-spacer />
                      <v-icon medium class="grey--text text--darken-2">icons8-contact</v-icon>
                  </div>
                  <v-divider class="grey lighten-1 mb-1"></v-divider>
              </v-col>
              <v-col cols="12" class="" style="height: 100% !important">
                  <v-simple-table dense>
                      <template v-slot:default>
                          <tbody>
                          </tbody>
                      </template>
                  </v-simple-table>
              </v-col>
          </v-row>
      </v-col> -->
          <!-- //* INVOICE DETAILS VIEW -->
          <!-- <v-col  cols="12" sm="12" class="mb-3">
              <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                  <v-col cols="12">
                      <div class="d-flex align-center">
                          <span class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Finance & Accounting</span>
                          <v-spacer />
                          <v-icon medium class="grey--text text--darken-2">icons8-calculator</v-icon>
                      </div>
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                  </v-col>
                  <v-col cols="12" class="" style="height: 100% !important">
                      <v-simple-table dense>
                          <template v-slot:default>
                              <tbody>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Invoice Email:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
                                          <a :href="'mailto:' + item.orgInvoiceEmail" target="_blank">{{ item.orgInvoiceEmail }}</a>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Purchasing Email:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
                                          <a :href="'mailto:' + item.orgPurchasingEmail" target="_blank">{{ item.orgPurchasingEmail }}</a>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Credit Limit:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
                                          {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.orgAccountCreditLimit) }}
                                      </td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Account On Hold:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">
                                          <span v-if="item.orgAccountOnHold === '0'">No</span>
                                          <span v-if="item.orgAccountOnHold === '1'">Yes</span>
                                      </td>
                                  </tr>
                              </tbody>
                          </template>
                      </v-simple-table>
                  </v-col>
              </v-row>
          </v-col> -->
          <!-- //* ADDRESS DETAILS VIEW -->
          <!-- <v-col cols="12" sm="12" class=" mb-3"> -->
          <!-- VIEW VERSION -->
          <!-- <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
              <v-col cols="12">
                  <div class="d-flex align-center">
                      <span class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Address</span>
                      <v-spacer />
                      <v-icon medium class="grey--text text--darken-2">icons8-map-marker</v-icon>
                  </div>
                  <v-divider class="grey lighten-1 mb-1"></v-divider>
              </v-col>
              <v-col cols="12" style="height: 100% !important">
                  <v-simple-table dense>
                      <template v-slot:default>
                          <tbody style="height: 100px" class="grey lighten-5">
                          </tbody>
                      </template>
                  </v-simple-table>
              </v-col>
          </v-row>
      </v-col> -->
          <!-- //* ORG INFO VIEW -->
          <!-- <v-col cols="12" sm="12" class="mb-3">
              <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                  <v-col cols="12">
                      <div class="d-flex align-center">
                          <span class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Organisation Info</span>
                          <v-spacer />
                          <v-icon medium class="grey--text text--darken-2">icons8-city</v-icon>
                      </div>
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                  </v-col>
                  <v-col cols="12" style="height: 100% !important">
                      <v-simple-table dense>
                          <template v-slot:default>
                              <tbody>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Channel:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgChannel }}</td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 grey lighten-5">Employees:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgEmployees }}</td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Industry:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgIndustry }}</td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Sector:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-1">{{ item.orgSector }}</td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Turnover:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgTurnover }}</td>
                                  </tr>
                              </tbody>
                          </template>
                      </v-simple-table>
                  </v-col>
              </v-row>
          </v-col> -->
          <!-- //* OWNER / SOURCE INFO VIEW -->
          <!-- <v-col cols="12" sm="12" class="mb-3">
              <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                  <v-col cols="12">
                      <div class="d-flex align-center">
                          <span class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Source Info</span>
                          <v-spacer />
                          <v-icon medium class="primary--text" text--darken-2 >icons8-secured-letter</v-icon>
                      </div>
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                  </v-col>
                  <v-col cols="12" style="height: 100% !important">
                      <v-simple-table dense>
                          <template v-slot:default>
                              <tbody>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Source:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgSource }}</td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Source Name:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.orgSourceName }}</td>
                                  </tr>
                              </tbody>
                          </template>
                      </v-simple-table>
                  </v-col>
              </v-row>
          </v-col> -->

          <!-- //* SOCIAL INFO VIEW -->
          <!-- <v-col cols="12">
              <v-row class="grey lighten-5 rounded pa-3 fill-height" no-gutters>
                  <v-col cols="12">
                      <div class="d-flex align-center">
                          <span class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Social Networks</span>
                          <v-spacer />
                          <v-icon medium class="grey--text text--darken-2">icons8-people-working-together</v-icon>
                      </div>
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                  </v-col>
                  <v-col cols="12" style="height: 100% !important">
                      <v-simple-table dense>
                          <template v-slot:default>
                              <tbody>
                                  <tr>
                                      <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
                                          <v-icon class="apptext--text mr-2">icons8-twitter</v-icon>
                                          <div class="body-2 pointer">
                                              <a :href="item.orgTwitter" target="_blank">{{ item.orgTwitter }}</a>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
                                          <v-icon class="apptext--text mr-2">icons8-linkedin</v-icon>
                                          <div class="body-2">
                                              <a :href="item.orgLinkedIn" target="_blank">{{ item.orgLinkedIn }}</a>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
                                          <v-icon class="apptext--text mr-2">icons8-facebook</v-icon>
                                          <div class="body-2">
                                              <a :href="item.orgFacebook" target="_blank">{{ item.orgFacebook }}</a>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 grey lighten-5 d-flex align-center">
                                          <v-icon class="apptext--text mr-2">icons8-instagram</v-icon>
                                          <div class="body-2">
                                              <a :href="item.orgInstagram" target="_blank">{{ item.orgInstagram }}</a>
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </template>
                      </v-simple-table>
                  </v-col>
              </v-row>
          </v-col> -->
          <!-- METADATA INFO -->
          <!-- <v-col cols="12">
              <v-row class="grey lighten-5 rounded pa-3 mt-3 fill-height" no-gutters>
                  <v-col cols="12">
                      <div class="d-flex align-center">
                          <span class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Metadata</span>
                          <v-spacer />
                          <v-icon medium class="primary--text" text--darken-2 >icons8-secured-letter</v-icon>
                      </div>
                      <v-divider class="grey lighten-1 mb-1"></v-divider>
                  </v-col>
                  <v-col cols="12" style="height: 100% !important">
                      <v-simple-table dense>
                          <template v-slot:default>
                              <tbody>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Owner:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.ownerUserName }}</td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Created by:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.createdUserName }}</td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Created:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YYYY HH:mm:ss') }}</td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Modified by:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ item.modifiedUserName }}</td>
                                  </tr>
                                  <tr>
                                      <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold grey lighten-5">Modified:</td>
                                      <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 grey lighten-5 pl-2">{{ MIX_formatDateTime(item.modifiedDateTime, 'x', 'DD-MMM-YYYY HH:mm:ss') }}</td>
                                  </tr>
                              </tbody>
                          </template>
                      </v-simple-table>
                  </v-col>
              </v-row>
          </v-col> -->
        </v-row>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
// import companiesHouse from '@/views/organisations/companiesHouse.vue';

export default {
  name: 'OrganisationDetails',
  components: {
    // 'companies-house': companiesHouse,
  },
  props: {
    datadown: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dense: {
      type: Boolean,
      default: false,
    },
    itemnew: {
      type: Boolean,
      default: false,
    },
    listshrunk: {
      type: Boolean,
      default: false,
    },
    orgtype: {
      type: String,
      default: '',
    },
  },
  // * DATA
  data: () => ({
    // Item
    item: {},

  }),
  computed: {
    ...mapGetters({
      GET_dense: 'GET_dense',
    }),
    inputCols() {
      if (this.listshrunk) {
        return '3';
      } else {
        return '6';
      }
    },
  },
  // * METHODS
  methods: {
    // * READ ITEM
    async readItem() {
      let t = this;
      var id = {...t.datadown}
      // ////console.log("reading item " + JSON.stringify(id, null, 2))
      let result = await t.MIX_readItem(id.orgId, 'org');
      if (result.code === 1) {
        this.item = result.data;
        // ////console.log("item = " + JSON.stringify(t.item, null, 2))
        t.itemNew = false;
        t.loading = false;
      }
    },
  },
  // * WATCH
  watch: {
    datadown() {
      var t = this;
      t.item = {...t.datadown};
      // ////console.log("item customer = " + JSON.stringify(t.item, null, 2))
    },
  },
  // * CREATED
  async created() {
    let t = this;
    t.item = {...t.datadown};
    // ////console.log("item customer = " + JSON.stringify(t.item, null, 2))
    // this.readItem();

  },
};
</script>

<style></style>
