<template>
	<div>
		<v-row class="pa-0 ma-0" fill-height>
			<v-row no-gutters class="py-3 px-3">
				<v-col class="font-weight-bold primary--text text-h6 d-flex align-center">
					<div class="mx-3"><v-icon>icons8-hierarchy</v-icon></div>
					<div class="text-h7" v-if="item.attributeName">{{ item.attributeName }}</div>
					<div class="text-h7" v-else>New Api Integration</div>
					<v-spacer></v-spacer>
					<div>
						<v-btn class="ml-2 primary--text" plain :small="GET_dense" @click="$emit('closeItem')" depressed>
							<v-icon>icons8-multiply</v-icon>
						</v-btn>
					</div>
				</v-col>
			</v-row>

			<v-col class="pa-0" fill-height :cols="viewCols.cols" :xs="viewCols.xs" :sm="viewCols.sm" :md="viewCols.md" :lg="viewCols.lg" :xl="viewCols.xl">
                <v-row class="pa-3" no-gutters>
                    <v-col cols="12" sm="12" class="pa-3">
                        <div class="text-left primary--text text-h6 font-weight-light">Generate new API key</div>
                        <div class="text-left error--text caption font-weight-light">* Please store this key in a safe pleace as it will not be available when you navigate away</div>
                    </v-col>
                    <v-col cols="12" sm="12" class="white pa-3">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <tbody>
                                    <tr>
                                        <td width="30%">Service Name</td>
                                        <td width="70%" class="py-2">
                                            <v-select
                                                :items="services"
                                                label="Please select a service"
                                                v-model="selectedService"
                                                outlined
                                            ></v-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">Api Key</td>
                                        <td width="70%" class="py-2">
                                            <v-text-field disabled v-model="apiKey" outlined dense hide-details="auto" background-color="white" color="primary"></v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <v-btn block class="primary" @click="generateApiKey(selectedService)" :loading="getKey" :disabled="!selectedService">GENERATE API KEY</v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		props: {
			activeitem: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		data: () => ({
            apiKey: '',
			editMode: false,
            getKey: false,
			item: {},
            key: 'api:',
			newItem: false,
            selectedService: '',
            schema: null,
            services: ["Redis", "Send Grid"],
			tab: "details",
		}),
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
			viewCols() {
				return {
					cols: 12,
					xs: 12,
					sm: 12,
					md: 12,
					lg: 12,
					xl: 12,
				};
			},
		},
		methods: {
            initialiseItem() {
				let t = this;
				if (JSON.stringify(t.activeitem) !== '{}') {
					this.readItem();
				} else {
					t.newItem = true;
					t.editMode = true;
				}
			},
            async readItem() {
				let t = this;
				let result = await t.MIX_readItem(t.activeitem.id, t.key);
				if (result.code === 1) {
					this.item = result.data;
					t.editMode = false;
					t.newItem = false;
				}
			},
            generateApiKey(service) {
                let t = this;
                const formatService = service.replace(/\s/g, '').toLowerCase();
                t.getKey = true;
                t.$axios.get(t.apiUrl + `/generateApiToken?key=${formatService}`)
                    .then(response => {
                        if(response.code === -1) {
                            t.apiKey = "Error creating api key, please try again."
                            t.getKey = false;
                        } else {
                            t.apiKey = response.data.data;
                            t.getKey = false;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        t.getKey = false;
                    })
            },					
		},
        async created() {
			this.initialiseItem();
		},
	};
</script>
