<template>
	<v-row class="pa-0 ma-0" fill-height>
        <v-row no-gutters class="pb-3 px-3 pt-3" :class="item.orgAccountOnHold === '1' ? 'warning' : 'secondary'">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3"><v-icon class="white--text">icons8-factory</v-icon></div>
                <div v-if="item.orgName.length > 40">
                    <v-tooltip top :max-width="200">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="tooltiptext tooltip font-weight-light"
                                ><strong>Supplier:</strong>{{ item.orgName.substring(0, 40) }}...</span
                            >
                        </template>
                        <span>{{ item.orgName }}</span>
                    </v-tooltip>
                </div>
                <div v-else-if="item.orgName.length <= 40">
                    <span class="font-weight-light"
                        ><strong>Supplier: </strong>{{ item.orgName }}</span
                    >
                </div>
                <div class="ml-2 font-weight-light" v-if="item.orgName === ''">New</div>

                <v-spacer></v-spacer>
				<div class="ml-2" v-if="item.orgAccountOnHold === '1'">ON HOLD</div>
                <div>
                    <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                        <v-icon>icons8-multiply</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

		<v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
			<v-tabs background-color="white" v-model="tab" show-arrows>
				<v-tab href="#details">Details</v-tab>
				<v-tab href="#notes">Lead Time Notes</v-tab>
				<!-- <v-tab href="#options">Options</v-tab>
				<v-tab href="#fabrics">Fabrics</v-tab>
				<v-tab href="#colours">Colours</v-tab>
				<v-tab href="#colours">Attributes</v-tab> -->
			</v-tabs>
			<v-tabs-items v-model="tab" fill-height class="grey lighten-3">
				<v-tab-item value="details">
                    <organisation-details dense :datadown="item" :itemnew="itemNew" v-on:save="saveItem" v-on:cancel="cancelItem" />
				</v-tab-item>
				<!-- Notes Tab -->
				<v-tab-item value="notes">
					<supplier-notes :datadown="item" v-on:save="saveItem" v-on:cancel="cancelItem"/>
				</v-tab-item>
				<!-- Options Tab -->
				<v-tab-item value="options">
					<supplier-options :org="item" :editmode="editMode" />
				</v-tab-item>
				<!-- Fabrics Tab -->
				<v-tab-item value="fabrics">
					<supplier-fabrics :org="item" />
				</v-tab-item>
				<!-- Colours Tab -->
				<v-tab-item value="colours">
					<supplier-colours :org="item" />
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
	import { mapGetters } from "vuex";
    import organisationDetails from "@/views/organisations/organisationDetails.vue"
	import supplierOptions from "@/views/suppliers/supplierOptions.vue";
	import supplierFabrics from "@/views/suppliers/supplierFabrics.vue";
	import supplierColours from "@/views/suppliers/supplierColours.vue";
	import supplierNotes from '@/views/suppliers/supplierNotes.vue';


	import schema from "@/schema.js";
	export default {
		// * COMPONENTS
		components: {
            "organisation-details": organisationDetails,
			"supplier-options": supplierOptions,
			"supplier-fabrics": supplierFabrics,
			"supplier-colours": supplierColours,
			"supplier-notes": supplierNotes
		},
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			tab: "details",
			key: "org",
			schema: null,

			// Active Item
			item: {},
            itemNew: false,
            editMode: false,

            // Form items
			accountTypes: [],
            addressTypes: [],

            // Validation
            rules: {},

            // Toggles
            addAddressToggle: false,
            addOtherAddressToggle: false,
            addOrgInfoToggle: false,
            addNotesToggle: false,
            addOwnerInfoToggle: false,
            addSocialToggle: false
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
				GET_destroy: "GET_destroy",
			}),
			viewCols() {
				return {
					cols: 12,
					xs: 12,
					sm: 12,
					md: 12,
					lg: 12,
					xl: 12,
				};
			},
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				if (JSON.stringify(t.datadown) !== '{}') {
					this.readItem(t.datadown.id);
				} else {
					t.itemNew = true;
					t.editMode = true;
					t.item = { ...t.schema };
					t.item.id = t.MIX_generateId();
				}
			},
            // * VALIDATION RULES
            validateItem() {
                let t = this;
                t.rules = {
                    // GENERAL DETAILS
                    // orgName: [ v => !!v || 'Supplier Name Required'],
                    // orgRegisteredName: [ v => !!v || 'Registered Name Required'],
                    // orgRegistrationNumber: [ v => !!v || 'Registration Number Required'],
                    // orgTelephone: [ v => !!v || 'Telephone Required'],
                    // orgWebsite: [ v => !!v || 'Website Required'],
                    // orgEmail: [ v => !!v || 'Email Required'],
                    // orgInvoiceEmail: [ v => !!v || 'Invoice Email Required'],
                    // orgPurchasingEmail: [ v => !!v || 'Purchasing Email Required'],

                    // ADDRESS DETAILS
                    // orgAddressType: [ v => !!v || 'Address Type Required'],
                    // orgAddressLine1: [ v => !!v || 'Address Line 1 Required'],
                    // orgAddressLine2: [ v => !!v || 'Address Line 2 Required'],
                    // orgAddressLine3: [ v => !!v || 'Address Line 3 Required'],
                    // orgTown: [ v => !!v || 'Town Required'],
                    // orgCounty: [ v => !!v || 'County Required'],
                    // orgPostcode: [ v => !!v || 'Postcode Required'],

                    // OTHER ADDRESS DETAILS
                    // orgOtherAddressType: [ v => !!v || 'Address Type Required'],
                    // orgOtherAddressLine1: [ v => !!v || 'Address Line 1 Required'],
                    // orgOtherAddressLine2: [ v => !!v || 'Address Line 2 Required'],
                    // orgOtherAddressLine3: [ v => !!v || 'Address Line 3 Required'],
                    // orgOtherTown: [ v => !!v || 'Town Required'],
                    // orgOtherCounty: [ v => !!v || 'County Required'],
                    // orgOtherPostcode: [ v => !!v || 'Postcode Required'],

                    // ORG INFO
                    // orgEmployees: [ v => !!v || 'No of Employees Required'],
                    // orgIndustry: [ v => !!v || 'Industry Required'],
                    // orgSector: [ v => !!v || 'Sector Required'],
                    // orgAccountNumber: [ v => !!v || 'Account Number Required'],
                    // orgAccountTypes: [ v => !!v || 'Account Type Required'],
                    // orgLocalAuthority: [ v => !!v || 'Local Authority Required'],
                    // orgTurnover: [ v => !!v || 'Turnover Required'],

                    // OWNER / SOURCE INFO
                    // orgAccountOwner: [ v => !!v || 'Account Owner Required'],
                    // orgSource: [ v => !!v || 'Source Required'],
                    // orgSourceName: [ v => !!v || 'Source Name Required'],

                    // SOCIAL INFO
                    // orgTwitter: [ v => !!v || 'Twitter Required'],
                    // orgFacebook: [ v => !!v || 'Facebook Required'],
                    // orgLinkedIn: [ v => !!v || 'LinkedIn Required'],
                    // orgInstagram: [ v => !!v || 'Instagram Required'],
                }
                t.$nextTick(() => {
                    if (t.$refs.form.validate()) {
                        this.createItem()
						this.editMode = false;
                    } else {
                        this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Fill out all the fields correctly in order to proceed.', show: true })
                    }
                })
            },
			// * CREATE ITEM	
			async createItem(item) {
                let t = this;
				t.item.id = t.item.orgName.slice(0, 11).toUpperCase().replace(/ /g, "").trim();
				if (t.item.id.length < 10) {
					t.item.id = t.item.id + new Array(9 - t.item.id.length).fill("0").join("") + "1";
				}
				
				let result = await this.MIX_createItem(this.item, this.key, this.itemNew);
				if (result.code === 1) {
					this.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					this.readItem(item.id);
					t.$emit("refreshitems")

				}
			},
			// * READ ITEM
			async readItem(itemId) {
				let t = this;
				let result = await t.MIX_readItem(itemId, t.key);
				if (result.code === 1) {
					this.item = result.data;
					t.itemNew = false;
					t.loading = false;
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) { this.readItem() }
			},
			// * DELETE ITEM
			async deleteItem() {
				let result = await this.MIX_deleteItem(this.item.id, this.key);
				if (result.code === 1) { this.readItem() }
			},			
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) { this.readItem() }
			},	
			// Close Item
			closeItem() {
				this.$emit("closeitem");
			},	
			// Save Item
			async saveItem(item) {
				console.log('############################### Save item ')
				//console.log('itemdddddddd = ' + JSON.stringify(item))
				let result = await this.MIX_createItem(item, this.key, this.itemNew);
				if (result.code === 1) {
					this.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				}
			},			
			cancelItem() {
				this.readItem(this.item.id);
			}	
		},
		// * WATCH
		watch: {
            // Datadown changes
			datadown() { this.initialiseItem() },
		},
		// * CREATED
		async created() {
            let t = this
			this.schema = schema.organisation;
			this.initialiseItem();
			let accountTypes = t.MIX_redisReadSet("set:orgAccountTypes", false);
			if (accountTypes.code === 1) { t.accountTypes = accountTypes.data }	

			let addressTypes = await t.MIX_redisReadSortedSet('sset:addressTypes', 0, 9999);
			if (addressTypes.code === 1) { t.addressTypes = addressTypes.data }	
		},
		// * MOUNTED
		async mounted() {},
	};
</script>
