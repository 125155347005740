<template>
	<v-row fill-height class="grey lighten-2" style="height: 100% !important" no-gutters>
		<v-col class="align-center pa-3" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
			<div class="d-flex align-center mb-1">
				<div class="text-left primary--text text-h5 font-weight-light" v-if="!listShrunk">Fabrics ({{itemsTotal}})</div>
				<v-spacer v-if="!listShrunk"></v-spacer>
				<div class="ml-1"><v-btn depressed block class="accent secondary--text" @click="openItem({})" :large="!GET_dense"><v-icon class="mr-3">icons8-cloth</v-icon>New</v-btn></div>
				<v-spacer v-if="listShrunk"></v-spacer>
				<div>
						<v-btn
							class="primary--text ml-1"
							:large="!GET_dense"
							@click="
								listExpanded = false;
								listShrunk = true;
							"
							depressed
							background-color="white"
							:dense="!GET_dense"
							v-if="!listShrunk"
						>
							<v-icon color="primary">icons8-double-left</v-icon>
						</v-btn>
						<v-btn class="primary--text ml-1" :large="!GET_dense" @click="listShrunk = false" depressed v-if="listShrunk">
							<v-icon color="primary">icons8-forward</v-icon>
						</v-btn>
						<v-btn class="primary--text ml-1" :large="!GET_dense" @click="listExpanded = false" depressed v-if="listExpanded && !listShrunk">
							<v-icon color="primary">icons8-back</v-icon>
						</v-btn>
						<v-btn class="primary--text ml-1" :large="!GET_dense" @click="listExpanded = true" depressed v-if="!listExpanded && !listShrunk">
							<v-icon color="primary">icons8-forward</v-icon>
						</v-btn>
						<v-btn class="primary--text ml-1" depressed :large="!GET_dense" v-if="!listShrunk" @click="dialog = true;">
							<v-icon color="primary">icons8-import-csv</v-icon>
						</v-btn>
						<v-btn class="primary--text ml-1" depressed :large="!GET_dense" v-if="!listShrunk" @click="exportCSV">
							<v-icon color="primary">icons8-export-csv</v-icon>
						</v-btn>
						<v-btn class="primary--text ml-1" depressed :large="!GET_dense" @click="getItems()">
							<v-icon color="primary">icons8-available-updates</v-icon>
						</v-btn>
						<v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important;" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed class="primary--text ml-1" v-bind="attrs" v-on="on" v-if="!listShrunk" :large="!GET_dense"><v-icon class="" color="primary">icons8-table-properties</v-icon></v-btn>
							</template>
							<v-card class="pa-5" width="250" >
								<div class="body-2 font-weight-bold">Show / hide Columns</div>
								<v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
							</v-card>
						</v-menu>
				
				</div>
			</div>
			<v-divider class="grey lighten-1 mb-4"></v-divider>
			<!-- <div class="" v-if="listShrunk && !listExpanded"><v-btn depressed class="accent secondary--text" block @click="openItem({})">New</v-btn></div>			 -->
			<div class="flex-grow-1 my-3">
				<v-text-field label="Search Fabrics" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field>
			</div>
			<div class="d-flex align-center">
					<div>
						Showing&nbsp;<strong>{{ itemsFrom + 1 }}</strong>&nbsp;
						<span v-if="itemsTo !== itemsTotal"
							>to <strong>{{ itemsTo }}</strong></span
						>
						of <strong>{{ itemsTotal }}</strong>
					</div>
					<v-spacer />
					<div class="mb-5" v-if="!listShrunk"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>
			</div>
			<!-- TABLE BASED LIST -->
			<v-data-table v-if="!listShrunk" sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions">>
				<template v-slot:item.enabled="{ item }">
					<span v-if="item.enabled === '1'">Yes</span>
					<span v-if="item.enabled === '0'">No</span>
				</template>
				<template v-slot:item.action="{ item }">
					<v-icon class="error--text pa-1" style="border: 1px red solid; border-radius: 3px;" v-if="GET_destroy" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
					<v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted  === 'false'">icons8-trash-can</v-icon>
					<v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === 'true'">icons8-trash-restore</v-icon>
					<v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
				</template>
			</v-data-table>
			<!-- CARD BASED LIST -->
				<div v-if="listShrunk" style="height: 67vh; overflow-y: scroll" class="">
					<v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
					<div class="d-flex align-center ml-1">
						<div class="ml-3">
							<div class="body-2 font-weight-bold" style="width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.attributeName }}</div>
							Code: <span class="body-2">{{ item.attributeCode }}</span>
						</div>
						<v-spacer></v-spacer>
						<div class="text-right mr-3">
							<v-icon class="primary--text" @click="
								openItem(item)
							">icons8-forward</v-icon>
						</div>
					</div>
					</v-card>
				</div>
            <!-- App Copyright -->
            <appCopyright />
		</v-col>
		
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: lightgrey solid 2px !important" v-if="!listExpanded" class="grey lighten-3" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
				<fabric-item v-if="!listExpanded" v-on:refreshItems="getItems" :activeitem="activeItem" v-on:closeItem="listExpanded = true" />
			</v-col>
		</transition>
		<transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: lightgrey solid 2px !important" class="" v-if="GET_taskBar" :cols="taskCols.cols" :xs="taskCols.xs" :sm="taskCols.sm" :md="taskCols.md" :lg="taskCols.lg" :xl="taskCols.xl">
				<taskbar />
			</v-col>
		</transition>
		<v-dialog v-model="dialog" width="1024">
			<import-csv v-on:close="dialog = false;" :csvheaders="csvHeaders" :prefix="key" />
		</v-dialog>
	</v-row>
</template>

<script>
	import importCSV from "@/views/base/importCSV.vue";
	import taskBar from "@/views/tasks/taskbar.vue";
	import fabricItem from "@/views/fabrics/fabric.vue";
	import { mapGetters } from "vuex";
	export default {
		components: {
			"fabric-item": fabricItem,
			taskbar: taskBar,
			"import-csv": importCSV 
		},
		data: () => ({
			key: "attribute",
			index: "idx:attributes",
			dialog: false,
			csvHeaders: ["id","attName","attCode","attType","optionId","optionName","key","attHexColor","attMTO","attPriceBand","attAddCost","attAddSell","enabled","attParentId","attDescription","attImage","attSupplier"],
			toggleDeletedItems: false,
			showImages: false,
			items: [],
			itemsTotal: null,
			listExpanded: true,
			listShrunk: false,
			searchQuery: "",
			itemsFrom: 0,
			itemsTo: 10,
			activeItem: {},
			// Headers to display in the Data Table
			headers: [
				{ text: "Id", value: "id", hidden: true },
				{ text: "Name", value: "attName", hidden: false },
				{ text: "Code", value: "attCode", hidden: false },				
				{ text: "Type", value: "attType", hidden: false },
				{ text: "MTO", value: "attMTO", hidden: true },
				{ text: "Price Band", value: "attPriceBand", hidden: true },
				{ text: "Add Cost", value: "attAddCost", hidden: true },
				{ text: "Add Sell", value: "attAddSell", hidden: true },
				{ text: "Enabled", value: "enabled", hidden: true },
				{ text: "Parent ID", value: "attParentId", hidden: true },
				{ text: "Description", value: "attDescription", hidden: true },
				{ text: "Hex Color", value: "attHexColor", hidden: true },
				{ text: "Image", value: "attImage", hidden: true },
				{ text: "Supplier", value: "attSupplier", hidden: true },
				{ text: "Option Id", value: "optionId", hidden: true },
				{ text: "Option Name", value: "optionName", hidden: true },
				{ text: "Enabled", value: "enabled", hidden: false },
				{ text: "Key", value: "key", hidden: true },
				{ text: "Date Time (C)", value: "createdDateTime", hidden: true },
				{ text: "User ID (C)", value: "createdUserId", hidden: true },
				{ text: "User Name (C)", value: "createdUserName", hidden: true },
				{ text: "User Email (C)", value: "createdUserEmail", hidden: true },
				{ text: "Date Time (M)", value: "modifiedDateTime", hidden: true },
				{ text: "User ID (M)", value: "modifiedUserId", hidden: true },
				{ text: "User Name (M)", value: "modifiedUserName", hidden: true },
				{ text: "User Email (M)", value: "modifiedUserEmail", hidden: true },
				{ text: "Deleted", value: "deleted", hidden: true },
				{ text: "Date Time (D)", value: "deletedDateTime", hidden: true },
				{ text: "User ID (D)", value: "deletedUserId", hidden: true },
				{ text: "User Name (D)", value: "deletedUserName", hidden: true },
				{ text: "User Email (D)", value: "deletedUserEmail", hidden: true }, 
				{ text: "Action", value: "action", align: "end", hidden: false, width: "155px" },
			],
			// Fields to Retrieve from Database
			sortBy: "@attName",
			datatableOptions: {
				page: 1,
				itemsPerPage: 10,
				sortBy: ["attName"],
				sortDesc: [false],
				groupBy: [],
				groupDesc: [],
				mustSort: false,
				multiSort: false,
			},
			itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
			itemCols: { cols: 12, xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
			taskCols: { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
		}),
		computed: {
			...mapGetters({
				GET_taskBar: "GET_taskBar",
				GET_dense: "GET_dense",
				GET_destroy: "GET_destroy",
			}),
			computedFields() {
				let computedFields = this.headers.filter(x => x.hidden === false).map(y => '@'+ y.value);
				if (!computedFields.includes("@id")) { computedFields.push ("@id") }
				if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
				computedFields = computedFields.join(',');
				return computedFields
			},			
			computedSearchQuery() {
				let searchQuery = this.searchQuery;
				if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
					if (this.toggleDeletedItems) {
						searchQuery = "@enabled:1 @attType:Fabric";
					} else {
						searchQuery = "@deleted:0 @enabled:1 @attSupplier:0 @attType:Fabric";
					}
					return searchQuery;
				} else {
					if (searchQuery.startsWith("@")) {
						if (this.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @enabled:1 @attSupplier:0 @attType:Fabric`;
							return searchQuery;
						} else {
							searchQuery = `${searchQuery}*  @deleted:0 @enabled:1 @attSupplier:0 @attType:Fabric`;
							return searchQuery;
						}
					} else {
						if (this.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @enabled:1 @attSupplier:0 @attType:Fabric`;
							return searchQuery;
						} else {
							searchQuery = `${searchQuery}*  @deleted:0 @enabled:1 @attSupplier:0 @attType:Fabric`;
							return searchQuery;
						}
					}
				}
			},
			computedHeaders() {
				return this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
			},
			computedItems() {
				var items = this.items; //.filter(x => x.);
				return items; //.slice(this.organisationsFrom, this.organisationsTo);
			}
		},
		methods: {
			// * DESTROY ITEM FROM ITEMS
			async destroyItem(itemId) {
				let result = await this.MIX_destroyItem(itemId, this.key);
				if (result.code === 1) { this.getItems() }
			},
			// * DELETE ITEM FROM ITEMS
			async deleteItem(itemId) {
				let result = await this.MIX_deleteItem(itemId, this.key);
				if (result.code === 1) { this.getItems() }
			},			
			// * RESTORE ITEM FROM ITEMS
			async restoreItem(itemId) {
				let result = await this.MIX_restoreItem(itemId, this.key);
				if (result.code === 1) { this.getItems() }
			},			
			// * OPEN ITEM
			openItem(item) {
				this.activeItem = item;
				this.listExpanded = false;						
			},
			// * GET ITEMS
			async getItems() {
				console.log('getItems running...')
				// console.log('this.index = ' + this.index)
				// console.log('this.computedSearchQuery = ' + this.computedSearchQuery)
				// console.log('this.fields = ' + this.fields)
				let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
				// console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2));
				if (itemsResult.code === 1) {
					this.itemsTotal = itemsResult.data.total;
					this.items = itemsResult.data.results;
					let itemsPerPage = this.datatableOptions.itemsPerPage
					if (itemsPerPage === -1) { 
						itemsPerPage = this.itemsTotal
					} else {
						itemsPerPage = this.datatableOptions.itemsPerPage
					}
					let itemsTo = this.datatableOptions.page * itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
				} else {
					//console.log("items = " + JSON.stringify(itemsResult, null, 2));
				}
                //console.log("items = " + JSON.stringify(this.items, null, 2))
			},
			refreshCols() {
				switch (true) {
					// Expanded List with No Task Bar
					case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
						this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// Expanded List with Task Bar
					case this.listExpanded && !this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
						this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					// List with Product Page (No Task Bar)
					case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// List with Product Page and Task Bar
					case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
						this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					// Shrunk List with Product Page (No Task Bar)
					case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						this.itemCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// Shrunk List with Product Page and Task Bar)
					case !this.listExpanded && this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;

					default:
						this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
						this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
				}
			},
			exportCSV() {
				// console.log('exportCSV')
				let csv = this.$papa.unparse(this.items);
				this.$papa.download(csv, 'My title')
				//console.log('uparse ' + JSON.stringify(csv, null, 2))
			}
		},
		watch: {
			// If the search query changes then get updated dataset based on search query
			computedSearchQuery() {
				this.getItems();
			},
			toggleDeletedItems() {
				this.getItems();
			},
			showImages() {
				let imagesIndex = this.headers.findIndex((x) => x.value === "fabricMainImage");
				if (this.showImages) {
					this.headers[imagesIndex].hidden = false;
				} else {
					this.headers[imagesIndex].hidden = true;
				}
			},
			datatableOptions: {
				handler() {
					let itemsPerPage = this.datatableOptions.itemsPerPage
					if (itemsPerPage === -1) { 
						itemsPerPage = this.itemsTotal
					} else {
						itemsPerPage = this.datatableOptions.itemsPerPage
					}
					this.itemsFrom = this.datatableOptions.page * itemsPerPage - itemsPerPage;
					let itemsTo = this.datatableOptions.page * itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
					//console.log('organisationFrom=' + this.organisationFrom)
					this.getItems();
				},
				deep: true,
			},
			listExpanded() {
				this.refreshCols();
			},
			listShrunk() {
				this.refreshCols();
			},
			GET_taskBar() {
				this.refreshCols();
			},
			computedFields: {
				handler() {
					this.getItems();
				},deep: true
			},
		},
		async created() {
			this.getItems();
		},
		mounted() {
			this.refreshCols();
		},
	};
</script>
