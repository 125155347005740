<template>
  <v-card :class="actionLead === true ? '' : '' " flat outlined>
    <v-col cols="12" class="align-start">
      <v-row class="pa-2 pb-0">
        <v-col cols="12" class="d-flex ma-0 pa-0 pl-1 align-start">
          <!-- @click="openLead" -->
          <div>
            <!-- <pre>{{ lead }}</pre> -->
            <div v-if="actionLead == false || orgIsGroup"
                 class="font-weight-bold text-start body-2 text-truncate primary--text"
                 style="max-width: 200px !important">
              {{ lead.orgName }}
            </div>
            <div v-if="lead.salesTitle" class="font-weight-bold text-start body-2 text-truncate grey--text"
                 style="max-width: 200px !important">
              {{ lead.salesTitle }}
            </div>
            <div v-if="lead.salesTitle" class="font-weight-regular text-start body-2 text-truncate grey--text"
                 style="max-width: 230px !important">
              {{ lead.salesDescription }}
            </div>
          </div>
          <v-spacer/>
          <div>
            <v-avatar v-if="lead.ownerUserName" color="caption primary white--text font-weight-regular" size="30">
              {{ lead.ownerUserName.match(/\b(\w)/g).join('') }}
            </v-avatar>
          </div>
        </v-col>
      </v-row>

      <v-row class="pa-2 pt-0" :class="reveal ? 'pb-2' : ''">
        <v-col cols="12" class="d-flex ma-0 pa-0 pr-3 justify-end align-center">
          <!-- <div> -->
          <div v-if="lead.salesPackages !== '' && lead.salesPackages !== undefined && lead.salesPackages !== null">
            <div class="d-flex align-center">
              <div v-for="item in lead.salesPackages.split(',')" :key="item">
                <PackageIcon :packagedata="getPackageDetails(item)"/>
              </div>
            </div>
          </div>
          <v-spacer/>
          <div v-if="lead.salesType"
               class="font-weight-regular body-2 pr-3 text-start text-uppercase"
               :class="lead.salesType === 'hot' ? 'error--text' : lead.salesType === 'warm' ? 'warning--text' : lead.salesType === 'cold' ? 'info--text' : 'grey--text'"
          >
            {{ lead.salesType }}
          </div>
          <!-- <v-spacer /> -->
          <div>
            <v-icon class="grey--text" size="15" @click="reveal = !reveal">icons8-expand-arrow</v-icon>
          </div>
          <!-- </div> -->
        </v-col>
      </v-row>

      <v-card v-if="reveal === true" class="mt-3 elevation-0" style="height: 100% !important; width: 100% !important">
        <v-divider/>
        <table class="ma-0 pa-0 mt-3 caption grey--text text--darken-2 " border="0" width="100%">
          <!-- <tr>
              <td class="font-weight-bold" width="40%" align="left">Exp Close:</td>
              <td class="" width="60%" align="left">{{ MIX_formatDateTime(lead.salesEstimatedCloseDate, 'X', 'MMM-YY') }}</td>
          </tr> -->
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Owner:</td>
            <td class="" width="60%" align="left">{{ lead.ownerUserName }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Latest Activity:</td>
            <td class="" width="60%" align="left"><a @click="openActivity">{{ latestActivity.actionSubject }}</a></td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Activity Type:</td>
            <td class="" width="60%" align="left">{{ latestActivity.actionType }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Latest Action:</td>
            <td class="" width="60%" align="left"><a @click="openAction">{{ latestAction.actionSubject }}</a></td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Action Type:</td>
            <td class="" width="60%" align="left">{{ latestAction.actionType }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Action Due:</td>
            <td class="" width="60%" align="left">{{ MIX_formatDateTime(latestAction.actionDate, 'X', 'DD-MMM-YYYY') }}</td>
          </tr>
        </table>

        <v-row class="d-flex justify-start pt-3" dense style="">
          <v-col v-if="actionLead == false" cols="3" class="">
            <v-btn icon depressed @click="openLead" class="caption font-weight-bold primary--text" title="Account"><v-icon>icons8-hotel-building</v-icon></v-btn>
          </v-col>
          <v-col v-if="actionLead == false" cols="3" class="">
            <v-btn icon depressed @click="activityDialog = true" class="caption font-weight-bold primary--text" title="Log Activity"><v-icon>icons8-tear-off-calendar</v-icon></v-btn>
          </v-col>
          <v-col v-if="actionLead == false" cols="3" class="">
            <v-btn icon depressed @click="actionDialog = true" class="caption font-weight-bold primary--text" title="Create Action"><v-icon>icons8-day-off</v-icon></v-btn>
          </v-col>
          <v-col cols="3" class="">
            <v-btn icon depressed @click="openLeadItem" class="caption font-weight-bold primary--text" title="Edit"><v-icon>icons8-edit</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

<!--activity / action Dialog-->
    <NewActionDialog :activityDialog="actionDialog" v-on:closeDialog="closeDialog" v-on:refreshItems="$emit('refreshItems')" :orgId="lead.orgId"></NewActionDialog>

    <NewActivityDialog :activityDialog="activityDialog" v-on:closeDialog="closeDialog" v-on:refreshItems="$emit('refreshItems')" :orgId="lead.orgId"></NewActivityDialog>
  </v-card>
</template>

<script>
import NewActionDialog from "@/views/sales/NewActionDialog.vue";
import NewActivityDialog from "@/views/sales/NewActivityDialog.vue";

export default {
  components: {NewActivityDialog, NewActionDialog},
  data: () => ({
    lead: {},
    reveal: false,
    orgType: '',
    latestActivity: {
      actionSubject: 'N/A',
      actionType: 'N/A',
    },
    latestAction: {
      actionSubject: 'N/A',
      actionType: 'N/A',
    },
    actionDialog: false,
    activityDialog: false,
  }),
  props: {
    datadown: {
      type: Object,
      default: () => ({})
    },
    actionLead: {
      type: Boolean,
      default: false,
    },
    orgIsGroup: {
      type: Boolean,
      default: false,
    }
  },
  computed: {},
  methods: {
    openLead() {
      // ////console.log('openLead');
      this.$emit('openlead', this.lead);
    },
    openLeadItem() {
      this.$emit('openleaditem', this.lead);
    },
    openActivity(){
      this.MIX_go('/customers/' + this.lead.orgId+'?tab=activities');
    },
    openAction(){
      this.MIX_go('/customers/' + this.lead.orgId+'?tab=actions');
    },
    async getLatestActivity() {
      const activityFields = '@actionType,@actionSubject,@actionDescription,@actionStatus,@actionPriority,@actionDate,@actionCompletedDateTime,@actionStageId,@actionStageName,@actionCompleted,@orgId,@orgName,@actionEndDate,@actionTags,@createdDateTime,@ownerUserName,@action,@id,@deleted,@actionId'
      let itemsResult = await this.MIX_redisAggregateSearch('idx:actions', `@actionCompleted:"1" @orgId:${this.lead.orgId}`, '0', '1', activityFields, '@createdDateTime', true);
      if (itemsResult.code === 1) {
        if(itemsResult.data.results[0] !== undefined){
          this.latestActivity = itemsResult.data.results[0];
        }else{
          this.latestActivity = {
            actionSubject: 'N/A',
            actionType: 'N/A',
          }
        }
      } else {
        // console.log(err)
      }
    },
    async getLatestAction() {
      const activityFields = '@actionType,@actionSubject,@actionDescription,@actionStatus,@actionPriority,@actionDate,@actionCompletedDateTime,@actionStageId,@actionStageName,@actionCompleted,@orgId,@orgName,@actionEndDate,@actionTags,@createdDateTime,@ownerUserName,@action,@id,@deleted,@actionId'
      let itemsResult = await this.MIX_redisAggregateSearch('idx:actions', `@actionCompleted:"0" @orgId:${this.lead.orgId}`, '0', '1', activityFields, '@createdDateTime', true);
      if (itemsResult.code === 1) {
        if(itemsResult.data.results[0] !== undefined){
          this.latestAction = itemsResult.data.results[0];
        }else{
          this.latestAction = {
            actionSubject: 'N/A',
            actionType: 'N/A',
          }
        }
      } else {
        // console.log(err)
      }
    },
    closeDialog(){
      this.activityDialog = false
      this.actionDialog = false
      this.addingAction = false
      this.newAction = {}
      this.newAction = {...this.schema}
    }
  },
  async created() {
    var t = this
    this.lead = {...this.datadown};
    await this.getLatestActivity();
    await this.getLatestAction();
    let itemResult = await this.MIX_readItem(t.lead.orgId, 'org');
    if (itemResult.code === 1) {
      // this.$emit('edit', itemResult.data);
      // // ////console.log("result = " + JSON.stringify(itemResult.data))
      t.orgType = itemResult.data.orgAccountTypes
    }
    // // ////console.log("lead = " + JSON.stringify(this.lead, null, 2));
  },

}
</script>

<style scoped>
.borderClass {
  border-left: 5px solid #019EAF !important;
  border-color: #019EAF !important
}
</style>
