<template>
    <v-row fill-height class="grey lighten-2" style="height: 100% !important; overflow-y: scroll !important" no-gutters>
        <v-col class="align-center pa-3 mt-3" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            <div class="d-flex align-center">
                <div><v-icon class="grey--text" x-large>icons8-sewing-patch</v-icon></div>
                <div class="text-left headingtext--text text-h4 font-weight-light ml-1" v-if="!listShrunk">Options</div>
                <v-spacer v-if="!listShrunk"></v-spacer>
                <div class="ml-1">
                    <v-btn depressed block class="accent secondary--text" @click="openItem({})"><v-icon class="mr-3">icons8-sewing-patch</v-icon>New</v-btn>
                </div>
                <v-spacer v-if="listShrunk"></v-spacer>
                <div>
					<!-- <v-btn class="primary--text ml-2" @click="listShrunk = false" depressed v-if="listShrunk">
						<v-icon color="primary">icons8-show-sidepanel</v-icon>
					</v-btn>
					<v-btn class="primary--text ml-2" @click="listShrunk = true" depressed v-if="!listExpanded && !listShrunk">
						<v-icon color="primary">icons8-hide-sidepanel</v-icon>
					</v-btn> -->
					<!-- <v-btn class="primary--text ml-2" depressed v-if="!listShrunk && ['Developer-Admin'].includes(userLevel)" @click="dialogImport = true">
						<v-icon color="primary">icons8-import-csv</v-icon>
					</v-btn>
					<v-btn disabled class="primary--text ml-2" depressed v-if="!listShrunk && ['Developer-Admin'].includes(userLevel)">
						<v-icon color="primary">icons8-export-csv</v-icon>
					</v-btn> -->
                    <v-btn class="primary--text ml-1" depressed @click="getItems()">
                        <v-icon color="primary">icons8-available-updates</v-icon>
                    </v-btn>
                    <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn depressed class="primary--text ml-1" v-bind="attrs" v-on="on" v-if="!listShrunk"><v-icon class="" color="primary">icons8-table-properties</v-icon></v-btn>
                        </template>
                        <v-card class="pa-5" width="250">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </v-card>
                    </v-menu>
                </div>
              <!--    EXPORT CSV-->
              <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-1">
                <v-btn depressed class="primary--text ml-1" v-if="!listShrunk" @click="exportBox = true">
                  <v-icon>icons8-export-csv</v-icon>
                </v-btn>
              </div>
              <ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
                <template v-slot:header>
                  <div class="full-width d-flex align-center">
                    <div>Export CSV</div>
                    <v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
                    <v-spacer />
                    <v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-cancel</v-icon></v-btn>
                  </div>
                </template>
                <p>Please select the data you wish to export:</p>
                <table width="100%" cellspacing="5">
                  <tr>
                    <td width="100"><strong>Current page:</strong></td>
                    <td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
                    <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
                  </tr>
                  <tr>
                    <td><strong>Current total:</strong></td>
                    <td>{{ itemsTotal }} items (filtered)</td>
                    <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
                  </tr>
                  <tr>
                    <td><strong>All Items:</strong></td>
                    <td>{{ totalCount }} items (without filter)</td>
                    <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
                  </tr>
                  <tr>
                    <td valign="top"><strong>Specific:</strong></td>
                    <td>
                      <div class="d-flex align-center">
                        <div>From</div>
                        <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
                        <div>To</div>
                        <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
                      </div>
                      <div class="ml-n7">
                        <CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
                      </div>
                    </td>
                    <td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
                  </tr>
                </table>
              </ExportBox>
              <!--      EXPORT END-->
            </div>
            <div class="flex-grow-1 my-3">
                <div><v-text-field label="Search Options" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field></div>
            </div>
            <!-- TABLE BASED LIST -->
            <v-data-table v-if="!listShrunk" sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions">
                <template v-slot:top>
                    <div class="d-flex align-center">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn value="destroy" small v-if="['Developer-Admin'].includes(userLevel)">
                                <v-icon class="error--text">icons8-delete-trash</v-icon>
                            </v-btn>
                            <v-btn value="delete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                            </v-btn>
                            <v-btn value="undelete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
                                <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:item.enabled="{ item }">
                    <span v-if="item.enabled === '1'">Yes</span>
                    <span v-if="item.enabled === '0'">No</span>
                </template>
                <template v-slot:item.optionVariant="{ item }">
                    <span v-if="item.optionVariant === '1'">Yes</span>
                    <span v-if="item.optionVariant === '0'">No</span>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon class="error--text pa-1" v-if="toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">icons8-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1'">icons8-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
                </template>
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listShrunk" style="height: 67vh; overflow-y: scroll" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-2 font-weight-bold" style="width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.attributeName }}</div>
                            Code: <span class="body-2">{{ item.attributeCode }}</span>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text" @click="openItem(item)">icons8-forward</v-icon>
                        </div>
                    </div>
                </v-card>
            </div>
            <!-- <pre>{{ items }}</pre> -->
            <!-- App Copyright -->
            <appCopyright />
        </v-col>
        <transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col style="border-left: lightgrey solid 2px !important" v-if="!listExpanded" class="grey lighten-3" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <option-item v-if="!listExpanded" v-on:refreshItems="getItems" :activeitem="activeItem" v-on:closeItem="listExpanded = true" />
            </v-col>
        </transition>
        <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col style="border-left: lightgrey solid 2px !important" class="" v-if="GET_taskBar" :cols="taskCols.cols" :xs="taskCols.xs" :sm="taskCols.sm" :md="taskCols.md" :lg="taskCols.lg" :xl="taskCols.xl">
                <taskbar />
            </v-col>
        </transition>
        <v-dialog v-model="dialogImport" fullscreen>
            <v-card>
                <import-csv v-on:close="dialogImport = false" :csvheaders="csvHeaders" :prefix="key" rediskey="option" />
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import schema from '@/schema.js';
import importCSV from '@/views/base/importCSV.vue';
import taskBar from '@/views/tasks/taskbar.vue';
import option from '@/views/options/option.vue';
import { mapGetters } from 'vuex';
import ExportBox from "@/components/general/ExportBox.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
import TextField from "@/components/form/TextField.vue";
import AppButton from "@/components/general/AppButton.vue";
export default {
    components: {
      AppButton, TextField, CheckboxField, ExportBox,
        'option-item': option,
        taskbar: taskBar,
        'import-csv': importCSV,
    },
    data: () => ({
        key: 'option',
        index: 'idx:options',
        dialog: false,
        dialogImport: false,
        //csvHeaders: ["id", "optionName", "optionType", "optionVariant", "optionSupplier", "enabled", "key"],
        toggleFeatures: [],
        showImages: false,
        items: [],
        schema: {},
        itemsTotal: null,
        listExpanded: true,
        listShrunk: false,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 10,
        activeItem: {},
        // Headers to display in the Data Table
        headers: [
            { text: 'Id', value: 'id', hidden: true },
            { text: 'Name', value: 'optionName', hidden: false, expanded: false },
            { text: 'Type', value: 'optionType', hidden: false, expanded: false },
            { text: 'Variant', value: 'optionVariant', hidden: false, expanded: false },
            { text: 'Supplier', value: 'optionSupplier', hidden: true, expanded: false },
            { text: 'Org ID', value: 'orgId', sortable: false, hidden: true, expanded: false },
            { text: 'Org Name', value: 'orgName', sortable: false, hidden: false, expanded: false },
            { text: 'Enabled', value: 'enabled', hidden: true, expanded: false },
            { text: 'Key', value: 'key', hidden: true, expanded: false },
            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: false },
            { text: 'Created ID', value: 'createdUserId', sortable: false, hidden: true, expanded: false },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: false },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: false },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: false },
            { text: 'Modified ID', value: 'modifiedUserId', sortable: false, hidden: true, expanded: false },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: false },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: false },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: false },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: false },
            { text: 'Deleted ID', value: 'deletedUserId', sortable: false, hidden: true, expanded: false },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: false },
            { text: 'Deleted User', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: false },
            { text: 'Action', value: 'action', align: 'end', hidden: false, expanded: false, width: '155px' },
        ],
        // Fields to Retrieve from Database
        fields: '@id,@optionName,@optionType,@optionVariant,@optionSupplier,@enabled,@key,@deleted',
        sortBy: '@optionName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['optionName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        itemCols: { cols: 12, xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
        taskCols: { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
      //EXPORT DATA
      exportBox: false,
      exportItems: {
        itemsFrom: "1",
        itemsTo: "10",
      },
      exportLoading: false,
      exportFiltered: false,
      showInfo: false,
      totalCount: 0,
    }),
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleDeletedItems) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
                return searchQuery;
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}*`;
                        return searchQuery;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0`;
                        return searchQuery;
                    }
                } else {
                    if (this.toggleDeletedItems) {
                        searchQuery = `${searchQuery}* `;
                        return searchQuery;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0`;
                        return searchQuery;
                    }
                }
            }
        },
        computedHeaders() {
            return this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
        },
        computedFields() {
            // console.log('computedHeaders = ' + JSON.stringify(this.computedHeaders))
            let computedFields = this.computedHeaders.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            if (!computedFields.includes('@enabled')) {
                computedFields.push('@enabled');
            }
            //if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        csvHeaders() {
            let schema = [];
            for (const property in this.schema) {
                schema.push(property);
            }
            return schema;
        },
    },
    methods: {
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.activeItem = item;
            this.listExpanded = false;
        },
        // * GET ITEMS
        async getItems() {
            console.log('computedSearchQuery = ' + this.computedSearchQuery);
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsPerPage = this.datatableOptions.itemsPerPage;
                if (itemsPerPage === -1) {
                    itemsPerPage = this.itemsTotal;
                } else {
                    itemsPerPage = this.datatableOptions.itemsPerPage;
                }
                let itemsTo = this.datatableOptions.page * itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                //console.log("items = " + JSON.stringify(itemsResult, null, 2));
            }
            //console.log("items = " + JSON.stringify(this.items, null, 2))
        },
        refreshCols() {
            switch (true) {
                // Expanded List with No Task Bar
                case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Expanded List with Task Bar
                case this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // List with Product Page (No Task Bar)
                case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // List with Product Page and Task Bar
                case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // Shrunk List with Product Page (No Task Bar)
                case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    this.itemCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Shrunk List with Product Page and Task Bar)
                case !this.listExpanded && this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;

                default:
                    this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
            }
        },
      // Export Box CSV
      async exportCSV(itemsFrom, itemsTo, filter, specific) {
        // console.table({ itemsFrom, itemsTo, filter, specific });
        if (itemsFrom > itemsTo) {
          this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
        } else {
          this.exportLoading = true;
          var itemsCount, tableData, csv, query, exportFileName;
          switch (true) {
              // Current Page / Current Total (filtered)
            case filter && !specific:
              itemsCount = itemsTo - itemsFrom;
              query = this.computedSearchQuery;
              break;
              // All (Not filtered)
            case !filter && !specific:
              itemsCount = this.totalCount;
              query = `*`;
              break;
              // Specific (filtered/ not filtered)
            case specific:
              itemsFrom = itemsFrom - 1;
              itemsCount = itemsTo - itemsFrom;
              if (this.exportFiltered) {
                query = this.computedSearchQuery;
              } else {
                query = `*`;
              }
              break;
          }
          // console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
          // let exportResult = await this.REDIS_searchFor("animal", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
          let exportResult = await this.MIX_redisAggregateSearch(this.index, query, itemsFrom, itemsCount, this.computedFields, this.sortBy, false);
          // console.log(exportResult)
          tableData = JSON.parse(JSON.stringify(exportResult.data.results));
          // console.table(tableData)
          csv = this.$papa.unparse(tableData);
          if (itemsFrom === 0) {
            itemsFrom = 1; // This is just for filename purposes
          }
          if (itemsFrom >= 10) {
            itemsFrom = itemsFrom + 1;
          }
          switch (true) {
            case !specific && filter:
              exportFileName = `People_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case !specific && !filter:
              exportFileName = `People_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && this.exportFiltered:
              exportFileName = `People_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && !this.exportFiltered:
              exportFileName = `People_Specific_${itemsFrom}_to_${itemsTo}`;
              break;
          }
          // console.log(exportFileName);
          // console.log(csv.length);
          this.$papa.download(csv, exportFileName);
          this.exportLoading = false;
        }
      },
      async getTotal(){
        let totalResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
        // console.log(totalResult)
        this.totalCount = totalResult.data.total;
      },
    },
    watch: {
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'fabricMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                let itemsPerPage = this.datatableOptions.itemsPerPage;
                if (itemsPerPage === -1) {
                    itemsPerPage = this.itemsTotal;
                } else {
                    itemsPerPage = this.datatableOptions.itemsPerPage;
                }
                this.itemsFrom = this.datatableOptions.page * itemsPerPage - itemsPerPage;
                let itemsTo = this.datatableOptions.page * itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
        listExpanded() {
            this.refreshCols();
        },
        listShrunk() {
            this.refreshCols();
        },
        GET_taskBar() {
            this.refreshCols();
        },
    },
    async created() {
        this.schema = schema.option;
        this.getTotal();
        this.getItems();
    },
    mounted() {
        this.refreshCols();
    },
};
</script>
