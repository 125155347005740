// import {mapGetters, mapActions} from 'vuex';
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
const mixin = {
	data: () => ({
		API_URL: process.env.VUE_APP_MOFSERVER,
	}),
	computed: {
		...mapGetters({
			GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
		}),
	},
	methods: {
		...mapActions({
			ACT_alertBox: "ACT_alertBox",
			ACT_puDialog: 'ACT_puDialog'
		}),
		//GET VERSION
		MIX_getVersion() {
			return require('/package.json')?.version;
		},
		// * CHANGE ROUTE FUNCTION
		MIX_go(path) {
			this.$router.push(path).catch((err) => {
				console.log("Route error: " + err);
			});
		},
		// * CHANGE ROUTE FUNCTION
		MIX_goExternal(path) {
			window.open(path, '_blank').focus();
		},

		// * FORMAT DATE TIME
		MIX_formatDateTime: function (date, formatIn, formatOut) {
			if (date !== "" && date !== null && date !== undefined && date != 0) {
				return moment(date, formatIn).format(formatOut);
			} else {
				return moment().format(formatOut);
			}
		},
		MIX_addFormatDateTime: function(data, formatIn, formatOut, timeValue, timeUnit) {
			if (data !== "" && data !== null && data !== undefined && data != 0) {
				return moment(data, formatIn).add(timeValue, timeUnit).format(formatOut);
			} else {
				return moment().add(timeValue, timeUnit).format(formatOut);
			}
		},
		MIX_fromNow: function (date, formatIn, ago) {
			return moment(date, formatIn).fromNow(ago);
		},
		MIX_alertBox: function (payload) {
			this.ACT_alertBox(payload);
		}, // alertBox Mix

		MIX_puDialog(show, dynamicComponent, width, height) {
			var payload = {};
			payload.show = show;
			payload.dynamicComponent = dynamicComponent;
			payload.width = width;
			payload.height = height;
			this.ACT_puDialog(payload);
		},
		MIX_updateApp: function () {
			alert("Update App");
		},
		MIX_generateId() {
			let generatedId = "";
			const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Alphanumeric characters
			for (let i = 0; i < 20; i++) {
				generatedId += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return generatedId;
		},

		/// TEMPORARY DATA
		MIX_randomProductGenerator: async function () {
			return await this.$axios.get("https://random-data-api.com/api/commerce/random_commerce").then(function (response) {
				return response.data;
			});
			//return result.data;
		},
		MIX_organisations: async function (type) {
			var result
			if ((type !== undefined) && (type !== null) && (type !== "")) {
				result = await this.MIX_redisSearch("idx:orgs", `@deleted:0 @enabled:1 @orgAccountTypes:{${type}}`, 0, 9999);
			} else {
				result = await this.MIX_redisSearch("idx:orgs", `@deleted:0 @enabled:1`, 0, 9999);
			}
			if (result.code === 1) {
				return result.data.documents.map((x) => {
					return {
						id: x.value.id,
						orgName: x.value.orgName,
						orgApproved: x.value.orgApproved
					};
				});
			}
		},
		MIX_contacts: async function (orgId) {
			var result
			//console.log('MIX_contacts -> orgId : ', orgId);
			if ((orgId !== undefined) && (orgId !== null) && (orgId !== "")) {
				result = await this.MIX_redisSearch("idx:contacts", `@deleted:0 @orgId:{${orgId}}`, 0, 9999); // TODO - RG - Need to add @enabled to schema
			} else {
				result = await this.MIX_redisSearch("idx:contacts", `@deleted:0`, 0, 9999);
			}
			if (result.code === 1) {
				return result.data.documents.map((x) => {
					return {
						id: x.value.id,
						contactName: x.value.contactFirstName + " " + x.value.contactLastName,
						contactEmail: x.value.contactEmail
					};
				});
			}
		},
		// Users from Cache
		MIX_users: async function (userGroup) {
			var result
			//console.log('MIX_contacts -> userGroup : ', userGroup);
			if ((userGroup !== undefined) && (userGroup !== null) && (userGroup !== "")) {
				result = await this.MIX_redisSearch("idx:users", `@deleted:0 @userGroup:${userGroup}`, 0, 9999); // TODO - RG - Need to add @enabled to schema
			} else {
				result = await this.MIX_redisSearch("idx:users", `@deleted:0`, 0, 9999);
			}
			//console.log('result = ', JSON.stringify(result, null, 2));
			if (result.code === 1) {
				return result.data.documents.map(x => x.value )
			}
		},
		// Get Current Owner - used to populated current owner on new forms
		MIX_getCurrentOwner: function () {
				return {
					ownerUserId: this.GET_FIREBASE_userAuth.uid,
					ownerUserName: this.GET_FIREBASE_userAuth.displayName,
					ownerUserEmail: this.GET_FIREBASE_userAuth.email
				}
		},
		// CRUD Actions
		// Save Item
		MIX_createItem: async function (item, itemType, newItem) {
			// console.log('MIX_createItem');
			let t = this;
			try {
				let dateTimeNow = t.$moment().format("x");
				if (newItem) {
					item.createdDateTime = dateTimeNow;
					item.createdUserId = t.GET_FIREBASE_userAuth.uid;
					item.createdUserName = t.GET_FIREBASE_userAuth.displayName;
					item.createdUserEmail = t.GET_FIREBASE_userAuth.email;
				}
				item.modifiedDateTime = dateTimeNow;
				item.modifiedUserId = t.GET_FIREBASE_userAuth.uid;
				item.modifiedUserName = t.GET_FIREBASE_userAuth.displayName;
				item.modifiedUserEmail = t.GET_FIREBASE_userAuth.email;
				// console.log(`${itemType}:${item.id}`)
				if(item.id === '' || item.id === undefined || item.id === null) {
					item.id = t.MIX_generateId();
				}
				console.log('t.item = ' + JSON.stringify(item, null, 2))
				let result = await t.MIX_redisCreateHash(`${itemType}:${item.id}`, item);
				// console.log('result = ' + JSON.stringify(result, null, 2))
				if (result.code === 1) {
					//t.$emit("refreshItems");
					return result;
				} else {
					console.log("error: " + result.error);
					return result;
				}
			} catch (error) {
				console.log(error);
			}
		},
		// Read Item
		MIX_readItem: async function (itemId, key) {
           // console.log(key + ':' + itemId)
			let readItemResult = await this.MIX_redisReadHash(`${key}:${itemId}`);
			// console.log(JSON.stringify(readItemResult, null, 2));
			if (readItemResult.code === 1) {
				return readItemResult;
			} else {
				this.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Reading Item", show: true });
			}
		},
		// Destroy Item
		MIX_destroyItem: async function (itemId, itemType) {
			let t = this;
			try {
				let result = await this.MIX_redisDestroyHash(`${itemType}:${itemId}`);
				if (result.code === 1) {
					this.MIX_alertBox({ color: "success", message: "Permenantly Deleted", timeout: 2000, show: true });
					t.$emit("refreshItems");
					return result;
				} else {
					this.MIX_alertBox({ color: "error", message: "Error Deleting", timeout: 4000, show: true });
					// console.log("error: " + result.error);
				}
			} catch (error) {
				t.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Deleting", show: true });
				// console.log(error);
			}
		},
		// Delete Item
		MIX_deleteItem: async function (itemId, key) {
			let t = this;
			try {
				let fields = {};
				let dateTimeNow = t.$moment().format("x");
				fields.deleted = "1";
				fields.modifiedDateTime = dateTimeNow;
				fields.modifiedUserId = t.GET_FIREBASE_userAuth.uid;
				fields.modifiedUserName = t.GET_FIREBASE_userAuth.displayName;
				fields.modifiedUserEmail = t.GET_FIREBASE_userAuth.email;
				fields.deletedDateTime = dateTimeNow;
				fields.deletedUserId = t.GET_FIREBASE_userAuth.uid;
				fields.deletedUserName = t.GET_FIREBASE_userAuth.displayName;
				fields.deletedUserEmail = t.GET_FIREBASE_userAuth.email;
                if (key === 'quote') {
                    fields.quoteStatus = 'Deleted'
                }
				let result = await this.MIX_redisUpdateHash(`${key}:${itemId}`, fields);
				if (result.code === 1) {
					this.MIX_alertBox({ color: "success", message: "Deleted", timeout: 2000, show: true });
					t.$emit("refreshItems");
					return result;
				} else {
					this.MIX_alertBox({ color: "error", message: "Error Deleting", timeout: 4000, show: true });
					console.log("error: " + result.error);
				}
			} catch (error) {
				t.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Deleting", show: true });
				console.log(error);
			}
		},
		// Restore Item
		MIX_restoreItem: async function (itemId, key) {
			let t = this;
			try {
				let fields = {};
				let dateTimeNow = t.$moment().format("x");
				fields.deleted = "0";
				fields.modifiedDateTime = dateTimeNow;
				fields.modifiedUserId = t.GET_FIREBASE_userAuth.uid;
				fields.modifiedUserName = t.GET_FIREBASE_userAuth.displayName;
				fields.modifiedUserEmail = t.GET_FIREBASE_userAuth.email;
				fields.deletedDateTime = "";
				fields.deletedUserId = "";
				fields.deletedUserName = "";
				fields.deletedUserEmail = "";
				let result = await this.MIX_redisUpdateHash(`${key}:${itemId}`, fields);
				if (result.code === 1) {
					this.MIX_alertBox({ color: "success", message: "Item has been restored", timeout: 2000, show: true });
					t.$emit("refreshItems");
					return result;
				} else {
					this.MIX_alertBox({ color: "error", message: "Error restoring item", timeout: 4000, show: true });
					console.log("error: " + result.error);
				}
			} catch (error) {
				this.MIX_alertBox({ color: "error", message: "Error restoring item", timeout: 4000, show: true });
				console.log(error);
			}
		},
		MIX_generatePDF: async function (url) {
			return await fetch(this.API_URL + '/createPdf', {
				method: 'POST',
				body: JSON.stringify({ url: url }),
			}).then(response => {
				return response
			})
		},
		MIX_sendEmail: async function (email) {
			return await fetch(this.API_URL + `/sendEmail?to=${email.to}`, {
				method: 'POST',
				body: JSON.stringify({ subject: email.subject, body: email.body, attachment: email.attachment }),
			}).then(response => {
				return response
			})
		},
		MIX_googlePlacesConversion (placeResultData) {
			placeResultData = JSON.parse(placeResultData);
			// console.log('placeResultData', JSON.stringify(placeResultData, null, 2));
			let result = {}
            result.name = placeResultData.name;
            let premise = placeResultData.address_components.filter((component) => component.types.includes('premise'));
            let street_number = placeResultData.address_components.filter((component) => component.types.includes('street_number'));
            let route = placeResultData.address_components.filter((component) => component.types.includes('route'));
            let sublocality_level_1 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_1'));
            let sublocality_level_2 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_2'));
            let locality = placeResultData.address_components.filter((component) => component.types.includes('locality'));
            let administrative_area_level_2 = placeResultData.address_components.filter((component) => component.types.includes('administrative_area_level_2'));
            let country = placeResultData.address_components.filter((component) => component.types.includes('country'));
            let postal_code = placeResultData.address_components.filter((component) => component.types.includes('postal_code'));
            let postal_town = placeResultData.address_components.filter((component) => component.types.includes('postal_town'));
            let formatted_phone_number = placeResultData.formatted_phone_number;

            var addressLine1 = '';
            var addressLine2 = '';
            // Address Line 1
            if (JSON.stringify(premise) !== '[]') {
                addressLine1 = premise[0]['long_name'];
            }
            if (JSON.stringify(street_number) !== '[]') {
                addressLine1 = addressLine1 + ' ' + street_number[0]['long_name'];
            }
            if (JSON.stringify(route) !== '[]') {
                addressLine1 = addressLine1 + ' ' + route[0]['long_name'];
            }
            if (addressLine1 !== null && addressLine1 !== undefined && addressLine1 !== '') {
                result.addressLine1 = addressLine1.trim();
            } else {
                result.addressLine1 = placeResultData.formatted_address.split(',')[0];
            }
            // Address Line 2
            if (JSON.stringify(sublocality_level_1) !== '[]') {
                addressLine2 = sublocality_level_1[0]['long_name'];
            }
            if (JSON.stringify(sublocality_level_2) !== '[]') {
                addressLine2 = addressLine2 + ' ' + sublocality_level_2[0]['long_name'];
            }
            if (JSON.stringify(locality) !== '[]') {
                addressLine2 = addressLine2 + ' ' + locality[0]['long_name'];
            }
            if (addressLine2 !== null && addressLine2 !== undefined && addressLine2 !== '') {
                result.addressLine2 = addressLine2.trim();
            }
            // Address Line 3
            result.AddressLine3 = '';
            // Check for Postal Town
            if (JSON.stringify(postal_town) !== '[]') {
                result.town = postal_town[0]['long_name'];
            }
            // Check for County
            if (JSON.stringify(administrative_area_level_2) !== '[]') {
                result.county = administrative_area_level_2[0]['long_name'];
            }
            // Check for Country
            if (JSON.stringify(country) !== '[]') {
                result.country = country[0]['long_name'];
            }
            // Check for postcode
            if (JSON.stringify(postal_code) !== '[]') {
                result.postcode = postal_code[0]['long_name'];
            }
            // Add LAttitude and Longitude
			// console.log('lat = ', placeResultData.geometry);
            result.lat = placeResultData.geometry.location.lat;
            result.lng = placeResultData.geometry.location.lng;
            // Check for Phone Number
            if (formatted_phone_number !== null && formatted_phone_number !== undefined && formatted_phone_number !== '') {
                result.elephone = formatted_phone_number.replace(/\s/g, '');
            }
            // Add Website
            result.website = placeResultData.website;
			return result;
		},
		// * Create Quote Revision
		async MIX_quoteRevision(key) {
			let t = this;
			try {
				return t.$axios.get(t.apiUrl + `/quoteRevision/?key=${key}`)
					.then(response => {
						return response.data
					})
			} catch (error) {
				return { code: -1, mesage: "Error: Could not Create Quote Revision", data: null, error: error };
			}	
		},
		// * Copy Quote
		async MIX_quoteCopy(key) {
			let t = this;
			try {
				return t.$axios.get(t.apiUrl + `/quoteCopy/?key=${key}`)
					.then(response => {
						return response.data
					})
			} catch (error) {
				return { code: -1, mesage: "Error: Could not Copy Quote", data: null, error: error };
			}	
		}	,
		//convert quote to order
		// * Copy Quote
		async MIX_quoteToOrder(key) {
			let t = this;
			try {
				return t.$axios.get(t.apiUrl + `/quoteToOrder/?key=${key}`)
					.then(response => {
						return response.data
					})
			} catch (error) {
				return { code: -1, mesage: "Error: Could not Convert Quote", data: null, error: error };
			}
		}

},
};

export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
