<template>
    <v-container fluid fill-height class="d-flex pa-0 ma-0 primary">
        <v-col cols="3" class="primary d-flex align-center">
            <v-img contain class="mb-8" max-height="120" :src="require('@/assets/logo.png')"></v-img>
        </v-col>
        <v-col cols="9" class="white d-flex align-self-stretch justify-center">
            <v-card width=550 class="pa-8 rounded-sm elevation-0 align-self-center">
                <v-card-title class="d-flex justify-start">
                    <span class="headline py-4">Register account</span>
                </v-card-title>
                <v-card-subtitle class="d-flex justify-start pb-10 black--text"> Enter your email and password below </v-card-subtitle>
                <v-card-text class="pt-6">
                    <v-form ref="loginForm" v-on:submit.prevent="login()" class="" v-model="valid" lazy-validation>
                        <v-text-field outlined class="primary--text" v-model="form.userName" label="Full Name" append-icon="mdi-account" :rules="nameRules" required></v-text-field>
                        <v-text-field outlined class="primary--text" v-model="form.userEmail" label="Email" type="email" append-icon="mdi-email" :rules="emailRules" required></v-text-field>
                        <v-text-field outlined class="primary--text" v-model="form.userTelephone" label="Telephone" type="number" append-icon="mdi-phone" :rules="telephoneNumberRules" required></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="password"
                            label="Password"
                            type="password"
                            append-icon="mdi-lock"
                            :rules="passwordRules"
                            autocomplete="off"
                            required
                        ></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="confirmPassword"
                            label="Confirm Password"
                            :append-icon="show ? 'mdi-lock' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                            :rules="[password === confirmPassword || 'Password must match']"
                            autocomplete="off"
                            required
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn block x-large class="primary white--text mb-8" @click="register()" :disabled="form.userEmail === '' || form.userPassword === '' || loading" :loading="loading">Register</v-btn>
                </v-card-actions>
                <div class="body-1 float-right">Already have an account? <span class="font-weight-bold secondary--text" style="cursor: pointer" @click="$router.push('/')">Login</span></div>
            </v-card>
        </v-col>
    </v-container>
</template>

<script>
/* eslint-disable */
import { getAuth, updateProfile, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

export default {
    name: "Register",
    data() {
        return {
            inputBackgroundColor: "white",
            step: 1,
            valid: true,
            valid1: true,
            showPasswordIcon: false,
            showPasswordIcon1: false,
            adminRegCode: "",
            form: {
                id: "",
                userName: "",
                userEmail: "",
                userTelephone: "",
                createdDateTime: "",
                createdUserId: "",
                createdUserName: "",
                createdUserEmail: "",
                modifiedDateTime: "",
                modifiedUserId: "",
                modifiedUserName: "",
                modifiedUserEmail: "",
                privacy: false,
                deleted: false,
                userStatus: '',
                photoURL: '',
                deleted: "0",
            },
            password: "",
            confirmPassword: "",
            accountCreated: true,
            loading: false,
            loading1: false,
            privacyDialog: false,
            emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
            nameRules: [(v) => !!v || 'Name is required'],
            passwordRules: [(v) => !!v || 'Password is required'],
            telephoneNumberRules: [
                (v) => !!v || "Telephone Number is required",
            ],
            registerErrorMessage: null,
            userInLocalStorage: false,
            userInLocalStorageId: null,
            show: false,
        };
    },

    computed: {
        // * Checks if the password matches the confirm password
        passwordConfirmationRule() {
            return () =>
                this.password === this.confirmPassword || "Password must match";
        },
    },

    methods: {
        //* REGISTER
        async register() {
            debugger
            var t = this;
            t.loading = true;
            const auth = getAuth();
            t.form.userEmail = t.form.userEmail.toLowerCase();
            // * CREATE USER AUTH
            createUserWithEmailAndPassword(auth, t.form.userEmail, t.password)
                .then(async (user) => {
                    var userId = user.user.uid;
                    // * SET USER DETAILS
                    t.setUserDetails(userId);
                    //* CREATE USER ACCOUNT
                    await t.MIX_firestore_create(t.form, "users");
                    await updateProfile(auth.currentUser, {
                        displayName: t.form.userName,
                    })
                        .then(async () => {
                            const user = auth.currentUser;
                            // * SET AS CURRENT USER
                            await t.MIX_FIREBASE_userAuth(user);
                            t.accountCreated = true;
                            t.MIX_alertBox({
                                color: "success",
                                timeout: 2000,
                                message: "Please verify your account by email",
                                show: true,
                            });
                            //* SEND VERIFICATION EMAIL TO USER
                            sendEmailVerification(auth.currentUser).then(() => {
                                console.log("Email Verification Sent");
                            });
                        })
                        .catch((error) => {
                            console.log("Error " + error);
                        });
                })
                .then(() => {
                    // * REDIRECT USER TO HOMEPAGE
                    t.$router.push("/").catch((error) => {
                        console.log(error.message);
                    });
                })
                .catch((err) => {
                    t.registerErrorMessage = err;
                    t.accountCreated = false;
                    console.log("err: " + err);
                    t.MIX_alertBox({
                        color: "error",
                        timeout: 2000,
                        message: err,
                        show: true,
                    });
                });
        },

        //* SETTING DETAILS TO USER
        setUserDetails(uid) {
            var t = this;
            t.form.id = uid; // * assign firebase auth user id to firebase user in collection
            t.form.createdDateTime = t.$moment().format("x");
            t.form.createdUserId = uid;
            t.form.createdUserName = t.form.userName;
            t.form.createdUserEmail = t.form.userEmail;
            t.form.modifiedDateTime = t.$moment().format("x");
            t.form.modifiedUserId = uid;
            t.form.modifiedUserName = t.form.userName;
            t.form.modifiedUserEmail = t.form.userEmail;
        },

        //* SEND VERIFICATION EMAIL
        sendVerificationEmail(currUser) {
            var t = this;
            var currentUser = currUser;
            currentUser
                .sendEmailVerification()
                .then(function () {
                    t.MIX_alertBox({
                        color: "warning",
                        timeout: 2000,
                        message:
                            "An account verification email has been sent to you.",
                        show: true,
                    });
                })
                .catch(function (error) {
                    // ! verification email error
                    console.log("err: " + error);
                    t.MIX_alertBox({
                        color: "error",
                        timeout: 2000,
                        message: error,
                        show: true,
                    });
                });
        },
    },
};
</script>