<template>
  <div class="" style="padding: 0px !important; margin: 0px !important; width:100% !important">
    <v-row class="" dense style="z-index: 0 !important">
      <!-- Main Content -->
      <v-col cols="12" class=" align-start rounded">
        <v-row class="pa-6 justify-center align-center pb-0">
          <div class="flex-grow-1 pr-1">
            <v-text-field
                label="Search Activities"
                v-model="searchQuery"
                outlined
                dense
                hide-details="auto"
                :disabled="addTask"
                background-color="white"
                color="primary"
                clearable
                prepend-inner-icon="icons8-search"
                @click:clear="searchQuery = ''"
            ></v-text-field>
          </div>
          <v-btn-toggle mandatory class="mr-1">
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="activityFilter = 'all'">
              All Activities
            </v-btn>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="activityFilter = 'user'">
              Assigned to Me
            </v-btn>
          </v-btn-toggle>
          <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="logActivity()">
            + <v-icon>icons8-clock</v-icon>
          </v-btn>
          <!-- <v-btn
              @click="addNewTask()"
              :disabled="addTask"
              class="elevation-0 primary">+ Add</v-btn> -->
        </v-row>
        <v-row v-if="addTask == true" dense class="px-4 pt-3">
          <v-card class="elevation-0 grey lighten-4 py-3 px-3" outlined>
            <v-row dense>
              <v-col class="d-flex align-center justify-center" cols="12" xs="12">
                <div class="pl-1 font-weight-medium body-1 primary--text">New Task</div>
                <v-spacer/>
                <v-btn
                    @click="saveNewTask()"
                    class="elevation-0 primary">Save
                </v-btn>
              </v-col>
              <v-col cols="12" xs="12">
                <v-select label="Type" v-model="newAction.actionType" :items="actionTypes" required outlined dense
                          hide-details="auto" background-color="white" color="primary" clearable
                          :menu-props="{ top: false, offsetY: true }"
                          @click:clear="clearItem('actionType')"></v-select>
              </v-col>

              <v-col cols="12" xs="12">
                <v-text-field label="Subject" v-model="newAction.actionSubject" outlined dense hide-details="auto"
                              background-color="white" color="primary" clearable
                              @click:clear="clearItem('actionSubject')"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field label="Description" v-model="newAction.actionDescription" outlined dense
                              hide-details="auto" background-color="white" color="primary" clearable
                              @click:clear="clearItem('actionDescription')"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" md="12">
                <v-select label="Priority" v-model="newAction.actionPriority" :items="priority" required outlined dense
                          hide-details="auto" background-color="white" color="primary" clearable
                          :menu-props="{ top: false, offsetY: true }"
                          @click:clear="clearItem('actionPriority')"></v-select>
              </v-col>
              <v-col cols="12" xs="12">
                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y
                        max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Action Start Date" v-model="computedDate1" outlined dense hide-details="auto"
                                  background-color="white" color="primary" v-bind="attrs" v-on="on" clearable
                                  @click:clear="clearItem('actionStartDate')"></v-text-field>
                  </template>
                  <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1"
                                 @input="dateMenu1 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" xs="12">
                <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y
                        max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Action End Date" v-model="computedDate2" outlined dense hide-details="auto"
                                  background-color="white" color="primary" v-bind="attrs" v-on="on" clearable
                                  @click:clear="clearItem('actionEndDate')"></v-text-field>
                  </template>
                  <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="2"
                                 @input="dateMenu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" xs="12">
                <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y
                        max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Action Close Date" v-model="computedDate3" outlined dense hide-details="auto"
                                  background-color="white" color="primary" v-bind="attrs" v-on="on" clearable
                                  @click:clear="clearItem('actionCloseDate')"></v-text-field>
                  </template>
                  <v-date-picker v-model="date3" full-width no-title color="secondary" :first-day-of-week="1"
                                 @input="dateMenu3 = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>
          <!-- </v-col> -->
        </v-row>
        <v-row v-if="addTask == false" class="px-3 pt-0">
          <v-col cols="12">
            <v-btn-toggle multiple v-model="type" class="d-flex align-center flex-row">
              <v-btn class="flex-grow-1" value="Call" active-class="grey lighten-1 white--text" depressed small>
                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Call' : ''" size="20" color="grey"
                        class="icons8-call"></v-icon>
                <span v-if="$vuetify.breakpoint.xlOnly">Call</span>
              </v-btn>
              <v-btn class="flex-grow-1" value="Call Back" active-class="grey lighten-1 white--text" depressed small>
                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Call Back' : ''" size="20" color="grey"
                        class="icons8-callback"></v-icon>
                <span v-if="$vuetify.breakpoint.xlOnly">Call Back</span>
              </v-btn>
              <v-btn class="flex-grow-1" value="Case" active-class="grey lighten-1 white--text" depressed small>
                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Case' : ''" size="20" color="grey"
                        class="icons8-complaint"></v-icon>
                <span v-if="$vuetify.breakpoint.xlOnly">Case</span>
              </v-btn>
              <v-btn class="flex-grow-1" value="Email" active-class="grey lighten-1 white--text" depressed small>
                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Email' : ''" size="20" color="grey"
                        class="icons8-mail"></v-icon>
                <span v-if="$vuetify.breakpoint.xlOnly">Email</span>
              </v-btn>
              <v-btn class="flex-grow-1" value="Event" active-class="grey lighten-1 white--text" depressed small>
                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Event' : ''" size="20" color="grey"
                        class="icons8-calendar"></v-icon>
                <span v-if="$vuetify.breakpoint.xlOnly">Event</span>
              </v-btn>
              <v-btn class="flex-grow-1" value="Meeting" active-class="grey lighten-1 white--text" depressed small>
                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Meeting' : ''" size="20" color="grey"
                        class="icons8-meeting"></v-icon>
                <span v-if="$vuetify.breakpoint.xlOnly">Meeting</span>
              </v-btn>
              <v-btn class="flex-grow-1" value="Task" active-class="grey lighten-1 white--text" depressed small>
                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Task' : ''" size="20" color="grey"
                        class="icons8-tasklist"></v-icon>
                <span v-if="$vuetify.breakpoint.xlOnly">Task</span>
              </v-btn>
              <v-btn class="flex-grow-1" value="Note" active-class="grey lighten-1 white--text" depressed small>
                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Note' : ''" size="20" color="grey"
                        class="icons8-note"></v-icon>
                <span v-if="$vuetify.breakpoint.xlOnly">Note</span>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <!-- //* VIEW MODE -->
        <v-row v-if="addTask == false" class="pt-3" style="width: 100% !important" no-gutters>
          <!-- <pre>{{ orgIsGroup }}</pre> -->
          <v-col cols="12">
            <v-card v-if="computedMonths.length > 0" class="elevation-0 grey lighten-3 pr-3"
                    style="height: 83vh !important; overflow-y: scroll !important ">
              <v-timeline
                  align-top
                  dense
                  v-for="(month, i) in computedMonths"
                  :key="i"
              >
                <v-timeline-item
                    hide-dot
                    class="pb-0 mb-0"
                    v-if="JSON.stringify(computedMonthItems(month)) !== '[]'"
                >
                  <div class="font-weight-bold text-h6 grey--text">{{ month }}</div>
                </v-timeline-item>
                <v-timeline-item
                    v-for="(item, i) in computedMonthItems(month)"
                    :key="i"
                    fill-dot
                    flat
                    :icon="item.icon"
                    class="pb-2 elevation-0"
                >
                  <actionCard :datadown="item" :orgIsGroup="orgIsGroup" v-on:saveItem="saveAction"
                              v-on:deleteItem="deleteAction" :key="item.id"/>
                </v-timeline-item>
              </v-timeline>
            </v-card>
            <div class="grey--text d-flex font-weight-bold text-h5 align-center justify-center"
                 v-if="computedMonths.length == 0">
              NO ACTIVITIES
            </div>
          </v-col>
        </v-row>
      </v-col>

    </v-row>

<!--    ACTIVITY NEW-->
   <NewActivityDialog :activityDialog="activityDialog" v-on:closeDialog="closeDialog" v-on:refreshItems="getItems" :orgId="datadown.id"></NewActivityDialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import actionCard from '@/views/sales/ActivityCard.vue'
import NewActivityDialog from '@/views/sales/NewActivityDialog.vue'
// import companiesHouse from '@/views/organisations/companiesHouse.vue';
import schema from "@/schema.js";
import moment from 'moment';

export default {
  name: 'organisationActivities',
  components: {
    NewActivityDialog,
    // 'companies-house': companiesHouse,
    'actionCard': actionCard,
  },
  props: {
    datadown: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dense: {
      type: Boolean,
      default: false,
    },
    itemnew: {
      type: Boolean,
      default: false,
    },
    listshrunk: {
      type: Boolean,
      default: false,
    },
    orgtype: {
      type: String,
      default: '',
    },
    refreshItems: {
      type: Number,
      default: 0,
    },
    orgIsGroup: {
      type: Boolean,
      default: false,
    },
    organisations: {
      type: Array,
      default: () => [],
    }
  },
  // * DATA
  data: () => ({
    // Item
    actions: {},
    key: 'action',
    index: 'idx:actions',
    items: [],
    type: [],
    selectedActionOwner: [],
    itemsTotal: null,
    searchQuery: '',
    itemsFrom: 0,
    itemsTo: 9,
    datatableOptions: {
      page: 1,
      itemsPerPage: 999,
      sortBy: ['actionDate'],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    },
    sortBy: '@action',
    addTask: false,
    actionTypes: [],

    // Headers to display in the Data Table
    headers: [
      {text: 'Action', value: 'actionType', sortable: false, hidden: false, expanded: false},
      {text: 'Subject', value: 'actionSubject', sortable: false, hidden: false, expanded: false},
      {text: 'Description', value: 'actionDescription', sortable: false, hidden: false, expanded: false},
      {text: 'Status', value: 'actionStatus', sortable: false, hidden: false, expanded: false},
      {text: 'Priority', value: 'actionPriority', sortable: false, hidden: false, expanded: false},
      {text: 'Start Date', value: 'actionDate', sortable: false, hidden: false, expanded: true},
      {text: 'Start Date', value: 'actionCompletedDateTime', sortable: false, hidden: false, expanded: true},

      {text: 'Organisation', value: 'actionStageId', sortable: false, hidden: false, expanded: false},
      {text: 'Organisation', value: 'actionStageName', sortable: false, hidden: false, expanded: false},
      {text: 'Organisation', value: 'actionCompleted', sortable: false, hidden: false, expanded: false},
      {text: 'Organisation', value: 'orgId', sortable: false, hidden: false, expanded: false},
      {text: 'Organisation', value: 'orgName', sortable: false, hidden: false, expanded: false},
      {text: 'End Date', value: 'actionEndDate', sortable: false, hidden: false, expanded: false},
      {text: 'Organisation', value: 'actionTags', sortable: false, hidden: false, expanded: false},
      {text: 'Contact Tags', value: 'contactTags', sortable: false, hidden: true, expanded: true},
      {text: 'Opportunity', value: 'opportunityId', sortable: false, hidden: true, expanded: true},
      {text: 'Lead', value: 'leadId', sortable: false, hidden: true, expanded: false},
      {text: 'Call Type', value: 'actionCallType', sortable: false, hidden: true, expanded: false},
      {text: 'Call Duration', value: 'actionCallDuration', sortable: false, hidden: true, expanded: false},
      {text: 'Call Purpose', value: 'actionCallPurpose', sortable: false, hidden: true, expanded: false},
      {text: 'Call Result', value: 'actionCallResult', sortable: false, hidden: true, expanded: true},
      {text: 'Meeting Host', value: 'actionMeetingHost', sortable: false, hidden: true, expanded: false},
      {
        text: 'Meeting Participants',
        value: 'actionMeetingParticipants',
        sortable: false,
        hidden: true,
        expanded: false
      },
      {text: 'Meeting Type', value: 'actionMeetingType', sortable: false, hidden: true, expanded: false},
      {text: 'Meeting Location', value: 'actionMeetingLocation', sortable: false, hidden: true, expanded: true},
      {text: 'Event Host', value: 'actionEventHost', sortable: false, hidden: true, expanded: true},
      {text: 'Event Participants', value: 'actionEventParticipants', sortable: false, hidden: true, expanded: false},
      {text: 'Event Type', value: 'actionEventType', sortable: false, hidden: true, expanded: false},
      {text: 'Event Location', value: 'actionEventLocation', sortable: false, hidden: true, expanded: false},
      {text: 'Case Type', value: 'actionCaseType', sortable: false, hidden: true, expanded: false},

      {text: "Owner Time (C)", value: "ownerDateTime", sortable: false, hidden: true, expandable: false},
      {text: "Owner ID (C)", value: "ownerUserId", sortable: false, hidden: true, expandable: false},
      {text: "Owner Name (C)", value: "ownerUserName", sortable: false, hidden: false, expandable: false},

      {text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true},
      {text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true},
      {text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: true},
      {text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true},
      {text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true},
      {text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true},
      {text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true},
      {text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true},
      {text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false},
    ],
    newAction: {},
    priority: ['Urgent', 'High', 'Medium', 'Low'],

    date1: moment().format("YYYY-MM-DD"),
    dateMenu1: false,
    date2: moment().add(1, "days").format("YYYY-MM-DD"),
    dateMenu2: false,
    date3: moment().add(1, "days").format("YYYY-MM-DD"),
    dateMenu3: false,

    //activity
    activityDialog: false,
    addingAction: false,
    status: ['Open', 'Pending', 'Closed'],


    callType: ['Inbound', 'Outbound', 'Missed'],
    minutes: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'],
    callPurpose: ['None', 'Prospecting', 'Administrative', 'Negotiotiation', 'Demo', 'Project', 'Desk'],
    callResult: ['None', 'Interested', 'Not Interested', 'No Response', 'Busy', 'Requested More Info', 'Requested Call Back', 'Invalid Number'],

    //meeting dropdowns
    users: [],
    contacts: [],
    meetingTypes: ['In Person', 'Remote', 'Conference Call'],
    eventTypes: ['Tradeshow', 'Exhibition', 'Other'],
    caseTypes: ['Complaint', 'Question', 'Other'],

    selectedEventParticipants: [],
    selectedContacts: [],
    selectedHost: [],
    selectedOrganisation: null,
    fullOrganisationList: [],
    rules: {},
    showEventAddress: false,
    showMeetingAddress: false,


    participantsAutocomplete: '',

    activityFilter: ''

  }),
  computed: {
    ...mapGetters({
      GET_dense: 'GET_dense',
    }),
    inputCols() {
      if (this.listshrunk) {
        return '3';
      } else {
        return '6';
      }
    },
    computedMonths() {
      var t = this;


      const months = [];

      // ////console.log("items = " + JSON.stringify(this.items, null, 2))
      ////console.log("t.actions = " + JSON.stringify(t.actions, null, 2))
      // this.items = this.items.filter((action) => {
      //     return action.orgId === t.actions.id
      // })

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.items = this.items.filter((action) => {
        return action.actionCompleted === '1'
      })

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.items.sort((a, b) => b.actionCompletedDateTime.localeCompare(a.actionCompletedDateTime));

      ////console.log("items = " + JSON.stringify(this.items, null, 2))

      this.items.filter((item) => {
        var month = []
        month = t.$moment(item.actionCompletedDateTime, 'X').format('MMM')
        if (month !== 'Invalid date') {
          return months.push(month);
        }
      });
      // // //console..log("motnhs = " + JSON.stringify(months, null, 2))

      let filteredMonths = [...new Set(months)];
      ////console.log("filteredMonths = " + JSON.stringify(filteredMonths, null, 2));

      return filteredMonths
    },
    computedSearchQuery() {
      var t = this
      let searchQuery = this.searchQuery;
      var orgs = {}

      if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
        // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
        //     searchQuery = '*';
        // } else {
        searchQuery = '@deleted:0';
        // }
      } else {
        if (searchQuery.startsWith('@')) {
          // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
          //     searchQuery = `${searchQuery}*`;
          // } else {
          searchQuery = `${searchQuery}*  @deleted:0`;
          // }
        } else {
          // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
          //     searchQuery = `${searchQuery}*`;
          // } else {
          searchQuery = `${searchQuery}*  @deleted:0`;
          // }
        }
      }
      if (this.orgIsGroup == true && this.organisations.length > 0) {
        orgs = this.organisations
        orgs = orgs.map((y) => y.id).join("|")
        //console.log('orgs = ' + JSON.stringify(orgs, null, 2))
        searchQuery = `${searchQuery} @orgId:${orgs}`;
      } else {
        searchQuery = `${searchQuery} @orgId:${t.datadown.id}`
      }

      if(this.activityFilter === 'user'){
        searchQuery = `${searchQuery} @ownerUserId:${this.MIX_getCurrentOwner().ownerUserId}`
      }

      //console.log('searchQuery actions = ' + searchQuery)


      return searchQuery;
    },
    computedDate1() {
      return this.$moment(this.date1, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
    computedDate2() {
      return this.$moment(this.date2, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
    computedDate3() {
      return this.$moment(this.date3, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
    computedFields() {
      let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
      if (!computedFields.includes('@id')) {
        computedFields.push('@id');
      }
      if (!computedFields.includes('@deleted')) {
        computedFields.push('@deleted');
      }
      if (!computedFields.includes('@actionId')) {
        computedFields.push('@actionId');
      }
      computedFields = computedFields.join(',');
      return computedFields;
    },
  },
  // * METHODS
  methods: {
    addNewTask() {
      var t = this;
      t.newAction = schema.action
      t.newAction.orgId = t.datadown.id
      t.newAction.orgName = t.datadown.orgName
      t.newAction.actionStageName = t.datadown.orgStageName
      t.newAction.actionStageId = t.datadown.orgStageId
      this.newAction.actionStartDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
      this.newAction.actionEndDate = this.$moment(this.date2, 'YYYY-MM-DD').format('X');
      this.newAction.actionCloseDate = this.$moment(this.date3, 'YYYY-MM-DD').format('X');


      t.activityDialog = true
    },
    async saveNewTask() {
      var t = this;

      var action = t.newAction

      // // // //console..log("saving action + " + JSON.stringify(action, null, 2))

      let result = await t.MIX_createItem(action, t.key, true);
      if (result.code === 1) {
        // t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
        // t.readItem(item.id);
        // t.$emit("refreshItems");
        // t.closeDialog()
        // // // //console..log('saved action')
        t.newAction = []
        t.addTask = false
        this.getItems()
      }

    },
    computedMonthItems(month) {
      var t = this;

      var items = t.items

      items.forEach((item) => {
        switch (true) {
          case item.actionType === 'Call':
            item.icon = 'icons8-call'
            break;
          case item.actionType === 'Call Back':
            item.icon = 'icons8-callback'
            break;
          case item.actionType === 'Case':
            item.icon = 'icons8-complaint'
            break;
          case item.actionType === 'Email':
            item.icon = 'icons8-mail'
            break;
          case item.actionType === 'Event':
            item.icon = 'icons8-calendar'
            break;
          case item.actionType === 'Meeting':
            item.icon = 'icons8-meeting'
            break;
          case item.actionType === 'Task':
            item.icon = 'icons8-tasklist'
            break;
          case item.actionType === '':
            item.icon = ''
            break;

        }
      })

      if (this.type !== null && this.type !== undefined && JSON.stringify(this.type) !== '[]') {
        items = this.items.filter((item) => this.type.includes(item.actionType));
      }

      // items = items.filter((action) => {
      //     return action.orgId === t.actions.id
      // })
      ////console.log("computedMonthItems = " + JSON.stringify(items, null, 2))

      items = items.filter((action) => {
        if ((t.$moment(action.actionCompletedDateTime, 'X').format('MMM')) === month) {
          return action
        }
      })
      // items.sort((a, b) => a.actionDate.localeCompare(b.actionDate));

      return items
    },
    // * READ ITEM
    async readItem() {
      let t = this;
      var id = {...t.datadown}
      // // // // // //console..log("reading item " + JSON.stringify(id, null, 2))
      let result = await t.MIX_readItem(id.orgId, 'org');
      if (result.code === 1) {
        this.item = result.data;
        // // // // // //console..log("item = " + JSON.stringify(t.item, null, 2))
        t.itemNew = false;
        t.loading = false;
      }
    },
    // * DELETE ITEM
    async deleteAction(action) {
      let result = await this.MIX_deleteItem(action.id, this.key);
      if (result.code === 1) {
        // this.readItem(this.item.id);
        this.$emit("refreshItems");
        // t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
      }
    },
    async saveAction(action) {
      const t = this;

      ////console.log("saving action + " + JSON.stringify(action, null, 2))
      action.actionCompletedDateTime = t.$moment().format('X')
      ////console.log("saving action + " + JSON.stringify(action, null, 2))

      let result = await t.MIX_createItem(action, t.key, false);
      if (result.code === 1) {
        t.MIX_alertBox({color: "success", timeout: 2000, message: "Saved", show: true});
        // t.readItem(item.id);
        t.$emit("refreshItems");
        await this.getItems();
        // t.closeDialog()
        // t.getItems()
        // // ////console.log('saved action')
      }
    },
    // * GET ITEMS
    async getItems() {
      // console.log('getItems Activity running')
      // console.log(this.index)
      // console.log(this.itemsFrom)
      // console.log(this.datatableOptions)
      // console.log(this.computedFields)
      // console.log(this.sortBy)
      let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
      console.log(itemsResult)
      if (itemsResult.code === 1) {
        this.itemsTotal = itemsResult.data.total;
        this.items = itemsResult.data.results;
        // console.table(this.items)
        // //console..log("items = " + JSON.stringify(this.items, null, 2))
        let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
        if (itemsTo < this.itemsTotal) {
          this.itemsTo = itemsTo;
        } else {
          this.itemsTo = this.itemsTotal;
        }
      } else {
        //// // // // // //console..log("items = " + JSON.stringify(itemsResult, null, 2));
      }
    },
    logActivity() {
      var t = this;

      t.addingAction = true
      // t.panel = ''
      t.newAction = {}
      t.newAction = {...t.schema}
      t.newAction.orgId = t.datadown.id
      t.newAction.actionCompletedDateTime = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
      t.newAction.actionCompleted = 1
      t.activityDialog = true
    },
    getAddressDataMeeting: function (addressData, placeResultData) {
      let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
      // //////console.log(JSON.stringify(placeResult, null, 2));
      this.newAction.meetingAddressName = placeResult.name;
      this.newAction.meetingAddressLine1 = placeResult.addressLine1;
      this.newAction.meetingAddressLine2 = placeResult.addressLine2;
      this.newAction.meetingAddressLine3 = placeResult.addressLine3;
      this.newAction.meetingAddressTown = placeResult.town;
      this.newAction.meetingAddressCounty = placeResult.county;
      this.newAction.meetingAddressPostcode = placeResult.postcode;
      this.newAction.meetingAddressCountry = placeResult.country;
      this.newAction.meetingAddressLat = placeResult.lat;
      this.newAction.meetingAddressLng = placeResult.lng;
      this.showMeetingAddress = true
    },
    getAddressDataEvent: function (addressData, placeResultData) {
      let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
      this.newAction.eventAddressName = placeResult.name;
      this.newAction.eventAddressLine1 = placeResult.addressLine1;
      this.newAction.eventAddressLine2 = placeResult.addressLine2;
      this.newAction.eventAddressLine3 = placeResult.addressLine3;
      this.newAction.eventAddressTown = placeResult.town;
      this.newAction.eventAddressCounty = placeResult.county;
      this.newAction.eventAddressPostcode = placeResult.postcode;
      this.newAction.eventAddressCountry = placeResult.country;
      this.newAction.eventAddressLat = placeResult.lat;
      this.newAction.eventAddressLng = placeResult.lng;
      this.showEventAddress = true
    },
    closeDialog(){
      this.activityDialog = false
      this.addingAction = false
      this.newAction = {}
      this.newAction = {...this.schema}
    }
  },
  // * WATCH
  watch: {
    dateMenu1() {
      this.newAction.actionCompletedDateTime = this.$moment(this.date1, 'YYYY-MM-DD').format('X');

    },
    dateMenu2() {
      this.newAction.actionEndDate = this.$moment(this.date2, 'YYYY-MM-DD').format('X');
      // this.checkDates();
    },
    dateMenu3() {
      this.newAction.actionCloseDate = this.$moment(this.date3, 'YYYY-MM-DD').format('X');
      // this.checkDates();
    },
    refreshItems() {
      this.getItems();
    },
    activityFilter() {
      this.getItems();
    },
    datatableOptions: {
      handler() {
        this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
        let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
        if (itemsTo < this.itemsTotal) {
          this.itemsTo = itemsTo;
        } else {
          this.itemsTo = this.itemsTotal;
        }
        this.getItems();
      },
      deep: true,
    },
    datadown() {
      var t = this;
      t.actions = {...t.datadown};
      t.getItems()
      // // // // // // //console..log("item customer = " + JSON.stringify(t.item, null, 2))
    },
    // If the search query changes then get updated dataset based on search query
    computedSearchQuery() {
      this.getItems();
    },
    organisations() {
      var t = this;
      t.getItems()
    },
    selectedActionOwner: {
      handler: function () {
        this.newAction.ownerUserId = this.selectedActionOwner.ownerUserId;
        this.newAction.ownerUserName = this.selectedActionOwner.ownerUserName;
        this.newAction.ownerUserEmail = this.selectedActionOwner.ownerUserEmail;
      },
    },
  },
  // * CREATED
  async created() {
    let t = this;
    // console.log(t.datadown.id)
    t.schema = {...schema.action}
    t.actions = {...t.datadown};
    t.newAction = {...t.schema}
    this.getItems();
    let actionTypes = await t.MIX_redisReadSet('actionTypes', false);
    if (actionTypes.code === 1) {
      t.actionTypes = actionTypes.data;
    }
    if(this.$route.query.new === 'true' && this.$route.query.tab === 'activities'){
      this.logActivity()
    }

    if (this.newAction.ownerUserId !== '' && this.newAction.ownerUserId !== undefined && this.newAction.ownerUserId !== null) {
      this.selectedActionOwner = {
        ownerUserId: this.datadown.ownerUserId,
        ownerUserName: this.datadown.ownerUserName,
        ownerUserEmail: this.datadown.ownerUserEmail
      };
    } else {
      this.selectedActionOwner = this.MIX_getCurrentOwner();
    }

    let users = await t.MIX_users();
    t.users = users.map((x) => {
      return {
        ownerUserId: x.id,
        ownerUserName: x.userName,
        ownerUserEmail: x.userEmail,
      };
    });

  },
};
</script>

<style></style>
