<template>
    <div>
		<div v-if="!loading" class="primary d-flex align-center justify-center noprint" style="height: 100px !important; width: 100% !important;">
            <v-btn depressed class="white pa-3 ma-5 rounded" @click="print()">Print Quote</v-btn>
        </div>
		<!-- Header -->
        <header>
            <div class="d-flex align-start" style="height: 20px !important">
                <div style="background-color: #6c318b !important; height: 20px; width: 100px !important">
                    <v-img width="200" height="20" cover src="@/assets/spacer.png" />
                </div>
                <div style="background-color: #428881 !important; height: 20px; width: 100px !important">
                    <v-img width="200" height="20" cover src="@/assets/spacer.png" />
                </div>
                <div style="background-color: #d8348c !important; height: 20px; width: 100px !important">
                    <v-img width="200" height="20" cover src="@/assets/spacer.png" />
                </div>
                <div style="background-color: #8b8a8f !important; height: 20px; width: 100px !important">
                    <v-img width="200" height="20" cover src="@/assets/spacer.png" />
                </div>
            </div>
        </header>
        <!-- Page Body START -->
        <table>
            <thead>
                <tr>
                    <td>
                        <div class="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div id="" class="">
                            <div class="d-flex" style="width: 100% !important">
                                <div class="" style="width: 50% !important">
                                    <div v-if="product.productImageId !== ''" class="black d-flex align-center white pa-1"><v-img class="" width="250" height="250" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/' + product.productImageId + '.jpeg?x=' + Date.now()" /></div>
                                    <div v-if="product.productImageId === ''" class="black d-flex align-center white pa-1"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
                                </div>
                                <div class="mr-5 d-flex align-center" style="width: 50% !important">
                                    <div class="primary--text text-h5 text-right">
                                        {{ product.productName }}
                                        <br /><strong>Code: </strong>{{ product.productSupplierCode }}
                                    </div>
                                    <div class="primary--text text-h6 text-right"></div>
                                    <br />
                                </div>
                            </div>
                            <!-- Description -->
                            <div class="d-flex align-left" style="width: 100% !important" v-if="product.productDimensions !== ''">
                                <div class="primary--text text-h6 text-right">Description:</div>
                                <div class="grey--text text-h6 text-right">{{ product.productDescription }}</div>
                            </div>
                            <div class="my-5 primary">
                                <v-img width="100%" height="3" cover src="@/assets/spacer.png" />
                            </div>
                            <!-- Dimensions -->
                            <div class="d-flex align-left" style="width: 100% !important" v-if="product.productDimensions !== ''">
                                <div class="primary--text text-h6 text-right">Dimensions:</div>
                                <div class="grey--text text-h6 text-right">{{ product.productDimensions }}</div>
                            </div>
                            <!-- Options -->
                            <div class="d-flex align-left" style="width: 100% !important" v-if="product.productDimensions !== ''">
                                <div class="primary--text text-h6 text-right">Options:</div>
                                <div class="grey--text text-h6 text-right">{{ product.productDimensions }}</div>
                            </div>
                            <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ultrices ornare ultrices. Sed lacinia suscipit augue quis egestas. Fusce placerat porttitor est, vitae dictum tortor euismod congue. Ut vestibulum orci eget elit bibendum, in tristique mauris semper. Phasellus pharetra sagittis orci, et condimentum eros vehicula non. Pellentesque fermentum facilisis lectus quis sollicitudin. Praesent mollis iaculis pharetra. Vivamus et lorem tristique, iaculis metus nec, porttitor nibh. Integer eget metus in nulla ultrices rutrum vel eu magna. Suspendisse potenti.</p>
                                <p>Morbi pretium elit ex, eget maximus sem elementum tempus. Ut sit amet nisi sodales, suscipit nibh eget, gravida ipsum. Etiam porttitor eros at purus ultricies, at vestibulum nunc tristique. Morbi in iaculis diam. Aliquam erat volutpat. Nulla eu maximus urna. Vestibulum leo sapien, aliquam id imperdiet non, interdum et magna. Mauris pellentesque lobortis fermentum. Integer porttitor pellentesque mi malesuada aliquet. Proin sit amet ultrices mauris, sed varius dui. Nam semper ligula eu pretium accumsan. Praesent cursus urna et cursus tempor. Nam at erat in odio interdum venenatis. Donec in lorem quis arcu pulvinar congue a sed erat.</p>
                                <p>Nam ultricies ac mauris non sodales. Suspendisse tristique cursus hendrerit. Phasellus non arcu massa. Sed imperdiet dui ac est tempus volutpat. Praesent ante nulla, hendrerit non egestas id, volutpat vitae lacus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc sed justo luctus, mollis ante quis, fermentum eros. Phasellus tincidunt arcu a erat efficitur vestibulum. Praesent tortor tortor, lobortis eget imperdiet in, placerat quis lacus. Aenean pretium lectus a nisi auctor, in iaculis tellus rhoncus. Morbi ac dolor vitae nibh tincidunt pharetra. Ut consequat maximus purus, vitae tempor erat tincidunt id. Aenean mattis ex ac euismod pharetra.</p>
                                <p>Sed ut sapien non nibh vulputate facilisis. Donec sagittis neque nibh, eu vulputate augue faucibus nec. Etiam porta quam et ante aliquet aliquet. Vivamus ut vehicula risus, at semper nulla. Integer malesuada ante pulvinar dolor porttitor, id tincidunt lectus ultrices. Suspendisse venenatis nec sapien id consectetur. Etiam quam erat, luctus sed lacus at, aliquet viverra leo. Quisque rutrum, nunc ac lacinia volutpat, nisl magna lacinia nulla, et egestas libero mi at quam.</p>
                                <p>Sed convallis eu felis vitae pharetra. Donec neque sem, tempus vitae enim eu, suscipit egestas est. In pretium gravida est, lacinia aliquam neque vehicula in. Nunc id pellentesque ante. Nunc ac sem hendrerit, blandit diam vitae, facilisis nisl. Aenean vel condimentum purus. Nam et imperdiet justo, at ultricies elit.</p>
                                <p>Integer vestibulum id risus sit amet commodo. Donec erat neque, ornare a dignissim ullamcorper, mollis at magna. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis eget elit eget nulla lacinia iaculis. Morbi et sapien id erat sollicitudin ultrices. In id nunc id lorem fringilla pharetra. Vivamus et neque fermentum, lobortis libero nec, maximus erat. In aliquam congue quam at eleifend. Maecenas semper, odio ac bibendum dictum, urna orci venenatis eros, vitae malesuada enim urna et orci. Ut hendrerit justo lorem, id consequat dui finibus eu. Duis non dolor lacinia, fringilla nulla sit amet, luctus urna. Donec sed nibh justo. Vestibulum quis lectus hendrerit, dictum sapien nec, congue nisi. Proin tempus nunc sit amet odio porttitor aliquam. Cras bibendum quam a elit posuere, at condimentum velit faucibus. Duis vel tellus a nulla varius suscipit eu sed magna.</p>
                                <p>Nulla facilisi. Integer et sem nibh. Cras id justo eu elit ultricies mollis. Etiam porttitor nisl pharetra nunc venenatis, feugiat consequat augue rhoncus. Suspendisse magna turpis, placerat in blandit eget, suscipit nec tellus. Cras mollis enim vitae elit accumsan ultrices. Nam molestie massa sed nulla dictum, vel ultrices tortor porttitor.</p>
                                <p>Donec eget ante eleifend, sollicitudin massa quis, luctus ligula. Etiam fermentum porta pharetra. Vivamus fermentum est non augue semper, sit amet molestie ex consectetur. Duis a ligula ultricies, accumsan dui vel, convallis quam. Donec rhoncus, leo nec vulputate volutpat, tellus elit porta tortor, ut lobortis lacus lorem non purus. Mauris ante tellus, tempor a leo vel, varius porta sem. Pellentesque aliquet nulla ex. Sed malesuada porttitor hendrerit. Nullam cursus odio turpis. Quisque eu magna at ante rhoncus tincidunt.</p>
                                <p>Nulla metus nisi, sagittis fermentum neque et, bibendum rutrum arcu. Sed sagittis, leo eu porta sollicitudin, mi massa ultrices nulla, eget pulvinar mauris sem eget risus. Pellentesque malesuada, orci sit amet facilisis elementum, nisl risus posuere dolor, eget posuere nunc neque a urna. Vestibulum venenatis sit amet massa ac fermentum. Vestibulum pharetra massa non elementum lacinia. Curabitur imperdiet aliquam pretium. Morbi diam risus, ornare sed pellentesque a, volutpat rutrum massa.</p>
                                <p>Nulla orci eros, dapibus sit amet semper eget, malesuada ut tortor. Morbi sagittis maximus convallis. Pellentesque nec mi massa. Fusce ut ante ullamcorper, hendrerit lorem quis, blandit quam. Vestibulum lacinia facilisis suscipit. Phasellus tempus accumsan dui, eget ultricies est rutrum quis. Ut molestie tincidunt consequat. Nulla ligula elit, egestas ut sapien sit amet, sagittis vehicula eros. Vivamus dapibus tortor libero, ultrices mollis elit lobortis ut.</p>
                                <p>Vivamus eget turpis non orci sagittis cursus. Suspendisse sit amet consectetur velit. Maecenas sit amet tempus dui. Cras cursus est sit amet odio egestas, nec semper libero interdum. Fusce bibendum auctor maximus. Quisque sollicitudin sagittis libero, vitae commodo metus consequat nec. Vivamus orci odio, fermentum et est non, eleifend euismod ex. Cras nec ultrices felis, nec congue turpis. Phasellus iaculis tellus eget ex eleifend, a tempus nisi ultricies. Sed eu pulvinar metus. Phasellus cursus, neque ut imperdiet tristique, lorem mauris facilisis ex, nec gravida tortor mauris pellentesque urna. Nulla dignissim diam sed leo fermentum, quis efficitur ligula semper.</p>
                                <p>Vivamus scelerisque vestibulum molestie. Curabitur laoreet, eros a lacinia tincidunt, ante sapien finibus orci, sed mattis erat orci in magna. Phasellus ac lorem imperdiet, interdum purus id, aliquam libero. In vehicula dui turpis, venenatis posuere odio iaculis nec. Nulla in enim gravida, aliquet velit id, consequat turpis. In auctor elit sit amet augue pharetra auctor. Aenean quis libero dolor.</p>
                                <p>Vivamus dictum efficitur lectus, luctus faucibus purus aliquet sit amet. Praesent lorem erat, porttitor non fringilla et, commodo eget ante. Vestibulum non lacus a magna volutpat mollis. Curabitur sit amet dignissim est. Donec varius augue lorem, ut eleifend ipsum ultricies sed. Praesent sed aliquet mauris. Curabitur pretium eleifend dui eu dictum. Duis tincidunt lacus ante, et aliquet ante lobortis nec. Etiam quam erat, condimentum in ipsum non, malesuada mattis enim. Donec elementum mi et tempus efficitur. Etiam sollicitudin leo nec eros varius ultrices. Ut rutrum facilisis metus malesuada facilisis. Sed quis lectus sit amet est porttitor eleifend id et urna. Integer eget mauris sit amet urna pharetra finibus. Donec at posuere urna.</p>
                                <p>Suspendisse sit amet nunc nec augue fringilla pulvinar. Mauris eu rutrum ante, ac condimentum magna. Praesent varius placerat nisl vitae lobortis. Nam vel urna bibendum, cursus sapien ut, congue lectus. Suspendisse quam nibh, iaculis eget massa rhoncus, volutpat cursus ex. Proin vel sollicitudin nunc. Nam imperdiet finibus arcu eu auctor. Quisque sed vehicula turpis. Nunc a mollis dui.</p>
                                <p>Vestibulum sollicitudin eget ante ut ornare. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et viverra turpis, non mollis odio. Ut quis orci risus. Pellentesque quis porttitor nunc. Maecenas eu elementum erat. Vestibulum finibus fringilla nulla id viverra. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent ultricies sapien at dolor ullamcorper, ornare pharetra arcu mollis. Aliquam sit amet feugiat nibh, quis suscipit ante. Mauris convallis vestibulum odio.</p>
                                <p>Donec laoreet hendrerit lectus, non viverra augue venenatis id. Nullam pharetra quam nisi, in mattis dui varius ac. Mauris tempus diam diam, tincidunt semper nisl sollicitudin at. Vivamus tristique eu magna lacinia maximus. Maecenas luctus quam vitae pharetra congue. Etiam in ultricies libero. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam sed ornare massa. Nam maximus consectetur risus vitae dictum. Morbi efficitur tortor turpis, a scelerisque purus volutpat eu. Sed non magna quis libero accumsan iaculis vitae quis nisl. Nam pretium, augue vitae blandit condimentum, sapien risus gravida ante, in bibendum leo erat nec mi. Donec sed quam at massa posuere suscipit a in neque. Nunc quis rutrum justo. Aenean eget lacinia augue. Phasellus tempor pulvinar venenatis.</p>
                                <p>Nunc mollis nunc at magna consectetur efficitur. Aenean congue arcu sit amet commodo faucibus. Donec consectetur massa neque, ut pulvinar massa imperdiet non. Curabitur sollicitudin sit amet ante a interdum. Integer dignissim mauris vitae felis porttitor, id lacinia leo pulvinar. Maecenas quis varius erat. Fusce pharetra mollis neque, ut ultrices tortor imperdiet mattis. Proin tempus imperdiet egestas. Etiam justo magna, viverra a velit id, fringilla molestie purus. Donec dignissim odio lorem.</p>
                                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Suspendisse libero nisl, iaculis at ultricies vulputate, fermentum ac metus. In ac lorem volutpat dui mattis mattis at quis tortor. Duis rhoncus varius venenatis. Donec et lorem tempus, semper odio non, interdum arcu. Cras non laoreet diam. Phasellus scelerisque quam ac nisl congue dignissim.</p>
                                <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean eget ligula et nibh consectetur mattis. Ut mattis risus ipsum, vitae iaculis nisi interdum sed. Aenean in orci ullamcorper, varius purus et, lobortis ligula. Duis in quam ut dui egestas tempus vitae sit amet nulla. Mauris venenatis malesuada massa non semper. Mauris vel enim vulputate, hendrerit lorem suscipit, vestibulum sapien. Nulla est magna, maximus et viverra sit amet, luctus et nibh. Sed eu eleifend velit, at fermentum ante. Sed vel ullamcorper massa. Nulla nec iaculis ante. Fusce neque lectus, finibus et leo vel, pulvinar dapibus neque. Sed quis eros ac arcu fringilla ullamcorper.</p>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div class="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
        </table>
        <!-- Page Body END -->

        
        <!-- Footer -->
        <footer>
            <div class="d-flex align-end">
                <div class="body-2 d-flex align-center" style="width: 70% !important">Ministry of Furniture, Ministry House, Brunel Park, Brunel Way, Baglan Energy Park, Port Talbot, Neath, SA11 2FP Ffôn / Tel: 01639 812382 | Y We / Web: www.ministryoffurniture.com</div>
                <div class="d-flex justify-end align-center" style="width: 30% !important"><v-img max-width="150" max-height="100" contain src="@/assets/logo.png" /></div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    // * DATA
    data: () => ({
        loading: false,
        editmode: false,
        // item
        product: {},
    }),
    methods: {
		print() {
			window.print();
		},
        initialise() {
            this.loading = true;
            this.product = { ...this.datadown };
            this.loading = false;
        },
    },
    created() {
        this.initialise();
    },
};
</script>

<style scoped>
	.noprint { z-index: 999 !important; position: fixed; }

@media print {
	.noprint { display: none; }
}
	
header,
.header-space {
	background-color: #ffffff;
    height: 100px;
    font-weight: bold;
    width: 100%;
	padding-bottom: 15pt;
}
footer,
.footer-space {
	background-color: #ffffff;	
    height: 100px;
    font-weight: bold;
    width: 100%;
    padding-top: 15pt;
}

header {
    position: fixed;
    top: 0;
    /* border-bottom: 1px solid black; */
}

footer {
    position: fixed;
    bottom: 0;
    /* border-top: 1px solid black; */
}
/* } */

</style>