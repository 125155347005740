<template>
  <div class="" style="padding: 0px !important; margin: 0px !important">
    <v-row class="apptext--text" dense style="z-index: 0 !important">
      <!-- Main Content -->
      <v-col cols="12" class=" align-start rounded">
        <v-row dense class="d-flex align-end">
          <v-spacer/>
          <v-btn text @click="MIX_go('/contracts')" class="primary--text font-weight-bold elevation-0">Go To Contracts
            <v-icon>icons8-forward</v-icon>
          </v-btn>
        </v-row>
        <!-- <div v-if="items.length > 0" class="grey--text pl-3 align-center d-flex">
            Here are all other Contracts linked with this Customer
        </div> -->
        <v-row v-if="items.length > 0" class="pa-6 pt-0 pb-0">
          <div class="flex-grow-1">
            <v-text-field
                label="Search Contracts "
                v-model="searchQuery"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                color="primary"
                clearable
                prepend-inner-icon="icons8-search"
                @click:clear="searchQuery = ''"
            ></v-text-field>
          </div>
        </v-row>
        <!-- //* VIEW MODE -->
        <v-row class="pt-3" style="width: 100% !important" no-gutters>
          <v-col cols="12">
            <v-card v-if="items.length > 0" class="elevation-0 grey lighten-4 px-3 pt-3"
                    style="height: 80vh !important; overflow-y: scroll !important ">
              <!-- <pre>{{ items }}</pre> -->
              <div v-for="(item, i) in items" :key="i">
                <contractCard v-on:opencontractitem="openItem" class="mb-2" style="" :actionContract="true"
                              :datadown="item"></contractCard>
              </div>
            </v-card>
            <div class="grey--text d-flex font-weight-bold text-h5 align-center justify-center"
                 v-if="items.length == 0">
              NO CONTRACTS
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-dialog v-if="activeContract" v-model="contractDialog" width="1366px" style="overflow: hidden !important;"
                persistent>
        <!-- <div class="d-flex flex-column ma-0 pa-0"> -->
        <div class="px-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
          <div class="mb-1">
            <v-icon class="white--text" large>icons8-bill</v-icon>
          </div>
          <div class="white--text text-h6 d-flex align-center">
                            <span class="font-weight-normal ml-2">
                                <span v-if="activeContract.contractTitle === ''">New</span>
                                Contract
                              <!-- <span v-if="item.salesTitle !== ''">: {{ item.salesTitle.substring(0, 40) }}...</span> -->
                            </span>
          </div>
          <v-spacer/>
          <div>
            <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeContract()" depressed>
              <v-icon>icons8-multiply</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="pa-0 ma-0 white">
          <!--                        <Contract v-on:refreshItems="refreshitems" :pu="true" :key="dialogContractKey"  :activecontract="activeContract" v-on:close="closeContract" v-on:save="createItem" :orgtype="orgtype"/>-->
        </div>
        <!-- </div> -->
        <!-- <Contract v-on:refreshItems="refreshitems" :key="dialogContractKey"  :datadown="activeContract" v-on:closeitem="closeContract" :orgtype="orgtype"/> -->
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
// import companiesHouse from '@/views/organisations/companiesHouse.vue';
import contractCard from '@/views/sales/ContractCard.vue'
//TODO: Check if this is needed
// import Contract from '@/views/contracts/ContractDetails.vue';
export default {
  name: 'OrganisationDetails',
  components: {
    // 'companies-house': companiesHouse,
    'contractCard': contractCard,
    // 'Contract': Contract,
  },
  props: {
    datadown: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dense: {
      type: Boolean,
      default: false,
    },
    itemnew: {
      type: Boolean,
      default: false,
    },
    listshrunk: {
      type: Boolean,
      default: false,
    },
    orgtype: {
      type: String,
      default: '',
    },
    orgIsGroup: {
      type: Boolean,
      default: false,
    },
    organisations: {
      type: Array,
      default: () => [],
    }
  },
  // * DATA
  data: () => ({
    // Item
    actions: {},
    key: 'contract',
    index: 'idx:contracts',
    items: [],
    itemsTotal: null,
    searchQuery: '',
    itemsFrom: 0,
    itemsTo: 9,
    datatableOptions: {
      page: 1,
      itemsPerPage: 999,
      sortBy: ['contractTitle'],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    },
    sortBy: '@contractTitle',

    stages: [],
    stage: [],
    activeContract: {},
    contractDialog: false,
    dialogContractKey: 0,
    // Headers to display in the Data Table
    headers: [
      {text: 'Name', value: 'contractTitle', sortable: false, hidden: false, expanded: false},
      {text: 'Customer', value: 'orgName', sortable: false, hidden: false, expanded: false},
      {text: 'Date', value: 'contractDate', sortable: false, hidden: false, expanded: true},
      {text: 'Commencement Date', value: 'contractCommencementDate', sortable: false, hidden: false, expanded: false},
      {text: 'Contract Term', value: 'contractTerm', sortable: false, hidden: false, expanded: false},
      {text: 'Renewal/Termination', value: 'contractContinuation', sortable: false, hidden: false, expanded: true},
      {text: 'Subsequent Term', value: 'contractSubsequentTerm', sortable: false, hidden: false, expanded: true},
      {text: 'Status', value: 'contractStatus', sortable: false, hidden: false, expanded: false},
      {text: 'Owner Id', value: 'ownerUserId', sortable: false, hidden: false, expanded: true},
      {text: 'Owner Name', value: 'ownerUserName', sortable: false, hidden: false, expanded: true},
      {text: 'Owner Email', value: 'ownerUserEmail', sortable: false, hidden: false, expanded: true},
      {text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: false, expanded: true},
      {text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true},
      {text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: true},
      {text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true},
      {text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true},
      {text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true},
      {text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true},
      {text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true},
      {text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true},
      {text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false},
    ],

  }),
  computed: {
    ...mapGetters({
      GET_dense: 'GET_dense',
    }),
    inputCols() {
      if (this.listshrunk) {
        return '3';
      } else {
        return '6';
      }
    },
    computedSearchQuery() {
      let searchQuery = this.searchQuery;
      var id = {...this.datadown}
      id = id.id
      var orgs = {}
      // ////console.log("id = " + JSON.stringify(id, null, 2))

      if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
        // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
        //     searchQuery = '*';
        // } else {
        searchQuery = `@deleted:0 `;
        // }
      } else {
        if (searchQuery.startsWith('@')) {
          // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
          //     searchQuery = `${searchQuery}* @orgId:${id}}`;
          // } else {
          searchQuery = `${searchQuery}* @deleted:0`;
          // }
        } else {
          // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
          //     searchQuery = `${searchQuery}* @orgId:{${id}}`;
          // } else {
          searchQuery = `${searchQuery}* @deleted:0`;
          // }
        }
      }
      if (this.orgIsGroup == true && this.organisations.length > 0) {
        orgs = this.organisations
        orgs = orgs.map((y) => y.id).join("|")
        //console.log('orgs = ' + JSON.stringify(orgs, null, 2))
        searchQuery = `${searchQuery} @orgId:{${orgs}}`;
      } else {
        searchQuery = `${searchQuery} @orgId:{${id}}`
      }


      return searchQuery;
    },
    computedFields() {
      let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
      if (!computedFields.includes('@id')) {
        computedFields.push('@id');
      }
      if (!computedFields.includes('@deleted')) {
        computedFields.push('@deleted');
      }
      if (!computedFields.includes('@actionId')) {
        computedFields.push('@actionId');
      }
      computedFields = computedFields.join(',');
      return computedFields;
    },
  },
  // * METHODS
  methods: {
    // async computedItems() {
    //     var t = this;

    //     var items = t.items

    //     items.forEach((item) => {
    //         let result =  t.MIX_redisSearch('idx:stages', `@id:{${item.id}}`, 0, 9999);
    //         // ////console.log("result = " + JSON.stringify(result, null, 2))
    //     })

    //     return items
    // },
    // * GET ITEMS
    async getItems() {
      let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
      if (itemsResult.code === 1) {
        this.itemsTotal = itemsResult.data.total;
        this.items = itemsResult.data.results;
        let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
        if (itemsTo < this.itemsTotal) {
          this.itemsTo = itemsTo;
        } else {
          this.itemsTo = this.itemsTotal;
        }
      } else {
        // // ////console.log("items = " + JSON.stringify(itemsResult, null, 2));
      }
    },
    // * CREATE ITEM
    async createItem(item) {
      let t = this;
      // var item = t.item
      // ////console.log("creating lead + " + JSON.stringify(item, null, 2))

      let result = await t.MIX_createItem(item, t.key, t.itemNew);
      if (result.code === 1) {
        t.MIX_alertBox({color: "success", timeout: 2000, message: "Saved", show: true});
        // t.readItem(item.id);
        // t.$emit("refreshItems");
        t.closeContract()
      }
    },
    refreshitems() {
      ////console.log("refreshing items")
      this.refreshItems++
    },
    refreshItems: function () {
      this.getItems();
    },

    // * OPEN ITEM
    async openItem(item) {
      ////console.log("open item = " + JSON.stringify(item, null, 2))
      // this.activeContract = item;
      // this.readItem(this.activeContract.id)
      let result = await this.MIX_readItem(item.id, 'contract');
      if (result.code === 1) {
        this.activeContract = result.data;
      }
      this.contractDialog = true
    },
    closeContract() {
      this.activeContract = {}
      this.contractDialog = false
      this.dialogContractKey++
      this.getItems()
    },

  },
  // * WATCH
  watch: {
    datadown() {
      var t = this;
      // t.actions = { ...t.datadown };
      t.getItems()
      // // ////console.log("item customer = " + JSON.stringify(t.item, null, 2))
    },
    // If the search query changes then get updated dataset based on search query
    computedSearchQuery() {
      this.getItems();
    },
  },
  // * CREATED
  async created() {
    let t = this;
    t.actions = {...t.datadown};
    this.getItems();

    // let stages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
    // stages = stages.data.documents.map((x) => x.value);
    // // // ////console.log("stages = " + JSON.stringify(stages, null, 2));
    // t.stages = stages.filter((item) => {
    //     return item.stagePipeline === 'Contracts';
    // })
    // t.stages = t.stages.map((x) => {
    //     return {
    //         stageId: x.id,
    //         stageName: x.stageName,
    //         stageOutcome: x.stageOutcome
    //     };
    // })

  },
};
</script>

<style>
/* .borderBottom {
    border-bottom: 5px solid #019EAF !important;
    border-color: #019EAF !important
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-bottom: 5px solid #019EAF !important;
    border-color: #019EAF !important
} */
</style>
