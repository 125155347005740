<template>
    <v-text-field
        :label="label"
        v-model="innerValue"
        :error-messages="validateMessage"
        :rounded="rounded"
        :type="type"
        :filled="filled"
        :hint="hint"
        :persistent-hint="hint ? true : false"
        :dense="dense"
        :placeholder="placeholder"
        class="rounded-of"
        :background-color="backgroundcolor"
        hide-details="auto"
        :disabled="disabled"
        :clearable="clearable"
        @click:clear="clearText()"
		:autocomplete="autocomplete ? 'on':'donotautocomplete'"
        :readonly="readonly">
        <!-- <template v-slot:append>
            <div v-if="innerValue" class="d-flex align-center" :style="`height: ${height}px !important;`">
               <v-icon @click="clear()" class="grey--text">icons8-cancel</v-icon>
            </div>
        </template>   -->
    </v-text-field>
</template>

<script>
export default {
    props: {
        rounded: {
            type: Boolean,
            default: true,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        value: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        validate: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        backgroundcolor: {
            type: String,
            default: "grey lighten-4",
        },
        placeholder: {
            type: String,
            default: "",
        },
        hint: {
            type: String,
            default: "",
        },
        dense: {
            type: Boolean,
            default: true,
        },
        mask: {
            type: String,
            default: "",
        },
		autocomplete: {
			type: Boolean,
			defaukt: false,
		}
    },
    data:() => ({
        innerValue: "",
        validateMessage: "",
    }),
    methods: {
        clearText() {
            setTimeout(() => {
                this.innerValue = "";
            }, 1);
        }
    },
    watch: {
        value: function (value) {
            this.innerValue = value;
        },
        innerValue: function (value) {
            this.$emit("input", value);
            this.validateMessage = "";
        },
        validate: function (value) {
            this.validateMessage = value;
        },
    },
    mounted() {
        this.innerValue = this.value;
        this.validateMessage = this.error;
    },
};
</script>
