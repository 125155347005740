var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3 white",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-left primary--text text-h5 font-weight-light"},[_vm._v("Import CSV")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.closeImport()}}},[_vm._v("icons8-multiply")])],1),_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.importTypes,"label":"Import Type"},model:{value:(_vm.importType),callback:function ($$v) {_vm.importType=$$v},expression:"importType"}}),_c('v-divider',{staticClass:"primary-lighten-2 pb-4"}),_c('input',{ref:"input",attrs:{"hidden":"","type":"file","accept":".csv"},on:{"change":function($event){return _vm.handleFileUpload($event)}}}),_c('div',{staticClass:"py-3 d-flex align-center"},[_c('v-btn',{staticClass:"accent secondary--text",attrs:{"depressed":""},on:{"click":function($event){return _vm.$refs.input.click()}}},[_vm._v("Upload CSV")]),_c('v-btn',{staticClass:"grey lighten-1 secondary--text ml-3",attrs:{"depressed":""},on:{"click":function($event){_vm.content = [];
				_vm.parsed = false;}}},[_vm._v("Clear Data")]),_c('v-spacer'),(JSON.stringify(_vm.content) !== '[]')?_c('div',[(_vm.csvHeadersNotRecognised.length > 0)?_c('div',{staticClass:"font-weight-bold error--text"},[_vm._v("CSV contained unrecognised headers")]):_vm._e(),(JSON.stringify(_vm.csvHeadersNotRecognised) !== '[]')?_c('div'):_vm._e(),_c('v-btn',{staticClass:"primary white--text",attrs:{"depressed":""},on:{"click":_vm.importFile}},[_vm._v("Import "+_vm._s(_vm.content.data.length)+" Records")])],1):_vm._e()],1),(JSON.stringify(_vm.content) !== '[]')?_c('div',[_c('v-tabs',{attrs:{"background-color":"secondary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#csvdata"}},[_vm._v(" CSV Data ")]),_c('v-tab',{attrs:{"href":"#importresult"}},[_vm._v(" Import Result ")]),_c('v-tabs-items',{attrs:{"fill-height":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"csvdata"}},[_vm._v(" duplicateIDs: "+_vm._s(_vm.duplicateIDs.length)+" / duplicateIDs: "+_vm._s(_vm.duplicateIDs)+" "),(JSON.stringify(_vm.csvHeadersNotRecognised) !== '[]')?_c('div',[_vm._v("Headers not recognised: "+_vm._s(_vm.csvHeadersNotRecognised.join(", ")))]):_c('div',[_vm._v(" All Headers Recognised"),_c('br'),_c('v-btn',{on:{"click":function($event){_vm.selectedHeaders = _vm.csvHeadersAll}}},[_vm._v("Select All Headers")]),_vm._v(" Selected Headers for Import: "+_vm._s(_vm.selectedHeaders)+" "),_c('div',{staticStyle:{"width":"100% !important"}},[_c('v-data-table',{staticClass:"truncate",attrs:{"headers":_vm.computedHeaders,"items":_vm.content.data,"items-per-page":20,"calculate-widths":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
				var props = ref.props;
return _vm._l((props.headers),function(head){return _c('th',{key:head.value,attrs:{"align":"center"}},[_c('v-checkbox',{staticClass:"ma-0",attrs:{"small":"","value":head.text,"color":"primary"},model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1)})}}],null,false,1327165363)})],1)],1)]),_c('v-tab-item',{attrs:{"value":"importresult"}},[(JSON.stringify(_vm.importResult) !== '[]')?_c('div',{staticStyle:{"width":"100% !important"}},[_c('div',{staticClass:"py-4"},[_c('table',{attrs:{"cellspacing":"10"}},[_c('tr',[_c('td',{},[_vm._v("Total:")]),_c('td',{staticClass:"grey white--text font-weight-bold",staticStyle:{"width":"50px !important"},attrs:{"align":"center"},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(_vm._s(_vm.computedImportResultSummary.total))]),_c('td',{},[_vm._v("Errors:")]),_c('td',{staticClass:"error white--text font-weight-bold",staticStyle:{"width":"50px !important"},attrs:{"align":"center"},on:{"click":function($event){_vm.filter = 'error'}}},[_vm._v(_vm._s(_vm.computedImportResultSummary.errors))]),_c('td',{},[_vm._v("Success:")]),_c('td',{staticClass:"success white--text font-weight-bold",staticStyle:{"width":"50px !important"},attrs:{"align":"center"},on:{"click":function($event){_vm.filter = 'success'}}},[_vm._v(_vm._s(_vm.computedImportResultSummary.success))])])])]),_c('v-data-table',{staticClass:"truncate",attrs:{"headers":_vm.computedImportHeaders,"items":_vm.computedImportResult,"items-per-page":20,"calculate-widths":"","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.importStatus",fn:function(ref){
				var item = ref.item;
return [_c('v-icon',{class:item.importStatus + '--text'},[_vm._v("icons8-filled-circle")])]}},{key:"expanded-item",fn:function(ref){
				var headers = ref.headers;
				var item = ref.item;
return [_c('td'),_c('td'),(item.importErrors !== '')?_c('td',{staticClass:"error--text",attrs:{"colspan":headers.length - 2}},[_c('strong',[_vm._v("Import Errors:")]),_c('br'),_c('pre',[_vm._v(_vm._s(item.importErrors))])]):_vm._e()]}}],null,false,59421128)})],1):_vm._e()])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }