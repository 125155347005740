<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow-y: scroll !important">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="mx-4 d-flex align-center" style="width: 100% !important">
                    <v-btn text class="accent secondary--text" @click="dialogAddress = true"><v-icon class="mr-2">icons8-map</v-icon>Add Address</v-btn>

                    <!-- <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="saveItem"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                    <v-spacer />
                    <v-btn text class="grey lighten-2 secondary--text" v-if="editmode" @click="cancelItem"><v-icon class="mr-2">icons8-multiply</v-icon>Cancel</v-btn> -->
                </div>
            </v-col>
        </v-row>
        <v-row dense class="mx-2">
            <v-col cols="12">
                <!-- TODO - RG - Edit item from within address-list -->
                <address-list :datadown="org" v-on:edit="editItem" :listshrunk="listshrunk" :refreshitems="refreshitems" />
            </v-col>
        </v-row>

         <!-- // * ADD NEW ADDRESS -->
        <v-dialog v-model="dialogAddress" width="1024">
            <v-card class="mx-auto rounded">
                <address-item :datadown="org" :activeitem="activeItem" :key="dialogAddressKey" v-on:close="closeAddress" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import address from "@/views/address/addressItem.vue";
import addressList from "@/views/address/addressList.vue";
export default {
    // * COMPONENTS
    components: {
        "address-item": address,
        "address-list": addressList
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        key: 'address',
        // toggles

        activeItem: {},

        // dialogs
        dialogAddress: false,
        dialogAddressKey: 0,

        org: {},

        // dropdowns
        addressTypes: [],

        refreshitems: 0

    }),
    // * COMPUTED
    computed: {
    },
    // * METHODS
    methods: {
        closeAddress() { 
            this.activeItem = {};
            this.dialogAddressKey++
            this.dialogAddress = false; 
            this.refreshitems++
        },
        editItem(value) {
            this.activeItem = value;
            this.dialogAddress = true;
        }
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.org = { ...this.datadown };
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        this.org = { ...this.datadown };
    },
};
</script>