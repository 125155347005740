<template>
    <v-row class="pa-0 ma-0" fill-height>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" class="pa-0">
            <v-row no-gutters class="pb-3 px-3 pt-3 secondary">
                <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                    <div class="mx-3"><v-icon class="white--text" :large="!GET_dense">icons8-user-groups</v-icon></div>
                    <div>
                        <span class="font-weight-light"><strong>Person: </strong>{{ item.contactFirstName }} {{ item.contactLastName }}</span>
                    </div>

                    <div class="ml-2 font-weight-light" v-if="item.contactFirstName === ''">New</div>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn class="ml-2 white--text" plain :large="!GET_dense" @click="closeItem" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" class="pa-0" fill-height cols="auto" style="width: 100% !important">
            <v-tabs background-color="white" v-model="tab" show-arrows>
                <v-tab href="#details">Details</v-tab>
                <v-tab :disabled="itemNew" href="#notes">Notes</v-tab>
                <!-- <v-tab disabled href="#activities">Activities</v-tab>
                <v-tab disabled href="#activities">Related</v-tab>
                <v-tab disabled href="#activities">History</v-tab> -->

                <!-- <v-tab href="#contacts">Contacts</v-tab> -->
            </v-tabs>
            <v-tabs-items v-model="tab" fill-height>
                <!-- Fabric Tab -->
                <v-tab-item value="details" class="grey lighten-3">
                    <contact-details :org="org" :fullContact="true" dense :datadown="item" :newItem="itemNew" v-on:save="createItem" />
                </v-tab-item>
                <!-- Notes Tab -->
                <v-tab-item value="notes" class="grey lighten-3" fill-height>
                    <notes :datadown="item" :listshrunk="listshrunk" v-on:save="saveItem" v-on:cancel="cancelItem" />
                </v-tab-item>                
                <!-- Contacts Tab -->
                <v-tab-item value="contacts" class="grey lighten-3">
                    <organisation-contacts :org="item" />
                </v-tab-item>
            </v-tabs-items>
        </v-col>

        <!-- Mobile Tabs -->
        <v-row no-gutters  v-if="$vuetify.breakpoint.smAndDown">
            <contact-details :fullContact="true" dense :datadown="item" :newItem="itemNew" v-on:close="closeItem" v-on:save="createItem" />
        </v-row>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import contactDetails from '@/views/contacts/contactDetails.vue';
import organisationContacts from '@/views/organisations/organisationContacts.vue';
import notes from '@/views/base/notes.vue';
import schema from '@/schema.js';
export default {
    // * COMPONENTS
    components: {
        'organisation-contacts': organisationContacts,
        'contact-details': contactDetails,
        'notes': notes
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        org: {
            type: Object,
            default: function () {
                return {}
            },
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },        
    },
    // * DATA
    data: () => ({
        key: 'contact',
        tab: 'details',
        schema: null,
        // Item
        item: {},
        itemNew: false,
        editMode: false,
        // Form items
        accountTypes: [],
        addressTypes: [],
        industries: [],
        // Tags
        computedOrgTags: [],
        // Validation
        rules: {},
        // Toggles
        addAddressToggle: false,
        addManualAddress: false,
        addOtherAddressToggle: false,
        addLeadStatusToggle: false,
        addOrgInfoToggle: false,
        addSocialInfoToggle: false,
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_dense: 'GET_dense',
            GET_destroy: 'GET_destroy',
        }),
        computedFullName() {
            return this.item.contactFirstName + ' ' + this.item.contactLastName;
        },
    },
    // * METHODS
    methods: {
        // * INITIALISE ITEM
        initialiseItem() {
            let t = this;
            if (JSON.stringify(t.datadown) !== '{}') {
                this.readItem(t.datadown.id);
                t.editMode = false;
                console.log('existing contact')
            } else {
                console.log('new contact')
                t.itemNew = true;
                t.editMode = true;
                t.item = { ...t.schema };
                t.item.id = t.MIX_generateId();
            }
        },
        // * VALIDATION RULES
        // TODO - RG - I think this needs to be moved to contactDetails.vue
        validateItem() {
            let t = this;
            t.rules = {
                // GENERAL DETAILS
                orgName: [(v) => !!v || 'Organisation Name Required'],
                // orgRegisteredName: [ v => !!v || 'Registered Name Required'],
                // orgRegistrationNumber: [ v => !!v || 'Registration Number Required'],
                // orgTelephone: [ v => !!v || 'Telephone Required'],
                // orgWebsite: [ v => !!v || 'Website Required'],
                // orgEmail: [ v => !!v || 'Email Required'],
                // orgInvoiceEmail: [ v => !!v || 'Invoice Email Required'],
                // orgPurchasingEmail: [ v => !!v || 'Purchasing Email Required'],

                // ADDRESS DETAILS
                // orgAddressType: [ v => !!v || 'Address Type Required'],
                // orgAddressLine1: [ v => !!v || 'Address Line 1 Required'],
                // orgAddressLine2: [ v => !!v || 'Address Line 2 Required'],
                // orgAddressLine3: [ v => !!v || 'Address Line 3 Required'],
                // orgTown: [ v => !!v || 'Town Required'],
                // orgCounty: [ v => !!v || 'County Required'],
                // orgPostcode: [ v => !!v || 'Postcode Required'],

                // OTHER ADDRESS DETAILS
                // orgOtherAddressType: [ v => !!v || 'Address Type Required'],
                // orgOtherAddressLine1: [ v => !!v || 'Address Line 1 Required'],
                // orgOtherAddressLine2: [ v => !!v || 'Address Line 2 Required'],
                // orgOtherAddressLine3: [ v => !!v || 'Address Line 3 Required'],
                // orgOtherTown: [ v => !!v || 'Town Required'],
                // orgOtherCounty: [ v => !!v || 'County Required'],
                // orgOtherPostcode: [ v => !!v || 'Postcode Required'],

                // ORG INFO
                // orgEmployees: [ v => !!v || 'No of Employees Required'],
                // orgIndustry: [ v => !!v || 'Industry Required'],
                // orgSector: [ v => !!v || 'Sector Required'],
                // orgAccountNumber: [ v => !!v || 'Account Number Required'],
                //orgAccountTypes: [ v => !!v || 'Account Type Required'],
                // orgLocalAuthority: [ v => !!v || 'Local Authority Required'],
                // orgTurnover: [ v => !!v || 'Turnover Required'],

                // OWNER / SOURCE INFO
                // orgAccountOwner: [ v => !!v || 'Account Owner Required'],
                // orgSource: [ v => !!v || 'Source Required'],
                // orgSourceName: [ v => !!v || 'Source Name Required'],

                // SOCIAL INFO
                // orgTwitter: [ v => !!v || 'Twitter Required'],
                // orgFacebook: [ v => !!v || 'Facebook Required'],
                // orgLinkedIn: [ v => !!v || 'LinkedIn Required'],
                // orgInstagram: [ v => !!v || 'Instagram Required'],
            };
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    this.createItem();
                    this.editMode = false;
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Fill out all the fields correctly in order to proceed.', show: true });
                }
            });
        },
        // * CREATE ITEM
        async createItem(item) {
            let t = this;
            let result = await t.MIX_createItem(item, t.key, t.itemNew);
            if (result.code === 1) {
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                t.readItem(item.id);
                t.$emit('refreshitems');
            }
        },
        // * READ ITEM
        async readItem(itemId) {
            let t = this;
            let result = await t.MIX_readItem(itemId, t.key);
            if (result.code === 1) {
                this.item = result.data;
                t.itemNew = false;
                t.loading = false;
            }
        },
        // * DESTROY ITEM
        async destroyItem() {
            let result = await this.MIX_destroyItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        // * DELETE ITEM
        async deleteItem() {
            let result = await this.MIX_deleteItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        // * RESTORE ITEM
        async restoreItem() {
            let result = await this.MIX_restoreItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        // Close Item
        closeItem() {
            this.$emit('closeitem');
        },
        cancelItem() {
            this.readItem(this.item.id);
        },

        // Save Item
        async saveItem(item) {
            if (this.itemNew) {
                this.createItem(item);
            } else {
                let result = await this.MIX_createItem(item, this.key, this.itemNew);
                if (result.code === 1) {
                    this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                    this.readItem(item.id);
                }
            }
            this.$emit('updateQuote', item);
        },        
        
    },
    // * WATCH
    watch: {
        // Datadown Changes
        datadown() {
            this.initialiseItem();
        },
        computedOrgTags() {
            var t = this;
            var splitVal = [];
            splitVal = t.item.orgTags.split(',');
            return splitVal;
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.loading = true;
        t.schema = schema.contact;
        t.initialiseItem();

        let accountTypes = await t.MIX_redisReadSet('set:orgAccountTypes', false);
        if (accountTypes.code === 1) {
            t.accountTypes = accountTypes.data;
        }

        let addressTypes = await t.MIX_redisReadSortedSet('sset:addressTypes', 0, 9999);
        if (addressTypes.code === 1) {
            t.addressTypes = addressTypes.data;
        }

        let industries = await t.MIX_redisReadSet('set:industries', true);
        if (industries.code === 1) {
            t.industries = industries.data;
        }
    },
    // * MOUNTED
    async mounted() {},
};
</script>

<style scoped>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 22px;
}
</style>
