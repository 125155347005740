const attribute = {
	id: "",
	attName: "",
	attCode: "",
	attType: "",
	attMTO: "",
	attPriceBand: "",
	attAddCost: 0,
	attAddSell: 0,
	attEnabled: 1,
	attParentId: "",
	attDescription: "",
	attHexColor: "",
	attImageId: "",
	attSupplier: "0",
	optionId: "",
	optionName: "",	
	orgId: "",
	orgName: "",
	enabled: "1",
	key: "attribute",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
}

const option = {
	id: "",
	optionName: "",
	optionType: "",
	optionVariant: "0",
	enabled: "1",
	key: "option",
	// Organisation Fields
	orgId: "",
	orgName: "",	
	optionSupplier: "0",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
}
// * ORGANISATION
const organisation = {
    // GENERAL INFO
	id: "",
	orgName: "",
	orgRegisteredName: "",
	orgRegistrationNumber: "",
	orgTelephone: "",
	orgOtherTelephone: "",
	orgWebsite: "",
	orgEmail: "",
	orgInvoiceEmail: "",
	orgPurchasingEmail: "",
	orgStatus: "",
	orgImageId: "",
	orgApproved: "0",
	orgProfileScore: "0",

    // ADDRESS DETAILS
	orgAddressLine1: "",
	orgAddressLine2: "",
	orgAddressLine3: "",
	orgTown: "",
	orgCounty: "",
	orgCountry: "",
	orgPostcode: "",
	orgLat: "",
	orgLng: "",

    // ORG INFO
	orgChannel: "",
	orgEmployees: "",
	orgPupils: "",
	orgIndustry: "",
	orgSector: "",

	// Finance / Accounting
	orgFinanceAccountNumber: "",
	orgAccountCreditLimit: "",
	orgAccountOnHold: "0",


    orgPriceFileChange: '',

	orgAccountTypes: [],
	orgLocalAuthority: "",
	orgTurnover: "",

    // Notes
	notes: "",
    leadTimeNotes: "",

    // OWNER / SOURCE INFO
	orgSource: "",
	orgSourceName: "",
	orgTags: "",

    // SOCIAL INFO
	orgTwitter: "",
	orgFacebook: "",
	orgLinkedIn: "",
	orgInstagram: "",

	// Owner
	ownerUserId: "",
	ownerUserName: "",
	ownerUserEmail: "",	

	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
    key: "org",
    enabled: '1'
}

const product = {
	id: "",
	// Details
	productName: "",
	productCode: "",
	productSupplierCode: "",
	productPreCode: "",
	productPreviousCode: "",
	productStatus: "",
	productAvailableFrom: "",
	productAvailableTo: "",
	productShortDescription: "",
	productDescription: "",
	productImageId: "",
	productImageOldPath: "",
	productLeadTimeDays: "",
	productMTO: "0",
	productMOFWebLink: "",
	productSupplierWebLink: "",

    productWebLink1: "",
    productWebLink2: "",
    productWebLink3: "",


	// Grouping
	productChannel: "",
	productCategory: "",
	productSubcategory: "",
	productItemType: "",
	productType: "",
	productRange: "",
	productFitType: "L",
	// Tags
	productTags: "",
	enabled: "1",
	// Dimensions
	productWidth: "",
	productHeight: "",
	productFrontWidth: "",
	productBackWidth: "",
	productSeatHeight: "",
	productInternalLegWidth: "",
	productDimensions: "",
	// Recycling
	productRecyclability: "",
	productRecycledContent: "",
	// Features Tags
	productFeaturesTags: "",
	// Suppliers
	orgId: "",
	orgName: "",
	productLeadTime: "",
	// Options & Variants
	hasOptions: "0",
	hasVariants: "0",
	hasExtras: "0",
	isExtra: "0",
	isExtraSoldSeparately: "0",
	// Pricing
	MOQ: "1", // Minimum Order Qty
	pricingType: "Simple", // Pricing Type
	IMP: "",
	RRP: "", // Recommended Retail Price
    
	MP: "", // Market Price
    MPLastUpdatedDateTime: "",
    MPLastUpdatedUserId: "",

	cQ1: "", // Cost Qty 1
	cP1: "", // Cost Price 1
	cQ2: "", // Cost Qty 2
	cP2: "", // Cost Price 2
	cQ3: "", // Cost Qty 3
	cP3: "", // Cost Price 3
	sQ1: "", // Sell Qty 1
	sP1: "", // Sell Price 1
	sQ2: "", // Sell Qty 2
	sP2: "", // Sell Price 2
	sQ3: "", // Sell Qty 3
	sP3: "", // Sell Price 3
	cST: "", // Cost Stocked
	sST: "", // Sell Stocked
	cBA: "", // Cost Band A
	cBB: "", // Cost Band B
	cBC: "", // Cost Band C
	cBD: "", // Cost Band D
	cBE: "", // Cost Band E
	cBF: "", // Cost Band F
	sBA: "", // Sell Band A
	sBB: "", // Sell Band B
	sBC: "", // Sell Band C
	sBD: "", // Sell Band D
	sBE: "", // Sell Band E
	sBF: "", // Sell Band F
	NPS: "", // NPS Core Product Pricing
	discountGroup1: "0", // Custom Pricing 1
	discountGroup2: "0", // Custom Pricing 2
	discountGroup3: "0", // Custom Pricing 3
	discountGroup4: "0", // Custom Pricing 4
	discountGroup5: "0", // Custom Pricing 5
	discountGroup6: "0", // Custom Pricing 6
	discountGroup7: "0", // Custom Pricing 7
	discountGroup8: "0", // Custom Pricing 8
	discountGroup9: "0", // Custom Pricing 9
	discountGroup10: "0", // Custom Pricing 10
	//product option data
	optionName1: "",
	optionAttributes1: "",
	optionName2: "",
	optionAttributes2: "",
	optionDependancy2: "",
	optionName3: "",
	optionAttributes3: "",
	optionDependancy3: "",
	optionName4: "",
	optionAttributes4: "",
	optionDependancy4: "",
	optionName5: "",
	optionAttributes5: "",
	optionDependancy5: "",	
	optionName6: "",
	optionAttributes6: "",
	optionDependancy6: "",
	optionName7: "",
	optionAttributes7: "",
	optionDependancy7: "",
	optionName8: "",
	optionAttributes8: "",
	optionDependancy8: "",
	optionName9: "",
	optionAttributes9: "",
	optionDependancy9: "",
	optionName10: "",
	optionAttributes10: "",
	optionDependancy10: "",					
	// Standard Fields
	key: "product",
	deleted: "0",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
	// TODO - RG - Sustainable options %
}

const variant = {
	id: "",
	// Details
	variantCode: "",
	variantSupplierCode: "",
	variantPreviousCode: "",
	variantDescription: "",
	productCode: "",
	productId: "",
	variantStatus: "",
	variantAvailableFrom: "",
	variantAvailableTo: "",
	variantImageId: "",
	variantImageOldPath: "",
	variantMTO: "0",
	enabled: "0",
	// Suppliers
	orgId: "",
	orgName: "",
	//product option data
	optionName1: '',
	optionValue1: '',
	optionName2: '',
	optionValue2: '',
	optionName3: '',
	optionValue3: '',
	optionName4: '',
	optionValue4: '',
	optionName5: '',
	optionValue5: '',
	optionName6: '',
	optionValue6: '',
	optionName7: '',
	optionValue7: '',
	optionName8: '',
	optionValue8: '',
	optionName9: '',
	optionValue9: '',
	optionName10: '',
	optionValue10: '',						
	// Pricing
	MOQ: "1", // Minimum Order Qty
	pricingType: "Simple", // Pricing Type
	IMP: "", // Install Margin Percent
	RRP: "0", // Recommended Retail Price
	MP: "0", // Market Price
	cQ1: "0", // Cost Qty 1
	cP1: "0", // Cost Price 1
	cQ2: "0", // Cost Qty 2
	cP2: "0", // Cost Price 2
	cQ3: "0", // Cost Qty 3
	cP3: "0", // Cost Price 3
	sQ1: "0", // Sell Qty 1
	sP1: "0", // Sell Price 1
	sQ2: "0", // Sell Qty 2
	sP2: "0", // Sell Price 2
	sQ3: "0", // Sell Qty 3
	sP3: "0", // Sell Price 3
	cST: "0", // Cost Stocked
	sST: "0", // Sell Stocked	
	cBA: "0", // Cost Band A
	cBB: "0", // Cost Band B
	cBC: "0", // Cost Band C
	cBD: "0", // Cost Band D
	cBE: "0", // Cost Band E
	cBF: "0", // Cost Band F
	sBA: "0", // Sell Band A
	sBB: "0", // Sell Band B
	sBC: "0", // Sell Band C
	sBD: "0", // Sell Band D
	sBE: "0", // Sell Band E
	sBF: "0", // Sell Band F
	NPS: "0", // NPS Core Product Pricing
	discountGroup1: "0", // Custom Pricing 1
	discountGroup2: "0", // Custom Pricing 2
	discountGroup3: "0", // Custom Pricing 3
	discountGroup4: "0", // Custom Pricing 4
	discountGroup5: "0", // Custom Pricing 5
	discountGroup6: "0", // Custom Pricing 6
	discountGroup7: "0", // Custom Pricing 7
	discountGroup8: "0", // Custom Pricing 8
	discountGroup9: "0", // Custom Pricing 9
	discountGroup10: "0", // Custom Pricing 10
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "variant",
}

const quote = {
	id: "",
	// Quote Details
	quoteLocked: "0",
	quoteNumber: "",
	quoteClientRef: "",
	quoteVersion: "0",
	quoteCurrentVersion: "0",
	quoteStatus: "Draft",
	quoteTitle: "",
	quoteCustomerNote: "",
	quoteInternalNote: "",
	quoteChannel: "",
	quoteNPS: "0",
	quoteType: "",
	quoteDate: "",
	quoteExpiryDate: "",
	quoteDeliveryDate: "",
	quoteValidityDays: "30",
	quoteTerms: "",
	quoteInstall: "0",

	//sales additions
	quoteID: '',
	opportunityID: '',
	leadID: '',

    // MCD
    quoteMCDRate: "0",
    quoteMDCDiscount: "",

    // Approval Fields
	approved: "0",

	// quoteApprovalLevel: "",
	// quoteFirstLevelStatus: "",
	// quoteFirstLevelApprovedBy: "",
	// quoteFirstLevelDeniedBy: "",
	// quoteSecondLevelStatus: "",
	// quoteSecondLevelApprovedBy: "",
	// quoteSecondLevelDeniedBy: "",


    quoteInstallApproval: '0',
    quoteInstallApprovalStatus: 'N/A',
    quoteInstallApprovedBy: '',
    quoteInstallDeniedBy: '',

    quoteSupplyChainApproval: '0',
    quoteSupplyChainApprovalStatus: 'N/A',
    quoteSupplyChainApprovedBy: '',
    quoteSupplyChainDeniedBy: '',

    quoteSalesManagerApproval: '0',
    quoteSalesManagerApprovalStatus: 'N/A',
    quoteSalesManagerApprovedBy: '',
    quoteSalesManagerDeniedBy: '',

    quoteMDDirectorApproval: '0',
    quoteMDDirectorApprovalStatus: 'N/A',
    quoteMDDirectorApprovedBy: '',
    quoteMDDirectorDeniedBy: '',

    quoteFDDirectorApproval: '0',
    quoteFDDirectorApprovalStatus: 'N/A',
    quoteFDDirectorApprovedBy: '',
    quoteFDDirectorDeniedBy: '',



    // Approval Notes 
    firstLevelApprovalNotes: '',
    firstLevelApprovalUserId: '',
    firstLevelRejectionNotes: '',
    firstLevelRejectionUserId: '',
    secondLevelApprovalNotes: '',
    secondLevelApprovalUserId: '',
    secondLevelRejectionNotes: '',
    secondLevelRejectionUserId: '',

    quoteWinPercentage: 0,
    
	// Totals
	totalItems: "",
	totalInstallCost: "",
	totalInstallSell: "",
	totalInstallMarginValue: "",
	totalInstallMarginPercent: "",
	totalCost: "",
	totalSell: "",
	totalSellMargin: "",
	totalMargin: "",
	// Tags
	quoteTags: "",
	orgTags: "",
	// Rooms and Floors
	quoteRoomTags: "",
	quoteFloorTags: "",
    quoteOptionalTags: "",
	// Customer Details
	orgId: "",
	orgName: "",
	orgImageId: "",
	// Contact Details
	contactId: "",
	contactName: "",
	contactEmail: "",
	// Notes
	notes: "",
	// Owner
	ownerUserId: "",
	ownerUserName: "",
	ownerUserEmail: "",
	// Prepared By
	preparedByUserId: "",
	preparedByUserName: "",
	preparedByUserEmail: "",
	// Order
	order: "0",
	orderId: "",
	orderUserId: "",
	orderUserName: "",
	orderUserEmail: "",	
	// Address Details
	orgDeliveryAddressLine1: "",
	orgDeliveryAddressLine2: "",
	orgDeliveryAddressLine3: "",
	orgDeliveryTown: "",
	orgDeliveryCounty: "",
	orgDeliveryPostcode: "",
	orgInvoiceAddressLine1: "",
	orgInvoiceAddressLine2: "",
	orgInvoiceAddressLine3: "",
	orgInvoiceTown: "",
	orgInvoiceCounty: "",
	orgInvoicePostcode: "",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "quote",	
}

const lineItem = {
	id: "",
	lineCode: "",
	lineCodeId: "",
	lineParentCode: "",
	lineParentCodeId: "",
	quoteId: "",
	orgId: "",
	orgName: "",
	lineImageId: "",
	lineDescription: "",
	lineEdit: "0",
	lineExtend: "0",
	lineOrder: "",
	lineReference: "",
	lineProductType: "",
	lineType: "", // Product / Line Break
	lineSOPType: "", // Quote / Order / Purchase Order	
	lineQty: "",
	lineIMP: "",
	lineMOQ: "",
	lineCost: "",
	lineCostBase: "",
	lineSell: "",
	lineSellBase: "",
	lineMarginPercent: "",
	lineMarginValue: "",
	lineNPS: "",
	lineNPSEnable: "0",
	lineRRP: "",
	lineRRPBase: "",
	lineMP: "",
	lineTotalCost: "",
	lineTotalSell: "",
	lineTotalMarginPercent: "",
	lineTotalMarginValue: "",
	lineDiscount: "",
	lineMCDRate: "",
    lineMCDDiscount: "",
	lineTotal: "",
	lineFieldsChanged: "",
	lineOptional: "0", // This is an optional line item
	lineIncludeTotal: "1", // Include the Line Total in Quote Total 0 / 1
	lineMarginReason: "", // Reasons for Margin
	lineMarginComments: "", // Comments for Margin
	lineFeatures: "",
	// Room and Floors
	lineRoomTag: "",
	lineFloorTag: "",
    lineOptionalTags: "",
    lineAuthRequired: '0',


	// Grouping
	productChannel: "",
	productCategory: "",
	productSubcategory: "",
	productItemType: "",
	productType: "",
	productRange: "",
	productFitType: "L",

    
	// PGSR
	lineChannel: "",
	lineCategory: "",
	lineSubcategory: "",
	lineItemType: "",
	lineRange: "",
	lineFitType: "",
	// TODO - RG - Make sure PGSR is written from calcualate.js
	// Tags
	productTags: "",
	// Options
	optionName1: "",
	optionName2: "",
	optionName3: "",
	optionName4: "",
	optionName5: "",
	optionName6: "",
	optionName7: "",
	optionName8: "",
	optionName9: "",
	optionName10: "",
	optionValue1: "",
	optionValue2: "",
	optionValue3: "",
	optionValue4: "",
	optionValue5: "",
	optionValue6: "",
	optionValue7: "",
	optionValue8: "",
	optionValue9: "",
	optionValue10: "",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "lineItem"
	// TODO - RG - Line Items need the following fields Rooms, Floors, Product Categories, SubCategories, Types
}

// Contact
const contact = {
    id: "",
    contactTitle: "",
    contactFirstName: "",
    contactLastName: "",
	contactFullName: "",
    contactEmail: "",
    contactAlternateEmail: "",
    contactUnsubscribe: "0",
	contactNumber:"",
    contactMobilePhone: "",
    contactDDIPhone: "",
	contactSwitchboard: "",
	contactExtension: "",
    contactJobTitle: "",
	contactJobLevel: "",
    contactJobDepartment: "",
	// Addresses
    contactAddressLine1: "",
    contactAddressLine2: "",
    contactAddressLine3: "",
    contactTown: "",
    contactCounty: "",
    contactPostcode: "",
	contactStatus: "",
    contactSource: "",
    contactSourceName: "",
    orgId: "",
    orgName: "",
	// Notes
	notes: "",
    contactReportingTo: "",
    contactTwitter: "",
    contactFacebook: "",
    contactLinkedIn: "",
    contactInstagram: "",
    contactNotes: "",
	contactProfileScore: "0",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "contact",
}
const address = {
	addressType: '',
	addressName: '',
	addressLine1: '',
	addressLine2: '',
	addressLine3: '',
	addressTown: '',
	addressCounty: '',
	addressPostcode: '',
	addressCountry: '',
	addressLat: '',
	addressLng: '',
	orgId: '',
	orgName: '',
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: 'address'
}

const note = {
    noteTitle: '',
    noteContent: '',
    quoteId: '',

	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: 'note'
}

// * STAGE
const stage = {
	// GENERAL INFO
	id: "",
	stageName: "",
	stagePipeline: "",
	stageValue: "",
	stageOutcome: "",

	// Standard Fields
	ownerUserId: "",
	ownerUserName: "",
	ownerUserEmail: "",
	createdDateTime: "0",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "0",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",

	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "stage"
}

// * LEAD
const lead = {
	// GENERAL INFO
	id: "",

	salesTags: "",
	salesTitle: "",
	salesDescription: "",
	salesPipeline: "",
	salesChannel: "",
	salesType: "warm",
	salesTimePriority: "medium",
	salesSource: "",
	salesSourceName: "",
	salesNotes: "",
	salesOrgTags: "",
	salesImageId: "",
	salesOtherImageIds: "",
	salesStatus: "Draft",
	salesStageName: '',
	salesStageId: '',
	salesProbability: "0",
	salesValue: "",
	packageId: "",
	packageName: "",
	orgId: "",
	orgName: "",
	orgTags: "",
	contactId: "",
	contactName: "",
	contactEmail: "",

	// Standard Fields
	ownerUserId: "",
	ownerUserName: "",
	ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "lead"
}

const opportunity = {
	// GENERAL INFO
	id: "",

	salesTags: "",
	salesTitle: "",
	salesDescription: "",
	salesPipeline: "",
	salesChannel: "",
	salesType: "warm",
	salesTimePriority: "medium",
	salesSource: "",
	salesSourceName: "",
	salesNotes: "",
	salesOrgTags: "",
	salesImageId: "",
	salesOtherImageIds: "",
	salesStatus: "Draft",
	salesEstimatedCloseDate: "0",
	salesActualCloseDate: "0",
	leadStageName: '',
	leadStageId: '',
	salesPackages: "",
	packageId: "",
	packageName: "",
	salesStageName: '',
	salesStageId: '',
	salesProbability: "0",
	salesValue: "",
	orgId: "",
	orgName: "",
	orgTags: "",
	contactId: "",
	contactName: "",
	contactEmail: "",

	// Standard Fields
	ownerUserId: "",
	ownerUserName: "",
	ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "opportunity"
}

const action = {
	id: "",
	actionType: "",
	actionSubject: "",
	actionDescription: "",
	actionPriority: "",
	actionDate: "",
	orgTags: "",
	contactTags: "",
	opportunityId: "",
	leadId: "",
	actionCallType: "",
	actionCallDuration: "",
	actionCallPurpose: "",
	actionCallResult: "",
	actionMeetingHost: "",
	actionMeetingParticipants: "",
	actionMeetingType: "",
	actionMeetingLocation: "",
	actionEventHost: "",
	actionEventParticipants: "",
	actionEventType: "",
	actionEventLocation: "",
	actionCaseType: "",

	orgId: "",
	orgName: "",

	contactId: "",
	contactName: "",

	actionCompleted: 0,
	actionCompletedDateTime: '0',

	actionStageId: "",
	actionStageName: "",

	actionStatus: "",

	// Standard Fields
	ownerUserId: "",
	ownerUserName: "",
	ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	enabled: "0",
	key: "action"
}

const package_schema = {
	id: '',
	packageName: '',
	packageCategory: '',
	packageCode: '',
	packageStatus: '',
	packageCost: '',
	packageSell: '',
	packageManagersDiscount: '',
	packageDirectorsDiscount: '',
	packageBillingCycle: '',
	packageMinTerm: '',
	packageNoticePeriod: '',
	packageCustomerDescription: '',
	packageInternalDescription: '',

	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "package",
}

const order = {
	id: "",
	// Quote Details
	quoteLocked: "0",
	quoteNumber: "",
	quoteClientRef: "",
	quoteVersion: "0",
	quoteCurrentVersion: "0",
	quoteStatus: "Draft",
	quoteTitle: "",
	quoteCustomerNote: "",
	quoteInternalNote: "",
	quoteChannel: "",
	quoteNPS: "0",
	quoteType: "",
	quoteDate: "",
	quoteExpiryDate: "",
	quoteDeliveryDate: "",
	quoteValidityDays: "30",
	quoteTerms: "",
	quoteInstall: "0",

	// MCD
	quoteMCDRate: "0",
	quoteMDCDiscount: "",

	// Approval Fields
	approved: "0",

	// quoteApprovalLevel: "",
	// quoteFirstLevelStatus: "",
	// quoteFirstLevelApprovedBy: "",
	// quoteFirstLevelDeniedBy: "",
	// quoteSecondLevelStatus: "",
	// quoteSecondLevelApprovedBy: "",
	// quoteSecondLevelDeniedBy: "",


	quoteInstallApproval: '0',
	quoteInstallApprovalStatus: 'N/A',
	quoteInstallApprovedBy: '',
	quoteInstallDeniedBy: '',

	quoteSupplyChainApproval: '0',
	quoteSupplyChainApprovalStatus: 'N/A',
	quoteSupplyChainApprovedBy: '',
	quoteSupplyChainDeniedBy: '',

	quoteSalesManagerApproval: '0',
	quoteSalesManagerApprovalStatus: 'N/A',
	quoteSalesManagerApprovedBy: '',
	quoteSalesManagerDeniedBy: '',

	quoteMDDirectorApproval: '0',
	quoteMDDirectorApprovalStatus: 'N/A',
	quoteMDDirectorApprovedBy: '',
	quoteMDDirectorDeniedBy: '',

	quoteFDDirectorApproval: '0',
	quoteFDDirectorApprovalStatus: 'N/A',
	quoteFDDirectorApprovedBy: '',
	quoteFDDirectorDeniedBy: '',

	//sales additions
	quoteID: '',
	opportunityID: '',
	leadID: '',

	// Approval Notes
	firstLevelApprovalNotes: '',
	firstLevelApprovalUserId: '',
	firstLevelRejectionNotes: '',
	firstLevelRejectionUserId: '',
	secondLevelApprovalNotes: '',
	secondLevelApprovalUserId: '',
	secondLevelRejectionNotes: '',
	secondLevelRejectionUserId: '',

	quoteWinPercentage: 0,

	// Totals
	totalItems: "",
	totalInstallCost: "",
	totalInstallSell: "",
	totalInstallMarginValue: "",
	totalInstallMarginPercent: "",
	totalCost: "",
	totalSell: "",
	totalSellMargin: "",
	totalMargin: "",
	// Tags
	quoteTags: "",
	orgTags: "",
	// Rooms and Floors
	quoteRoomTags: "",
	quoteFloorTags: "",
	quoteOptionalTags: "",
	// Customer Details
	orgId: "",
	orgName: "",
	orgImageId: "",
	// Contact Details
	contactId: "",
	contactName: "",
	contactEmail: "",
	// Notes
	notes: "",
	// Owner
	ownerUserId: "",
	ownerUserName: "",
	ownerUserEmail: "",
	// Prepared By
	preparedByUserId: "",
	preparedByUserName: "",
	preparedByUserEmail: "",
	// Order
	order: "0",
	orderId: "",
	orderUserId: "",
	orderUserName: "",
	orderUserEmail: "",
	// Address Details
	orgDeliveryAddressLine1: "",
	orgDeliveryAddressLine2: "",
	orgDeliveryAddressLine3: "",
	orgDeliveryTown: "",
	orgDeliveryCounty: "",
	orgDeliveryPostcode: "",
	orgInvoiceAddressLine1: "",
	orgInvoiceAddressLine2: "",
	orgInvoiceAddressLine3: "",
	orgInvoiceTown: "",
	orgInvoiceCounty: "",
	orgInvoicePostcode: "",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "order",
}

const orderLineItem = {
	id: "",
	lineCode: "",
	lineCodeId: "",
	lineParentCode: "",
	lineParentCodeId: "",
	quoteId: "",
	orgId: "",
	orgName: "",
	lineImageId: "",
	lineDescription: "",
	lineEdit: "0",
	lineExtend: "0",
	lineOrder: "",
	lineReference: "",
	lineProductType: "",
	lineType: "", // Product / Line Break
	lineSOPType: "", // Quote / Order / Purchase Order
	lineQty: "",
	lineIMP: "",
	lineMOQ: "",
	lineCost: "",
	lineCostBase: "",
	lineSell: "",
	lineSellBase: "",
	lineMarginPercent: "",
	lineMarginValue: "",
	lineNPS: "",
	lineNPSEnable: "0",
	lineRRP: "",
	lineRRPBase: "",
	lineMP: "",
	lineTotalCost: "",
	lineTotalSell: "",
	lineTotalMarginPercent: "",
	lineTotalMarginValue: "",
	lineDiscount: "",
	lineMCDRate: "",
	lineMCDDiscount: "",
	lineTotal: "",
	lineFieldsChanged: "",
	lineOptional: "0", // This is an optional line item
	lineIncludeTotal: "1", // Include the Line Total in Quote Total 0 / 1
	lineMarginReason: "", // Reasons for Margin
	lineMarginComments: "", // Comments for Margin
	lineFeatures: "",
	// Room and Floors
	lineRoomTag: "",
	lineFloorTag: "",
	lineOptionalTags: "",
	lineAuthRequired: '0',


	// Grouping
	productChannel: "",
	productCategory: "",
	productSubcategory: "",
	productItemType: "",
	productType: "",
	productRange: "",
	productFitType: "L",


	// PGSR
	lineChannel: "",
	lineCategory: "",
	lineSubcategory: "",
	lineItemType: "",
	lineRange: "",
	lineFitType: "",
	// TODO - RG - Make sure PGSR is written from calcualate.js
	// Tags
	productTags: "",
	// Options
	optionName1: "",
	optionName2: "",
	optionName3: "",
	optionName4: "",
	optionName5: "",
	optionName6: "",
	optionName7: "",
	optionName8: "",
	optionName9: "",
	optionName10: "",
	optionValue1: "",
	optionValue2: "",
	optionValue3: "",
	optionValue4: "",
	optionValue5: "",
	optionValue6: "",
	optionValue7: "",
	optionValue8: "",
	optionValue9: "",
	optionValue10: "",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "lineItem"
	// TODO - RG - Line Items need the following fields Rooms, Floors, Product Categories, SubCategories, Types
}


module.exports = { 
	attribute,
	product,
    organisation,
	option,
	variant,
	quote,
	lineItem,
    contact,
    lead,
	address,
    note,
	stage,
	opportunity,
	action,
	package_schema,
	order,
	orderLineItem
}