<template>
	<div style="width: 100%" class="pa-3 white">
		<div class="d-flex align-center">
			<div class="text-left primary--text text-h5 font-weight-light">Import CSV</div>
			<v-spacer />
			<v-icon @click="closeImport()">icons8-multiply</v-icon>
		</div>
		<v-select :items="importTypes" label="Import Type" v-model="importType" class="mt-3"></v-select>
		<v-divider class="primary-lighten-2 pb-4"></v-divider>

		<input ref="input" hidden type="file" accept=".csv" @change="handleFileUpload($event)" />
		<div class="py-3 d-flex align-center">
			<v-btn class="accent secondary--text" depressed @click="$refs.input.click()">Upload CSV</v-btn>
			<v-btn
				class="grey lighten-1 secondary--text ml-3"
				depressed
				@click="
					content = [];
					parsed = false;
				"
				>Clear Data</v-btn
			>
			<v-spacer />
			<div v-if="JSON.stringify(content) !== '[]'">
				<div v-if="csvHeadersNotRecognised.length > 0" class="font-weight-bold error--text">CSV contained unrecognised headers</div>
				<div v-if="JSON.stringify(csvHeadersNotRecognised) !== '[]'"></div>
				<v-btn class="primary white--text" depressed @click="importFile">Import {{ content.data.length }} Records</v-btn>
			</div>
		</div>
		<div v-if="JSON.stringify(content) !== '[]'">
			<v-tabs v-model="tab" background-color="secondary" dark>
				<v-tab href="#csvdata"> CSV Data </v-tab>
				<v-tab href="#importresult"> Import Result </v-tab>
				<v-tabs-items v-model="tab" fill-height class="">
					<v-tab-item value="csvdata">
						duplicateIDs: {{ duplicateIDs.length }} / duplicateIDs: {{ duplicateIDs }}
						<div v-if="JSON.stringify(csvHeadersNotRecognised) !== '[]'">Headers not recognised: {{ csvHeadersNotRecognised.join(", ") }}</div>
						<div v-else>
							All Headers Recognised<br />
							<v-btn @click="selectedHeaders = csvHeadersAll">Select All Headers</v-btn>
							Selected Headers for Import: {{ selectedHeaders }}

							<div class="" style="width: 100% !important">
								<v-data-table :headers="computedHeaders" :items="content.data" :items-per-page="20" calculate-widths class="truncate">
									<template v-slot:header="{ props }">
										<th v-for="head in props.headers" :key="head.value" align="center">
											<v-checkbox v-model="selectedHeaders" small :value="head.text" color="primary" class="ma-0"></v-checkbox>
										</th>
									</template>
								</v-data-table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="importresult">
						<div class="" style="width: 100% !important" v-if="JSON.stringify(importResult) !== '[]'">
							<div class="py-4">
								<table cellspacing="10">
									<tr>
										<td class="">Total:</td>
										<td @click="filter = ''" align="center" class="grey white--text font-weight-bold" style="width: 50px !important;">{{ computedImportResultSummary.total }}</td>
										<td class="">Errors:</td>
										<td @click="filter = 'error'" align="center" class="error white--text font-weight-bold" style="width: 50px !important;">{{ computedImportResultSummary.errors }}</td>
										<td class="">Success:</td>
										<td @click="filter = 'success'" align="center" class="success white--text font-weight-bold" style="width: 50px !important;">{{ computedImportResultSummary.success }}</td>
									</tr>
								</table>
							</div>
							<v-data-table :headers="computedImportHeaders" :items="computedImportResult" :items-per-page="20" calculate-widths class="truncate" :expanded.sync="expanded" show-expand>
								<template v-slot:item.importStatus="{ item }">
									<v-icon :class="item.importStatus + '--text'">icons8-filled-circle</v-icon>
								</template>
								<template v-slot:expanded-item="{ headers, item }">
									<td></td>
									<td></td>
									<td :colspan="headers.length - 2" class="error--text" v-if="item.importErrors !== ''">
										<strong>Import Errors:</strong><br />
										<pre>{{ item.importErrors }}</pre>
										<!-- <ul><li v-for="(item, index) in item.importErrors.split(',')" :key="index">{{ item }}</li></ul> -->
									</td>
								</template>
							</v-data-table>
						</div>
					</v-tab-item>
				</v-tabs-items>
			</v-tabs>
		</div>
	</div>
</template>

<script>
	import schema from "@/schema.js";
	import { mapGetters } from "vuex";
	export default {
		props: {
			// csvheaders: {
			// 	type: Array,
			// 	default: function () {
			// 		return [];
			// 	},
			// },
			prefix: {
				type: String,
				default: "",
			},
			rediskey: {
				type: String,
				default: "",
			},
		},
		components: {},
		data: () => ({
			tab: "csvdata",
			file: "",
			content: [],
			schema: {},
			importType: "",
			importTypes: ["product", "variant", "option", "attribute", "organisation", "contact"],
			import: [],
			parsed: false,
			selectedHeaders: [],
			importResult: [],
			expanded: [],
			singleExpand: false,
			filter: ''
		}),
		computed: {
			...mapGetters({
				GET_taskBar: "GET_taskBar",
				GET_dense: "GET_dense",
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
			computedImportResult() {
				let importResult = this.importResult
				if (this.filter !== '') {
					importResult = importResult.filter(x => x.importStatus === this.filter)
				}
				return importResult
			},
			duplicateIDs: function () {
				let ids = [];
				this.import.forEach(function (item) {
					ids.push(item.id);
				});
				// let idSet = new Set(ids);
				// let difference = ids.filter(x => !Array.from(idSet).includes(x));
				// //let intersection = Array.from(idSet).filter(x => ids.includes(x));
				// return difference; // number of duplicates


				const toFindDuplicates = ids => ids.filter((item, index) => ids.indexOf(item) !== index)
				const duplicateElements = toFindDuplicates(ids);
				console.log(duplicateElements);
				return duplicateElements


			},
			csvHeaders() {
				let schema = this.schema[this.importType];
				let newSchema = [];
				for (const property in schema) {
					newSchema.push(property);
				}
				return newSchema;
			},
			csvHeadersNotRecognised() {
				if (JSON.stringify(this.content) !== "[]") {
					let headers = [];
					//console.log(JSON.stringify(this.content.meta.fields, null, 2))
					for (var i = 0; i < this.content.meta.fields.length; i++) {
						let header = this.content.meta.fields[i];
						if (this.csvHeaders.indexOf(header) == -1) {
							headers.push(header);
						}
					}
					return headers;
				} else {
					return [];
				}
			},
			csvHeadersAll() {
				if (JSON.stringify(this.content) !== "[]") {
					let headers = [];
					//console.log(JSON.stringify(this.content.meta.fields, null, 2))
					for (var i = 0; i < this.content.meta.fields.length; i++) {
						let header = this.content.meta.fields[i];
						headers.push(header);
					}
					return headers;
				} else {
					return [];
				}
			},
			computedHeaders() {
				if (JSON.stringify(this.content) !== "[]") {
					let headers = [];
					//console.log(JSON.stringify(this.content.meta.fields, null, 2))
					for (var i = 0; i < this.content.meta.fields.length; i++) {
						let header = {
							text: this.content.meta.fields[i],
							value: this.content.meta.fields[i],
						};
						headers.push(header);
					}
					return headers;
				} else {
					return [];
				}
			},
			computedImportHeaders() {
				if (JSON.stringify(this.content) !== "[]") {
					let headers = [];
					//console.log(JSON.stringify(this.content.meta.fields, null, 2))
					headers.push({
						text: "importStatus",
						value: "importStatus",
					});
					for (var i = 0; i < this.content.meta.fields.length; i++) {
						let header = {
							text: this.content.meta.fields[i],
							value: this.content.meta.fields[i],
						};
						headers.push(header);
					}
					headers.push({
						text: "importErrors",
						value: "importErrors",
					});

					return headers;
				} else {
					return [];
				}
			},
			computedImportResultSummary() {
				if (JSON.stringify(this.importResult) !== "[]") {
					let summary = {
						success: 0,
						errors: 0,
						total: 0,
					};
					for (var i = 0; i < this.importResult.length; i++) {
						let item = this.importResult[i];
						if (item.importStatus == "success") {
							summary.success++;
						} else if (item.importStatus == "error") {
							summary.errors++;
						}
					}
					summary.total = this.importResult.length;
					return summary;
				} else {
					return {
						total: 0,
						success: 0,
						errors: 0,
					};
				}
			},
		},
		methods: {
			closeImport() {
				this.content = [];
				this.$emit("close");
			},
			handleFileUpload(event) {
				this.file = event.target.files[0];
				this.parseFile();
				this.$refs.input.value = null;
			},
			// Parse File
			parseFile() {
				let t = this;
				this.$papa.parse(this.file, {
					header: true,
					skipEmptyLines: true,
					dynamicTyping: true,
					complete: function (results) {
						let dateTimeNow = t.$moment().format("x");
						this.content = results;
						let importContent = [];
						let importLine = {};
						for (var i = 0; i < results.data.length; i++) {
							importLine = {
								...results.data[i],
								createdDateTime: dateTimeNow,
								createdUserId: t.GET_FIREBASE_userAuth.uid,
								createdUserName: t.GET_FIREBASE_userAuth.displayName,
								createdUserEmail: t.GET_FIREBASE_userAuth.email,
								modifiedDateTime: dateTimeNow,
								modifiedUserId: t.GET_FIREBASE_userAuth.uid,
								modifiedUserName: t.GET_FIREBASE_userAuth.displayName,
								modifiedUserEmail: t.GET_FIREBASE_userAuth.email,
								deletedDateTime: '',
								deletedUserId: '',
								deletedUserName: '',
								deletedUserEmail: '',
							};
							importContent.push(importLine);
						}
						this.import = importContent;
						this.parsed = true;
					}.bind(this),
				});
			},
			async importFile() {
				let t = this;
				//Bulk Updatex
				//console.log("t.import =\n" + JSON.stringify(t.import, null, 2));

				let axiosPost = await t.$axios(`http://localhost/api/v1/import/${this.importType}/`, { data: t.import, method: "POST" });
				t.importResult = axiosPost.data.data;
				console.log("axiosPost = " + JSON.stringify(axiosPost.data.data, null, 2));
				t.tab = "importresult";
				// if (axiosPost.code === 1) {
				// 	t.MIX_alertBox({ color: "success", timeout: 2000, message: "Import CSV Successful", show: true });
				// 	t.$emit("close");
				// } else {
				// 	t.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Importing CSV", show: true });
				// 	console.log("import = " + axiosPost.message);
				// }
			},
		},
		async mounted() {
			this.importType = this.rediskey;
			this.schema = schema;
		},
	};
</script>

<style scoped>
	.truncate {
		display: inline-block;
		/* width: 200px; */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.v-data-table__expanded__content {
		box-shadow: none !important;
	}
</style>
