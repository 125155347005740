<template>
  <v-card height="700px" class="d-flex flex-column grey lighten-3">
    <div class="pa-0 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
      <!-- <div >
          <v-icon class="white--text">icons8-link</v-icon>
      </div>
      <div class="text-left white--text text-h5 pl-2 font-weight-light">Stages</div> -->
      <v-spacer/>
      <div>
        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeDialog()" depressed>
          <v-icon>icons8-multiply</v-icon>
        </v-btn>
      </div>
    </div>

    <v-card-text class="grey lighten-3 pa-0">
      <!-- <div class="pa-4">
          eventId = {{ this.event.id }}
          <br />
          <pre>{{ contentLink }}</pre>
      </div> -->
      <!-- <div>linkGroups: <pre>{{ linkGroups }}</pre></div> -->
      <div class="d-flex align-center pa-3 pb-0">
        <div class="d-flex align-center pr-8 pb-1">
          <div>
            <v-icon class="primary--text" x-large>icons8-business-network</v-icon>
          </div>
          <div class="text-left primary--text pl-1 text-h4 font-weight-thin">Stages</div>
        </div>
        <v-spacer/>
        <div class="mx-1 pb-2">
          <v-btn v-if="showTable == true" depressed block class="font-weight-bold primary darken-2 white--text"
                 @click="showTable = false">
            <v-icon>icons8-menu-squared</v-icon>
            <span class="ml-3">Add New Stage</span>
          </v-btn>
          <v-btn v-else-if="showTable == false" depressed block class="font-weight-bold grey darken-1 white--text"
                 @click="resetForm">
            <v-icon>icons8-multiply</v-icon>
            <span class="ml-3">Cancel</span>
          </v-btn>
        </div>
      </div>
      <div class="pa-4 pt-0">
        <div v-if="showTable == true" style="width: 100% !important">
          <!-- TABLE BASED LIST -->
          <stage-list v-on:edit="editItem"/>
        </div>
        <div v-else>
          <v-row style="width: 100% !important" no-gutters>
            <!-- GENERAL DETAILS EDIT-->
            <v-col cols="12" sm="12" class="my-1">
              <v-row dense>
                <v-col cols="12" xs="12" md="6" class="my-1">
                  <v-text-field label="Stage Name" v-model="item.stageName" :rules="rules.stageName" outlined dense
                                hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearContactName('stageName', '')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" md="6" class="my-1">
                  <v-select label="Stage Pipeline" v-model="item.stagePipeline" :rules="rules.stagePipeline"
                            :items="pipelines" required outlined dense hide-details="auto" background-color="white"
                            color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true"
                            @click:clear="clearItem('stagePipeline')"></v-select>
                </v-col>
                <v-col cols="12" xs="12" md="6" class="my-1">
                  <v-text-field label="Stage Value" type="number" v-model="item.stageValue" :rules="rules.stageValue"
                                outlined dense hide-details="auto" background-color="white" color="primary" clearable
                                @click:clear="clearContactName('stageValue', '')"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" md="6" class="my-1">
                  <v-select label="Stage Outcome" v-model="item.stageOutcome" :rules="rules.stageOutcome"
                            :items="outcomes" required outlined dense hide-details="auto" background-color="white"
                            color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true"
                            @click:clear="clearItem('stageOutcome')"></v-select>
                </v-col>
                <v-col cols="12" class="my-1 pl-2">

                </v-col>

              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions class="grey lighten-1 pa-0">
      <v-col cols="12" class="d-flex">
        <v-col cols="6" class="d-flex justify-start pa-0">
          <v-btn v-if="showAddLink" class="grey darken-1 white--text font-weight-bold" depressed
                 @click="showAddLink = false; contentListTable = true">
            <v-icon class="mr-2">icons8-back</v-icon>
            <span>Back</span></v-btn>
        </v-col>
        <v-col cols="6" class="d-flex justify-end pa-0">
          <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeDialog">
            <v-icon class="mr-2">icons8-multiply</v-icon>
            <span>Cancel</span></v-btn>
          <v-btn v-if="showTable == false" class="primary white--text font-weight-bold ml-2 mr-1" depressed
                 @click="saveStage">
            <v-icon class="mr-2">icons8-save</v-icon>
            <span>Save</span></v-btn>
        </v-col>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import schema from '@/schema.js';
import contentList from "@/views/sales/StagesList.vue";

export default {
  name: 'eventContentList',
  components: {
    'stage-list': contentList,
  },
  // PROPS
  props: {
    contentlink: {
      Type: Object,
      Default: function () {
        return {}
      },
    },
    event: {
      Type: Object,
      Default: function () {
        return {}
      },
    }
  },
  // DATA
  data: () => ({
    key: 'stage',
    newStage: true,
    linkGroups: [],
    schema: {},
    contentListTable: true,
    contentLink: {},
    rules: {},
    showAddLink: false,
    linkGroupAutocomplete: '',
    showTable: true,
    pipelines: ['Leads', 'Opportunities'],
    outcomes: ['noValue', 'success', 'failure'],
    item: {},
    edit: false,
  }),
  computed: {},
  methods: {
    // Clear Item Property
    clearItem(property) {
      setTimeout(() => {
        this.item[property] = '';
      }, 1);
    },
    // * Save Stage
    async saveStage() {
      let t = this;
      if (this.item.id === '') {
        t.item.id = t.MIX_generateId()
      }
      let result = await t.MIX_createItem(t.item, t.key, t.newStage);
      if (result.code === 1) {
        t.MIX_alertBox({color: 'success', timeout: 2000, message: 'Saved', show: true});
      }
      if (t.edit == true) {
        t.editSales(t.item)
      }
      t.resetForm();
      t.showTable = true
      // t.contentLink = { ...t.schema }
      // t.$emit('close');
    },
    resetForm() {
      this.item = {...this.schema};
      this.showTable = true;
    },
    async editSales(item) {
      var t = this;
      // ////console.log("item = " + JSON.stringify(item, null, 2))
      // TODO if stataement for if lead or opportunity or account
      if (item.stagePipeline == 'Leads') {
        // Get the leads in the stage from the database
        let itemResult = await this.MIX_redisSearch("idx:leads", `@deleted:0 @salesStageId:{${item.id}}`, 0, 9999);
        if (itemResult.code === 1) {
          var leads = itemResult.data.documents.map((x) => x.value);

          leads.forEach((lead) => {
            lead.salesStageName = item.stageName
            t.MIX_createItem(lead, 'lead', false)
          })

        } else {
          this.MIX_alertBox({color: 'error', timeout: 4000, message: 'Error Getting Leads', show: true});
        }
      } else if (item.stagePipeline === 'Opportunities') {
        // Get the opportunities in the stage from the database
        let itemResult = await this.MIX_redisSearch("idx:opportunities", `@deleted:0 @salesStageId:{${item.id}}`, 0, 9999);
        if (itemResult.code === 1) {
          var opportunities = itemResult.data.documents.map((x) => x.value);

          opportunities.forEach((opportunity) => {
            opportunity.salesStageName = item.stageName
            t.MIX_createItem(opportunity, 'opportunity', false)
          })

        } else {
          this.MIX_alertBox({color: 'error', timeout: 4000, message: 'Error Getting Opportunities', show: true});
        }
      } else if (item.stagePipeline === 'Accounts') {
        // Get the opportunities in the stage from the database
        let itemResult = await this.MIX_redisSearch("idx:orgs", `@deleted:0 @orgStageId:{${item.id}}`, 0, 9999);
        if (itemResult.code === 1) {
          var orgs = itemResult.data.documents.map((x) => x.value);

          orgs.forEach((org) => {
            org.orgStageName = item.stageName
            t.MIX_createItem(org, 'org', false)
          })

        } else {
          this.MIX_alertBox({color: 'error', timeout: 4000, message: 'Error Getting Organisations', show: true});
        }
      }

    },
    editItem(value) {
      this.item = value;
      this.showTable = false;
      this.edit = true
    },
    // Close Item
    closeDialog() {
      this.item = {...this.schema}
      this.$emit('close');
    },
    // Initialise Dialog Data
    initialise() {
      let t = this;
      t.schema = schema.stage;
      t.item = {...t.schema};
      // if (t.contentlink?.id !== '' && t.contentlink?.id !== undefined && t.contentlink?.id !== null) {
      //     t.contentLink = { ...t.contentlink }
      //     t.newContentLink = false;
      // } else {
      //     t.contentLink = { ...t.schema }
      //     t.newContentLink = true;
      // }
      // t.contentLink.eventId = t.event.id;
      // if (this.event.eventLinkGroups !== '' && this.event.eventLinkGroups !== undefined && this.event.eventLinkGroups !== null) {
      //     this.linkGroups = this.event.eventLinkGroups.split(',');
      // }
    }
  },
  watch: {
    contentlink: {
      handler: function () {
        this.initialise();
      },
      deep: true,
    }
  },
  async created() {
    var t = this
    t.initialise();
  },
};
</script>