<template>
	<div>
		<v-row class="pa-4" no-gutters>
			<v-col cols="12" sm="12">
				<!-- HEADING AND BUTTONS -->
				<div class="d-flex align-center">
					<div class="text-left primary--text text-h5 font-weight-light">Variants ({{ itemsTotal }})</div>
					<v-spacer />
					<!-- <v-btn class="primary--text font-weight-bold" depressed outlined color="primary" @click="toggleJSON = !toggleJSON">
						<span v-if="!toggleJSON">Show JSON</span>
						<span v-if="toggleJSON">Hide JSON</span>
					</v-btn> -->
					<v-btn
						class="ml-2 accent primary--text font-weight-bold"
						depressed
						:disabled="toggleForm"
						@click="
							toggleForm = !toggleForm;
							itemNew = true;
						"
					>
						New Variant
					</v-btn>
				</div>
				<!-- ITEM JSON -->
				<v-row v-if="toggleJSON">
					<v-col cols="12">
						<pre class="caption">{{ item }}</pre>
					</v-col>
				</v-row>
				<!-- ITEM FORM -->
				<v-row v-if="toggleForm" class="rounded grey lighten-5 rounded mt-3" dense>
					<v-col cols="12" sm="12" class="pa-3">
						<div class="font-weight-light body-1 mb-4">Use the form below to add a variant of this product.</div>
						<v-row dense>
                            <v-col cols="3">
                                <v-text-field label="Supplier Code" v-model="item.variantCode" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field label="Variant Description" v-model="item.variantDescription" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                            </v-col>
						</v-row>
						
						<v-row v-if="JSON.stringify(options) !== '[]'" dense>
							<v-col cols="12" class="apptext--text font-weight-bold">
								Select Options for Variant
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName1)">
								<v-autocomplete v-model="item.optionValue1" :label="findOptionName(product.optionName1)" item-text="attName" :items="filteredAttributes(1, product.optionName1)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" append-icon="mdi-close" @click:append="item.optionValue1 = ''"></v-autocomplete>
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName2)">
								<v-autocomplete v-model="item.optionValue2" :label="findOptionName(product.optionName2)" item-text="attName" :items="filteredAttributes(2, product.optionName2)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName3)">
								<v-autocomplete v-model="item.optionValue3" :label="findOptionName(product.optionName3)" item-text="attName" :items="filteredAttributes(3, product.optionName3)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName4)">
								<v-autocomplete v-model="item.optionValue4" :label="findOptionName(product.optionName4)" item-text="attName" :items="filteredAttributes(4, product.optionName4)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName5)">
								<v-autocomplete v-model="item.optionValue5" :label="findOptionName(product.optionName5)" item-text="attName" :items="filteredAttributes(5, product.optionName5)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName6)">
								<v-autocomplete v-model="item.optionValue6" :label="findOptionName(product.optionName6)" item-text="attName" :items="filteredAttributes(6, product.optionName6)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName7)">
								<v-autocomplete v-model="item.optionValue7" :label="findOptionName(product.optionName7)" item-text="attName" :items="filteredAttributes(7, product.optionName7)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName8)">
								<v-autocomplete v-model="item.optionValue8" :label="findOptionName(product.optionName8)" item-text="attName" :items="filteredAttributes(8, product.optionName8)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName9)">
								<v-autocomplete v-model="item.optionValue9" :label="findOptionName(product.optionName9)" item-text="attName" :items="filteredAttributes(9, product.optionName9)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
							</v-col>
							<v-col cols="2" v-if="isOptionVariant(product.optionName10)">
								<v-autocomplete v-model="item.optionValue10" :label="findOptionName(product.optionName10)" item-text="attName" :items="filteredAttributes(10, product.optionName10)" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
							</v-col>
						</v-row>

						<v-row class="mt-3" dense>
							<div class="d-flex align-center">
								<span class="ml-1 mr-2 font-weight-bold">Pricing:</span>
								<v-radio-group v-model="item.pricingType" row>
									<v-radio hide-details="auto" label="Simple" value="Simple"></v-radio>
									<v-radio hide-details="auto" label="Volume" value="Volume"></v-radio>
									<v-radio hide-details="auto" label="Band" value="Band"></v-radio>
								</v-radio-group>
							</div>

							<!-- SIMPLE PRICING -->
							<v-col cols="12" class="" v-if="item.pricingType === 'Simple'">
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="3"><v-text-field dense label="Min Order Qty" outlined background-color="white" hide-details="auto" v-model="item.MOQ"></v-text-field></v-col>
								</v-row>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="3"><v-text-field prefix="£" dense label="Cost Price" outlined background-color="white" hide-details="auto" v-model="item.cP1"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="3"><v-text-field prefix="£" dense label="Sell Price" outlined background-color="white" hide-details="auto" v-model="item.sP1"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="3"><v-text-field suffix="%" dense label="Margin" @change="item.sP1 = ((item.cP1 / (100 - $event)) * 100).toFixed(2)" outlined :background-color="((item.sP1 - item.cP1) / item.sP1) * 100 < 20 ? 'red lighten-4' : 'green lighten-4'" hide-details="auto" :value="isNaN(((item.sP1 - item.cP1) / item.sP1) * 100) ? '0' : (((item.sP1 - item.cP1) / item.sP1) * 100).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="3"><v-text-field prefix="£" readonly dense label="Sell Margin" outlined background-color="purple lighten-5" hide-details="auto" :value="(item.sP1 - item.cP1).toFixed(2)"></v-text-field></v-col>
								</v-row>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="3"><v-text-field prefix="£" dense label="Market Price" outlined background-color="white" hide-details="auto" v-model="item.MP"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="3"><v-text-field suffix="%" dense label="Dicount off Market Price" outlined background-color="purple lighten-5" hide-details="auto" :readonly="true" :value="(((item.MP - item.sP1) / item.MP) * 100).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="3"><v-text-field prefix="£" dense label="RRP" outlined background-color="white" hide-details="auto" v-model="item.RRP"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="3"><v-text-field suffix="%" dense label="Dicount off RRP" outlined background-color="purple lighten-5" hide-details="auto" :readonly="true" :value="(((item.RRP - item.sP1) / item.RRP) * 100).toFixed(2)"></v-text-field></v-col>
								</v-row>
								<div class="error--text font-weight-bold" v-if="((item.sP1 - item.cP1) / item.sP1) * 100 < 20">Margin too Low - Please increase margin or sell price</div>
							</v-col>
							<!-- VOLUME PRICING -->
							<v-col cols="12" class="white" v-if="item.pricingType === 'Volume'">
								<div class="body-2 text-secondary">Enter the Volume Pricing Below</div>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix=" " dense label="Sell Qty 1" outlined background-color="white" hide-details="auto" v-model="item.sQ1"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Sell Price 1" outlined background-color="white" hide-details="auto" v-model="item.sP1"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix=" " dense label="Sell Qty 2" outlined background-color="white" hide-details="auto" v-model="item.sQ2"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Sell Price 2" outlined background-color="white" hide-details="auto" v-model="item.sP2"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix=" " dense label="Sell Qty 3" outlined background-color="white" hide-details="auto" v-model="item.sQ3"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Sell Price 3" outlined background-color="white" hide-details="auto" v-model="item.sP3"></v-text-field></v-col>
								</v-row>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix=" " dense label="Cost Qty 1" outlined background-color="white" hide-details="auto" v-model="item.cQ1"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Cost Price 1" outlined background-color="white" hide-details="auto" v-model="item.cP1"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix=" " dense label="Cost Qty 2" outlined background-color="white" hide-details="auto" v-model="item.cQ2"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Cost Price 2" outlined background-color="white" hide-details="auto" v-model="item.cP2"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix=" " dense label="Cost Qty 3" outlined background-color="white" hide-details="auto" v-model="item.cQ3"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Cost Price 3" outlined background-color="white" hide-details="auto" v-model="item.cP3"></v-text-field></v-col>
								</v-row>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="2"><v-text-field suffix="%" dense label="Margin 1 %" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP1"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Margin 1 Sell" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP1"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field suffix="%" dense label="Margin 2 %" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP2"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Margin 2 Sell" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP2"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field suffix="%" dense label="Margin 3 %" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP3"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Margin 3 Sell" outlined background-color="purple lighten-5" hide-details="auto" v-model="item.sP3"></v-text-field></v-col>
								</v-row>
							</v-col>
							<!-- BAND PRICING -->
							<v-col cols="12" class="white" v-if="item.pricingType === 'Band'">
								<div class="body-2 text-secondary">Enter the Band Pricing Below</div>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">A</v-col>
									<v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">B</v-col>
									<v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">C</v-col>
									<v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">D</v-col>
									<v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">E</v-col>
									<v-col cols="12" xs="12" sm="2" class="text-center font-weight-bold">F</v-col>
								</v-row>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Sell Band A" outlined background-color="white" hide-details="auto" v-model="item.sBA"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Sell Band B" outlined background-color="white" hide-details="auto" v-model="item.sBB"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Sell Band C" outlined background-color="white" hide-details="auto" v-model="item.sBC"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Sell Band D" outlined background-color="white" hide-details="auto" v-model="item.sBD"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Sell Band E" outlined background-color="white" hide-details="auto" v-model="item.sBE"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Sell Band F" outlined background-color="white" hide-details="auto" v-model="item.sBF"></v-text-field></v-col>
								</v-row>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Cost Band A" outlined background-color="white" hide-details="auto" v-model="item.cBA"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Cost Band B" outlined background-color="white" hide-details="auto" v-model="item.cBB"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Cost Band C" outlined background-color="white" hide-details="auto" v-model="item.cBC"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Cost Band D" outlined background-color="white" hide-details="auto" v-model="item.cBD"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Cost Band E" outlined background-color="white" hide-details="auto" v-model="item.cBE"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Cost Band F" outlined background-color="white" hide-details="auto" v-model="item.cBF"></v-text-field></v-col>
								</v-row>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Margin Band A" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBA - item.cBA).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Margin Band B" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBB - item.cBB).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Margin Band C" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBC - item.cBC).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Margin Band D" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBD - item.cBD).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Margin Band E" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBE - item.cBE).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="Margin Band F" outlined background-color="purple lighten-5" readonly hide-details="auto" :value="(item.sBF - item.cBF).toFixed(2)"></v-text-field></v-col>
								</v-row>
								<v-row class="my-2" dense>
									<v-col cols="12" xs="12" sm="2"><v-text-field suffix="%" dense label="Margin Band A" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBA - item.cBA) / item.sBA) * 100).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field suffix="%" dense label="Margin Band B" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBB - item.cBB) / item.sBB) * 100).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field suffix="%" dense label="Margin Band C" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBC - item.cBC) / item.sBC) * 100).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field suffix="%" dense label="Margin Band D" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBD - item.cBD) / item.sBD) * 100).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field suffix="%" dense label="Margin Band E" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBE - item.cBE) / item.sBE) * 100).toFixed(2)"></v-text-field></v-col>
									<v-col cols="12" xs="12" sm="2"><v-text-field suffix="%" dense label="Margin Band F" outlined background-color="purple lighten-5" hide-details="auto" :value="(((item.sBF - item.cBF) / item.sBF) * 100).toFixed(2)"></v-text-field></v-col>
								</v-row>
							</v-col>
                            
                            <v-col class="white pt-0" cols="12">
								<v-row dense>
                                    <v-col cols="12" xs="12" sm="2"><v-text-field prefix="£" dense label="NPS Price" outlined background-color="white" hide-details="auto" v-model="item.NPS"></v-text-field></v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
								<v-btn class="grey lighten-3 primary--text font-weight-bold" depressed @click="toggleCustomPricing = !toggleCustomPricing">
									<span v-if="toggleCustomPricing">- Custom Pricing</span>
									<span v-if="!toggleCustomPricing">+ Custom Pricing</span>
								</v-btn>
							</v-col>
							<!-- Custom Pricing -->
							<v-col cols="12" class="white" v-if="toggleCustomPricing">
								<div class="text-h7 font-weight-bold">Custom Pricing</div>
								<div class="body-2 text-secondary">Custom Pricing is used for Contract Pricing</div>
								<div class="pb-3 d-flex align-start flex-wrap" style="height: 100px !important">
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing1" label="NPS" hide-details="auto"></v-checkbox></div>
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing2" label="Dealer" hide-details="auto"></v-checkbox></div>
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing3" label="Super Dealer" hide-details="auto"></v-checkbox></div>
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing4" label="Spare" hide-details="auto"></v-checkbox></div>
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing5" label="Spare" hide-details="auto"></v-checkbox></div>
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing6" label="Spare" hide-details="auto"></v-checkbox></div>
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing7" label="Spare" hide-details="auto"></v-checkbox></div>
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing8" label="Spare" hide-details="auto"></v-checkbox></div>
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing9" label="Spare" hide-details="auto"></v-checkbox></div>
									<div class=""><v-checkbox class="mr-2" v-model="item.customPricing10" label="Spare" hide-details="auto"></v-checkbox></div>
								</div>
							</v-col>
						</v-row>

						<v-row class="mt-3" dense>
							<v-col cols="12" xs="12" class="d-flex align-start justify-start">
                                <div class="d-flex align-center">
                                    <span class="ml-1 mr-2 font-weight-bold">Image:</span>
                                </div>
                            </v-col>
							<v-col cols="12" xs="12" md="4" class="d-flex align-center justify-center">
								<div class="" v-if="item.variantImageId !== '' && item.variantImageId !== undefined && item.variantImageId !== null"><v-img class="my-0" width="250" height="150" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/' + item.variantImageId + '.jpeg'" /></div>
								<div class="" v-if="item.variantImageId === '' || item.variantImageId === undefined || item.variantImageId === null"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
							</v-col>
							<v-col cols="12" xs="12" md="8" class="d-flex align-center justify-center">
                                <ImageUpload
                                    accept="image/jpeg, image/jpg, image/png"
                                    label="Select file"
                                    buttonClass="primary elevation-0"
                                    progressClass="py-4 pa-2"
                                    folderLocation="products/"
                                    :fileName="MIX_generateId()"
                                    :compressionMaxSizeMB="1"
                                    :compressionmaxWidthOrHeight="500"
                                    :selectedItem="item"
                                    :private="false"
                                    :createThumbnail="true"
                                    v-on:update="updateItemImage"
                                />
							</v-col>
                        </v-row>
					</v-col>
					<v-col cols="12" class="grey lighten-2 rounded pa-3 d-flex justify-right align-center">
						<v-spacer />
						<div>
							<v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="toggleForm = false">
								<span>Cancel</span>
							</v-btn>
						</div>
						<div class="ml-2">
							<v-btn v-if="itemNew" class="accent primary--text font-weight-bold" depressed @click="createItem()">
								<span>Save</span>
							</v-btn>
							<v-btn v-else class="accent primary--text font-weight-bold" depressed @click="updateItem()">
								<span>Update</span>
							</v-btn>
						</div>
					</v-col>
				</v-row>
				<!-- ITEMS TABLE -->
				<v-row class="grey lighten-5 rounded mt-4" dense>
					<v-col class="12 px-3">
						<!-- TABLE SEARCH AND COLUMNS ETC -->
						<div class="mt-3 d-flex align-center">
							<div class="flex-grow-1"><v-text-field label="Search Variants" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field></div>
							<div class="ml-2">
								<v-btn depressed class="primary--text grey-lighten-5" @click="getItems()"><v-icon class="" color="primary">icons8-available-updates</v-icon></v-btn>
							</div>
							<div class="ml-2">
								<v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
									<template v-slot:activator="{ on, attrs }">
										<v-btn depressed class="primary--text grey-lighten-5" v-bind="attrs" v-on="on"><v-icon>icons8-table-properties</v-icon></v-btn>
									</template>
									<div class="white" style="width: 250px; max-height: 300px">
										<div class="pa-5 white" style="width: 100% !important">
											<div class="body-2 font-weight-bold">Show / hide Columns</div>
											<v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
										</div>
									</div>
								</v-menu>
							</div>
							<div class="ml-2">
								<v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
									<template v-slot:activator="{ on, attrs }">
										<v-btn depressed class="primary--text grey-lighten-5" v-bind="attrs" v-on="on"><v-icon>icons8-menu-vertical</v-icon></v-btn>
									</template>
									<div class="white" style="width: 250px; max-height: 300px">
										<div class="pa-5 white" style="width: 100% !important">
											<div class="body-2 font-weight-bold">Other Actions</div>
											<v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch>
										</div>
									</div>
								</v-menu>
							</div>
              <!--    EXPORT CSV-->
              <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">
                <v-btn depressed class="primary--text grey-lighten-5" @click="exportBox = true">
                  <v-icon>icons8-export-csv</v-icon>
                </v-btn>
              </div>
              <ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
                <template v-slot:header>
                  <div class="full-width d-flex align-center">
                    <div>Export CSV</div>
                    <v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
                    <v-spacer />
                    <v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-cancel</v-icon></v-btn>
                  </div>
                </template>
                <p>Please select the data you wish to export:</p>
                <table width="100%" cellspacing="5">
                  <tr>
                    <td width="100"><strong>Current page:</strong></td>
                    <td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
                    <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
                  </tr>
                  <tr>
                    <td><strong>Current total:</strong></td>
                    <td>{{ itemsTotal }} items (filtered)</td>
                    <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
                  </tr>
                  <tr>
                    <td><strong>All Items:</strong></td>
                    <td>{{ totalCount }} items (without filter)</td>
                    <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
                  </tr>
                  <tr>
                    <td valign="top"><strong>Specific:</strong></td>
                    <td>
                      <div class="d-flex align-center">
                        <div>From</div>
                        <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
                        <div>To</div>
                        <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
                      </div>
                      <div class="ml-n7">
                        <CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
                      </div>
                    </td>
                    <td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
                  </tr>
                </table>
              </ExportBox>
              <!--      EXPORT END-->
						</div>
						<!-- <div>{{ computedSearchQuery }}</div> -->
						<!-- TABLE BASED LIST -->
						<v-data-table sortable dense class="mt-3" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions" :loading="loading" loading-text="Loading... Please wait">
							<!-- <template v-slot:item.deleted="{ item }">
								<span v-if="item.deleted === '1'">Yes</span>
								<span v-if="item.deleted === '0'">No</span>
							</template> -->
							<!-- <template v-slot:item="{ item }">
								<span v-if="item.RRP">£ {{ item.RRP }}</span>
							</template> -->
							<!-- <template v-slot:item.createdDateTime="{ item }">
								<span>{{ MIX_formatDateTime(item.createdDateTime, "x", "DD-MMM-YY HH:mm:ss") }}</span>
							</template>
							<template v-slot:item.modifiedDateTime="{ item }">
								<span>{{ MIX_formatDateTime(item.modifiedDateTime, "x", "DD-MMM-YY HH:mm:ss") }}</span>
							</template>
							<template v-slot:item.deletedDateTime="{ item }">
								<span>{{ MIX_formatDateTime(item.deletedDateTime, "x", "DD-MMM-YY HH:mm:ss") }}</span>
							</template>-->
							<template v-slot:item.action="{ item }">
								<v-icon class="error--text pa-1" style="border: 1px red solid; border-radius: 3px" v-if="GET_destroy" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on" class="grey--text text--darken-2 ml-5" @click="replicateItem(item.id)">icons8-replicate-rows</v-icon>
									</template>
									<span>Replicate</span>
								</v-tooltip>
								<v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0'">icons8-trash-can</v-icon>
								<v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1'">icons8-trash-restore</v-icon>
								<v-icon
									class="primary--text ml-5"
									@click="
										readItem(item.id, key);
										itemNew = false;
										toggleForm = true;
									"
									>icons8-edit</v-icon
								>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import schema from "@/schema.js";
  import ExportBox from "@/components/general/ExportBox.vue";
  import CheckboxField from "@/components/form/CheckboxField.vue";
  import TextField from "@/components/form/TextField.vue";
  import AppButton from "@/components/general/AppButton.vue";
	export default {
    components: {AppButton, TextField, CheckboxField, ExportBox},
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
			editmode: {
				type: Boolean,
				default: false,
			},
		},
		// * DATA
		data: () => ({
			loading: false,

			// redis
			key: "variant",
			index: "idx:variants",

			// toggles
			toggleJSON: false,
			toggleForm: false,
			toggleCustomPricing: false,
			toggleDeletedItems: false,
			product: {},
			options: [],
			attributes: [],
			// optionsWithVariants: [],

			// search / filtering
			searchQuery: "",

			// item
			item: {},
			itemNew: false,

			// items
			items: [],
			itemsTotal: null,
			itemsFrom: 0,
			itemsTo: 9,

			// Headers to display in the Data Table
			headers: [
				{ text: "Id", value: "id", hidden: true },
				{ text: "Variant Code", value: "variantCode", hidden: false },
				{ text: "Product Code", value: "productCode", hidden: true },
				{ text: "Product ID", value: "productId", hidden: true },
				{ text: "Product Name", value: "productName", hidden: true },
				{ text: "Variant Description", value: "variantDescription", hidden: false },
				{ text: "Variant Image", value: "variantMainImage", hidden: true },
				{ text: "Supplier ID", value: "orgId", hidden: true }, // should be orgName
				{ text: "Supplier Name", value: "orgName", hidden: true }, // should be orgName
				{ text: "Option Name 1", value: "optionName1", hidden: true },
				{ text: "Option Value 1", value: "optionValue1", hidden: false },
				{ text: "Option Name 2", value: "optionName2", hidden: true },
				{ text: "Option Value 2", value: "optionValue2", hidden: false },
				{ text: "Option Name 3", value: "optionName3", hidden: true },
				{ text: "Option Value 3", value: "optionValue3", hidden: false },
				{ text: "Option Name 4", value: "optionName4", hidden: true },
				{ text: "Option Value 4", value: "optionValue4", hidden: false },
				{ text: "Option Name 5", value: "optionName5", hidden: true },
				{ text: "Option Value 5", value: "optionValue5", hidden: false },
				{ text: "Option Name 6", value: "optionName6", hidden: true },
				{ text: "Option Value 6", value: "optionValue6", hidden: false },
				{ text: "Option Name 7", value: "optionName7", hidden: true },
				{ text: "Option Value 7", value: "optionValue7", hidden: false },
				{ text: "Option Name 8", value: "optionName8", hidden: true },
				{ text: "Option Value 8", value: "optionValue8", hidden: false },
				{ text: "Option Name 9", value: "optionName9", hidden: true },
				{ text: "Option Value 9", value: "optionValue9", hidden: false },
				{ text: "Option Name 10", value: "optionName10", hidden: true },
				{ text: "Option Value 10", value: "optionValue10", hidden: false },
				{ text: "MOQ", value: "MOQ", hidden: true },
				{ text: "Pricing Type", value: "pricingType", hidden: true },
				{ text: "RRP", value: "RRP", hidden: false },
				{ text: "Market Price", value: "MP", hidden: true },
				{ text: "Cost Qty 1", value: "cQ1", hidden: true },
				{ text: "Cost Price 1", value: "cP1", hidden: false },
				{ text: "Cost Qty ", value: "cQ2", hidden: true },
				{ text: "Cost Price 2", value: "cP2", hidden: true },
				{ text: "Cost Qty 3", value: "cQ3", hidden: true },
				{ text: "Cost Price 3", value: "cP3", hidden: true },
				{ text: "Sell Qty 1", value: "sQ1", hidden: true },
				{ text: "Sell Price 1", value: "sP1", hidden: false },
				{ text: "Sell Qty 2", value: "sQ2", hidden: true },
				{ text: "Sell Price 2", value: "sP2", hidden: true },
				{ text: "Sell Qty 3", value: "sQ3", hidden: true },
				{ text: "Sell Price 3", value: "sP3", hidden: true },
				{ text: "MOQ", value: "MOQ", hidden: true },
				{ text: "Cost Band A", value: "cBA", hidden: true },
				{ text: "Cost Band B", value: "cBB", hidden: true },
				{ text: "Cost Band C", value: "cBC", hidden: true },
				{ text: "Cost Band D", value: "cBD", hidden: true },
				{ text: "Cost Band E", value: "cBE", hidden: true },
				{ text: "Cost Band F", value: "cBF", hidden: true },
				{ text: "Sell Band A", value: "sBA", hidden: true },
				{ text: "Sell Band B", value: "sBB", hidden: true },
				{ text: "Sell Band C", value: "sBC", hidden: true },
				{ text: "Sell Band D", value: "sBD", hidden: true },
				{ text: "Sell Band E", value: "sBE", hidden: true },
				{ text: "Sell Band F", value: "sBF", hidden: true },
				{ text: "NPS", value: "NPS", hidden: true },
				{ text: "Custom Pricing 1", value: "custom1", hidden: true },
				{ text: "Custom Pricing 2", value: "custom2", hidden: true },
				{ text: "Custom Pricing 3", value: "custom3", hidden: true },
				{ text: "Custom Pricing 4", value: "custom4", hidden: true },
				{ text: "Custom Pricing 5", value: "custom5", hidden: true },
				{ text: "Custom Pricing 6", value: "custom6", hidden: true },
				{ text: "Custom Pricing 7", value: "custom7", hidden: true },
				{ text: "Custom Pricing 8", value: "custom8", hidden: true },
				{ text: "Custom Pricing 9", value: "custom9", hidden: true },
				{ text: "Custom Pricing 10", value: "custom10", hidden: true },
				{ text: "Key", value: "key", hidden: true },
				{ text: "Date Time (C)", value: "createdDateTime", hidden: true },
				{ text: "User ID (C)", value: "createdUserId", hidden: true },
				{ text: "User Name (C)", value: "createdUserName", hidden: true },
				{ text: "User Email (C)", value: "createdUserEmail", hidden: true },
				{ text: "Date Time (M)", value: "modifiedDateTime", hidden: true },
				{ text: "User ID (M)", value: "modifiedUserId", hidden: true },
				{ text: "User Name (M)", value: "modifiedUserName", hidden: true },
				{ text: "User Email (M)", value: "modifiedUserEmail", hidden: true },
				{ text: "Deleted", value: "deleted", hidden: true },
				{ text: "Date Time (D)", value: "deletedDateTime", hidden: true },
				{ text: "User ID (D)", value: "deletedUserId", hidden: true },
				{ text: "User Name (D)", value: "deletedUserName", hidden: true },
				{ text: "User Email (D)", value: "deletedUserEmail", hidden: true },
				{ text: "Actions", value: "action", hidden: false, width: "200px" },
			],
			// Fields to Retrieve from Database
			sortBy: "@variantCode",
			datatableOptions: {
				page: 1,
				itemsPerPage: 10,
				sortBy: ["variantCode"],
				sortDesc: [false],
				groupBy: [],
				groupDesc: [],
				mustSort: false,
				multiSort: false,
			},

      //EXPORT DATA
      exportBox: false,
      exportItems: {
        itemsFrom: "1",
        itemsTo: "10",
      },
      exportLoading: false,
      exportFiltered: false,
      showInfo: false,
      totalCount: 0,
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_destroy: "GET_destroy",
			}),
			// COMPUTED FIELDS
			computedFields() {
				/* The Fields are Computed from the Headers so when you switch the headers off and on they
				create a computed field list so the database only brings back data that is required */
				let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => "@" + y.value);
				// The following fields if hidden or not should always be included
				if (!computedFields.includes("@id")) {
					computedFields.push("@id");
				}
				if (!computedFields.includes("@deleted")) {
					computedFields.push("@deleted");
				}
				if (!computedFields.includes("@enable")) {
					computedFields.push("@enable");
				}
				computedFields = computedFields.join(",");
				return computedFields;
			},
			// COMPUTED SEARCH QUERY
			computedSearchQuery() {
				/* The computed search query prepares the search query for Redis in the correct format */
				let t = this;
				let searchQuery = t.searchQuery;
				console.log("t.product.id = " + t.product.id);
				if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
					if (t.toggleDeletedItems) {
						searchQuery = `@productId:{${t.product.id}}`;
					} else {
						searchQuery = `@deleted:0 @productId:{${t.product.id}}`;
					}
				} else {
					if (searchQuery.startsWith("@")) {
						if (t.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @productId:{${t.product.id}}`;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @productId:{${t.product.id}}`;
						}
					} else {
						if (t.toggleDeletedItems) {
							searchQuery = `${searchQuery}* @productId:{${t.product.id}}`;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @productId:{${t.product.id}}`;
						}
					}
				}
				// Filter By optionId
				// if (t.optionSelected?.optionId) {
				// 	searchQuery = `${searchQuery} @optionId:${t.optionSelected.optionId}`;
				// }
				return searchQuery;
			},
			// COMPUTED HEADERS
			computedHeaders() {
				/* Computed headers are used to decide which headers to show / hide */
				let t = this;
				let headers = t.headers;
				// additional loop to replace column headers which more appropriate names (for variants only)
				if (JSON.stringify(t.options) !== "[]") {
					headers.forEach((header) => {
						{
							for (var i = 1; i <= 10; i++) {
								if (header.value === "optionValue" + i) {
									// console.log('header.value = ' + header.value)
									// console.log("x = " + t.product["optionName" + i]);
									if (!t.isOptionVariant(t.product["optionName" + i])) {
										header.hidden = true;
									} else {
										header.text = t.findOptionName(t.product["optionName" + i]);
										header.hidden = false;
									}
								}
							}
						}
					});
				}
				headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
				return headers;
			},
			// COMPUTED ITEMS
			computedItems() {
				/* Computed Items modifies some of the data before its displayed in the table */
				let t = this;
				let items = this.items;
				items = items.map((x) => ({
					id: x.id,
					variantCode: x.variantCode,
					productCode: x.productCode,
					productId: x.productId,
					productName: x.productName,
					variantDescription: x.variantDescription,
					key: x.key,
					variantMainImage: x.variantMainImage,
					orgId: x.orgId,
					orgName: x.orgName,
					optionName1: t.findOptionName(x.optionName1),
					optionValue1: t.findAttributeName(x.optionValue1),
					optionName2: t.findOptionName(x.optionName2),
					optionValue2: t.findAttributeName(x.optionValue2),
					optionName3: t.findOptionName(x.optionName3),
					optionValue3: t.findAttributeName(x.optionValue3),
					optionName4: t.findOptionName(x.optionName4),
					optionValue4: t.findAttributeName(x.optionValue4),
					optionName5: t.findOptionName(x.optionName5),
					optionValue5: t.findAttributeName(x.optionValue5),
					optionName6: t.findOptionName(x.optionName6),
					optionValue6: t.findAttributeName(x.optionValue6),
					optionName7: t.findOptionName(x.optionName7),
					optionValue7: t.findAttributeName(x.optionValue7),
					optionName8: t.findOptionName(x.optionName8),
					optionValue8: t.findAttributeName(x.optionValue8),
					optionName9: t.findOptionName(x.optionName9),
					optionValue9: t.findAttributeName(x.optionValue9),
					optionName10: t.findOptionName(x.optionName10),
					optionValue10: t.findAttributeName(x.optionValue10),
					MOQ: x.MOQ,
					pricingType: x.pricingType,
					RRP: "£" + parseFloat(x.RRP).toFixed(2),
					MP: "£" + parseFloat(x.MP).toFixed(2),
					cQ1: "£" + parseFloat(x.cQ1).toFixed(2),
					cP1: "£" + parseFloat(x.cP1).toFixed(2),
					cQ2: "£" + parseFloat(x.cQ2).toFixed(2),
					cP2: "£" + parseFloat(x.cP2).toFixed(2),
					cQ3: "£" + parseFloat(x.cQ3).toFixed(2),
					cP3: "£" + parseFloat(x.cP3).toFixed(2),
					sQ1: "£" + parseFloat(x.sQ1).toFixed(2),
					sP1: "£" + parseFloat(x.sP1).toFixed(2),
					sQ2: "£" + parseFloat(x.sQ2).toFixed(2),
					sP2: "£" + parseFloat(x.sP2).toFixed(2),
					sQ3: "£" + parseFloat(x.sQ3).toFixed(2),
					sP3: "£" + parseFloat(x.sP3).toFixed(2),
					cBA: "£" + parseFloat(x.cBA).toFixed(2),
					cBB: "£" + parseFloat(x.cBB).toFixed(2),
					cBC: "£" + parseFloat(x.cBC).toFixed(2),
					cBD: "£" + parseFloat(x.cBD).toFixed(2),
					cBE: "£" + parseFloat(x.cBE).toFixed(2),
					cBF: "£" + parseFloat(x.cBF).toFixed(2),
					sBA: "£" + parseFloat(x.sBA).toFixed(2),
					sBB: "£" + parseFloat(x.sBB).toFixed(2),
					sBC: "£" + parseFloat(x.sBC).toFixed(2),
					sBD: "£" + parseFloat(x.sBD).toFixed(2),
					sBE: "£" + parseFloat(x.sBE).toFixed(2),
					sBF: "£" + parseFloat(x.sBF).toFixed(2),
					NPS: "£" + parseFloat(x.NPS).toFixed(2),
					custom1: x.custom1,
					custom2: x.custom2,
					custom3: x.custom3,
					custom4: x.custom4,
					custom5: x.custom5,
					custom6: x.custom6,
					custom7: x.custom7,
					custom8: x.custom8,
					custom9: x.custom9,
					custom10: x.custom10,
					createdDateTime: x.createdDateTime,
					createdUserId: x.createdUserId,
					createdUserName: x.createdUserName,
					createdUserEmail: x.createdUserEmail,
					modifiedDateTime: x.modifiedDateTime,
					modifiedUserId: x.modifiedUserId,
					modifiedUserName: x.modifiedUserName,
					modifiedUserEmail: x.modifiedUserEmail,
					deleted: x.deleted,
					deletedDateTime: x.deletedDateTime,
					deletedUserId: x.deletedUserId,
					deletedUserName: x.deletedUserName,
					deletedUserEmail: x.deletedUserEmail,
				}));
				return items; //.slice(this.organisationsFrom, this.organisationsTo);
			},
		},
		// * WATCH
		watch: {
			// DATA DOWN FROM PARENT
			datadown: {
				handler: function () {
					this.product = this.datadown;
					this.getItems();
				},
				deep: true,
			},
			headers: {
				handler: function () {
					this.getItems();
				},deep: true,
			},
            toggleForm: {
                handler: function () {
                    if (this.toggleForm) {
                        this.item.id = this.MIX_generateId();
                    }
                }, deep: true
            }
		},
		// * METHODS
		methods: {
            updateItemImage(fileName) {
                console.log('updateItemImage', fileName)
                this.item.variantImageId = fileName;
            },
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				t.item = { ...t.schema };
				t.item.productName = t.product.productName;
				t.item.productCode = t.product.productCode;
				t.item.productId = t.product.id;
				t.item.orgId = t.product.orgId;
				t.item.orgName = t.product.orgName;
				t.item.optionName1 = t.product.optionName1;
				t.item.optionName2 = t.product.optionName2;
				t.item.optionName3 = t.product.optionName3;
				t.item.optionName4 = t.product.optionName4;
				t.item.optionName5 = t.product.optionName5;
				t.item.optionName6 = t.product.optionName6;
				t.item.optionName7 = t.product.optionName7;
				t.item.optionName8 = t.product.optionName8;
				t.item.optionName9 = t.product.optionName9;
				t.item.optionName10 = t.product.optionName10;
			},
			// CHECK OPTION IS A VARIANT
			isOptionVariant(optionId) {
				if (optionId !== "") {
					// console.log("optionId = " + optionId);
					// console.log("options = " + JSON.stringify(this.options, null, 2));
					let option = this.options.find((x) => x.id === optionId);
					// console.log("option = " + JSON.stringify(option, null, 2));
					if (option.optionVariant === "1") {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			},
			// FIND OPTION
			findOption(optionId) {
				if (optionId !== "") {
					return this.options.find((x) => x.id === optionId);
				} else {
					return "";
				}
			},
			// FIND OPTION NAME
			findOptionName(optionId) {
				let result = "";
				if (optionId !== "") {
					result = this.options.find((x) => x.id === optionId);
					if (result !== undefined) {
						result = result.optionName;
					}
				}
				return result;
			},
			// FIND ATTRIBUTE NAME
			findAttributeName(id) {
				let result = [];
				if (id !== "") {
					result = this.attributes.find((x) => x.id === id);
					if (result !== undefined) {
						result = result.attName;
					}
				}
				return result;
			},
			// FILTERED ATTRIBUTES
			filteredAttributes(option, optionId) {
				let attributes = this.attributes;
				if (this.product["optionDependancy" + option] !== "" && this.product["optionDependancy" + option] !== undefined && this.product["optionDependancy" + option] !== null) {
					var dependancy = this.item["optionDependancy" + option];
					attributes = attributes.filter((x) => x.optionId === optionId && x.attParentId === this.preview["option" + dependancy]);
				} else {
					attributes = attributes.filter((x) => x.optionId === optionId);
				}
				return attributes;
			},
			// CREATE ITEM
			async createItem() {
				let t = this;
				let result = await t.MIX_createItem(t.item, t.key, t.itemNew);
				if (result.code === 1) {
					t.getItems();
					t.initialiseItem();
				}
			},
			// READ ITEM
			async readItem(itemId, key) {
				let t = this;
				let result = await t.MIX_readItem(itemId, key);
				if (result.code === 1) {
					t.item = result.data;
				}
			},
			// UPDATE ITEM
			async updateItem() {
				let t = this;
				let result = await t.MIX_createItem(t.item, t.key, t.itemNew);
				if (result.code === 1) {
					t.getItems();
					t.initialiseItem();
					t.toggleForm = false;
				}
			},
			// DELETE ITEM
			async deleteItem(itemId) {
				let result = await this.MIX_deleteItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// RESTORE ITEM
			async restoreItem(itemId) {
				let result = await this.MIX_restoreItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// DESTROY ITEM
			async destroyItem(itemId) {
				let result = await this.MIX_destroyItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// REPLICATE ITEM
			async replicateItem(itemId) {
				let t = this;
				let readResult = await t.MIX_readItem(itemId, t.key);
				if (readResult.code === 1) {
					let item = readResult.data;
					item.id = t.MIX_generateId();
					let createResult = await t.MIX_createItem(item, t.key, true);
					if (createResult.code === 1) {
						t.getItems();
						t.initialiseItem();
					}
				}
			},
			// GET ITEMS
			async getItems() {
				this.loading = true;
				let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
				if (itemsResult.code === 1) {
					this.loading = false;
					this.itemsTotal = itemsResult.data.total;
					this.items = itemsResult.data.results;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
				}
			},
			// READ OPTIONS FROM DB
			async readOptions() {
				let result = await this.MIX_redisSearch("idx:options", `@deleted:0 @enabled:1 @orgId:{${this.datadown.orgId}}`, 0, 9999);
				this.options = result.data.documents.map((x) => {
					return {
						id: x.value.id,
						optionName: x.value.optionName,
						optionVariant: x.value.optionVariant,
					};
				});
			},
			// READ ATTRIBUTES FROM DB
			async readAttributes() {
				let result = await this.MIX_redisSearch("idx:attributes", `@deleted:0 @enabled:1 @orgId:{${this.datadown.orgId}}`, 0, 9999);
				this.attributes = result.data.documents.map((x) => {
					return {
						id: x.value.id,
						attName: x.value.attName,
						optionId: x.value.optionId,
						optionName: x.value.optionName,
					};
				});
			},
      // Export Box CSV
      async exportCSV(itemsFrom, itemsTo, filter, specific) {
        // console.table({ itemsFrom, itemsTo, filter, specific });
        if (itemsFrom > itemsTo) {
          this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
        } else {
          this.exportLoading = true;
          var itemsCount, tableData, csv, query, exportFileName;
          switch (true) {
              // Current Page / Current Total (filtered)
            case filter && !specific:
              itemsCount = itemsTo - itemsFrom;
              query = this.computedSearchQuery;
              break;
              // All (Not filtered)
            case !filter && !specific:
              itemsCount = this.totalCount;
              query = `*`;
              break;
              // Specific (filtered/ not filtered)
            case specific:
              itemsFrom = itemsFrom - 1;
              itemsCount = itemsTo - itemsFrom;
              if (this.exportFiltered) {
                query = this.computedSearchQuery;
              } else {
                query = `*`;
              }
              break;
          }
          // console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
          // let exportResult = await this.REDIS_searchFor("animal", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
          let exportResult = await this.MIX_redisAggregateSearch(this.index, query, itemsFrom, itemsCount, this.computedFields, this.sortBy, false);
          // console.log(exportResult)
          tableData = JSON.parse(JSON.stringify(exportResult.data.results));
          // console.table(tableData)
          csv = this.$papa.unparse(tableData);
          if (itemsFrom === 0) {
            itemsFrom = 1; // This is just for filename purposes
          }
          if (itemsFrom >= 10) {
            itemsFrom = itemsFrom + 1;
          }
          switch (true) {
            case !specific && filter:
              exportFileName = `People_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case !specific && !filter:
              exportFileName = `People_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && this.exportFiltered:
              exportFileName = `People_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && !this.exportFiltered:
              exportFileName = `People_Specific_${itemsFrom}_to_${itemsTo}`;
              break;
          }
          // console.log(exportFileName);
          // console.log(csv.length);
          this.$papa.download(csv, exportFileName);
          this.exportLoading = false;
        }
      },
      async getTotal(){
        let totalResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
        // console.log(totalResult)
        this.totalCount = totalResult.data.total;
      },
		},
		// * CREATED
		created() {
			this.schema = schema.variant;
			this.product = this.datadown;
			this.getItems();
			this.initialiseItem();
      this.getTotal();
		},
		async mounted() {
			await this.readOptions();
			await this.readAttributes();
		},
	};
</script>
