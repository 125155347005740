
<template>
    <div class="grey lighten-3" style="height: calc(100vh - 150px) !important; overflow-y: scroll !important; overflow-x: hidden !important">
        <!-- Menu Buttons -->
        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
            <v-col cols="12" class="d-flex align-center justify-start primary py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <div class="white--text text-h5 font-weight-bold ml-3">Product Selector</div>
                <v-spacer />
            </v-col>
        </v-row>
        <!-- <pre>{{ datadown }}</pre> -->
        <v-row class="ma-0">
            <v-col cols="12" sm="12" class="">
                <!-- <pre v-if="itemSelected.variantImageId">{{ itemSelected.variantImageId }}</pre> -->
                <!-- <div class="d-flex align-center px-3" v-if="quoteid !== ''">
                    <div class="text-left primary--text text-h6 font-weight-light">Product / Variant Selector</div>
                    <v-spacer />
                    <v-btn @click="$emit('close')" class="primary--text">
                        <v-icon>icons8-multiply</v-icon>
                    </v-btn>
                </div> -->
                <v-row class="">
                    <!-- PRODUCT DETAILS / SELECTION -->
                    <v-col cols="6" xs="6" :sm="quoteid === '' ? '8':'6'" class="">
                        <span class="text-h6 secondary--text">{{ item.productName }}</span
                        ><br />
                        <span class="body-2">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Supplier Code</th>
                                            <th>Previous Code</th>
                                            <th>Product ID</th>
                                            <th>Pricing Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ item.productSupplierCode }}</td>
                                            <td>{{ item.productPreviousCode }}</td>
                                            <td>{{ item.id }}</td>
                                            <td>{{ item.pricingType }}</td>
                                        </tr>
                                    </tbody>

                                    <thead>
                                        <tr>
                                            <th colspan="2">Short Description</th>
                                            <th colspan="2">Dimensions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="2">{{ item.productShortDescription }}</td>

                                            <td colspan="2">{{ item.productDimensions }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </span>
                        <!-- <pre>{{ optionsSelected }}</pre> -->
                        <!-- OPTION SELECTIONS -->
                        <!-- item.optionName1: {{ item.optionName1 }} -->
                        <v-form ref="form" lazy-validation>
                            <v-row class="" dense v-if="JSON.stringify(item) !== '{}'">
                                <v-col cols="12" xs="12" sm="12" class="mt-3">
                                    <strong class="primary--text">Step 1 - Select Product Options *</strong><br />
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center" v-if="item.optionName1 !== ''">
                                    <v-autocomplete :rules="rules.optionName1" :label="findOptionName(item.optionName1)" v-model="optionsSelected.optionValue1" :items="getAttributes(1)" dense :menu-props="{ top: false, offsetY: true }" required :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue1')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="item.optionName2 !== ''">
                                    <!-- optionDependancy2: {{ item.optionDependancy2}}<br /> -->
                                    <v-autocomplete :rules="rules.optionName2" :label="findOptionName(item.optionName2)" v-model="optionsSelected.optionValue2" :items="getAttributes(2)" dense :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue2')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="item.optionName3 !== ''">
                                    <!-- optionDependancy3: {{ item.optionDependancy3}}<br /> -->
                                    <v-autocomplete :rules="rules.optionName3" :label="findOptionName(item.optionName3)" v-model="optionsSelected.optionValue3" :items="getAttributes(3)" dense :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue3')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="item.optionName4 !== ''">
                                    <!-- optionDependancy4: {{ item.optionDependancy4}}<br /> -->
                                    <v-autocomplete :rules="rules.optionName4" :label="findOptionName(item.optionName4)" v-model="optionsSelected.optionValue4" :items="getAttributes(4)" dense :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue4')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="item.optionName5 !== ''">
                                    <!-- optionDependancy5: {{ item.optionDependancy5}}<br /> -->
                                    <v-autocomplete :rules="rules.optionName5" :label="findOptionName(item.optionName5)" v-model="optionsSelected.optionValue5" :items="getAttributes(5)" dense :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue5')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="item.optionName6 !== ''">
                                    <!-- optionDependancy6: {{ item.optionDependancy6}}<br /> -->
                                    <v-autocomplete :rules="rules.optionName6" :label="findOptionName(item.optionName6)" v-model="optionsSelected.optionValue6" :items="getAttributes(6)" dense :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue6')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="item.optionName7 !== ''">
                                    <!-- optionDependancy7: {{ item.optionDependancy7}}<br /> -->
                                    <v-autocomplete :rules="rules.optionName7" :label="findOptionName(item.optionName7)" v-model="optionsSelected.optionValue7" :items="getAttributes(7)" dense :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue7')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="item.optionName8 !== ''">
                                    <!-- optionDependancy8: {{ item.optionDependancy8}}<br /> -->
                                    <v-autocomplete :rules="rules.optionName8" :label="findOptionName(item.optionName8)" v-model="optionsSelected.optionValue8" :items="getAttributes(8)" dense :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue8')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="item.optionName9 !== ''">
                                    <v-autocomplete :rules="rules.optionName9" :label="findOptionName(item.optionName9)" v-model="optionsSelected.optionValue9" :items="getAttributes(9)" dense :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue9')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="item.optionName10 !== ''">
                                    <v-autocomplete :rules="rules.optionName10" :label="findOptionName(item.optionName10)" v-model="optionsSelected.optionValue10" :items="getAttributes(10)" dense :menu-props="{ top: false, offsetY: true }" :attach="true" item-text="value" item-value="value" outlined class="" background-color="white" hide-details="auto" clearable @click:clear="clearOptionSelected('optionValue10')">
                                        <template v-slot:selection="{ item }"> {{ findAttributeName(item.value) }} </template>
                                        <template v-slot:item="{ item }"> {{ findAttributeName(item.value) }} </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" class="d-flex align-center caption" v-if="activeKey !== 'product'">
                                    <v-btn depressed class="accent secondary--text" @click="validateLineItem()">Save Options</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <!-- SIMPLE PRODUCT SELECT -->
                        <v-row  v-if="item.hasVariants === '0' && quoteid !== ''">
                            <v-col cols="12" xs="12" sm="4" class="" >
                                <strong class="primary--text">Step 2 - Select Product *</strong><br />
                                <v-btn class="accent secondary--text" @click="selectItem(item)" v-if="item.hasVariants === '0' && quoteid !== ''">Select</v-btn>
                            </v-col>
                        </v-row>

                    </v-col>
                    <!-- <pre>Quote Id: {{ quoteid }}</pre> -->
                    <!-- PRODUCT PREVIEW -->
                    <v-col cols="12" xs="12" :sm="quoteid === '' ? '4':'2'" class="justify-center mt-12">
                        <!-- {{ item.productImageId }} -->
                        <div v-if="itemSelected == null ">
                            <!-- no item selected
                            {{ item.productImageId }} -->
                            <div v-if="item.productImageId !== '' && item.productImageId !== undefined" class="black d-flex align-center white pa-1" style="border: 1px solid lightgrey !important; border-radius: 5px !important"><v-img class="" height="200" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/' + item.productImageId + '.jpeg'" /></div>
                            <div v-if="item.productImageId === ''" class="black d-flex align-center white pa-1" style="border: 1px solid lightgrey !important; border-radius: 5px !important"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
                        </div>
                        <div v-if="itemSelected !== null && itemSelected.key == 'product'">
                            <!-- product 
                            {{ itemSelected.productImageId }} -->
                            <div v-if="itemSelected.productImageId !== '' && itemSelected.productImageId !== undefined" class="black d-flex align-center white pa-1" style="border: 1px solid lightgrey !important; border-radius: 5px !important"><v-img class="" height="200" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/' + itemSelected.productImageId + '.jpeg'" /></div>
                            <div v-if="itemSelected.productImageId === ''" class="black d-flex align-center white pa-1" style="border: 1px solid lightgrey !important; border-radius: 5px !important"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
                        </div>
                        <div v-if="itemSelected !== null && itemSelected.key == 'variant'">
                            <!-- variant
                            {{ itemSelected.variantImageId }} -->
                            <div v-if="itemSelected.variantImageId !== '' && itemSelected.variantImageId !== undefined" class="black d-flex align-center white pa-1" style="border: 1px solid lightgrey !important; border-radius: 5px !important"><v-img class="" height="200" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/' + itemSelected.variantImageId + '.jpeg'" /></div>
                            <div v-if="itemSelected.variantImageId === ''" class="black d-flex align-center white pa-1" style="border: 1px solid lightgrey !important; border-radius: 5px !important"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div>
                        </div>

                    </v-col>
                    <v-col cols="6" xs="4" sm="4" class="mt-2 secondary" v-if="quoteid !== ''">
                        <div class="d-flex align-center mb-2">
                            <div class="text-h7 font-weight-bold white--text">Step 3 - Check Line Item Description:</div>
                            <v-spacer />
                            <div><v-checkbox class="pa-0 ma-0" dark v-model="includeDimensions" label="Include Product Dimensions" hide-details="auto"></v-checkbox></div>
                        </div>
                        <div class="font-weight-normal body-2 white--text">Supplier Code</div>
                        <v-text-field v-if="item.productSupplierCode !== ''" class="mt-0 pa-0" background-color="white" hide-details="auto" v-model="computedSupplierCode"></v-text-field>
                        <div class="font-weight-normal body-2 white--text mt-2">Description</div>
                        <v-textarea rows="6" outlined class="mt-3 ma-0 pa-0" background-color="white" hide-details="auto" v-model="computedDescription"></v-textarea>
                        <div class="d-flex justify-end mt-3" width="100%">
                            <div class="body-2 white--text pr-2">Please note: if you change any options it will overwrite the Item Description</div>
                            <v-spacer />
                            

                            <v-btn depressed class="accent secondary--text" @click="validateForm()"> Add to Quote</v-btn>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" v-if="quoteid == '' && activeKey == 'product'">
                        <v-btn depressed class="accent secondary--text" @click="validateForm()"> Add to Quote</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- PRICING TABLE -->
                    <v-col cols="12" xs="12" sm="12" class="pa-3" v-if="item.hasVariants === '0'">
                        <table class="white pa-2 caption" width="100%">
                            <tr>
                                <th align="left" class="font-weight-bold">Supplier Code</th>
                                <th v-if="item.MP !== ''" align="left" class="font-weight-bold">MP</th>
                                <th v-if="item.RRP !== ''" align="left" class="font-weight-bold">RRP</th>
                                <th v-if="item.NPS !== ''" align="left" class="font-weight-bold">NPS</th>
                                <th v-if="item.cQ1 !== ''" align="left" class="font-weight-bold">Cost Qty 1</th>
                                <th v-if="item.cP1 !== ''" align="left" class="font-weight-bold">Cost Price 1</th>
                                <th v-if="item.cQ2 !== ''" align="left" class="font-weight-bold">Cost Qty 2</th>
                                <th v-if="item.cP2 !== ''" align="left" class="font-weight-bold">Cost Price 2</th>
                                <th v-if="item.cQ3 !== ''" align="left" class="font-weight-bold">Cost Qty 3</th>
                                <th v-if="item.cP3 !== ''" align="left" class="font-weight-bold">Cost Price 3</th>
                                <th v-if="item.sQ1 !== ''" align="left" class="font-weight-bold">Sell Qty 1</th>
                                <th v-if="item.sP2 !== ''" align="left" class="font-weight-bold">Sell Price 1</th>
                                <th v-if="item.sQ2 !== ''" align="left" class="font-weight-bold">Sell Qty 2</th>
                                <th v-if="item.sP2 !== ''" align="left" class="font-weight-bold">Sell Price 2</th>
                                <th v-if="item.sQ3 !== ''" align="left" class="font-weight-bold">Sell Qty 3</th>
                                <th v-if="item.sP3 !== ''" align="left" class="font-weight-bold">Sell Price 3</th>

                                <th v-if="item.cBA !== ''" align="left" class="font-weight-bold">Cost Band A</th>
                                <th v-if="item.cBB !== ''" align="left" class="font-weight-bold">Cost Band B</th>
                                <th v-if="item.cBC !== ''" align="left" class="font-weight-bold">Cost Band C</th>
                                <th v-if="item.cBD !== ''" align="left" class="font-weight-bold">Cost Band D</th>
                                <th v-if="item.cBE !== ''" align="left" class="font-weight-bold">Cost Band E</th>
                                <th v-if="item.cBF !== ''" align="left" class="font-weight-bold">Cost Band F</th>

                                <th v-if="item.sBA !== ''" align="left" class="font-weight-bold">Sell Band A</th>
                                <th v-if="item.sBB !== ''" align="left" class="font-weight-bold">Sell Band B</th>
                                <th v-if="item.sBC !== ''" align="left" class="font-weight-bold">Sell Band C</th>
                                <th v-if="item.sBD !== ''" align="left" class="font-weight-bold">Sell Band D</th>
                                <th v-if="item.sBE !== ''" align="left" class="font-weight-bold">Sell Band E</th>
                                <th v-if="item.sBF !== ''" align="left" class="font-weight-bold">Sell Band F</th>
                            </tr>
                            <tr>
                                <td align="left" class="body-1">{{ item.productSupplierCode }}</td>
                                <td v-if="item.MP !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.MP) }}</td>
                                <td v-if="item.RRP !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.RRP) }}</td>
                                <td v-if="item.NPS !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.NPS) }}</td>
                                <!-- Simple / Volume Pricing -->
                                <td v-if="item.cQ1 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cQ1) }}</td>
                                <td v-if="item.cP1 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cP1) }}</td>
                                <td v-if="item.cQ2 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cQ2) }}</td>
                                <td v-if="item.cP2 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cP2) }}</td>
                                <td v-if="item.cQ3 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cQ3) }}</td>
                                <td v-if="item.cP3 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cP3) }}</td>
                                <td v-if="item.sQ1 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sQ1) }}</td>
                                <td v-if="item.sP2 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sP1) }}</td>
                                <td v-if="item.sQ2 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sQ2) }}</td>
                                <td v-if="item.sP2 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sP2) }}</td>
                                <td v-if="item.sQ3 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sQ3) }}</td>
                                <td v-if="item.sP3 !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sP3) }}</td>
                                <!-- Cost Band Pricing -->
                                <td v-if="item.cBA !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cBA) }}</td>
                                <td v-if="item.cBB !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cBB) }}</td>
                                <td v-if="item.cBC !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cBC) }}</td>
                                <td v-if="item.cBD !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cBD) }}</td>
                                <td v-if="item.cBE !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cBE) }}</td>
                                <td v-if="item.cBF !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.cBF) }}</td>
                                <!-- Sell Band Pricing -->
                                <td v-if="item.sBA !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sBA) }}</td>
                                <td v-if="item.sBB !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sBB) }}</td>
                                <td v-if="item.sBC !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sBC) }}</td>
                                <td v-if="item.sBD !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sBD) }}</td>
                                <td v-if="item.sBE !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sBE) }}</td>
                                <td v-if="item.sBF !== ''" align="left" class="body-1">{{ new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.sBF) }}</td>
                            </tr>
                        </table>
                    </v-col>
                    <!-- VARIANT TABLE -->
                    <v-col cols="12" xs="12" sm="12" class="pa-3" v-if="item.hasVariants === '1'">
                        <div class="d-flex align-center">
                            <strong class="primary--text">Step 2 - Select Product Variant *</strong>
                            <div class="ml-2">
                                <v-menu offset-y class="grey lighten-3" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn depressed icon class="grey lighten-3 grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>icons8-table-properties</v-icon></v-btn>
                                    </template>
                                    <div class="white" style="width: 250px; max-height: 300px">
                                        <div class="pa-5 white" style="width: 100% !important">
                                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                                        </div>
                                    </div>
                                </v-menu>
                            </div>
                            <div class="pb-4 pl-2">
                                <v-switch v-model="headers[1].hidden" color="secondary" dense flat label="Show Image" hide-details="auto" :true-value="false" :false-value="true"></v-switch>
                            </div>

                        </div>
                        <!-- <br /> -->
                        <v-data-table sortable dense class="mt-3" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions" :loading="loading" loading-text="Loading... Please wait" :expanded.sync="expanded" show-expand>
                            <template v-slot:item.action="{ item }">
                                <!-- <v-btn small depressed class="accent secondary--text" @click="reviewLineItem(item)">Review</v-btn> -->
                                <!-- <v-btn small depressed class="accent secondary--text" @click="getSelected(item)">Select</v-btn> -->
                                <v-btn x-small depressed class="accent secondary--text" @click="selectItem(item)">Select</v-btn>
                            </template>
                            
                            <template v-slot:item.variantImageId="{ item }">
                                <!-- {{ item.variantImageId }} -->
                                <div v-if="item.variantImageId !== '' && item.variantImageId !== undefined" class="d-flex align-center pa-1">
                                    <img class="" height="50" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/' + item.variantImageId + '.jpeg'" />
                                </div>
                                <div v-if="item.variantImageId === ''" class="black d-flex align-center white pa-1">
                                    <v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon>
                                </div>
                            </template>

                            <!-- Volume Pricing - Replace 0.00 with - for easier reading -->
                            <template v-slot:item.cQ1="{ item }"
                                ><span v-if="!isNaN(item.cQ1)">{{ item.cQ1 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cQ2="{ item }"
                                ><span v-if="!isNaN(item.cQ2)">{{ item.cQ2 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cQ3="{ item }"
                                ><span v-if="!isNaN(item.cQ3)">{{ item.cQ3 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sQ1="{ item }"
                                ><span v-if="!isNaN(item.sQ1)">{{ item.sQ1 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sQ2="{ item }"
                                ><span v-if="!isNaN(item.sQ2)">{{ item.sQ2 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sQ3="{ item }"
                                ><span v-if="!isNaN(item.sQ3)">{{ item.sQ3 }}</span
                                ><span v-else>-</span></template
                            >

                            <template v-slot:item.cP1="{ item }"
                                ><span v-if="!isNaN(item.cP1)">{{ item.cP1 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cP2="{ item }"
                                ><span v-if="!isNaN(item.cP2)">{{ item.cP2 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cP3="{ item }"
                                ><span v-if="!isNaN(item.cP3)">{{ item.cP3 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sP1="{ item }"
                                ><span v-if="!isNaN(item.sP1)">{{ item.sP1 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sP2="{ item }"
                                ><span v-if="!isNaN(item.sP2)">{{ item.sP2 }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sP3="{ item }"
                                ><span v-if="!isNaN(item.sP3)">{{ item.sP3 }}</span
                                ><span v-else>-</span></template
                            >

                            <!-- Cost Bands - Replace 0.00 witih - for easier reading -->
                            <template v-slot:item.MP="{ item }"
                                ><span v-if="!isNaN(item.MP)">{{ item.MP }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.RRP="{ item }"
                                ><span v-if="!isNaN(item.RRP)">{{ item.RRP }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cST="{ item }"
                                ><span v-if="!isNaN(item.cST)">{{ item.cST }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sST="{ item }"
                                ><span v-if="!isNaN(item.sST)">{{ item.sST }}</span
                                ><span v-else>-</span></template
                            >

                            <!-- Cost Bands - Replace 0.00 witih - for easier reading -->
                            <template v-slot:item.cBA="{ item }"
                                ><span v-if="!isNaN(item.cBA)">{{ item.cBA }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cBB="{ item }"
                                ><span v-if="!isNaN(item.cBB)">{{ item.cBB }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cBC="{ item }"
                                ><span v-if="!isNaN(item.cBC)">{{ item.cBC }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cBD="{ item }"
                                ><span v-if="!isNaN(item.cBD)">{{ item.cBD }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cBE="{ item }"
                                ><span v-if="!isNaN(item.cBE)">{{ item.cBE }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.cBF="{ item }"
                                ><span v-if="!isNaN(item.cBF)">{{ item.cBF }}</span
                                ><span v-else>-</span></template
                            >
                            <!-- Sell Bands - Replace 0.00 witih - for easier reading -->
                            <template v-slot:item.sBA="{ item }"
                                ><span v-if="!isNaN(item.sBA)">{{ item.sBA }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sBB="{ item }"
                                ><span v-if="!isNaN(item.sBB)">{{ item.sBB }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sBC="{ item }"
                                ><span v-if="!isNaN(item.sBC)">{{ item.sBC }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sBD="{ item }"
                                ><span v-if="!isNaN(item.sBD)">{{ item.sBD }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sBE="{ item }"
                                ><span v-if="!isNaN(item.sBE)">{{ item.sBE }}</span
                                ><span v-else>-</span></template
                            >
                            <template v-slot:item.sBF="{ item }"
                                ><span v-if="!isNaN(item.sBF)">{{ item.sBF }}</span
                                ><span v-else>-</span></template
                            >

                            <template v-slot:item.optionValue1="{ item }">{{ findAttributeName(item.optionValue1) }}</template>
                            <template v-slot:item.optionValue2="{ item }">{{ findAttributeName(item.optionValue2) }}</template>
                            <template v-slot:item.optionValue3="{ item }">{{ findAttributeName(item.optionValue3) }}</template>
                            <template v-slot:item.optionValue4="{ item }">{{ findAttributeName(item.optionValue4) }}</template>
                            <template v-slot:item.optionValue5="{ item }">{{ findAttributeName(item.optionValue5) }}</template>
                            <template v-slot:item.optionValue6="{ item }">{{ findAttributeName(item.optionValue6) }}</template>
                            <template v-slot:item.optionValue7="{ item }">{{ findAttributeName(item.optionValue7) }}</template>
                            <template v-slot:item.optionValue8="{ item }">{{ findAttributeName(item.optionValue8) }}</template>
                            <template v-slot:item.optionValue9="{ item }">{{ findAttributeName(item.optionValue9) }}</template>
                            <template v-slot:item.optionValue10="{ item }">{{ findAttributeName(item.optionValue10) }}</template>
                            <template v-slot:expanded-item="{ headers, item }">
                                <td></td>
                                <td :colspan="computedHeaders.length" class="grey--text text--darken-2" align="left">
                                    {{ item.variantDescription }}
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                    <!-- <v-col>
                        <pre>{{ optionsSelected }}</pre>
                    </v-col> -->
                </v-row>
            </v-col>
            <!-- PREVIEW SELECTION -->
            <!-- <v-col cols="12" sm="4" class="white pa-5">
                <v-row>
                    <v-col class="apptext--text">
                        <p class="text-h5 font-weight-bold">Your Selection</p>
                        <table width="100%">
                            <tr v-for="(value, name) in lineItem" :key="name">
                                <td v-if="value !== ''">{{ name }}</td>
                                <td v-if="value !== ''">{{ value }}</td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
            </v-col> -->
        </v-row>
    </div>
</template>

<script>
export default {
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        quoteid: {
            type: String,
            default: '',
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        closed: true,
        orgId: '',
        loading: true,
        editmode: true,
        expanded: [],
        // redis
        key: 'variant',
        index: 'idx:variants',
        // Options Selected - Result from Dropdowns
        optionsSelected: {
            optionValue1: '',
            optionValue2: '',
            optionValue3: '',
            optionValue4: '',
            optionValue5: '',
            optionValue6: '',
            optionValue7: '',
            optionValue8: '',
            optionValue9: '',
            optionValue10: '',
        },
        activeKey: '',

        lineItem: {}, // Used for Exporting LineItem
        existingLineItem: {}, // Used for Storing Existing LineItem

        options: [],
        attributes: [],
        item: {},
        selection: {},

        // search / filtering
        searchQuery: '',

        // items
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 200,
        itemSelected: null,
        includeDimensions: false,

        // Headers to display in the Data Table
        headers: [
            { text: 'Actions', value: 'action', align: 'start', hidden: false },
            { text: 'Variant Image', value: 'variantImageId', hidden: true },
            { text: 'Id', value: 'id', hidden: true },
            { text: 'Variant Code', value: 'variantCode', hidden: true },
            { text: 'Supplier Code', value: 'variantSupplierCode', hidden: false },
            { text: 'Previous Code', value: 'variantPreviousCode', hidden: true },
            { text: 'Variant Description', value: 'variantDescription', hidden: true },
            { text: 'Product Code', value: 'productCode', hidden: true },
            { text: 'Product ID', value: 'productId', hidden: true },
            { text: 'Status', value: 'variantStatus', hidden: true },
            { text: 'Available From', value: 'variantAvailableFrom', hidden: true },
            { text: 'Available To', value: 'variantAvailableTo', hidden: true },
            { text: 'Image Old Path', value: 'variantImageOldPath', hidden: true },
            { text: 'Made to Order', value: 'variantMTO', hidden: true },
            { text: 'Enabled', value: 'enabled', hidden: true },
            { text: 'Supplier ID', value: 'orgId', hidden: true }, // should be orgName
            { text: 'Supplier Name', value: 'orgName', hidden: true }, // should be orgName
            { text: 'Option Name 1', value: 'optionName1', hidden: true },
            { text: 'Option Value 1', value: 'optionValue1', hidden: true },
            { text: 'Option Name 2', value: 'optionName2', hidden: true },
            { text: 'Option Value 2', value: 'optionValue2', hidden: true },
            { text: 'Option Name 3', value: 'optionName3', hidden: true },
            { text: 'Option Value 3', value: 'optionValue3', hidden: true },
            { text: 'Option Name 4', value: 'optionName4', hidden: true },
            { text: 'Option Value 4', value: 'optionValue4', hidden: true },
            { text: 'Option Name 5', value: 'optionName5', hidden: true },
            { text: 'Option Value 5', value: 'optionValue5', hidden: true },
            { text: 'Option Name 6', value: 'optionName6', hidden: true },
            { text: 'Option Value 6', value: 'optionValue6', hidden: true },
            { text: 'Option Name 7', value: 'optionName7', hidden: true },
            { text: 'Option Value 7', value: 'optionValue7', hidden: true },
            { text: 'Option Name 8', value: 'optionName8', hidden: true },
            { text: 'Option Value 8', value: 'optionValue8', hidden: true },
            { text: 'Option Name 9', value: 'optionName9', hidden: true },
            { text: 'Option Value 9', value: 'optionValue9', hidden: true },
            { text: 'Option Name 10', value: 'optionName10', hidden: true },
            { text: 'Option Value 10', value: 'optionValue10', hidden: true },
            { text: 'MOQ', value: 'MOQ', hidden: true },
            { text: 'Pricing Type', value: 'pricingType', hidden: true },
            { text: 'RRP', value: 'RRP', hidden: false },
            { text: 'Market Price', value: 'MP', hidden: false },
            { text: 'Cost Qty 1', value: 'cQ1', hidden: false },
            { text: 'Cost Price 1', value: 'cP1', hidden: false },
            { text: 'Cost Qty 2', value: 'cQ2', hidden: true },
            { text: 'Cost Price 2', value: 'cP2', hidden: true },
            { text: 'Cost Qty 3', value: 'cQ3', hidden: true },
            { text: 'Cost Price 3', value: 'cP3', hidden: true },
            { text: 'Sell Qty 1', value: 'sQ1', hidden: false },
            { text: 'Sell Price 1', value: 'sP1', hidden: false },
            { text: 'Sell Qty 2', value: 'sQ2', hidden: true },
            { text: 'Sell Price 2', value: 'sP2', hidden: true },
            { text: 'Sell Qty 3', value: 'sQ3', hidden: true },
            { text: 'Sell Price 3', value: 'sP3', hidden: true },
            { text: 'Cost Stocked', value: 'cST', hidden: false },
            { text: 'Sell Stocked', value: 'sST', hidden: false },
            { text: 'Cost Band A', value: 'cBA', hidden: false },
            { text: 'Cost Band B', value: 'cBB', hidden: false },
            { text: 'Cost Band C', value: 'cBC', hidden: false },
            { text: 'Cost Band D', value: 'cBD', hidden: false },
            { text: 'Cost Band E', value: 'cBE', hidden: false },
            { text: 'Cost Band F', value: 'cBF', hidden: false },
            { text: 'Sell Band A', value: 'sBA', hidden: false },
            { text: 'Sell Band B', value: 'sBB', hidden: false },
            { text: 'Sell Band C', value: 'sBC', hidden: false },
            { text: 'Sell Band D', value: 'sBD', hidden: false },
            { text: 'Sell Band E', value: 'sBE', hidden: false },
            { text: 'Sell Band F', value: 'sBF', hidden: false },
            { text: 'NPS', value: 'NPS', hidden: false },
            { text: 'Discount Group 1', value: 'discountGroup1', hidden: true },
            { text: 'Discount Group 2', value: 'discountGroup2', hidden: true },
            { text: 'Discount Group 3', value: 'discountGroup3', hidden: true },
            { text: 'Discount Group 4', value: 'discountGroup4', hidden: true },
            { text: 'Discount Group 5', value: 'discountGroup5', hidden: true },
            { text: 'Discount Group 6', value: 'discountGroup6', hidden: true },
            { text: 'Discount Group 7', value: 'discountGroup7', hidden: true },
            { text: 'Discount Group 8', value: 'discountGroup8', hidden: true },
            { text: 'Discount Group 9', value: 'discountGroup9', hidden: true },
            { text: 'Discount Group 10', value: 'discountGroup10', hidden: true },
            { text: 'Date Time (C)', value: 'createdDateTime', hidden: true },
            { text: 'User ID (C)', value: 'createdUserId', hidden: true },
            { text: 'User Name (C)', value: 'createdUserName', hidden: true },
            { text: 'User Email (C)', value: 'createdUserEmail', hidden: true },
            { text: 'Date Time (M)', value: 'modifiedDateTime', hidden: true },
            { text: 'User ID (M)', value: 'modifiedUserId', hidden: true },
            { text: 'User Name (M)', value: 'modifiedUserName', hidden: true },
            { text: 'User Email (M)', value: 'modifiedUserEmail', hidden: true },
            { text: 'Deleted', value: 'deleted', hidden: true },
            { text: 'Date Time (D)', value: 'deletedDateTime', hidden: true },
            { text: 'User ID (D)', value: 'deletedUserId', hidden: true },
            { text: 'User Name (D)', value: 'deletedUserName', hidden: true },
            { text: 'User Email (D)', value: 'deletedUserEmail', hidden: true },
            { text: 'Key', value: 'key', hidden: true },
        ],
        // Fields to Retrieve from Database
        sortBy: '@variantCode',
        datatableOptions: {
            page: 1,
            itemsPerPage: 100,
            sortBy: ['variantCode'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        showImageToggle: false,
        // Validation
        rules: {},
    }),
    // * COMPUTED
    computed: {
        computedSupplierCode() {
            let supplierCode = '';
            if (this.itemSelected !== null && this.itemSelected !== undefined && this.itemSelected !== '') {
                // description = this.itemSelected.variantName
                let attCode = ''
                if (this.itemSelected.key === 'product') {
                    supplierCode = this.itemSelected.productSupplierCode
                } else {
                    supplierCode = this.itemSelected.variantSupplierCode
                }
                if (this.optionsSelected?.optionValue1 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue1);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
                if (this.optionsSelected?.optionValue2 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue2);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
                if (this.optionsSelected?.optionValue3 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue3);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
                if (this.optionsSelected?.optionValue4 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue4);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
                if (this.optionsSelected?.optionValue5 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue5);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
                if (this.optionsSelected?.optionValue6 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue6);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
                if (this.optionsSelected?.optionValue7 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue7);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
                if (this.optionsSelected?.optionValue8 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue8);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
                if (this.optionsSelected?.optionValue9 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue9);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
                if (this.optionsSelected?.optionValue10 !== '') {
                    attCode = this.findAttributeCode(this.optionsSelected.optionValue10);
                    if (attCode !== '' && attCode !== null && attCode !== undefined) {
                        supplierCode = supplierCode + '-' + attCode
                    }
                }
            }
            return supplierCode
        },
        computedDescription() {
            let description = '';
            if (this.itemSelected !== null && this.itemSelected !== undefined && this.itemSelected !== '') {
                // description = this.itemSelected.variantName
                if (this.itemSelected.key === 'product') {
                    description = this.itemSelected.productShortDescription + '\n';
                } else {
                    description = this.itemSelected.variantDescription + '\n';
                }
            }
            if (this.includeDimensions) {
                description = description + 'Dimensions: ' + this.item.productDimensions + ' | \n';
            }
            let options = '';
            if (this.item.optionName1 !== '') {
                if (this.optionsSelected.optionValue1 !== '' && this.optionsSelected.optionValue1 !== null && this.optionsSelected.optionValue1 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName1) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue1) + ' | ';
                }
            }
            if (this.item.optionName2 !== '') {
                if (this.optionsSelected.optionValue2 !== '' && this.optionsSelected.optionValue2 !== null && this.optionsSelected.optionValue2 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName2) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue2) + ' | ';
                }
            }
            if (this.item.optionName3 !== '') {
                if (this.optionsSelected.optionValue3 !== '' && this.optionsSelected.optionValue3 !== null && this.optionsSelected.optionValue3 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName3) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue3) + ' | ';
                }
            }
            if (this.item.optionName4 !== '') {
                if (this.optionsSelected.optionValue4 !== '' && this.optionsSelected.optionValue4 !== null && this.optionsSelected.optionValue4 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName4) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue4) + ' | ';
                }
            }
            if (this.item.optionName5 !== '') {
                if (this.optionsSelected.optionValue5 !== '' && this.optionsSelected.optionValue5 !== null && this.optionsSelected.optionValue5 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName5) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue5) + ' | ';
                }
            }
            if (this.item.optionName6 !== '') {
                if (this.optionsSelected.optionValue6 !== '' && this.optionsSelected.optionValue6 !== null && this.optionsSelected.optionValue6 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName6) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue6) + ' | ';
                }
            }
            if (this.item.optionName7 !== '') {
                if (this.optionsSelected.optionValue7 !== '' && this.optionsSelected.optionValue7 !== null && this.optionsSelected.optionValue7 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName7) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue7) + ' | ';
                }
            }
            if (this.item.optionName8 !== '') {
                if (this.optionsSelected.optionValue8 !== '' && this.optionsSelected.optionValue8 !== null && this.optionsSelected.optionValue8 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName8) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue8) + ' | ';
                }
            }
            if (this.item.optionName9 !== '') {
                if (this.optionsSelected.optionValue9 !== '' && this.optionsSelected.optionValue9 !== null && this.optionsSelected.optionValue9 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName9) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue9) + ' | ';
                }
            }
            if (this.item.optionName10 !== '') {
                if (this.optionsSelected.optionValue10 !== '' && this.optionsSelected.optionValue10 !== null && this.optionsSelected.optionValue10 !== undefined) {
                    options = options + ' ' + this.findOptionName(this.item.optionName10) + ': ';
                    options = options + ' ' + this.findAttributeName(this.optionsSelected.optionValue10) + ' | ';
                }
            }

            // remove last | from options
            if (options.length > 0) {
                options = options.substring(0, options.length - 2);
            }
            options = options.trim();
            return description + options;
        },
        // COMPUTED FIELDS
        computedFields() {
            let computedFields = this.headers.map((y) => '@' + y.value);
            computedFields = computedFields.join(',');
            return computedFields;
        },
        // COMPUTED SEARCH QUERY
        computedSearchQuery() {
            /* The computed search query prepares the search query for Redis in the correct format */
            let t = this;
            let searchQuery = t.searchQuery;
            var id;
            //console.log('item = ' + JSON.stringify(t.item, null, 2));
            if (t.item.lineParentCodeId !== '' && t.item.lineParentCodeId !== undefined) {
                //console.log('here 1');
                id = t.item.lineParentCodeId; // For Variants Edit
            } else if (t.item.lineCodeId !== '' && t.item.lineCodeId !== undefined) {
                //console.log('here 2');
                id = t.item.lineCodeId; // For Products Edit
            } else {
                //console.log('here 3');
                id = t.item.id; // For Products Add
            }
            //console.log('id=', id);

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (t.toggleDeletedItems) {
                    searchQuery = `@productId:{${id}}`;
                } else {
                    searchQuery = `@deleted:0 @productId:{${id}}`;
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (t.toggleDeletedItems) {
                        searchQuery = `${searchQuery}* @productId:{${id}}`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0 @productId:{${id}}`;
                    }
                } else {
                    if (t.toggleDeletedItems) {
                        searchQuery = `${searchQuery}* @productId:{${id}}`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0 @productId:{${id}}`;
                    }
                }
            }
            // Filter By optionId
            if (id !== '') {
                //console.log('t.optionsSelected.optionValue1 = ' + t.optionsSelected.optionValue1);
                if (t.optionsSelected.optionValue1 !== '' && t.optionsSelected.optionValue1 !== undefined && t.isOptionVariant(t.item.optionName1)) {
                    searchQuery = `${searchQuery} @optionValue1:${t.optionsSelected.optionValue1}`;
                }
                if (t.optionsSelected.optionValue2 !== '' && t.optionsSelected.optionValue2 !== undefined && t.isOptionVariant(t.item.optionName2)) {
                    searchQuery = `${searchQuery} @optionValue2:${t.optionsSelected.optionValue2}`;
                }
                if (t.optionsSelected.optionValue3 !== '' && t.optionsSelected.optionValue3 !== undefined && t.isOptionVariant(t.item.optionName3)) {
                    searchQuery = `${searchQuery} @optionValue3:${t.optionsSelected.optionValue3}`;
                }
                if (t.optionsSelected.optionValue4 !== '' && t.optionsSelected.optionValue4 !== undefined && t.isOptionVariant(t.item.optionName4)) {
                    searchQuery = `${searchQuery} @optionValue4:${t.optionsSelected.optionValue4}`;
                }
                if (t.optionsSelected.optionValue5 !== '' && t.optionsSelected.optionValue5 !== undefined && t.isOptionVariant(t.item.optionName5)) {
                    searchQuery = `${searchQuery} @optionValue5:${t.optionsSelected.optionValue5}`;
                }
                if (t.optionsSelected.optionValue6 !== '' && t.optionsSelected.optionValue6 !== undefined && t.isOptionVariant(t.item.optionName6)) {
                    searchQuery = `${searchQuery} @optionValue6:${t.optionsSelected.optionValue6}`;
                }
                if (t.optionsSelected.optionValue7 !== '' && t.optionsSelected.optionValue7 !== undefined && t.isOptionVariant(t.item.optionName7)) {
                    searchQuery = `${searchQuery} @optionValue7:${t.optionsSelected.optionValue7}`;
                }
                if (t.optionsSelected.optionValue8 !== '' && t.optionsSelected.optionValue8 !== undefined && t.isOptionVariant(t.item.optionName8)) {
                    searchQuery = `${searchQuery} @optionValue8:${t.optionsSelected.optionValue8}`;
                }
                if (t.optionsSelected.optionValue9 !== '' && t.optionsSelected.optionValue9 !== undefined && t.isOptionVariant(t.item.optionName9)) {
                    searchQuery = `${searchQuery} @optionValue9:${t.optionsSelected.optionValue9}`;
                }
                if (t.optionsSelected.optionValue10 !== '' && t.optionsSelected.optionValue10 !== undefined && t.isOptionVariant(t.item.optionName10)) {
                    searchQuery = `${searchQuery} @optionValue10:${t.optionsSelected.optionValue10}`;
                }
                //console.log('searchQuery', searchQuery);
                return searchQuery;
            } else {
                //console.log('searchQuery', searchQuery);
                return searchQuery;
            }
        },
        // COMPUTED HEADERS
        computedHeaders() {
            /* Computed headers are used to decide which headers to show / hide */
            let t = this;
            let headers = t.headers;
            // additional loop to replace column headers which more appropriate names (for variants only)
            if (JSON.stringify(t.options) !== '[]') {
                headers.forEach((header) => {
                    {
                        for (var i = 1; i <= 10; i++) {
                            if (header.value === 'optionValue' + i) {
                                if (!t.isOptionVariant(t.item['optionName' + i])) {
                                    header.hidden = true;
                                } else {
                                    header.text = t.findOptionName(t.item['optionName' + i]);
                                    header.hidden = false;
                                }
                            }
                        }
                    }
                });
            }
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            switch (t.item.pricingType) {
                case 'Band':
                    headers = headers.filter((header) => !['cQ1', 'cP1', 'cQ2', 'cP2', 'cQ3', 'cP3', 'sQ1', 'sP1', 'sQ2', 'sP2', 'sQ3', 'sP3'].includes(header.value));
                    break;
                case 'Volume':
                    headers = headers.filter((header) => !['cBA', 'cBB', 'cBC', 'cBD', 'cBE', 'cBF', 'sBA', 'sBB', 'sBC', 'sBD', 'sBE', 'sBF'].includes(header.value));
                    break;
                case 'Simple':
                    headers = headers.filter((header) => !['cQ2', 'cP2', 'cQ3', 'cP3', 'sQ2', 'sP2', 'sQ3', 'sP3', 'cBA', 'cBB', 'cBC', 'cBD', 'cBE', 'cBF', 'sBA', 'sBB', 'sBC', 'sBD', 'sBE', 'sBF'].includes(header.value));
                    break;
            }

            return headers;
        },
        // COMPUTED ITEMS
        computedItems() {
            /* Computed Items modifies some of the data before its displayed in the table */
            //let t = this;
            let items = this.items;
            items = items.map((x) => ({
                id: x.id,
                variantCode: x.variantCode,
                variantSupplierCode: x.variantSupplierCode,
                productCode: x.productCode,
                productId: x.productId,
                productName: x.productName,
                variantDescription: x.variantDescription,
                key: x.key,
                variantImageId: x.variantImageId,
                orgId: x.orgId,
                orgName: x.orgName,
                optionName1: x.optionName1,
                optionValue1: x.optionValue1,
                optionName2: x.optionName2,
                optionValue2: x.optionValue2,
                optionName3: x.optionName3,
                optionValue3: x.optionValue3,
                optionName4: x.optionName4,
                optionValue4: x.optionValue4,
                optionName5: x.optionName5,
                optionValue5: x.optionValue5,
                optionName6: x.optionName6,
                optionValue6: x.optionValue6,
                optionName7: x.optionName7,
                optionValue7: x.optionValue7,
                optionName8: x.optionName8,
                optionValue8: x.optionValue8,
                optionName9: x.optionName9,
                optionValue9: x.optionValue9,
                optionName10: x.optionName10,
                optionValue10: x.optionValue10,
                MOQ: x.MOQ,
                pricingType: x.pricingType,
                RRP: parseFloat(x.RRP).toFixed(2),
                MP: parseFloat(x.MP).toFixed(2),
                cST: parseFloat(x.cST).toFixed(2),
                sST: parseFloat(x.sST).toFixed(2),
                cQ1: parseFloat(x.cQ1).toFixed(2),
                cP1: parseFloat(x.cP1).toFixed(2),
                cQ2: parseFloat(x.cQ2).toFixed(2),
                cP2: parseFloat(x.cP2).toFixed(2),
                cQ3: parseFloat(x.cQ3).toFixed(2),
                cP3: parseFloat(x.cP3).toFixed(2),
                sQ1: parseFloat(x.sQ1).toFixed(2),
                sP1: parseFloat(x.sP1).toFixed(2),
                sQ2: parseFloat(x.sQ2).toFixed(2),
                sP2: parseFloat(x.sP2).toFixed(2),
                sQ3: parseFloat(x.sQ3).toFixed(2),
                sP3: parseFloat(x.sP3).toFixed(2),
                cBA: parseFloat(x.cBA).toFixed(2),
                cBB: parseFloat(x.cBB).toFixed(2),
                cBC: parseFloat(x.cBC).toFixed(2),
                cBD: parseFloat(x.cBD).toFixed(2),
                cBE: parseFloat(x.cBE).toFixed(2),
                cBF: parseFloat(x.cBF).toFixed(2),
                sBA: parseFloat(x.sBA).toFixed(2),
                sBB: parseFloat(x.sBB).toFixed(2),
                sBC: parseFloat(x.sBC).toFixed(2),
                sBD: parseFloat(x.sBD).toFixed(2),
                sBE: parseFloat(x.sBE).toFixed(2),
                sBF: parseFloat(x.sBF).toFixed(2),
                NPS: parseFloat(x.NPS).toFixed(2),
                discountGroup1: x.discountGroup1,
                discountGroup2: x.discountGroup2,
                discountGroup3: x.discountGroup3,
                discountGroup4: x.discountGroup4,
                discountGroup5: x.discountGroup5,
                discountGroup6: x.discountGroup6,
                discountGroup7: x.discountGroup7,
                discountGroup8: x.discountGroup8,
                discountGroup9: x.discountGroup9,
                discountGroup10: x.discountGroup10,
                createdDateTime: x.createdDateTime,
                createdUserId: x.createdUserId,
                createdUserName: x.createdUserName,
                createdUserEmail: x.createdUserEmail,
                modifiedDateTime: x.modifiedDateTime,
                modifiedUserId: x.modifiedUserId,
                modifiedUserName: x.modifiedUserName,
                modifiedUserEmail: x.modifiedUserEmail,
                deleted: x.deleted,
                deletedDateTime: x.deletedDateTime,
                deletedUserId: x.deletedUserId,
                deletedUserName: x.deletedUserName,
                deletedUserEmail: x.deletedUserEmail,
            }));
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        productShortDescription() {
            let productShortDescription = '';
            if (JSON.stringify(this.item) !== '{}') {
                if (this.optionsSelected.optionValue1 !== '') {
                    productShortDescription = this.findOptionName(this.item.optionName1) + ': ' + this.findAttributeName(this.optionsSelected.optionValue1);
                }
                if (this.optionsSelected.optionValue2 !== '') {
                    productShortDescription = productShortDescription + ', ' + this.findOptionName(this.item.optionName2) + ': ' + this.findAttributeName(this.optionsSelected.optionValue2);
                }
                if (this.optionsSelected.optionValue3 !== '') {
                    productShortDescription = productShortDescription + ', ' + this.findOptionName(this.item.optionName3) + ': ' + this.findAttributeName(this.optionsSelected.optionValue3);
                }
                if (this.optionsSelected.optionValue4 !== '') {
                    productShortDescription = productShortDescription + ', ' + this.findOptionName(this.item.optionName4) + ': ' + this.findAttributeName(this.optionsSelected.optionValue4);
                }
                if (this.optionsSelected.optionValue5 !== '') {
                    productShortDescription = productShortDescription + ', ' + this.findOptionName(this.item.optionName5) + ': ' + this.findAttributeName(this.optionsSelected.optionValue5);
                }
                if (this.optionsSelected.optionValue6 !== '') {
                    productShortDescription = productShortDescription + ', ' + this.findOptionName(this.item.optionName6) + ': ' + this.findAttributeName(this.optionsSelected.optionValue6);
                }
                if (this.optionsSelected.optionValue7 !== '') {
                    productShortDescription = productShortDescription + ', ' + this.findOptionName(this.item.optionName7) + ': ' + this.findAttributeName(this.optionsSelected.optionValue7);
                }
                if (this.optionsSelected.optionValue8 !== '') {
                    productShortDescription = productShortDescription + ', ' + this.findOptionName(this.item.optionName8) + ': ' + this.findAttributeName(this.optionsSelected.optionValue8);
                }
                if (this.optionsSelected.optionValue9 !== '') {
                    productShortDescription = productShortDescription + ', ' + this.findOptionName(this.item.optionName9) + ': ' + this.findAttributeName(this.optionsSelected.optionValue9);
                }
                if (this.optionsSelected.optionValue10 !== '') {
                    productShortDescription = productShortDescription + ', ' + this.findOptionName(this.item.optionName10) + ': ' + this.findAttributeName(this.optionsSelected.optionValue10);
                }
                return productShortDescription;
            } else {
                return '';
            }
        },
    },
    // * WATCH
    watch: {
        // DATA DOWN FROM PARENT
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
                this.getItems();
            },
            deep: true,
        },
        optionsSelected: {
            handler: function () {
                this.getItems();
            },
            deep: true,
        },
    },
    // * METHODS
    methods: {
        selectItem(item) {
            this.itemSelected = item;
            //alert(JSON.stringify(this.itemSelected, null, 2))
        },
        clearOptionSelected(property) {
            setTimeout(() => {
                this.optionsSelected[property] = '';
            }, 1);
        },
        async readOptions() {
            let result = await this.MIX_redisSearch('idx:options', `@deleted:0 @enabled:1 @orgId:{${this.datadown.orgId}}`, 0, 9999);
            this.options = result.data.documents.map((x) => {
                return {
                    id: x.value.id,
                    optionName: x.value.optionName,
                    optionVariant: x.value.optionVariant,
                };
            });
        },
        async readAttributes() {
            let result = await this.MIX_redisSearch('idx:attributes', `@deleted:0 @enabled:1 @orgId:{${this.datadown.orgId}}`, 0, 9999);
            this.attributes = result.data.documents.map((x) => {
                return {
                    id: x.value.id,
                    attName: x.value.attName,
                    attCode: x.value.attCode,
                    optionId: x.value.optionId,
                    optionName: x.value.optionName,
                    attParentId: x.value.attParentId,
                };
            });
        },
        // * READ ITEM
        async readItem(item) {
            let t = this;
            var id;
            if (item.lineParentId !== '' && item.lineParentId !== undefined) {
                id = item.lineParentCodeId; // For Variants Edit
            } else if (item.lineCodeId !== '' && item.lineCodeId !== undefined) {
                id = item.lineCodeId; // For Products Edit
            } else {
                id = item.id; // For Products Add
            }
            // console.log('id = ' + id);
            let result = await t.MIX_readItem(id, 'product');
            // console.log('ressult.code = ' + result.code)
            if (result.code === 1) {
                this.item = result.data;
            }
        },
        getAttributes(option) {
            var attributes = [];
            if (!this.closed) {
                //console.log('xxxx = ' + this.isOptionVariant(this.item['optionName' + option]))
                if (this.isOptionVariant(this.item['optionName' + option])) {
                    attributes = this.item['optionAttributes' + option];
                    if (attributes !== undefined) {
                        attributes = attributes.split(',');
                    }
                    let optionValues = this.items.map((x) => x['optionValue' + option]);
                    optionValues = Array.from(new Set(optionValues));
                    var disabledOptions;
                    if (attributes !== undefined) {
                        disabledOptions = attributes.filter((x) => optionValues.indexOf(x) === -1);
                    } else {
                        disabledOptions = [];
                    }
                    // check for disabled attributes
                    let attributesNew = [];
                    for (let i = 0; i < attributes.length; i++) {
                        // if attribute[i] is in disabledOptions, then add it to attributesNew
                        if (disabledOptions.indexOf(attributes[i]) !== -1) {
                            attributesNew.push({ value: attributes[i], disabled: true });
                        } else {
                            attributesNew.push({ value: attributes[i], disabled: false });
                        }
                    }
                    //}
                    attributes = attributesNew;
                } else {
                    // if (this.item['optionDependancy' + option] !== '') {
                    //     console.log('xxoptionDependancy = ' + this.item['optionDependancy' + option]);
                    // }
                    var attributesNew = [];
                    attributes = this.item['optionAttributes' + option];
                    //console.log('attributes = ' + JSON.stringify(attributes, null, 2));
                    if (attributes !== undefined) {
                        attributes = attributes.split(',');
                        for (let i = 0; i < attributes.length; i++) {
                            if (this.findAttributeParentId(attributes[i]) !== '') {
                                // get dependancy value
                                let dependancyValue = this.optionsSelected['optionValue' + this.item['optionDependancy' + option]]
                                // check parentId against dependancy
                                if (this.findAttributeParentId(attributes[i]) === dependancyValue) {
                                    attributesNew.push({ value: attributes[i], disabled: false });
                                } else {
                                    attributesNew.push({ value: attributes[i], disabled: true });
                                }
                            }
                            attributesNew.push({ value: attributes[i], disabled: false });
                        }
                        attributesNew.unshift({ value: 'TBC', disabled: false });
                    }
                    attributes = attributesNew;
                }
            }
            return attributes;
        },
        findOption(optionId) {
            if (optionId !== '') {
                return this.options.find((x) => x.id === optionId);
            } else {
                return '';
            }
        },
        findOptionName(optionId) {
            let result = '';
            if (optionId !== '') {
                result = this.options.find((x) => x.id === optionId);
                if (result !== undefined) {
                    result = result.optionName;
                }
            }
            return result;
        },
        findAttributeName(id) {
            let result = [];
            if (id !== '') {
                if (id !== 'TBC') {
                    result = this.attributes.find((x) => x.id === id);
                    if (result !== undefined) {
                        result = result.attName;
                    }
                } else {
                    result = 'TBC';
                }
            }
            return result;
        },
        findAttributeCode(id) {
            //console.log('attributes === ' + JSON.stringify(this.attributes, null, 2))
            let result = [];
            if (id !== '') {
                if (id !== 'TBC') {
                    result = this.attributes.find((x) => x.id === id);
                    if (result !== undefined) {
                        result = result.attCode;
                    }
                } else {
                    result = '';
                }
            }
            return result;
        },        
        findAttributeParentId(id) {
            let result = [];
            if (id !== '') {
                if (id !== 'TBC') {
                    result = this.attributes.find((x) => x.id === id);
                    if (result !== undefined) {
                        result = result.attParentId;
                    }
                } else {
                    result = 'TBC';
                }
            }
            return result;
        },        
        filteredAttributes(option, optionId) {
            let attributes = this.attributes;
            if (this.item['optionDependancy' + option] !== '' && this.item['optionDependancy' + option] !== undefined && this.item['optionDependancy' + option] !== null) {
                var dependancy = this.item['optionDependancy' + option];
                attributes = attributes.filter((x) => x.optionId === optionId && x.attParentId === this.optionsSelected['option' + dependancy]);
            } else {
                attributes = attributes.filter((x) => x.optionId === optionId);
            }
            return attributes;
        },
        // CHECK OPTION IS A VARIANT
        isOptionVariant(optionId) {
            // console.log(optionId);
            if (optionId !== '') {
                let option = this.options.find((x) => x.id === optionId);
                if (option?.optionVariant === '1') {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        // GET ITEMS
        async getItems() {
            this.loading = true;
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.loading = false;
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
        },

        async calculateLineItem(item) {
            // console.log('cqlculqte3LinItem = ' + JSON.stringify(item, null, 2));
            let lineItemResult = await this.CALC_lineItem('', '', item, item.MOQ, this.optionsSelected); // new line item
            this.lineItem = lineItemResult;
            this.$emit('additemtoquote', lineItemResult);
        },
        async updateLineItem(item) {
            let lineItemResult = await this.CALC_lineItem(this.existingLineItem.quoteId, this.existingLineItem.id, item, this.existingLineItem.lineQty, this.optionsSelected); // new line item
            this.lineItem = lineItemResult;
            this.$emit('additemtoquote', lineItemResult);
        },

        // New Filtering Dropdowns
        getFilteredAttributes(option) {
            let attributes = this.items.map((x) => x['optionValue' + option]);
            for (var i = 0; i < attributes.length; i++) {
                //attributes[i] = this.findAttributeName(attributes[i]);
            }
            return Array.from(new Set(attributes));
        },

        // NEW
        async reviewLineItem(item) {
            // console.log('this.existingLineItem.id = ', this.existingLineItem.id);
            let t = this;
            if (t.activeKey === 'product') {
                let lineItemResult = await t.CALC_lineItem('', item, t.optionsSelected, t.quoteid); // new line item
                t.lineItem = lineItemResult;
            } else {
                let lineItemResult = await t.CALC_lineItem(t.existingLineItem, item, t.optionsSelected, ''); // new line item
                t.lineItem = lineItemResult;
            }
        },
        validateLineItem() {
            this.rules = {
                optionName1: [(v) => !!v || 'Option Required'],
                optionName2: [(v) => !!v || 'Option Required'],
                optionName3: [(v) => !!v || 'Option Required'],
                optionName4: [(v) => !!v || 'Option Required'],
                optionName5: [(v) => !!v || 'Option Required'],
                optionName6: [(v) => !!v || 'Option Required'],
                optionName7: [(v) => !!v || 'Option Required'],
                optionName8: [(v) => !!v || 'Option Required'],
                optionName9: [(v) => !!v || 'Option Required'],
                optionName10: [(v) => !!v || 'Option Required'],
            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    // if (this.itemSelected !== null) {
                    //     this.createLineItem(this.itemSelected)
                    // } else {
                        this.MIX_alertBox({ color: 'success', timeout: 4000, message: "Validated", show: true})
                    // }
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: "Please fill out all the fields", show: true})
                }
            })
        },
        validateForm() {
            this.rules = {
                optionName1: [(v) => !!v || 'Option Required'],
                optionName2: [(v) => !!v || 'Option Required'],
                optionName3: [(v) => !!v || 'Option Required'],
                optionName4: [(v) => !!v || 'Option Required'],
                optionName5: [(v) => !!v || 'Option Required'],
                optionName6: [(v) => !!v || 'Option Required'],
                optionName7: [(v) => !!v || 'Option Required'],
                optionName8: [(v) => !!v || 'Option Required'],
                optionName9: [(v) => !!v || 'Option Required'],
                optionName10: [(v) => !!v || 'Option Required'],
            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    if (this.itemSelected !== null) {
                        this.createLineItem(this.itemSelected)
                    } else {
                        this.MIX_alertBox({ color: 'error', timeout: 4000, message: "Please select an item", show: true})
                    }
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: "Please fill out all the fields", show: true})
                }
            })
        },
        // FIXME - RG - Create Line Item not working
        async createLineItem(item) {
            // console.log('@@ createLineItem @@');
            // console.log('activeKey = ')
            //console.log('item ->', JSON.stringify(item, null, 2));
            let t = this;
            var lineItemResult;
            t.closed = true;
            // console.log('cli item = ' + JSON.stringify(item, null, 2));
            item.customDescription = t.computedDescription;
            item.productSupplierCode = t.computedSupplierCode;
            if (t.activeKey === 'product') {
                lineItemResult = await t.CALC_lineItem('', item, t.optionsSelected, t.quoteid); // new line item
                // console.log(' lineItemResult = ' + JSON.stringify(lineItemResult, null, 2));

                t.lineItem = lineItemResult;
                this.$emit('additemtoquote', t.lineItem); // Do Not Save
            } else {
                // console.log('else calculate item as existing line item');
                lineItemResult = await t.CALC_lineItem(t.existingLineItem, item, t.optionsSelected, t.quoteid); // existing line item
                // console.log('lineItemResult = ', JSON.stringify(lineItemResult, null, 2));
                // console.log(' lineItemResult = ' + JSON.stringify(lineItemResult, null, 2));
                t.lineItem = lineItemResult;
                this.$emit('updatelineitem', lineItemResult);
            }
        },
        async updateLineItemNew(item) {
            // console.log('this.existingLineItem.id = ', this.existingLineItem.id);
            item.customDescription = this.computedDescription;
            let lineItemResult = await this.CALC_lineItem(this.existingLineItem, item, this.optionsSelected, this.quoteid); // new line item
            this.lineItem = lineItemResult;
            this.$emit('updatelineitem', lineItemResult); // Do Not Save
            this.lineItem = {};
        },
    },
    // * CREATED
    async created() {
        let t = this;
        var productId;
        this.item = { ...this.datadown };
        //alert(this.item.id)
        this.getItems();
        let key = t.datadown.key;
        this.activeKey = t.datadown.key;
        // Get Product Id
        if (key === 'product') {
            // If product then we are in add product mode
            // first we need to get the id
            productId = t.datadown.id;
            t.existingLineItem = {}; // existing item does not exist
        } else if (key === 'lineItem') {
            // If lineItem then we are in edit lineItem mode
            t.existingLineItem = t.datadown;
            if (t.datadown.lineParentCodeId !== '' && t.datadown.lineParentCodeId !== undefined) {
                productId = t.datadown.lineParentCodeId;
            } else {
                productId = t.datadown.lineCodeId; // For Products Edit
            }
        } else {
            productId = '';
        }
        // Get Product
        let itemResult = await t.MIX_readItem(productId, 'product');
        if (itemResult.code === 1) {
            t.item = itemResult.data;
            t.orgId = itemResult.data.orgId;
            // Set Option Selections
            this.optionsSelected.optionValue1 = this.datadown.optionValue1;
            this.optionsSelected.optionValue2 = this.datadown.optionValue2;
            this.optionsSelected.optionValue3 = this.datadown.optionValue3;
            this.optionsSelected.optionValue4 = this.datadown.optionValue4;
            this.optionsSelected.optionValue5 = this.datadown.optionValue5;
            this.optionsSelected.optionValue6 = this.datadown.optionValue6;
            this.optionsSelected.optionValue7 = this.datadown.optionValue7;
            this.optionsSelected.optionValue8 = this.datadown.optionValue8;
            this.optionsSelected.optionValue9 = this.datadown.optionValue9;
            this.optionsSelected.optionValue10 = this.datadown.optionValue10;
        } else {
            t.item = {};
            t.orgId = '';
        }
        await this.readOptions();
        await this.readAttributes();
        this.getItems();
        this.closed = false;
    },
    async mounted() {
        // Check to see if lineItem or item is passed in
    },
};
</script>

<style scoped>
.truncate {
    display: inline-block;
    /* width: 200px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table__expanded__content {
    box-shadow: none !important;
}
</style>
