<template>
    <div class="grey lighten-3" style="overflow: hidden !important" >
        <!--style="height: calc(100vh - 150px) !important; overflow: scroll !important"-->
        <v-row class="" dense>
            <v-col cols="12" sm="12" class="">
                <div class="d-flex align-center primary pa-4">
                    <div class="text-left white--text text-h5 font-weight-light">Edit Categories</div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                            <v-icon>icons8-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" class="px-5">
                <v-row class="pb-3" dense>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-spacer />
                        <div>
                            <v-btn class="accent primary--text mt-2 font-weight-bold" depressed :disabled="addNew" @click="addNew = !addNew">New</v-btn>
                        </div>
                    </v-col>
                    <v-col :cols="computedCols.cols" :xs="computedCols.xs" :sm="computedCols.sm" :md="computedCols.md" :lg="computedCols.lg" :xl="computedCols.xl" class="ma-0 pa-0">
                        <v-data-table 
                            sortable 
                            dense 
                            class="mt-3" 
                            :headers="computedHeaders" 
                            :items="items" 
                            :server-items-length="itemsTotal" 
                            :options.sync="datatableOptions" 
                            :loading="loading" 
                            :loading-text="'Loading Categories'" 
                            :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 50, 100] }"
                            >
                            <template v-slot:top>
                                <div class="d-flex align-center" style="width: 100% !important">
                                    <div class="py-5 pl-3 body-2">
                                        Showing <strong>{{ itemsFrom + 1 }}</strong>
                                        <span v-if="itemsTo !== itemsTotal">
                                            to <strong>{{ itemsTo }}</strong></span
                                        >
                                        of <strong>{{ itemsTotal }}</strong>
                                    </div>
                                    <v-spacer />
                                    <v-spacer />
                                    <!-- <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                                        <v-btn value="destroy" small v-if="['Developer-Admin'].includes(userLevel)">
                                            <v-icon class="error--text">icons8-delete-trash</v-icon>
                                        </v-btn>
                                        <v-btn value="delete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
                                            <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                                        </v-btn>
                                        <v-btn value="undelete" small v-if="['Developer-Admin', 'Staff-Admin', 'Staff-Manager'].includes(userLevel)">
                                            <v-icon class="grey--text text--darken-3">icons8-trash-restore</v-icon>
                                        </v-btn>
                                    </v-btn-toggle> -->
                                    <!-- <div class="mb-5 mx-5"><v-switch v-model="showImages" color="primary" label="Images" hide-details="auto" inset></v-switch></div>
                                <div class="mb-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>-->
                                </div>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-icon class="primary--text mr-0" @click="showSubcats(item)">icons8-forward</v-icon>
                            </template>
                            <!-- <template v-slot:item.importStatus="{ item }">
                                <v-icon :class="item.importStatus + '--text'">icons8-filled-circle</v-icon>
                            </template> -->
                        </v-data-table>
                    </v-col>
                    <v-col cols="4" v-if="showSubcategories" class="pl-3 pb-2">
                        <v-card class="elevation-0 grey lighten-4 mt-2" height="100%" width="100%">
                            <v-card-text class="align-center">
                                <div class="d-flex align-start">
                                    <div>
                                        <div>
                                            <div class="body-1 font-weight-bold">{{ category.categoryTitle }}</div>
                                        </div>
                                        <div class="d-flex">
                                            <!-- <v-icon class="primary--text">icons8-sorting</v-icon> -->
                                            <div class="body-2">Subcategories</div>
                                        </div>
                                    </div>
                                    <v-spacer />
                                    <v-btn plain class="primary--text font-weight-bold pr-0" depressed @click="editCategory"><v-icon>icons8-edit</v-icon>Edit</v-btn>
                                </div>
                                <div class="pt-4">
                                    <ul><li v-for="(item, index) in subcategories" :key="index">{{ item.subcategoryTitle }}</li></ul>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="addNew" cols="12" xs="12" sm="12" class="px-5">
                <v-row class="d-flex align-center" dense>
                    <div class="mt-2 mr-2 priceblock">
                        <v-text-field dense label="Category Name" v-model="category.categoryTitle" outlined background-color="white" hide-details="auto" />
                    </div>
                    <div>
                        <v-btn class="accent primary--text mt-2 font-weight-bold" depressed @click="addSubcategory">Add Subcategory</v-btn>
                    </div>
                </v-row>
                <v-row v-for="(item, index) in subcategories" :key="index" class="d-flex align-center" dense>
                    <div class="mt-2 mr-2 priceblock">
                        <v-text-field dense label="Subcategory Name" v-model="item.subcategoryTitle" outlined background-color="white" hide-details="auto" />
                    </div>
                    <div>
                        <v-btn class="grey white--text mt-2 font-weight-bold" depressed @click="removeSubcategory(index)"><v-icon>icons8-multiply</v-icon></v-btn>
                    </div>
                </v-row>
                <v-row dense>
                    <!-- <div>
                        <pre>{{ category }}</pre>
                    </div>
                    <div>
                        <pre>{{ subcategory }}</pre>
                    </div>
                    <div>
                        <pre>{{ subcategories }}</pre>
                    </div> -->
                </v-row>
            </v-col>


        </v-row>
        <v-row class="px-3 pb-3 primary">
            <v-col cols="12" class="d-flex justify-right align-center">
                <v-spacer />
                <div>
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <div class="ml-2">
                    <v-btn :disabled="!addNew" class="accent primary--text font-weight-bold" depressed @click="saveItem"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import schema from "@/schema.js";
export default {
    // DATA
    data: () => ({
        index: 'idx:categories',
        key: 'category',
			
        itemsTotal: null,
		searchQuery: "",
		itemsFrom: 0,
		itemsTo: 9,
        sortBy: "@categoryTitle",
		datatableOptions: {
			page: 1,
			itemsPerPage: 10,
			sortBy: ["categoryTitle"],
			sortDesc: [false],
			groupBy: [],
			groupDesc: [],
			mustSort: false,
			multiSort: false,
		},
         headers: [
            { text: "Category Id", value: "id", sortable: true, hidden: false, expanded: false },
            { text: "Category Title", value: "categoryTitle", sortable: false, hidden: false, expanded: false },
            { text: "Action", value: "action", align: "end", sortable: false, hidden: false, expanded: false, width: "105px" },
        ],
        addNew: false,
        category: {},
        subcategory: {},
        subcategories: [],
        categorySchema: null,
        subcategorySchema: null,
        itemNew: true,
        showSubcategories: false
    }),
    // COMPUTED
    computed: {
        computedHeaders() {
            // let headers = this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            return headers;
        },
		computedSearchQuery() {
			let searchQuery = this.searchQuery;
			if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
				searchQuery = "@deleted:0";
				return searchQuery;
			} else {
				searchQuery = `${searchQuery}*  @deleted:0`;
				return searchQuery;
			}
		},
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedCols() {
            var itemsCols = {};

            switch (true) {
				// Expanded List with No Task Bar
				case this.showSubcategories:
					itemsCols = { cols: 8, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 };
					break;
                default:
                    itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };
                    break;
            }
            return itemsCols;
        }
    },
    // METHODS
    methods: {
        async showSubcats(item) {
            var t = this; 

            var id = item.id

            // read item 


			let result = await t.MIX_readItem(item.id, 'category');
			if (result.code === 1) {
				this.category = result.data;
            }

            let itemsResult = await this.MIX_redisAggregateSearch('idx:subcategories', `@deleted:0 @subcategoryCategoryId:${id}`, 0, 999, '@id,@subcategoryTitle,@subcategoryCategoryId,@deleted', '@subcategoryTitle', false );

			if (itemsResult.code === 1) {
				var items = itemsResult.data.results;
			}
            
            t.subcategories = items
            t.showSubcategories = true
        },
        removeSubcategory(index) {
            this.subcategories.splice(index, 1)
        },
        addSubcategory() {
            var t = this;

            var newSubcat = {...t.subcategory}
            t.subcategories.push(newSubcat)
        },
        closeItem() {
            this.$emit('closeitem');
        },
        cancelItem() {
            this.item = {};
            this.lineItem = {};
            this.$emit('closeitem');
        },
        async saveItem() {
            var t = this; 
            
            // save category 
            var category = t.category
            category.id = t.MIX_generateId();

            let result = await this.MIX_createItem(category, this.key, this.itemNew);

            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
            }

            // save subcategories with id of category

            var subcategories = t.subcategories

            for (let i=0; i < subcategories.length; i++) {
                var subcat = subcategories[i]

                subcat.id = t.MIX_generateId();
                subcat.subcategoryCategoryId = category.id

                await this.MIX_createItem(subcat, 'subcategory', this.itemNew);

            }
        },
		// * GET ITEMS
		async getItems() {
            console.log('index', this.index)
            console.log('computedSearchQuery', this.computedSearchQuery)
            console.log('itemsFrom', this.itemsFrom)
            console.log('datatableOptions.itemsPerPage', this.datatableOptions.itemsPerPage)
            console.log('fields', this.computedFields)
            console.log('sortBy', this.sortBy)
			let itemsResult = await this.MIX_redisAggregateSearch(
                this.index, 
                this.computedSearchQuery, 
                this.itemsFrom, 
                this.datatableOptions.itemsPerPage, 
                this.computedFields, 
                this.sortBy, 
                false
            );
            console.log('itemsResult', JSON.stringify(itemsResult, null, 2))

			if (itemsResult.code === 1) {
				this.itemsTotal = itemsResult.data.total;
				this.items = itemsResult.data.results;
				let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
				if (itemsTo < this.itemsTotal) {
					this.itemsTo = itemsTo;
				} else {
					this.itemsTo = this.itemsTotal;
				}
			}
		},
    },
    // CREATED
    async created() {
        let t = this;

        t.getItems()

        t.categorySchema = schema.category
        t.subcategorySchema = schema.subcategory
        t.category = {...t.categorySchema}
        t.subcategory = {...t.subcategorySchema}
    },
};
</script>

<style scoped>
.priceblock {
    flex: 1;
}
</style>
