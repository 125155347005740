<template>
	<v-row fill-height class="grey lighten-2" style="height: calc(100vh - 46px) !important" no-gutters>
		<v-col class="align-center pa-3" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
			<div class="d-flex align-center mb-1">
				<div class="text-left primary--text text-h5 font-weight-light">API Integrations</div>
				<v-spacer></v-spacer>
				<div class="mr-2" v-if="!listShrunk"><v-btn depressed class="accent secondary--text" @click="openItem({})">New</v-btn></div>
				<div>
					<v-btn-toggle dense multiple>
						<v-btn
							class="primary--text"
							:large="!GET_dense"
							@click="
								listExpanded = false;
								listShrunk = true;
							"
							depressed
							:dense="!GET_dense"
							v-if="!listShrunk"
						>
							<v-icon color="primary">icons8-double-left</v-icon>
						</v-btn>
						<v-btn class="primary--text" :large="!GET_dense" @click="listShrunk = false" depressed v-if="listShrunk">
							<v-icon color="primary">icons8-forward</v-icon>
						</v-btn>
						<v-btn class="primary--text" :large="!GET_dense" @click="listExpanded = false" depressed v-if="listExpanded && !listShrunk">
							<v-icon color="primary">icons8-back</v-icon>
						</v-btn>
						<v-btn class="primary--text" :large="!GET_dense" @click="listExpanded = true" depressed v-if="!listExpanded && !listShrunk">
							<v-icon color="primary">icons8-forward</v-icon>
						</v-btn>
						<v-btn class="primary--text" depressed :large="!GET_dense" v-if="!listShrunk">
							<v-icon color="primary">icons8-export-csv</v-icon>
						</v-btn>
						<v-btn class="primary--text" depressed :large="!GET_dense" @click="getProducts()">
							<v-icon color="primary">icons8-available-updates</v-icon>
						</v-btn>
						<v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important;" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed outlined class="primary--text" v-bind="attrs" v-on="on"><v-icon class="" color="primary">icons8-table-properties</v-icon></v-btn>
							</template>
							<v-card class="pa-5" width="250" >
								<div class="body-2 font-weight-bold">Show / hide Columns</div>
								<v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
							</v-card>
						</v-menu>
					</v-btn-toggle>
				</div>
			</div>
			<v-divider class="grey lighten-1 mb-4"></v-divider>
			<div class="" v-if="listShrunk && !listExpanded"><v-btn depressed class="accent secondary--text" block @click="openItem({})">New</v-btn></div>			
			<div class="flex-grow-1 my-3">
				<v-text-field label="Search Api Integrations" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="icons8-search" @click:clear="searchQuery = ''"></v-text-field>
			</div>
			<div class="d-flex align-center">
					<div>
						Showing <strong>{{ itemsFrom + 1 }}</strong>
						<span v-if="itemsTo !== itemsTotal"
							>to <strong>{{ itemsTo }}</strong></span
						>
						of <strong>{{ itemsTotal }}</strong>
					</div>
					<v-spacer />
					<div class="mb-5" v-if="!listShrunk"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>
			</div>
			<!-- TABLE BASED LIST -->
			<v-data-table v-if="!listShrunk" sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions">
				<template v-slot:item.createdDateTime="{ item }">
					{{ $moment(item).format("DD-MMM-YYYY") }}
				</template>
				<template v-slot:item.action="{ item }">
					<v-icon class="error--text pa-1" style="border: 1px red solid; border-radius: 3px;" @click="destroyItem(item.id)">icons8-delete-trash</v-icon>
					<v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted  === 'false'">icons8-trash-can</v-icon>
					<v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === 'true'">icons8-trash-restore</v-icon>
					<v-icon class="primary--text ml-5" @click="openItem(item)">icons8-forward</v-icon>
				</template>
			</v-data-table>
			<!-- CARD BASED LIST -->
				<div v-if="listShrunk" style="height: 67vh; overflow-y: scroll" class="">
					<v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
					<div class="d-flex align-center ml-1">
						<div class="ml-3">
							<div class="body-2 font-weight-bold" style="width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.serviceName }}</div>
							Code: <span class="body-2">{{ item.serviceName }}</span>
						</div>
						<v-spacer></v-spacer>
						<div class="text-right mr-3">
							<v-icon class="primary--text" @click="
								openItem(item)
								listExpanded = false;
							">icons8-forward</v-icon>
						</div>
					</div>
					</v-card>
				</div>
		</v-col>
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: lightgrey solid 2px !important" v-if="!listExpanded" class="grey lighten-3" cols="12" :xs="adminCols.xs" :sm="adminCols.sm" :md="adminCols.md" :lg="adminCols.lg" :xl="adminCols.xl">
				<Api v-if="!listExpanded" v-on:refreshItems="getItems" :activeitem="activeItem" v-on:closeItem="listExpanded = true" />
			</v-col>
		</transition>
		<transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: lightgrey solid 2px !important" class="" v-if="GET_taskBar" :cols="taskCols.cols" :xs="taskCols.xs" :sm="taskCols.sm" :md="taskCols.md" :lg="taskCols.lg" :xl="taskCols.xl">
				<Taskbar />
			</v-col>
		</transition>
	</v-row>
</template>

<script>
	import TaskBar from "@/views/tasks/taskbar.vue";
	import Api from "@/views/admin/Api.vue";

	import { mapGetters } from "vuex";
	export default {
        name: 'Admin',
		components: {
			Api: Api,
			Taskbar: TaskBar,
		},
		data: () => ({
			key: "api",
			index: "idx:api",
			toggleDeletedItems: true,
			showImages: false,
			items: [],
			itemsTotal: null,
			searchQuery: "",
			itemsFrom: 0,
			itemsTo: 9,
			activeItem: {},
			listExpanded: true,
			listShrunk: false,
			// Headers to display in the Data Table
			headers: [
				{ text: "Service Name", value: "serviceName", hidden: false },
                { text: "Service Description", value: "serviceDescription", hidden: false },
				{ text: "Created", value: "createdDateTime", hidden: false },				
				{ text: "Action", value: "action", align: "end", hidden: false, width: "155px" },
			],
			// Fields to Retrieve from Database
			fields: "@serviceName,@serviceDescription,@createdDateTime",
			sortBy: "@serviceName",
			datatableOptions: {
				page: 1,
				itemsPerPage: 10,
				sortBy: ["serviceName"],
				sortDesc: [false],
				groupBy: [],
				groupDesc: [],
				mustSort: false,
				multiSort: false,
			},
			itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
			adminCols: { cols: 12, xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
			taskCols: { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
		}),
		computed: {
			...mapGetters({
				GET_taskBar: "GET_taskBar",
				GET_dense: "GET_dense",
			}),
			// computedSearchQuery() {
			// 	let searchQuery = this.searchQuery;
			// 	if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
			// 		if (this.toggleDeletedItems) {
			// 			searchQuery = "@serviceName";
			// 		}
			// 		return searchQuery;
			// 	} else {
			// 		if (searchQuery.startsWith("@")) {
			// 			if (this.toggleDeletedItems) {
			// 				searchQuery = `${searchQuery}* @serviceName`;
			// 				return searchQuery;
			// 			} else {
			// 				searchQuery = `${searchQuery}*  @serviceName`;
			// 				return searchQuery;
			// 			}
			// 		} else {
			// 			if (this.toggleDeletedItems) {
			// 				searchQuery = `${searchQuery}* @serviceName`;
			// 				return searchQuery;
			// 			} else {
			// 				searchQuery = `${searchQuery}* @serviceName`;
			// 				return searchQuery;
			// 			}
			// 		}
			// 	}
			// },
			computedHeaders() {
				return this.headers.filter((header) => !header.hidden);
			},
			computedItems() {
				var items = this.items;
				return items;
			},
		},
		methods: {
			// * DESTROY ITEM FROM ITEMS
			async destroyItem(itemId) {
				let result = await this.MIX_destroyItem(itemId, this.key);
				if (result.code === 1) { this.getItems() }
			},
			// * DELETE ITEM FROM ITEMS
			async deleteItem(itemId) {
				let result = await this.MIX_deleteItem(itemId, this.key);
				if (result.code === 1) { this.getItems() }
			},			
			// * RESTORE ITEM FROM ITEMS
			async restoreItem(itemId) {
				let result = await this.MIX_restoreItem(itemId, this.key);
				if (result.code === 1) { this.getItems() }
			},			
			openItem(item) {
				this.activeItem = item;
				this.listExpanded = false;						
			},
			async getItems() {
				let itemsResult = await this.MIX_redisAggregateSearch(this.index, '*', this.itemsFrom, this.datatableOptions.itemsPerPage, this.fields, this.sortBy, false);
				if (itemsResult.code === 1) {
					this.itemsTotal = itemsResult.data.total;
					this.items = itemsResult.data.results;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
				} else {
					this.MIX_alertBox({ color: "error", message: "Error retreiving items", timeout: 4000, show: true });
				}
			},
			refreshCols() {
				switch (true) {
					// Expanded List with No Task Bar
					case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
						this.adminCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// Expanded List with Task Bar
					case this.listExpanded && !this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
						this.adminCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					// List with Product Page (No Task Bar)
					case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.adminCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// List with Product Page and Task Bar
					case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
						this.adminCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					// Shrunk List with Product Page (No Task Bar)
					case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						this.adminCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// Shrunk List with Product Page and Task Bar)
					case !this.listExpanded && this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						this.adminCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;

					default:
						this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
						this.adminCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
				}
			},
		},
		watch: {
			computedSearchQuery() {
				this.getItems();
			},
			toggleDeletedItems() {
				this.getItems();
			},
			datatableOptions: {
				handler() {
					this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
					this.getItems();
				},
				deep: true,
			},
			listExpanded() {
				this.refreshCols();
			},
			listShrunk() {
				this.refreshCols();
			},
			GET_taskBar() {
				this.refreshCols();
			},
		},
		async created() {
			this.getItems();
		},
		mounted() {
			this.refreshCols();
			this.getItems();
		},
	};
</script>
