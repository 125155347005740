<template>
   <!-- <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="5" xl="5" class="">
            <v-row class="pt-4 px-5 justify-center">
                <v-col cols="12" xs="12" md="6"> 
                    <div class="text-h6 font-weight-normal primary--text">Reset Password</div>
                    <p class="mt-3 mb-0 text-left body2">Please enter your email address to request a password reset.</p>
                    <v-divider></v-divider>
                </v-col> 
            </v-row>
            <v-row class="d-flex justify-center mt-1">
                <v-col cols="12" xs="12" sm="6"> 
                    <v-form>
                        <v-text-field ref="email" dense filled :background-color="inputBackgroundColor" v-model.trim="email" label="Email" append-icon="icons8-envelope" hide-details="auto" data-vv-name="email" required :rules="rules.email"></v-text-field>

                        <v-row class="my-4 text-center">
                            <div v-if="showDone" class="pl-3 body-2 error--text">A link to reset your password has been sent to your email address. Check your inbox.</div>
                            <v-col cols="12" class="justify-center">
                                <v-btn block depressed @click.native="resetPassword()" large class="my-2 mb-4 primary">Reset Password</v-btn>
                                <router-link :to="{ path: 'login' }" replace class="btn btn-link" >Return to Login</router-link>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>         
        </v-col>     
    </v-row>     -->
    <v-container fluid fill-height class="d-flex pa-0 ma-0 primary">
        <v-col cols="3" class="primary d-flex align-center">
            <v-img contain class="mb-8" max-height="150" :src="require('@/assets/logo.png')"></v-img>
        </v-col>
        <v-col cols="9" class="white d-flex align-self-stretch justify-center">
            <v-card width="550" class="pa-8 rounded-sm elevation-0 align-self-center">
                <v-card-title class="d-flex justify-start">
                    <span class="headline py-4">Reset Password</span>
                </v-card-title>
                <v-card-subtitle class="d-flex justify-start pb-10 black--text">Please enter your email address to request a password reset</v-card-subtitle>
                <v-card-text class="pt-3">
                    <v-form ref="form" lazy-validation>
                        <v-text-field outlined class="primary--text" v-model="form.userEmail" label="Email" type="email" append-icon="oomph-secured-letter" :rules="emailRules" required></v-text-field>
                    </v-form>
                    <!-- <Alert border="left" :message="alertMessage" :value="value" :type="type" /> -->
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn
                        block
                        x-large
                        class="primary white--text mb-8 elevation-0"
                        @click="reset()"
                        :disabled="form.userEmail === '' || loading"
                        :loading="loading"
                        >Reset</v-btn
                    >
                </v-card-actions>
                <div class="body-1 float-right pr-4">Already have an account? <span class="font-weight-bold quaternary--text" style="cursor: pointer" @click="$router.push('/')">Login</span></div>
            </v-card>
        </v-col>
        <!-- <v-col cols="12" v-if="!$vuetify.breakpoint.mobile" class="py-0 white d-flex align-self-stretch justify-end">
            <v-row >
                <v-col cols="3" class="primary"></v-col>
                <v-col cols="9" class="white d-flex justify-end py-4">
                    <span class="d-flex align-self-end">Powered by</span>
                    <v-img contain class="ml-2 mb-1 d-flex align-self-end" max-height="50" max-width="100" :src="require('@/assets/vindico.png')"></v-img>
                </v-col>
            </v-row>
        </v-col> -->
    </v-container>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
export default {
    name: "ResetPassword",
    // * DATA
	data: () => ({
		inputBackgroundColor: "white",
		email: "",
		rules: [],
        showDone: false,
        form: {
            email: "",
        },
        loading: false,
        emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
	}),
	methods: {
        reset() {
            if (this.$refs.form.validate()) {
                var t = this;
                t.loading = true;
                // t.form.orgId = 'OOMPHWELLNESS0000001',
                // t.form.orgName = 'Oomph Wellness';
                // t.form.userGroup = 'Guest';
                const auth = getAuth();

				sendPasswordResetEmail(auth, t.form.userEmail)
					.then(function() {
						// Email sent.
						t.MIX_alertBox({ color: "success", timeout: 2000, message: "A link to reset your password has been sent to your email address. Check your inbox.", show: true });
                            // t.value = true;
                            // t.type = 'success';
                            // t.alertMessage = 'A link to reset your password has been sent to your email address. Check your inbox.';
                         t.loading = false;
                            t.$router.push('/');
						// console.log('email sent');
					})
					.catch(function() {
						// console.log('err: ' + error);
						t.MIX_alertBox({ color: "error", timeout: 2000, message: "There was an error", show: true });
					});
            }
        },
		resetPassword: function() {
			let t = this;
			
            t.email = t.email.toLowerCase();

            t.rules = {
                email: [
                    (v) => !!v || 'Email is Required',
                    (v) => /.+@.\..+/.test(v) || 'Email must be valid'
                ]
            }
            
            t.$nextTick(() => {
                if (t.$refs.email.validate()) {
                    t.$firebase.auth
                        .sendPasswordResetEmail(t.email)
                        .then(function() {
                            t.showDone = true
                            t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'A link to reset your password has been sent to your email address. Check your inbox', show: true })
                        })
                        .catch(function(error) {
                            console.log("err: " + error)
                            t.MIX_alertBox({ color: 'error', timeout: 2000, message: error, show: true })
                        });
                } else {
                    t.MIX_alertBox({ color: 'warning', timeout: 2000, message: 'Fill out your email address correctly in order to proceed.', show: true })
                }
            })
		},
	},
};
</script>