<template>
    <div class="d-flex flex-column justify-start grey lighten-5" style="padding: 0px !important; margin: 0px !important; overflow: scroll !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 168px) !important;' : 'height: calc(100vh - 120px) !important;'">
        <!-- Menu Buttons -->
        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <!-- Loader -->
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <div class="ml-3">
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="editmode = false"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="error white--text"  v-if="!editmode" @click="destroyProductImage">Delete Image</v-btn>
                </div>
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="saveProduct()"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- Images -->
        <v-row class="pa-3" no-gutters>
            <v-col cols="12" sm="12" class="white pa-3">
                <div class="text-left primary--text text-h6 font-weight-light">Images</div>
                <div class="mt-4" v-if="product.productImageId !== ''">
                    <div><v-img class="my-2" width="250" height="250" contain :src="`${mainImage}`"/></div>
                    <!-- <div v-if="product.productImageId === ''"><v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon></div> -->
                </div>
                <ImageUpload accept="image/jpeg, image/jpg, image/png" label="Select file" buttonClass="primary" progressClass="py-4 pa-2" folderLocation="products/" :fileName="product.id" :compressionMaxSizeMB="1" :compressionmaxWidthOrHeight="500" :selectedItem="product" :private="false" :createThumbnail="true" v-on:update="updateProductImage()" />
                <!-- <h1 v-if="product.productImageId === ''">{{ product.productImageId }}</h1> -->
                <!-- <v-row justify="space-around">
				<v-icon @click="model--"> mdi-minus </v-icon>
				{{ model }}
				<v-icon @click="model++"> mdi-plus </v-icon>
			</v-row>
			<v-carousel v-model="model">
				<v-carousel-item v-for="(color, i) in colors" :key="color">
					<v-sheet :color="color" height="100%" tile>
						<v-row class="fill-height" align="center" justify="center">
							<div class="text-h2">Slide {{ i + 1 }}</div>
						</v-row>
					</v-sheet>
				</v-carousel-item>
			</v-carousel> -->
                <!-- <h5>productImageId: {{ product.productImageId }}</h5>
                <pre class="caption">{{ product }}</pre> -->
            </v-col>
        </v-row>
    </div>
</template>

<script>
/* eslint-disable */
import { getApp } from "firebase/app";
import { getStorage, ref, deleteObject } from 'firebase/storage';
export default {
    name: 'productImages',
    // * PROPS
    props: {
        productdata: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    // * DATA
    data: () => ({
        loading: true, // holds the component loading status
        editmode: false,
        product: {}, // stores the product data
		imageKey: 0,
		mainImage: '',
    }),
    // * METHODS
    methods: {
        // Initialise the component with data rqeuired
        initialise() {
            console.log('productImages initialise');
            this.loading = true;
            this.product = { ...this.productdata }; // set the product data
			this.mainImage = 'https://storage.googleapis.com/mof-swapp-public/products/' + this.product.productImageId + '.jpeg?x=' + Date.now()
			this.loading = false;
        },
        // Update the Product Object with the image details
        updateProductImage() {
            console.log('updateProductImage ' + this.product.id);
            this.product.productImageId = this.product.id;
			this.mainImage = 'https://storage.googleapis.com/mof-swapp-public/products/' + this.product.productImageId + '.jpeg?x=' + Date.now()
            // this.updateProduct();
        },
        // Update the Product in the PArent Component
        saveProduct() {
            this.$emit('saveitem', this.product); // update the product
        },
        // delete product image
        async destroyProductImage() {
            console.log('destroyImage ' + this.product.productImageId);
			const firebaseApp = getApp();
			const storage = getStorage(firebaseApp, "gs://mof-swapp-public");
			
            let image = `products/${this.product.productImageId}.jpeg`;
			const imageRef = ref(storage, image);
			await deleteObject(imageRef);
			
			let thumbnailImage = `products/thumbnails/${this.product.productImageId}.jpeg`;
			const thumbnailRef = ref(storage, thumbnailImage);
			await deleteObject(thumbnailRef);
            
			this.product.productImageId = '';
			this.saveProduct();
            //this.updateProduct();
        },
    },
    // * WATCH
    watch: {
        // Product Data
        productdata: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
    },
    created() {
        this.initialise();
    },
    // * MOUNTED
    mounted() {},
};
</script>
