<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow: hidden !important">
        <v-row class="apptext--text" dense>
            <v-col cols="12" class="d-flex align-center justify-center grey darken-1 py-4">
            <!-- <pre>{{ toggleFeatures }}</pre> -->
                <v-btn-toggle v-model="toggleFeatures" multiple :dense="dense" class="mx-3">
                    <v-btn value="move" title="Show Move Grabber" :disabled="['Awaiting Approval', 'Ready', 'Rejected', 'Won', 'Lost'].includes(quote.quoteStatus)">
                        <v-icon class="grey--text text--darken-3">icons8-move-grabber</v-icon>
                    </v-btn>
                    <v-btn value="images" title="Show Images">
                        <v-icon class="grey--text text--darken-3">icons8-picture</v-icon>
                    </v-btn>
                    <v-btn value="base" title="Show Base Price">
                        <v-icon class="grey--text text--darken-3">icons8-price-tag-pound</v-icon>
                    </v-btn>
                    <v-btn value="margin" title="Show Margin">
                        <v-icon class="grey--text text--darken-3">icons8-profit</v-icon>
                    </v-btn>
                    <v-btn value="discount" title="Show Discount">
                        <v-icon class="grey--text text--darken-3">icons8-low-price-pound</v-icon>
                    </v-btn>
                    <v-btn value="delete" title="Toggle Delete" :disabled="['Awaiting Approval', 'Ready', 'Rejected', 'Won', 'Lost'].includes(quote.quoteStatus)">
                        <v-icon class="grey--text text--darken-3">icons8-trash-can</v-icon>
                    </v-btn>
                    <v-btn value="enableNPS" @click="enableNPS()" title="NPS Pricing" v-bind:disabled="!editmode">
                        <img :style="toggleFeatures.includes('enableNPS') ? 'filter: grayscale(100%) !important ' : ''" width="22" contain src="@/assets/npslogo.png" />
                    </v-btn>
                </v-btn-toggle>
                <!-- //! TODO - add icons  -->
                <v-btn v-if="quote.quoteStatus === 'Awaiting Approval'" class="white--text" @click="sortLineItems" text>
                    Sort
                </v-btn>
                <v-spacer />
                <div class="ml-3" v-if="['Draft', 'Rejected'].includes(quote.quoteStatus)">
                    <v-btn :disabled="editmode" text class="grey lighten-5 success--text" @click="openSubmitApprovalDialog()"><v-icon class="mr-2 success--text">icons8-ok</v-icon>Submit for Approval</v-btn>
                </div>

                <div class="ml-3" v-if="['Awaiting Approval'].includes(quote.quoteStatus) && approveUsers.includes(GET_FIREBASE_userAccessToken.user_id) ">
                    <v-btn text class="grey lighten-5 warning--text" @click="openApprovalDialog()"><v-icon class="mr-2">icons8-verified-badge</v-icon>Approval</v-btn>
                </div>
                
                <div class="ml-3" v-if="quote.quoteStatus === 'Ready'">
                    <v-btn text class="grey lighten-5 secondary--text" @click="openApprovalNoteDialog('Sent')"><v-icon class="mr-2">icons8-mail</v-icon>Sent</v-btn>
                </div>
                <div class="ml-3" v-if="quote.quoteStatus === 'Sent'">
                    <v-btn text class="success white--text" @click="openApprovalNoteDialog('Won')"><v-icon class="mr-2">icons8-ok</v-icon>Won</v-btn>
                </div>
                <div class="ml-3" v-if="quote.quoteStatus === 'Sent'">
                    <v-btn text class="error white--text" @click="openApprovalNoteDialog('Lost')"><v-icon class="mr-2">icons8-cancel</v-icon>Lost</v-btn>
                </div>



                <!-- EDIT -->
                <div class="ml-3">
                    <v-btn 
                        :disabled="['Awaiting Approval', 'Won'].includes(quote.quoteStatus) && !['Developer-Admin', 'Staff-Directors', 'Staff-Admin'].includes(userLevel)" 
                        text 
                        class="grey lighten-5 secondary--text" 
                        v-if="!editmode" 
                        @click="editLineItems">
                        <v-icon class="mr-2">icons8-edit</v-icon>
                        Edit
                    </v-btn>
                    <v-btn 
                        :disabled="['Awaiting Approval', 'Won'].includes(quote.quoteStatus) && !['Developer-Admin', 'Staff-Directors', 'Staff-Admin'].includes(userLevel)" 
                        text 
                        class="accent secondary--text" 
                        v-if="editmode" 
                        @click="saveLineItems">
                        <v-icon class="mr-2">icons8-save</v-icon>
                        Save
                    </v-btn>
                </div>

                <!-- TODO - RG - Quote - Import and Export Line Items -->
                <div class="mx-2 mr-3">
                    <v-menu offset-y :ref="item.id" :value="false" class="white" rounded style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="grey lighten-5 error--text" v-bind="attrs" v-on="on"><v-icon color="primary">icons8-menu-vertical</v-icon></v-btn>
                        </template>
                        <div class="white mt-1" style="width: 250px; max-height: 300px">
                            <div class="pa-5 white" style="width: 100% !important">
                                <div class="" v-if="['Draft', 'Rejected'].includes(quote.quoteStatus)">
                                    <v-btn :disabled="!editmode || quote.quoteInstall === '1'" text class=" font-weight-bold lighten-5 secondary--text align-center" @click="addInstall">Add Install</v-btn>
                                </div>
                                <div class="text--secondary font-weight-bold">
                                    <v-btn @click="addMCDDialog" :disabled="!editmode" text class=" font-weight-bold lighten-5 secondary--text align-center">Add / Edit MCD Discount</v-btn>
                                </div>
                                <div v-if="quote.quoteStatus === 'Awaiting Approval'" class="text--secondary font-weight-bold">
                                    <v-btn @click="draft" text class=" font-weight-bold lighten-5 secondary--text align-center">Revert to Draft</v-btn>
                                </div>

                              <!--    EXPORT CSV-->
                              <div v-if="!listshrunk && $vuetify.breakpoint.mdAndUp" class="ml-2">
                                <v-btn @click="exportBox = true" text class=" font-weight-bold lighten-5 secondary--text align-center">Export to CSV</v-btn>
                              </div>
                              <ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
                                <template v-slot:header>
                                  <div class="full-width d-flex align-center">
                                    <div>Export CSV</div>
                                    <v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
                                    <v-spacer />
                                    <v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-cancel</v-icon></v-btn>
                                  </div>
                                </template>
                                <p>Please select the data you wish to export:</p>
                                <table width="100%" cellspacing="5">
                                  <tr>
                                    <td width="100"><strong>Current page:</strong></td>
                                    <td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
                                    <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
                                  </tr>
                                  <tr>
                                    <td><strong>Current total:</strong></td>
                                    <td>{{ itemsTotal }} items (filtered)</td>
                                    <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
                                  </tr>
                                  <tr>
                                    <td><strong>All Items:</strong></td>
                                    <td>{{ totalCount }} items (without filter)</td>
                                    <td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
                                  </tr>
                                  <tr>
                                    <td valign="top"><strong>Specific:</strong></td>
                                    <td>
                                      <div class="d-flex align-center">
                                        <div>From</div>
                                        <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
                                        <div>To</div>
                                        <div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
                                      </div>
                                      <div class="ml-n7">
                                        <CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
                                      </div>
                                    </td>
                                    <td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
                                  </tr>
                                </table>
                              </ExportBox>
                              <!--      EXPORT END-->
                            </div>
                        </div>
                    </v-menu>
                </div>
            </v-col>
        </v-row>



        <v-row class="px-3 primary" no-gutters>
            <v-col cols="4" class="pl-3 white--text font-weight-bold d-flex align-center"> Product Name </v-col>
            <v-col cols="8" class="white--text font-weight-bold d-flex align-center justify-stretch">
                <div class="priceblock text-center ml-2 py-3">Qty</div>
                <div class="priceblock text-center ml-2 py-3">Unit</div>
                <div class="priceblock text-center ml-2 py-3" v-if="toggleFeatures.includes('margin')">Margin</div>
                <div class="priceblock text-center ml-2 py-3" v-if="toggleFeatures.includes('discount')">Discount</div>
                <div class="priceblock text-center ml-2 py-3">Sub Total</div>
                <div class="priceblock text-center ml-2 py-3">Line Total</div>
                <div class="priceblock text-center ml-2 py-3">Action</div>
            </v-col>
        </v-row>
        <!-- <pre>Quote Ready for Approval: {{ quoteReadyForApproval }}</pre> -->
        <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut animate__faster">
            <v-row dense no-gutters class="warning py-2" v-if="Number(quoteTotal.totalSell) > 25000 && quote.approved === '0' || quoteReadyForApproval == false">
                <v-col cols="12" class="text-left ml-6 white--text font-weight-bold">This Quote requires Approval</v-col>
            </v-row>
        </transition>
        <div style="position: relative; overflow: auto !important; height: calc( 100% - 220px )" class="pb-5">
            <drop-list handle="draghandle" :items="items" class="mt-1" @insert="onInsert" @reorder="reOrder" :disabled="!editmode">
                <template v-slot:item="{ item, index }">
                    <drag class="item pa-2" :key="index" :disabled="!editmode" :data="item">
                        
                        <v-row class="mt-1 py-2 mx-1 rounded grey lighten-2" no-gutters v-if="item.lineType === 'Product'">
<!--                             <pre>{{ item }}</pre>-->
<!--                              <pre>{{ index }}</pre>-->
                            <div v-if="toggleFeatures.includes('move')" class="ml-2">
                                <v-icon color="grey">icons8-move-grabber</v-icon>
                            </div>
                            <v-col cols="auto" class="dnd-no-drag pl-2" v-if="toggleFeatures.includes('images')">
                                <div class="d-flex align-center justify-center white rounded" style="border: 1px solid lightgrey !important; width: 90px; height: 90px">
                                    <div v-if="item.lineImageId !== ''">
                                        <v-hover v-if="item.lineEdit === '1'">
                                            <template v-slot:default="{ hover }">
                                                <v-card flat>
                                                    <v-img class="my-2" width="80" height="80" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/thumbnails/' + item.lineImageId + '.jpeg'" />
                                                    <v-fade-transition>
                                                        <v-overlay v-if="hover" absolute >
                                                            <v-btn width="80" height="80" color="transparent" @click="editLineImage(index)" >
                                                                <v-icon color="white">icons8-edit</v-icon>
                                                            </v-btn>
                                                        </v-overlay>
                                                    </v-fade-transition>
                                                </v-card>
                                            </template>
                                        </v-hover>
                                        <v-img v-else class="my-2" width="80" height="80" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/thumbnails/' + item.lineImageId + '.jpeg'" />
                                    </div>

                                    <div v-if="item.lineImageId === ''">
                                        <v-icon x-large class="grey--text text--lighten-1">icons8-no-image</v-icon>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="" class="dnd-no-drag">
                                <!-- <img :src="item.lineImageId" contain width="85" height="85" v-if="toggleFeatures.include('images')" /> -->
                                <!-- <div class="error d-flex justfiy-center align-center" style="border-radius: 3px !important;">
										<v-icon class="white--text px-1">icons8-box-important</v-icon>
										</div> -->
                                <v-textarea :readonly="item.lineEdit === '0'" small dense class="ml-2 dnd-no-drag" label="Line Description" v-model="item.lineDescription" outlined :background-color="item.lineEdit === '0' ? 'grey lighten-3' : 'white'" rows="3" hide-details="auto" />
                                <!-- <v-select class="ml-2" label="Reason for Flag" dense outlined background-color="white" hide-details="auto"></v-select> -->
                                <!-- <v-text-field dense outlined class="ml-2" placeholder="Notes" background-color="white" rows="3" hide-details="auto" /> -->
                                <!-- <div class="d-flex align-center mt-1 px-2">
                                   -->
<!--                                <div class="body-2 ml-2">-->
<!--                                        ORDER:-->
<!--                                        <span class="font-weight-bold">{{ item.lineOrder }}</span>-->
<!--                                    </div>-->
                                <!-- <div class="body-2 ml-2">
                                        IMP:
                                        <span class="font-weight-bold">{{ item.lineIMP }}</span>
                                    </div> -->

                                <!-- <div class="body-2 ml-2">
                                        item.lineExtend {{ item.lineExtend }}
                                    </div> -->
                                <!-- <div class="mb-2 body-2 ml-2">
										Supplier ID: <span class="font-weight-bold">{{ item.orgId }}</span>
									</div> -->
                                <!-- </div> -->
                                <!-- <div class="body-2 ml-2">
                                        ID:
                                        <span class="font-weight-bold">{{ item.id }}</span>
                                    </div> -->
                                <!-- {{ item.id }} -->
                            </v-col>
                            <v-col cols="8" class="">
                                <div class="d-flex align-start">
                                    <div class="ml-2 dnd-no-drag">
                                        <div class="priceblock dnd-no-drag">
                                            <v-text-field :readonly="item.lineEdit === '0'" @change="calculateLineItemNew(item, index, 'lineQty')" small dense label="Qty" v-model="item.lineQty" outlined :background-color="item.lineEdit === '0' ? 'grey lighten-3' : 'white'" hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">MOQ: {{ item.lineMOQ }}</span>
                                        </div>
                                        <div class="mt-2 priceblock dnd-no-drag" v-if="item.lineRRP !== 'NaN'">
                                            <v-text-field :readonly="item.lineEdit === '0'" @change="calculateLineItemNew(item, index, 'lineRRP')" prefix="£" dense label="RRP" v-model="item.lineRRP" outlined :background-color="item.lineEdit === '0' ? 'grey lighten-3' : 'white'" hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">Base:</span><span v-if="toggleFeatures.includes('base')" class="ml-1 caption grey--text text--darken-3">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineRRPBase) }}</span>
                                        </div>
                                    </div>
                                    <div class="ml-2 dnd-no-drag">
                                        <div class="priceblock dnd-no-drag">
                                            <v-text-field :readonly="item.lineEdit === '0' || item.lineMCDRate === '1'" @change="calculateLineItemNew(item, index, 'lineSell')" prefix="£" dense label="Sell Price" v-model="item.lineSell" outlined :background-color="item.lineEdit === '0' ? 'grey lighten-3' : 'white'" hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">Base:</span><span v-if="toggleFeatures.includes('base')" class="ml-1 caption grey--text text--darken-3">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineSellBase) }}</span>
                                        </div>
                                        <div class="mt-2 priceblock dnd-no-drag">
                                            <v-text-field :readonly="item.lineEdit === '0'" @change="calculateLineItemNew(item, index, 'lineCost')" prefix="£" dense label="Cost Price" v-model="item.lineCost" outlined :background-color="item.lineEdit === '0' ? 'grey lighten-3' : 'white'" hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">Base:</span><span v-if="toggleFeatures.includes('base')" class="ml-1 caption grey--text text--darken-3">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineCostBase) }}</span>
                                        </div>
                                    </div>
                                    <div class="ml-2 dnd-no-drag" v-if="toggleFeatures.includes('margin')">
                                        <div class="priceblock dnd-no-drag">
                                            <v-text-field :readonly="item.lineEdit === '0'" @change="calculateLineItemNew(item, index, 'lineMarginPercent')" suffix="%" dense label="Margin" v-model="item.lineMarginPercent" outlined :background-color="Number(item.lineMarginPercent) < Number(item.lineMarginPercentBase) ? 'red lighten-4' : 'green lighten-4'" hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">Base:</span><span v-if="toggleFeatures.includes('base')" class="ml-1 caption grey--text text--darken-3">{{ item.lineMarginPercentBase }}%</span>
                                        </div>
                                        <div class="mt-2 priceblock dnd-no-drag">
                                            <v-text-field prefix="£" dense label="Margin Value" class="noselect" readonly v-model="item.lineMarginValue" outlined :background-color="Number(item.lineMarginPercent) < Number(item.lineMarginPercentBase) ? 'red lighten-4' : 'green lighten-4'" hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">Base:</span><span v-if="toggleFeatures.includes('base')" class="ml-1 caption grey--text text--darken-3">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.lineMarginValue) }}</span>
                                        </div>
                                    </div>
                                    <div class="ml-2 dnd-no-drag" v-if="toggleFeatures.includes('discount')">
                                        <div class="priceblock dnd-no-drag">
                                            <v-text-field :readonly="item.lineEdit === '0'" @change="calculateLineItemNew(item, index, 'lineDiscount')" suffix="%" dense label="Discount" v-model="item.lineDiscount" outlined :background-color="item.lineEdit === '0' ? 'grey lighten-3' : 'white'" hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">&nbsp;</span>
                                        </div>
                                        <div class="mt-2 priceblock dnd-no-drag">
                                            <v-text-field readonly @change="changeLineMCDRate(index)" suffix="%" dense label="MCD Rate" v-if="mcdToggle" outlined v-model="item.lineMCDDiscount" :background-color="item.lineEdit === '0' ? 'grey lighten-3' : 'white'" hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">&nbsp;</span>
                                        </div>
                                    </div>
                                    <div class="ml-2 dnd-no-drag">
                                        <div class="priceblock dnd-no-drag">
                                            <v-text-field prefix="£" dense label="Total Sell" v-model="item.lineTotalSell" outlined background-color="purple lighten-5" readonly hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">&nbsp;</span>
                                        </div>
                                        <div class="mt-2 priceblock dnd-no-drag">
                                            <v-text-field prefix="£" dense label="Total Cost" v-model="item.lineTotalCost" outlined background-color="purple lighten-5" readonly hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">&nbsp;</span>
                                        </div>
                                    </div>
                                    <div class="ml-2 dnd-no-drag">
                                        <div class="priceblock dnd-no-drag">
                                            <v-text-field :readonly="item.lineEdit === '0'" prefix="£" dense label="" v-model="item.lineTotal" outlined background-color="secondary" dark hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">&nbsp;</span>
                                        </div>
                                        <div class="mt-2 priceblock dnd-no-drag">
                                            <v-text-field prefix="£" dense label="Line Margin Sell" readonly v-model="item.lineTotalMarginValue" outlined :background-color="Number(item.lineMarginPercent) < Number(item.lineMarginPercentBase) || Number(item.lineTotalMarginValue) <= 0 ? 'red lighten-4' : 'green lighten-4'" hide-details="auto" />
                                            <span class="caption" v-if="toggleFeatures.includes('base')">&nbsp;</span>
                                        </div>
                                    </div>
                                    <div class="ml-2">
                                        <div class="dnd-no-drag">
                                            <v-btn v-if="item.lineEdit === '0'" @click="editLine(index)" :disabled="!editmode" depressed outlined class="primary--text mr-2"><v-icon color="primary">icons8-edit</v-icon></v-btn>
                                            <v-btn v-if="item.lineEdit === '1'" @click="saveLine(index)" :disabled="!editmode" depressed outlined class="primary--text mr-2"><v-icon color="primary">icons8-save</v-icon></v-btn>
                                        </div>
                                        <span class="caption" v-if="toggleFeatures.includes('base')">&nbsp;</span>
                                        <div class="mt-3 dnd-no-drag">
                                            <div v-if="toggleFeatures.includes('delete')">
                                                <v-btn @click="destroyItem(item.id)" depressed class="error primary--text mr-2"><v-icon color="white">icons8-trash-can</v-icon></v-btn>
                                            </div>
                                            <div v-if="!toggleFeatures.includes('delete')">
                                                <v-btn v-if="item.lineExtend === '0'" @click="extendLine(index, 'Open')" :disabled="item.lineEdit === '0'" depressed outlined class="primary--text mr-2"><v-icon color="primary">icons8-expand-arrow</v-icon></v-btn>
                                                <v-btn v-if="item.lineExtend === '1'" @click="extendLine(index, 'Close')" :disabled="item.lineEdit === '0'" depressed outlined class="primary--text mr-2"><v-icon color="primary">icons8-collapse-arrow</v-icon></v-btn>
                                            </div>

                                            <!-- <div><v-checkbox v-model="item.lineOptional" :true-value="1" :false-value="0" class="" label="Optional" dense></v-checkbox></div> -->
                                            <!-- <div><v-checkbox v-model="item.lineIncludeTotal" :true-value="1" :false-value="0" class="ml-2" label="Include in Quote Total" dense></v-checkbox></div> -->
                                        </div>
                                        <!-- <div class="mt-3 dnd-no-drag">
										<v-btn depressed outlined class="primary--text mr-2" @click="lineItems.splice(index, 1)"><v-icon color="primary">icons8-trash-can</v-icon></v-btn>
									</div> -->
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" dense class="d-flex ml-3 mt-2 pr-5">
                                <div class="body-2">
                                    Code:
                                    <span class="font-weight-bold">{{ item.lineCode }}</span>
                                </div>
                                <div class="body-2 ml-2">
                                    Supplier:
                                    <span class="font-weight-bold pr-1">{{ item.orgName }}</span>
                                    
                                    <!-- <span>{{ $moment().add(2, 'week').format('X') }}</span> -->
                                    <v-icon class="error--text mr-1" v-if="getPriceFileChange(item.orgId) !== 'noChange'">icons8-price-tag</v-icon>
                                    <span class="error--text font-weight-bold" v-if="getPriceFileChange(item.orgId) !== 'noChange'">Price File Change Due: {{ getPriceFileChange(item.orgId) }}</span>
                                </div>
                                <v-spacer />
                                <div>
									<v-btn @click="duplicateLine(item)" class="elevation-0 mr-1" depressed v-bind:disabled="item.lineEdit === '1'">Duplicate Line</v-btn>
                                    <v-btn @click="showMarketPrice(item)" class="elevation-0">Market Price</v-btn>
                                </div>
                            </v-col>
                          <v-col cols="12" dense class="d-flex ml-3" style="margin-top: -1em;">
                            <div class="body-2">
                              Floor:
                              <span class="font-weight-bold" v-if="item.lineFloorTag">{{ item.lineFloorTag }}</span>
                              <span class="font-weight-bold" v-if="!item.lineFloorTag">N/A</span>
                            </div>
                            <div class="body-2 ml-2">
                              Room:
                              <span class="font-weight-bold pr-1" v-if="item.lineRoomTag">{{ item.lineRoomTag }}</span>
                              <span class="font-weight-bold" v-if="!item.lineRoomTag">N/A</span>
                            </div>
                          </v-col>
                            <!-- <pre>Sell: {{ item.lineSell }}</pre>
                            <pre> Sell Base: {{ item.lineSellBase }}</pre> -->
                        </v-row>

                        <!-- <pre>LineAuthRequired: {{ item.lineAuthRequired }}</pre> -->
                        <v-row dense class="mx-1 grey lighten-2 px-1 py-2" style="margin-top: 0px !important" v-if="item.lineType === 'Product' && item.lineExtend === '1'">
                            <v-col class="dnd-no-drag">
                                <v-text-field label="Line Reference" v-model="item.lineReference" outlined dense hide-details="auto" background-color="white" color="primary"> </v-text-field>
                            </v-col>
                            <v-col class="dnd-no-drag">
                                <v-combobox
                                    class=""
                                    hide-selected
                                    label="Floor"
                                    v-model="item.lineFloorTag"
                                    :search-input.sync="floorTagAutocomplete"
                                    @change="
                                        floorTagAutocomplete = '';
                                        updateQuoteFloorTags(item.lineFloorTag);
                                    "
                                    :items="floorTags"
                                    outlined
                                    :dense="dense"
                                    hide-details="auto"
                                    background-color="white"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    :attach="true"
                                    clearable
                                >
                                    <template v-slot:selection="{ attrs, item, selected }">
                                        <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                            <span class="pr-2">
                                                {{ item }}
                                            </span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <span class="subheading">Create</span>
                                            <v-chip color="grey lighten-3" label small>
                                                {{ floorTagAutocomplete }}
                                            </v-chip>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <v-col class="dnd-no-drag">
                                <v-combobox
                                    class=""
                                    small-chips
                                    hide-selected
                                    label="Room"
                                    v-model="item.lineRoomTag"
                                    :search-input.sync="roomTagAutocomplete"
                                    @change="
                                        roomTagAutocomplete = '';
                                        updateQuoteRoomTags(item.lineRoomTag);
                                    "
                                    :items="roomTags"
                                    outlined
                                    :dense="dense"
                                    hide-details="auto"
                                    background-color="white"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    :attach="true"
                                    clearable
                                >
                                    <template v-slot:selection="{ attrs, item, selected }">
                                        <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                            <span class="pr-2">
                                                {{ item }}
                                            </span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <span class="subheading">Create</span>
                                            <v-chip color="grey lighten-3" label small>
                                                {{ roomTagAutocomplete }}
                                            </v-chip>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <v-col cols="12" class="dnd-no-drag">
                                <!-- -->
                                <v-select auto-select-first label="Features" v-model="item.lineFeatures" multiple :items="productFeatures" item-text="featureName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true"> </v-select>
                            </v-col>
                            <v-col cols="3" class="dnd-no-drag">
                                <v-combobox
                                    class=""
                                    small-chips
                                    hide-selected
                                    label="Optional Heading "
                                    v-model="item.lineOptionalTags"
                                    :search-input.sync="optionalTagAutocomplete"
                                    @change="
                                        optionalTagAutocomplete = '';
                                        updateQuoteOptionalTags(item.lineOptionalTags);
                                    "
                                    :items="optionalTags"
                                    outlined
                                    :dense="dense"
                                    hide-details="auto"
                                    background-color="white"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    :attach="true"
                                    clearable
                                >
                                    <template v-slot:selection="{ attrs, item, selected }">
                                        <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                            <span class="pr-2">
                                                {{ item }}
                                            </span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <span class="subheading">Create</span>
                                            <v-chip color="grey lighten-3" label small>
                                                {{ optionalTagAutocomplete }}
                                            </v-chip>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <v-col cols="9" class="d-flex align-center dnd-no-drag">
                                <div class="mr-1 d-flex align-start">
                                    <v-checkbox v-model="item.lineIncludeTotal" true-value="1" false-value="0" label="Include in Total" hide-details="auto" class="pa-0 ma-0 mr-3" />
                                    <!-- <v-checkbox v-model="item.lineOptional" true-value="1" false-value="0" label="Optional" hide-details="auto" class="pa-0 ma-0" /> -->
                                </div>
                                <v-spacer />

                                <v-btn small @click="changeOptions(item)" class="mr-1 grey darken-1 white--text" depressed>Change Options</v-btn>
                                <!-- <v-btn block @click="calculateLineItemNew(item, index, 'restore')" class="accent text--secondary" depressed>Restore Pricing</v-btn> -->
                                <v-btn small @click="calculateLineItemNew(item, index, 'restore')" depressed class="mr-1 grey darken-1 white--text">Restore Pricing</v-btn>
                                <!-- <div v-for="item in item.lineAuthGroup.split(',')" :key="item"> -->

                                <v-btn v-if="item.lineAuthRequired == '1' && item.lineProductType == 'Custom' && supplyChainUsers.includes(GET_FIREBASE_userAccessToken.user_id) && quote.quoteStatus == 'Awaiting Approval'" small depressed class="success white--text" @click="approveLineItem(index)">Approve</v-btn>
                                <!-- </div> -->
                                <!-- <div v-for="item in item.lineAuthGroup.split(',')" :key="item"> -->
                                <!-- <v-btn :disabled="!toggleFeatures.includes('delete')" small @click="destroyItem(item.id)" depressed class="error white--text">Delete</v-btn> -->
                                <!-- </div> -->
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mx-1" style="margin-top: 0px !important" v-if="item.lineType === 'Product'">
                            <v-expansion-panels flat tile class="dnd-no-drag" accordion :disabled="!editmode">
                                <!-- <v-expansion-panel :disabled="item.lineEdit === '0'" depressed class="grey lighten-2" style="border-left: 6px solid #e4401c !important" v-if="checkTBC(item) || (item.lineAuthGroup !== '' && item.lineAuthGroup !== undefined) || item.lineTotalMarginPercent < 20 || item.lineTotalMarginPercent < 20"></v-expansion-panel> -->
                                <v-expansion-panel :disabled="item.lineEdit === '0'" depressed class="grey lighten-2" :style="( item.lineAuthRequired == '1' || checkTBC(item) ) ? 'border-left: 6px solid #e4401c !important' : 'border-left: 6px solid #00942A !important'" v-if="checkTBC(item) || Number(item.lineMarginPercent) < Number(item.lineMarginPercentBase) || Number(item.lineSell) < Number(item.lineSellBase) || item.lineAuthRequired == '1'">
                                    <v-expansion-panel-header :class="( item.lineAuthRequired == '1' || checkTBC(item) ) ? 'warning--text' : 'success--text'" class="font-weight-bold"><span v-if="( item.lineAuthRequired == '1' || checkTBC(item) )">Warnings <span v-if="item.lineEdit === '1'">- Click to View</span><span v-else>- Click edit button to View</span></span><span v-else>Approved</span> </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <ul>
                                            <li class="mb-1" v-if="checkTBC(item)">
                                                Some Options are set to TBC - These will need to be confirmed before converting to Order, click Change Options button above.
                                                <!-- <div class="mt-1 d-flex align-center">
                                                    <div>
                                                        <v-btn @click="changeOptions(item)" block class="grey darken-1 white--text" depressed>Change Options</v-btn>
                                                    </div>
                                                </div> -->
                                            </li>
                                            <li class="mt-2" v-if="Number(item.lineMarginPercent) < Number(item.lineMarginPercentBase)">
                                                <span v-if="quote.quoteStatus !== 'Awaiting Approval' && quote.quoteStatus !== 'Ready'"> Low Margin detected - please correct or click to give reason.</span>
                                                <span v-else-if="item.lineAuthRequired === '1' && quote.quoteStatus === 'Awaiting Approval' ">Low Margin has been detected, please view reason below and approve this Line before Approving Quote.</span>
                                                <span v-else-if="item.lineAuthRequired === '0' && quote.quoteStatus === 'Awaiting Approval' || item.lineAuthRequired === '0' &&  quote.quoteStatus === 'Ready'">Low Margin has been detected and approved, please view reason below.</span>
                                                <div class="d-flex align-center mt-1">
                                                    <div class="">
                                                        <v-select :menu-props="{ top: false, offsetY: true }" :attach="false" :disabled="quote.quoteStatus === 'Awaiting Approval' || item.lineAuthRequired == '0'" v-model="item.lineMarginReason" :items="marginReasons" label="Reason" outlined dense hide-details="auto" background-color="white" />
                                                    </div>
                                                    <div class="ml-2 flex-grow-1">
                                                        <v-text-field :disabled="quote.quoteStatus === 'Awaiting Approval' || item.lineAuthRequired == '0'" v-model="item.lineMarginComments" label="Comments" outlined dense hide-details="auto" background-color="white" />
                                                    </div>
                                                    <div v-if="['Developer-Admin', 'Staff-Directors', 'Staff-Admin'].includes(userLevel) && item.lineAuthRequired == '1' && quote.quoteStatus != 'Draft' " class="pl-2">
                                                        <v-btn small depressed class="success white--text" @click="approveLineItem(index)">Approve</v-btn>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="" v-else-if="item.lineAuthRequired === '1'">
                                                <div v-if="item.lineProductType == 'Custom'">
                                                    <span v-if="quote.quoteStatus !== 'Awaiting Approval'">Custom Product added, please wait for line to be Authorised.</span>
                                                    <span v-else-if="quote.quoteStatus === 'Awaiting Approval'">Custom Product has been added, please approve this Line before Approving Quote.</span>
                                                </div>
                                                <div v-else>
                                                    <span v-if="quote.quoteStatus !== 'Awaiting Approval'">Standard pricing has been altered, please click to <v-btn @click="calculateLineItemNew(item, index, 'restore')" depressed small class="mx-1 grey darken-1 white--text">Restore pricing</v-btn> or wait for line to be Authorised.</span>
                                                    <span v-else-if="quote.quoteStatus === 'Awaiting Approval'">Standard pricing has been altered, please approve this Line before Approving Quote.</span>
                                                </div>
                                                <div class="mt-1 d-flex align-center justify-end">
                                                    <div>
                                                        <v-btn v-if="item.lineAuthRequired == '1' && item.lineProductType == 'Custom' && supplyChainUsers.includes(GET_FIREBASE_userAccessToken.user_id) && quote.quoteStatus != 'Draft'" small depressed class="success white--text" @click="approveLineItem(index)">Approve</v-btn>
                                                        <v-btn v-else-if="item.lineAuthRequired == '1' && salesAuthUsers.includes(GET_FIREBASE_userAccessToken.user_id) && quote.quoteStatus == 'Awaiting Approval'" small depressed class="success white--text" @click="approveLineItem(index)">Approve</v-btn>
                                                    </div>
                                                </div>
                                            </li>
                                            
                                            <!-- <pre>Item lineAuthRequired: {{item}}</pre> -->

                                        </ul>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-row>

                        <v-row class="rounded" no-gutters style="border-radius: 3px" v-if="item.lineType === 'section'">
                            <div v-if="toggleMoveItems" class="d-flex align-center">
                                <v-icon color="grey">icons8-move-grabber</v-icon>
                            </div>
                            <v-col class="d-flex align-center pl-2 ml-2 grey darken-1 dnd-no-drag">
                                <v-text-field background-color="grey darken-1" v-model="item.sectionName" hide-details="auto" dense dark filled clearable />
                                <div>
                                    <v-btn depressed outlined class="grey--text text--darken-1 mr-2" @click="items.splice(index, 1)"><v-icon color="white">icons8-trash-can</v-icon></v-btn>
                                </div>
                            </v-col>
                        </v-row>

                        <!-- <pre>Item: {{ item }}</pre> -->
                    </drag>
                </template>
            </drop-list>
            <v-row :key="quoteInstallKey" class="rounded mt-2 py-2 mx-3" no-gutters style="border-radius: 3px" v-if="quote.quoteInstall === '1'">
                <v-col cols="12" class="py-4 grey lighten-2">
                    <div v-if="editmode" class="mx-2 d-flex align-center justify-end">
                        <div class="font-weight-bold ml-2">Installation</div>
                        <div class="font-weight-bold ml-2"><v-text-field prefix="£" dense class="" label="Install Cost" readonly v-model="quoteInstall.totalInstallCost" outlined background-color="white" hide-details="auto" /></div>
                        <div class="font-weight-bold ml-2"><v-text-field prefix="£" dense class="" label="Install Sell" v-model="quoteInstall.totalInstallSell" @change="checkTotalInstallSell" outlined background-color="white" hide-details="auto" /></div>
                        <div class="font-weight-bold ml-2"><v-text-field prefix="£" dense class="" label="Margin Value" readonly v-model="quoteInstall.totalInstallMarginValue" outlined background-color="white" hide-details="auto" /></div>
                        <div class="font-weight-bold ml-2"><v-text-field suffix="%" dense class="" label="Margin Percent" readonly v-model="quoteInstall.totalInstallMarginPercent" outlined background-color="white" hide-details="auto" /></div>
                        <div class="ml-2">
                            <div class="dnd-no-drag">
                                <v-btn :disabled="!editmode" @click="removeInstall" depressed class="error primary--text"><v-icon color="white">icons8-trash-can</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                    <div v-if="!editmode" class="mx-2 d-flex align-center justify-end">
                        <div class="font-weight-bold ml-2">Installation</div>
                        <div class="font-weight-bold ml-2"><v-text-field prefix="£" dense class="" label="Install Cost" readonly v-model="quote.totalInstallCost" outlined background-color="white" hide-details="auto" /></div>
                        <div class="font-weight-bold ml-2"><v-text-field prefix="£" dense class="" label="Install Sell" readonly v-model="quote.totalInstallSell" @change="checkTotalInstallSell" outlined background-color="white" hide-details="auto" /></div>
                        <div class="font-weight-bold ml-2"><v-text-field prefix="£" dense class="" label="Margin Value" readonly v-model="quote.totalInstallMarginValue" outlined background-color="white" hide-details="auto" /></div>
                        <div class="font-weight-bold ml-2"><v-text-field suffix="%" dense class="" label="Margin Percent" readonly v-model="quote.totalInstallMarginPercent" outlined background-color="white" hide-details="auto" /></div>
                        <div class="ml-2">
                            <div class="dnd-no-drag">
                                <v-btn :disabled="!editmode" @click="removeInstall" depressed class="error primary--text"><v-icon color="white">icons8-trash-can</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </v-col>
                        <!-- <pre>{{ quoteInstall }}</pre> -->
            </v-row>
            <!-- <pre>Quote MCD Rate: {{ quote.quoteMCDRate }}</pre> -->
            <br /><br />
        <!-- <pre>{{ quote }}</pre> -->
        </div>
        <div style="position: absolute; bottom: 0; width: 100%; height: 110px; z-index: 10001 !important">
            <v-col cols="12" class="primary white--text">
                <div class="mx-2 d-flex align-center justify-start">
                    <div class="font-weight-bold"><v-text-field dense label="" class="" readonly v-model="quoteTotal.totalItems" outlined background-color="white" hide-details="auto" />Line Items</div>
                    <div class="font-weight-bold ml-2"><v-text-field prefix="£" dense label="" class="" readonly v-model="quoteTotal.totalCost" outlined background-color="white" hide-details="auto" />Quote Cost</div>
                    <div class="font-weight-bold ml-2"><v-text-field prefix="£" dense label="" class="" readonly v-model="quoteTotal.totalSell" outlined background-color="white" hide-details="auto" />Quote Sell</div>
                    <div class="font-weight-bold ml-2"><v-text-field prefix="£" dense label="" class="" readonly v-model="quoteTotal.totalSellMargin" outlined background-color="white" hide-details="auto" />Quote Margin</div>
                    <div class="font-weight-bold ml-2"><v-text-field suffix="%" dense label="" v-model="quoteTotal.totalMargin" outlined :background-color="quoteTotal.totalMargin < 20 ? 'red lighten-4' : 'green lighten-4'" hide-details="auto" />Margin %</div>
                </div>
            </v-col>
        </div>



        <v-dialog v-model="dialogOptionsSelector" :key="dialogKey" width="95%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <product-preview 
                    v-if="!loading" 
                    :datadown="activeLineItem" 
                    dense 
                    v-on:updatelineitem="updateLineItem" 
                    v-on:close="closeProductPreview"
                />
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogOptionsSelector" :key="dialogKey" width="95%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <product-preview 
                    v-if="!loading" 
                    :datadown="activeLineItem" 
                    dense 
                    v-on:updatelineitem="updateLineItem" 
                    v-on:close="closeProductPreview"
                />
            </v-card>
        </v-dialog>

        <v-dialog v-model="showMarketDialog" persistent :key="marketPriceDialogKey" width="60%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <div class="grey lighten-3" style=" overflow: hidden !important">
                    <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
                        <v-col cols="12" class="d-flex align-center justify-start primary py-4">
                            <div class="white--text text-h5 font-weight-bold ml-5 mt-2">Market Price</div>
                            <v-spacer />
                        </v-col>
                    </v-row>
                    
                    <v-row class="px-4 pt-3">

<!--                      <pre>{{marketPriceItem}}</pre>-->
                        <v-row class="grey lighten-3 rounded pa-3 fill-height" no-gutters v-if="marketPriceItem">
                            <v-col cols="6" class="py-1">
                              <v-text-field prefix="£" class="" dense label="Market Price" outlined background-color="white" hide-details="auto" v-model="marketPriceItem.MP" clearable @click:clear="clearItem('MP')" v-bind:disabled="!editingMP"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="py-1">
                              <v-text-field label="Market Price Link One" v-model="marketPriceItem.productWebLink1" outlined dense hide-details="auto" background-color="white" color="primary" clearable v-bind:disabled="!editingMP"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="py-1">
                              <v-text-field label="Market Price Link Two" v-model="marketPriceItem.productWebLink2" outlined dense hide-details="auto" background-color="white" color="primary" clearable v-bind:disabled="!editingMP"></v-text-field>
                            </v-col>


                            <v-col cols="12" class="py-1">
                              <v-text-field label="Market Price Link Three" v-model="marketPriceItem.productWebLink3" outlined dense hide-details="auto" background-color="white" color="primary" clearable v-bind:disabled="!editingMP"></v-text-field>
                            </v-col>

<!--                            <v-col cols="12" class="" style="height: 100% !important">-->
<!--                                <v-simple-table dense>-->
<!--                                    <template v-slot:default>-->
<!--                                      <pre>{{marketPriceItem}}</pre>-->
<!--                                        <tbody>-->
<!--                                            <tr>-->
<!--                                                <td width="40%" valign="top" class="pl-0 apptext&#45;&#45;text font-weight-bold grey lighten-3">Market Price:</td>-->
<!--                                                <td width="60%" valign="top" class="apptext&#45;&#45;text font-weight-normal body-1 grey lighten-3 pl-2">-->
<!--                                                    {{ marketPriceItem.MP }}-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td width="40%" valign="top" class="pl-0 apptext&#45;&#45;text font-weight-bold body-2 grey lighten-3">Market Price Comparison:</td>-->
<!--                                                <td width="60%" valign="top" class="apptext&#45;&#45;text font-weight-normal body-1 grey lighten-3 pl-2">-->
<!--                                                    <div v-if="marketPriceItem.productWebLink1">-->
<!--                                                        <a :href="marketPriceItem.productWebLink1" target="_blank">{{ marketPriceItem.productWebLink1.substring(0, 21) }}...</a>-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td width="40%" valign="top" class="pl-0 apptext&#45;&#45;text font-weight-bold body-2 grey lighten-3">Market Price Comparison 2:</td>-->
<!--                                                <td width="60%" valign="top" class="apptext&#45;&#45;text font-weight-normal body-1 grey lighten-3 pl-2">-->
<!--                                                    <div v-if="marketPriceItem.productWebLink2">-->
<!--                                                        <a :href="marketPriceItem.productWebLink2" target="_blank">{{ marketPriceItem.productWebLink2.substring(0, 21) }}...</a>-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td width="40%" valign="top" class="pl-0 apptext&#45;&#45;text font-weight-bold body-2 grey lighten-3">Market Price Comparison 3:</td>-->
<!--                                                <td width="60%" valign="top" class="apptext&#45;&#45;text font-weight-normal body-1 grey lighten-3 pl-2">-->
<!--                                                    <div v-if="marketPriceItem.productWebLink3">-->
<!--                                                        <a :href="marketPriceItem.productWebLink3" target="_blank">{{ marketPriceItem.productWebLink3.substring(0, 21) }}...</a>-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                        </tbody>-->
<!--                                    </template>-->
<!--                                </v-simple-table>-->
<!--                            </v-col>-->
                        </v-row>
                    </v-row>
                    <!-- <pre>{{ marketPriceItem }}</pre> -->

                    <v-row class="mt-0 d-flex justify-center align-center" dense>
                        <div v-if="marketPriceItem.modifiedDateTime && marketPriceItem.modifiedDateTime !== ''" class="apptext--text font-weight-bold body-2">
                            Last Modified at {{ MIX_formatDateTime(marketPriceItem.modifiedDateTime, 'x', 'HH:mm DD-MMM-YYYY ') }}
                            <span v-if="marketPriceItem.modifiedUserName && marketPriceItem.modifiedUserName !== ''"> by {{ marketPriceItem.modifiedUserName }} </span>
                        </div>
                    </v-row>

                    <v-row class="px-3 pb-3 mt-8 primary">
                        <v-col cols="12" class="d-flex justify-right align-center">
                            <v-spacer />
                            <div class="mr-2">
                              <v-btn text class="grey lighten-5 secondary--text" v-if="!editingMP" @click="editingMP = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                            </div>
                            <div>
                                <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="closeMarketDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                            </div>
                            <div class="ml-2">
                                <v-btn class="accent primary--text font-weight-bold" depressed @click="saveMarketDialog" v-if="editingMP"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>


        <v-dialog v-model="editImageDialog" persistent :key="imageDialogKey" width="60%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <div class="grey lighten-3" style=" overflow: hidden !important">
                    <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
                        <v-col cols="12" class="d-flex align-center justify-start primary py-4">
                            <div class="white--text text-h5 font-weight-bold ml-3">Image Editor</div>
                            <v-spacer />
                        </v-col>
                    </v-row>
                    <v-row class="pl-9 pt-6">
                        <div class="font-weight-bold grey--text text--darken-2">You are replacing the below Line Item image, to replace the Variant/Product Item image, please select the relevent checkbox below.</div>
                    </v-row>
                    <v-row class="pa-4" dense>
                        <v-card flat width="100%">
                            <v-row class="d-flex align-center">
                                <v-col cols="2" class="pa-6 align-center justify-center">
                                    <div class="font-weight-bold grey--text">Editing Image:</div>
                                    <v-img class="my-2" width="140" height="140" contain :src="'https://storage.googleapis.com/mof-swapp-public/products/thumbnails/' + imageItem.lineImageId + '.jpeg'" />
                                </v-col>
                                <v-col cols="10">
                                    <ImageUpload 
                                        accept="image/jpeg, image/jpg, image/png" 
                                        label="Select file" 
                                        buttonClass="primary" 
                                        progressClass="py-4 pa-2" 
                                        folderLocation="products/" 
                                        :fileName="imageItem.id" 
                                        :compressionMaxSizeMB="1" 
                                        :compressionmaxWidthOrHeight="500" 
                                        :selectedItem="imageItem" 
                                        :private="false" 
                                        :createThumbnail="true" 
                                        v-on:update="updateImage()" 
                                    />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-row>
                    <v-col cols="12">
                        <v-row class="d-flex justify-center align-center">
                            <!-- <v-col cols="4" sm="4" class="my-1 d-flex align-center">
                                <v-checkbox  label="Replace Line Item Image" v-model="imageItem.quoteNPS" true-value="1" false-value="0" class="pa-0 ma-0" hide-details="auto"></v-checkbox>
                            </v-col> -->
                            <v-col cols="4" sm="4" class="my-1 d-flex align-center justify-center">
                                <v-checkbox  label="Replace Product Image" v-model="replaceProductImage" class="pa-0 ma-0" hide-details="auto"></v-checkbox>
                            </v-col>
                            <v-col v-if="imageItem.lineParentCodeId !== '' && imageItem.lineCodeId !== ''" cols="4" sm="4" class="my-1 d-flex align-center">
                                <v-checkbox  label="Replace Variant Image" v-model="replaceVariantImage" class="pa-0 ma-0" hide-details="auto"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center align-center">
                            <div v-if="replaceProductImage" class="font-weight-bold ">This will replace the default Product Image as well as the Line Item Image, this action is NOT reversable</div>
                            <div v-else-if="replaceVariantImage" class="font-weight-bold">This will replace the default Variant Image as well as the Line Item Image, this action is NOT reversable</div>
                            <div v-else class="font-weight-bold ">This will replace the Line Item Image, this action is NOT reversable</div>
                        </v-row>
                    </v-col>
                    <!-- <pre>{{ imageItem }}</pre> -->
                    <v-row class="px-3 pb-3 mt-8 primary">
                        <v-col cols="12" class="d-flex justify-right align-center">
                            <v-spacer />
                            <div>
                                <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="cancelImageItem"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            </div>
                            <div class="ml-2">
                                <v-btn class="accent primary--text font-weight-bold" depressed @click="saveImageItem"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>




        <v-dialog v-model="approvalDialog" persistent :key="approvalDialogKey" width="60%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <div class="grey lighten-3" style=" overflow: hidden !important">
                    <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
                        <v-col cols="12" class="d-flex align-center justify-start primary py-4">
                            <div class="white--text d-flex align-center text-h5 font-weight-bold ml-4 mt-2">
                                <v-icon class="white--text mr-2">icons8-box-important</v-icon>
                                <span v-if="approvalReason === 'Low Margin'">Unable to Submit For Approval</span>
                                <span v-if="approvalReason === 'Approval'">Unable to Approve Quote</span>
                            </div>
                            <v-spacer />
                        </v-col>
                    </v-row>
                    <v-row v-if="approvalReason === 'Low Margin'" class="pl-8 py-6 d-flex justify-center align-center">
                        <div class="font-weight-bold grey--text text--darken-2 pt-1">Low Margin Reason and Comment required before the Quote can be submitted for approval.</div>
                    </v-row>
                    <v-row v-if="approvalReason === 'Approval'" class="pl-8 py-6 d-flex justify-center align-center">
                        <div class="font-weight-bold grey--text text--darken-2 pt-1">There are some Line Items that require approval before the Quote can be approved.</div>
                    </v-row>
                
                    
                    <v-row class="px-3 pb-3 mt-4 primary">
                        <v-col cols="12" class="d-flex justify-right align-center">
                            <v-spacer />
                            <div>
                                <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="closeApprovalDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>


        <v-dialog v-model="approvalNotesDialog" persistent :key="approvalNotesDialogKey" width="60%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <div class="grey lighten-3" style=" overflow: hidden !important">
                    <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
                        <v-col cols="12" class="d-flex align-center justify-start primary py-4">
                            <div class="white--text d-flex align-center text-h5 font-weight-bold ml-4 mt-2">
                                <v-icon class="white--text mr-2">icons8-box-important</v-icon>
                                <span v-if="stage === 'approveQuoteFirstLevel' || stage === 'approveQuoteSecondLevel'">Approval Notes</span>
                                <span v-else-if="stage === 'rejectQuoteFirstLevel' || stage === 'rejectQuoteSecondLevel'">Rejection Notes</span>
                                <span v-else>{{ stage }} Notes</span>
                            </div>
                            <v-spacer />
                        </v-col>
                    </v-row>
                    <v-row class="px-4 py-2 d-flex justify-center align-center">
                        <v-col cols="12" class="d-flex align-start justify-start">
                            <v-text-field dense label="Note Title" v-model="note.noteTitle" outlined  hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('noteTitle')"> </v-text-field>
                        </v-col>
                        <v-col cols="12" class="d-flex pt-0 align-start justify-start">
                            <v-textarea rows="4" label="Note Content" v-model="note.noteContent" outlined  hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('noteContent')"> </v-textarea>
                        </v-col>
                        <!-- <pre>{{ note }}</pre> -->
                    </v-row>
                
                    
                    <v-row class="px-3 pb-3 mt-4 primary">
                        <v-col cols="12" class="d-flex justify-right align-center">
                            <v-spacer />
                            <div>
                                <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="closeApprovalNotesDialog"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Close</span> </v-btn>
                            </div>
                            <div>
                                <v-btn class="accent ml-2 primary--text font-weight-bold" depressed @click="saveApprovalNotes"> <v-icon class="mr-2">icons8-save</v-icon><span>Save </span> <span class="pl-1" v-if="stage === 'approveQuoteFirstLevel' || stage === 'approveQuoteSecondLevel'"> and Approve</span><span class="pl-1" v-else-if="stage === 'rejectQuoteFirstLevel' || stage === 'rejectQuoteSecondLevel'">and Reject</span></v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="MCDDialog" persistent :key="MCDDialogKey" width="60%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <div class="grey lighten-3" style=" overflow: hidden !important">
                    <v-row dense style="height: 60px; min-height: 60px; max-height: 60px">
                        <v-col cols="12" class="d-flex align-center justify-start primary py-4">
                            <div class="white--text text-h5 font-weight-bold ml-4 mt-2">Edit MCD Rate</div>
                            <v-spacer />
                        </v-col>
                    </v-row>
                    <v-row class="pl-8 pt-2 d-flex align-center">
                        <div class="font-weight-bold grey--text text--darken-2 pt-1">The MCD Rate will be applied to all Line Items in the Quote</div>
                        <v-col cols="4" class="px-7">
                            <v-text-field suffix="%" dense label="MCD Rate" @change="changeMCDRate" outlined v-model="MCDRate" :background-color="item.lineEdit === '0' ? 'grey lighten-3' : 'white'" hide-details="auto" />
                        </v-col>
                        <v-col cols="2">
                            <v-btn @click="removeMCD" class="elevation-0">Remove MCD</v-btn>
                        </v-col>

                    </v-row>
                    
                    <v-row class="px-3 pb-3 mt-4 primary">
                        <v-col cols="12" class="d-flex justify-right align-center">
                            <v-spacer />
                            <div>
                                <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="MCDDialog = false"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            </div>
                            <div class="ml-2">
                                <v-btn class="accent primary--text font-weight-bold" depressed @click="saveMCDRate"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="approveDialog" persistent :key="approveDialogKey" width="60%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <div class="grey lighten-3" style=" overflow: hidden !important">
                    <v-row dense style="height: 40px; min-height: 40px; max-height: 40px">
                        <v-col cols="12" class="d-flex align-center justify-start primary py-2">
                            <div class="white--text text-h6 font-weight-bold ml-4 mt-2">Approvals</div>
                        </v-col>
                    </v-row>


                    <v-row class="pt-2">
                        <v-col cols="3" class="d-flex justify-center align-center">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-center align-center">
                            <div v-if="!quoteReadyForApproval" class="d-flex">
                                <v-icon class="mr-2 error--text">icons8-box-important</v-icon>
                                Please note there are some Line Items still requiring Approval.
                            </div>
                        </v-col>
                        <v-col cols="3" class="d-flex justify-end align-center pr-7">
                            <div>
                                <v-btn :disabled="quote.quoteStatus !== 'Awaiting Approval'" class="grey lighten-1 grey--text text--lighten-3 font-weight-bold" depressed @click="addNote = !addNote"> <v-icon class="mr-2">icons8-note</v-icon><span v-if="!addNote">Add Note</span><span v-if="addNote">Remove Note</span> </v-btn>
                            </div>
                        </v-col>
                    </v-row>


                    <v-row class="px-4 py-0 d-flex align-center">
                        <v-col cols="12" class="pt-0" style="height: 100% !important">
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3">Install Approval</td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3">Supply Chain Approval</td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3">Sales Manager Approval</td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3">MD Approval</td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3">FD Approval</td>
                                        </tr>
                                        <tr>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1 pa-0 grey lighten-3">

                                                <div
                                                    :class="
                                                    quote.quoteInstallApprovalStatus == 'Rejected' ? 'error white--text' : 
                                                    ( quote.quoteInstallApprovalStatus == 'Pending' || quote.quoteInstallApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                                                    quote.quoteInstallApprovalStatus == 'Approved' ? 'success white--text' : 
                                                    'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold" style="padding: 0px !important; height: 100% !important">
                                                        <span v-if="quote.quoteInstallApprovalStatus == 'Rejected'">R</span>
                                                        <span v-else-if="quote.quoteInstallApprovalStatus == 'Pending' || quote.quoteInstallApprovalStatus == 'Awaiting Approval'">P</span>
                                                        <span v-else-if="quote.quoteInstallApprovalStatus == 'Approved'">A</span>
                                                        <span v-else>N/A</span>
                                                </div>
                                            </td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1  grey lighten-3 pa-0">

                                                <div
                                                    :class="
                                                    quote.quoteSupplyChainApprovalStatus == 'Rejected' ? 'error white--text' : 
                                                    ( quote.quoteSupplyChainApprovalStatus == 'Pending' || quote.quoteSupplyChainApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                                                    quote.quoteSupplyChainApprovalStatus == 'Approved' ? 'success white--text' : 
                                                    'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold" style="padding: 0px !important; height: 100% !important">
                                                        <span v-if="quote.quoteSupplyChainApprovalStatus == 'Rejected'">R</span>
                                                        <span v-else-if="quote.quoteSupplyChainApprovalStatus == 'Pending' || quote.quoteSupplyChainApprovalStatus == 'Awaiting Approval'">P</span>
                                                        <span v-else-if="quote.quoteSupplyChainApprovalStatus == 'Approved'">A</span>
                                                        <span v-else>N/A</span>
                                                </div>
                                            </td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1  grey lighten-3 pa-0">

                                                <div
                                                    :class="
                                                    quote.quoteSalesManagerApprovalStatus == 'Rejected' ? 'error white--text' : 
                                                    ( quote.quoteSalesManagerApprovalStatus == 'Pending' || quote.quoteSalesManagerApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                                                    quote.quoteSalesManagerApprovalStatus == 'Approved' ? 'success white--text' : 
                                                    'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold" style="padding: 0px !important; height: 100% !important">
                                                        <span v-if="quote.quoteSalesManagerApprovalStatus == 'Rejected'">R</span>
                                                        <span v-else-if="quote.quoteSalesManagerApprovalStatus == 'Pending' || quote.quoteSalesManagerApprovalStatus == 'Awaiting Approval'">P</span>
                                                        <span v-else-if="quote.quoteSalesManagerApprovalStatus == 'Approved'">A</span>
                                                        <span v-else>N/A</span>
                                                </div>
                                            </td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1  grey lighten-3 pa-0">

                                                <div
                                                    :class="
                                                    quote.quoteMDDirectorApprovalStatus == 'Rejected' ? 'error white--text' : 
                                                    ( quote.quoteMDDirectorApprovalStatus == 'Pending' || quote.quoteMDDirectorApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                                                    quote.quoteMDDirectorApprovalStatus == 'Approved' ? 'success white--text' : 
                                                    'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold" style="padding: 0px !important; height: 100% !important">
                                                        <span v-if="quote.quoteMDDirectorApprovalStatus == 'Rejected'">R</span>
                                                        <span v-else-if="quote.quoteMDDirectorApprovalStatus == 'Pending' || quote.quoteMDDirectorApprovalStatus == 'Awaiting Approval'">P</span>
                                                        <span v-else-if="quote.quoteMDDirectorApprovalStatus == 'Approved'">A</span>
                                                        <span v-else>N/A</span>
                                                </div>
                                            </td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1  grey lighten-3 pa-0">

                                                <div
                                                    :class="
                                                    quote.quoteFDDirectorApprovalStatus == 'Rejected' ? 'error white--text' : 
                                                    ( quote.quoteFDDirectorApprovalStatus == 'Pending' || quote.quoteFDDirectorApprovalStatus == 'Awaiting Approval' ) ? 'warning white--text' : 
                                                    quote.quoteFDDirectorApprovalStatus == 'Approved' ? 'success white--text' : 
                                                    'grey white--text'" class="d-flex align-center justify-center caption font-weight-bold" style="padding: 0px !important; height: 100% !important">
                                                        <span v-if="quote.quoteFDDirectorApprovalStatus == 'Rejected'">R</span>
                                                        <span v-else-if="quote.quoteFDDirectorApprovalStatus == 'Pending' || quote.quoteFDDirectorApprovalStatus == 'Awaiting Approval'">P</span>
                                                        <span v-else-if="quote.quoteFDDirectorApprovalStatus == 'Approved'">A</span>
                                                        <span v-else>N/A</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1  grey lighten-3 pa-0">
                                                 <!-- v-if="quote.quoteInstallApprovalStatus == 'Pending'" -->
                                                <div class="d-flex" style="width: 100% !important; height: 100% !important">
                                                    <div class="pr-1" style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="reject('quoteInstallApprovalStatus')" :disabled="(quote.quoteInstallApprovalStatus !== 'Pending' && quote.quoteInstallApprovalStatus !== 'Awaiting Approval') || quote.quoteStatus == 'Rejected' || !installApprovalUsers.includes(GET_FIREBASE_userAccessToken.user_id) "  style="width: 100% !important; height: 100% !important" class="error white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Reject</v-btn>
                                                    </div>
                                                    <div style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="approve('quoteInstallApprovalStatus')" :disabled="quote.quoteInstallApprovalStatus !== 'Pending' && quote.quoteInstallApprovalStatus !== 'Awaiting Approval' || quote.quoteStatus == 'Rejected' || !installApprovalUsers.includes(GET_FIREBASE_userAccessToken.user_id)" style="width: 100% !important; height: 100% !important" class="success white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Approve</v-btn>
                                                    </div>
                                                </div>

                                            </td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1  grey lighten-3 pa-0">
                                                <div class="d-flex" style="width: 100% !important; height: 100% !important">
                                                    <div class="pr-1" style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="reject('quoteSupplyChainApprovalStatus')" :disabled="quote.quoteSupplyChainApprovalStatus !== 'Pending' && quote.quoteSupplyChainApprovalStatus !== 'Awaiting Approval' || quote.quoteStatus == 'Rejected' || !supplyChainUsers.includes(GET_FIREBASE_userAccessToken.user_id)" style="width: 100% !important; height: 100% !important" class="error white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Reject</v-btn>
                                                    </div>
                                                    <div style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="approve('quoteSupplyChainApprovalStatus')" :disabled="quote.quoteSupplyChainApprovalStatus !== 'Pending' && quote.quoteSupplyChainApprovalStatus !== 'Awaiting Approval' || quote.quoteStatus == 'Rejected' || !supplyChainUsers.includes(GET_FIREBASE_userAccessToken.user_id) ||  !quoteReadyForApproval" style="width: 100% !important; height: 100% !important" class="success white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Approve</v-btn>
                                                    </div>
                                                </div>

                                            </td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1  grey lighten-3 pa-0">
                                                <div class="d-flex" style="width: 100% !important; height: 100% !important">
                                                    <div class="pr-1" style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="reject('quoteSalesManagerApprovalStatus')" :disabled="quote.quoteSalesManagerApprovalStatus !== 'Pending' && quote.quoteSalesManagerApprovalStatus !== 'Awaiting Approval' || quote.quoteStatus == 'Rejected' || !salesAuthUsers.includes(GET_FIREBASE_userAccessToken.user_id)" style="width: 100% !important; height: 100% !important" class="error white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Reject</v-btn>
                                                    </div>
                                                    <div style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="approve('quoteSalesManagerApprovalStatus')" :disabled="quote.quoteSalesManagerApprovalStatus !== 'Pending' && quote.quoteInstallApprovalStatus !== 'Awaiting Approval' ||  !quoteReadyForApproval ||quote.quoteStatus == 'Rejected' || !salesAuthUsers.includes(GET_FIREBASE_userAccessToken.user_id)" style="width: 100% !important; height: 100% !important" class="success white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Approve</v-btn>
                                                    </div>
                                                </div>

                                            </td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1  grey lighten-3 pa-0">
                                                <div class="d-flex" style="width: 100% !important; height: 100% !important">
                                                    <div class="pr-1" style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="reject('quoteMDDirectorApprovalStatus')" :disabled="quote.quoteMDDirectorApprovalStatus !== 'Pending' && quote.quoteMDDirectorApprovalStatus !== 'Awaiting Approval' || quote.quoteStatus == 'Rejected' || GET_FIREBASE_userAccessToken.user_id !== 'ai7FXnC6oVOLYjmmpTkB3aE4vpm2'" style="width: 100% !important; height: 100% !important" class="error white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Reject</v-btn>
                                                    </div>
                                                    <div style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="approve('quoteMDDirectorApprovalStatus')" :disabled="quote.quoteMDDirectorApprovalStatus !== 'Pending' && quote.quoteMDDirectorApprovalStatus !== 'Awaiting Approval' || quote.quoteStatus == 'Rejected' || GET_FIREBASE_userAccessToken.user_id !== 'ai7FXnC6oVOLYjmmpTkB3aE4vpm2'" style="width: 100% !important; height: 100% !important" class="success white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Approve</v-btn>
                                                    </div>
                                                </div>

                                            </td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="top" class="apptext--text font-weight-normal body-1  grey lighten-3 pa-0">
                                                <div class="d-flex" style="width: 100% !important; height: 100% !important">
                                                    <div class="pr-1" style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="reject('quoteFDDirectorApprovalStatus')" :disabled="quote.quoteFDDirectorApprovalStatus !== 'Pending' && quote.quoteFDDirectorApprovalStatus !== 'Awaiting Approval' || quote.quoteStatus == 'Rejected' || GET_FIREBASE_userAccessToken.user_id !== '3gIclPqXRtOz5lVlIE5y8o9j3Yu1'" style="width: 100% !important; height: 100% !important" class="error white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Reject</v-btn>
                                                    </div>
                                                    <div style="width: 50% !important; height: 100% !important">
                                                        <v-btn @click="approve('quoteFDDirectorApprovalStatus')" :disabled="quote.quoteFDDirectorApprovalStatus !== 'Pending' && quote.quoteFDDirectorApprovalStatus !== 'Awaiting Approval' || quote.quoteStatus == 'Rejected' || GET_FIREBASE_userAccessToken.user_id !== '3gIclPqXRtOz5lVlIE5y8o9j3Yu1'"  style="width: 100% !important; height: 100% !important" class="success white--text d-flex align-center justify-center caption font-weight-bold elevation-0">Approve</v-btn>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3">{{ getUser(quote.quoteInstallApprovedBy) }}{{ getUser(quote.quoteInstallDeniedBy) }}</td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3">{{ getUser(quote.quoteSupplyChainApprovedBy) }}{{ getUser(quote.quoteSupplyChainDeniedBy) }}</td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3">{{ getUser(quote.quoteSalesManagerApprovedBy) }}{{ getUser(quote.quoteSalesManagerDeniedBy) }}</td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3">{{ getUser(quote.quoteMDDirectorApprovedBy) }}{{ getUser(quote.quoteMDDirectorDeniedBy) }}</td>
                                            <td style="border-color: #eeeeee !important; border-style: solid !important" width="20%" valign="middle" class="text-center apptext--text font-weight-bold body-2 grey lighten-3 ">{{ getUser(quote.quoteFDDirectorApprovedBy) }}{{ getUser(quote.quoteFDDirectorDeniedBy) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>

                    <v-row v-if="addNote" class="px-4 pb-2 pt-0 d-flex justify-center align-center">
                        <v-col cols="12" class="d-flex pt-0 align-start justify-start">
                            <v-text-field dense label="Note Title" v-model="note.noteTitle" outlined  hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('noteTitle')"> </v-text-field>
                        </v-col>
                        <v-col cols="12" class="d-flex pt-0 align-start justify-start">
                            <v-textarea rows="4" label="Note Content" v-model="note.noteContent" outlined  hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('noteContent')"> </v-textarea>
                        </v-col>
                        <!-- <pre>{{ note }}</pre> -->
                    </v-row>

                    
                    <v-row class="px-4 pb-3 mt-4 primary">
                        <v-col cols="12" class="d-flex justify-right align-center">
                            <v-spacer />
                            <div>
                                <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="approveDialog = false"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            </div>
                            <div class="ml-2">
                                <v-btn 
                                :disabled="
                                quote.quoteStatus !== 'Awaiting Approval' || !approveUsers.includes(GET_FIREBASE_userAccessToken.user_id)" class="accent primary--text font-weight-bold" depressed @click="saveApprovalDialog"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="submitApprovalDialog" persistent :key="submitApprovalDialogKey" width="30%">
            <v-card class="mx-auto">
                <!-- Preview Tab -->
                <div class="grey lighten-3" style=" overflow: hidden !important">
                    <v-row dense style="height: 40px; min-height: 40px; max-height: 40px">
                        <v-col cols="12" class="d-flex align-center justify-start primary py-2">
                            <div class="white--text text-h6 font-weight-bold ml-4 mt-2">Submit for Approval?</div>
                        </v-col>
                    </v-row>


                    <v-row class="pt-2">
                        <v-col cols="12" class="d-flex font-weight-bold grey--text justify-center align-center px-7">
                            Please confirm you wish to submit this quote for approval, this action cannot be undone.
                        </v-col>
                    </v-row>
                    
                    <v-row class="px-4 pb-3 mt-4 primary">
                        <v-col cols="12" class="d-flex justify-right align-center">
                            <v-spacer />
                            <div>
                                <v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="submitApprovalDialog = false"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                            </div>
                            <div class="ml-2">
                                <v-btn class="accent primary--text font-weight-bold" depressed @click="ready"> <v-icon class="mr-2">icons8-save</v-icon><span>Save</span> </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import productPreview from '@/views/products/productPreview.vue';
import { mapGetters } from 'vuex';
import { DropList, Drag } from 'vue-easy-dnd';
import schema from '@/schema.js';
import pgsr from "@/import/pgsr.json";
import TextField from "@/components/form/TextField.vue";
import AppButton from "@/components/general/AppButton.vue";
import ExportBox from "@/components/general/ExportBox.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
// import { VueEditor } from 'vue2-editor';
export default {
    // * COMPONENTS
    components: {
      CheckboxField, ExportBox, AppButton, TextField,
        drag: Drag,
        'drop-list': DropList,
        'product-preview': productPreview,
        // VueEditor
    },
    // * PROPS
    props: {
        listshrunk: {
            type: Boolean,
            default: false,
        },
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        dense: {
            type: Boolean,
            default: false,
        },
        refreshlineitems: {
            type: Number,
            default: 0,
        },
    },
    // * DATA
    data: () => ({
        dialogKey: 5,
        imageDialogKey: 6,
        key: 'lineItem',
        index: 'idx:lineItems',
        loading: false,
        editmode: false,
        item: {},
        quote: {},
        dialogOptionsSelector: false,
        activeLineItem: {},
        schema: null,

        // Rooms and Floors
        roomTagAutocomplete: '',
        floorTagAutocomplete: '',
        roomTags: [],
        floorTags: [],

        // Optional
        optionalTagAutocomplete: '',
        optionalTags: [],

        // Approval Users
        firstLevelApprovalUsers : [
            'DGOGjDzCmnZLDHfv0BWR2G1SobT2',
            'Pr2QauqNq9bMhi94MYXCgPx3q8x2', 
            'leQaGXlzfoSJmCIB1KNPmLkb3sX2', 
            'CwmRwYA4CzZ57qH8tkSFJyEs6Mw1', 
            'dAmKL8xoxNXBxTdfWcwofuN1fBQ2',
        ],
        secondLevelApprovalUsers: [
            'DGOGjDzCmnZLDHfv0BWR2G1SobT2', 
            'ai7FXnC6oVOLYjmmpTkB3aE4vpm2', 
            '3gIclPqXRtOz5lVlIE5y8o9j3Yu1',
        ],


        installApprovalUsers: [
            'Pr2QauqNq9bMhi94MYXCgPx3q8x2', 'leQaGXlzfoSJmCIB1KNPmLkb3sX2', 'DGOGjDzCmnZLDHfv0BWR2G1SobT2'
        ], 

        supplyChainUsers: [
            'JRUoz6UNZWZqnRa3LdBbwE5Sa8B3', 'dAmKL8xoxNXBxTdfWcwofuN1fBQ2', 'h8uybNJtoDdbSNo952hFWgac9n53', 'DTbkUntsoqhn6z3nq64Bqjzw5do1', 'leQaGXlzfoSJmCIB1KNPmLkb3sX2',
            'DGOGjDzCmnZLDHfv0BWR2G1SobT2', 
        ], 

        salesAuthUsers: [
            'Pr2QauqNq9bMhi94MYXCgPx3q8x2', 'leQaGXlzfoSJmCIB1KNPmLkb3sX2', 'CwmRwYA4CzZ57qH8tkSFJyEs6Mw1', 'DGOGjDzCmnZLDHfv0BWR2G1SobT2'
        ], 

        approveUsers: [
            'Pr2QauqNq9bMhi94MYXCgPx3q8x2',
            'leQaGXlzfoSJmCIB1KNPmLkb3sX2',
            'JRUoz6UNZWZqnRa3LdBbwE5Sa8B3',
            'dAmKL8xoxNXBxTdfWcwofuN1fBQ2',
            'h8uybNJtoDdbSNo952hFWgac9n53',
            'DTbkUntsoqhn6z3nq64Bqjzw5do1',
            'CwmRwYA4CzZ57qH8tkSFJyEs6Mw1',
            'ai7FXnC6oVOLYjmmpTkB3aE4vpm2',
            '3gIclPqXRtOz5lVlIE5y8o9j3Yu1',
            'DGOGjDzCmnZLDHfv0BWR2G1SobT2'
        ],


        quoteReadyForApproval: true,

        // TODO - RG - Create a Server Schema for lineItems
        // Toggles
        toggleDeletedItems: false,
        toggleProductImages: false,
        toggleMoveItems: false,
        toggleMargin: false,
        toggleDiscount: false,
        mcdToggle: true,

        showMarketDialog: false, 
        marketPriceDialogKey: 0,
        marketPriceItem: {},

        MCDDialog: false,
        MCDDialogKey: 1,
        MCDRate: '',

        toggleFeatures: [],
        productFeatures: [],
        // Filters
        searchQuery: '',
        filter: {},
        // Items
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 999,
        // Headers
        //No Headers as not using table
        sortBy: '@lineOrder',

        dropKey: 2,
        // items: [1, 2, 3, 4, 5],
        marginReasons: ['Market Price', 'Price Match', 'Competition', 'Other'],
        dialogProductPreviewKey: 3,
        lineItems: [],


        overlay: false,
        editImageDialog: false,
        imageItem: {},
        replaceProductImage: false,
        replaceVariantImage: false,
        quoteInstallKey: 4,


        approvalDialog: false,
        approvalDialogKey: 0,
        approvalReason: '',

        approvalNotesDialogKey: 0,
        approvalNotesDialog: '',
        stage: '',
        approvalNotes: '',
        note: {},

        pgsr: {},

        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'align': ''},{ 'align': 'center'},{ 'align': 'justify'}, { 'align': 'right' }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],  
            [{ 'color': [] }, { 'background': [] }],
            ['link'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
        ],

        orgs: {},
        users: {},

        addNote: false,

        approveDialog: false,
        approveDialogKey: 0,

        submitApprovalDialog: false,
        submitApprovalDialogKey: 0,

      //EXPORT DATA
      exportBox: false,
      exportItems: {
        itemsFrom: "1",
        itemsTo: "10",
      },
      exportLoading: false,
      exportFiltered: false,
      showInfo: false,
      totalCount: 0,

      //Market Price
      editingMP: false,

    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },
        methodProductCategories: function () {
            return this.pgsr.map((category) => category.name);
        },
        quoteInstall() {

            
            // TODO - This needs to work off product grouping
            var quoteTotalInstallSell = ''
            quoteTotalInstallSell = this.quote.totalInstallSell

            var totalInstallSell = ''
            // console.log('quoteTotalInstallSell = ' + JSON.stringify(quoteTotalInstallSell, null, 2))
            // console.log('methodProductCategories = ' + JSON.stringify(this.pgsr.productCategory, null, 2))
            // console.log('item = ' + JSON.stringify(this.items, null, 2))

            if (quoteTotalInstallSell === '' || quoteTotalInstallSell === '0') {

                totalInstallSell = this.items
                    .filter((item) => item.lineType === 'Product' && item.lineIncludeTotal === '1')
                    .map((item) => {
                        var IMP;
                        if (item.lineCategory !== '' && item.lineCategory !== 0) {

                            let category = this.pgsr.find((category) => category.name == item.lineCategory)

                            if (category !== undefined) {
                                IMP = Number(category.installation)
                            } else {
                                IMP = 8;
                            }

                        } else {
                            IMP = 8;
                        }
                        var lineInstall = (Number(IMP) / 100) * Number(item.lineTotalSell); //.toFixed(2)
                        return lineInstall;
                    })
                    .reduce((prev, curr) => prev + curr, 0);

                totalInstallSell = Number(totalInstallSell); //.toFixed(2)
            
            } else {
                totalInstallSell = Number(quoteTotalInstallSell)
            }
            
            // console.log('totalInstallSell = ' + totalInstallSell);
            
            if (totalInstallSell < 350) {
                totalInstallSell = 350
            }

            let totalInstallMarginValue = ''
            let totalInstallMarginPercent = ''


            let percentage = ''
            if (this.MCDRate !== "" ) { 
                percentage = ( 100 - Number(this.MCDRate) )
                percentage = Number(percentage) / 100
                totalInstallSell = totalInstallSell / percentage
                // totalInstallSell = totalInstallSell
            }

            let totalInstallCost = ''

            totalInstallCost = totalInstallSell - (10 / 100) * totalInstallSell;



            console.log('totalInstallMarginValue = ' + totalInstallMarginValue);
            console.log('totalInstallSell = ' + totalInstallSell);
            console.log('totalInstallCost = ' + totalInstallCost);


            totalInstallMarginValue = (totalInstallSell - totalInstallCost).toFixed(2)
            console.log('totalInstallMarginValue = ' + totalInstallMarginValue);
            totalInstallMarginPercent = (((totalInstallSell - totalInstallCost) / totalInstallSell) * 100).toFixed(2)
            console.log('totalInstallMarginPercent = ' + totalInstallMarginPercent);

            return {
                totalInstallCost: totalInstallCost.toFixed(2),
                totalInstallSell: totalInstallSell.toFixed(2),
                totalInstallMarginValue: totalInstallMarginValue,
                totalInstallMarginPercent: totalInstallMarginPercent,
            };
        },
        quoteTotal() {
            // console.log('quoteInstall', this.quote.quoteInstall);
            let totalCost = Number(
                this.items
                    .filter((item) => item.lineType === 'Product' && item.lineIncludeTotal === '1')
                    .map((item) => Number(item.lineTotalCost))
                    .reduce((prev, curr) => prev + curr, 0)
            );
            if (this.quote.quoteInstall === '1') {
                totalCost += Number(this.quoteInstall.totalInstallCost);
            }
            let totalSell = ''

            
            totalSell = Number(
                this.items
                    .filter((item) => item.lineType === 'Product' && item.lineIncludeTotal === '1')
                    .map((item) => Number(item.lineTotalSell))
                    .reduce((prev, curr) => prev + curr, 0)
            );
            if (this.quote.quoteInstall === '1') {
                totalSell += Number(this.quoteInstall.totalInstallSell);
            }

            // if MCD, work out total sell of before MCD price, and then work out margins
            let originalTotalSell = ''
            if (this.quote.quoteMCDRate == '1') {
                originalTotalSell = Number(
                    this.items
                        .filter((item) => item.lineType === 'Product' && item.lineIncludeTotal === '1')
                        .map((item) => Number((item.lineSellBeforeMCD * item.lineQty).toFixed(2)))
                        .reduce((prev, curr) => prev + curr, 0)
                );

            }

            let totalSellMargin = '';
            let totalMargin = '';
            let totalItems = '';
            
            if (this.quote.quoteMCDRate === '1') {
                totalSellMargin = Number(originalTotalSell) - Number(totalCost);
                totalMargin = (totalSellMargin / originalTotalSell) * 100;
                totalItems = this.items.length;
            } else {
                totalSellMargin = Number(totalSell) - Number(totalCost);
                totalMargin = (totalSellMargin / totalSell) * 100;
                totalItems = this.items.length;
            }

            return {
                totalItems: totalItems.toFixed(0),
                totalCost: totalCost.toFixed(2),
                totalSell: totalSell.toFixed(2),
                totalSellMargin: totalSellMargin.toFixed(2),
                totalMargin: totalMargin.toFixed(2),
            };
        },
        // Computed Search Query
        computedSearchQuery() {
            let t = this;
            let searchQuery = t.searchQuery;
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (t.toggleDeletedItems) {
                    searchQuery = `* @quoteId:${t.quote.id}`;
                } else {
                    searchQuery = `@deleted:0 @quoteId:${t.quote.id}`;
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (t.toggleDeletedItems) {
                        searchQuery = `${searchQuery}* @quoteId:${t.quote.id}`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0 @quoteId:${t.quote.id}`;
                    }
                } else {
                    if (t.toggleDeletedItems) {
                        searchQuery = `${searchQuery}* @quoteId:${t.quote.id}`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0 @quoteId:${t.quote.id}`;
                    }
                }
            }
            // console.log("searchQuery = " + searchQuery);

            return searchQuery;
        },
        // Computed Items
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.quote = { ...this.datadown };
                if (this.quote.quoteMCDRate == '1') {
                    this.MCDRate = this.quote.quoteMDCDiscount
                }
                // console.log('item = ', JSON.stringify(this.item, null, 2));
                this.getLineItems();
            },
            deep: true,
        },
        refreshlineitems() {
            this.getLineItems();
        },
        addNote: {
            handler: function () {
                var t = this;
                if (!this.addNote) {
                    t.note = {}
                    t.note = { ...t.schema };
                    t.note.quoteId = t.quote.id
                    t.note.id = t.MIX_generateId()
                }
            },
            deep: true,
        },
        approveDialog: {
            handler: function () {
                if (!this.approveDialog) {
                    this.addNote = false
                }
                this.approvalDialogKey++
            },
            deep: true,
        },
    },
    // * METHODS
    methods: {
        getPriceFileChange(id) {
            // var t = this;
            var orgs = this.orgs

            orgs = orgs.filter((item) => {
                return item.id == id
            })
            var priceFileChange = ''
            var now = this.$moment().format('X')
            if (orgs.length > 0) {
                priceFileChange = orgs[0].orgPriceFileChange
            } 
            
            var diffDays = this.$moment(priceFileChange, 'X').diff(this.$moment(now, 'X'), 'days');
            if (diffDays < 14) {
                return this.$moment(priceFileChange, 'X').format('DD-MM-YYYY')
            } else {
                return 'noChange'
            }

        },
        sortLineItems() {
            this.items.sort((a,b) => b.lineAuthRequired.localeCompare(a.lineAuthRequired))
        },
        removeMCD() {
            var t = this;
            t.quote.quoteMCDRate = '0'
            t.quote.quoteMDCDiscount = ''
            for (let i=0; i < t.items.length; i++) {
                
                t.items[i].lineSell = t.items[i].lineSellBeforeMCD
                t.items[i].lineMCDRate = '0'
                t.items[i].lineMCDDiscount = ''
                this.calculateLineItemNew(t.items[i], i, 'lineSell')

            }
            t.MCDRate = ''
        },
        changeMCDRate() {
            // console.log("changing MCDD Rate")
            var t = this;

            t.quote.quoteMCDRate = '1'
            t.quote.quoteMDCDiscount = t.MCDRate
            for (let i=0; i < t.items.length; i++) {
                let costSell = t.items[i].lineSell
                // console.log("lineSellBefore = " + costSell)

                if (t.items[i].lineMCDRate == '0') {
                    t.items[i].lineSellBeforeMCD = costSell
                }

                t.items[i].lineMCDRate = '1'
                t.items[i].lineMCDDiscount = t.MCDRate


                let percentage = (100 - Number(t.MCDRate))
                percentage = Number(percentage) / 100
                // console.log("percentage = " + percentage)
                costSell = costSell / percentage
                // console.log("lineSellAfter = " + costSell)
                costSell = costSell.toFixed(2)


                t.items[i].lineSell = costSell
                this.calculateLineItemNew(t.items[i], i, 'MCDDiscount')
            }
        },
        changeLineMCDRate(index) {
            // console.log("changing Line MCD Rate")
            var t = this;
            let i = index

            let costSell = t.items[i].lineSell
            // console.log("lineSellBefore = " + costSell)

            if (t.items[i].lineMCDRate == '0') {
                t.items[i].lineSellBeforeMCD = costSell
            }
            t.items[i].lineMCDRate = '1'
            // t.items[i].lineMCDDiscount = t.MCDRate


            let percentage = (100 - Number(t.items[i].lineMCDDiscount))
            percentage = Number(percentage) / 100
            // console.log("percentage = " + percentage)
            costSell = costSell / percentage
            // console.log("lineSellAfter = " + costSell)
            costSell = costSell.toFixed(2)

            t.items[i].lineSell = costSell
            this.calculateLineItemNew(t.items[i], i, 'MCDDiscount')
        },
        saveMCDRate() {
            this.MCDDialog = false

        },
        addMCDDialog() {
            var t = this;
            
            t.MCDDialog = true
        },
        closeMarketDialog() {
            var t = this;
            t.editingMP = false
            t.marketPriceItem = {}
            t.showMarketDialog = false
            t.marketPriceDialogKey++
        },
        async showMarketPrice(item) {
            var t = this;
            console.log("market price item = " + JSON.stringify(item, null, 2))

          //grab the product from the product id stored in the line item
          //item.lineParentCode !== undefined && item.lineParentCode !== null && item.lineParentCode !== ''
            if(item.lineParentCodeId !== undefined && item.lineParentCodeId !== null && item.lineParentCodeId !== '') {
              const productFromId = await t.MIX_redisReadHash(`product:`+item.lineParentCodeId);
              console.log(productFromId)
              //productFromId.data.documents.map((x) => x.value);
              t.marketPriceItem = productFromId.data;
            }else if(item.lineCodeId !== undefined && item.lineCodeId !== null && item.lineCodeId !== ''){
              const productFromId = await t.MIX_redisReadHash(`product:`+item.lineCodeId);
              // console.log(productFromId.data.documents[0])
              t.marketPriceItem = productFromId.data;
            }else{
              console.log("No product id found")
            }

            t.showMarketDialog = true

        },
        checkTotalInstallSell() {
            var t = this;

            var totalInstallSell = Number(t.quoteInstall.totalInstallSell)
            // console.log("intsallSell = " + totalInstallSell)
            
            if (totalInstallSell < 350) {
                totalInstallSell = 350
            }
            let totalInstallCost = ''
            totalInstallCost = totalInstallSell - (10 / 100) * totalInstallSell;
            this.quoteInstall.totalInstallCost = totalInstallCost.toFixed(2),
            this.quoteInstall.totalInstallSell = totalInstallSell.toFixed(2),



            this.quoteInstall.totalInstallMarginValue = (totalInstallSell - totalInstallCost).toFixed(2),


            this.quoteInstall.totalInstallMarginPercent = (((totalInstallSell - totalInstallCost) / totalInstallSell) * 100).toFixed(2),
            t.quoteInstallKey++
        },
        enableNPS() {
            // this.updateQuote();
            console.log(JSON.stringify(this.toggleFeatures, null, 2))
            if (this.toggleFeatures.includes('enableNPS')) {
                this.quote.quoteNPS = '0'
            } else {
                this.quote.quoteNPS = '1'
            }
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].lineProductType !== 'Custom') {
                    if (this.quote.quoteNPS === '1') {
                        this.items[i].lineNPSEnable = '1';
                        // this.items[i].lineSell = this.items[i].lineNPS;
                        this.calculateLineItemNew(this.items[i], i, 'lineNPS')
                    } else {
                        this.items[i].lineNPSEnable = '0';
                        // this.items[i].lineSell = this.items[i].lineSellBase;
                        this.calculateLineItemNew(this.items[i], i, 'restore')
                    }
                }
            }
        },
        updateImage() {
            // console.log('updatingImage')
            this.imageItem.lineImageId = this.imageItem.id
        },
        addInstall() {
            this.quote.quoteInstall = '1';
        },
        removeInstall() {
            this.quote.quoteInstall = '0';
        },
        async updateLineItem(item) {
            // console.log('@@@ updateLineItem @@@');
            let t = this;
            // TODO - RG - Add variant Code for Product Selection
            this.dialogOptionsSelector = false;
            item.quoteId = t.quoteid;
            if (item.lineFeatures !== undefined && item.lineFeatures !== null && item.lineFeatures !== '') {
                item.lineFeatures = item.lineFeatures.join(',');
                item.lineFeatures = item.lineFeatures.filter((x) => x !== '');
            }
            let lineItemResult = await t.MIX_createItem(item, 'lineItem', false);
            if (lineItemResult.code === 1) {
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Product added to quote', show: true });
            } else {
                t.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error adding to Quote', show: true });
            }
            // }
            this.getLineItems();
        },
        async approveLineItem(index) {
            var t = this;
            console.log('approving line item ' + index)
            console.log('approving line item ' + JSON.stringify(this.items[index], null, 2))
            
            this.items[index].lineAuthRequired = '0'
            this.items[index].lineAuthorised = t.GET_FIREBASE_userAccessToken.user_id
            console.log('approving line item ' + JSON.stringify(this.items[index], null, 2))
            
            // this.saveLine(index)

            await this.MIX_createItem(this.items[index], 'lineItem', false);

            this.saveLineItems()
        },
        showApprovalDialog(reason) {
            var t = this;

            t.approvalDialog = true
            t.approvalDialogKey++
            t.approvalReason = reason
        },
        closeApprovalDialog() {
            this.approvalDialog = false 
            this.approvalDialogKey++ 
            this.approvalReason = ''
        },
        saveApprovalDialog() {
            var t = this; 
            
            if (t.quote.quoteFDDirectorApprovalStatus == 'Rejected' || t.quote.quoteMDDirectorApprovalStatus == 'Rejected' || t.quote.quoteSalesManagerApprovalStatus == 'Rejected' || t.quote.quoteSupplyChainApprovalStatus == 'Rejected' || t.quote.quoteInstallApprovalStatus == 'Rejected') {
                t.quote.quoteStatus = 'Rejected'
                t.quote.approved = '0'
            }

            // we need to check the approval status of the quote 
            if (t.quoteReadyForApproval && t.quote.quoteInstallApproval == '0' && t.quote.quoteSupplyChainApproval == '0' && t.quote.quoteSalesManagerApproval == '0' && t.quote.quoteMDDirectorApproval == '0' && t.quote.quoteFDDirectorApproval == '0') {
                // we need to set the quote status to approved 
                this.quote.approved = '1';
                this.quote.quoteStatus = 'Ready';
            }
            if (this.addNote) {
                // save note 
                this.saveNote()
            }
            // save quote 
            this.updateQuote()
            this.approveDialog = false
        },
        async saveNote() {
            let result = await this.MIX_createItem(this.note, 'note', true);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                // this.readItem(item.id);
            }
        },
        getUser(userId) {
            var t = this;
            // console.log('getting user ' + userId)
            var user = []
            var userName = ''
            if (userId !== undefined) {
                user = t.users.filter((item) => { return item.id == userId })

                if (user.length !== 0) { userName = user[0].userName }
            }

            return userName

        },
        reject(field) {
            var t = this 

            switch (field) {
                case 'quoteInstallApprovalStatus':
                    t.quote.quoteInstallApproval = '0',
                    t.quote.quoteInstallApprovalStatus = 'Rejected',
                    t.quote.quoteInstallDeniedBy = t.GET_FIREBASE_userAccessToken.user_id                    
                    break;
                case 'quoteSupplyChainApprovalStatus':
                    t.quote.quoteSupplyChainApproval = '0',
                    t.quote.quoteSupplyChainApprovalStatus = 'Rejected',
                    t.quote.quoteSupplyChainDeniedBy = t.GET_FIREBASE_userAccessToken.user_id
                    
                    break;
                case 'quoteSalesManagerApprovalStatus':
                    t.quote.quoteSalesManagerApproval = '0',
                    t.quote.quoteSalesManagerApprovalStatus = 'Rejected',
                    t.quote.quoteSalesManagerDeniedBy = t.GET_FIREBASE_userAccessToken.user_id
                    
                    break;
                case 'quoteMDDirectorApprovalStatus':
                    t.quoteMDDirectorApproval = '0',
                    t.quote.quoteMDDirectorApprovalStatus = 'Rejected',
                    t.quote.quoteMDDirectorDeniedBy = t.GET_FIREBASE_userAccessToken.user_id
                    
                    break;
                case 'quoteFDDirectorApprovalStatus':
                    t.quote.quoteFDDirectorApproval = '0',
                    t.quote.quoteFDDirectorApprovalStatus = 'Rejected',
                    t.quote.quoteFDDirectorDeniedBy = t.GET_FIREBASE_userAccessToken.user_id
                    
                    break;
                default:
                    break;
            }
        },
        approve(field) {
            var t = this 

            switch (field) {
                case 'quoteInstallApprovalStatus':
                    t.quote.quoteInstallApproval = '0',
                    t.quote.quoteInstallApprovalStatus = 'Approved',
                    t.quote.quoteInstallApprovedBy = t.GET_FIREBASE_userAccessToken.user_id                    
                    break;
                case 'quoteSupplyChainApprovalStatus':
                    t.quote.quoteSupplyChainApproval = '0',
                    t.quote.quoteSupplyChainApprovalStatus = 'Approved',
                    t.quote.quoteSupplyChainApprovedBy = t.GET_FIREBASE_userAccessToken.user_id
                    
                    break;
                case 'quoteSalesManagerApprovalStatus':
                    t.quote.quoteSalesManagerApproval = '0',
                    t.quote.quoteSalesManagerApprovalStatus = 'Approved',
                    t.quote.quoteSalesManagerApprovedBy = t.GET_FIREBASE_userAccessToken.user_id
                    
                    break;
                case 'quoteMDDirectorApprovalStatus':
                    t.quote.quoteMDDirectorApproval = '0',
                    t.quote.quoteMDDirectorApprovalStatus = 'Approved',
                    t.quote.quoteMDDirectorApprovedBy = t.GET_FIREBASE_userAccessToken.user_id
                    
                    break;
                case 'quoteFDDirectorApprovalStatus':
                    t.quote.quoteFDDirectorApproval = '0',
                    t.quote.quoteFDDirectorApprovalStatus = 'Approved',
                    t.quote.quoteFDDirectorApprovedBy = t.GET_FIREBASE_userAccessToken.user_id
                    
                    break;
                default:
                    break;
            }
        },
        resetForm() {
            this.quote.quoteSalesManagerApproval = '0'
            this.quote.quoteSalesManagerApprovalStatus = 'N/A',
            this.quote.quoteInstallApprovedBy = '',
            this.quote.quoteInstallDeniedBy = '',
            this.quote.quoteMDDirectorApproval = '0'
            this.quote.quoteMDDirectorApprovalStatus = 'N/A'
            this.quote.quoteSupplyChainApprovedBy = '',
            this.quote.quoteSupplyChainDeniedBy = '',
            this.quote.quoteFDDirectorApproval = '0'
            this.quote.quoteFDDirectorApprovalStatus = 'N/A'
            this.quote.quoteSalesManagerApprovedBy = '',
            this.quote.quoteSalesManagerDeniedBy = '',
            this.quote.quoteSupplyChainApproval = '0'
            this.quote.quoteSupplyChainApprovalStatus = 'N/A'
            this.quote.quoteMDDirectorApprovedBy = '',
            this.quote.quoteMDDirectorDeniedBy = '',
            this.quote.quoteInstallApproval = '0'
            this.quote.quoteInstallApprovalStatus = 'N/A'
            this.quote.quoteFDDirectorApprovedBy = '',
            this.quote.quoteFDDirectorDeniedBy = ''
        },
        ready() {
            var t = this;

            this.resetForm()
            var lowMargin = false
            var sellPriceChanged = false
            var notReady = false
            var customProduct = false

            if (t.submitApprovalDialog == true) {
                t.submitApprovalDialog = false
            }

            for (var i = 0; i < this.items.length; i++) {
                if (Number(this.items[i].lineMarginPercent) < Number(this.items[i].lineMarginPercentBase)) {
                    lowMargin = true
                    if (this.items[i].lineMarginReason === '' || this.items[i].lineMarginComments == '') {
                        // NOT READY TO SUBMIT FOR APPROVAL
                        // console.log('low margin = ' + JSON.stringify(this.items[i], null, 2))
                        this.showApprovalDialog('Low Margin')
                        notReady = true
                    }
                } else {
                    // Do nothing
                }


                if (Number(this.items[i].lineSell) != Number(this.items[i].lineSellBase)) {
                    sellPriceChanged = true
                }


                if (this.items[i].lineProductType == 'Custom') {
                    customProduct = true
                }
            }

            // If the quote is not approved then set to awaiting approval otherwise set to ready
            if (!notReady) {
                this.quote.approved = '1';
                this.quote.quoteStatus = 'Ready';
            }



            // if quote lineTotalSell sell > 25000, approval needed by directors and sales auth team
            if (Number(this.quoteTotal.totalSell) > 25000 && !notReady) {
                this.quote.approved = '0';
                this.quote.quoteStatus = 'Awaiting Approval';

                this.quote.quoteSalesManagerApproval = '1'
                this.quote.quoteSalesManagerApprovalStatus = 'Pending',

                this.quote.quoteMDDirectorApproval = '1'
                this.quote.quoteMDDirectorApprovalStatus = 'Pending'

                this.quote.quoteFDDirectorApproval = '1'
                this.quote.quoteFDDirectorApprovalStatus = 'Pending'


                
            } 
            // if quote has any lineItem changed (different cost or sell price) or if low margin, 
            // approval needed by sales managers
             
            if (lowMargin == true && !notReady || sellPriceChanged == true && !notReady) {
                this.quote.approved = '0';
                this.quote.quoteStatus = 'Awaiting Approval';

                this.quote.quoteSalesManagerApproval = '1'
                this.quote.quoteSalesManagerApprovalStatus = 'Pending'
            } 

             
            // if custom product added, approval needed by supply chain auth team
            if (customProduct == true && !notReady) {
                this.quote.approved = '0';
                this.quote.quoteStatus = 'Awaiting Approval';

                this.quote.quoteSupplyChainApproval = '1'
                this.quote.quoteSupplyChainApprovalStatus = 'Pending'

            }

            // if quote has install, approval needed by install team
            if (t.quote.quoteInstall == '1' && !notReady) {
                this.quote.approved = '0';
                this.quote.quoteStatus = 'Awaiting Approval';

                this.quote.quoteInstallApproval = '1'
                this.quote.quoteInstallApprovalStatus = 'Pending'

            }

           
            
            if (!notReady) { this.$emit('save', this.quote) }
        },
        updateQuote() {
            this.$emit('save', this.quote);
        },
        changeQuoteStatus(status) {
            this.quote.quoteStatus = status;
            this.$emit('save', this.quote);
        },
        approveQuote() {
            this.quote.quoteStatus = 'Ready';
            this.quote.approved = '1';
            this.$emit('save', this.quote);
        },
        openSubmitApprovalDialog() {
            var t = this; 

            this.submitApprovalDialog = true
        },
        openApprovalDialog() {
            var t = this;

            this.approveDialog = true


            t.note = {}
            t.note = { ...t.schema };
            t.note.quoteId = t.quote.id
            t.note.id = t.MIX_generateId()
        },
        openApprovalNoteDialog(level) {
            var t = this;

            if (!this.quoteReadyForApproval) {
                this.showApprovalDialog('Approval')

            } else {
                t.stage = level 

                t.note = {}
                t.note = { ...t.schema };
                t.note.quoteId = t.quote.id
                t.note.id = t.MIX_generateId()
                
                
                // t.note.noteTitle = 
                switch (level) {
                    case 'approveQuoteFirstLevel': 
                        t.note.noteTitle = 'First Level Quote Approval'
                        break;
                    case 'rejectQuoteFirstLevel': 
                        t.note.noteTitle = 'First Level Quote Rejection'
                        break;
                    case 'approveQuoteSecondLevel':
                        t.note.noteTitle = 'Second Level Quote Approval'
                        break;
                    case 'rejectQuoteSecondLevel':
                        t.note.noteTitle = 'Second Level Quote Rejection'
                        break;
                    case 'Sent':
                        t.note.noteTitle = 'Quote Sent to Client'
                        break;
                    case 'Won':
                        t.note.noteTitle = 'Quote Won'
                        break;
                    case 'Lost':
                        t.note.noteTitle = 'Quote Lost'
                        break;
                    default: 
                        t.note.noteTitle = ''
                        break
                }
                
                t.approvalNotesDialogKey++
                t.approvalNotesDialog = true
            }
        },
        closeApprovalNotesDialog() {
            var t = this;

            t.stage = '' 
            t.note = {}
            t.note = { ...t.schema };
            t.approvalNotesDialog = false
            t.approvalNotesDialogKey++
            t.approvalNotes = ''
        },

        async saveApprovalNotes() {
            var t = this;
                this.quote.quoteStatus = t.stage;
                this.$emit('save', this.quote);
            let result = await this.MIX_createItem(this.note, 'note', true);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                // this.readItem(item.id);
            }
            t.closeApprovalNotesDialog()
        },
        changeOptions(item) {
            // check if item is custom product
            if (item.lineType == 'customProduct') {
                this.dialogProductPreviewKey++;
                this.dialogProductPreview = true;
                this.activeLineItem = item;
            } else {
                this.dialogOptionsSelectorKey++;
                this.dialogOptionsSelector = true;
                this.activeLineItem = item;
            }
            this.dialogKey++;
            this.activeLineItem = item;
            this.dialogOptionsSelector = true;
        },
        alertme(value) {
            this.$refs[value].save();
        },
        // On Insert
        onInsert(event) {
            this.items.splice(event.index, 0, event.data);
        },
        async reOrder(event) {
            //$event.apply(items)
            event.apply(this.items);
        },
        // Add Product
        addProduct() {
            this.lineItems.push(JSON.parse(JSON.stringify(this.product)));
        },
        // Add Section
        addSection() {
            this.lineItems.push({
                sectionName: 'Add Section Title',
                lineType: 'section',
                lineBackgroundColour: 'black',
            });
        },
        closeProductPreview() {
            //this.dialogProductPreviewKey++;
            this.dialogOptionsSelector = false;
        },
        // Update Margin
        updateMargin(index) {
            this.items[index].lineSell = ((this.items[index].lineCost / (100 - this.items[index].lineMarginPercent)) * 100).toFixed(2);
            this.calculateLineItem(index);
            // <div class="ml-2 priceblock"><v-text-field suffix="%" :disabled="product.cP1 === null || product.sP1 === null" dense label="New Margin" outlined background-color="white" hide-details="auto" @change="product.sP1 = ((product.cP1 / (100 - $event)) * 100).toFixed(2)"></v-text-field></div>
        },
        // Calculate Line Item
        async calculateLineItemNew(item, index, fieldChanged) {
            // get fieldsChanged from item as set
            var fieldsChanged;
            console.log('item.fieldsChanged = ', JSON.stringify(item.fieldsChanged, null, 2));
            if (item.fieldsChanged === null || item.fieldsChanged === undefined) {
                fieldsChanged = [];
            } else {
                fieldsChanged = item.fieldsChanged.split(',');
            }
            console.log('fieldChanged = ', JSON.stringify(fieldChanged));
            if (fieldChanged !== 'restore') {
                fieldsChanged.push(fieldChanged);
            } else if (fieldChanged === 'restore' && fieldsChanged.includes('lineNPS')) {
                fieldsChanged = ['lineNPS'];
            } else {
                fieldsChanged = [];
            }
            // if (this.quote.quoteNPS === '1') {
            //     fieldsChanged.push ('NPS');
            // }
            console.log('fieldsChanged = ', JSON.stringify(fieldsChanged));
            item.fieldsChanged = Array.from(new Set(fieldsChanged))
                .filter((x) => x !== '')
                .join(',');
            let lineItemResult = await this.CALC_lineItemUpdate(item, fieldChanged); // new line item
            this.items[index].fieldsChanged = lineItemResult.fieldsChanged;
            this.items[index].lineRRP = lineItemResult.lineRRP;
            this.items[index].lineRRPBase = lineItemResult.lineRRPBase;
            this.items[index].lineMOQ = lineItemResult.lineMOQ;
            this.items[index].lineQty = lineItemResult.lineQty;
            this.items[index].lineCost = lineItemResult.lineCost;
            this.items[index].lineCostBase = lineItemResult.lineCostBase;
            this.items[index].lineNPS = lineItemResult.lineNPS;
            this.items[index].lineNPSEnable = lineItemResult.lineNPSEnable;
            this.items[index].lineSell = lineItemResult.lineSell;
            this.items[index].lineSellBase = lineItemResult.lineSellBase;
            this.items[index].lineTotalCost = lineItemResult.lineTotalCost;
            this.items[index].lineTotalSell = lineItemResult.lineTotalSell;
            this.items[index].lineMarginValue = lineItemResult.lineMarginValue;
            this.items[index].lineMarginValueBase = lineItemResult.lineMarginValueBase;
            this.items[index].lineMarginPercent = lineItemResult.lineMarginPercent;
            this.items[index].lineMarginPercentBase = lineItemResult.lineMarginPercentBase;
            this.items[index].lineTotal = lineItemResult.lineTotal;
            this.items[index].lineDiscount = lineItemResult.lineDiscount;
            this.items[index].lineTotalMarginValue = lineItemResult.lineTotalMarginValue;
            this.items[index].lineTotalMarginPercent = lineItemResult.lineTotalMarginPercent;
            this.items[index].lineAuthRequired = lineItemResult.lineAuthRequired;
            // console.log("lineItemResult = ", JSON.stringify(lineItemResult, null, 2));
        },
        calculateLineItem(index) {
            this.items[index].lineTotalCost = (Number(this.items[index].lineCost) * Number(this.items[index].lineQty)).toFixed(2).toString();
            this.items[index].lineTotalSell = (Number(this.items[index].lineSell) * Number(this.items[index].lineQty)).toFixed(2).toString();
            this.items[index].lineMarginValue = (Number(this.items[index].lineSell) - Number(this.items[index].lineCost)).toFixed(2).toString();
            this.items[index].lineMarginPercent = ((Number(this.items[index].lineMarginValue) / Number(this.items[index].lineSell)) * 100).toFixed(1).toString();
            // B11-((C11/100)*B11)
            if (this.items[index].lineMCDRate !== '0' && this.items[index].lineMCDRate !== '') {
                let lineTotal = (Number(this.items[index].lineTotalSell) - (Number(this.items[index].lineDiscount) / 100) * Number(this.items[index].lineTotalSell)).toFixed(2);
                lineTotal = ((lineTotal / (100 - Number(this.items[index].lineMCDRate))) * 100).toFixed(2);
                this.items[index].lineTotal = lineTotal.toString();
            } else {
                if (this.items[index].lineDiscount !== '0') {
                    this.items[index].lineTotal = (Number(this.items[index].lineTotalSell) - (Number(this.items[index].lineDiscount) / 100) * Number(this.items[index].lineTotalSell)).toFixed(2).toString();
                } else {
                    this.items[index].lineTotal = this.items[index].lineTotalSell;
                }
            }
            this.items[index].lineTotalMarginValue = (Number(this.items[index].lineTotal) - Number(this.items[index].lineTotalCost)).toFixed(2).toString();
            this.items[index].lineTotalMarginPercent = ((Number(this.items[index].lineTotalMarginValue) / Number(this.items[index].lineTotal)) * 100).toFixed(1).toString();
            //this.items[index].lineMargin = (this.items[index].totall);
        },
        // Get Line Items
        async getLineItems() {
            // console.log("getLineItems Running");
            //				console.log("index: " + this.index + "\n computedSearchQuery: " + this.computedSearchQuery + "\n itemsFrom: " + this.itemsFrom + "\n itemsTo: " + this.itemsTo);
            //GET http://localhost/api/v1/orderedsearch?index=idx:lineItems&searchquery=*&from=0&size=9999&sortfield=lineOrder
            let itemsResult = await this.MIX_redisOrderedSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.itemsTo, 'lineOrder');
            // console.log("itemsResult = " + JSON.stringify(itemsResult, null, 2));
          console.log(itemsResult)
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                //This fixes the line items shifting around due to redis not sorting properly
                this.items = itemsResult.data.documents.map((x) => x.value).sort((a,b) => a.lineOrder - b.lineOrder)
                for (var i = 0; i < this.items.length; i++) {
                    if (this.items[i].lineFeatures !== undefined && this.items[i].lineFeatures !== null && this.items[i].lineFeatures !== '') {
                        this.items[i].lineFeatures = this.items[i].lineFeatures.split(',');
                        this.items[i].lineFeatures = this.items[i].lineFeatures.filter((x) => x !== '');
                    }
                }
                await this.checkCostPrice() 
                this.checkApproval()
            }
        },
        // Edit Line
        editLine(index) {
            this.items[index].lineEdit = '1';
            //console.log('this.items[index].orgId=',this.items[index].orgId)
        },
        extendLine(index, action) {
            if (action === 'Open') {
                this.items[index].lineExtend = '1';
            } else {
                this.items[index].lineExtend = '0';
            }
        },
        editLineImage(index) {
            // this.items[index].imageEdit = '1'

            this.imageItem = this.items[index]
            this.editImageDialog = true
        },
        cancelImageItem() {
            this.imageItem = {}
            this.editImageDialog = false
        },
        draft() {

            this.quote.quoteStatus = 'Draft'
            this.quote.approved = '0';
            this.quote.quoteSalesManagerApproval = '0'
            this.quote.quoteSalesManagerApprovalStatus = 'N/A',
            this.quote.quoteMDDirectorApproval = '0'
            this.quote.quoteMDDirectorApprovalStatus = 'N/A'
            this.quote.quoteFDDirectorApproval = '0'
            this.quote.quoteFDDirectorApprovalStatus = 'N/A'
            this.quote.quoteSupplyChainApproval = '0'
            this.quote.quoteSupplyChainApprovalStatus = 'N/A'
            this.quote.quoteInstallApproval = '0'
            this.quote.quoteInstallApprovalStatus = 'N/A'
            this.$emit('save', this.quote);      
        },
        editLineItems() {
            // console.log('editLineItems');
            // if (['Awaiting Approval', 'Ready'].includes(this.quote.quoteStatus)) {
            //     (this.quote.quoteStatus = 'Draft'), 
            //     this.quote.approved = '0';
            //     this.quote.quoteApprovalLevel = ''
            //     this.quote.quoteFirstLevelStatus = ''
            //     this.quote.quoteFirstLevelApprovedBy = ''
            //     this.quote.quoteFirstLevelDeniedBy = ''
            //     this.quote.quoteSecondLevelStatus = ''
            //     this.quote.quoteSecondLevelApprovedBy = ''
            //     this.quote.quoteSecondLevelDeniedBy = ''
            // }
            if (this.quote.quoteStatus === 'Rejected') {
                this.quote.quoteStatus = 'Draft'
                this.quote.approved = '0';
                this.quote.quoteSalesManagerApproval = '0'
                this.quote.quoteSalesManagerApprovalStatus = 'N/A',
                this.quote.quoteMDDirectorApproval = '0'
                this.quote.quoteMDDirectorApprovalStatus = 'N/A'
                this.quote.quoteFDDirectorApproval = '0'
                this.quote.quoteFDDirectorApprovalStatus = 'N/A'
                this.quote.quoteSupplyChainApproval = '0'
                this.quote.quoteSupplyChainApprovalStatus = 'N/A'
                this.quote.quoteInstallApproval = '0'
                this.quote.quoteInstallApprovalStatus = 'N/A'
            }
            this.$emit('save', this.quote);                                                      
            this.editmode = true;
            this.$emit('showproducts');
        },
        async saveLineItems() {
            let i;
          console.log('saveLineItems');
            console.log(this.items)
            this.editmode = false;

            //check that lineIncludeTotal = 1 in at least one item
            const lineIncludeTotal = this.items.filter((x) => x.lineIncludeTotal === '1');
            if(lineIncludeTotal.length === 0) {
                this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'at least one option must be included in total', show: true });
                return
            }

            // if status == awaiting approval or ready, chech auth
            if (['Awaiting Approval', 'Ready'].includes(this.quote.quoteStatus)) {
                this.ready()
            }

            // Save Line Order
            for (i = 0; i < this.items.length; i++) {
                // console.log('this.items[i] = ' + JSON.stringify(this.items[i], null, 2))
                this.items[i].lineEdit = '0';
                this.items[i].lineExtend = '0';
                this.items[i].lineOrder = i;
                await this.MIX_createItem(this.items[i], 'lineItem', false);
            }
            this.$emit('showquotes');
            // remove blank items from floorTags array
            this.floorTags.forEach((item, index) => {
                if (item === '') {
                    this.floorTags.splice(index, 1);
                }
            });
            this.quote.quoteFloorTags = this.floorTags.join(',');
            // remove blank items from roomTags array
            this.roomTags.forEach((item, index) => {
                if (item === '') {
                    this.roomTags.splice(index, 1);
                }
            });
            this.quote.quoteRoomTags = this.roomTags.join(',');

            // remove blank items from optionalTags array
            this.optionalTags.forEach((item, index) => {
                if (item === '') {
                    this.optionalTags.splice(index, 1);
                }
            });
            this.quote.quoteOptionalTags = this.optionalTags.join(',');
            if (this.quote.quoteInstall === '1') {
                this.quote.totalInstallCost = this.quoteInstall.totalInstallCost;
                this.quote.totalInstallSell = this.quoteInstall.totalInstallSell;
                this.quote.totalInstallMarginValue = this.quoteInstall.totalInstallMarginValue;
                this.quote.totalInstallMarginPercent = this.quoteInstall.totalInstallMarginPercent;
            } else {
                this.quote.totalInstallCost = 0;
                this.quote.totalInstallSell = 0;
                this.quote.totalInstallMarginValue = 0;
                this.quote.totalInstallMarginPercent = 0;
            }
            (this.quote.totalItems = this.quoteTotal.totalItems.toString()), (this.quote.totalCost = this.quoteTotal.totalCost.toString()), (this.quote.totalSell = this.quoteTotal.totalSell.toString()), (this.quote.totalSellMargin = this.quoteTotal.totalSellMargin.toString()), (this.quote.totalMargin = this.quoteTotal.totalMargin.toString()), console.log(this.quote);
            this.$emit('save', this.quote);
            this.checkApproval()
        },
        async saveImageItem() {
            if (this.replaceProductImage === true) {
                // console.log('replace product image') 
                this.replacingProductImage() 
            } 
            if (this.replaceVariantImage === true) {
                // console.log('replace Variant image')
                this.replacingVariantImage()
            }

            // console.log('saving image')
            await this.MIX_createItem(this.imageItem, 'lineItem', false);

            this.$emit('save', this.quote)
            this.editImageDialog = false
        },
        async replacingProductImage() {
            var t = this;

            var productId = ''
            if (this.imageItem.lineParentCodeId !== '') {
                productId = this.imageItem.lineParentCodeId
            } else {
                productId = this.imageItem.lineCodeId
            }

            // get product 
            let productResult = await this.MIX_readItem(productId, 'product');
            var product = productResult.data;
            // console.log("productResult = " + JSON.stringify(product, null, 2))
            
            // if product, replace image
            if (product.productImageId) {
                // console.log("changing product image")
                product.productImageId = this.imageItem.lineImageId
                // console.log("product image changed = " + JSON.stringify(product, null, 2))
                // save
                await this.MIX_createItem(product.id, 'product', false);
            }


        },
        async replacingVariantImage() {
            var t = this;

            var variantId = this.imageItem.lineCodeId

            // get product
            let variantResult = await this.MIX_readItem(variantId, 'variant');
            var variant = variantResult.data;
            // console.log("variantResult = " + JSON.stringify(variant, null, 2))
            
            // if product, replace image
            if (variant.productImageId) {
                // console.log("changing variant image")
                variant.variantImageId = this.imageItem.lineImageId
                // console.log("variant image changed = " + JSON.stringify(variant, null, 2))
                // save
                await this.MIX_createItem(variant.id, 'variant', false);
            }
            
        },
        async saveLine(index) {
            this.items[index].lineEdit = '0';
            this.items[index].lineExtend = '0';
            await this.calculateLineItemNew(this.items[index],index, 'lineQty');
            await this.MIX_createItem(this.items[index], 'lineItem', false);
            // TODO - RG - Add Save to Redis
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getLineItems();
            }
        },
        checkTBC: function (item) {
            let tbcCheck = 0;
            if (item.optionValue1 === 'TBC') {
                tbcCheck++;
            }
            if (item.optionValue2 === 'TBC') {
                tbcCheck++;
            }
            if (item.optionValue3 === 'TBC') {
                tbcCheck++;
            }
            if (item.optionValue4 === 'TBC') {
                tbcCheck++;
            }
            if (item.optionValue5 === 'TBC') {
                tbcCheck++;
            }
            if (item.optionValue6 === 'TBC') {
                tbcCheck++;
            }
            if (item.optionValue7 === 'TBC') {
                tbcCheck++;
            }
            if (item.optionValue8 === 'TBC') {
                tbcCheck++;
            }
            if (item.optionValue9 === 'TBC') {
                tbcCheck++;
            }
            if (item.optionValue10 === 'TBC') {
                tbcCheck++;
            }
            if (tbcCheck === 0) {
                return false;
            } else {
                return true;
            }
        },
        // async readOptions(orgId) {
        // 	console.log("orgId = " + orgId)
        // 	let result = await this.MIX_redisSearch("idx:options", `@deleted:0 @enabled:1 @orgId:${orgId}`, 0, 9999);
        // 	return result.data.documents.map((x) => {
        // 		return {
        // 			id: x.value.id,
        // 			optionName: x.value.optionName,
        // 			optionVariant: x.value.optionVariant
        // 		};
        // 	});
        // },
        // async readAttributes(orgId) {
        // 	console.log("orgId = " + orgId)
        // 	let result = await this.MIX_redisSearch("idx:attributes", `@deleted:0 @enabled:1 @orgId:${orgId}`, 0, 9999);
        // 	return result.data.documents.map((x) => {
        // 		return {
        // 			id: x.value.id,
        // 			attName: x.value.attName,
        // 			optionId: x.value.optionId,
        // 			optionName: x.value.optionName,
        // 			attParentId: x.value.attParentId,
        // 		};
        // 	});
        // },
        addcommas(x) {
            //remove commas
            return x ? parseFloat(x.replace(/,/g, '')) : 0;
        },
        formatTag: function (item) {
            if (item !== null) {
                return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            } else {
                return '';
            }
        },
        // Update Quote Floor Tags
        updateQuoteFloorTags(tag) {
            // If Tag does not exist in array then add it
            if (this.floorTags.indexOf(tag) === -1) {
                this.floorTags.push(tag);
            }
            // Remove any blank tags from array
            this.floorTags.forEach((item, index) => {
                if (item === '') {
                    this.floorTags.splice(index, 1);
                }
            });
        },
        // Update Quote Room Tags
        updateQuoteRoomTags(tag) {
            // If Tag does not exist in array then add it
            if (this.roomTags.indexOf(tag) === -1) {
                this.roomTags.push(tag);
            }
            // Remove any blank tags from array
            this.roomTags.forEach((item, index) => {
                if (item === '') {
                    this.roomTags.splice(index, 1);
                }
            });
        },
        updateQuoteOptionalTags(tag) {
            // If Tag does not exist in array then add it 
            if (this.optionalTags.indexOf(tag) === -1) {
                this.optionalTags.push(tag);
            }
            // Remove any blank tags from array 
            this.optionalTags.forEach((item, index) => {
                if (item === '') {
                    this.optionalTags.splice(index, 1)
                }
            })
        },
        async checkCostPrice() {
            var t = this;
            var quote = t.quote
            
            if ( quote.quoteStatus === 'Awaiting Approval' || quote.quoteStatus === 'Draft') {
                for (var i=0; i < t.items.length; i++) {
                    var item = t.items[i]

                    var costBase = item.lineCostBase
                    var costPrice = item.lineCost

                    if (costBase !== costPrice) {
                        item.lineCost = item.lineCostBase

                        await this.MIX_createItem(item, 'lineItem', false);
                    }
                }
            }
            
        },
        checkApproval() {
            var t = this;

            t.quoteReadyForApproval = true
            for (var i=0; i < this.items.length; i++) {

                if (this.items[i].lineAuthRequired == '1') {
                    t.quoteReadyForApproval = false
                }

            }

            // if (t.quote.quoteInstall === '1') {
            //     t.quoteReadyForApproval = false
            // }
        },
		async duplicateLine(item) {
      // await this.saveLineItems();
			let newLineItem = item;
			// console.log("item = " + JSON.stringify(item, null, 2))
			newLineItem.id = this.MIX_generateId();
			// console.log("newLineItem = " + JSON.stringify(newLineItem, null, 2))
			let lineItemResult = await this.MIX_createItem(newLineItem, 'lineItem', true);
            if (lineItemResult.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Product duplicated', show: true });
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error duplicating product', show: true });
            }
			this.getLineItems();
			// newLineItem.id = null;
		},
      // Export Box CSV
      async exportCSV(itemsFrom, itemsTo, filter, specific) {
        // console.table({ itemsFrom, itemsTo, filter, specific });
        if (itemsFrom > itemsTo) {
          this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
        } else {
          this.exportLoading = true;
          var itemsCount, tableData, csv, query, exportFileName;
          switch (true) {
              // Current Page / Current Total (filtered)
            case filter && !specific:
              itemsCount = itemsTo - itemsFrom;
              query = this.computedSearchQuery;
              break;
              // All (Not filtered)
            case !filter && !specific:
              itemsCount = this.totalCount;
              query = `*`;
              break;
              // Specific (filtered/ not filtered)
            case specific:
              itemsFrom = itemsFrom - 1;
              itemsCount = itemsTo - itemsFrom;
              if (this.exportFiltered) {
                query = this.computedSearchQuery;
              } else {
                query = `*`;
              }
              break;
          }
          // console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
          // let exportResult = await this.REDIS_searchFor("animal", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
          // console.log(query)
          // console.log(itemsCount)
          //this.MIX_redisOrderedSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.itemsTo, 'lineOrder');
          let exportResult = await this.MIX_redisOrderedSearch(this.index, this.computedSearchQuery, itemsFrom, itemsTo, 'lineOrder');
          // console.log(exportResult)
          tableData = JSON.parse(JSON.stringify(exportResult.data.documents.map((x) => x.value)));
          const headersToRemove = ["lineEdit", "deletedUserName", "deleted", "id", "productChannel", "lineChannel","key","createdUserId", "lineCodeId", "lineParentCodeId", "createdUserName","deletedUserEmail", "deletedUserId", "modifiedUserEmail", "quoteid", "orgName", "lineImageId","createdDateTime", "orgId", "modifiedDateTime", "modifiedUserName", "modifiedUserId", "createdUserEmail", "deletedDateTime", "lineSOPType"]
          for(let header in headersToRemove){
            Reflect.deleteProperty(tableData[0], headersToRemove[header])
          }
          console.log(tableData)
          csv = this.$papa.unparse(tableData);
          if (itemsFrom === 0) {
            itemsFrom = 1; // This is just for filename purposes
          }
          if (itemsFrom >= 10) {
            itemsFrom = itemsFrom + 1;
          }
          switch (true) {
            case !specific && filter:
              exportFileName = `Quote_LineItems_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case !specific && !filter:
              exportFileName = `Quote_LineItems_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && this.exportFiltered:
              exportFileName = `Quote_LineItems_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
              break;
            case specific && !this.exportFiltered:
              exportFileName = `Quote_LineItems_Specific_${itemsFrom}_to_${itemsTo}`;
              break;
          }
          // console.log(exportFileName);
          // console.log(csv.length);
          this.$papa.download(csv, exportFileName);
          this.exportLoading = false;
        }
      },
      async getTotal(){
          //this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.itemsTo, '@lineCode @lineCodeId @lineTotalCost', this.sortBy, false);
        let totalResult = await this.MIX_redisOrderedSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.itemsTo, 'lineOrder');
        // console.log(totalResult.data.documents)
        // console.log(JSON.stringify(totalResult.data.documents.map((x) => x.value)))
        this.totalCount = totalResult.data.total;
      },
      async getPGSR() {
        // console.log('getItems running...');
        let itemsResult = await this.MIX_redisReadString('PGSR');
        // console.log('itemsResult = ' + JSON.stringify(itemsResult, null, 2));
        if (itemsResult.code === 1) {
          var items = JSON.parse(itemsResult.data);
          this.pgsr = items['productCategory'];
          // console.log('this.items = ' + JSON.stringify(this.items, null, 2));
        }
      },
      //save and close market dialog
      async saveMarketDialog(){
        //this.marketPriceItem
        let result = await this.MIX_createItem(this.marketPriceItem, 'product', false);
        if (result.code === 1) {
          this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
          this.closeMarketDialog();
        }else{
          this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error saving Market Price', show: true });
        }

      },
    },
    async created() {
        let t = this;
        // TODO - RG - Add Loader
        this.quote = { ...this.datadown };
        this.schema = schema.note
      await this.getTotal();
        t.getPGSR()
        if (this.quote.quoteMCDRate == '1') {
            this.MCDRate = this.quote.quoteMDCDiscount
        }
        if (this.quote.quoteFloorTags !== '' && this.quote.quoteFloorTags !== null && this.quote.quoteFloorTags !== undefined) {
            this.floorTags = this.quote.quoteFloorTags.split(',');
        }
        if (this.quote.quoteRoomTags !== '' && this.quote.quoteRoomTags !== null && this.quote.quoteRoomTags !== undefined) {
            this.roomTags = this.quote.quoteRoomTags.split(',');
        }
        if (this.quote.quoteOptionalTags !== '' && this.quote.quoteOptionalTags !== null && this.quote.quoteOptionalTags !== undefined) {
            this.optionalTags = this.quote.quoteOptionalTags.split(',')
        }

        // Get Local Authority
        let productFeaturesResult = await this.MIX_redisSearch('idx:productFeatures', `*`, 0, 9999);
        t.productFeatures = productFeaturesResult.data.documents.map((x) => x.value);


        let orgResult = await this.MIX_redisSearch('idx:orgs', `*`, 0, 9999);
        t.orgs = orgResult.data.documents.map((x) => x.value);

        let usersResult = await this.MIX_redisSearch('idx:users', `*`, 0, 9999);
        t.users = usersResult.data.documents.map((x) => x.value);

        this.getLineItems();
        this.checkApproval()
    },
};
</script>
<style scoped lang="scss">
.wrapper {
    .list {
        border: 1px solid black;
        margin: 100px auto;
        width: 200px;

        .item {
            padding: 20px;
            margin: 10px;
            background-color: rgb(220, 220, 255);
            display: flex;
            align-items: center;
            justify-content: center;

            &.feedback {
                background-color: rgb(255, 220, 220);
                border: 2px dashed black;
            }

            &.drag-image {
                background-color: rgb(220, 255, 220);
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>

<style scoped>
.bottom {
    position: fixed;
    bottom: 0px !important;
}
.priceblock {
    flex: 1;
}
</style>
