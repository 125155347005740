import Vue from "vue";
import App from "@/App.vue";
import router from "@/router.js";
import vuetify from "@/vuetify.js";
import store from "@/store.js";
import axios from "axios";
import moment from "moment";
import "animate.css";
import { saveAs } from 'file-saver';


//* GOOGLE MAPS
import * as VueGoogleMaps from "vue2-google-maps";
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

Vue.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyDixX6ZmVfJCu2e2vdGwOGQwv2i1uEXna0",
      libraries: "places",
    },
});

Vue.use(VuetifyGoogleAutocomplete, {
    /*
      not used as loaded with component
      apiKey: key,
    */
    vueGoogleMapsCompatibility: true,
});


// * GLOBAL MIXIN
import mixin from "./mixin.js";
Vue.use(mixin);

// * CALCULATE MIXIN
import calculate from "./calculate.js";
Vue.use(calculate);

//* FIREBASE INTEGRATION
const firebase = require("@/firebase/firebase-config.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebase_auth_mixin from "@/firebase/firebase_auth_mixin.js"; //
Vue.use(firebase_auth_mixin);
import firebase_firestore_mixin from "@/firebase/firebase_firestore_mixin.js"; //
Vue.use(firebase_firestore_mixin);

//* REDIS INTEGRATION
import redis_mixin from "@/redis/redis_mixin.js"; //
Vue.use(redis_mixin);

//* BASE COMPONENTS
// alertBox Component - Requires alertBox entry in store.js and mixin.js
import alertBox from "@/views/base/alertBox.vue";
Vue.component("alertbox", alertBox);
// appupdate Component - using service worker
require('./components/appupdate/appupdate_import.js');
import FileUpload from "@/components/fileupload/FileUpload";
Vue.component("FileUpload", FileUpload);
import confirmDialog from "@/views/base/confirmDialog.vue";
Vue.component("confirm-dialog", confirmDialog);
// Image Upload
import ImageUpload from "@/components/imageupload/ImageUpload";
Vue.component("ImageUpload", ImageUpload);
// Copyright
import appCopyright from "@/views/base/appCopyright.vue";
Vue.component("appCopyright", appCopyright);
// * CSV Import Component
import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse)

// * TEMPORARY DEVELOPMENT ONLY
import { LoremIpsum } from "lorem-ipsum";
const lorem = new LoremIpsum({
	sentencesPerParagraph: {
		max: 8,
		min: 4,
	},
	wordsPerSentence: {
		max: 16,
		min: 4,
	},
});
Vue.prototype.$lorem = lorem;

// GLOBAL PROTOTYPES
Vue.prototype.$saveAs = saveAs;
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;


// Icons8 CSS Import
import "@/assets/css/styles.min.css";

Vue.config.productionTip = false;

// //* Load user auth object to vuex
// import { doc, getDoc } from "firebase/firestore";

import './registerServiceWorker'
firebase.auth.onAuthStateChanged(async (user) => {
	if (user) {
		await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", user);

		if (user !== null) {
			user.getIdToken(true).then(async (idToken) => {
				var token = JSON.parse(new Buffer(idToken.split('.')[1], 'base64').toString('utf8'));
				// console.log("token" + JSON.stringify(token, null, 2))
				await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAccessToken", token);
			})
		}
	}
});

new Vue({
	router,
	vuetify,
	store,
	render: (h) => h(App),
}).$mount("#app");
