<template>
	<div>
		<!-- Menu Buttons -->
        <v-row dense style="height: 60px; min-height: 60px; max-height: 60px;">
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                    <div class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <div class="ml-3" v-if="!loading">
                    <v-btn class="grey lighten-1 secondary--text font-weight-bold" v-if="editmode" depressed @click="editmode = false"> <v-icon class="mr-2">icons8-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>       				
                <v-spacer />
				<!-- HEADING AND BUTTONS -->
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="grey lighten-5 secondary--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">icons8-edit</v-icon>Edit</v-btn>
                    <v-btn text class="accent secondary--text" v-if="editmode" @click="saveProduct"><v-icon class="mr-2">icons8-save</v-icon>Save</v-btn>
				</div>					
            </v-col>
        </v-row>  
		<v-row class="pa-1" no-gutters>
			<v-col cols="12" sm="12">
				<!-- ITEM JSON -->
				<!-- <v-row v-if="toggleJSON">
					<v-col cols="12">
						<pre class="caption">{{ item }}</pre>
					</v-col>
				</v-row> -->
				<!-- OPTIONS FORM -->
				<v-row v-if="editmode" class="rounded grey lighten-5 rounded mt-3" dense>
					<v-col cols="12" sm="12" class="pa-3">
						<div class="font-weight-light body-1 mb-4">Use the table and form below to manage options for this Supplier. These options will be available to build products from this supplier.</div>
						<!-- Option 1 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName1Autocomplete" @change="optionName1Autocomplete = ''"  auto-select-first full-width label="Option Name 1" v-model="item.optionName1" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName1 === ''" @click="item.optionName1 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-autocomplete auto-select-first :search-input.sync="optionAttributes1Autocomplete" @change="optionAttributes1Autocomplete = ''" full-width :disabled="item.optionName1 === ''" label="Option 1 Attributes" v-model="selection.optionAttributes1" :items="filteredAttributes(item.optionName1)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName1 === ''" @click="selectAllAttributes(1, item.optionName1)" color="primary">icons8-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName1 === '' || JSON.stringify(selection.optionAttributes1) === '[]'" @click="selection.optionAttributes1 = []">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionShow1">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow1 = false;
											(item.optionName1 = ''), (item.optionDependancy1 = ''), (selection.optionAttributes1 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<!-- <v-row class="my-3" dense v-if="!item.optionShow1">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow1 = true">+ Option 1</v-btn></v-col>
						</v-row> -->
						<!-- Option 2 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName2Autocomplete" @change="optionName2Autocomplete = ''" auto-select-first full-width label="Option Name 2" v-model="item.optionName2" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName2 === ''" @click="item.optionName2 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionAttributes2Autocomplete" @change="optionAttributes2Autocomplete = ''" auto-select-first  full-width :disabled="item.optionName2 === ''" label="Option 2 Attributes" v-model="selection.optionAttributes2" :items="filteredAttributes(item.optionName2)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName2 === ''" @click="selectAllAttributes(2, item.optionName2)" color="primary">icons8-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName2 === '' || JSON.stringify(selection.optionAttributes2) === '[]'" @click="selection.optionAttributes2 = []">mdi-close</v-icon></div>
							</v-col>
							<v-col cols="3" class="d-flex align-center justify-stretch">
								<v-select :disabled="item.optionName2 === ''" label="Option Dependancy" v-model="item.optionDependancy2" :items="checkOptionDependancy(item.optionName2)" item-text="optionName" item-value="option" outlined dense hide-details="auto" background-color="white" color="primary"></v-select>
								<div class="ml-2"><v-icon :disabled="item.optionName2 === '' || item.optionDependancy2 === ''" @click="item.optionDependancy2 = ''">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionName1 !== '' && item.optionShow2">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow2 = false;
											(item.optionName2 = ''), (item.optionDependancy2 = ''), (selection.optionAttributes2 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<!-- <v-row class="my-3" dense v-if="item.optionName1 !== '' && !item.optionShow2">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow2 = true">+ Option 2</v-btn></v-col>
						</v-row> -->
						<!-- Option 3 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName3Autocomplete" @change="optionName3Autocomplete = ''" auto-select-first full-width label="Option Name 3" v-model="item.optionName3" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName3 === ''" @click="item.optionName3 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionAttributes3Autocomplete" @change="optionAttributes3Autocomplete = ''" auto-select-first full-width :disabled="item.optionName3 === ''" label="Option 3 Attributes" v-model="selection.optionAttributes3" :items="filteredAttributes(item.optionName3)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName3 === ''" @click="selectAllAttributes(3, item.optionName3)" color="primary">icons8-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName3 === '' || JSON.stringify(selection.optionAttributes3) === '[]'" @click="selection.optionAttributes3 = []">mdi-close</v-icon></div>
							</v-col>
							<v-col cols="3" class="d-flex align-center justify-stretch">
								<v-select :disabled="item.optionName3 === ''" label="Option Dependancy" v-model="item.optionDependancy3" :items="checkOptionDependancy(item.optionName3)" item-text="optionName" item-value="option" outlined dense hide-details="auto" background-color="white" color="primary"></v-select>
								<div class="ml-2"><v-icon :disabled="item.optionName3 === '' || item.optionDependancy3 === ''" @click="item.optionDependancy3 = ''">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionName2 !== '' && item.optionShow3">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow3 = false;
											(item.optionName3 = ''), (item.optionDependancy3 = ''), (selection.optionAttributes3 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<!-- <v-row class="my-3" dense v-if="item.optionName2 !== '' && !item.optionShow3">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow3 = true">+ Option 3</v-btn></v-col>
						</v-row> -->
						<!-- Option 4 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName4Autocomplete" @change="optionName4Autocomplete = ''" auto-select-first full-width label="Option Name 4" v-model="item.optionName4" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName4 === ''" @click="item.optionName4 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionAttributes4Autocomplete" @change="optionAttributes4Autocomplete = ''" auto-select-first full-width :disabled="item.optionName4 === ''" label="Option Attributes 4" v-model="selection.optionAttributes4" :items="filteredAttributes(item.optionName4)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName4 === ''" @click="selectAllAttributes(4, item.optionName4)" color="primary">icons8-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName4 === '' || JSON.stringify(selection.optionAttributes4) === '[]'" @click="selection.optionAttributes4 = []">mdi-close</v-icon></div>
							</v-col>
							<v-col cols="3" class="d-flex align-center justify-stretch">
								<v-autocomplete auto-select-first :disabled="item.optionName4 === ''" label="Option Dependancy" v-model="item.optionDependancy4" :items="checkOptionDependancy(item.optionName4)" item-text="optionName" item-value="option" outlined dense hide-details="auto" background-color="white" color="primary"></v-autocomplete>
								<div class="ml-2"><v-icon :disabled="item.optionName4 === '' || item.optionDependancy4 === ''" @click="item.optionDependancy4 = ''">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionName3 !== '' && item.optionShow4">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow4 = false;
											(item.optionName4 = ''), (item.optionDependancy4 = ''), (selection.optionAttributes4 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<!-- <v-row class="my-3" dense v-if="item.optionName3 !== '' && !item.optionShow4">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow4 = true">+ Option 4</v-btn></v-col>
						</v-row> -->
						<!-- Option 5 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName5Autocomplete" @change="optionName5Autocomplete = ''" auto-select-first full-width label="Option Name 5" v-model="item.optionName5" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName5 === ''" @click="item.optionName5 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionAttributes5Autocomplete" @change="optionAttributes5Autocomplete = ''" auto-select-first full-width :disabled="item.optionName5 === ''" label="Option Attributes 5" v-model="selection.optionAttributes5" :items="filteredAttributes(item.optionName5)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName5 === ''" @click="selectAllAttributes(5, item.optionName5)" color="primary">icons8-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName5 === '' || JSON.stringify(selection.optionAttributes5) === '[]'" @click="selection.optionAttributes5 = []">mdi-close</v-icon></div>
							</v-col>
							<v-col cols="3" class="d-flex align-center justify-stretch">
								<v-autocomplete auto-select-first :disabled="item.optionName5 === ''" label="Option Dependancy 5" v-model="item.optionDependancy5" :items="checkOptionDependancy(item.optionName5)" item-text="optionName" item-value="option" outlined dense hide-details="auto" background-color="white" color="primary"></v-autocomplete>
								<div class="ml-2"><v-icon :disabled="item.optionName5 === '' || item.optionDependancy5 === ''" @click="item.optionDependancy5 = ''">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionName4 !== '' && item.optionShow5">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow5 = false;
											(item.optionName5 = ''), (item.optionDependancy5 = ''), (selection.optionAttributes5 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<!-- <v-row class="my-3" dense v-if="item.optionName4 !== '' && !item.optionShow5">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow5 = true">+ Option 5</v-btn></v-col>
						</v-row> -->
						<!-- Option 6 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName6Autocomplete" @change="optionName6Autocomplete = ''" auto-select-first full-width label="Option Name 6" v-model="item.optionName6" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName6 === ''" @click="item.optionName6 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionAttributes6Autocomplete" @change="optionAttributes6Autocomplete = ''" auto-select-first full-width :disabled="item.optionName6 === ''" label="Option Attributes 6" v-model="selection.optionAttributes6" :items="filteredAttributes(item.optionName6)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName6 === ''" @click="selectAllAttributes(6, item.optionName6)" color="primary">icons8-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName6 === '' || JSON.stringify(selection.optionAttributes6) === '[]'" @click="selection.optionAttributes6 = []">mdi-close</v-icon></div>
							</v-col>
							<v-col cols="3" class="d-flex align-center justify-stretch">
								<v-autocomplete auto-select-first :disabled="item.optionName6 === ''" label="Option Dependancy 6" v-model="item.optionDependancy6" :items="checkOptionDependancy(item.optionName6)" item-text="optionName" item-value="option" outlined dense hide-details="auto" background-color="white" color="primary"></v-autocomplete>
								<div class="ml-2"><v-icon :disabled="item.optionName6 === '' || item.optionDependancy6 === ''" @click="item.optionDependancy6 = ''">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionName5 !== '' && item.optionShow6">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow6 = false;
											(item.optionName6 = ''), (item.optionDependancy6 = ''), (selection.optionAttributes6 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<!-- <v-row class="my-3" dense v-if="item.optionName5 !== '' && !item.optionShow6">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow6 = true">+ Option 6</v-btn></v-col>
						</v-row> -->
						<!-- Option 7 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName7Autocomplete" @change="optionName7Autocomplete = ''" auto-select-first full-width label="Option Name 7" v-model="item.optionName7" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName7 === ''" @click="item.optionName7 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionAttributes7Autocomplete" @change="optionAttributes7Autocomplete = ''" auto-select-first full-width :disabled="item.optionName7 === ''" label="Option Attributes 7" v-model="selection.optionAttributes7" :items="filteredAttributes(item.optionName7)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName7 === ''" @click="selectAllAttributes(7, item.optionName7)" color="primary">icons8-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName7 === '' || JSON.stringify(selection.optionAttributes7) === '[]'" @click="selection.optionAttributes7 = []">mdi-close</v-icon></div>
							</v-col>
							<v-col cols="3" class="d-flex align-center justify-stretch">
								<v-autocomplete auto-select-first :disabled="item.optionName7 === ''" label="Option Dependancy 7" v-model="item.optionDependancy7" :items="checkOptionDependancy(item.optionName7)" item-text="optionName" item-value="option" outlined dense hide-details="auto" background-color="white" color="primary"></v-autocomplete>
								<div class="ml-2"><v-icon :disabled="item.optionName7 === '' || item.optionDependancy7 === ''" @click="item.optionDependancy7 = ''">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionName6 !== '' && item.optionShow7">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow7 = false;
											(item.optionName7 = ''), (item.optionDependancy7 = ''), (selection.optionAttributes7 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<!-- <v-row class="my-3" dense v-if="item.optionName6 !== '' && !item.optionShow7">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow7 = true">+ Option 7</v-btn></v-col>
						</v-row> -->
						<!-- Option 8 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName8Autocomplete" @change="optionName8Autocomplete = ''" auto-select-first full-width label="Option Name 8" v-model="item.optionName8" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName8 === ''" @click="item.optionName8 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionAttributes8Autocomplete" @change="optionAttributes8Autocomplete = ''" auto-select-first full-width :disabled="item.optionName8 === ''" label="Option Attributes 8" v-model="selection.optionAttributes8" :items="filteredAttributes(item.optionName8)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName8 === ''" @click="selectAllAttributes(8, item.optionName8)" color="primary">icons8-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName8 === '' || JSON.stringify(selection.optionAttributes8) === '[]'" @click="selection.optionAttributes8 = []">mdi-close</v-icon></div>
							</v-col>
							<v-col cols="3" class="d-flex align-center justify-stretch">
								<v-autocomplete auto-select-first :disabled="item.optionName8 === ''" label="Option Dependancy 8" v-model="item.optionDependancy8" :items="checkOptionDependancy(item.optionName8)" item-text="optionName" item-value="option" outlined dense hide-details="auto" background-color="white" color="primary"></v-autocomplete>
								<div class="ml-2"><v-icon :disabled="item.optionName8 === '' || item.optionDependancy8 === ''" @click="item.optionDependancy8 = ''">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionName7 !== '' && item.optionShow8">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow8 = false;
											(item.optionName8 = ''), (item.optionDependancy8 = ''), (selection.optionAttributes8 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<!-- <v-row class="my-3" dense v-if="item.optionName7 !== '' && !item.optionShow8">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow8 = true">Add Option 8</v-btn></v-col>
						</v-row> -->
						<!-- Option 9 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName9Autocomplete" @change="optionName9Autocomplete = ''" auto-select-first full-width label="Option Name 9" v-model="item.optionName9" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName9 === ''" @click="item.optionName9 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-select full-width :disabled="item.optionName9 === ''" label="Option Attributes 9" v-model="selection.optionAttributes9" :items="filteredAttributes(item.optionName9)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-select></div>
								<div class="ml-2"><v-icon :disabled="item.optionName9 === ''" @click="selectAllAttributes(9, item.optionName9)" color="primary">icons9-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName9 === '' || JSON.stringify(selection.optionAttributes9) === '[]'" @click="selection.optionAttributes9 = []">mdi-close</v-icon></div>
							</v-col>
							<v-col cols="3" class="d-flex align-center justify-stretch">
								<v-autocomplete auto-select-first :disabled="item.optionName9 === ''" label="Option Dependancy 9" v-model="item.optionDependancy9" :items="checkOptionDependancy(item.optionName9)" item-text="optionName" item-value="option" outlined dense hide-details="auto" background-color="white" color="primary"></v-autocomplete>
								<div class="ml-2"><v-icon :disabled="item.optionName9 === '' || item.optionDependancy9 === ''" @click="item.optionDependancy9 = ''">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionName8 !== '' && item.optionShow9">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow9 = false;
											(item.optionName9 = ''), (item.optionDependancy9 = ''), (selection.optionAttributes9 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<!-- <v-row class="my-3" dense v-if="item.optionName8 !== '' && !item.optionShow9">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow9 = true">Add Option 9</v-btn></v-col>
						</v-row> -->
						<!-- Option 10 -->
						<v-row class="my-1" dense>
							<v-col cols="4" class="d-flex align-center">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionName10Autocomplete" @change="optionName10Autocomplete = ''" auto-select-first full-width label="Option Name 10" v-model="item.optionName10" :items="filteredOptions()" :item-disabled="checkIsItemDisabled" item-text="optionName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary"> </v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName10 === ''" @click="item.optionName10 = ''">mdi-close</v-icon></div>
							</v-col>
							<v-col class="d-flex align-center justify-stretch">
								<div class="flex-grow-1"><v-autocomplete :search-input.sync="optionAttributes10Autocomplete" @change="optionAttributes10Autocomplete = ''" auto-select-first full-width :disabled="item.optionName10 === ''" label="Option Attributes 10" v-model="selection.optionAttributes10" :items="filteredAttributes(item.optionName10)" multiple item-text="attName" item-value="id" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"></v-autocomplete></div>
								<div class="ml-2"><v-icon :disabled="item.optionName10 === ''" @click="selectAllAttributes(10, item.optionName10)" color="primary">icons10-checked-checkbox</v-icon></div>
								<div class="ml-2"><v-icon :disabled="item.optionName10 === '' || JSON.stringify(selection.optionAttributes10) === '[]'" @click="selection.optionAttributes10 = []">mdi-close</v-icon></div>
							</v-col>
							<v-col cols="3" class="d-flex align-center justify-stretch">
								<v-autocomplete auto-select-first :disabled="item.optionName10 === ''" label="Option Dependancy 10" v-model="item.optionDependancy10" :items="checkOptionDependancy(item.optionName10)" item-text="optionName" item-value="option" outlined dense hide-details="auto" background-color="white" color="primary"></v-autocomplete>
								<div class="ml-2"><v-icon :disabled="item.optionName10 === '' || item.optionDependancy10 === ''" @click="item.optionDependancy10 = ''">mdi-close</v-icon></div>
								<div class="ml-2" v-if="item.optionName9 !== '' && item.optionShow10">
									<v-btn
										class="primary--text font-weight-bold"
										:large="GET_dense"
										depressed
										@click="
											item.optionShow10 = false;
											(item.optionName10 = ''), (item.optionDependancy10 = ''), (selection.optionAttributes10 = []);
										"
										>REMOVE</v-btn
									>
								</div>
							</v-col>
						</v-row>
						<v-row class="my-3" dense v-if="item.optionName9 !== '' && !item.optionShow10">
							<v-col><v-btn class="primary--text font-weight-bold" depressed :large="GET_dense" @click="item.optionShow10 = true">Add Option 10</v-btn></v-col>
						</v-row>
					</v-col>
					<!-- <v-col cols="12" class="grey lighten-2 rounded pa-3 d-flex justify-right align-center">
						<v-spacer />
						<div>
							<v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="editmode = false">
								<span>Cancel</span>
							</v-btn>
						</div>
						<div class="ml-2">
							<v-btn class="accent primary--text font-weight-bold" depressed @click="saveProduct">
								<span>Save</span>
							</v-btn>
							<v-btn v-else class="accent primary--text font-weight-bold" depressed @click="updateItem()">
								<span>Update</span>
							</v-btn>
						</div>
					</v-col> -->
				</v-row>
				<!-- OPTIONS DETAILS -->
				<v-row v-if="!editmode" class="rounded mt-3 apptext--text body-2" dense>
					<v-col cols="12">
						<div class="d-flex justify-stretch ">
							<div class="rounded white ma-2 pa-5 flex-grow-1" v-if="item.optionName1 !== ''">
								<span class="text-subtitle-1">Option 1</span><br />
								<strong>{{ findOptionName(item.optionName1) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes1" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>
							<div class="rounded white ma-2 pa-5 flex-grow-1" v-if="item.optionName2 !== ''">
								<span class="text-subtitle-1">Option 2</span><br />
								<strong>{{ findOptionName(item.optionName2) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes2" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>
							<div class="rounded white ma-2 pa-5 flex-grow-1" v-if="item.optionName3 !== ''">
								<span class="text-subtitle-1">Option 3</span><br />
								<strong>{{ findOptionName(item.optionName3) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes3" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>
							<div class="rounded white ma-2 pa-5 flex-grow-1" v-if="item.optionName4 !== ''">
								<span class="text-subtitle-1">Option 4</span><br />
								<strong>{{ findOptionName(item.optionName4) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes4" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>
							<div class="rounded white ma-2 pa-5 flex-grow-1" v-if="item.optionName5 !== ''">
								<span class="text-subtitle-1">Option 5</span><br />
								<strong>{{ findOptionName(item.optionName5) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes5" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>
						</div>
						<div class="d-flex justify-stretch">
							<div class="rounded white ma-2 pa-5 flex-grow-1" v-if="item.optionName6 !== ''">
								<span class="text-subtitle-1">Option 6</span><br />
								<strong>{{ findOptionName(item.optionName6) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes6" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>							
							<div class="rounded white ma-2 pa-5" v-if="item.optionName7 !== ''">
								<span class="text-subtitle-1">Option 7</span><br />
								<strong>{{ findOptionName(item.optionName7) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes7" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>
							<div class="rounded white ma-2 pa-5" v-if="item.optionName8 !== ''">
								<span class="text-subtitle-1">Option 8</span><br />
								<strong>{{ findOptionName(item.optionName8) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes8" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>
							<div class="rounded white ma-2 pa-5" v-if="item.optionName9 !== ''">
								<span class="text-subtitle-1">Option 9</span><br />
								<strong>{{ findOptionName(item.optionName9) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes9" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>
							<div class="rounded white ma-2 pa-5" v-if="item.optionName10 !== ''">
								<span class="text-subtitle-1">Option 10</span><br />
								<strong>{{ findOptionName(item.optionName10) }}</strong>
								<ul><li v-for="attribute in computedOptionAttributes10" :key="attribute">{{ findAttributeName(attribute) }}</li></ul>
							</div>																																																								
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
			// editmode: {
			// 	type: Boolean,
			// 	default: false,
			// },
		},
		// * DATA
		data: () => ({
			loading: false,
			editmode: false,
			toggleJSON: false,
			toggleForm: false,

			attributes: [],
			options: [],
			selection: {
				optionAttributes1: [],
				optionAttributes2: [],
				optionAttributes3: [],
				optionAttributes4: [],
				optionAttributes5: [],
				optionAttributes6: [],
				optionAttributes7: [],
				optionAttributes8: [],
				optionAttributes9: [],
				optionAttributes10: [],
			},
			optionName1Autocomplete: '',
			optionName2Autocomplete: '',
			optionName3Autocomplete: '',
			optionName4Autocomplete: '',
			optionName5Autocomplete: '',
			optionName6Autocomplete: '',
			optionName7Autocomplete: '',
			optionName8Autocomplete: '',
			optionName9Autocomplete: '',
			optionName10Autocomplete: '',
			optionAttributes1Autocomplete: '',
			optionAttributes2Autocomplete: '',
			optionAttributes3Autocomplete: '',
			optionAttributes4Autocomplete: '',
			optionAttributes5Autocomplete: '',
			optionAttributes6Autocomplete: '',
			optionAttributes7Autocomplete: '',
			optionAttributes8Autocomplete: '',
			optionAttributes9Autocomplete: '',
			optionAttributes10Autocomplete: '',
			


			item: {
				optionName1: "",
				optionShow1: false,
				optionAttributes1: "",
				optionName2: "",
				optionShow2: false,
				optionAttributes2: "",
				optionDependancy2: "",
				optionName3: "",
				optionShow3: false,
				optionAttributes3: "",
				optionDependancy3: "",
				optionName4: "",
				optionShow4: false,
				optionAttributes4: "",
				optionDependancy4: "",
				optionName5: "",
				optionShow5: false,
				optionAttributes5: "",
				optionDependancy5: "",
				optionName6: "",
				optionShow6: false,
				optionAttributes6: "",
				optionDependancy6: "",
				optionName7: "",
				optionShow7: false,
				optionAttributes7: "",
				optionDependancy7: "",
				optionName8: "",
				optionShow8: false,
				optionAttributes8: "",
				optionDependancy8: "",
				optionName9: "",
				optionShow9: false,
				optionAttributes9: "",
				optionDependancy9: "",
				optionName10: "",
				optionShow10: false,
				optionAttributes10: "",
				optionDependancy10: "",
			},
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
			computedItemNames() {
				let optionNames = [];
				for (const property in this.item) {
					if (property.startsWith("optionName") && this.item[property] !== "") {
						optionNames.push(this.item[property]);
					}
				}
				return optionNames;
			},
			computedOptionAttributes1() { return this.selection.optionAttributes1.filter((x) => x !== "") },
			computedOptionAttributes2() { return this.selection.optionAttributes2.filter((x) => x !== "") },
			computedOptionAttributes3() { return this.selection.optionAttributes3.filter((x) => x !== "") },
			computedOptionAttributes4() { return this.selection.optionAttributes4.filter((x) => x !== "") },
			computedOptionAttributes5() { return this.selection.optionAttributes5.filter((x) => x !== "") },
			computedOptionAttributes6() { return this.selection.optionAttributes6.filter((x) => x !== "") },
			computedOptionAttributes7() { return this.selection.optionAttributes7.filter((x) => x !== "") },
			computedOptionAttributes8() { return this.selection.optionAttributes8.filter((x) => x !== "") },
			computedOptionAttributes9() { return this.selection.optionAttributes9.filter((x) => x !== "") },
			computedOptionAttributes10() { return this.selection.optionAttributes10.filter((x) => x !== "") },
			computedOptions() {
				let optionNames = [];
				let optionName = "";
				for (const property in this.item) {
					if (property.startsWith("optionName") && this.item[property] !== "") {
						let option = this.findOption(this.item[property]);
						if (option !== undefined) {
							optionName = option.optionName;
						}

						optionNames.push({
							option: property.charAt(property.length - 1),
							optionId: this.item[property],
							optionName: optionName,
						});
					}
				}
				return optionNames;
			},
		},
		// * METHODS
		methods: {
			saveProduct() {
				this.$emit("saveitem", this.item);
				this.editmode = false;
			},
			async readOptions() {
				let result = await this.MIX_redisSearch("idx:options", `@deleted:0 @enabled:1 @orgId:{${this.datadown.orgId}}`, 0, 9999);
				this.options = result.data.documents.map((x) => {
					return {
						id: x.value.id,
						optionName: x.value.optionName,
					};
				});
			},
			async readAttributes() {
				let result = await this.MIX_redisSearch("idx:attributes", `@deleted:0 @enabled:1 @orgId:{${this.datadown.orgId}}`, 0, 9999);
				this.attributes = result.data.documents.map((x) => {
					return {
						id: x.value.id,
						attName: x.value.attName,
						optionId: x.value.optionId,
						optionName: x.value.optionName,
					};
				});
			},
			checkOptionDependancy(item) {
				// console.log("item = " + JSON.stringify(item));
				let options = this.computedOptions;
				// console.log("options = " + JSON.stringify(options));
				return options.filter((x) => x.optionId !== item);
			},
			checkIsItemDisabled(item) {
				if (JSON.stringify(this.computedItemNames) !== "[]") {
					let value = this.computedItemNames.includes(item.id);
					return value;
				} else {
					return false;
				}
			},
			// FIND OPTION
			findOption(optionId) {
				if (optionId !== "") {
					return this.options.find((x) => x.id === optionId);
				} else {
					return "";
				}
			},
			// FIND OPTION NAME
			findOptionName(optionId) {
				let result = "";
				if (optionId !== "") {
					result = this.options.find((x) => x.id === optionId);
					if (result !== undefined) {
						result = result.optionName;
					}
				}
				return result;
			},
			// FIND ATTRIBUTE NAME
			findAttributeName(id) {
				let result = [];
				if (id !== "") {
					result = this.attributes.find((x) => x.id === id);
					if (result !== undefined) {
						result = result.attName;
					}
				}
				return result;
			},			filteredOptions() {
				// let optionsSelected = this.computedItemNames.map(x => x.optionId)
				// console.log(JSON.stringify(optionsSelected, null, 2))
				// return this.options.filter(x => {
				// 	return !optionsSelected.includes(x.id)
				// });
				return this.options;
			},
			filteredAttributes(optionId) {
				// console.log('filteredAttributes - optionId = ' + optionId)
				if (this.attributes !== undefined) {
					let result = this.attributes.filter((x) => x.optionId === optionId);
					// console.log('result = ' + JSON.stringify(result))
					return result
				} else {
					return [];
				}
			},
			// SELECT ALL ATTRIBUTES IN DROPDOWN
			selectAllAttributes(n, optionId) {
				let t = this;
				t.filteredAttributes(optionId).forEach((x) => {
					if (t.selection["optionAttributes" + n].indexOf(x.id) === -1) {
						t.selection["optionAttributes" + n].push(x.id);
					}
				});
			},
		},
		watch: {
			datadown: {
				handler: function () {
					this.item = { ...this.datadown };
				},
				deep: true,
			},
			selection: {
				handler: function () {
					// remove empty items / blank spaces
					// let optionAttributes1 = this.selection.optionAttributes1.filter((x) => x !== "");
					// let optionAttributes2 = this.selection.optionAttributes2.filter((x) => x !== "");
					// let optionAttributes3 = this.selection.optionAttributes3.filter((x) => x !== "");
					// let optionAttributes4 = this.selection.optionAttributes4.filter((x) => x !== "");
					// let optionAttributes5 = this.selection.optionAttributes5.filter((x) => x !== "");
					// let optionAttributes6 = this.selection.optionAttributes6.filter((x) => x !== "");
					// let optionAttributes7 = this.selection.optionAttributes7.filter((x) => x !== "");
					// let optionAttributes8 = this.selection.optionAttributes8.filter((x) => x !== "");
					// let optionAttributes9 = this.selection.optionAttributes9.filter((x) => x !== "");
					// let optionAttributes10 = this.selection.optionAttributes10.filter((x) => x !== "");

					this.item.optionAttributes1 = this.computedOptionAttributes1.join(",");
					this.item.optionAttributes2 = this.computedOptionAttributes2.join(",");
					this.item.optionAttributes3 = this.computedOptionAttributes3.join(",");
					this.item.optionAttributes4 = this.computedOptionAttributes4.join(",");
					this.item.optionAttributes5 = this.computedOptionAttributes5.join(",");
					this.item.optionAttributes6 = this.computedOptionAttributes6.join(",");
					this.item.optionAttributes7 = this.computedOptionAttributes7.join(",");
					this.item.optionAttributes8 = this.computedOptionAttributes8.join(",");
					this.item.optionAttributes9 = this.computedOptionAttributes9.join(",");
					this.item.optionAttributes10 = this.computedOptionAttributes10.join(",");
				},
				deep: true,
			},
		},
		async mounted() {
			this.loading = true;
			this.item = { ...this.datadown };
			this.selection.optionAttributes1 = this.item.optionAttributes1.split(",");
			this.selection.optionAttributes2 = this.item.optionAttributes2.split(",");
			this.selection.optionAttributes3 = this.item.optionAttributes3.split(",");
			this.selection.optionAttributes4 = this.item.optionAttributes4.split(",");
			this.selection.optionAttributes5 = this.item.optionAttributes5.split(",");
			this.selection.optionAttributes6 = this.item.optionAttributes6.split(",");
			this.selection.optionAttributes7 = this.item.optionAttributes7.split(",");
			this.selection.optionAttributes8 = this.item.optionAttributes8.split(",");
			this.selection.optionAttributes9 = this.item.optionAttributes9.split(",");
			this.selection.optionAttributes10 = this.item.optionAttributes10.split(",");
			// console.log(JSON.stringify(this.item, null, 2))
			await this.readOptions();
			await this.readAttributes();
			this.loading = false;
			//console.log('attributes = ' + JSON.stringify(this.attributes, null, 2))
		},
	};
</script>
