<template>
    <div class="grey lighten-2" style="width: 100% !important; height: 100% !important; overflow-y: scroll !important" fill-height>
        <!-- <v-progress-linear v-if="loading" indeterminate color="primary darken-2"></v-progress-linear> -->
        <v-row dense class="">
            <v-col class="align-center pa-5 mt-1" cols="12" xs="12">
                <div class="d-flex align-center mb-1">
                    <div><v-icon class="grey--text" x-large>icons8-upload-document</v-icon></div>
                    <div class="text-left headingtext--text text-h4 font-weight-light ml-2">Bulk Import</div>
                </div>
            </v-col>
            <v-col cols="12" xs="12" md="12" class="px-5">
                <div class="mt-3" style="width: 100% !important">Please select the supplier who's data you wish to import:<br /></div>
                <v-autocomplete :items="orgs" item-text="orgName" @change="getTotals" label="Select Supplier" dense v-model="selectedOrg" return-object auto-select-first @click:clear="clearSelectedOrg()" outlined hide-details="auto" background-color="white" color="primary" clearable></v-autocomplete>
                <br />
                <p class="error--text font-weight-bold">Please not this option is not reversable</p>
                <v-simple-table v-if="selectedOrg !== ''">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th width="25%">Supplier</th>
                                <th width="5%" align="center">System Total</th>
                                <th width="20%">Action</th>
                                <th width="10%">Check Stats</th>
                                <th width="40%">Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Options -->
                            <tr>
                                <td valign="top" class="pt-3">{{ selectedOrg.orgName }} Options</td>
                                <td valign="top" class="pt-3" align="center">
                                    <v-progress-circular v-if="optionsTotal === '' && selectedOrg !== ''" indeterminate color="purple"></v-progress-circular>
                                    <div class="text-h6" v-if="optionsTotal !== ''">{{ optionsTotal }}</div>
                                </td>
                                <td valign="top" class="py-2">
                                    <input ref="optionsInput" hidden type="file" accept=".csv" @change="handleFileUpload($event, 'options')" />
                                    <v-btn :disabled="actionRunning || optionsProgressResult.status === 'Completed'" block @click="$refs.optionsInput.click()" class="primary"
                                        ><v-icon>icons8-upload-document</v-icon>
                                        <div class="ml-2"></div>
                                        Upload & Check</v-btn
                                    >
                                </td>
                                <td valign="top" align="center" class="d-flex align-start font-weight-bold text-h6 pt-3">
                                    <div style="width: 100% !important" class="d-flex align-start" v-if="JSON.stringify(optionsImportCheckStats) !== '{}'">
                                        <div class="mx-1 success--text">{{ optionsImportCheckStats.success }}</div>
                                        |
                                        <div class="mx-1 error--text">{{ optionsImportCheckStats.errors }}</div>
                                        |
                                        <div class="mx-1 black--text">{{ optionsImportCheckStats.total }}</div>
                                    </div>
                                </td>
                                <td valign="top">
                                    <ul class="my-3" v-if="JSON.stringify(optionsImportCheckResults) !== '[]'">
                                        <li v-for="(result, index) in optionsImportCheckResults" :key="index" class="body-2 error--text font-weight-bold">{{ result.text }}<br /><span class="caption" v-html="result.value" /></li>
                                    </ul>
                                    <div class="mt-1 d-flex align-center" v-if="JSON.stringify(optionsImportCheckResults) === '[]'">
                                        <div class="my-1">
                                            <v-btn :disabled="actionRunning || JSON.stringify(optionsImportCheckStats) === '{}' || optionsProgressResult.status === 'Completed'" block @click="importOptions()" class="black white--text"
                                                ><v-icon>icons8-upload-document</v-icon>
                                                <div class="ml-2"></div>
                                                Import</v-btn
                                            >
                                        </div>

                                        <div class="ml-2" style="width: 100% !important">
                                            <v-progress-linear v-model="computedOptionsPercenteComplete" color="primary" :class="computedOptionsPercenteComplete < 50 ? 'black--text' : 'white--text'" height="35">
                                                <div class="font-weight-bold white--text" v-if="optionsProgressResult.status !== ''">{{ optionsProgressResult.count }}/{{ optionsProgressResult.totalItems }}</div>
                                                <div class="font-weight-bold white--text ml-4" v-if="optionsProgressResult.status === 'Completed'">Bulk Import Completed</div>
                                            </v-progress-linear>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- Attributes -->
                            <tr>
                                <td valign="top" class="pt-3">{{ selectedOrg.orgName }} Attributes</td>
                                <td valign="top" class="pt-3" align="center">
                                    <v-progress-circular v-if="attributesTotal === '' && selectedOrg !== ''" indeterminate color="purple"></v-progress-circular>
                                    <div class="text-h6" v-if="attributesTotal !== ''">{{ attributesTotal }}</div>
                                </td>
                                <td valign="top" class="py-2">
                                    <input ref="attributesInput" hidden type="file" accept=".csv" @change="handleFileUpload($event, 'attributes')" />
                                    <v-btn :disabled="actionRunning || attributesProgressResult.status === 'Completed'" block @click="$refs.attributesInput.click()" class="primary"
                                        ><v-icon>icons8-upload-document</v-icon>
                                        <div class="ml-2"></div>
                                        Upload & Check</v-btn
                                    >
                                </td>
                                <td valign="top" align="center" class="d-flex align-start font-weight-bold text-h6 pt-3">
                                    <div style="width: 100% !important" class="d-flex align-start" v-if="JSON.stringify(attributesImportCheckStats) !== '{}'">
                                        <div class="mx-1 success--text">{{ attributesImportCheckStats.success }}</div>
                                        |
                                        <div class="mx-1 error--text">{{ attributesImportCheckStats.errors }}</div>
                                        |
                                        <div class="mx-1 black--text">{{ attributesImportCheckStats.total }}</div>
                                    </div>
                                </td>
                                <td valign="top">
                                    <ul class="my-3" v-if="JSON.stringify(attributesImportCheckResults) !== '[]'">
                                        <li v-for="(result, index) in attributesImportCheckResults" :key="index" class="body-2 error--text font-weight-bold">{{ result.text }}<br /><span class="caption" v-html="result.value" /></li>
                                    </ul>
                                    <div class="mt-1 d-flex align-center" v-if="JSON.stringify(attributesImportCheckResults) === '[]'">
                                        <div class="my-1">
                                            <v-btn :disabled="actionRunning || JSON.stringify(attributesImportCheckStats) === '{}' || attributesProgressResult.status === 'Completed'" block @click="importAttributes()" class="black white--text"
                                                ><v-icon>icons8-upload-document</v-icon>
                                                <div class="ml-2"></div>
                                                Import</v-btn
                                            >
                                        </div>

                                        <div class="ml-2" style="width: 100% !important">
                                            <v-progress-linear v-model="computedAttributesPercenteComplete" color="primary" :class="computedAttributesPercenteComplete < 50 ? 'black--text' : 'white--text'" height="35">
                                                <div class="font-weight-bold white--text" v-if="attributesProgressResult.status !== ''">{{ attributesProgressResult.count }}/{{ attributesProgressResult.totalItems }}</div>
                                                <div class="font-weight-bold white--text ml-4" v-if="attributesProgressResult.status === 'Completed'">Bulk Import Completed</div>
                                            </v-progress-linear>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- Products -->
                            <tr>
                                <td valign="top" class="pt-3">{{ selectedOrg.orgName }} Products</td>
                                <td valign="top" class="pt-3" align="center">
                                    <v-progress-circular v-if="productsTotal === '' && selectedOrg !== ''" indeterminate color="purple"></v-progress-circular>
                                    <div class="text-h6" v-if="productsTotal !== ''">{{ productsTotal }}</div>
                                </td>
                                <td valign="top" class="py-2">
                                    <input ref="productsInput" hidden type="file" accept=".csv" @change="handleFileUpload($event, 'products')" />
                                    <v-btn :disabled="actionRunning || productsProgressResult.status === 'Completed'" block @click="$refs.productsInput.click()" class="primary"
                                        ><v-icon>icons8-upload-document</v-icon>
                                        <div class="ml-2"></div>
                                        Upload & Check</v-btn
                                    >
                                </td>
                                <td valign="top" align="center" class="d-flex align-start font-weight-bold text-h6 pt-3">
                                    <div style="width: 100% !important" class="d-flex align-start" v-if="JSON.stringify(productsImportCheckStats) !== '{}'">
                                        <div class="mx-1 success--text">{{ productsImportCheckStats.success }}</div>
                                        |
                                        <div class="mx-1 error--text">{{ productsImportCheckStats.errors }}</div>
                                        |
                                        <div class="mx-1 black--text">{{ productsImportCheckStats.total }}</div>
                                    </div>
                                </td>
                                <td valign="top">
                                    <ul class="my-3" v-if="JSON.stringify(productsImportCheckResults) !== '[]'">
                                        <li v-for="(result, index) in productsImportCheckResults" :key="index" class="body-2 error--text font-weight-bold">{{ result.text }}<br /><span class="caption" v-html="result.value" /></li>
                                    </ul>
                                    <div class="mt-1 d-flex align-center" v-if="JSON.stringify(productsImportCheckResults) === '[]'">
                                        <div class="my-1">
                                            <v-btn :disabled="actionRunning || JSON.stringify(productsImportCheckStats) === '{}' || productsProgressResult.status === 'Completed'" block @click="importProducts()" class="black white--text"
                                                ><v-icon>icons8-upload-document</v-icon>
                                                <div class="ml-2"></div>
                                                Import</v-btn
                                            >
                                        </div>

                                        <div class="ml-2" style="width: 100% !important">
                                            <v-progress-linear v-model="computedProductsPercenteComplete" color="primary" :class="computedProductsPercenteComplete < 50 ? 'black--text' : 'white--text'" height="35">
                                                <div class="font-weight-bold white--text" v-if="productsProgressResult.status !== ''">{{ productsProgressResult.count }}/{{ productsProgressResult.totalItems }}</div>
                                                <div class="font-weight-bold white--text ml-4" v-if="productsProgressResult.status === 'Completed'">Bulk Import Completed</div>
                                            </v-progress-linear>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- Variants -->
                            <tr>
                                <td valign="top" class="pt-3">{{ selectedOrg.orgName }} Variants</td>
                                <td valign="top" class="pt-3" align="center">
                                    <v-progress-circular v-if="variantsTotal === '' && selectedOrg !== ''" indeterminate color="purple"></v-progress-circular>
                                    <div class="text-h6" v-if="variantsTotal !== ''">{{ variantsTotal }}</div>
                                </td>
                                <td valign="top" class="py-2">
                                    <input ref="variantsInput" hidden type="file" accept=".csv" @change="handleFileUpload($event, 'variants')" />
                                    <v-btn :disabled="actionRunning || variantsProgressResult.status === 'Completed'" block @click="$refs.variantsInput.click()" class="primary"
                                        ><v-icon>icons8-upload-document</v-icon>
                                        <div class="ml-2"></div>
                                        Upload & Check</v-btn
                                    >
                                </td>
                                <td valign="top" align="center" class="d-flex align-start font-weight-bold text-h6 pt-3">
                                    <div style="width: 100% !important" class="d-flex align-start" v-if="JSON.stringify(variantsImportCheckStats) !== '{}'">
                                        <div class="mx-1 success--text">{{ variantsImportCheckStats.success }}</div>
                                        |
                                        <div class="mx-1 error--text">{{ variantsImportCheckStats.errors }}</div>
                                        |
                                        <div class="mx-1 black--text">{{ variantsImportCheckStats.total }}</div>
                                    </div>
                                </td>
                                <td valign="top">
                                    <ul class="my-3" v-if="JSON.stringify(variantsImportCheckResults) !== '[]'">
                                        <li v-for="(result, index) in variantsImportCheckResults" :key="index" class="body-2 error--text font-weight-bold">{{ result.text }}<br /><span class="caption" v-html="result.value" /></li>
                                    </ul>
                                    <div class="mt-1 d-flex align-center" v-if="JSON.stringify(variantsImportCheckResults) === '[]'">
                                        <div class="my-1">
                                            <v-btn :disabled="actionRunning || JSON.stringify(variantsImportCheckStats) === '{}' || variantsProgressResult.status === 'Completed'" block @click="importVariants()" class="black white--text"
                                                ><v-icon>icons8-upload-document</v-icon>
                                                <div class="ml-2"></div>
                                                Import</v-btn
                                            >
                                        </div>

                                        <div class="ml-2" style="width: 100% !important">
                                            <v-progress-linear v-model="computedVariantsPercenteComplete" color="primary" :class="computedVariantsPercenteComplete < 50 ? 'black--text' : 'white--text'" height="35">
                                                <div class="font-weight-bold white--text" v-if="variantsProgressResult.status !== ''">{{ variantsProgressResult.count }}/{{ variantsProgressResult.totalItems }}</div>
                                                <div class="font-weight-bold white--text ml-4" v-if="variantsProgressResult.status === 'Completed'">Bulk Import Completed</div>
                                            </v-progress-linear>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <!-- <v-col cols="4"
                >productsIds:<br />
                <pre class="caption">{{ productsIds }}</pre>
            </v-col>
            <v-col cols="4">
            variants: <span v-if="JSON.stringify(variantsImportData) !== '[]'">({{ variantsImportData.length }})</span><br />
            <pre class="caption">{{ variantsImportData }}</pre>
            </v-col> -->
            <!-- <v-col cols="4">errorData: <span v-if="JSON.stringify(errorData) !== '[]'">({{ errorData.length}})</span><br /><pre class="caption">{{ errorData }}</pre></v-col> -->
            <!-- :rules="rules.productSupplier" -->
        </v-row>
        <confirm-dialog :datadown="confirmDialog" v-on:method="confirmMethodHandler" />
    </div>
</template>

<script>
export default {
    name: "DeleteData",
    data: () => ({
        // loading status for each type
        loading: false,
        optionsLoading: false,
        attributesLoading: false,
        productsLoading: false,
        variantsLoading: false,
        // Data for each Type
        options: [],
        attributes: [],
        // Data IDs for each type
        optionsIds: [],
        attributesIds: [],
        productsIds: [],
        variantsIds: [],
        // Totals for each type
        optionsTotal: "",
        attributesTotal: "",
        productsTotal: "",
        variantsTotal: "",
        // Results for each type
        optionsResult: "",
        attributesResult: "",
        productsResult: "",
        variantsResult: "",
        // Progress IDs for each type
        optionsProgressId: "",
        attributesProgressId: "",
        productsProgressId: "",
        variantsProgressId: "",
        // Options Progress Result
        optionsProgressResult: {
            status: "",
            count: "",
            totalItems: "",
        },
        // Attributes Progress Result
        attributesProgressResult: {
            status: "",
            count: "",
            totalItems: "",
        },
        // Products Progress Result
        productsProgressResult: {
            status: "",
            count: "",
            totalItems: "",
        },
        // Variants Progress Result
        variantsProgressResult: {
            status: "",
            count: "",
            totalItems: "",
        },
        // Action Running Status
        actionRunning: true,
        orgs: [],
        selectedOrg: "",
        //
        file: "",

        errorData: [],
        parsed: false,

        // Dialogs
        confirmDialog: {},
        confirmOptionsImport: {
            show: true,
            title: "WARNING - Import Options",
            message: "Are you sure you want to import options.",
            color: "warning",
            actionText1: "No",
            actionText2: "Yes",
            id: "",
            method: "importOptions",
            action: "",
        },
        confirmAttributesImport: {
            show: true,
            title: "WARNING - Import Attributes",
            message: "Are you sure you want to import attributes.",
            color: "warning",
            actionText1: "No",
            actionText2: "Yes",
            id: "",
            method: "importAttributes",
            action: "",
        },
        confirmProductsImport: {
            show: true,
            title: "WARNING - Import Products",
            message: "Are you sure you want to import products.",
            color: "warning",
            actionText1: "No",
            actionText2: "Yes",
            id: "",
            method: "importProducts",
            action: "",
        },
        confirmVariantsImport: {
            show: true,
            title: "WARNING - Import Variants",
            message: "Are you sure you want to import variants.",
            color: "warning",
            actionText1: "No",
            actionText2: "Yes",
            id: "",
            method: "importVariants",
            action: "",
        },
        // options Import
        optionsImportData: [],
        optionsImportCheckResults: [],
        optionsImportCheckStats: {},
        optionsImportMandatoryHeaders: ["id", "optionName", "optionType", "optionVariant", "optionSupplier", "enabled", "key", "orgId", "orgName", "deleted", "createdDateTime", "createdUserId", "createdUserName", "createdUserEmail", "modifiedDateTime", "modifiedUserId", "modifiedUserName", "modifiedUserEmail", "deletedDateTime", "deletedUserId", "deletedUserName", "deletedUserEmail"],
        // attributes Import
        attributesImportData: [],
        attributesImportCheckResults: [],
        attributesImportCheckStats: {},
        attributesImportMandatoryHeaders: ["id", "attName", "attCode", "attType", "optionId", "optionName", "key", "attHexColor", "attMTO", "attPriceBand", "attAddCost", "attAddSell", "enabled", "attParentId", "attDescription", "attImageId", "attSupplier", "orgId", "orgName", "deleted"],
        // products Import
        productsImportData: [],
        productsImportCheckResults: [],
        productsImportCheckStats: {},
        productsImportMandatoryHeaders: [
            "productCode",
            "productName",
            "productSupplierCode",
            "productPreviousCode",
            "productStatus",
            "productAvailableFrom",
            "productAvailableTo",
            "productShortDescription",
            "productDescription",
            "productImageId",
            "productImageOldPath",
            "productLeadTimeDays",
            "productMTO",
            "productCategory",
            "productSubcategory",
            "productItemType",
            "productFitType",
            "productType",
            "productRange",
            "productTags",
            "enabled",
            "productWidth",
            "productHeight",
            "productFrontWidth",
            "productBackWidth",
            "productSeatHeight",
            "productInternalLegWidth",
            "productDimensions",
            "orgId",
            "orgName",
            "hasOptions",
            "hasVariants",
            "hasExtras",
            "isExtra",
            "isExtraSoldSeparately",
            "MOQ",
            "pricingType",
            "RRP",
            "MP",
            "cQ1",
            "cP1",
            "cQ2",
            "cP2",
            "cQ3",
            "cP3",
            "sQ1",
            "sP1",
            "sQ2",
            "sP2",
            "sQ3",
            "sP3",
            "cST",
            "sST",
            "cBA",
            "cBB",
            "cBC",
            "cBD",
            "cBE",
            "cBF",
            "sBA",
            "sBB",
            "sBC",
            "sBD",
            "sBE",
            "sBF",
            "NPS",
            "discountGroup1",
            "discountGroup2",
            "discountGroup3",
            "discountGroup4",
            "discountGroup5",
            "discountGroup6",
            "discountGroup7",
            "discountGroup8",
            "discountGroup9",
            "discountGroup10",
            "optionName1",
            "optionAttributes1",
            "optionName2",
            "optionAttributes2",
            "optionDependancy2",
            "optionName3",
            "optionAttributes3",
            "optionDependancy3",
            "optionName4",
            "optionAttributes4",
            "optionDependancy4",
            "optionName5",
            "optionAttributes5",
            "optionDependancy5",
            "optionName6",
            "optionAttributes6",
            "optionDependancy6",
            "optionName7",
            "optionAttributes7",
            "optionDependancy7",
            "optionName8",
            "optionAttributes8",
            "optionDependancy8",
            "optionName9",
            "optionAttributes9",
            "optionDependancy9",
            "optionName10",
            "optionAttributes10",
            "optionDependancy10",
            "key",
            "deleted",
            "id",
        ],
        // variants Import
        variantsImportData: [],
        variantsImportCheckResults: [],
        variantsImportCheckStats: {},
        variantsImportMandatoryHeaders: [
            "variantCode",
            "variantSupplierCode",
            "variantPreviousCode",
            "variantDescription",
            "productCode",
            "productId",
            "variantStatus",
            "variantAvailableFrom",
            "variantAvailableTo",
            "variantImageId",
            "variantImageOldPath",
            "variantMTO",
            "enabled",
            "orgId",
            "orgName",
            "optionName1",
            "optionValue1",
            "optionName2",
            "optionValue2",
            "optionName3",
            "optionValue3",
            "optionName4",
            "optionValue4",
            "optionName5",
            "optionValue5",
            "optionName6",
            "optionValue6",
            "optionName7",
            "optionValue7",
            "optionName8",
            "optionValue8",
            "optionName9",
            "optionValue9",
            "optionName10",
            "optionValue10",
            "MOQ",
            "pricingType",
            "RRP",
            "MP",
            "cQ1",
            "cP1",
            "cQ2",
            "cP2",
            "cQ3",
            "cP3",
            "sQ1",
            "sP1",
            "sQ2",
            "sP2",
            "sQ3",
            "sP3",
            "cST",
            "sST",
            "cBA",
            "cBB",
            "cBC",
            "cBD",
            "cBE",
            "cBF",
            "sBA",
            "sBB",
            "sBC",
            "sBD",
            "sBE",
            "sBF",
            "NPS",
            "discountGroup1",
            "discountGroup2",
            "discountGroup3",
            "discountGroup4",
            "discountGroup5",
            "discountGroup6",
            "discountGroup7",
            "discountGroup8",
            "discountGroup9",
            "discountGroup10",
            "deleted",
            "key",
            "id",
        ],
    }),
    computed: {
        // Options Progress Percent Complete
        computedOptionsPercenteComplete() {
            let percentComplete = Math.ceil((Number(this.optionsProgressResult.count) / Number(this.optionsProgressResult.totalItems)) * 100);
            if (isNaN(percentComplete)) {
                percentComplete = 0;
            }
            return percentComplete;
        },
        // Attributes Progress Percent Complete
        computedAttributesPercenteComplete() {
            let percentComplete = Math.ceil((Number(this.attributesProgressResult.count) / Number(this.attributesProgressResult.totalItems)) * 100);
            if (isNaN(percentComplete)) {
                percentComplete = 0;
            }
            return percentComplete;
        },
        // Products Progress Percent Complete
        computedProductsPercenteComplete() {
            let percentComplete = Math.ceil((Number(this.productsProgressResult.count) / Number(this.productsProgressResult.totalItems)) * 100);
            if (isNaN(percentComplete)) {
                percentComplete = 0;
            }
            return percentComplete;
        },
        // Variants Progress Percent Complete
        computedVariantsPercenteComplete() {
            let percentComplete = Math.ceil((Number(this.variantsProgressResult.count) / Number(this.variantsProgressResult.totalItems)) * 100);
            if (isNaN(percentComplete)) {
                percentComplete = 0;
            }
            return percentComplete;
        },
    },
    methods: {
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrg = "";
            }, 1);
        },
        async getTotals() {
            this.actionRunning = true;
            // Count Option Numbers
            this.optionsLoading = true;
            let url = `${process.env.VUE_APP_MOFSERVER}/search/?index=idx:options&searchquery=@orgId:{${this.selectedOrg.orgId}}&from=0&size=9999`;
            let optionsTotalResult = await this.$axios.get(url);
            if (optionsTotalResult.data.total > 0) {
                this.options = optionsTotalResult.data.documents.map((x) => x.value);
                this.optionsIds = optionsTotalResult.data.documents.map((x) => x.id);
                this.optionsTotal = optionsTotalResult.data.total;
            } else {
                this.options = [];
                this.optionsIds = [];
                this.optionsTotal = 0;
            }
            this.optionsLoading = false;
            // Count Attribute Numbers
            this.attributesLoading = true;
            url = `${process.env.VUE_APP_MOFSERVER}/search/?index=idx:attributes&searchquery=@orgId:{${this.selectedOrg.orgId}}&from=0&size=9999`;
            let attributesTotalResult = await this.$axios.get(url);
            if (attributesTotalResult.data.total > 0) {
                this.attributes = attributesTotalResult.data.documents.map((x) => x.value);
                this.attributesIds = attributesTotalResult.data.documents.map((x) => x.id);
                this.attributesTotal = attributesTotalResult.data.total;
            } else {
                this.attributes = [];
                this.attributesIds = [];
                this.attributesTotal = 0;
            }
            this.attributesLoading = false;
            // Count Product Numbers
            this.productsLoading = true;
            url = `${process.env.VUE_APP_MOFSERVER}/search/?index=idx:products&searchquery=@orgId:{${this.selectedOrg.orgId}}&from=0&size=9999`;
            let productsTotalResult = await this.$axios.get(url);
            if (productsTotalResult.data.total > 0) {
                // this.products = productsTotalResult.data.documents.map((x) => x.value);
                this.productsIds = productsTotalResult.data.documents.map((x) => x.id);
                this.productsTotal = productsTotalResult.data.total;
            } else {
                // this.products = [];
                this.productsIds = [];
                this.productsTotal = 0;
            }
            this.productsLoading = false;
            // Count Variable Numbers
            this.variantsLoading = true;
            url = `${process.env.VUE_APP_MOFSERVER}/search/?index=idx:variants&searchquery=@orgId:{${this.selectedOrg.orgId}}&from=0&size=9999`;
            let variantsTotalResult = await this.$axios.get(url);
            if (variantsTotalResult.data.total > 0) {
                // this.variants = variantsTotalResult.data.documents.map((x) => x.value);
                this.variantsIds = variantsTotalResult.data.documents.map((x) => x.id);
                this.variantsTotal = variantsTotalResult.data.total;
            } else {
                // this.variants = [];
                this.variantsIds = [];
                this.variantsTotal = 0;
            }
            this.variantsLoading = false;
            this.actionRunning = false;
        },
        handleFileUpload(event, type) {
            this.file = event.target.files[0];
            this.parseFile(type);
            this.$refs[type + "Input"].value = null;
        },
        confirmMethodHandler(value) {
            this.confirmDialog = {};
            if (value.action === 2) {
                this[value.method](value.id);
            }
        },
        // Parse File
        parseFile(type) {
            let t = this;
            this.$papa.parse(this.file, {
                header: true,
                quoteChar: '"',
                skipEmptyLines: true,
                dynamicTyping: false,
                complete: function (results) {
                    let dateTimeNow = t.$moment().format("x");
                    let importContent = [];
                    // let errorData = [];
                    let importLine = {};
                    for (var i = 0; i < results.data.length; i++) {
                        importLine = {
                            ...results.data[i],
                            createdDateTime: dateTimeNow,
                            createdUserId: t.GET_FIREBASE_userAuth.uid,
                            createdUserName: t.GET_FIREBASE_userAuth.displayName,
                            createdUserEmail: t.GET_FIREBASE_userAuth.email,
                            modifiedDateTime: dateTimeNow,
                            modifiedUserId: t.GET_FIREBASE_userAuth.uid,
                            modifiedUserName: t.GET_FIREBASE_userAuth.displayName,
                            modifiedUserEmail: t.GET_FIREBASE_userAuth.email,
                            deletedDateTime: "",
                            deletedUserId: "",
                            deletedUserName: "",
                            deletedUserEmail: "",
                        };
                        importContent.push(importLine);
                    }
                    // this.errorData = errorData;
                    this[type + "ImportData"] = importContent;
                    this[type + "CheckImportData"]();
                    this.parsed = true;
                }.bind(this),
            });
        },
        // options Check Import Data
        optionsCheckImportData() {
            this.optionsImportCheckResults = [];
            let optionsImportCheckStats = {};
            optionsImportCheckStats.errors = 0;
            optionsImportCheckStats.total = this.optionsImportData.length;
            // check mandatory headers
            let headers = Object.keys(this.optionsImportData[0]);
            let missingHeaders = [];
            for (let i = 0; i < this.optionsImportMandatoryHeaders.length; i++) {
                if (!headers.includes(this.optionsImportMandatoryHeaders[i])) {
                    missingHeaders.push(this.optionsImportMandatoryHeaders[i]);
                }
            }
            // If any missing headers then add to import check results
            if (missingHeaders.length > 0) {
                this.optionsImportCheckResults.push({
                    text: "Missing Column Headers or Incorrect File",
                    value: `${missingHeaders.join(", ")}`,
                });
            }
            // check for id = null
            let nullIds = [];
            for (let i = 0; i < this.optionsImportData.length; i++) {
                if (this.optionsImportData[i].id === null) {
                    optionsImportCheckStats.errors++;
                    nullIds.push(`Row ${i + 2}<br />`);
                }
            }
            // If any null ids then add to import check results
            if (nullIds.length > 0) {
                this.optionsImportCheckResults.push({
                    text: "Null Ids - Delete Emtpy Rows below the data set",
                    value: `${nullIds.join("")}`,
                });
            }
            // check for duplicate ids
            let ids = [];
            let duplicateIds = [];
            for (let i = 0; i < this.optionsImportData.length; i++) {
                if (ids.includes(this.optionsImportData[i].id)) {
                    optionsImportCheckStats.errors++;
                    duplicateIds.push(this.optionsImportData[i].id);
                } else {
                    ids.push(`Row ${i + 2} - ${this.optionsImportData[i].id}<br />`);
                }
            }
            // If any duplicated ids then add to import check results
            if (duplicateIds.length > 0) {
                this.optionsImportCheckResults.push({
                    text: "Duplicate Ids",
                    value: `${duplicateIds.join("")}`,
                });
            }
            // if any lines include orgId that is not the selected org then add to import check results
            let orgIds = [];
            for (let i = 0; i < this.optionsImportData.length; i++) {
                if (this.optionsImportData[i].orgId !== this.selectedOrg.orgId && this.optionsImportData[i].orgId !== null) {
                    optionsImportCheckStats.errors++;
                    orgIds.push(`Row ${i + 2} - ${this.optionsImportData[i].orgId}<br />`);
                }
            }
            // If any orgIds that are not the selected org then add to import check results
            if (orgIds.length > 0) {
                this.optionsImportCheckResults.push({
                    text: "Incorrect Org Ids",
                    value: `${orgIds.join("")}`,
                });
            }
            // if the option name contains any special characters then add to import check results
            let specialChars = [];
            for (let i = 0; i < this.optionsImportData.length; i++) {
                if (this.optionsImportData[i]?.optionName?.match(/[^a-zA-Z0-9 _]/g)) {
                    optionsImportCheckStats.errors++;
                    specialChars.push(`Row ${i + 2} - ${this.optionsImportData[i].optionName}<br />`);
                }
            }
            // If any option names that contain special characters then add to import check results
            if (specialChars.length > 0) {
                this.optionsImportCheckResults.push({
                    text: "Option Names Contain Special Characters",
                    value: `${specialChars.join("")}`,
                });
            }
            optionsImportCheckStats.success = (Number(optionsImportCheckStats.total) - Number(optionsImportCheckStats.errors)).toString();
            this.optionsImportCheckStats = optionsImportCheckStats;
        },
        // attributes Check Import Data
        attributesCheckImportData() {
            let t = this;
            this.attributesImportCheckResults = [];
            let attributesImportCheckStats = {};
            attributesImportCheckStats.errors = 0;
            attributesImportCheckStats.total = this.attributesImportData.length;
            // check mandatory headers
            let headers = Object.keys(this.attributesImportData[0]);
            let missingHeaders = [];
            for (let i = 0; i < this.attributesImportMandatoryHeaders.length; i++) {
                if (!headers.includes(this.attributesImportMandatoryHeaders[i])) {
                    missingHeaders.push(this.attributesImportMandatoryHeaders[i]);
                }
            }
            // If any missing headers then add to import check results
            if (missingHeaders.length > 0) {
                this.attributesImportCheckResults.push({
                    text: "Missing Column Headers or Incorrect File",
                    value: `${missingHeaders.join(", ")}`,
                });
            }
            // check for id = null
            let nullIds = [];
            for (let i = 0; i < this.attributesImportData.length; i++) {
                if (this.attributesImportData[i].id === null) {
                    attributesImportCheckStats.errors++;
                    nullIds.push(`Row ${i + 2}<br />`);
                }
            }
            // If any null ids then add to import check results
            if (nullIds.length > 0) {
                this.attributesImportCheckResults.push({
                    text: "Null Ids - Delete Emtpy Rows below the data set",
                    value: `${nullIds.join("")}`,
                });
            }
            // check for duplicate ids
            let ids = [];
            let duplicateIds = [];
            for (let i = 0; i < this.attributesImportData.length; i++) {
                if (ids.includes(this.attributesImportData[i].id)) {
                    attributesImportCheckStats.errors++;
                    duplicateIds.push(this.attributesImportData[i].id);
                } else {
                    ids.push(`Row ${i + 2} - ${this.attributesImportData[i].id}<br />`);
                }
            }
            // If any duplicated ids then add to import check results
            if (duplicateIds.length > 0) {
                this.attributesImportCheckResults.push({
                    text: "Duplicate Ids",
                    value: `${duplicateIds.join("")}`,
                });
            }
            // if any lines include orgId that is not the selected org then add to import check results
            let orgIds = [];
            for (let i = 0; i < this.attributesImportData.length; i++) {
                if (this.attributesImportData[i].orgId !== this.selectedOrg.orgId && this.attributesImportData[i].orgId !== null) {
                    attributesImportCheckStats.errors++;
                    orgIds.push(`Row ${i + 2} - ${this.attributesImportData[i].orgId}<br />`);
                }
            }
            // If any orgIds that are not the selected org then add to import check results
            if (orgIds.length > 0) {
                this.attributesImportCheckResults.push({
                    text: "Incorrect Org Ids",
                    value: `${orgIds.join("")}`,
                });
            }
            // if the attribute name contains any special characters then add to import check results
            let specialChars = [];
            for (let i = 0; i < this.attributesImportData.length; i++) {
                // console.log(JSON.stringify(this.attributesImportData[i], null, 2));
                if (this.attributesImportData[i]?.attName?.match(/[^a-zA-Z0-9 _]/g)) {
                    attributesImportCheckStats.errors++;
                    specialChars.push(`Row ${i + 2} - ${this.attributesImportData[i].attName}<br />`);
                }
            }
            // If any attribute names that contain special characters then add to import check results
            if (specialChars.length > 0) {
                this.attributesImportCheckResults.push({
                    text: "Attribute Names Contain Special Characters",
                    value: `${specialChars.join("")}`,
                });
            }
            // if the attribute optionId does not exist then add to import check results
            let optionIds = [];
            for (let i = 0; i < this.attributesImportData.length; i++) {
                if (this.attributesImportData[i].optionId !== null) {
                    // ****************
                    let optionId = "option:" + this.attributesImportData[i].optionId;
                    if (!t.optionsIds.includes(optionId)) {
                        attributesImportCheckStats.errors++;
                        optionIds.push(`Row ${i + 2} - ${t.attributesImportData[i].optionId}<br />`);
                    }
                }
            }
            // If any attribute optionIds that do not exist then add to import check results
            if (optionIds.length > 0) {
                this.attributesImportCheckResults.push({
                    text: "Attribute optionId Do Not Exist",
                    value: `${optionIds.join("")}`,
                });
            }
            attributesImportCheckStats.success = (Number(attributesImportCheckStats.total) - Number(attributesImportCheckStats.errors)).toString();
            this.attributesImportCheckStats = attributesImportCheckStats;
        },
        // products Check Import Data
        productsCheckImportData() {
            // let t = this;
            this.productsImportCheckResults = [];
            let productsImportCheckStats = {};
            productsImportCheckStats.errors = 0;
            productsImportCheckStats.total = this.productsImportData.length;
            // check mandatory headers
            let headers = Object.keys(this.productsImportData[0]);
            let missingHeaders = [];
            for (let i = 0; i < this.productsImportMandatoryHeaders.length; i++) {
                if (!headers.includes(this.productsImportMandatoryHeaders[i])) {
                    missingHeaders.push(this.productsImportMandatoryHeaders[i]);
                }
            }
            // If any missing headers then add to import check results
            if (missingHeaders.length > 0) {
                this.productsImportCheckResults.push({
                    text: "Missing Column Headers or Incorrect File",
                    value: `${missingHeaders.join(", ")}`,
                });
            }
            // check for id = null
            let nullIds = [];
            for (let i = 0; i < this.productsImportData.length; i++) {
                if (this.productsImportData[i].id === null) {
                    productsImportCheckStats.errors++;
                    nullIds.push(`Row ${i + 2}<br />`);
                }
            }
            // If any null ids then add to import check results
            if (nullIds.length > 0) {
                this.productsImportCheckResults.push({
                    text: "Null Ids - Delete Emtpy Rows below the data set",
                    value: `${nullIds.join("")}`,
                });
            }
            // check for duplicate ids
            let ids = [];
            let duplicateIds = [];
            for (let i = 0; i < this.productsImportData.length; i++) {
                if (ids.includes(this.productsImportData[i].id)) {
                    productsImportCheckStats.errors++;
                    duplicateIds.push(this.productsImportData[i].id);
                } else {
                    ids.push(`Row ${i + 2} - ${this.productsImportData[i].id}<br />`);
                }
            }
            // If any duplicated ids then add to import check results
            if (duplicateIds.length > 0) {
                this.productsImportCheckResults.push({
                    text: "Duplicate Ids",
                    value: `${duplicateIds.join("")}`,
                });
            }
            // if any lines include orgId that is not the selected org then add to import check results
            let orgIds = [];
            for (let i = 0; i < this.productsImportData.length; i++) {
                if (this.productsImportData[i].orgId !== this.selectedOrg.orgId && this.productsImportData[i].orgId !== null) {
                    productsImportCheckStats.errors++;
                    orgIds.push(`Row ${i + 2} - ${this.productsImportData[i].orgId}<br />`);
                }
            }
            // If any orgIds that are not the selected org then add to import check results
            if (orgIds.length > 0) {
                this.productsImportCheckResults.push({
                    text: "Incorrect Org Ids",
                    value: `${orgIds.join("")}`,
                });
            }
            // if the product name contains any special characters then add to import check results
            let specialChars = [];
            for (let i = 0; i < this.productsImportData.length; i++) {
                // console.log(JSON.stringify(this.productsImportData[i], null, 2));
                if (this.productsImportData[i]?.attName?.match(/[^a-zA-Z0-9 _]/g)) {
                    productsImportCheckStats.errors++;
                    specialChars.push(`Row ${i + 2} - ${this.productsImportData[i].attName}<br />`);
                }
            }
            // If any product names that contain special characters then add to import check results
            if (specialChars.length > 0) {
                this.productsImportCheckResults.push({
                    text: "Attribute Names Contain Special Characters",
                    value: `${specialChars.join("")}`,
                });
            }
            // if the product optionId does not exist then add to import check results
            let missingAttributes = [];
            let missingOptions = [];
            // Set Fields to Check
            var optionFields = ["optionName1", "optionName2", "optionName3", "optionName4", "optionName5", "optionName6", "optionName7", "optionName8", "optionName9", "optionName10"];
            var optionAttributes = ["optionAttributes1", "optionAttributes2", "optionAttributes3", "optionAttributes4", "optionAttributes5", "optionAttributes6", "optionAttributes7", "optionAttributes8", "optionAttributes9", "optionAttributes10"];
            for (let i = 0; i < this.productsImportData.length; i++) {
                let errorCount = 0
                let product = this.productsImportData[i];
                for (const property in product) {
                    // options check
                    if (optionFields.includes(property)) {
                        if (product[property] !== null && product[property] !== undefined && product[property] !== "") {
                            let formattedOptionId = product.orgId+'_'+(product[property]).replace(/ /g, "").trim();
                            product[property] = formattedOptionId
                            if (!this.checkOptionExists(formattedOptionId)) {
                                errorCount++;
                                missingOptions.push(`Row ${i + 2} - ${product[property]}<br />`);
                            }
                        }
                    }

                    // 
                    // attributes check
                    if (optionAttributes.includes(property)) {
                        if (product[property] !== null && product[property] !== undefined && product[property] !== "") {
                            try {
                                let attributes = JSON.parse(product[property]);
                                let formatedAttributes = []
                                for (let j = 0; j < attributes.length; j++) {
                                    let attributeId = product["optionName" + property.slice(-1)]+"_"+(attributes[j]).replace(/ /g, "").trim()
                                    formatedAttributes.push(attributeId);
                                    
                                    if (!this.checkAttributeExists(attributeId)) {
                                        errorCount++;
                                        missingAttributes.push(`Row ${i + 2} - ${attributeId}<br />`);
                                    }
                                }
                                product[property] = formatedAttributes;
                                //console.log('.'+product[property]);
                            } catch (error) {
                                console.log("error", error.message);
                                errorCount++;
                                //productsImportCheckStats.errors++;
                                missingAttributes.push(`Row ${i + 2} - ${product[property]} <br />`);
                            }
                        }
                    }
                }
                if (errorCount > 0) {
                    productsImportCheckStats.errors++;
                }
                // add product search code with no special characters or spaces 
                product.productSearchCode = product.productSupplierCode.replace(/[^a-zA-Z0-9]/g, "").trim();
                product.productPreviousSearchCode = product.productPreviousCode.replace(/[^a-zA-Z0-9]/g, "").trim();
            }
            // If any product optionIds that do not exist then add to import check results
            if (missingOptions.length > 0) {
                this.productsImportCheckResults.push({
                    text: "Options Does Not Exist or is Misspelled",
                    value: `${missingOptions.join("")}`,
                });
            }
            // if any product attributes that do not exist then add to import check results
            if (missingAttributes.length > 0) {
                this.productsImportCheckResults.push({
                    text: "Attributes Does Not Exist or is Misspelled or is not in the correct format",
                    value: `${missingAttributes.join("")}`,
                });
            }
            productsImportCheckStats.success = (Number(productsImportCheckStats.total) - Number(productsImportCheckStats.errors)).toString();
            this.productsImportCheckStats = productsImportCheckStats;
        },
        // variants Check Import Data
        variantsCheckImportData() {
            // let t = this;
            this.variantsImportCheckResults = [];
            let variantsImportCheckStats = {};
            variantsImportCheckStats.errors = 0;
            variantsImportCheckStats.total = this.variantsImportData.length;
            // check mandatory headers
            let headers = Object.keys(this.variantsImportData[0]);
            let missingHeaders = [];
            for (let i = 0; i < this.variantsImportMandatoryHeaders.length; i++) {
                if (!headers.includes(this.variantsImportMandatoryHeaders[i])) {
                    missingHeaders.push(this.variantsImportMandatoryHeaders[i]);
                }
            }
            // If any missing headers then add to import check results
            if (missingHeaders.length > 0) {
                this.variantsImportCheckResults.push({
                    text: "Missing Column Headers or Incorrect File",
                    value: `${missingHeaders.join(", ")}`,
                });
            }
            // check for id = null
            let nullIds = [];
            for (let i = 0; i < this.variantsImportData.length; i++) {
                if (this.variantsImportData[i].id === null) {
                    variantsImportCheckStats.errors++;
                    nullIds.push(`Row ${i + 2}<br />`);
                }
            }
            // If any null ids then add to import check results
            if (nullIds.length > 0) {
                this.variantsImportCheckResults.push({
                    text: "Null Ids - Delete Emtpy Rows below the data set",
                    value: `${nullIds.join("")}`,
                });
            }
            // check for duplicate ids
            let ids = [];
            let duplicateIds = [];
            for (let i = 0; i < this.variantsImportData.length; i++) {
                if (ids.includes(this.variantsImportData[i].id)) {
                    variantsImportCheckStats.errors++;
                    duplicateIds.push(this.variantsImportData[i].id);
                } else {
                    ids.push(`Row ${i + 2} - ${this.variantsImportData[i].id}<br />`);
                }
            }
            // If any duplicated ids then add to import check results
            if (duplicateIds.length > 0) {
                this.variantsImportCheckResults.push({
                    text: "Duplicate Ids",
                    value: `${duplicateIds.join("")}`,
                });
            }
            // if any lines include orgId that is not the selected org then add to import check results
            let orgIds = [];
            for (let i = 0; i < this.variantsImportData.length; i++) {
                if (this.variantsImportData[i].orgId !== this.selectedOrg.orgId && this.variantsImportData[i].orgId !== null) {
                    variantsImportCheckStats.errors++;
                    orgIds.push(`Row ${i + 2} - ${this.variantsImportData[i].orgId}<br />`);
                }
            }
            // If any orgIds that are not the selected org then add to import check results
            if (orgIds.length > 0) {
                this.variantsImportCheckResults.push({
                    text: "Incorrect Org Ids",
                    value: `${orgIds.join("")}`,
                });
            }
            // if the variant code contains any special characters then add to import check results
            let specialChars = [];
            for (let i = 0; i < this.variantsImportData.length; i++) {
                // console.log(JSON.stringify(this.variantsImportData[i], null, 2));

                if (this.variantsImportData[i]?.variantCode?.match(/[^a-zA-Z0-9 _]/g)) {
                    variantsImportCheckStats.errors++;
                    specialChars.push(`Row ${i + 2} - ${this.variantsImportData[i].variantCode}<br />`);
                }
            }
            // If any variant names that contain special characters then add to import check results
            if (specialChars.length > 0) {
                this.variantsImportCheckResults.push({
                    text: "Attribute Names Contain Special Characters",
                    value: `${specialChars.join("")}`,
                });
            }

            // TODO - RG - something needs to go here
            // product check needs to go here
            // if the variant productId does not exist then add to import check results
            let productIds = [];
            for (let i = 0; i < this.variantsImportData.length; i++) {
                if (this.variantsImportData[i].productId !== null) {
                    if (!this.checkProductExists(this.variantsImportData[i].productId)) {
                        variantsImportCheckStats.errors++;
                        productIds.push(`Row ${i + 2} - ${this.variantsImportData[i].productId}<br />`);
                    }
                }
            }
            // If any attribute optionIds that do not exist then add to import check results
            if (productIds.length > 0) {
                this.variantsImportCheckResults.push({
                    text: "Variant product Id Do Not Exist",
                    value: `${productIds.join("")}`,
                });
            }

            // if the variant optionId does not exist then add to import check results
            let missingAttributes = [];
            let missingOptions = [];
            // Set Fields to Check
            var optionFields = ["optionName1", "optionName2", "optionName3", "optionName4", "optionName5", "optionName6", "optionName7", "optionName8", "optionName9", "optionName10"];
            var optionValues = ["optionValue1", "optionValue2", "optionValue3", "optionValue4", "optionValue5", "optionValue6", "optionValue7", "optionValue8", "optionValue9", "optionValue10"];
            for (let i = 0; i < this.variantsImportData.length; i++) {
                let errorCount = 0
                let variant = this.variantsImportData[i];
                for (const property in variant) {
                    // options check
                    if (optionFields.includes(property)) {
                        if (variant[property] !== null && variant[property] !== undefined && variant[property] !== "") {
                            let formattedOptionId = variant.orgId+'_'+(variant[property]).replace(/ /g, "").trim();
                            variant[property] = formattedOptionId
                            if (!this.checkOptionExists(formattedOptionId)) {
                                errorCount++;
                                missingOptions.push(`Row ${i + 2} - ${variant[property]}<br />`);
                            }
                        }
                    } 
                    // attributes check
                    if (optionValues.includes(property)) {
                        if (variant[property] !== null && variant[property] !== undefined && variant[property] !== "") {
                            try {
                                let attributes = JSON.parse(variant[property]);
                                let formatedAttributes = []
                                for (let j = 0; j < attributes.length; j++) {
                                    let attributeId = variant["optionName" + property.slice(-1)]+"_"+(attributes[j]).replace(/ /g, "").trim()
                                    formatedAttributes.push(attributeId);
                                    
                                    if (!this.checkAttributeExists(attributeId)) {
                                        errorCount++;
                                        missingAttributes.push(`Row ${i + 2} - ${attributeId}<br />`);
                                    }
                                }
                                variant[property] = formatedAttributes;
                                //console.log('.'+variant[property]);
                            } catch (error) {
                                console.log("error", error.message);
                                errorCount++;
                                //variantsImportCheckStats.errors++;
                                missingAttributes.push(`Row ${i + 2} - ${variant[property]} <br />`);
                            }
                        }
                    }
                }
                if (errorCount > 0) {
                    variantsImportCheckStats.errors++;
                }
                // add product search code with no special characters or spaces 
                variant.variantSearchCode = variant.variantSupplierCode.replace(/[^a-zA-Z0-9]/g, "").trim();
                variant.variantPreviousSearchCode = variant.variantPreviousCode.replace(/[^a-zA-Z0-9]/g, "").trim();
            }
            // If any product optionIds that do not exist then add to import check results
            if (missingOptions.length > 0) {
                this.variantsImportCheckResults.push({
                    text: "Options Does Not Exist or is Misspelled",
                    value: `${missingOptions.join("")}`,
                });
            }
            // if any product attributes that do not exist then add to import check results
            if (missingAttributes.length > 0) {
                this.variantsImportCheckResults.push({
                    text: "Attributes Does Not Exist or is Misspelled or is not in the correct format",
                    value: `${missingAttributes.join("")}`,
                });
            }
            variantsImportCheckStats.success = (Number(variantsImportCheckStats.total) - Number(variantsImportCheckStats.errors)).toString();
            this.variantsImportCheckStats = variantsImportCheckStats;
        },
        async importOptions() {
            try {
                this.actionRunning = true;
                this.optionsProgressId = this.MIX_generateId();
                this.getOptionsProgressUpdate(this.optionsProgressId);
                let url = `${process.env.VUE_APP_MOFSERVER}/bulkhash/?key=option&progressid=${this.optionsProgressId}`;
                let optionsResult = await this.$axios.post(url, this.optionsImportData);
                if (optionsResult.data.code === 1) {
                    this.optionsResult = optionsResult.data.data;
                }
                this.actionRunning = false;
                this.getTotals();
            } catch (error) {
                this.optionsResult = "An Error Occured - Please Contact Support";
                this.actionRunning = false;
                console.log(error);
            }
        },
        async importAttributes() {
            try {
                this.actionRunning = true;
                this.attributesProgressId = this.MIX_generateId();
                this.getAttributesProgressUpdate(this.attributesProgressId);
                let url = `${process.env.VUE_APP_MOFSERVER}/bulkhash/?key=attribute&progressid=${this.attributesProgressId}`;
                let attributesResult = await this.$axios.post(url, this.attributesImportData);
                if (attributesResult.data.code === 1) {
                    this.attributesResult = attributesResult.data.data;
                }
                this.actionRunning = false;
                this.getTotals();
            } catch (error) {
                this.attributesResult = "An Error Occured - Please Contact Support";
                this.actionRunning = false;
                console.log(error);
            }
        },
        async importProducts() {
            try {
                this.actionRunning = true;
                this.productsProgressId = this.MIX_generateId();
                this.getProductsProgressUpdate(this.productsProgressId);
                let url = `${process.env.VUE_APP_MOFSERVER}/bulkhash/?key=product&progressid=${this.productsProgressId}`;
                let productsResult = await this.$axios.post(url, this.productsImportData);
                if (productsResult.data.code === 1) {
                    this.productsResult = productsResult.data.data;
                }
                this.actionRunning = false;
                this.getTotals();
            } catch (error) {
                this.productsResult = "An Error Occured - Please Contact Support";
                this.actionRunning = false;
                console.log(error);
            }
        },
        async importVariants() {
            try {
                this.actionRunning = true;
                this.variantsProgressId = this.MIX_generateId();
                this.getVariantsProgressUpdate(this.variantsProgressId);
                let url = `${process.env.VUE_APP_MOFSERVER}/bulkhash/?key=variant&progressid=${this.variantsProgressId}`;
                let variantsResult = await this.$axios.post(url, this.variantsImportData);
                if (variantsResult.data.code === 1) {
                    this.variantsResult = variantsResult.data.data;
                }
                this.actionRunning = false;
                this.getTotals();
            } catch (error) {
                this.variantsResult = "An Error Occured - Please Contact Support";
                this.actionRunning = false;
                console.log(error);
            }
        },
        async getOptionsProgressUpdate(progressId) {
            let t = this;
            let optionsProgressResult = {};
            const myInterval = setInterval(async () => {
                optionsProgressResult = await this.MIX_redisReadHash("progress:" + progressId);
                t.optionsProgressResult = optionsProgressResult.data;
                if (optionsProgressResult.data.status === "Completed") {
                    clearInterval(myInterval);
                }
            }, 100);
        },
        async getAttributesProgressUpdate(progressId) {
            let t = this;
            let attributesProgressResult = {};
            const myInterval = setInterval(async () => {
                attributesProgressResult = await this.MIX_redisReadHash("progress:" + progressId);
                t.attributesProgressResult = attributesProgressResult.data;
                if (attributesProgressResult.data.status === "Completed") {
                    clearInterval(myInterval);
                }
            }, 100);
        },
        async getProductsProgressUpdate(progressId) {
            let t = this;
            let productsProgressResult = {};
            const myInterval = setInterval(async () => {
                productsProgressResult = await this.MIX_redisReadHash("progress:" + progressId);
                t.productsProgressResult = productsProgressResult.data;
                if (productsProgressResult.data.status === "Completed") {
                    clearInterval(myInterval);
                }
            }, 100);
        },
        async getVariantsProgressUpdate(progressId) {
            let t = this;
            let variantsProgressResult = {};
            const myInterval = setInterval(async () => {
                variantsProgressResult = await this.MIX_redisReadHash("progress:" + progressId);
                t.variantsProgressResult = variantsProgressResult.data;
                if (variantsProgressResult.data.status === "Completed") {
                    clearInterval(myInterval);
                }
            }, 100);
        },        
        // checks
        checkOptionExists(optionId) {
            // check options object array for option Name
            let optionExists = this.optionsIds.find((x) => x === 'option:'+optionId);
            if (optionExists !== undefined) {
                return true;
            } else {
                return false;
            }
        },
        checkAttributeExists(attId) {
            let attExists = this.attributesIds.find((x) => x === 'attribute:'+attId);
            if (attExists !== undefined) {
                return true;
            } else {
                return false;
            }
        },
        checkProductExists(productId) {
            let productExists = this.productsIds.find((x) => x === 'product:'+productId);
            if (productExists !== undefined) {
                return true;
            } else {
                return false;
            }
        },
    },
    async created() {
        this.loading = true;
        let orgs = await this.MIX_organisations("Supplier");
        orgs = orgs.filter((x) => x.orgApproved === "1");
        this.orgs = orgs.map((x) => {
            return {
                orgId: x.id,
                orgName: x.orgName,
            };
        });
        this.loading = false;
    },
};
</script>
<!-- TODO 


Search for Products in Variants


-->