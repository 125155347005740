<template>
	<div>
		<v-row class="pa-0 ma-0" fill-height>
			<v-row no-gutters class="py-3 px-3">
				<v-col class="font-weight-bold primary--text text-h6 d-flex align-center">
					<div class="mx-3"><v-icon>icons8-color-mode</v-icon></div>
					<div class="text-h7" v-if="item.attName">{{ item.attName }}</div>
					<div class="text-h7" v-else>New Fabric</div>
					<v-spacer></v-spacer>
					<div class="ml-4" v-if="!itemNew && GET_destroy"><v-icon :large="!GET_dense" class="error--text pa-1" style="border: 1px red solid; border-radius: 3px" @click="destroyItem()">icons8-delete-trash</v-icon></div>
					<div class="ml-4" v-if="item.deleted === 'false'"><v-icon :large="!GET_dense" class="grey--text text--darken-2" @click="deleteItem()">icons8-trash-can</v-icon></div>
					<div class="ml-4" v-if="item.deleted === 'true'"><v-icon :large="!GET_dense" class="grey--text text--darken-2" @click="restoreItem()">icons8-trash-restore</v-icon></div>
					<div class="ml-4">
						<!-- <v-icon
							v-if="editMode"
							:large="!GET_dense"
							class="primary--text animate__animated animate__pulse animate__infinite"
							@click="
								editMode = false;
								createItem();
							"
						>
							icons8-save</v-icon
						> -->
						<v-icon :large="!GET_dense" depressed class="primary--text" @click="editMode = true">icons8-edit</v-icon>
					</div>

					<div>
						<v-btn class="ml-2 primary--text" plain :small="GET_dense" @click="$emit('closeItem')" depressed>
							<v-icon>icons8-multiply</v-icon>
						</v-btn>
					</div>
				</v-col>
			</v-row>
			<v-col class="pa-0" fill-height :cols="viewCols.cols" :xs="viewCols.xs" :sm="viewCols.sm" :md="viewCols.md" :lg="viewCols.lg" :xl="viewCols.xl">
				<v-tabs background-color="white" v-model="tab" show-arrows>
					<v-tab href="#details">Details</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab" fill-height class="grey lighten-3">
					<!-- COLOUR DETAILS TAB -->
					<v-tab-item value="details">
						<v-row class="pa-3" no-gutters>
							<v-col cols="12" sm="12" class="pa-3">
								<div class="text-left primary--text text-h6 font-weight-light">Colour Details</div>
							</v-col>
							<v-col cols="12" sm="12" class="grey lighten-5 roundedtop mt-2 pt-3">
								<v-simple-table dense class="apptext--text transparent"
									>>
									<template v-slot:default>
										<tbody>
											<tr>
												<td width="50%"><strong>Code</strong><br />Code used by supplier to identify fabric</td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.attCode }}</div>
													<div v-else><v-text-field v-model="item.attCode" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Name</strong><br />Name of Colour e.g Blue</td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.attName }}</div>
													<div v-else><v-text-field v-model="item.attName" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Type</strong></td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.attType }}</div>
													<div v-else><v-text-field v-model="item.attType" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Option Name</strong><br />Parent option for this fabric</td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.optionName }}</div>
													<div v-else><v-autocomplete v-model="optionSelected" :items="options" return-object item-text="optionName" outlined dense hide-details="auto" background-color="white" color="primary" clearable :value="item.optionName"></v-autocomplete></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Option ID</strong></td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.optionId }}</div>
													<div v-else><v-text-field disabled v-model="item.optionId" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>MOQ</strong><br />Minimum Order Quantity</td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.attMTO }}</div>
													<div v-else><v-text-field v-model="item.attMTO" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Price Band</strong><br />Pricing band if any A-F</td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.attPriceBand }}</div>
													<div v-else><v-text-field v-model="item.attPriceBand" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Add Cost</strong><br />Supplier additional cost for this fabric</td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">£ {{ item.attAddCost }}</div>
													<div v-else><v-text-field prefix="£" v-model="item.attAddCost" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Add Sell</strong><br />Additional sell price for this fabric</td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">£ {{ item.attAddSell }}</div>
													<div v-else><v-text-field prefix="£" v-model="item.attAddSell" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Parent</strong><br />Select a parent attribute this fabric depends on e.g Edge</td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.attParentId }}</div>
													<div v-else><v-text-field v-model="item.attParentId" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%" class="pt-2" valign="top"><strong>Description</strong><br />Enter a description if required</td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.attDescription }}</div>
													<div v-else><v-textarea v-model="item.attAddSell" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-textarea></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Hex Colour (Swatch)</strong></td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.attHexColor }}</div>
													<div v-else><v-text-field v-model="item.attHexColor" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Image (Swatch)</strong></td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.attImage }}</div>
													<div v-else><v-text-field v-model="item.attImage" outlined dense hide-details="auto" background-color="white" color="primary" clearable></v-text-field></div>
												</td>
											</tr>
											<tr>
												<td width="50%"><strong>Enabled</strong></td>
												<td width="50%" class="py-2">
													<div v-if="!editMode">{{ item.enabled }}</div>
													<div v-else><v-switch v-model.number="item.enabled" inset outlined dense hide-details="auto" background-color="white" color="primary" true-value="1" false-value="0" :value="item.enabled"></v-switch></div>
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
								<!-- <pre>{{ optionSelected }}</pre>
								<br /><br />								
								<pre>{{ item }}</pre> -->
							</v-col>
							<v-col cols="12" v-if="editMode" class="grey lighten-2 pa-3 d-flex justify-right align-center roundedbottom">
								<v-spacer />
								<div>
									<v-btn class="grey lighten-1 secondary--text font-weight-bold" depressed @click="initialiseItem(); $emit('closeItem')">
										<span>Cancel</span>
									</v-btn>
								</div>
								<div class="ml-2">
									<v-btn v-if="itemNew" class="accent primary--text font-weight-bold" depressed @click="createItem()">
										<span>Save</span>
									</v-btn>
									<v-btn v-else class="accent primary--text font-weight-bold" depressed @click="updateItem()">
										<span>Update</span>
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import schema from "@/schema.js";
	export default {
		// * COMPONENTS
		components: {},
		// * PROPS
		props: {
			activeitem: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			addImageToggle: false,
			addPricingToggle: true,
			editMode: false,
			tab: "details",
			itemNew: false,
			key: "attribute",
			schema: null,
			options: [],
			optionSelected: {},
			// Active Item
			item: {},
			// Price Bands
			priceBands: ["A", "B", "C", "D", "E", "F"],
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_destroy: "GET_destroy",
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
			viewCols() {
				return {
					cols: 12,
					xs: 12,
					sm: 12,
					md: 12,
					lg: 12,
					xl: 12,
				};
			},
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				if (JSON.stringify(t.activeitem) !== "{}") {
					this.readItem();
				} else {
					t.itemNew = true;
					t.editMode = true;
					t.item = { ...t.schema };
					t.item.key = "attribute";
				}
			},
			// * CREATE ITEM
			async createItem() {
				this.item.id = this.item.attName.replace(/\s/g, "");
				let result = await this.MIX_createItem(this.item, this.key, this.itemNew);
				if (result.code === 1) {
					this.initialiseItem();
				}
			},
			// * READ ITEM
			async readItem() {
				let t = this;
				let result = await t.MIX_readItem(t.activeitem.id, t.key);
				if (result.code === 1) {
					this.item = result.data;
					t.editMode = false;
					t.itemNew = false;
					this.optionSelected = {
						id: t.item.id,
						optionName: t.item.optionName,
					};
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem();
				}
			},
			// * DELETE ITEM
			async deleteItem() {
				let result = await this.MIX_deleteItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem();
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem();
				}
			},
			// * GET OPTIONS
			async getOptions() {
				let result = await this.MIX_redisSearch("idx:options", "@deleted:0 @enabled:1 @optionType:Fabric", 0, 9999);
				if (result.code === 1) {
					this.options = result.data.documents.map((x) => {
						return {
							id: x.id,
							optionName: x.value.optionName,
						};
					});
				}
			},
			setOptionSelected() {
				this.item.optionId = this.optionSelected.id;
				this.item.optionName = this.optionSelected.optionName;
			},
		},
		// * WATCH
		watch: {
			activeitem() {
				this.initialiseItem();
			},
			optionSelected: {
				handler: function () {
					this.setOptionSelected();
				},
				deep: true,
			},
		},
		// * CREATED
		async created() {
			this.schema = schema.attribute;
			this.initialiseItem();
			this.getOptions();
			this.setOptionSelected;
		},
	};
</script>
