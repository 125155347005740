import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import config from "@/customConfig.js"

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg' | 'icons8' || 'mdi' // || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		options: {
            customProperties: true,
        },
		themes: {
			light: {
				primary: "#6F2D86", // config.primary, //"#6F2D86",
				secondary: config.secondary, //"#371548",
				accent: config.accent, //"C4CF59#",
				error: "#b71c1c",
                lightgrey: '#E0E0E0',
				headingtext: '#6F2D86',
				apptext: '#4f4f4f',
                notificationsBackground: '#EEEEEE',
                darkgrey: '#4f4f4f', //'#4f4f4f',
                appbackground: '#E5E5E5', //'#F2F2F2',
                success: '#00942A',
                // warning: '#E04B0D',
                info: '#4492DA',
                vindicoPurple: '#582468',
                white: '#ffffff'
			},
		},
	},
});
