<template>
  <v-card :class="actionTicket === true ? '' : '' " flat outlined>
    <v-col cols="12" class="align-start">
      <v-row class="pa-2 pb-0">
        <v-col cols="12" class="d-flex ma-0 pa-0 pl-1 ">
          <!-- @click="openTicket" -->
          <div class="d-flex align-center">
            <v-icon
                size="40"
                class="grey--text mr-1"
                :class="
                                ticket.status === 'Open' ? 'icons8-open-message' :
                                ticket.status === 'Closed' ? 'icons8-cancel' :
                                ticket.status === 'Archived' ? 'icons8-archive' :
                                ticket.status === 'Solved' ? 'icons8-checkmark-yes' :
                                ticket.status === 'Pending' ? 'icons8-clock-2' :
                                ticket.status === 'On Hold' ? 'icons8-pause-button' : '' "
            />
          </div>
          <div>
            <!-- <pre>{{ ticket }}</pre> -->
            <div class="font-weight-bold text-start body-2 text-truncate primary--text"
                 style="max-width: 300px !important">
              {{ ticket.orgName }}
            </div>
            <div class="font-weight-bold text-start body-2 text-truncate grey--text"
                 style="max-width: 300px !important">
              {{ ticket.subject }} - <span class="font-weight-regular"> {{ ticket.message }}</span>
            </div>
            <div class="d-flex font-weight-bold text-start body-2 grey--text">
              {{ MIX_formatDateTime(ticket.createdDateTime, 'x', 'DD-MMM-YYYY') }}
            </div>
          </div>
          <v-spacer/>
          <div class="d-flex flex-column align-start justify-end">
            <v-avatar v-if="ticket.ownerUserName" color="caption primary white--text font-weight-regular" size="30">
              {{ ticket.ownerUserName.match(/\b(\w)/g).join('') }}
            </v-avatar>
            <v-icon class="grey--text" size="15" @click="reveal = !reveal">icons8-expand-arrow</v-icon>
          </div>
        </v-col>
      </v-row>
      <v-row class="pa-2 pt-0" :class="reveal ? 'pb-2' : ''">
        <v-col cols="12" class="d-flex ma-0 pa-0 pr-3 justify-end align-center">
          <!-- <div> -->

          <v-spacer/>
          <!-- <v-spacer /> -->
          <div>
          </div>
          <!-- </div> -->
        </v-col>
      </v-row>

      <v-card v-if="reveal === true" class="mt-3 elevation-0" style="height: 100% !important; width: 100% !important">
        <v-divider/>
        <table class="ma-0 pa-0 mt-3 caption grey--text text--darken-2 " border="0" width="100%">
          <!-- <tr>
              <td class="font-weight-bold" width="40%" align="left">Exp Close:</td>
              <td class="" width="60%" align="left">{{ MIX_formatDateTime(ticket.salesEstimatedCloseDate, 'X', 'MMM-YY') }}</td>
          </tr> -->
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Owner:</td>
            <td class="" width="60%" align="left">{{ ticket.ownerUserName }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold" width="40%" align="left">Created By:</td>
            <td class="" width="60%" align="left">{{ ticket.createdUserName }}</td>
          </tr>
        </table>

        <div class="d-flex justify-stretch pt-3" style="width: 100% !important;">
          <div v-if="actionTicket == false" style="width: 48% !important;">
            <v-btn block depressed @click="openTicket" class="caption font-weight-bold primary--text">Account</v-btn>
          </div>
          <v-spacer/>
          <div style="width: 48% !important;">
            <v-btn block depressed @click="openTicketItem" class="caption font-weight-bold primary--text">View</v-btn>
          </div>
        </div>
      </v-card>
    </v-col>

  </v-card>
</template>

<script>
export default {
  data: () => ({
    ticket: {},
    reveal: false,
    orgType: '',
  }),
  props: {
    datadown: {
      type: Object,
      default: () => ({})
    },
    actionTicket: {
      type: Boolean,
      default: false,
    }
  },
  computed: {},
  methods: {
    openTicket() {
      // ////console.log('openTicket');
      this.$emit('openticket', this.ticket);
    },
    openTicketItem() {
      this.$emit('openticketitem', this.ticket);
    }
  },
  async created() {
    // var t = this
    this.ticket = {...this.datadown};
    ////console.log("ticket = " + JSON.stringify(this.ticket, null, 2))
  },

}
</script>

<style scoped>
.borderClass {
  border-left: 5px solid #019EAF !important;
  border-color: #019EAF !important
}
</style>
